export const HomeIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_554_5093)">
        <path
          d="M15.3286 6.47921L14.8217 6.08255H14.8339V3.16588H13.6672V5.17896L10.0786 2.3708C9.44392 1.8738 8.55667 1.8738 7.922 2.3708L2.67142 6.47921C2.245 6.81288 2 7.31571 2 7.85763V15.9992H7.25V10.7492C7.25 10.4278 7.51133 10.1659 7.83333 10.1659H10.1667C10.4887 10.1659 10.75 10.4278 10.75 10.7492V15.9992H16V7.85763C16 7.31571 15.755 6.81288 15.3286 6.47921ZM14.8333 14.8325H11.9167V10.7492C11.9167 9.78438 11.1315 8.99921 10.1667 8.99921H7.83333C6.8685 8.99921 6.08333 9.78438 6.08333 10.7492V14.8325H3.16667V7.85763C3.16667 7.6768 3.24833 7.50938 3.39067 7.39796L8.64067 3.28955C8.85242 3.12388 9.14758 3.12388 9.35933 3.28955L14.6093 7.39796C14.7517 7.50938 14.8333 7.6768 14.8333 7.85763V14.8325Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_554_5093">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
