import { Button } from "components/DesignSystem/Button/Button";
import { OptionData } from "components/DesignSystem/Combobox/Combobox";
import Pencil from "components/icons/pencil";
import { Pointer } from "components/icons/Pointer";
import { PoweredByGoogleIcon } from "components/icons/PoweredByGoogleIcon";
import { OptionProps, components } from "react-select";
import { AddressAutofill } from "types/Models/addressAutofill";
import { Countries } from "types/Models/countries";

export const CustomOption = ({
  editAddress,
  isEditable,
  children,
  ...props
}: OptionProps<OptionData> & {
  editAddress?: () => void;
  isEditable?: boolean;
}) => {
  return (
    <components.Option
      {...props}
      className="[&.select\_\_option--is-focused]:t-bg-surface-lighter-grey [&.select\_\_option--is-selected]:t-bg-i-surface-light-purple"
    >
      <div className="t-flex t-gap-2.5 t-font-medium t-items-center t-justify-between t-px-3 ">
        <div className="t-cursor-pointer t-truncate !t-border-none t-py-2.5">
          <div className="t-items-center t-flex t-gap-2">
            <span className="t-w-fit">
              <Pointer />
            </span>
            <span className="t-flex-1 t-truncate">{children}</span>
          </div>
        </div>
        {isEditable && (
          <span className="t-w-fit">
            <Button
              customType="transparent"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                editAddress?.();
              }}
            >
              <Pencil color="#5622FF" size="16" />
            </Button>
          </span>
        )}
      </div>
    </components.Option>
  );
};

export const CustomSavedAddressOption = ({
  children,
  ...props
}: OptionProps<OptionData> & { editAddress: () => void }) => {
  const isFirstOption =
    (props.data as OptionData).value === (props.options[0] as OptionData).value;

  if (isFirstOption) {
    return (
      <div>
        <div className="t-text-body-sm t-text-text-30 t-p-3">
          Saved Addresses
        </div>
        <CustomOption {...props} isEditable>
          {children}
        </CustomOption>
      </div>
    );
  }

  return (
    <CustomOption {...props} isEditable>
      {children}
    </CustomOption>
  );
};

export const CustomGoogleSuggestedOption = ({
  children,
  ...props
}: OptionProps<OptionData>) => {
  const isFirstOption =
    (props.data as OptionData).value === (props.options[0] as OptionData).value;

  if (isFirstOption) {
    return (
      <div>
        <div className="t-text-body-sm t-text-text-30 t-p-3">
          Powered by <PoweredByGoogleIcon />
        </div>
        <CustomOption {...props}>{children}</CustomOption>
      </div>
    );
  }

  return <CustomOption {...props}>{children}</CustomOption>;
};
