import { ActionCard } from "components/ActionCard/ActionCard";
import { Button } from "components/DesignSystem/Button/Button";
import { ConditionalLink } from "components/conditionalLink";
import { useRouteMatch } from "react-router-dom";
import { LargeCard } from "./Home";

export const ForeignCAHome = () => {
  const { url } = useRouteMatch();

  return (
    <div className="t-px-9 t-py-8">
      <div className="t-text-title-h2-bold">Welcome to your practice!</div>
      <div className="t-flex t-gap-6 t-mt-6 t-flex-wrap">
        <LargeCard block>
          <div className="t-border t-border-solid t-rounded-lg t-p-5 t-border-neutral-10 t-h-full t-w-full t-text-text-100 t-bg-[url('static/images/InklePracticePromotionalCard.svg')] t-bg-no-repeat t-bg-right-bottom">
            <p className="t-flex t-flex-col t-gap-1 t-mb-3">
              <span className="t-text-title-h2-bold t-leading-normal">
                Supercharge your practice
              </span>
              <ul className="t-text-body t-text-text-60 t-pl-6 t-my-3">
                <li>
                  Grow your revenue by providing US tax & compliance services
                </li>
                <li>Add your markup over Inkle pricing</li>
                <li>Early-bird offer: No platform fee</li>
              </ul>
              <span className="t-text-text-60 t-text-body-sm">
                💡 You will maintain full control and visibility over your
                clients.
              </span>
            </p>
            <ConditionalLink to={`${url}/crm?addclient=true`}>
              <Button size="small">Add clients</Button>
            </ConditionalLink>
          </div>
        </LargeCard>
        <LargeCard>
          <ActionCard
            name="Need any help? "
            content={
              <span className="t-text-body t-text-text-30 t-mb-14">
                Ask Team Inkle for any queries or help on the product
              </span>
            }
            className="t-bg-[url('static/images/ChatGradient.svg')] t-bg-no-repeat t-bg-right-bottom"
            action={
              <ConditionalLink to={`${url}/chat`}>
                <Button size="small">Chat with us</Button>
              </ConditionalLink>
            }
          />
        </LargeCard>
      </div>
    </div>
  );
};
