import { FileUploadingToast } from "components/design/LoadingToast";
import {
  DnDArea,
  File,
  UploadLabel,
} from "components/design/UploadFileSection";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { DownloadIcon } from "components/icons/Download";
import { useAbortController } from "hooks/useAbortController";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useShow } from "hooks/useShow";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import {
  usePopulateBulkBillsMutation,
  useUploadBulkBillsMutation,
} from "store/apis/vendorBills";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const BulkBillUpload = ({ isOpen, close }: ModalProps) => {
  const entityId = useCurrentEntityId();
  const { alertToast, successToast, infoToast } = useToast();
  const [file, setFile] = useState<File | null>(null);
  const [uploadFile, uploadState] = useUploadBulkBillsMutation();
  const [addBill, addState] = usePopulateBulkBillsMutation();
  const addingToast = useShow();
  const uploadController = useAbortController({
    onAbort: () => {
      alertToast({ message: "Upload cancelled" });
    },
  });

  const addingRefController = useAbortController({
    onAbort: () => infoToast({ title: "Bills addition cancelled" }),
  });

  const onClose = () => {
    close();
    setFile(null);
    uploadState.reset();
  };

  const onDrop = async (files: File[]) => {
    if (!files.length) return;
    try {
      setFile(files[0]);
      await uploadFile({
        entityId,
        file: files[0],
        signal: uploadController.signal,
      }).unwrap();
    } catch (error) {
      setFile(null);
      if (
        (
          error as {
            status: string;
          }
        ).status === "FETCH_ERROR"
      )
        return;

      alertToast(
        { message: (error as BackendError)?.data?.error?.message },
        error as Error
      );
    }
  };

  const onAddBills = async ({ fileId }: { fileId: string }) => {
    if (!fileId) {
      alertToast({ message: "No file uploaded" });
      return;
    }
    addingToast.show();
    close();
    uploadState.reset();
    try {
      await addBill({
        entityId,
        bulk_file_id: fileId,
        signal: addingRefController.signal,
      }).unwrap();
      successToast({ title: "Bills added successfully" });
      setTimeout(() => {
        addingToast.hide();
      }, 1000);
      setFile(null);
    } catch (error) {
      addingToast.hide();
      setFile(null);
      if (
        (
          error as {
            status: string;
          }
        ).status === "FETCH_ERROR"
      )
        return;
      alertToast(
        { message: (error as BackendError)?.data?.error?.message },
        error as Error
      );
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Content size="large">
        <Modal.Header>
          <Modal.Title>Import Bills</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-flex t-flex-col t-gap-3 !t-pb-8">
          <UploadLabel>
            Download the pre-formatted template to ensure your data is properly
            structured. Add the bill details to the template and upload it.
          </UploadLabel>
          <span>
            <Button
              size="small"
              onClick={() =>
                window.open(
                  "https://inkle-django-files-prod.s3.ap-south-1.amazonaws.com/TEMPLATES/bulk_add_bills_template.xlsx"
                )
              }
            >
              <DownloadIcon />
              <span className="t-ml-1.5">Download Excel Template</span>
            </Button>
          </span>
          {file ? (
            <File
              file={file}
              onCancel={uploadController.abort}
              onDelete={() => setFile(null)}
              isUploading={uploadState.isLoading}
              isUploaded={uploadState.isSuccess}
            />
          ) : (
            <DnDArea
              onDrop={onDrop}
              accept={{
                "application/vnd.ms-excel": [".xls"],
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                  [".xlsx"],
              }}
              subText="File Format: xls/xlsx"
            />
          )}
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button>Cancel</Button>
          </Modal.RawClose>
          <Button
            disabled={!uploadState.data?.bulk_file_id}
            customType="primary"
            onClick={() =>
              uploadState.data?.bulk_file_id &&
              onAddBills({ fileId: uploadState.data.bulk_file_id })
            }
          >
            Add Bills
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
      {file && (
        <FileUploadingToast
          onCancel={addingRefController.abort}
          file={file}
          open={addingToast.isShown}
          close={addingToast.hide}
          isSuccess={addState.isSuccess}
          title="Adding Bills"
        />
      )}
    </Modal.Root>
  );
};
