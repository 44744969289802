import { INKLE_TEAM_AND_CPA_TEAM } from "constants/chatType";
import { emptyApi } from "./emptyApi";
import qs from "qs";
import { Task } from "types/Models/task";
import { Pagination } from "types/Models/pagination";
import dayjs from "dayjs";
import { YYYY_MM_DD } from "constants/date";

type ServiceTeam = {
  image_url: string;
  name: string;
  uuid: string;
};

type DomainResponse = {
  name: string;
  uuid: string;
  status: string;
};

type GetAllServiceProviderTaskRequest = {
  page?: number | null;
  page_size?: number | null;
  search_term?: string | null;
  task_tag_filter_uuids?: string | null;
  company_tag_filter_uuids?: string | null;
  task_template_filter_uuids?: string | null;
  task_state_filter_uuids?: string | null;
  assignee_uuids?: string | null;
  subscription_filter?: string | null;
  service_team_uuids?: string | null;
  team_member_uuids?: string | null;
  show_unassigned_team?: string | null;
  show_unassigned_preparer?: string | null;
  sort_by?: string | null;
  etd_filter?: string | null;
  generate_report?: boolean | null;
  export_to_mail?: boolean | null;
  completed_start_date?: string | null;
  completed_end_date?: string | null;
  formation_type?: string[] | null;
  profitability_status?: string | null;
  state_registrations?: string | null;
  missing_business_financials_form_7004_flag?: boolean | null;
  missing_information_form_7004_flag?: boolean | null;
  bookkeeping_status?: string | null;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceTeam: build.query<
      ServiceTeam[],
      {
        accessible_teams?: typeof INKLE_TEAM_AND_CPA_TEAM | null;
        serviceTeamId?: string | null;
      }
    >({
      query: ({ accessible_teams, serviceTeamId }) => {
        let queryUrl = qs.stringify(
          {
            accessible_teams: accessible_teams,
            service_team_ids: serviceTeamId,
          },
          { skipNulls: true }
        );
        return {
          url: `/api/inkle/serviceteams/get/?${queryUrl}`,
        };
      },
    }),

    createServiceTeam: build.mutation({
      query: ({ payload }) => {
        return {
          url: `/api/inkle/serviceteams/create/`,
          method: "POST",
          body: payload,
        };
      },
    }),

    assignMonthlySummary: build.mutation({
      query: ({ profileId, monthlyTxnSummaryId }) => {
        return {
          url: `/api/inkle/bookkeeping/monthly_txn_summary/${monthlyTxnSummaryId}/assignee/`,
          method: "PUT",
          body: { profile_id: profileId },
        };
      },
      invalidatesTags: (result) => {
        return result ? [{ type: "BOOKS_CRM" }] : [];
      },
    }),

    joinServiceTeam: build.mutation({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/serviceteams/team/${groupId}/join/`,
          method: "POST",
        };
      },
    }),
    getDomainRecognization: build.query<DomainResponse, void>({
      query: () => "/api/inkle/company/group/service_team/join/",
    }),

    getAllServiceProviderTask: build.query<
      { tasks: Task[] } & Pagination & { download_url?: string | null },
      GetAllServiceProviderTaskRequest
    >({
      query: (params) => {
        let queryUrl = qs.stringify(
          {
            ...params,
            completed_end_date: params.completed_end_date
              ? dayjs(params.completed_end_date).format(YYYY_MM_DD)
              : null,
            completed_start_date: params.completed_start_date
              ? dayjs(params.completed_start_date).format(YYYY_MM_DD)
              : null,
            formation_types: params.formation_type?.join(","),
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `api/inkle/tasks/v1/provider/tasks/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["All_SERVICE_PROVIDERS"] : []),
    }),

    updateTaskAssigneeAdmin: build.mutation<
      {},
      {
        taskId: string;
        preparerId?: string | null;
        removePreparer?: boolean;
        serviceTeamId?: string;
        removeServiceTeam?: boolean;
        estimatedDeliveryTime?: string;
        reason?: string;
      }
    >({
      query: ({
        taskId,
        preparerId,
        removePreparer,
        serviceTeamId,
        removeServiceTeam,
        estimatedDeliveryTime,
        reason,
      }) => {
        const payload = {
          preparer_id: preparerId,
          remove_preparer: removePreparer,
          service_team_id: serviceTeamId,
          remove_service_team: removeServiceTeam,
          estimated_delivery_time: estimatedDeliveryTime,
          reason,
        };

        return {
          url: `/api/inkle/tasks/v1/${taskId}/update/admin/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? ["All_SERVICE_PROVIDERS", "Tasks", "TaskList"] : [],
    }),

    updateTaskAssigneeProvider: build.mutation<
      {},
      {
        taskId: string;
        preparerId?: string | null;
        removePreparer?: boolean;
        estimatedDeliveryTime?: string;
        reason?: string;
      }
    >({
      query: ({
        taskId,
        preparerId,
        removePreparer,
        estimatedDeliveryTime,
        reason,
      }) => {
        const payload = {
          preparer_id: preparerId,
          remove_preparer: removePreparer,
          estimated_delivery_time: estimatedDeliveryTime,
          reason,
        };
        return {
          url: `/api/inkle/tasks/v1/${taskId}/update/provider/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? ["All_SERVICE_PROVIDERS", { type: "Tasks" }] : [],
    }),
    getAllCpaTeams: build.query<ServiceTeam[], {}>({
      query: () => {
        return {
          url: `/api/inkle/serviceteams/all_cpa_team_members/`,
          method: "GET",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetServiceTeamQuery,
  useCreateServiceTeamMutation,
  useJoinServiceTeamMutation,
  useAssignMonthlySummaryMutation,
  useGetDomainRecognizationQuery,
  useLazyGetServiceTeamQuery,
  useUpdateTaskAssigneeAdminMutation,
  useUpdateTaskAssigneeProviderMutation,
  useGetAllServiceProviderTaskQuery,
  useLazyGetAllServiceProviderTaskQuery,
  useGetAllCpaTeamsQuery,
} = extendedApi;
