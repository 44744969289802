import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import dayjs from "dayjs";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { FREQUENCY_MAP, STATUS_MAP } from "pages/Chat/Reminders";
import {
  ReminderDetails,
  ReminderSetting,
  UserProfile,
} from "store/apis/reminders";
import { formatDate } from "utils/formatDate";
import ToolTip from "components/design/toolTip";

export const ChatReminderDetails = ({
  selectedReminder,
  alertDetails,
  subscribers,
}: {
  selectedReminder: ReminderSetting;
  alertDetails?: ReminderDetails;
  subscribers: UserProfile[];
}) => {
  const { isForeignCA } = useRoleBasedView();
  const { name: groupName, uuid: groupId } = selectedReminder?.company_group;
  const isReminderEnabled = selectedReminder.is_reminder_enabled;
  const isAlert = Boolean(alertDetails);

  const companyUrl = isForeignCA
    ? `/practice/crm/${groupId}`
    : `/admin/crm/${groupId}`;

  const isOverdue = dayjs(alertDetails?.recurring_date).isBefore(dayjs());

  return (
    <div>
      <section className="t-pb-2">
        <InfoItem label="Name">
          {selectedReminder.content_details?.reminder_name || "-"}
        </InfoItem>
      </section>
      <div className="t-grid t-grid-cols-2 t-gap-x-4 t-gap-y-6">
        <InfoItem label="Company">
          <ConditionalLink target="_blank" to={companyUrl}>
            <span className="t-text-purple">{groupName}</span>
          </ConditionalLink>
        </InfoItem>
        <InfoItem label="Created by">
          {selectedReminder.reminder_setter?.name || "-"}
        </InfoItem>
        <InfoItem label="Frequency">
          {FREQUENCY_MAP[selectedReminder.frequency] || "-"}
        </InfoItem>
        {alertDetails && (
          <InfoItem label="Scheduled for">
            {formatDate(alertDetails.recurring_date)}
          </InfoItem>
        )}
        <InfoItem label="Status">
          {isAlert && alertDetails && (
            <Tag icon={false} tagType={isOverdue ? "red" : "purple"}>
              {isOverdue ? "OVERDUE" : STATUS_MAP[alertDetails.status]}
            </Tag>
          )}

          {!isAlert && (
            <>
              {isReminderEnabled ? (
                <Tag tagType="green" icon={false}>
                  Active
                </Tag>
              ) : (
                <Tag tagType="red" icon={false}>
                  Stopped
                </Tag>
              )}
            </>
          )}
        </InfoItem>
        <div className="t-col-span-2">
          <InfoItem label="Message">
            <div className="t-truncate">
              <ToolTip text={selectedReminder.content_details.message_text}>
                <span>
                  {isAlert
                    ? alertDetails?.message_text
                    : selectedReminder.content_details.message_text}
                </span>
              </ToolTip>
            </div>
          </InfoItem>
        </div>
        <InfoItem label="Channels">
          <div className="t-flex t-flex-wrap t-gap-1 t-mt-1">
            {selectedReminder.notification_channel.map((channel) => (
              <div
                className="t-px-3 t-py-0.5 t-rounded t-bg-neutral-0 t-text-text-30 t-text-body-sm"
                key={channel}
              >
                {channel}
              </div>
            ))}
          </div>
        </InfoItem>
        {selectedReminder.notification_channel?.includes("WHATSAPP") &&
          subscribers.length > 0 && (
            <InfoItem label="Whatsapp recipients">
              <div className="t-flex t-flex-wrap t-gap-1 t-mt-1">
                {subscribers.map((subscriber) => (
                  <div
                    className="t-px-3 t-py-0.5 t-rounded t-bg-neutral-0 t-text-text-30 t-text-body-sm"
                    key={subscriber.uuid}
                  >
                    {subscriber.name}
                  </div>
                ))}
              </div>
            </InfoItem>
          )}
      </div>
    </div>
  );
};

export const ToggleReminderEnable = ({
  toggleReminder,
  isUpdating,
  isOpen,
  isReminderEnabled,
  close,
}: {
  toggleReminder: () => void;
  isUpdating: boolean;
  isOpen: boolean;
  isReminderEnabled: boolean;
  close: () => void;
}) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>
            {isReminderEnabled ? "Stop" : "Enable"} this reminder?
          </Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          {isReminderEnabled
            ? "This action will cancel all scheduled and future alerts associated with this reminder."
            : "Future alerts for this reminder will resume and be sent to the selected channel and recipients."}
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button>Cancel</Button>
          </Modal.RawClose>
          <Button
            customType="primary"
            onClick={() => toggleReminder()}
            isLoading={isUpdating}
            disabled={isUpdating}
          >
            {isReminderEnabled ? "Stop" : "Start"}
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
