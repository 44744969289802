import classNames from "classnames";
import { ComponentProps, ReactNode, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Avatar } from "./Avatar";

type AvatarUploadProps = {
  label?: ReactNode;
  onDrop?(file: File): void;
  file?: File;
} & ComponentProps<typeof Avatar>;

export const AvatarUpload = ({
  onDrop: onDropFromProps,
  label = "Edit",
  ...props
}: AvatarUploadProps) => {
  const preview_url = props.file && URL.createObjectURL(props.file);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    onDropFromProps && onDropFromProps(acceptedFiles[0]);
  }, []);

  const { open, getInputProps } = useDropzone({
    onDrop,
    noKeyboard: true,
    multiple: false,
    accept: {
      "image/*": [".jpg", ".jpeg", ".png"],
    },
  });

  return (
    <button
      className="all:unset t-relative t-group t-inline-block t-cursor-pointer t-rounded-full"
      type="button"
      onClick={open}
    >
      <div className="group-hover:t-opacity-30">
        <Avatar {...props} src={preview_url || props.src} />
      </div>
      <div className="t-absolute t-inset-0 t-flex t-items-center t-justify-center t-invisible group-hover:t-visible">
        <input {...getInputProps()} />
        <div className="group-hover:t-opacity-60 t-rounded-full group-hover:t-bg-purple-100 t-absolute t-inset-0 t-flex t-items-center t-justify-center t-opacity-0"></div>
        <div
          className={classNames(
            "t-absolute t-inset-auto t-text-subtext-sm t-text-text",
            {
              "t-text-subtext-sm":
                props.size === "large" || props.size === "extra-large",
              "t-text-[8px]": props.size === "regular",
              "t-text-[2px]":
                props.size === "small" || props.size === "extra-small",
            }
          )}
        >
          {label}
        </div>
      </div>
    </button>
  );
};
