export const FREE_PLAN = "Free Trial";
export const STANDARD_PLAN = "Standard Plan";
export const PRO_PLAN = "Pro Plan";
export const DISCOUNTED_PRO_PLAN = "Discounted Pro Plan";
export const BOOKS_PRO = "Bookkeeping - Pro";
export const STANDARD = "STANDARD";
export const PRO = "PRO";
export const BYOA = "BYOA";
export const PAYG = "PAYG";
export const FREE = "FREE";
export const UNSUBSCRIBED = "UNSUBSCRIBED";
export const OVERDUE = "OVERDUE";
export const PAYG_BOOKKEEPING = "PAYG_BOOKKEEPING";
export const NOT_STARTED = "NOT STARTED";
export const DIY_BOOKKEEPING = "DIY_BOOKKEEPING";
export const RA = "RA";
export const MAILROOM = "MAILROOM";
export const BOOKS_PLUS_PLAN = "BOOKS_PLUS_PLAN";
export const MONTHLY_BOOKKEEPING = "MONTHLY_BOOKKEEPING";
export const MONTHLY_BOOKKEEPING_PLUS = "MONTHLY_BOOKKEEPING_PLUS";
