import DashboardContainer from "components/dashboard/DashboardContainer";
import { Button } from "components/DesignSystem/Button/Button";
import { KeyDownWrapper } from "components/DesignSystem/KeydownWrapper/KeydownWrapper";
import { Slider } from "components/DesignSystem/Slider/Slider";
import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import Tab from "components/DesignSystem/Tab/Tab";
import { Cross } from "components/icons/Cross";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useDocPreview } from "hooks/useDocPreview";
import { useState } from "react";
import { MailroomType, useGetMailroomDetailQuery } from "store/apis/mailroom";
import { FileIcon } from "utils/fileTypeIcon";

export const TypeOfMailroom = ({ type }: { type: MailroomType }) => {
  switch (type) {
    case "REGISTERED_AGENT": {
      return (
        <div className="t-px-2 t-py-1 t-rounded t-bg-orange-20 t-text-orange-80 t-text-body-sm">
          Registered Agent
        </div>
      );
    }
    case "MAILROOM": {
      return (
        <div className="t-px-2 t-py-1 t-rounded t-bg-blue-0 t-text-blue-70 t-text-body-sm">
          Mailroom
        </div>
      );
    }
    default:
      return <></>;
  }
};

export const MailroomSlider = ({
  showMailroomSlider,
  mailroomId,
  groupId,
  closeMailroomSlider,
}: {
  showMailroomSlider: boolean;
  mailroomId: string;
  groupId: string;
  closeMailroomSlider: () => void;
}) => {
  const openPreview = useDocPreview();
  const [silderAccordionValue, setSliderAccordionValue] =
    useState("ATTACHMENTS");

  const { data: mailroomDetail } = useGetMailroomDetailQuery(
    { groupId, mailId: mailroomId },
    { skip: !groupId || !mailroomId, refetchOnMountOrArgChange: true }
  );

  return (
    <Slider
      open={showMailroomSlider}
      className="t-absolute t-w-[360px] !t-p-0 t-shadow-slider !t-h-full t-z-sidebar"
      position="right"
      width={360}
    >
      <Tab.Root className="t-h-full">
        <DashboardContainer className="t-h-full">
          <DashboardContainer.Header>
            <div className="t-py-3 t-pr-4 t-pl-5 t-flex t-justify-between t-items-center t-border-b t-border-0 t-border-solid t-border-i-neutral-10">
              <span className="t-text-subtitle">
                {mailroomDetail?.received_from}
              </span>
              <Button
                customType="ghost_icon"
                size="small"
                onClick={closeMailroomSlider}
              >
                <Cross />
              </Button>
            </div>
          </DashboardContainer.Header>
          <DashboardContainer.Content
            className="t-p-4 t-bg-text t-flex t-flex-col t-gap-4"
            id="media-scroll"
          >
            <div className="t-border t-border-solid t-border-neutral-0 t-rounded-lg t-flex t-flex-wrap t-gap-3 t-p-4">
              <div className="t-w-2/5">
                <div className="t-text-body-sm t-text-text-30">Date</div>
                <div className="t-text-subtext t-text-text-100">
                  {dayjs(mailroomDetail?.date_received).format(DD_MMM_YYYY)}
                </div>
              </div>
              <div className="t-w-2/5">
                <div className="t-text-body-sm t-text-text-30">Receiver</div>
                <div className="t-text-subtext t-text-text-100">
                  {mailroomDetail?.sent_to}
                </div>
              </div>
              <div className="t-w-fit">
                <div className="t-text-body-sm t-text-text-30 t-mb-1">Type</div>
                <div>
                  <TypeOfMailroom type={mailroomDetail?.type as MailroomType} />
                </div>
              </div>
            </div>
            <Accordion.Root
              type="single"
              collapsible
              defaultValue="ATTACHMENTS"
              value={silderAccordionValue}
              onValueChange={setSliderAccordionValue}
              className="t-flex t-gap-4 t-flex-col"
            >
              <Accordion.Item value="ATTACHMENTS">
                <Accordion.Trigger>Attachment</Accordion.Trigger>
                <Accordion.Content>
                  <span>
                    <KeyDownWrapper>
                      <div className="t-flex t-flex-col t-gap-3">
                        {mailroomDetail?.document && (
                          <div
                            className="t-flex t-gap-2 t-items-end t-items-center t-border t-border-solid t-px-3 t-py-1 t-rounded t-border-neutral-10"
                            onClick={() =>
                              openPreview(mailroomDetail.document.uuid)
                            }
                            role="button"
                          >
                            <FileIcon
                              fileType={mailroomDetail.document.file_type}
                              width="24px"
                              height="24px"
                            />
                            <div className="t-text-body-sm t-text-text-100">
                              {mailroomDetail.document.name}
                            </div>
                          </div>
                        )}
                      </div>
                    </KeyDownWrapper>
                  </span>
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </DashboardContainer.Content>
        </DashboardContainer>
      </Tab.Root>
    </Slider>
  );
};
