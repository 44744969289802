export const TRANSACTION_COLUMNS = {
  SELECT: "SELECT",
  DATE: "DATE",
  FROM: "FROM",
  VENDOR: "VENDOR",
  DESCRIPTION: "DESCRIPTION",
  AMOUNT: "AMOUNT",
  CATEGORY: "CATEGORY",
  OPTIONS: "OPTIONS",
  INVOICE: "INVOICE",
  COMMENTS: "COMMENTS",
} as const;

export const TRANSACTION_COLUMNS_OPTIONS = [
  {
    label: "Date",
    value: TRANSACTION_COLUMNS.DATE,
  },
  {
    label: "Source",
    value: TRANSACTION_COLUMNS.FROM,
  },
  {
    label: "To/From",
    value: TRANSACTION_COLUMNS.VENDOR,
  },
  {
    label: "Description",
    value: TRANSACTION_COLUMNS.DESCRIPTION,
  },
  {
    label: "Amount",
    value: TRANSACTION_COLUMNS.AMOUNT,
  },
  {
    label: "Category",
    value: TRANSACTION_COLUMNS.CATEGORY,
  },
  {
    label: "Invoice",
    value: TRANSACTION_COLUMNS.INVOICE,
  },
  {
    label: "Comments",
    value: TRANSACTION_COLUMNS.COMMENTS,
  },
];

export const FIXED_TRANSACTION_COLUMNS: (keyof typeof TRANSACTION_COLUMNS)[] = [
  TRANSACTION_COLUMNS.SELECT,
  TRANSACTION_COLUMNS.DATE,
  TRANSACTION_COLUMNS.AMOUNT,
  TRANSACTION_COLUMNS.CATEGORY,
  TRANSACTION_COLUMNS.OPTIONS,
];
