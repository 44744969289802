import { Seperator } from "components/icons/Chat/Seperator";
import { USER_PROFILE } from "constants/userTypes";
import dayjs from "dayjs";
import { forwardRef, Ref } from "react";
import { Comment } from "types/Models/books";

export const NoteCard = forwardRef(
  ({ note }: { note: Comment }, ref: Ref<HTMLDivElement>) => {
    return (
      <div
        className="t-flex t-flex-col t-gap-3 t-p-3 t-border t-border-solid t-border-neutral-0 t-rounded"
        ref={ref}
      >
        <div className="t-text-body">{note.description}</div>
        <div className="t-flex t-items-center t-gap-2">
          <div className="t-flex t-gap-1 t-items-center">
            <img
              src={note.created_by.profile_url || USER_PROFILE}
              alt="user"
              height="16px"
              width="16px"
              className="t-rounded-full t-text-caption"
            />
            <div className="t-text-body-sm t-text-text-60">
              {note.created_by?.name}
            </div>
          </div>
          <Seperator />
          <div className="t-text-body-sm t-text-text-30">
            {dayjs(note.created_at).format("MMM DD")} at{" "}
            {dayjs(note.created_at).format("h:mm a")}
          </div>
        </div>
      </div>
    );
  }
);
