import React from "react";

export const Fire = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.2397 14.3757C17.0452 15.462 16.5226 16.4626 15.7422 17.2429C14.9618 18.0231 13.961 18.5455 12.8747 18.7398C12.8334 18.7464 12.7918 18.7498 12.75 18.7501C12.5619 18.75 12.3806 18.6793 12.2422 18.5519C12.1038 18.4244 12.0183 18.2497 12.0028 18.0622C11.9872 17.8747 12.0426 17.6882 12.1581 17.5397C12.2736 17.3912 12.4407 17.2915 12.6262 17.2604C14.1797 16.9988 15.4978 15.6807 15.7612 14.1245C15.7946 13.9283 15.9045 13.7534 16.0667 13.6382C16.229 13.5231 16.4304 13.4771 16.6266 13.5104C16.8227 13.5437 16.9976 13.6536 17.1128 13.8159C17.228 13.9782 17.2739 14.1795 17.2406 14.3757H17.2397ZM20.25 13.5001C20.25 15.6881 19.3808 17.7865 17.8336 19.3337C16.2865 20.8809 14.188 21.7501 12 21.7501C9.81196 21.7501 7.71354 20.8809 6.16637 19.3337C4.61919 17.7865 3.75 15.6881 3.75 13.5001C3.75 10.8826 4.78125 8.20603 6.81188 5.5454C6.87615 5.46116 6.95755 5.39149 7.05071 5.34099C7.14387 5.29048 7.24667 5.2603 7.35235 5.25241C7.45802 5.24453 7.56417 5.25913 7.66379 5.29525C7.76341 5.33137 7.85425 5.38819 7.93031 5.46197L10.1916 7.65665L12.2541 1.99322C12.2953 1.88026 12.363 1.77887 12.4516 1.69758C12.5402 1.6163 12.6471 1.55751 12.7632 1.52617C12.8792 1.49483 13.0011 1.49186 13.1186 1.5175C13.2361 1.54315 13.3457 1.59666 13.4381 1.67353C15.4884 3.37509 20.25 7.92665 20.25 13.5001ZM18.75 13.5001C18.75 9.17915 15.3947 5.44509 13.2928 3.53165L11.205 9.25697C11.1621 9.37456 11.0906 9.47956 10.9967 9.56239C10.9029 9.64523 10.7899 9.70327 10.6679 9.73123C10.5459 9.75919 10.4188 9.75618 10.2983 9.72246C10.1778 9.68875 10.0676 9.62541 9.97781 9.53822L7.50562 7.14009C6.00844 9.30103 5.25 11.4376 5.25 13.5001C5.25 15.2903 5.96116 17.0072 7.22703 18.2731C8.4929 19.5389 10.2098 20.2501 12 20.2501C13.7902 20.2501 15.5071 19.5389 16.773 18.2731C18.0388 17.0072 18.75 15.2903 18.75 13.5001Z"
        fill="currentColor"
      />
    </svg>
  );
};
