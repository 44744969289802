export const Expand = () => (
  <>
    <span className="t-sr-only">Expand</span>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clip-path="url(#clip0_2733_10563)">
        <path
          d="M8.50583 12.6725L2.845 18.3333H6.66667V20H1.66667C0.7475 20 0 19.2525 0 18.3333V13.3333H1.66667V17.155L7.3275 11.4942L8.50583 12.6725ZM18.3333 0H13.3333V1.66667H17.155L11.4942 7.3275L12.6725 8.50583L18.3333 2.845V6.66667H20V1.66667C20 0.7475 19.2525 0 18.3333 0Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2733_10563">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </>
);
