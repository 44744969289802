import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik, FormikValues } from "formik";
import { addressAutofill } from "formValidations/autofillSchema";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { AddressFormFields } from "components/AddressFormFields/AddressFormFields";
import Modal from "components/DesignSystem/Modal/Modal";

type AddressModalProps = {
  isLoading: boolean;
  open: boolean;
  onSubmit: (v: FormikValues) => void;
  onClose: () => void;
  initialValues: any;
};

export const AddressModal = ({
  isLoading,
  open,
  onSubmit,
  onClose,
  initialValues,
}: AddressModalProps) => {
  const { id } = initialValues;
  const localInitialValues = id
    ? initialValues
    : {
        city: "",
        country: "",
        state: "",
        street_address: "",
        street_address_line_2: "",
        zipcode: "",
      };

  return (
    <Modal.Root open={open} onOpenChange={onClose}>
      {open && (
        <Formik
          initialValues={localInitialValues}
          onSubmit={onSubmit}
          validationSchema={addressAutofill}
          validateOnChange={false}
          validateOnBlur
        >
          {({ submitForm }) => (
            <Modal.Content asChild>
              <Form>
                <Modal.Header>
                  <Modal.Title>{id ? "Edit" : "Add"} Address</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <div className="t-flex t-flex-col t-gap-4">
                    {<AddressFormFields />}
                  </div>
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Button
                    type="reset"
                    customType="secondary"
                    size="regular"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="button"
                    onClick={submitForm}
                    customType="primary"
                    size="regular"
                    isLoading={isLoading}
                    disabled={isLoading}
                  >
                    Save
                  </Button>
                </Modal.FooterButtonGroup>
              </Form>
            </Modal.Content>
          )}
        </Formik>
      )}
    </Modal.Root>
  );
};
