import { AmountSuperScript } from "components/design/AmountSuperScript";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useFormikContext } from "formik";
import { useEffect, useMemo } from "react";

export const CalculatedAmountForAnnualCatchup = ({
  currentSeason,
  calculatePrice,
}: {
  currentSeason: number;
  calculatePrice?: () => void;
}) => {
  const { values, setFieldValue } = useFormikContext<{
    from_date: string | null;
    to_date: string | null;
    accounting_method?: string;
    season: number;
    payment_amount: number;
  }>();

  const fromDate = useMemo(
    () =>
      dayjs().year(Number(currentSeason)).startOf("year").format(YYYY_MM_DD),
    [currentSeason]
  );

  const toDate = useMemo(
    () => dayjs().year(Number(currentSeason)).endOf("year").format(YYYY_MM_DD),
    [currentSeason]
  );

  useEffect(() => {
    if (fromDate && toDate && currentSeason) {
      setFieldValue("from_date", fromDate);
      setFieldValue("to_date", toDate);
      setFieldValue("season", currentSeason);
    }
  }, [fromDate, toDate, values.accounting_method]);

  useEffect(() => {
    if (values.from_date && values.to_date && values.accounting_method) {
      calculatePrice?.();
    }
  }, [values.from_date, values.to_date, values.accounting_method]);

  return (
    <div>
      <p className="t-text-subtitle">
        <p className="t-text-subtitle">
          <AmountSuperScript amount={values.payment_amount} />{" "}
        </p>
      </p>
      <div className="t-text-text-30 t-text-body">
        Price is estimated basis number of transactions <br />
        ($199 upto 100 transaction + $1 per transaction)
      </div>
    </div>
  );
};
