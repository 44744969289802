export const DocumentsIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_541_4994)">
        <path
          d="M14.25 3.75065H9.13767L6.80433 2.58398H3.75C3.28587 2.58398 2.84075 2.76836 2.51256 3.09655C2.18437 3.42474 2 3.86986 2 4.33398L2 15.4173H16V5.50065C16 5.03652 15.8156 4.5914 15.4874 4.26321C15.1592 3.93503 14.7141 3.75065 14.25 3.75065ZM3.75 3.75065H6.529L8.86233 4.91732H14.25C14.4047 4.91732 14.5531 4.97878 14.6625 5.08817C14.7719 5.19757 14.8333 5.34594 14.8333 5.50065V6.01457L3.16667 6.08048V4.33398C3.16667 4.17927 3.22812 4.0309 3.33752 3.92151C3.44692 3.81211 3.59529 3.75065 3.75 3.75065ZM3.16667 14.2507V7.24715L14.8333 7.18123V14.2507H3.16667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_541_4994">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
