import { object, string } from "yup";

export const taxCalculateSchema = object({
  seller_address: object({
    country: string().required("Country is required"),
    city: string().required("city is required"),
    address1: string().required("Address is required"),
    state: string().required("state is required"),
    zipCode: string().required("ZipCode is required"),
    uuid: string().required("Seller Address UUID is required"),
  }),
  buyer_address: object({
    country: string().required("Country  is required"),
    city: string().required("city is required"),
    address1: string().required("Address is required"),
    state: string().required("state is required"),
    zipCode: string().required("ZipCode is required"),
    uuid: string().required("Buyer Address UUID is required"),
  }),
  product_detail: object({
    uuid: string().required("Product Code is required"),
    tax_code: string().required("TaxCode is required"),
  }),
});
