import React, { ComponentProps } from "react";
import Modal from "components/DesignSystem/Modal/Modal";
import { LockSecure } from "components/icons/LockSecure";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import {
  ConnectionsModal,
  ConnectionsModalProps,
} from "components/Connections/ConnectionsModal";
import { useBankConnect } from "hooks/useBankConnect";
import ReactCountryFlag from "react-country-flag";
import { Button } from "components/DesignSystem/Button/Button";
import { ArrowRight } from "components/icons/ArrowRight";
import { OtherBankingInstitution } from "components/Connections/OtherBankingInstitution";
import { useModal } from "hooks/useModal";
import { useUpdateQuery } from "hooks/useQuery";
import { AddManualBank } from "components/Connections/AddManualBank";
import { Entity } from "types/Models/entity";

type ConnectDataSourcesRootProps = {
  isOpen: boolean;
  closeConnectionModal: () => void;
  onConnectSuccess: () => void;
};

type SingleEntityProps = {
  entity: Entity;
  onEntitySelect: (entity: Entity) => void;
};

const AnimatedConnectionModalContent = ({
  onConnect,
  onComplete,
  onManualBankAddSuccess,
  entityIdFromParent,
}: ConnectionsModalProps &
  Pick<
    ComponentProps<typeof OtherBankingInstitution>,
    "onManualBankAddSuccess" | "onComplete"
  >) => {
  return (
    <section className="t-animate-height-scale-up">
      <ConnectionsModal
        onConnect={onConnect}
        onComplete={onComplete}
        onManualBankAddSuccess={onManualBankAddSuccess}
        entityIdFromParent={entityIdFromParent}
      />
    </section>
  );
};

const SingleEntity = ({ entity, onEntitySelect }: SingleEntityProps) => {
  return (
    <section
      data-testid="select_entity"
      onClick={() => onEntitySelect(entity)}
      className="t-border t-border-solid t-border-neutral-0 t-p-3 t-py-4 t-flex t-justify-between t-items-center t-w-full t-min-h-19 t-text-text-30 t-rounded-md t-cursor-pointer"
    >
      <div className="t-flex t-gap-3 t-items-center">
        <div className="t-self-start"></div>
        <ReactCountryFlag
          className="!t-w-5 !t-h-3 t-rounded-sm t-object-cover"
          countryCode={entity?.country_code}
          svg
          title={entity?.country}
        />
        <div>
          <p className="t-mb-1 t-text-[#110733]">{entity?.name}</p>
        </div>
      </div>
      <Button size="small">
        Select <ArrowRight color="currentColor" />
      </Button>
    </section>
  );
};

const ConnectDataSourcesRoot = ({
  isOpen,
  closeConnectionModal,
  onConnectSuccess,
}: ConnectDataSourcesRootProps) => {
  const { entities } = useCurrentGroupContext();
  const { onConnect } = useBankConnect({});
  const [selectedEntity, setSelectedEntity] = React.useState<string | null>(
    null
  );
  const otherBankingInstitutionModal = useModal();
  const { update } = useUpdateQuery();

  const onEntitySelect = (entity: Entity) => {
    setSelectedEntity(entity?.uuid);
    update({ query: "entity", value: entity?.uuid });
  };

  const onClose = () => {
    setSelectedEntity(null);
    closeConnectionModal();
  };

  const handlePlaidSuccess = () => {
    onConnectSuccess?.();
    otherBankingInstitutionModal.close();
    const localentity = localStorage.getItem("plaid_entity_id");
    update({
      query: "entity",
      value: localentity,
    });
    localStorage.removeItem("plaid_entity_id");
  };

  const manualSuccessSet = ({
    entityId,
  }: Parameters<
    NonNullable<ComponentProps<typeof AddManualBank>["onSuccess"]>
  >[0]) => {
    update({
      query: "entity",
      value: entityId,
    });
  };

  return (
    <section>
      <Modal.Root open={isOpen} onOpenChange={onClose}>
        <Modal.Content size="large">
          <Modal.Header>
            <Modal.Title>Connect Data Sources</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <section>
              {selectedEntity ? (
                <AnimatedConnectionModalContent
                  onConnect={onConnect}
                  onComplete={handlePlaidSuccess}
                  onManualBankAddSuccess={manualSuccessSet}
                  entityIdFromParent={selectedEntity}
                />
              ) : (
                <section>
                  <p className="t-text-text-30 t-text-body-md">
                    Select the entity you want to connect data sources for:
                  </p>
                  <section className="t-flex t-flex-col t-gap-4">
                    {entities?.map((entity) => (
                      <SingleEntity
                        key={entity.uuid}
                        entity={entity}
                        onEntitySelect={onEntitySelect}
                      />
                    ))}
                  </section>
                </section>
              )}
            </section>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-items-center t-gap-3">
              <div>
                <LockSecure />
              </div>
              <p className="t-m-0 t-text-text-30 t-text-body-sm">
                Inkle connects your account securely in compliance with industry
                standards. Inkle will only have read-only access to
              </p>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </section>
  );
};

export default ConnectDataSourcesRoot;
