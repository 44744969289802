import { createContext, HTMLAttributes, useContext } from "react";
import cx from "classnames";

type RootProps = HTMLAttributes<HTMLDivElement> & {
  variant?: "default" | "secondary";
  shadow?: boolean;
  borderRounded?: "sm" | "lg";
};

const Context = createContext<Pick<RootProps, "shadow" | "variant">>({
  variant: "default",
  shadow: false,
});

const Provider = Context.Provider;

const Root = ({
  variant = "default",
  borderRounded = "sm",
  shadow = false,
  ...rest
}: RootProps) => {
  return (
    <Provider value={{ variant }}>
      <div
        {...rest}
        className={cx(
          "t-flex t-flex-col t-border t-border-solid t-border-neutral-10 t-overflow-auto t-relative",
          rest.className || "",
          {
            "t-px-4": variant === "default",
            "t-shadow-light": shadow,
            "t-rounded": borderRounded === "sm",
            "t-rounded-lg": borderRounded === "lg",
          }
        )}
      />
    </Provider>
  );
};

const Header = (props: HTMLAttributes<HTMLDivElement>) => {
  const { variant } = useContext(Context);
  return (
    <div
      {...props}
      className={cx(
        "t-flex t-flex-shrink-0 t-items-center t-justify-between t-border-0 t-border-b t-border-solid t-border-b-neutral-10 t-sticky t-top-0 t-bg-surface t-z-header",
        props.className || "",
        {
          "t-pb-3 t-pt-4": variant === "default",
          "t-px-6 t-py-4": variant === "secondary",
        }
      )}
    />
  );
};

const Title = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={cx("t-text-subtext t-text-text-100", props.className || "")}
    />
  );
};

const Body = (props: HTMLAttributes<HTMLDivElement>) => {
  const { variant } = useContext(Context);

  return (
    <div
      {...props}
      className={cx(props.className || "", {
        "t-mt-5": variant === "default",
        "t-p-6": variant === "secondary",
      })}
    />
  );
};

const Card = {
  Root,
  Header,
  Title,
  Body,
};

export default Card;
