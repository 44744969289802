import { ConnectionsModal } from "components/Connections/ConnectionsModal";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { useAppSelector } from "hooks/useAppSelector";
import { useBankConnect } from "hooks/useBankConnect";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import BankCircular from "static/images/BankCircular.svg";
import CheckCircle from "static/images/CheckCircle.svg";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";

export const AccountCard = ({ logo, bankName, accountName, mask }: any) => {
  return (
    <div className="t-border t-shadow-entity-card t-rounded-lg t-border-solid t-border-[#0000001a] t-flex t-items-center t-justify-between t-gap-2.5 t-p-5">
      <div className="t-flex t-items-center t-gap-3">
        <img
          src={logo || BankCircular}
          className="t-w-8 t-rounded-full"
          alt=""
        />
        <div className="t-max-w-[126px] t-text-body t-text-i-primary-100 t-truncate">
          <span>{accountName}</span>
        </div>
        <div className="t-flex t-items-center t-gap-0.5">
          <div className="t-w-2 t-h-2 t-rounded-full t-bg-i-primary-100"></div>
          <div className="t-w-2 t-h-2 t-rounded-full t-bg-i-primary-100"></div>
          <span className="t-text-body-sm">{mask}</span>
        </div>
      </div>
      <img src={CheckCircle} alt="connected" className="t-h-6 t-w-6" />
    </div>
  );
};

export const ConnectBank = () => {
  const group = useCurrentGroupContext();
  const groupId = group?.uuid || "";
  const entityIdOnSelect = useAppSelector(
    (state) => state.onboardingWidget.entityId
  );
  const entityId = useCurrentEntityId() || entityIdOnSelect!;

  const { data: ledger } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );
  const { accounts = [] } = ledger || {};
  const { onConnect } = useBankConnect();

  return (
    <div className="t-max-w-[652px] t-flex t-flex-col t-items-center t-gap-6 t-mx-auto t-p-3">
      <div className="t-flex t-flex-col t-w-full t-gap-5">
        <div className="t-flex t-flex-col t-gap-2">
          <div className="t-text-title-h2-bold t-text-text-100">
            Connect data sources
          </div>
          <div className="t-text-body-sm t-text-text-30">
            Inkle Books natively integrates with Brex & Mercury. We use Plaid to
            connect with all other banking institutions.
          </div>
        </div>
        <div className="t-w-2/5">
          <EntitySelector showOnlyUSEntities />
        </div>
        <div className="t-flex t-flex-col t-gap-3">
          <div className="t-text-subtitle-sm">Banking Institutions</div>
          <ConnectionsModal showEntityLabel={false} onConnect={onConnect} />
        </div>
        {accounts.length > 0 && (
          <div className="t-flex t-flex-col">
            <div className="t-text-subtitle-sm">Connected Accounts</div>
            <div className="t-mt-4 t-mb-3 t-flex t-flex-col t-gap-3 t-max-h-72 t-overflow-y-auto">
              {accounts.map((account) => (
                <AccountCard
                  key={account.account.uuid}
                  mask={account.account.mask}
                  bankName={account.bank_brand.name}
                  accountName={account.account.nickname}
                  logo={account.bank_brand.logo}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
