export const MegaPhone = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_7001_3446)">
        <path
          d="M0.8585 7.85L2.925 12.5H4.153C4.38211 12.4999 4.60757 12.4425 4.8089 12.3332C5.01024 12.2238 5.18109 12.066 5.30595 11.8739C5.4308 11.6818 5.50571 11.4615 5.52389 11.2331C5.54206 11.0047 5.50291 10.7754 5.41 10.566L4.2695 8H5.5C6.1628 8.00079 6.79822 8.26444 7.26689 8.73311C7.73556 9.20178 7.99921 9.8372 8 10.5H9V0.5H8C7.99921 1.1628 7.73556 1.79822 7.26689 2.26689C6.79822 2.73556 6.1628 2.99921 5.5 3H1.5C1.10218 3 0.720644 3.15804 0.43934 3.43934C0.158035 3.72064 0 4.10218 0 4.5L0 6.5C0.0011155 6.7831 0.0823264 7.06011 0.234245 7.29901C0.386164 7.5379 0.602588 7.72893 0.8585 7.85ZM4.496 10.9715C4.52151 11.0287 4.53232 11.0913 4.52744 11.1538C4.52256 11.2162 4.50215 11.2764 4.46806 11.3289C4.43397 11.3814 4.38729 11.4246 4.33227 11.4545C4.27724 11.4844 4.21562 11.5 4.153 11.5H3.575L2.0195 8H3.175L4.496 10.9715ZM1 4.5C1 4.36739 1.05268 4.24021 1.14645 4.14645C1.24021 4.05268 1.36739 4 1.5 4H5.5C5.96611 4.00011 6.4275 3.90676 6.85691 3.72546C7.28631 3.54417 7.675 3.27861 8 2.9445V8.0555C7.675 7.72139 7.28631 7.45583 6.85691 7.27454C6.4275 7.09324 5.96611 6.99989 5.5 7H1.5C1.36739 7 1.24021 6.94732 1.14645 6.85355C1.05268 6.75979 1 6.63261 1 6.5V4.5ZM11.1465 8.3535L10.0025 7.2095L10.7095 6.5025L11.8535 7.6465L11.1465 8.3535ZM10.7285 4.4785L10.0215 3.7715L11.1465 2.6465L11.8535 3.3535L10.7285 4.4785ZM10.5 5H12V6H10.5V5Z"
          fill="#A09CAD"
        />
      </g>
      <defs>
        <clipPath id="clip0_7001_3446">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
