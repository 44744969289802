import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import { Vendors as VendorsPage } from "../BillsAndVendors/Vendors";

export const Vendors = () => {
  return (
    <DashboardLayout
      header={
        <div className="t-sticky t-top-0">
          <Header v2 title="Vendors" />
        </div>
      }
    >
      <VendorsPage />
    </DashboardLayout>
  );
};
