import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { BILLING_INFO_ADDRESS_EDITED } from "constants/analyticsEvents";
import { Form, Formik } from "formik";
import { billingAddressSchema } from "formValidations/billingInfo";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import React, { useContext, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useSelector } from "react-redux";
import {
  useCreateAutofillMutation,
  useGetAutofillQuery,
  useUpdateAutofillMutation,
} from "store/apis/autofill";
import {
  useGetBillingInfoQuery,
  usePatchBillingEmailMutation,
} from "store/apis/billing";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import { RootState } from "store/store";
import BillingAddressAutofill from "./BillingAddressAutofill";
import { SelectAutofillCombobox } from "components/SelectAutofillCombobox";

export type BillingAddress = {
  country: string;
  street_address: string;
  street_address_line_2: string | null;
  state: string;
  city: string;
  zipcode: string;
  entity_id?: string;
  uuid?: string;
};

const EditBillingAddress = ({
  show,
  closeModal,
  currentBillingAddress,
  tagId,
  entityId,
}: {
  show: boolean;
  closeModal: () => void;
  currentBillingAddress: BillingAddress;
  tagId: string;
  isPortalOptional?: boolean;
  entityId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const {
    authtoken: { email },
  } = useContext(authContext);
  const { data: countries = [] } = useGetCountriesDataQuery();
  const [autofillAddress, setAutofillAddress] = useState({
    ...currentBillingAddress,
  });
  const [isAutofillAddress, setIsAutofillAddress] = useState(false);
  const [createAutofill] = useCreateAutofillMutation();
  const [updateAutofill] = useUpdateAutofillMutation();
  const { trackEvent } = useAnalytics();
  const { activeChannelGroupId } = useSelector(
    (state: RootState) => state.reviewAndBalancePayment
  );

  const { data: addressAutofills = [] } = useGetAutofillQuery(
    {
      groupId,
      autofillKey: "addresses",
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const group = useCurrentGroup();

  const { data: billingInfo } = useGetBillingInfoQuery(
    {
      group_id: group?.uuid!,
      entityId,
    },
    { skip: !group?.uuid || !entityId }
  );

  const [editBillingInfo] = usePatchBillingEmailMutation();

  const handleSubmit = async (
    values: { invoice_emails: string[] } & BillingAddress
  ) => {
    try {
      await editBillingInfo({
        invoice_emails: values.invoice_emails,
        group_id: groupId || activeChannelGroupId,
        entityId,
      }).unwrap();

      const autofill = addressAutofills.find((a) => a.uuid === values.uuid);

      await updateAutofill({
        entityId,
        fields: {
          ...autofill,
          tag_id: tagId,
          group_entity_id: entityId,
        },
        groupId: groupId || activeChannelGroupId,
        // @ts-ignore
        uuid: autofill?.uuid,
        autofillKey: "addresses",
      }).unwrap();
      successToast({ message: "Your changes has been saved" });
      trackEvent(BILLING_INFO_ADDRESS_EDITED, {
        group_id: groupId || activeChannelGroupId,
        email: email,
      });
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Formik
        validateOnChange={false}
        enableReinitialize
        initialValues={{
          ...autofillAddress,
          invoice_emails: billingInfo?.invoice_emails || [],
        }}
        onSubmit={handleSubmit}
        validationSchema={billingAddressSchema}
      >
        {({
          isSubmitting,
          isValid,
          values,
          dirty,
          submitForm,
          setFieldValue,
        }) => {
          return (
            <Form className="t-m-0">
              <Modal.Content useCustomOverlay>
                <Modal.Header>
                  <Modal.Title>Bill to</Modal.Title>
                  <Modal.Close type="button" />
                </Modal.Header>

                <Modal.Body className="t-overflow-auto t-flex t-flex-col t-gap-4">
                  <Combobox
                    placeholder="Add emails"
                    formatCreateLabel={(inputText) => `Add ${inputText}`}
                    isMulti
                    label="Billing emails"
                    withForm
                    name="invoice_emails"
                    creatable
                    components={{
                      DropdownIndicator: () => null,
                      NoOptionsMessage: () => null,
                    }}
                    onCreateOption={(value) =>
                      setFieldValue("invoice_emails", [
                        ...(values.invoice_emails || []),
                        value,
                      ])
                    }
                    value={values.invoice_emails?.map((i) => ({
                      label: i,
                      value: i,
                    }))}
                    menuPortalTarget={document.body}
                  />

                  <SelectAutofillCombobox
                    withForm
                    type="addresses"
                    selected={values.uuid}
                    name="uuid"
                    entityId={entityId}
                    label="Address"
                    onChange={(newValue) => setFieldValue("uuid", newValue)}
                    srollToSave
                  />
                </Modal.Body>
                <Modal.Footer>
                  <div className="t-flex t-justify-end t-gap-3">
                    <Button type="button" onClick={closeModal}>
                      Cancel
                    </Button>
                    <Button
                      onClick={submitForm}
                      customType="primary"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal.Content>
            </Form>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};

export default EditBillingAddress;
