import { BlockedButton } from "components/BlockedButton/BlockedButton";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Search } from "components/DesignSystem/Search/Search";
import { ArrowRight } from "components/icons/ArrowRight";
import { LockSecure } from "components/icons/LockSecure";
import { LinkBankAccount } from "components/LinkBankAccount";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import { CONNECT_BANK_ACCOUNT } from "constants/subscriptionPermissionFeatures";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { AccountCard } from "pages/Books/DataSources/DataSourcesList";
import React, { ComponentProps } from "react";
import { PlaidLinkOnSuccessMetadata } from "react-plaid-link";
import BankCircular from "static/images/BankCircular.svg";
import {
  Institution,
  useGetAllConnectionsQuery,
  useLazyGetPlaidInstitutionsQuery,
} from "store/apis/booksConnections";
import { debounce } from "utils/debouncing";
import { AddManualBank } from "./AddManualBank";
import {
  BankingConnections,
  BankingConnectionsProps,
  ConnectionsModalProps,
  RevenueConnections,
} from "./ConnectionsModal";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import Loader from "components/design/loader";
import { pluralize } from "utils/pluralize";
import { motion } from "framer-motion";

type OtherBankingInstitutionProps = {
  onManualBankAddSuccess?: ComponentProps<typeof AddManualBank>["onSuccess"];
  onComplete?: (connectedBanks: PlaidLinkOnSuccessMetadata) => void;
  entityId: string;
} & Pick<ConnectionsModalProps, "onConnect"> &
  Pick<BankingConnectionsProps, "onPlaidToDirectMigration">;

const PlaidConnection = ({
  institution,
  onComplete,
  entityId,
}: {
  institution: Institution;
  entityId: string;
} & Pick<OtherBankingInstitutionProps, "onComplete">) => {
  const { uuid: groupId } = useCurrentGroupContext();

  const base64ToUrl = (base64: string) => {
    return `data:image/png;base64, ${base64}`;
  };

  const handleStoreInLocal = ({ plaidCall }: { plaidCall: () => void }) => {
    if (document.body) {
      document.body.style.pointerEvents = "auto";
    }
    localStorage.setItem("plaid_entity_id", entityId);
    plaidCall();
  };

  return (
    <AccountCard
      logo={
        <Avatar
          src={institution.logo ? base64ToUrl(institution.logo) : ""}
          alt={institution.name}
        />
      }
      title={institution.name}
      description="Connect securely via Plaid"
      CTA={
        groupId && (
          <>
            <PermissionBasedUI
              errorMessage="You need to upgrade to Plus Plan to connect data sources."
              feature={CONNECT_BANK_ACCOUNT}
              blockedUI={<BlockedButton size="small">Connect</BlockedButton>}
            >
              <LinkBankAccount
                enableEntitySelection={false}
                groupId={groupId}
                onComplete={onComplete}
                entityId={entityId}
              >
                {({ ready, connectPlaid }) => (
                  <Button
                    size="small"
                    onClick={(e) =>
                      handleStoreInLocal({ plaidCall: () => connectPlaid(e) })
                    }
                    disabled={!ready}
                  >
                    Connect
                    <ArrowRight color="currentColor" />
                  </Button>
                )}
              </LinkBankAccount>
            </PermissionBasedUI>
          </>
        )
      }
    />
  );
};

const AccountCardItem = ({
  institution,
  onComplete,
  onConnect,
  onPlaidToDirectMigration,
  entityId,
}: {
  institution: Institution;
  entityId: string;
} & Pick<
  OtherBankingInstitutionProps,
  "onComplete" | "onConnect" | "onPlaidToDirectMigration"
>) => {
  const { uuid: groupId } = useCurrentGroupContext();

  const { data: connections } = useGetAllConnectionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId || !institution.connection_id }
  );

  const revenueConnections = connections?.filter(
    ({ connection_provider, uuid }) =>
      connection_provider === "STRIPE" && uuid === institution.connection_id
  );

  const bankingConnections = connections?.filter(
    ({ connection_provider, uuid }) =>
      connection_provider !== "STRIPE" && uuid === institution.connection_id
  );

  if (institution.connection_id) {
    return (
      <>
        {bankingConnections?.map((connection) => (
          <BankingConnections
            key={connection.uuid}
            connection={connection}
            onConnect={onConnect}
            onPlaidToDirectMigration={onPlaidToDirectMigration}
            entityId={entityId}
          />
        ))}

        {revenueConnections?.map((connection) => (
          <RevenueConnections
            key={connection.uuid}
            connection={connection}
            onConnect={onConnect}
            entityId={entityId}
          />
        ))}
      </>
    );
  }

  return (
    <PlaidConnection
      entityId={entityId}
      institution={institution}
      onComplete={onComplete}
    />
  );
};

export const OtherBankingInstitution = ({
  onManualBankAddSuccess,
  onComplete,
  onPlaidToDirectMigration,
  onConnect,
  entityId,
}: OtherBankingInstitutionProps) => {
  const addManualBankModal = useModal();
  const { uuid: groupId } = useCurrentGroupContext();
  const [searchTerm, setSearchTerm] = React.useState("");

  const [
    getPlaidInstitutions,
    { isFetching, isSuccess, data: institutions = [] },
  ] = useLazyGetPlaidInstitutionsQuery();

  const onChange = debounce(async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = e.target.value;
    setSearchTerm(searchTerm);
    if (searchTerm.length < 3) return;

    try {
      await getPlaidInstitutions({
        searchTerm,
        entityId,
        groupId,
      }).unwrap();
    } catch (error) {}
  });

  const isDirectConnection = institutions.find(
    (institution) => institution.connection_id
  );

  return (
    <>
      <Modal.Body>
        <div className="t-min-h-[522px] t-flex t-flex-col t-gap-4">
          <Search
            autoFocus
            id="search_bank"
            placeholder="Check if your bank/card is on Plaid"
            block
            customSize="large"
            onChange={onChange}
            className="t-shrink-0"
          />
          {!searchTerm && (
            <label
              className="t-text-text-30 t-text-subtext t-m-0"
              htmlFor="search_bank"
            >
              Enter at least 3 characters to start searching
            </label>
          )}
          <Async.Root
            isEmpty={institutions.length === 0 && Boolean(searchTerm)}
            isLoading={isFetching}
            isSuccess={isSuccess && Boolean(searchTerm)}
            customLoader={
              <div className="t-flex t-flex-col t-justify-center t-items-center t-gap-2 t-min-h-[350px]">
                <Loader size="small" />
                <p className="t-m-0 t-text-text-100 t-text-subtitle">
                  Searching
                </p>
              </div>
            }
          >
            <Async.Empty>
              <p className="t-text-text-30 t-text-subtext t-m-0">
                Couldn't find your bank on Plaid, add you bank/card details
                manually
              </p>
              <AccountCard
                logo={
                  <img
                    src={BankCircular}
                    className="t-rounded-full t-w-8 t-h-8"
                    alt="add bank"
                  />
                }
                title="Add bank/card"
                description="Enter bank account or credit card details"
                CTA={
                  <PermissionBasedUI
                    errorMessage="You need to upgrade to Plus Plan to connect data sources."
                    feature={CONNECT_BANK_ACCOUNT}
                    blockedUI={
                      <BlockedButton size="small">Add Details</BlockedButton>
                    }
                  >
                    <Button
                      size="small"
                      onClick={() => addManualBankModal.open()}
                    >
                      Add Details <ArrowRight color="currentColor" />
                    </Button>
                  </PermissionBasedUI>
                }
              />
            </Async.Empty>
            <Async.Success>
              {searchTerm && !isDirectConnection && (
                <p className="t-text-text-30 t-text-subtext t-m-0">
                  {pluralize(institutions.length, "bank", "banks")} found.
                  Please find them on Plaid and connect securely.
                </p>
              )}
              {institutions.map((institution, index) => (
                <motion.span
                  key={institution.name}
                  layout
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1, delay: 0.1 * index }}
                >
                  <AccountCardItem
                    key={institution.name}
                    institution={institution}
                    onComplete={onComplete}
                    onPlaidToDirectMigration={onPlaidToDirectMigration}
                    onConnect={onConnect}
                    entityId={entityId}
                  />
                </motion.span>
              ))}
            </Async.Success>
          </Async.Root>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="t-flex t-items-center t-gap-3">
          <div>
            <LockSecure />
          </div>
          <p className="t-m-0 t-text-text-30 t-text-body-sm">
            Inkle connects your account securely in compliance with industry
            standards. Inkle will only have read-only access to your
            transactions.
          </p>
        </div>
      </Modal.Footer>
      <AddManualBank
        onSuccess={onManualBankAddSuccess}
        isOpen={addManualBankModal.isOpen}
        close={addManualBankModal.close}
        entityId={entityId}
      />
    </>
  );
};
