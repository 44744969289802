import { useRoleBasedView } from "hooks/useRoleBasedView";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { debounce } from "utils/debouncing";
import { ADDTAG } from "../../constants/filterSelect";
import authContext from "../../jwt_context&axios/authContext";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Divider } from "components/design/Divider";
import {
  NOT_COMPLETED,
  NOT_CREATED,
  NOT_STARTED_KEY,
} from "constants/taskStatus";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { Button } from "components/DesignSystem/Button/Button";
import NewGroupCreationModal from "components/salesCrm/NewGroupEntityModal";
import AddCrmEntityTableModal from "components/salesCrm/AddCrmEntityTableModal";
import { DownloadIcon } from "components/icons/Download";
import { Search } from "components/DesignSystem/Search/Search";
import { parse } from "qs";

import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import { Chip } from "components/DesignSystem/Chips/Chips";
import {
  CRM_SUBSCRIPTION_TYPE_OPTIONS,
  CRM_SUBSCRIPTION_TYPES,
} from "dictionaries";
import { TASK_TEMPLATE_FILTER } from "types/contants/crm";
import { useModal } from "hooks/useModal";
import Modal from "components/DesignSystem/Modal/Modal";
import BroadcastIcon from "components/icons/Broadcast";
import { BroadcastMessage } from "components/BroadcastMessage/BroadcastMessage";
import { useFilters } from "hooks/useFilter";
import { formatDateByViewType } from "utils/formatDate";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { Field, Form, Formik } from "formik";
import dayjs from "dayjs";
import { YYYY_MM_DD } from "constants/date";

export default function ServiceTeamFilter({
  searchedTerm,
  tags,
  selectedSubscription,
  subscriptions,
  templates,
  setShowTagModal,
  selectedTag,
  currentPageItemCount,
  totalPageCount,
  totalItemCount,
  selectedAgent,
  assignMembers,
  usStates,
  usIncorporationMonths,
  selectedSubscriberType,
  sortDueBy,
  selectedTemplate,
  selectedLaunchedTemplate,
  update,
  paginationData,
  goToFirstPage,
  goToLastPage,
  goToNextPage,
  goToPrevPage,
  downloadCSV,
  isDownloadingCsv,
  selectedSalesperson,
  selectedStates,
  selectedIncorporationMonths,
  opsCrmFilters,
}) {
  const { authtoken } = useContext(authContext);
  const { isAdmin } = useRoleBasedView();
  const query = useQuery();
  const taskTemplateFilter = query.get(TASK_TEMPLATE_FILTER) || null;
  const [showProfileForm, setShowProfileForm] = useState(false);
  const [showEntityModal, setShowEntityModal] = useState(false);
  const [userDataPayload, setUserDataPayLoad] = useState();
  const [groupName, setGroupName] = useState("");
  const { isForeignCA, isUserTypeForeignCA } = useRoleBasedView();
  const showAddModal = query.get("addclient");
  const { update: updateQuery } = useUpdateQuery();
  const { search } = useLocation();
  const filters = parse(search, { ignoreQueryPrefix: true });
  const {
    open: openBroadcast,
    close: closeBroadcast,
    isOpen: isBroadcastOpen,
  } = useModal();

  const { values: dateFilter, updateFilter } = useFilters({
    initialValue: {
      SINCE_DATE: "",
    },
    useQueryParams: true,
  });

  useEffect(() => {
    if (showAddModal) {
      setShowProfileForm(true);
      updateQuery({ query: "addclient", value: null });
    }
  }, [showAddModal]);

  const searchQuery = debounce((e) => {
    const { value } = e.target;
    if (value) {
      update({ query: "search_term", value: e.target.value });
    } else {
      update({ query: "search_term", value: null });
    }
  });

  const tagsFilter = (e) => {
    const { value } = e.target;
    if (value === ADDTAG) {
      setShowTagModal(true);
      e.target.value = selectedTag;
    } else if (value) {
      update({ query: "tag_filter", value: value });
    } else {
      update({ query: "tag_filter", value: null });
    }
  };

  const templateFilter = (values) => {
    if (values) {
      update({ query: "not_launched_task_filter", value: values.join(",") });
    } else {
      update({ query: "not_launched_task_filter", value: null });
    }
  };

  const launchedTemplateFilter = (values) => {
    if (values) {
      update({ query: "launched_task_filter", value: values.join(",") });
    } else {
      update({ query: "launched_task_filter", value: null });
    }
  };

  const subscriptionFilter = (values) => {
    if (values) {
      update({ query: "subscription_type", value: values.join(",") });
    } else {
      update({ query: "subscription_type", value: null });
    }
  };

  const agentFilter = (values) => {
    if (values) {
      update({ query: "agent_filter", value: values.join(",") });
    } else {
      update({ query: "agent_filter", value: null });
    }
  };

  const salesPersonFilter = (values) => {
    if (values) {
      update({ query: "salesperson_filter", value: values.join(",") });
    } else {
      update({ query: "salesperson_filter", value: null });
    }
  };

  const usStateFilter = (values) => {
    if (values) {
      update({ query: "us_state_uuids", value: values.join(",") });
    } else {
      update({ query: "us_state_uuids", value: null });
    }
  };

  const usIncorporationMonthFilter = (values) => {
    if (values) {
      update({ query: "us_incorp_months", value: values.join(",") });
    } else {
      update({ query: "us_incorp_months", value: null });
    }
  };

  const subscriberFilter = (e) => {
    const { value } = e.target;
    if (value) {
      update({ query: "subscriber_filter", value: value });
    } else {
      update({ query: "subscriber_filter", value: null });
    }
  };

  const duesFilter = (e) => {
    const { value } = e.target;
    if (value) {
      update({ query: "sort_order", value: value });
    } else {
      update({ query: "sort_order", value: null });
    }
  };

  const taskFilter = (e) => {
    const { value } = e.target;
    if (value) {
      update({ query: TASK_TEMPLATE_FILTER, value: value });
    } else {
      update({ query: TASK_TEMPLATE_FILTER, value: null });
    }
  };

  const updateTagFilter = (values) => {
    if (values) {
      update({ query: "tag_filter", value: values.join(",") });
    } else {
      update({ query: "tag_filter", value: null });
    }
  };

  return (
    <>
      <div className="t-w-full">
        <div className="t-h-16 t-flex t-justify-between t-items-center t-border t-border-b t-border-l-0 t-border-r-0 t-border-t-0 t-border-solid t-border-neutral-10">
          <span className="t-flex t-items-center t-gap-3">
            <span className="t-flex t-items-center t-justify-center t-pl-6 t-text-title-h2-bold">
              Clients
            </span>
            <Search placeholder="Search" onChange={searchQuery} />
          </span>

          <div className="t-mr-4 t-flex t-items-center t-gap-2">
            <Pagination
              {...paginationData}
              goToFirstPage={goToFirstPage}
              goToPrevPage={goToPrevPage}
              goToNextPage={goToNextPage}
              goToLastPage={goToLastPage}
            />
            {isForeignCA && (
              <Button
                size="small"
                customType="primary"
                onClick={() => setShowProfileForm(true)}
              >
                + Add Client
              </Button>
            )}
          </div>
        </div>

        <div className="t-flex t-justify-between t-items-center t-mr-4">
          <div className="t-ml-4 t-py-2 t-flex-shrink-0">
            <Filter.Root
              defaultValue="TAG"
              title="Filter"
              capsule={
                <div className="t-flex t-gap-2 ">
                  {dateFilter.SINCE_DATE && (
                    <Chip
                      onClose={() => updateFilter("SINCE_DATE", "")}
                      isActive
                      filterType="BANK_DISCONNECTED_SINCE"
                    >
                      {dateFilter.SINCE_DATE && (
                        <>
                          Since{" "}
                          {formatDateByViewType(
                            dateFilter.SINCE_DATE,
                            "TOTALS"
                          )}
                        </>
                      )}
                    </Chip>
                  )}

                  {selectedAgent?.split(",").length > 0 && (
                    <Chip
                      onClose={() => agentFilter("")}
                      isActive
                      filterType="AGENT"
                    >
                      Agent ({selectedAgent.split(",").length})
                    </Chip>
                  )}

                  {selectedTemplate?.split(",").length > 0 && (
                    <Chip
                      onClose={() => templateFilter("")}
                      isActive
                      filterType="NOT_LAUNCHED_FILING"
                    >
                      Channel ({selectedTemplate.split(",").length})
                    </Chip>
                  )}

                  {selectedTag?.split(",").length > 0 && (
                    <Chip
                      onClose={() => updateTagFilter("")}
                      isActive
                      filterType="TAG"
                    >
                      Tag ({selectedTag.split(",").length})
                    </Chip>
                  )}

                  {selectedSalesperson?.split(",").length > 0 && (
                    <Chip
                      onClose={() => salesPersonFilter("")}
                      isActive
                      filterType="SALESPERSON"
                    >
                      Sales Person ({selectedSalesperson.split(",").length})
                    </Chip>
                  )}

                  {selectedStates?.split(",").length > 0 && (
                    <Chip
                      onClose={() => usStateFilter("")}
                      isActive
                      filterType="US_STATE"
                    >
                      US state ({selectedStates.split(",").length})
                    </Chip>
                  )}

                  {selectedIncorporationMonths?.split(",").length > 0 && (
                    <Chip
                      onClose={() => usIncorporationMonthFilter("")}
                      isActive
                      filterType="INCORP_MONTH"
                    >
                      Incorp Month (
                      {selectedIncorporationMonths.split(",").length})
                    </Chip>
                  )}
                </div>
              }
            >
              <Filter.Portal>
                <Filter.List>
                  {!isUserTypeForeignCA && (
                    <Filter.ListItem value="TAG">Tag</Filter.ListItem>
                  )}
                  {isAdmin && (
                    <Filter.ListItem value="AGENT">Agent</Filter.ListItem>
                  )}
                  {isAdmin && (
                    <Filter.ListItem value="SUBSCRIPTION">
                      Subscription
                    </Filter.ListItem>
                  )}
                  <Filter.ListItem value="NOT_LAUNCHED_FILING">
                    Not Launched Filing
                  </Filter.ListItem>
                  <Filter.ListItem value="LAUNCHED_FILING">
                    Launched Filing
                  </Filter.ListItem>
                  <Filter.ListItem value="SALESPERSON">
                    Sales Person
                  </Filter.ListItem>
                  <Filter.ListItem value="US_STATE">US State</Filter.ListItem>
                  <Filter.ListItem value="INCORP_MONTH">
                    Incorporation Month
                  </Filter.ListItem>
                  <Filter.ListItem value="BANK_DISCONNECTED_SINCE">
                    Bank disconnected since
                  </Filter.ListItem>
                </Filter.List>

                <Filter.Body value="BANK_DISCONNECTED_SINCE" block>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      SINCE_DATE: dateFilter.SINCE_DATE,
                    }}
                    onSubmit={() => {}}
                  >
                    <Form className="all:unset t-flex t-flex-col t-gap-4">
                      <Field name="SINCE_DATE">
                        {({ field }) => {
                          return (
                            <DateInput
                              {...field}
                              maxDate={new Date()}
                              label="Since"
                              onDateChange={(date) => {
                                updateFilter(
                                  "SINCE_DATE",
                                  dayjs(date).format(YYYY_MM_DD)
                                );
                              }}
                              portalId="callStart"
                            />
                          );
                        }}
                      </Field>
                    </Form>
                  </Formik>
                </Filter.Body>
                {isAdmin && (
                  <Filter.Body value="AGENT" block>
                    <MultiSelectFilter
                      onChange={agentFilter}
                      options={assignMembers?.map((assignee) => ({
                        value: assignee.profile_id,
                        label: assignee.name,
                      }))}
                      selected={selectedAgent?.split(",")}
                    />
                  </Filter.Body>
                )}

                {isAdmin && (
                  <Filter.Body value="NOT_LAUNCHED_FILING" block>
                    <MultiSelectFilter
                      onChange={templateFilter}
                      options={templates?.map((template) => ({
                        value: template.uuid,
                        label: `${template.title}  ${
                          !template.year ? "" : `- ${template.year}`
                        }`,
                      }))}
                      selected={selectedTemplate?.split(",")}
                    />
                  </Filter.Body>
                )}

                {isAdmin && (
                  <Filter.Body value="LAUNCHED_FILING" block>
                    <MultiSelectFilter
                      onChange={launchedTemplateFilter}
                      options={templates?.map((template) => ({
                        value: template.uuid,
                        label: `${template.title}  ${
                          !template.year ? "" : `- ${template.year}`
                        }`,
                      }))}
                      selected={selectedLaunchedTemplate?.split(",")}
                    />
                  </Filter.Body>
                )}

                {!isUserTypeForeignCA && (
                  <Filter.Body value="TAG" block>
                    {tags && (
                      <MultiSelectFilter
                        onChange={updateTagFilter}
                        options={tags.map((tag) => ({
                          value: tag.title,
                          label: tag.title,
                        }))}
                        selected={selectedTag?.split(",")}
                      />
                    )}
                  </Filter.Body>
                )}
                {isAdmin && (
                  <Filter.Body value="SUBSCRIPTION">
                    <MultiSelectFilter
                      isSearchable={false}
                      onChange={subscriptionFilter}
                      options={CRM_SUBSCRIPTION_TYPE_OPTIONS}
                      selected={selectedSubscription?.split(",")}
                    />
                  </Filter.Body>
                )}
                <Filter.Body value="SALESPERSON" block>
                  <MultiSelectFilter
                    onChange={salesPersonFilter}
                    options={assignMembers?.map((assignee) => ({
                      value: assignee.profile_id,
                      label: assignee.name,
                    }))}
                    selected={selectedSalesperson?.split(",")}
                  />
                </Filter.Body>
                <Filter.Body value="US_STATE" block>
                  <MultiSelectFilter
                    onChange={usStateFilter}
                    options={usStates?.map((state) => ({
                      value: state.state_id,
                      label: state.name,
                    }))}
                    selected={selectedStates?.split(",")}
                  />
                </Filter.Body>
                <Filter.Body value="INCORP_MONTH" block>
                  <MultiSelectFilter
                    onChange={usIncorporationMonthFilter}
                    options={usIncorporationMonths?.map((month) => ({
                      value: month.month_id,
                      label: month.name,
                    }))}
                    selected={selectedIncorporationMonths?.split(",")}
                  />
                </Filter.Body>
              </Filter.Portal>
            </Filter.Root>
          </div>

          {!isUserTypeForeignCA && !isForeignCA && (
            <div className="t-flex t-p-4 t-pl-0 t-w-4/5 t-overflow-auto t-self-start">
              <select className="selectFilterDrop" onChange={tagsFilter}>
                <option value=""> Select tag </option>
                {tags?.map((tag) => {
                  return (
                    <option
                      key={tag.uuid}
                      value={tag.title}
                      selected={selectedTag === tag.title}
                    >
                      #{tag.title}
                    </option>
                  );
                })}
                <hr />
                {authtoken?.is_inkle_admin && (
                  <option value="Add tag"> Add tag &#8594; </option>
                )}
              </select>
              {isAdmin && (
                <select
                  className="selectFilterDrop"
                  onChange={subscriberFilter}
                  value={selectedSubscriberType || ""}
                >
                  <option value="">Select Subscriber</option>
                  <option value="ACTIVE_SUBSCRIPTION">Active Subscriber</option>
                  <option value="INACTIVE_SUBSCRIPTION">
                    Inactive Subscriber
                  </option>
                </select>
              )}

              <select
                className="selectFilterDrop"
                onChange={taskFilter}
                value={taskTemplateFilter || ""}
              >
                <option value="">
                  Select {process.env.PUBLIC_OPS_TASK_TEMPLATE_FILTER_NAME}{" "}
                  filter
                </option>
                <option value={NOT_CREATED}>Not Created</option>
                <option value={NOT_STARTED_KEY}>Not Started</option>
                <option value={NOT_COMPLETED}>Not Completed</option>
              </select>

              {isAdmin && (
                <select
                  className="selectFilterDrop"
                  onChange={duesFilter}
                  value={sortDueBy || ""}
                >
                  <option value="">Sort by</option>
                  <option value="">Default</option>
                  <option value="DSC">Highest dues first</option>
                  <option value="ASC">Lowest dues first</option>
                </select>
              )}
            </div>
          )}
          <Button
            onClick={downloadCSV}
            customType="icon"
            size="regular"
            isLoading={isDownloadingCsv}
          >
            <span className="t-text-text-30">
              <DownloadIcon color="currentColor" size={20} />
            </span>
          </Button>
          {isAdmin && (
            <div className="t-flex t-ml-4">
              <Button onClick={openBroadcast}>
                <div className="t-flex t-gap-1">
                  <BroadcastIcon /> <span>Broadcast</span>
                </div>
              </Button>

              <Modal.Root open={isBroadcastOpen} onOpenChange={closeBroadcast}>
                <Modal.Content size="xl" useCustomOverlay>
                  <BroadcastMessage
                    onClose={closeBroadcast}
                    crmFilters={opsCrmFilters}
                    fromOpsCRM
                  />
                </Modal.Content>
              </Modal.Root>
            </div>
          )}
        </div>

        <Divider />
      </div>
      <NewGroupCreationModal
        showModal={showProfileForm}
        setShowModal={setShowProfileForm}
        setShowEntityModal={setShowEntityModal}
        setUserDataPayLoad={setUserDataPayLoad}
        userDataPayload={userDataPayload}
        setGroupName={setGroupName}
      />
      <AddCrmEntityTableModal
        setShowGroupModal={setShowProfileForm}
        showModal={showEntityModal}
        setModalShow={setShowEntityModal}
        userDataPayload={userDataPayload}
        groupName={groupName}
      />
    </>
  );
}
