import classNames from "classnames";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { KeyDownWrapper } from "components/DesignSystem/KeydownWrapper/KeydownWrapper";
import { NoteCard } from "components/DesignSystem/Notes/NoteCard";
import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { OutlinedPaperPlaneRight } from "components/icons/OutlinedPaperPlaneRight";
import { Form, Formik, FormikValues } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import {
  useAddFinancialClosingCommentsMutation,
  useGetFinancialClosingCommentsQuery,
} from "store/apis/financialClosing";
import { BackendError } from "types/utils/error";

export const Comments = ({
  financialClosingId,
}: {
  financialClosingId: string;
}) => {
  const [addFinancialClosingComments, { isLoading }] =
    useAddFinancialClosingCommentsMutation();
  const { alertToast } = useToast();
  const entityId = useCurrentEntityId();

  const {
    data: comments,
    isLoading: loading,
    isSuccess,
  } = useGetFinancialClosingCommentsQuery(
    {
      entityId: entityId,
      financialClosingId: financialClosingId,
    },
    {
      skip: !financialClosingId || !entityId,
    }
  );

  const onSubmit = async (
    values: { description: string },
    { resetForm }: FormikValues
  ) => {
    if (!values.description) return;

    try {
      await addFinancialClosingComments({
        entityId,
        financialClosingId,
        description: values.description,
      }).unwrap();
      resetForm();
    } catch (error) {
      alertToast({
        message: (error as BackendError).data?.error?.message,
      });
    }
  };

  return (
    <Accordion.Root defaultValue="COMMENT" collapsible type="single">
      <Accordion.Item value="COMMENT">
        <Accordion.Trigger>Comments</Accordion.Trigger>
        <Accordion.Content>
          <Async.Root isEmpty={false} isLoading={loading} isSuccess={isSuccess}>
            <Async.Empty>
              <></>
            </Async.Empty>
            <Async.Success>
              <span>
                <KeyDownWrapper>
                  <div className="t-overflow-auto t-flex t-flex-col t-gap-2 t-max-h-80">
                    <div className="t-flex t-flex-col t-gap-2">
                      {comments?.notes.map((note) => (
                        <NoteCard note={note} key={note.uuid} />
                      ))}
                    </div>
                    <Formik
                      initialValues={{ description: "" }}
                      onSubmit={onSubmit}
                    >
                      <Form
                        className={classNames(
                          "t-w-full t-m-0 t-sticky t-bottom-0"
                        )}
                      >
                        <TextInput
                          placeholder="Add your comment here"
                          name="description"
                          customSize="large"
                          rightComponent={
                            <Button
                              customType="ghost_icon"
                              isLoading={isLoading}
                              disabled={isLoading}
                            >
                              <span className="t-text-text-30">
                                <OutlinedPaperPlaneRight size="20" />
                              </span>
                            </Button>
                          }
                        />
                      </Form>
                    </Formik>
                  </div>
                </KeyDownWrapper>
              </span>
            </Async.Success>
          </Async.Root>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
};
