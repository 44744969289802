import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Toast from "components/DesignSystem/Toast/Toast";
import { Form, Formik, FormikHelpers, useFormikContext } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { RowSelectionState } from "react-table-8.10.7";
import {
  useBulkAssignMerchantMutation,
  useGetAllTransactionsDataQuery,
} from "store/apis/transactions";
import { useAddVendorsMutation } from "store/apis/vendors";
import { setTxnFilters } from "store/slices/transactionFilter";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { object, string } from "yup";
import { TransactionsView } from "components/TransactionsView/TransactionsView";
import { MerchantSelector } from "./MerchantSelector";
import { useGetTransactionsFilterSet } from "hooks/useGetTransactionsFilterSet";

type BulkAssignVendorProps = ModalProps & {
  deselectRows: () => void;
  unSelectedRowsIds: string[];
  isAllTxnSelected: boolean;
};

const MerchantComponent = () => {
  const { setValues, values } = useFormikContext<{
    vendorName: string;
    vendorId: string;
  }>();

  const { alertToast } = useToast();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const [addMerchant, { isLoading }] = useAddVendorsMutation();

  const onCreateMerchant = async (name: string) => {
    try {
      const { name: vendorName, uuid } = await addMerchant({
        groupId,
        entityId,
        payload: { name },
      }).unwrap();

      setValues({
        vendorName,
        vendorId: uuid,
      });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <MerchantSelector
      isLoading={isLoading}
      isDisabled={isLoading}
      merchantChange={async (values) => {
        setValues({
          vendorName: values?.data,
          vendorId: values?.merchantId || null,
        });
      }}
      value={
        values.vendorId
          ? {
              value: values.vendorId,
              label: values.vendorName,
            }
          : null
      }
      withForm
      name="vendorId"
      label="Vendor"
      placeholder="Select Vendor"
      menuPortalTarget={document.body}
      onCreateMerchant={onCreateMerchant}
      creatable
      styles={{
        menuPortal: (base) => ({
          ...base,
          width: 600,
        }),
      }}
    />
  );
};

const DisclaimerUI = ({
  selectedVendor,
  txnCount,
  previouslyAssignedVendorTxnsCount,
}: {
  selectedVendor: string;
  txnCount: number;
  previouslyAssignedVendorTxnsCount: number;
}) => {
  return (
    <div className="t-text-body">
      You have assigned{" "}
      <motion.b
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.4 }}
        key={selectedVendor}
      >
        {" "}
        "{selectedVendor}"
      </motion.b>{" "}
      to {txnCount} transactions
      {Boolean(previouslyAssignedVendorTxnsCount > 0 && txnCount) && (
        <motion.span
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeInOut", duration: 0.4 }}
        >
          {" "}
          that includes
          <b>
            {" "}
            {previouslyAssignedVendorTxnsCount} previously assigned transactions
          </b>
        </motion.span>
      )}
      .
      <br />
      Are you sure you want to proceed?
    </div>
  );
};

const Disclaimer = ({
  selectedVendor,
  selectedTxnIds,
}: {
  selectedVendor: { value: string; label: string } | null;
  selectedTxnIds: string[];
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: transactionData } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      transactionIds: selectedTxnIds.join(","),
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const allAssignedVendorTransactions =
    transactionData?.transactions.filter(({ transaction: { merchant } }) =>
      Boolean(merchant)
    ) || [];

  return (
    <DisclaimerUI
      txnCount={selectedTxnIds.length}
      previouslyAssignedVendorTxnsCount={allAssignedVendorTransactions.length}
      selectedVendor={selectedVendor?.label || ""}
    />
  );
};

export const BulkAssignVendor = ({
  close,
  isOpen,
  deselectRows,
  isAllTxnSelected,
  unSelectedRowsIds,
}: BulkAssignVendorProps) => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast } = useToast();
  const [bulkAssignVendor, { isSuccess, reset, data }] =
    useBulkAssignMerchantMutation();
  const dispatch = useAppDispatch();

  const { selectedTransactionIds } = useAppSelector(
    (store) => store.transactions
  );

  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  useEffect(() => {
    if (selectedTransactionIds) {
      const selectedTxns = selectedTransactionIds.reduce(
        (accumulator, currentId, i) => {
          return { ...accumulator, [currentId]: true };
        },
        {}
      );
      setRowSelection(selectedTxns);
    }
  }, [selectedTransactionIds]);

  const selectedTxnIds = Object.keys(rowSelection);

  const { data: filterSet, isLoading: isFilterSetLoading } =
    useGetTransactionsFilterSet({
      filter_operation: "ASSIGN_VENDOR",
      unSelectedRowsIds,
      skip: !isAllTxnSelected || !isOpen,
    });

  const {
    txns_count = 0,
    txns_hash_key = "",
    vendor_assigned_txns_count = 0,
  } = filterSet || {};

  const { data: transactionData, isLoading } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      transactionIds: selectedTransactionIds.join(","),
    },
    {
      skip: !groupId || !entityId || isAllTxnSelected,
      refetchOnMountOrArgChange: true,
    }
  );

  const { transactions = [] } = transactionData || {};

  const onSubmit = async (
    {
      vendorName,
      vendorId,
    }: {
      vendorName: string;
      vendorId: string;
    },
    {
      resetForm,
    }: FormikHelpers<{
      vendorName: string;
      vendorId: string;
    }>
  ) => {
    try {
      if (isAllTxnSelected) {
        await bulkAssignVendor({
          groupId,
          entityId,
          payload: {
            entity_merchant_uuid: vendorId,
            transactions_hash_key: txns_hash_key,
          },
        }).unwrap();
      } else {
        await bulkAssignVendor({
          groupId,
          entityId,
          payload: {
            entity_merchant_uuid: vendorId,
            transaction_ids: selectedTxnIds.join(", "),
          },
        }).unwrap();
      }
      close();
      setRowSelection({});
      resetForm();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const onToastClose = () => {
    reset();
    deselectRows();
    setRowSelection({});
  };

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Formik
          initialValues={{ vendorName: "", vendorId: "" }}
          onSubmit={onSubmit}
          validateOnChange
          validateOnMount
          enableReinitialize
          validationSchema={object({
            vendorId: string().required("Please select a vendor"),
          })}
        >
          {({
            submitForm,
            isSubmitting,
            isValid,
            values: { vendorId, vendorName },
            resetForm,
          }) => {
            return (
              <Modal.Content
                useCustomOverlay
                size={Boolean(vendorId) ? "xl" : "large"}
              >
                <Modal.Header>
                  <Modal.Title>Assign Vendor</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <Form className="t-m-0 t-flex t-flex-col t-gap-4">
                    {isFilterSetLoading ? (
                      <Loader size="small" />
                    ) : (
                      <>
                        <MerchantComponent />
                        {vendorId && (
                          <AnimatePresence>
                            {isAllTxnSelected ? (
                              <DisclaimerUI
                                previouslyAssignedVendorTxnsCount={
                                  vendor_assigned_txns_count
                                }
                                selectedVendor={vendorName}
                                txnCount={txns_count}
                              />
                            ) : (
                              <>
                                <Disclaimer
                                  selectedVendor={{
                                    label: vendorName,
                                    value: vendorId,
                                  }}
                                  selectedTxnIds={selectedTxnIds}
                                />
                                <motion.div
                                  layout
                                  initial={{ opacity: 0, y: 100 }}
                                  animate={{ opacity: 1, y: 0 }}
                                  transition={{
                                    ease: "easeInOut",
                                    duration: 0.5,
                                  }}
                                >
                                  {isLoading ? (
                                    <Loader />
                                  ) : (
                                    <TransactionsView
                                      transactions={transactions}
                                      rowSelection={rowSelection}
                                      setRowSelection={setRowSelection}
                                      showCheckbox
                                    />
                                  )}
                                </motion.div>
                              </>
                            )}
                          </AnimatePresence>
                        )}
                      </>
                    )}
                  </Form>
                </Modal.Body>
                <Modal.Footer>
                  <div className="t-flex t-justify-end t-gap-3">
                    <Button
                      type="reset"
                      onClick={() => {
                        resetForm();
                        close();
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      customType="primary"
                      onClick={submitForm}
                      isLoading={isSubmitting}
                      disabled={
                        isSubmitting ||
                        !isValid ||
                        Boolean(selectedTxnIds.length === 0)
                      }
                    >
                      Assign all{" "}
                      {selectedTxnIds.length
                        ? `(${selectedTxnIds.length})`
                        : ""}
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal.Content>
            );
          }}
        </Formik>
      </Modal.Root>
      <div className="t-fixed t-bottom-0 t-right-0">
        <Toast.Provider>
          <Toast.Root
            open={isSuccess}
            customType="success"
            size="regular"
            onOpenChange={onToastClose}
          >
            <Toast.Title>Successful</Toast.Title>
            <Toast.Description>
              {data?.transactions_updated[0].merchant_data.name} was assigned to{" "}
              {data?.total_transactions_updated} transactions`
            </Toast.Description>
            <Toast.Close onClose={onToastClose} />
            <Toast.Action
              onAction={() => {
                dispatch(
                  setTxnFilters({
                    vendors: [
                      data?.transactions_updated[0].merchant_data.name || "",
                    ],
                  })
                );
                deselectRows();
              }}
              altText="View"
            >
              View
            </Toast.Action>
          </Toast.Root>
        </Toast.Provider>
      </div>
    </>
  );
};
