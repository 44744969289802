export const UserGroup = () => (
  <svg
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1" y="0.5" width="23" height="23" rx="11.5" fill="white" />
    <rect x="1" y="0.5" width="23" height="23" rx="11.5" stroke="#F3F3F5" />
    <g clip-path="url(#clip0_52_27175)">
      <path
        d="M10.4375 13.5C11.7837 13.5 12.875 12.4087 12.875 11.0625C12.875 9.71631 11.7837 8.625 10.4375 8.625C9.09131 8.625 8 9.71631 8 11.0625C8 12.4087 9.09131 13.5 10.4375 13.5Z"
        stroke="#A09CAD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.97949 15.375C7.35407 14.7991 7.86655 14.3258 8.47043 13.9982C9.0743 13.6706 9.75044 13.499 10.4375 13.499C11.1245 13.499 11.8006 13.6706 12.4045 13.9982C13.0084 14.3258 13.5209 14.7991 13.8954 15.375"
        stroke="#A09CAD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5625 13.5C15.2495 13.4996 15.9257 13.6709 16.5296 13.9984C17.1335 14.3258 17.646 14.7991 18.0205 15.375"
        stroke="#A09CAD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.6575 8.79844C13.9909 8.66545 14.3496 8.60775 14.7079 8.62946C15.0662 8.65117 15.4153 8.75176 15.7302 8.92403C16.0452 9.0963 16.3182 9.33601 16.5297 9.62602C16.7413 9.91603 16.8862 10.2492 16.954 10.6017C17.0219 10.9542 17.0111 11.3173 16.9223 11.6652C16.8336 12.013 16.6691 12.3369 16.4406 12.6138C16.2121 12.8907 15.9254 13.1137 15.6007 13.2669C15.2761 13.4201 14.9216 13.4997 14.5626 13.5"
        stroke="#A09CAD"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_52_27175">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(6.5 6)"
        />
      </clipPath>
    </defs>
  </svg>
);
