import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { FINANCIAL_RECORD_TYPE } from "constants/vendorBills";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { parse, stringify } from "qs";
import {
  matchPath,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Vendors } from "./Vendors";
import { Bills } from "./Bills";
import { SendBillPage } from "components/VendorBills/SendBillPage";
import { AddBillPage } from "components/VendorBills/AddBillPage";
import { RequestBillPage } from "components/VendorBills/RequestBillPage";

export const BillsAndVendors = () => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const { link } = useConstructInternalLink();

  return (
    <Tab.Root asChild defaultValue={pathname} value="MATCH">
      <DashboardLayout
        header={
          <div className="t-sticky t-top-0">
            <Header v2 title="Bills" />
          </div>
        }
      >
        <Switch>
          <Route exact path={path}>
            <Bills />
          </Route>
          <Route path={`${path}/request/:billId`}>
            <RequestBillPage
              close={() =>
                history.push(
                  link(url, {
                    moreQuery: { BILL_TYPE: FINANCIAL_RECORD_TYPE.REQUEST },
                  })
                )
              }
            />
          </Route>
          <Route path={`${path}/send/:billId`}>
            <SendBillPage close={() => history.goBack()} />
          </Route>
          <Route path={`${path}/bill/:billId`}>
            <AddBillPage
              close={() =>
                history.push(
                  link(url, {
                    moreQuery: { BILL_TYPE: FINANCIAL_RECORD_TYPE.BILL },
                  })
                )
              }
            />
          </Route>
        </Switch>
      </DashboardLayout>
    </Tab.Root>
  );
};
