import { BaseFileInput } from "components/DesignSystem/BaseFileInput/BaseFileInput";
import Card from "components/DesignSystem/Card/Card";
import { Preview } from "components/PreviewModal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useParams } from "react-router-dom";
import {
  FinancialClosing,
  useAddOtherFinancialsMutation,
  useDeleteOtherFinancialsMutation,
} from "store/apis/financialClosing";
import { BackendError } from "types/utils/error";

type OtherAttachmentsProps = {};

export const OtherAttachments = ({
  other_financials,
}: {
  other_financials: FinancialClosing["other_financials"];
}) => {
  const { financialClosingId } = useParams<{ financialClosingId: string }>();
  const entityId = useCurrentEntityId();
  const { alertToast } = useToast();
  const privewModal = useModal();
  const [previewId, setPreviewId] = useState<string | null>(null);

  const [addOtherFinancials, { isLoading }] = useAddOtherFinancialsMutation();
  const [deleteOtherFinancials, { isLoading: isDeleting, originalArgs }] =
    useDeleteOtherFinancialsMutation();

  const onDrop = async (files: File[]) => {
    try {
      await addOtherFinancials({
        entityId: entityId,
        financialClosingId: financialClosingId,
        payload: {
          other_financials: files,
        },
      }).unwrap();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const onDelete = async (uuid: string) => {
    try {
      await deleteOtherFinancials({
        entityId: entityId,
        financialClosingId: financialClosingId,
        financialId: uuid,
      }).unwrap();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const onFileClick = ({ uuid }: { uuid: string }) => {
    setPreviewId(uuid);
    privewModal.open();
  };

  const onPreviewClose = () => {
    setPreviewId(null);
    privewModal.close();
  };

  return (
    <Card.Root className="t-pb-5">
      <Card.Header>
        <Card.Title>Other Attachments</Card.Title>
      </Card.Header>
      <Card.Body>
        <BaseFileInput
          files={other_financials}
          onFileClick={onFileClick}
          onDrop={onDrop}
          multiple
          isUploading={isLoading}
          isDeleting={isDeleting}
          fileDeletingUuid={originalArgs?.financialId}
          onDelete={(_, index) => onDelete(other_financials[index].uuid)}
        />
      </Card.Body>
      {previewId && (
        <Preview
          closeModal={onPreviewClose}
          showModal={privewModal.isOpen}
          previewId={previewId}
        />
      )}
    </Card.Root>
  );
};
