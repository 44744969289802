import { Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import { FC, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import UploadFilePublic from "static/images/UploadFilePublic.svg";
import { useUploadPublicWFormFileMutation } from "store/apis/groupWForms";
import { FileIcon } from "utils/fileTypeIcon";
import { Input } from "./design/input";
import { Button } from "./DesignSystem/Button/Button";
import { DeleteIcon } from "./icons/delete";

type IPublicWFormUploadFileProps = {
  closeShowUploadFileScreen: () => void;
  setShowSuccessfullyUpload: (val: boolean) => void;
};

export const PublicWFormUploadFile: FC<IPublicWFormUploadFileProps> = ({
  closeShowUploadFileScreen,
  setShowSuccessfullyUpload,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [uploadPublicWFormFile, { isLoading }] =
    useUploadPublicWFormFileMutation();
  let { merchantId } = useParams<{ merchantId: string }>();
  const { alertToast, successToast } = useToast();

  const handleSubmit = async ({ form_type }: { form_type: string }) => {
    try {
      await uploadPublicWFormFile({
        merchantId,
        file: file!,
        form_type,
      }).unwrap();
      successToast({
        message: "W-form  uploaded successfully!",
      });
      setShowSuccessfullyUpload(true);
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const onDrop = (files: File[]) => setFile(files[0]);

  const { open, getRootProps, getInputProps } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
    maxSize: 25000000,
    accept: {
      "image/*": [".png", ".jpeg", ".jpg", ".webp", ".avif"],
      "application/pdf": [".pdf"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
      "text/csv": [".csv"],
    },
    maxFiles: 1,
  });

  const deleteFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setFile(null);
  };

  const extension = file?.name?.split?.(".")?.pop?.()?.toUpperCase?.();

  return (
    <div
      className="t-bg-surface t-p-14 t-rounded-2xl t-flex t-flex-col t-gap-4"
      {...getRootProps()}
    >
      <div className="t-text-text-100 t-text-title-h2-bold">Upload W Form</div>
      <div>
        <div className="t-text-body t-text-text-100 t-mb-2">
          Select the form you're trying to upload *
        </div>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            form_type: "W-9",
          }}
        >
          <Form className="t-w-full t-flex t-flex-col t-m-0">
            <div className="t-flex t-w-full t-items-end t-gap-4">
              <div
                className="t-flex t-gap-3 t-w-full"
                role="group"
                aria-labelledby="form-selector-radio-group"
              >
                <Input
                  block
                  label={
                    <div className="t-text-body-sm t-text-text-100">
                      W-8 BEN-E
                    </div>
                  }
                  type="radio"
                  value="W-8BEN-E"
                  name="form_type"
                  required
                />
                <Input
                  block
                  label={
                    <div className="t-text-body-sm t-text-text-100">
                      W-8 BEN
                    </div>
                  }
                  value="W-8BEN"
                  type="radio"
                  name="form_type"
                  required
                />
                <Input
                  block
                  label={
                    <div className="t-text-body-sm t-text-text-100">W-9</div>
                  }
                  value="W-9"
                  type="radio"
                  name="form_type"
                  required
                />
              </div>
            </div>
            <div className="t-text-body t-text-text-100 t-mt-4 t-mb-2">
              Upload file
            </div>
            {file ? (
              <div className="t-flex t-items-center t-gap-1 t-justify-between t-w-[500px] t-border t-border-solid t-border-neutral-10 t-rounded t-px-3 t-py-2">
                <div className="t-truncate t-max-w-4/5">
                  <FileIcon fileType={extension} width="32px" height="32px" />
                  {file.name}
                </div>
                <Button
                  type="button"
                  customType="ghost_icon"
                  size="small"
                  onClick={deleteFile}
                >
                  <DeleteIcon />
                </Button>
              </div>
            ) : (
              <div
                className="t-w-[500px] t-flex t-flex-col t-p-8 t-cursor-pointer t-items-center t-justify-center t-gap-2 t-rounded-lg t-border t-border-dashed t-border-neutral-20 t-bg-surface-lighter-grey"
                onClick={open}
              >
                <input {...getInputProps()} />
                <img src={UploadFilePublic} alt="UploadFilePublic" />
                <div className="t-text-body t-text-text-100 t-flex t-justify-center t-w-full">
                  Drag and drop or click to upload
                </div>
              </div>
            )}
            <div className="t-flex t-w-full t-justify-end t-items-center t-gap-2 t-mt-4">
              <Button type="button" onClick={closeShowUploadFileScreen}>
                Go back
              </Button>
              <Button
                customType="primary"
                type="submit"
                disabled={!file || isLoading}
                isLoading={isLoading}
              >
                Complete
              </Button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};
