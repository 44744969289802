import Modal from "components/DesignSystem/Modal/Modal";
import { useCreateReminderSettingMutation } from "store/apis/reminders";
import { FormikForm } from "components/FormikForm/FormikForm";
import { SetReminder } from "components/SetReminder/SetReminder";
import { useChannelStateContext } from "stream-chat-react";
import { reminderSettingValidation } from "formValidations/reminderSettingValidation";

export const CreateMessageReminder = ({
  isOpen,
  onClose,
  message,
}: {
  isOpen: boolean;
  onClose: () => void;
  message: { id: string; text?: string };
}) => {
  const { channel } = useChannelStateContext();
  const groupId = channel.data?.group_uuid! as string;
  const [createReminderSetting, { isLoading: isCreating }] =
    useCreateReminderSettingMutation();

  const initialValues = {
    reminder_details: {
      frequency: "EVERY_3_DAYS" as const,
      is_reminder_enabled: true,
      notification_channels: ["CHAT"],
      on_date: null,
      subscribers: [],
      group_id: groupId,
    },
    content_details: {
      message_text: message.text,
      reminder_name: "",
    },
  };

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      await createReminderSetting({
        groupId,
        payload: {
          reminder_details: values.reminder_details,
          content_details: {
            message_id: message.id,
            message_text: values.content_details.message_text!,
            content_type: "message",
            channel_id: channel.id!,
            reminder_name: values.content_details.reminder_name!,
          },
        },
      }).unwrap();
      onClose();
    } catch (error) {}
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Content asChild useCustomOverlay>
        <FormikForm
          initialValues={initialValues}
          // @ts-ignore
          onSubmit={handleSubmit}
          validationSchema={reminderSettingValidation}
        >
          {channel.id && groupId && (
            <SetReminder
              channelId={channel.id}
              groupId={groupId}
              isLoading={isCreating}
            />
          )}
        </FormikForm>
      </Modal.Content>
    </Modal.Root>
  );
};
