import { AddressAutofill } from "types/Models/addressAutofill";
import { Countries } from "types/Models/countries";

export const getCity = (place: google.maps.places.Place) => {
  return place.addressComponents?.find((c) => c.types.includes("locality"))
    ?.longText;
};

export const getCountry = (
  place: google.maps.places.Place,
  availableCountriesData: Countries[]
) => {
  return availableCountriesData.find(
    (country) =>
      country.code_alpha_2 ===
      place.addressComponents?.find((c) => c.types.includes("country"))
        ?.shortText
  )?.name;
};

export const getState = (
  place: google.maps.places.Place,
  availableCountriesData: Countries[]
) => {
  return availableCountriesData
    .find(
      (country) =>
        country.code_alpha_2 ===
        place.addressComponents?.find((c) => c.types.includes("country"))
          ?.shortText
    )
    ?.states.find(
      (state) =>
        state.name ===
        place.addressComponents?.find((c) =>
          c.types.includes("administrative_area_level_1")
        )?.longText
    )?.name;
};

export const getZipCode = (place: google.maps.places.Place) => {
  return place.addressComponents?.find((c) => c.types.includes("postal_code"))
    ?.longText;
};

export const getStreetName = (place: google.maps.places.Place) => {
  return place.addressComponents?.find((c) => c.types.includes("route"))
    ?.longText;
};

export const getStreetAddress = (place: google.maps.places.Place) => {
  return `${
    place.addressComponents?.find((c) => c.types.includes("street_number"))
      ?.longText || ""
  } ${getStreetName(place) || ""}`;
};
