import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import { Field, FieldProps, Form, Formik, FormikValues } from "formik";
import { transactionSchema } from "formValidations/transactionSchema";

type AddTransactionManuallyProps = {
  isOpen: boolean;
  onClose: () => void;
  onAddNewTxn: (v: FormikValues) => void;
  minDate?: string;
  maxDate?: string;
};

export const AddTransactionManually = ({
  isOpen,
  onClose,
  onAddNewTxn,
  minDate,
  maxDate,
}: AddTransactionManuallyProps) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Modal.Content>
        <Formik
          initialValues={{
            amount: 0,
            transaction_type: "",
            description: "",
            date: minDate ? new Date(minDate) : new Date(),
          }}
          onSubmit={onAddNewTxn}
          validationSchema={transactionSchema}
          validateOnChange
        >
          {({ isSubmitting, isValid }) => (
            <Form className="all:unset">
              <Modal.Header>
                <Modal.Title>Add transaction manually</Modal.Title>
                <Modal.Close type="button" />
              </Modal.Header>
              <Modal.Body className="t-flex t-gap-4 t-flex-col t-overflow-auto">
                <Field name="date">
                  {({ field }: FieldProps) => {
                    return (
                      <DateInput
                        {...field}
                        label="Date"
                        placeholder={DD_MMM_YYYY}
                        dateFormat="dd-MMM-yyyy"
                        required
                        minDate={minDate ? new Date(minDate) : null}
                        maxDate={maxDate ? new Date(maxDate) : null}
                      />
                    );
                  }}
                </Field>
                <PriceInput name="amount" placeholder="$ 0.00" label="Amount" />
                <Combobox
                  required
                  name="transaction_type"
                  label="Transaction type"
                  withForm
                  placeholder="Select Transaction type"
                  options={
                    [
                      { label: "Debit", value: "DEBIT" },
                      { label: "Credit", value: "CREDIT" },
                    ]?.map(({ label, value }) => ({
                      value,
                      label,
                    }))!
                  }
                  block
                />

                <TextArea
                  label="Description"
                  name="description"
                  rows={4}
                  required
                  placeholder="Add description"
                  onKeyDown={(e) => e.stopPropagation()}
                />
              </Modal.Body>
              <Modal.Footer className="t-flex t-items-center t-gap-3 t-justify-end">
                <Button type="reset" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  customType="primary"
                  isLoading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                  type="submit"
                >
                  Add
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
