import { AmountSuperScript } from "components/design/AmountSuperScript";
import { BlurFade } from "components/design/BlurFade";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Header } from "components/DesignSystem/Header/Header";
import Select from "components/DesignSystem/Select/Select";
import Tab from "components/DesignSystem/Tab/Tab";
import { VendorBills } from "components/VendorBills/VendorBills";
import { VendorTransactions } from "components/Vendors/VendorTransactions";
import { CURRENT_SEASON, VENDOR_SEASON_OPTION } from "constants/vendor";
import { useState } from "react";
import { useGetVendorDetailQuery } from "store/apis/vendors";
import { Vendor } from "types/Models/vendors";

export const VendorBillsAndTxn = ({
  entityId,
  vendorId,
  vendor,
}: {
  vendor: Vendor;
  entityId: string;
  vendorId: string;
}) => {
  const [selectedSeason, setSelectedSeason] = useState(CURRENT_SEASON);

  const { data, isFetching, isSuccess } = useGetVendorDetailQuery(
    {
      entityId,
      vendorId,
      season: selectedSeason,
    },
    {
      skip: !vendorId || !entityId,
    }
  );

  return (
    <Tab.Root defaultValue="BILLS" className="t-mt-6">
      <Header
        v2
        bottom={
          <Tab.List>
            <Tab.Trigger value="BILLS">Bills</Tab.Trigger>
            <Tab.Trigger value="TRANSACTIONS">Transactions</Tab.Trigger>
          </Tab.List>
        }
      />

      <div className="t-mt-5">
        <Tab.Content value="BILLS">
          <VendorBills
            entityId={entityId}
            merchantId={vendorId}
            canRequestAndAddBill={false}
            isSearchable={false}
          />
        </Tab.Content>

        <Tab.Content value="TRANSACTIONS" className="t-gap-3 t-flex t-flex-col">
          <div>
            <Select.Root
              size="small"
              onValueChange={setSelectedSeason}
              defaultValue={CURRENT_SEASON}
              value={selectedSeason}
            >
              <Select.Trigger>
                Season: <Select.Value placeholder="select year" />
              </Select.Trigger>
              <Select.Content sideOffset={2}>
                {VENDOR_SEASON_OPTION.map((season) => (
                  <Select.Item value={season} key={season}>
                    {season}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select.Root>
          </div>

          <div className="t-flex t-gap-2 t-p-5 t-rounded t-border-solid t-border-neutral-0 t-border t-flex-col">
            <div className="t-text-text-30 t-text-body-sm">
              Total Transacted Amount
            </div>
            <Async.Root
              isEmpty={false}
              isLoading={isFetching}
              isSuccess={isSuccess}
              customLoader={
                <div className="t-bg-neutral-10 t-rounded-md t-h-5 t-animate-pulse t-w-20" />
              }
            >
              <Async.Empty>
                <></>
              </Async.Empty>
              <Async.Success>
                <BlurFade
                  key={selectedSeason}
                  className="t-text-text-100 t-text-subtitle"
                >
                  {data && (
                    <AmountSuperScript amount={data?.transaction_amount} />
                  )}
                </BlurFade>
              </Async.Success>
            </Async.Root>
          </div>

          <VendorTransactions
            entityId={entityId}
            season={selectedSeason}
            vendorName={vendor?.name}
          />
        </Tab.Content>
      </div>
    </Tab.Root>
  );
};
