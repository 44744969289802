import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import { useEffect, useRef, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import {
  useGetForm1099TemplateQuery,
  useGetForm1099TypesQuery,
  useSubmitForm1099TemplateMutation,
  useUpdateForm1099TemplateMutation,
} from "store/apis/Form1099";
import { EntityDataAttribute } from "types/Models/entity";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import isEqual from "lodash/isEqual";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";
import Loader from "components/design/loader";
import { vendorsApi } from "store/apis/vendors";
import { useDispatch } from "react-redux";
import * as Accordion from "@radix-ui/react-accordion";
import { CaretDown } from "components/icons/CaretDown";
import { Form1099Field, SectionField } from "./Form1099Field";
import WarningCircle from "static/images/WarningCircle.svg";
import classNames from "classnames";
import { ConfirmFilingModal } from "./ConfirmFilingModal";
import { useModal } from "hooks/useModal";

const MISC_FORM_TEMPLATE_TYPE = process.env.PUBLIC_FORM_1099_TEMPLATE_TYPE_MISC;

export const Form1099Fields = ({
  isOpen,
  close,
  wFormMapId,
  form1099Type,
  season,
  isInprogress,
  entityId,
}: {
  isOpen: boolean;
  close: () => void;
  wFormMapId: string;
  form1099Type: string;
  season: string;
  isInprogress?: boolean;
  entityId: string;
}) => {
  const { successToast, alertToast } = useToast();
  const dispatch = useDispatch();
  const [formTemplateId, setFormTemplateId] = useState<string | null>();
  const [
    updateForm1099Template,
    { isLoading: isUpdating, isSuccess: isUpdated },
  ] = useUpdateForm1099TemplateMutation();
  const [submitForm1099Template, { isLoading: isSubmittingForm }] =
    useSubmitForm1099TemplateMutation();
  const [isPaymentInfoMissing, setPaymentInfoMissing] = useState(false);
  const paymentSection = useRef<HTMLDivElement>(null);
  const confirmFilingModal = useModal();

  const {
    data: form1099Types,
    isLoading: isGettingForm1099Types,
    isSuccess: gettingForm1099TypesSuccess,
  } = useGetForm1099TypesQuery(
    { entityId, season },
    { skip: !entityId || !season }
  );

  const initialFormTemplateId =
    form1099Types?.find((form) => form.form_key === form1099Type)?.uuid ||
    form1099Types?.find((form) => form.form_key === MISC_FORM_TEMPLATE_TYPE)
      ?.uuid;

  const {
    data: form1099Template,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetForm1099TemplateQuery(
    {
      entityId,
      formTemplateId: (formTemplateId || initialFormTemplateId) as string,
      parentObjectId: wFormMapId,
    },
    {
      skip:
        !(formTemplateId || initialFormTemplateId) || !wFormMapId || !entityId,
      refetchOnMountOrArgChange: true,
    }
  );

  // Backend saves the selection of form type on a GET request, so can't rely on auto invalidation
  useEffect(() => {
    if (isSuccess && !isFetching) {
      dispatch(vendorsApi.util.invalidateTags([{ type: "VENDORS" }]));
    }
  }, [isFetching]);

  const initialValues = form1099Template?.sections.reduce((acc, section) => {
    section.fields.forEach((field) => {
      acc[field.name] = field.value;
    });
    return acc;
  }, {} as Record<string, EntityDataAttribute["value"] | undefined>);

  const onChange = debounce(
    async ({
      name,
      value,
      fieldId,
    }: {
      name: string;
      value: EntityDataAttribute["value"];
      fieldId: string;
    }) => {
      const currnetValue = initialValues?.[name];

      const isSameValue = isEqual(
        JSON.stringify(value),
        JSON.stringify(currnetValue)
      );

      if (isSameValue) {
        return;
      }

      try {
        await updateForm1099Template({
          entityId,
          value,
          fieldId,
        }).unwrap();
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  );

  const handleSubmit = async () => {
    try {
      await submitForm1099Template({
        formId: form1099Template?.uuid as string,
      }).unwrap();
      successToast({ message: "Form submitted successfully" });
      confirmFilingModal.close();
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const openConfirmFilingModal = () => {
    const isPaymentInfoMissing = form1099Template?.sections
      .find((section) => section.section_key === "payment_info")
      ?.fields.every((field) => !field.value);

    if (isPaymentInfoMissing) {
      setPaymentInfoMissing(true);
      paymentSection.current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
      return;
    }
    setPaymentInfoMissing(false);
    confirmFilingModal.open();
  };

  const handleFormTypeChange = (
    option: MultiValue<OptionData> | SingleValue<OptionData>
  ) => {
    if (option instanceof Array) {
      return null;
    }
    setFormTemplateId(option?.value ?? null);
  };

  const formTemplateOptions =
    form1099Types?.map((form) => ({
      label: form.name,
      value: form.uuid,
    })) ?? [];

  const allRequiredFieldsFilled = form1099Template?.sections.every((section) =>
    section.fields.every((field) => {
      if (field.is_required) {
        const value = field.value?.toString().trim();
        return Boolean(value);
      }
      return true;
    })
  );

  return (
    <>
      <Async.Root
        isEmpty={false}
        isLoading={isGettingForm1099Types || !gettingForm1099TypesSuccess}
        isSuccess={gettingForm1099TypesSuccess}
        renderOnLoad={
          <Modal.Root open={isOpen} onOpenChange={close}>
            <Modal.Content size="xl">
              <Modal.Header>
                <Modal.Title>Prepare Return</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Loader />
              </Modal.Body>
            </Modal.Content>
          </Modal.Root>
        }
      >
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          <Modal.Root open={isOpen} onOpenChange={close}>
            <Formik
              initialValues={{ initialValues }}
              onSubmit={openConfirmFilingModal}
            >
              {({ submitForm }) => (
                <Form className="t-m-0">
                  <Modal.Content size="xl">
                    <Modal.Header>
                      <div>
                        <Modal.Title>Prepare return</Modal.Title>
                        {isUpdated && (
                          <Modal.Subtitle>
                            <span className="t-flex t-items-center t-gap-2 t-transition-all t-text-body">
                              <img src={DoubleGreenTick} alt="Saved" />
                              Changes Autosaved
                            </span>
                          </Modal.Subtitle>
                        )}
                        {isUpdating && (
                          <Modal.Subtitle>
                            <div className="t-flex t-items-center t-gap-2 t-transition-all t-text-body t-h-4 t-w-4 t-mt-[9px]">
                              <Loader size="small" customType="secondary" />
                              Saving
                            </div>
                          </Modal.Subtitle>
                        )}
                      </div>

                      <Modal.Close />
                    </Modal.Header>
                    <Modal.Body>
                      <Combobox
                        name="formType"
                        label="Form Type"
                        isMulti={false}
                        size="small"
                        isDisabled={isInprogress}
                        isLoading={isGettingForm1099Types}
                        menuPortalTarget={document.body}
                        options={formTemplateOptions}
                        onChange={handleFormTypeChange}
                        value={
                          formTemplateOptions.find(
                            (option) =>
                              option.value === formTemplateId ||
                              option.value === initialFormTemplateId
                          ) ?? null
                        }
                      />

                      <Async.Root
                        isEmpty={false}
                        isLoading={isLoading}
                        isSuccess={isSuccess}
                      >
                        <Async.Empty>
                          <></>
                        </Async.Empty>
                        <Async.Success>
                          <div className="t-flex t-flex-col t-gap-6 t-mt-6 t-w-full">
                            {form1099Template?.sections.map((section) => {
                              return !section.is_collapsable ? (
                                <div
                                  key={section.uuid}
                                  ref={
                                    section.section_key === "payment_info"
                                      ? paymentSection
                                      : null
                                  }
                                >
                                  <div className="t-text-subtitle t-mb-2">
                                    {section.name}
                                    {isPaymentInfoMissing &&
                                      section.section_key ===
                                        "payment_info" && (
                                        <div className="t-text-body-sm t-text-red t-mt-1">
                                          <img
                                            src={WarningCircle}
                                            alt="WarningCircle"
                                            className="t-w-3 t-h-3 t-mr-1"
                                          />
                                          Payment information needs to be filled
                                        </div>
                                      )}
                                  </div>
                                  <div
                                    className={classNames(
                                      "t-border t-border-solid t-w-full t-rounded-lg t-p-4",
                                      {
                                        "t-border-neutral-0":
                                          !isPaymentInfoMissing ||
                                          section.section_key !==
                                            "payment_info",
                                        "t-border-red":
                                          isPaymentInfoMissing &&
                                          section.section_key ===
                                            "payment_info",
                                      }
                                    )}
                                  >
                                    <div className="t-grid t-grid-cols-2 t-gap-y-6 t-gap-x-4 t-w-full">
                                      {section.fields.map((field: any) => (
                                        <SectionField
                                          key={field.uuid}
                                          field={field}
                                          isInprogress={isInprogress}
                                          onChange={onChange}
                                          entityId={entityId}
                                        />
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <Accordion.Root
                                  type="single"
                                  defaultValue=""
                                  className="t-space-y-4"
                                  collapsible
                                  key={section.uuid}
                                >
                                  <Accordion.Item
                                    key={section.uuid}
                                    value={section.uuid}
                                    className="t-border t-border-solid t-border-neutral-10 t-p-4 t-rounded-lg"
                                  >
                                    <Accordion.Trigger
                                      type="button"
                                      className="all:unset t-text-subtitle t-text-text-100 t-flex t-gap-2 t-group t-w-full"
                                    >
                                      <span className="group-data-state-open:t-rotate-0 group-data-state-closed:-t-rotate-90 t-text-neutral t-transform t-transition t-duration-300 t-ease-in-out">
                                        <CaretDown />
                                      </span>
                                      {section.name}
                                    </Accordion.Trigger>
                                    <Accordion.Content className="t-mt-6">
                                      <div className="t-grid t-grid-cols-2 t-gap-y-6 t-gap-x-4 t-w-full">
                                        {section.fields.map((field: any) => (
                                          <SectionField
                                            key={field.uuid}
                                            field={field}
                                            isInprogress={isInprogress}
                                            onChange={onChange}
                                            entityId={entityId}
                                          />
                                        ))}
                                      </div>
                                    </Accordion.Content>
                                  </Accordion.Item>
                                </Accordion.Root>
                              );
                            })}
                          </div>
                        </Async.Success>
                      </Async.Root>
                    </Modal.Body>
                    <Modal.FooterButtonGroup>
                      <Modal.RawClose asChild>
                        <Button type="button">Cancel</Button>
                      </Modal.RawClose>
                      <Button
                        customType="primary"
                        onClick={submitForm}
                        disabled={
                          isUpdating ||
                          isFetching ||
                          !allRequiredFieldsFilled ||
                          isSubmittingForm ||
                          isInprogress
                        }
                      >
                        Submit to IRS
                      </Button>
                    </Modal.FooterButtonGroup>
                  </Modal.Content>
                </Form>
              )}
            </Formik>
          </Modal.Root>
          <ConfirmFilingModal
            {...confirmFilingModal}
            handleClick={handleSubmit}
            isLoading={isSubmittingForm}
          />
        </Async.Success>
      </Async.Root>
    </>
  );
};
