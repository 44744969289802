import { AppLayout } from "components/AppLayout/AppLayout";
import { ComingSoon } from "components/ComingSoon/ComingSoon";
import { Home } from "components/icons/LeftNav/Home";
import { MyEntities } from "components/icons/LeftNav/MyEntities";
import { InkleSalesTaxSymbol } from "components/icons/Logos/InkleSalesTaxSymbol";
import { NavAcrossApps } from "components/NavAcrossApps/NavAcrossApps";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import {
  LeftBar,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import { TopBar } from "components/TopBar/TopBar";
import { Switch, useRouteMatch } from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import "static/styles/containers/dashboard.css";
import { EntitySelector } from "components/EntitySelector/EntitySelector";

import { GroupSelectorWithDefaultGroup } from "components/GroupSelector/GroupSelectorWithDefaultGroup";

import GroupSelect from "components/dashboard/sideBar/groupSelect";
import { FilingDetails } from "components/fileTax/FilingDetails";
import { Filings } from "components/icons/LeftNav/Filings";
import { InkleSalesTaxLogo } from "components/icons/Logos/InkleSalesTaxLogo";
import { SalesTaxServices } from "components/SalesTaxService/SalesTaxService";
import TemplateMenu from "components/tasks/modal/AllTemplatesModal";
import { FOREIGN_CA } from "constants/onBoarding";
import { useAuth } from "hooks/useAuth";
import { UpgradeTaxPlan } from "../Tax/UpgradeTaxPlan";
import { useCurrentAppContext } from "contexts/CurrentAppContext";
import { useEffect, useState } from "react";
import { SalesNexusTracker } from "./SalesNexusTracker";
import { SalesTaxRegistrations } from "pages/SalesTax/SalesTaxRegistrations";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { Calculator } from "components/icons/LeftNav/SalesTax/Calculator";
import { TaxCalculator } from "pages/SalesTax/Calculator/TaxCalculator";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import Loader from "components/design/loader";

const Navbar = () => {
  const { type } = useAuth();

  const { link } = useConstructInternalLink();

  return (
    <div className="t-h-full t-flex t-flex-col t-justify-between">
      <div>
        {type === FOREIGN_CA && (
          <div className="t-m-2">
            <GroupSelect />
          </div>
        )}
        <LeftBarItem exact to={link("/")} icon={Home}>
          Nexus Tracker
        </LeftBarItem>

        <LeftBarItem to={link("/state-filings")} icon={MyEntities}>
          State Filings
        </LeftBarItem>

        <LeftBarItem to={link("/registrations")} icon={MyEntities}>
          Registrations
        </LeftBarItem>

        <LeftBarItem to={link("/tax-calculator")} icon={Calculator}>
          Tax Calculator
        </LeftBarItem>

        <LeftBarItem to={link("/services")} icon={Filings}>
          Services
        </LeftBarItem>
      </div>
      <div>
        <LeftBarSeparator />
        <NavAcrossApps />
      </div>
    </div>
  );
};

export const SalesTaxDashboard = () => {
  const { path, url } = useRouteMatch();
  const { dispatch: currentAppDispatch } = useCurrentAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const { isCpa, isForeignCA, isCustomer } = useRoleBasedView();

  useEffect(() => {
    currentAppDispatch({
      type: "SET_CURRENT_APP",
      payload: { currentApp: isCustomer ? "Sales Tax" : "Sales Tax Admin" },
    });
  }, []);

  return (
    <AppLayout
      sidebar={
        <LeftBar
          logo={
            <>
              <LeftBarLogo
                logo={InkleSalesTaxLogo}
                symbol={InkleSalesTaxSymbol}
              />
              {(isCpa || isForeignCA) && (
                <div className="t-px-2 t-pb-4 t-flex t-gap-2 t-flex-col">
                  <GroupSelectorWithDefaultGroup
                    onGroupChange={({ isLoading }) => setIsLoading(isLoading)}
                  />
                  <EntitySelector />
                </div>
              )}
            </>
          }
          showCollapse
        >
          <Navbar />
        </LeftBar>
      }
      topbar={
        <TopBar
          upgradeButton={<UpgradeTaxPlan />}
          logo={
            <LeftBarLogo
              logo={InkleSalesTaxLogo}
              symbol={InkleSalesTaxSymbol}
            />
          }
          nav={
            <LeftBar>
              <Navbar />
            </LeftBar>
          }
        >
          {isCustomer && (
            <EntitySelector
              toolTipPlacement="bottom"
              right={0}
              disableNonUsEntities
              showGoToEntity
              size="small"
            />
          )}
        </TopBar>
      }
      // banner={
      //   <DashboardBanner
      //     isCustomer={isCustomer!}
      //     subscriptionInactive={subscriptionInactive}
      //     stripePromise={stripePromise}
      //   />
      // }
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Switch>
          <RestrictedRoute exact path={`${path}/services/addtask`}>
            <TemplateMenu
              to={`/sales-tax/services`}
              taskCategoryType="SALES_TAX"
            />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/services/:taskId`}>
            <FilingDetails isFromServices parentRoute="/sales-tax/services" />
          </RestrictedRoute>
          <RestrictedRoute
            path={`${path}/state-filings`}
            component={ComingSoon}
          />
          <RestrictedRoute path={`${path}/services/`}>
            <SalesTaxServices />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/tax-calculator/`}>
            <TaxCalculator />
          </RestrictedRoute>

          <RestrictedRoute
            exact
            path={`${path}/registrations/`}
            component={SalesTaxRegistrations}
          />

          <RestrictedRoute exact path={path} component={SalesNexusTracker} />

          <RoutesAccrossApps />
        </Switch>
      )}
    </AppLayout>
  );
};
