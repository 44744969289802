import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Chip } from "components/DesignSystem/Chips/Chips";
import { Filter } from "components/DesignSystem/Filter/Filter";
import Modal from "components/DesignSystem/Modal/Modal";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { Search } from "components/DesignSystem/Search/Search";
import { DateFilter } from "components/Filters/DateFilter";
import {
  SORT_BY,
  SORT_ORDER,
  SortByFilter,
} from "components/Filters/SortByFilter";
import {
  CHECKLIST_ITEM_SECTION,
  CLOSING_STATUS,
} from "constants/financialClosing";
import { REPORT_FINANCIALS_MAP, REPORT_NAME } from "dictionaries";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useFilters } from "hooks/useFilter";
import { useToast } from "hooks/useToast";
import { Period } from "pages/Books/FinancialClosing/FinancialClosing";
import { ReportsType } from "pages/Books/Reports/Reports";
import EmptyCalender from "static/images/EmptyCalendar.svg";
import {
  ChecklistItem,
  useAddOtherFinancialsMutation,
  useGetAllFinancialClosingQuery,
  useLazyGetFinancialClosingQuery,
  useUpdateFinancialClosingCheckListMutation,
} from "store/apis/financialClosing";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { useDebounce } from "utils/debounce";
import { formatDateByViewType } from "utils/formatDate";
import { object, string } from "yup";

type FilterType = {
  START_DATE: string;
  END_DATE: string;
  SELECT_PERIOD: string;
  SORT_BY: keyof typeof SORT_BY;
  SEARCH: string;
};

type AllReportTypes = "AR_AGING" | "AP_AGING" | "VENDOR_SUMMARY" | ReportsType;

type AddToClosingProps = {
  startDate?: string;
  endDate?: string;
  reportType: AllReportTypes;
  reportFileId: string;
} & ModalProps;

const getReportTypeClosing = ({
  financials,
  reportType,
}: {
  reportType: AllReportTypes;
  financials: ChecklistItem[];
}) => {
  return financials.find(
    ({ type }) =>
      REPORT_FINANCIALS_MAP[type as keyof typeof REPORT_FINANCIALS_MAP] ===
      reportType
  );
};

export const AddToClosing = ({
  reportFileId,
  reportType,
  startDate,
  endDate,
  close,
  isOpen,
}: AddToClosingProps) => {
  const entityId = useCurrentEntityId();
  const { alertToast, successToast } = useToast();
  const [getFinancial] = useLazyGetFinancialClosingQuery();
  const [addFileToClosing] = useUpdateFinancialClosingCheckListMutation();
  const [addOtherFinancials] = useAddOtherFinancialsMutation();

  const { values, updateFilter } = useFilters<FilterType>({
    initialValue: {
      START_DATE: startDate || "",
      END_DATE: endDate || "",
      SELECT_PERIOD: "",
      SORT_BY: SORT_BY.LATEST,
      SEARCH: "",
    },
  });

  const debouncedSearch = useDebounce(values.SEARCH, 500);

  const {
    data = [],
    isLoading,
    isSuccess,
  } = useGetAllFinancialClosingQuery(
    {
      entityId,
      startDate: values.START_DATE,
      endDate: values.END_DATE,
      sortOrder: SORT_ORDER[values.SORT_BY],
      searchTerm: debouncedSearch,
      closingStatus: CLOSING_STATUS.IN_PROGRESS,
    },
    {
      skip: !entityId || !isOpen,
    }
  );

  const onAddOtherReport = async ({
    closingId,
    reportFileId,
  }: {
    closingId: string;
    reportFileId: string;
  }) => {
    try {
      const { closing_card_details } = await getFinancial({
        entityId,
        financialClosingId: closingId,
      }).unwrap();

      await addOtherFinancials({
        entityId: entityId,
        financialClosingId: closingId,
        payload: {
          file_document_ids: [reportFileId],
        },
      }).unwrap();

      successToast({
        title: "Successfully added",
        message: `${
          REPORT_NAME[reportType as keyof typeof REPORT_NAME]
        } added to financial closing: ${Period({
          end_date: closing_card_details.end_date,
          start_date: closing_card_details.start_date,
        })}`,
      });
      close();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const onAdd = async ({
    closingId,
    reportFileId,
  }: {
    closingId: string;
    reportFileId: string;
  }) => {
    try {
      const { checklist_items, closing_card_details } = await getFinancial({
        entityId,
        financialClosingId: closingId,
      }).unwrap();

      const financials = checklist_items.filter(
        ({ section }) => section === CHECKLIST_ITEM_SECTION.FINALIZE_FINANCIALS
      );
      const closingItem = getReportTypeClosing({ reportType, financials });

      if (!closingItem) return;

      await addFileToClosing({
        entityId: entityId,
        financialClosingId: closingId,
        checklistItemId: closingItem.uuid,
        payload: {
          document_id: reportFileId,
        },
      }).unwrap();

      successToast({
        title: "Successfully added",
        message: `${
          REPORT_NAME[closingItem.type as keyof typeof REPORT_NAME]
        } added to financial closing: ${Period({
          end_date: closing_card_details.end_date,
          start_date: closing_card_details.start_date,
        })}`,
      });
      close();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const onAddReport =
    reportType === "AP_AGING" ||
    reportType === "AR_AGING" ||
    reportType === "VENDOR_SUMMARY"
      ? onAddOtherReport
      : onAdd;

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Add to closing</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          initialValues={{
            closingId: "",
            reportFileId,
          }}
          validationSchema={object({
            closingId: string().required("Select a closing period"),
          })}
          validateOnMount
          onSubmit={onAddReport}
        >
          {({ submitForm, setFieldValue, isSubmitting, isValid }) => (
            <>
              <Modal.Body className="t-flex t-flex-col t-gap-6">
                <div className="t-flex t-flex-col t-gap-4">
                  <Search
                    onChange={(e) => updateFilter("SEARCH", e.target.value)}
                    placeholder="Search by closing period"
                    block
                    customSize="regular"
                  />
                  <Filter.Root
                    defaultValue="SORT_BY"
                    capsule={
                      <>
                        {(values.START_DATE || values.END_DATE) && (
                          <Chip
                            isRemovable={false}
                            onClose={() => {
                              updateFilter("START_DATE", "");
                              updateFilter("END_DATE", "");
                            }}
                            isActive
                            filterType="DATE_RANGE"
                          >
                            {values.START_DATE && values.END_DATE ? (
                              <>
                                {formatDateByViewType(
                                  values.START_DATE,
                                  "MONTHLY"
                                )}
                                {" to "}
                                {formatDateByViewType(
                                  values.END_DATE,
                                  "MONTHLY"
                                )}
                              </>
                            ) : (
                              <>
                                {values.START_DATE && (
                                  <>
                                    From{" "}
                                    {formatDateByViewType(
                                      values.START_DATE,
                                      "MONTHLY"
                                    )}
                                  </>
                                )}
                                {values.END_DATE && (
                                  <>
                                    Till{" "}
                                    {formatDateByViewType(
                                      values.END_DATE,
                                      "MONTHLY"
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </Chip>
                        )}
                        {values.SORT_BY && (
                          <Chip
                            isRemovable={false}
                            isActive
                            filterType="SORT_BY"
                          >
                            {values.SORT_BY === SORT_BY.LATEST
                              ? "Latest"
                              : "Old - New"}
                          </Chip>
                        )}
                      </>
                    }
                  >
                    <Filter.Portal size="small">
                      <Filter.List>
                        <Filter.ListItem value="SORT_BY">
                          Sort by
                        </Filter.ListItem>
                        <Filter.ListItem value="DATE_RANGE">
                          Date
                        </Filter.ListItem>
                      </Filter.List>
                      <Filter.Body value="SORT_BY" block>
                        <SortByFilter
                          updateFilter={updateFilter}
                          values={values}
                        />
                      </Filter.Body>
                      <Filter.Body value="DATE_RANGE" block>
                        <DateFilter
                          pickerType="month"
                          showPeriodSelector={false}
                          updateFilter={updateFilter}
                          values={values}
                          maxEndDate={null}
                        />
                      </Filter.Body>
                    </Filter.Portal>
                  </Filter.Root>
                </div>
                <Async.Root
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                  isEmpty={data.length === 0}
                >
                  <Async.Empty>
                    <div className="t-flex t-flex-col t-justify-center t-items-center t-gap-2">
                      <img
                        src={EmptyCalender}
                        alt="EmptyCalender"
                        className="t-size-[68px]"
                      />
                      <div className="t-flex t-flex-col t-items-center t-gap-1">
                        <div className="t-text-subtext-sm t-text-text-30">
                          No financial closing found!
                        </div>
                        <div className="t-text-body-sm t-text-text-30">
                          Consider adjusting the filters or adding a new
                          financial closing, and try again.
                        </div>
                      </div>
                    </div>
                  </Async.Empty>
                  <Async.Success>
                    <Form>
                      <RadioGroup.Root
                        onValueChange={(value) => {
                          setFieldValue("closingId", value);
                        }}
                      >
                        <RadioGroup.Content className="t-gap-4">
                          {data.map(({ start_date, end_date, uuid }) => (
                            <RadioGroup.Item
                              value={uuid}
                              key={uuid}
                              className="t-text-body t-text-text-100 t-w-max"
                            >
                              <Period
                                end_date={end_date}
                                start_date={start_date}
                              />
                            </RadioGroup.Item>
                          ))}
                        </RadioGroup.Content>
                      </RadioGroup.Root>
                    </Form>
                  </Async.Success>
                </Async.Root>
              </Modal.Body>

              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button>Cancel</Button>
                </Modal.RawClose>
                <Button
                  customType="primary"
                  onClick={submitForm}
                  isLoading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                >
                  Add
                </Button>
              </Modal.FooterButtonGroup>
            </>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
