import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { Label } from "components/DesignSystem/TextInput/TextInput";

export const SORT_BY = {
  LATEST: "LATEST",
  OLD_NEW: "OLD_NEW",
} as const;

export const SORT_ORDER = {
  [SORT_BY.LATEST]: "ASC",
  [SORT_BY.OLD_NEW]: "DSC",
} as const;

type SortByFilterProps = {
  updateFilter: <S extends "SORT_BY">(
    name: S,
    newValue: { SORT_BY: keyof typeof SORT_BY }[S]
  ) => void;
  values: {
    SORT_BY: keyof typeof SORT_BY;
  };
};

export const SortByFilter = ({ updateFilter, values }: SortByFilterProps) => {
  return (
    <>
      <Label htmlFor="SORT_BY">Sort by</Label>
      <RadioGroup.Root
        id="SORT_BY"
        onValueChange={(value: keyof typeof SORT_BY) => {
          updateFilter("SORT_BY", value);
        }}
        value={values.SORT_BY}
      >
        <RadioGroup.Content>
          <RadioGroup.Item value={SORT_BY.LATEST}>Latest</RadioGroup.Item>
          <RadioGroup.Item value={SORT_BY.OLD_NEW}>Old - New</RadioGroup.Item>
        </RadioGroup.Content>
      </RadioGroup.Root>
    </>
  );
};
