import React, { createContext, ReactNode, useContext } from "react";
import classNames from "classnames";
import { WhiteTick } from "components/icons/WhiteTick";
import { LargeWhiteTick } from "components/icons/LargeWhiteTick";
import { SmallWhiteTick } from "components/icons/SmallWhiteTick";

export type StepperProps = {
  children: ReactNode | string;
  size?: "xs" | "small" | "regular" | "large";
  direction?: "vertical" | "horizontal";
};

type StepperContextValue = Omit<StepperProps, "children">;

type StepProps = {
  step: number;
  isActive?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  isDisabled?: boolean;
  notFirstStep?: boolean;
  notLastStep?: boolean;
  description?: string;
  children: ReactNode;
  onClick?: () => void;
  clickable?: boolean;
};

const StepperContext = createContext<StepperContextValue | undefined>(
  undefined
);

const Step = ({
  children,
  step,
  isActive,
  isCompleted,
  isPending,
  notFirstStep,
  notLastStep,
  isDisabled,
  description,
  onClick,
  clickable,
}: StepProps) => {
  const context = useContext(StepperContext);
  const { size, direction } = context || {};
  let whiteTick;

  switch (size) {
    case "large":
      whiteTick = <LargeWhiteTick />;
      break;
    case "small":
      whiteTick = <SmallWhiteTick />;
      break;
    case "xs":
      whiteTick = <SmallWhiteTick />;
      break;
    default:
      whiteTick = <WhiteTick />;
      break;
  }

  return (
    <>
      <div
        data-size={size}
        data-direction={direction}
        className={classNames(
          "t-flex t-items-center data-[size=small]:t-h-6 data-[size=small]:t-min-w-6 data-[size=xs]:t-h-4 data-[size=xs]:t-min-w-4  data-[size=regular]:t-h-8 data-[size=regular]:t-min-w-8 data-[size=large]:t-h-10 data-[size=large]:t-min-w-10 data-[direction=vertical]:t-gap-4",
          {
            "t-cursor-pointer": clickable,
          }
        )}
        onClick={() => clickable && onClick?.()}
      >
        <div
          data-size={size}
          data-direction={direction}
          className={classNames(
            "t-border t-flex t-items-center t-justify-center t-border-solid data-[size=large]:t-border-[2.5px] data-[size=regular]:t-border-[2px] data-[size=small]:t-border-[1.5px] t-rounded-full data-[size=small]:t-h-6 data-[size=small]:t-min-w-6 data-[size=xs]:t-h-4 data-[size=xs]:t-min-w-4 data-[size=regular]:t-h-8 data-[size=regular]:t-min-w-8 data-[size=large]:t-h-10 data-[size=large]:t-min-w-10 data-[size=large]:t-text-body-lg data-[size=regular]:t-text-body-sm data-[size=small]:t-text-body-sm data-[direction=horizontal]:t-mr-2",
            {
              "t-border-purple-50 t-bg-purple-50 t-text-purple-0 !t-font-semibold":
                isActive && !isDisabled && !isCompleted,
              "t-border-dark_green-50 t-bg-dark_green-50":
                isCompleted && !isDisabled,
              "t-border-neutral-20 t-bg-neutral-0 t-text-neutral-30":
                (isPending || isDisabled) && !isCompleted,
            }
          )}
        >
          {isCompleted ? whiteTick : step}
        </div>
        <div>
          <div
            data-size={size}
            data-direction={direction}
            className={classNames(
              "data-[size=small]:t-text-subtext data-[size=xs]:t-text-body-sm data-[size=regular]:t-text-subtitle data-[size=large]:t-text-title-h2-bold",
              {
                "t-text-text-100": !isDisabled && !isActive,
                "t-text-neutral-30": isDisabled && !isActive,
                "t-text-purple-50": isActive,
              }
            )}
          >
            {children}
          </div>
          {description && (
            <div
              data-size={size}
              data-direction={direction}
              className={classNames(
                "data-[size=small]:t-text-body-sm data-[size=xs]:t-text-caption data-[size=regular]:t-text-body data-[size=large]:t-text-body-lg",
                {
                  "t-text-neutral": !isDisabled,
                  "t-text-neutral-20": isDisabled,
                }
              )}
            >
              {description}
            </div>
          )}
        </div>

        {notLastStep && (
          <div
            data-size={size}
            data-direction={direction}
            className={classNames(
              "t-h-0.5 data-[size=small]:t-ml-3 data-[size=small]:t-w-6 data-[size=regular]:t-ml-4 data-[size=regular]:t-w-8 data-[size=large]:t-ml-5 data-[size=large]:t-w-10 data-[direction=vertical]:t-hidden data-[direction=horizontal]:!t-mx-2",
              {
                "t-bg-green-80": isCompleted,
                "t-bg-purple-10": !isCompleted || isActive,
              }
            )}
          ></div>
        )}
      </div>
      {notLastStep && (
        <div
          data-size={size}
          data-direction={direction}
          className={classNames(
            "t-w-0.5 data-[direction=horizontal]:t-hidden data-[size=small]:t-ml-3 data-[size=small]:t-h-6 data-[size=regular]:t-ml-4 data-[size=regular]:t-h-8 data-[size=large]:t-ml-5 data-[size=large]:t-h-10",
            {
              "t-bg-purple-10": (!isCompleted && isActive) || !isCompleted,
              "t-bg-green-80": isCompleted,
            }
          )}
        ></div>
      )}
    </>
  );
};

export const Stepper = ({
  size = "regular",
  direction = "vertical",
  children,
}: StepperProps) => {
  const contextValue = { size, direction };
  return (
    <StepperContext.Provider value={contextValue}>
      <div
        className={classNames("t-flex t-flex-wrap", {
          "t-flex-col t-gap-y-2": direction === "vertical",
          "t-gap-x-2": direction === "horizontal",
        })}
      >
        {children}
      </div>
    </StepperContext.Provider>
  );
};

Stepper.Step = Step;
