import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { CalendarIcon } from "components/icons/CalendarIcon";
import { ClockCountdown } from "components/icons/ClockCountdown";
import {
  TICKET_ETD_APPROACHING,
  TICKET_ETD_TODAY_OR_PASSED,
  TICKET_ETD_UNSET,
} from "constants/ticket";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik } from "formik";
import { useModal } from "hooks/useModal";
import "react-datepicker/dist/react-datepicker.css";
import { ChatTicket } from "types/Models/chatTicket";
import { formatDate } from "utils/formatDate";
import { TicketEtdModal } from "./TicketEtdModal";
import { useToast } from "hooks/useToast";
import { useAddTicketETDMutation } from "store/apis/chatTicket";
import { YYYY_MM_DD } from "constants/date";
import { BackendError } from "types/utils/error";

export const TicketETD = ({ rowData }: { rowData: ChatTicket }) => {
  const etdModal = useModal();
  const today = dayjs();
  const etd = dayjs(rowData.etd_date);
  const { alertToast, successToast } = useToast();
  const [addTicketEtd, { isLoading: isAdding }] = useAddTicketETDMutation();

  let etdStatus = "TICKET_ETD_UNSET";

  if (!rowData.etd_date) {
    etdStatus = TICKET_ETD_UNSET;
  } else if (etd.isSame(today, "day") || etd.isBefore(today, "day")) {
    etdStatus = TICKET_ETD_TODAY_OR_PASSED;
  } else {
    etdStatus = TICKET_ETD_APPROACHING;
  }

  const addEtd = async (date: string) => {
    try {
      await addTicketEtd({
        etd_date: date,
        ticketId: rowData.uuid,
      }).unwrap();
      successToast({ message: "ETD added successfully" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <Formik
        onSubmit={() => {}}
        initialValues={{ estimated_delivery_time: rowData.etd_date }}
      >
        {({ values, setFieldValue, resetForm }) => (
          <Form className="t-w-full t-m-0" onClick={(e) => e.stopPropagation()}>
            <Field name="estimated_delivery_time">
              {({ field }: FieldProps) => {
                return (
                  <DateInput
                    {...field}
                    portalId="estimated_delivery_time_portal"
                    {...(true && {
                      customDatePickerInputTrigger: (
                        <span>
                          <ConditionalToolTip
                            condition={
                              rowData.etd_date && formatDate(rowData.etd_date)
                            }
                            sideOffset={-10}
                          >
                            <span>
                              <Button type="button" customType="transparent">
                                {etdStatus === TICKET_ETD_UNSET ? (
                                  <span className="t-text-text-30">
                                    <CalendarIcon />
                                  </span>
                                ) : (
                                  <span
                                    className={classNames({
                                      "t-text-yellow-70":
                                        etdStatus === TICKET_ETD_APPROACHING,
                                      "t-text-red":
                                        etdStatus ===
                                        TICKET_ETD_TODAY_OR_PASSED,
                                    })}
                                  >
                                    <ClockCountdown />
                                  </span>
                                )}
                              </Button>
                            </span>
                          </ConditionalToolTip>
                        </span>
                      ),
                    })}
                    onDateChange={(date) => {
                      setFieldValue("estimated_delivery_time", date);
                      if (rowData.etd_date) etdModal.open();
                      else addEtd(dayjs(date).format(YYYY_MM_DD));
                    }}
                    minDate={today.toDate()}
                  />
                );
              }}
            </Field>
            {etdModal.isOpen && values.estimated_delivery_time && (
              <TicketEtdModal
                isOpen={etdModal.isOpen}
                close={() => {
                  etdModal.close();
                  resetForm();
                }}
                date={values.estimated_delivery_time}
                ticketId={rowData.uuid}
              />
            )}
          </Form>
        )}
      </Formik>
    </span>
  );
};
