import { useDocPreview } from "hooks/useDocPreview";
import { useDrop } from "react-dnd";
import {
  useDeleteFileFromTaskMutation,
  useUploadToFormInstanceMutation,
} from "store/apis/task";
import { SelecteFile } from "types/Models/documents";
import { DocumentField } from "types/Models/task";
import { FileInput, FileType } from "./FileInput/FileInput";
import { DocumentPreviewModal } from "./PreviewModal";

type FormUploadDocumentsProps = {
  field: DocumentField;
  formInstanceId: string;
  groupId: string;
  suggestedDocUpload: () => void;
  docInstanceId?: string;
};

export const FormUploadDocuments = ({
  field: { documents, field_label, label, field_key, tag, required },
  formInstanceId,
  docInstanceId,
  groupId,
  suggestedDocUpload,
}: FormUploadDocumentsProps) => {
  const openPreview = useDocPreview();
  const [uploadDoc, { isLoading: isUploading }] =
    useUploadToFormInstanceMutation();
  const [deleteDoc, { isLoading: isDeleting, originalArgs }] =
    useDeleteFileFromTaskMutation();

  const onDrop = async (newFiles: FileType[]) => {
    try {
      for (let index = 0; index < newFiles.length; index++) {
        const newFile = newFiles[index];
        const fileToSign =
          "uuid" in newFile! ? { file_id: newFile.uuid } : { file: newFile };

        try {
          await uploadDoc({
            groupId,
            formInstanceId,
            tag,
            fieldKey: field_key,
            ...fileToSign,
          }).unwrap();
        } catch (error) {}
      }
    } catch (error) {}
  };

  const onSuggestedDocDrop = async ({ data }: { data: string }) => {
    const doc: SelecteFile = JSON.parse(data);
    await onDrop([doc]);
    suggestedDocUpload();
  };

  const deleteFile = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index?: number
  ) => {
    e?.stopPropagation();

    const fileId = documents.find((f, i) => i === index)?.uuid;
    if (fileId && index !== undefined) {
      try {
        await deleteDoc({
          groupId,
          fileId,
          formInstanceId,
          fieldKey: field_key,
        }).unwrap();
      } catch (error) {}
    }
  };

  const [{ isOver }, drop] = useDrop(() => ({
    accept: "doc",
    drop: onSuggestedDocDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  }));

  return (
    <div ref={drop}>
      <FileInput
        label={field_label || label}
        onDrop={onDrop}
        multiple
        files={documents}
        onFileClick={({ uuid }) => openPreview(uuid)}
        onDelete={deleteFile}
        fileTag={field_key}
        isUploading={isUploading}
        isDeleting={isDeleting}
        fileDeletingUuid={originalArgs?.fileId}
        isDragAccept={isOver}
        withInkleDocs
        withForm
        name={field_key}
        required={required}
      />
      <DocumentPreviewModal />
    </div>
  );
};
