import { AmountSuperScript } from "components/design/AmountSuperScript";
import { TableUI } from "components/design/TableUI";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { OptionDropdown } from "components/icons/optionDropdown";
import { PdfFile } from "components/icons/PdfFile";
import { Preview } from "components/PreviewModal";
import { DD_MMM_YYYY } from "constants/date";
import {
  BILL_STATUS,
  BILL_STATUS_LABEL,
  DATE_HEADER,
  FINANCIAL_RECORD_TYPE,
  STATUS_HEADER,
  TAG_TYPE,
} from "constants/vendorBills";
import dayjs from "dayjs";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { MouseEvent, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  useDeleteBillOrRequestMutation,
  useLazyDownloadBillOrRequestQuery,
  useUpdateBillOrRequestMutation,
} from "store/apis/vendorBills";
import { VendorBill } from "types/Models/vendorBills";
import { BackendError } from "types/utils/error";
import { BillSlider } from "./BillSlider";

type BillListProps = {
  bills: VendorBill[];
};

const columnHelper = createColumnHelper<VendorBill>();

const StatusHeader = () => {
  const query = useQuery();
  const billType = query.get("BILL_TYPE");
  return STATUS_HEADER[billType as keyof typeof FINANCIAL_RECORD_TYPE];
};

const DateHeader = () => {
  const query = useQuery();
  const billType = query.get("BILL_TYPE");

  return DATE_HEADER[billType as keyof typeof DATE_HEADER];
};

const ActionItems = ({
  bill,
  onStatusChange,
  onSendRequest,
  onDownload,
  onDelete,
}: {
  bill: VendorBill;
  onDelete: () => void;
  onDownload: () => void;
  onSendRequest: () => void;
  onStatusChange: (args: { status: keyof typeof BILL_STATUS }) => void;
}) => {
  const isBill = bill.bill_document_type === FINANCIAL_RECORD_TYPE.BILL;
  const status = isBill ? bill.bill_payment_status : bill.bill_request_status;

  switch (status) {
    case BILL_STATUS.DRAFT: {
      return (
        <>
          {isBill ? (
            <Dropdown.Item
              onSelect={() => onStatusChange({ status: BILL_STATUS.DUE })}
            >
              Mark as Active
            </Dropdown.Item>
          ) : (
            <Dropdown.Item onSelect={onSendRequest}>Send Request</Dropdown.Item>
          )}
          {!isBill && (
            <Dropdown.Item onSelect={onDownload}>Download</Dropdown.Item>
          )}
          <Dropdown.Item type="danger" onSelect={onDelete}>
            Delete
          </Dropdown.Item>
        </>
      );
    }
    case BILL_STATUS.DUE: {
      return (
        <>
          <Dropdown.Item
            onSelect={() => onStatusChange({ status: BILL_STATUS.PAID })}
          >
            Mark as Paid
          </Dropdown.Item>
          {!isBill && (
            <Dropdown.Item onSelect={onDownload}>Download</Dropdown.Item>
          )}
          <Dropdown.Item type="danger" onSelect={onDelete}>
            Delete
          </Dropdown.Item>
        </>
      );
    }
    case BILL_STATUS.OVERDUE: {
      return (
        <>
          <Dropdown.Item
            onSelect={() => onStatusChange({ status: BILL_STATUS.PAID })}
          >
            Mark as Paid
          </Dropdown.Item>
          {!isBill && (
            <Dropdown.Item onSelect={onDownload}>Download</Dropdown.Item>
          )}
          <Dropdown.Item type="danger" onSelect={onDelete}>
            Delete
          </Dropdown.Item>
        </>
      );
    }
    case BILL_STATUS.PAID: {
      return (
        <>
          {!isBill && (
            <Dropdown.Item onSelect={onDownload}>Download</Dropdown.Item>
          )}
        </>
      );
    }
    case BILL_STATUS.IN_REVIEW: {
      return (
        <>
          {!isBill && (
            <Dropdown.Item onSelect={onDownload}>Download</Dropdown.Item>
          )}
        </>
      );
    }
    case BILL_STATUS.APPROVED: {
      return (
        <>
          {!isBill && (
            <Dropdown.Item onSelect={onDownload}>Download</Dropdown.Item>
          )}
        </>
      );
    }
  }
};

const InvoiceNumber = ({ bill }: { bill: VendorBill }) => {
  const history = useHistory();
  const { link } = useConstructInternalLink();
  const previewModal = useModal();

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (bill.bill_document_type === FINANCIAL_RECORD_TYPE.REQUEST) {
      history.push(
        link(`/books/bills-and-vendors/request/${bill.uuid}`, {
          moreQuery: { BILL_TYPE: FINANCIAL_RECORD_TYPE.REQUEST },
        })
      );
      return;
    }
    if (bill.bill_document_type === FINANCIAL_RECORD_TYPE.BILL) {
      history.push(
        link(`/books/bills-and-vendors/bill/${bill.uuid}`, {
          moreQuery: { BILL_TYPE: FINANCIAL_RECORD_TYPE.BILL },
        })
      );
      return;
    }
  };

  const isBill = bill.bill_document_type === FINANCIAL_RECORD_TYPE.BILL;
  const status = isBill ? bill.bill_payment_status : bill.bill_request_status;
  const isDraft = status === BILL_STATUS.DRAFT;

  return (
    <>
      <div className="t-flex t-items-center t-gap-1">
        <Button
          customType={isDraft ? "link" : "transparent"}
          onClick={isDraft ? onClick : undefined}
          disabled={!isDraft}
        >
          {bill.bill_number || "-"}
        </Button>
        {bill.bill_document?.uuid && (
          <Button
            size="small"
            customType="ghost_icon"
            onClick={(e) => {
              e.stopPropagation();
              previewModal.open();
            }}
          >
            <span className="t-text-text-30">
              <PdfFile />
            </span>
          </Button>
        )}
      </div>

      <Preview
        closeModal={previewModal.close}
        showModal={previewModal.isOpen}
        previewId={bill.bill_document?.uuid}
      />
    </>
  );
};

const Action = ({ bill }: { bill: VendorBill }) => {
  const billId = bill.uuid;
  const isBill = bill.bill_document_type === FINANCIAL_RECORD_TYPE.BILL;
  const history = useHistory();
  const { alertToast, successToast } = useToast();
  const { path } = useRouteMatch();
  const { link } = useConstructInternalLink();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [deleteBillOrRequest, deletingDtate] = useDeleteBillOrRequestMutation();
  const [update, updateState] = useUpdateBillOrRequestMutation();
  const [downloadBill, downloadState] = useLazyDownloadBillOrRequestQuery();
  const [getUrl, previewUrlState] = useLazyGetPreviewUrlQuery();

  const isLoading =
    downloadState.isFetching ||
    deletingDtate.isLoading ||
    updateState.isLoading ||
    previewUrlState.isFetching;

  const onSendRequest = () => {
    history.push(
      link(`${path}/send/${bill.uuid}`, {
        moreQuery: { BILL_TYPE: FINANCIAL_RECORD_TYPE.REQUEST },
      })
    );
  };

  const onStatusChange = async ({
    status,
  }: {
    status: keyof typeof BILL_STATUS;
  }) => {
    try {
      const payload = isBill
        ? {
            bill_payment_status: status,
          }
        : {
            bill_request_status: status,
          };

      await update({
        billId,
        entityId,
        groupId,
        payload: payload,
      }).unwrap();
      successToast({
        message: `Status updated to ${status.toLocaleLowerCase()}`,
      });
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError)?.data?.error?.message,
        },
        error as Error
      );
    }
  };

  const onDownload = async () => {
    try {
      const { doc_id } = await downloadBill({
        entityId,
        groupId,
        billId,
      }).unwrap();
      const { download_url } = await getUrl({
        fileId: doc_id,
        groupId,
      }).unwrap();
      window.open(download_url, "_blank");
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError)?.data?.error?.message,
        },
        error as Error
      );
    }
  };

  const onDelete = async () => {
    try {
      await deleteBillOrRequest({
        billId,
        groupId,
        entityId,
      }).unwrap();
      successToast({
        message: isBill ? "Bill deleted" : "Request deleted",
      });
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as Error
      );
    }
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild disabled={isLoading}>
        <Button
          onClick={(e) => e.stopPropagation()}
          customType="ghost_icon"
          size="small"
          isLoading={isLoading}
          disabled={isLoading}
        >
          <span className="t-text-text-30">
            <OptionDropdown />
          </span>
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content align="end" onClick={(e) => e.stopPropagation()}>
        <ActionItems
          bill={bill}
          onDelete={onDelete}
          onDownload={onDownload}
          onSendRequest={onSendRequest}
          onStatusChange={onStatusChange}
        />
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export const billColumns = {
  bill_date: (size = 15) =>
    columnHelper.accessor("bill_date", {
      header: "BILL DATE",
      size,
      cell: ({ getValue, row }) => {
        const date = getValue();
        return (
          <div className="t-text-text-60 t-text-body">
            {date ? `${dayjs(getValue()).format(DD_MMM_YYYY)}` : "-"}
          </div>
        );
      },
    }),

  bill_number: (size = 10) =>
    columnHelper.accessor("bill_number", {
      header: "BILL NUMBER",
      size,
      cell: ({ row }) => {
        return <InvoiceNumber bill={row.original} />;
      },
    }),

  entity_merchant_data: (size = 15) =>
    columnHelper.accessor("entity_merchant_data", {
      header: "VENDOR",
      size,
      cell: ({ getValue }) => {
        const vendor = getValue();
        return (
          <>
            <div className="t-text-body t-text-text-60">
              {vendor?.name || "-"}
            </div>
            <div className="t-text-text-30 t-text-body-sm">{vendor?.email}</div>
          </>
        );
      },
    }),

  due_balance: (size = 15) =>
    columnHelper.accessor("due_balance", {
      header: () => <p className="t-text-right t-m-0 t-w-full">Amount</p>,
      size,
      cell: ({ getValue }) => {
        return (
          <div className="t-text-body t-text-right t-text-text-60">
            <AmountSuperScript amount={Number(getValue() || 0)} />
          </div>
        );
      },
    }),

  due_date: (size = 20, header?: string) =>
    columnHelper.accessor("due_date", {
      header: () => header || <DateHeader />,
      size,
      cell: ({ row }) => {
        const date =
          row.original.bill_document_type === FINANCIAL_RECORD_TYPE.BILL
            ? row.original.due_date
            : row.original.bill_request_date;

        return (
          <div className="t-text-text-60 t-text-body">
            {date ? `${dayjs(date).format(DD_MMM_YYYY)}` : "-"}
          </div>
        );
      },
    }),

  bill_request_status: (size = 20) =>
    columnHelper.accessor("bill_request_status", {
      header: () => <StatusHeader />,
      size,
      cell: ({ row }) => {
        const status =
          row.original.bill_document_type === FINANCIAL_RECORD_TYPE.BILL
            ? row.original.bill_payment_status
            : row.original.bill_request_status;

        return (
          <Tag tagType={TAG_TYPE[status]} rounded icon={false}>
            {BILL_STATUS_LABEL[status]}
          </Tag>
        );
      },
    }),

  action: (size = 1) =>
    columnHelper.accessor("uuid", {
      header: "",
      size,
      cell: ({ row }) => {
        return <Action bill={row.original} />;
      },
    }),

  title: (size = 5) =>
    columnHelper.accessor("title", {
      header: "Title",
      size,
      cell: (info) => {
        return (
          <div className="t-text-text-60 t-text-body">{info.getValue()}</div>
        );
      },
    }),
};

const columns = [
  billColumns.bill_number(),
  billColumns.entity_merchant_data(),
  billColumns.bill_date(),
  billColumns.due_date(),
  billColumns.due_balance(),
  billColumns.bill_request_status(),
  billColumns.action(),
];

export const BillList = ({ bills }: BillListProps) => {
  const slider = useModal();
  const [billId, setBillId] = useState<string | undefined>();

  const table = useReactTable({
    data: bills,
    columns: columns,
    defaultColumn: {
      minSize: 5,
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const openSlider = ({ billId }: { billId: string }) => {
    setBillId(billId);
    slider.open();
  };
  const closeSlider = () => {
    setBillId(undefined);
    slider.close();
  };

  return (
    <>
      <TableUI
        table={table}
        onRowClick={(row) => {
          openSlider({
            billId: row.original.uuid,
          });
        }}
      />
      {billId && (
        <BillSlider
          close={closeSlider}
          isOpen={slider.isOpen}
          billId={billId}
        />
      )}
    </>
  );
};
