import classNames from "classnames";
import Table from "components/DesignSystem/Table/Table";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTable } from "react-table";
import {
  useGetTaskFromChannelIdQuery,
  useGetTaskInvoicesQuery,
} from "store/apis/task";
import { useChatContext } from "stream-chat-react";

export const BalancingPaymentInvoiceHistory = () => {
  const { channel: currentChannel } = useChatContext();
  const { data: channelTask } = useGetTaskFromChannelIdQuery(
    {
      channelId: currentChannel?.id!,
    },
    {
      skip: !currentChannel?.id,
    }
  );
  const { tasks, company_group: companyGroup } = channelTask || {};
  const task = tasks?.[0];
  const { uuid: groupUuid } = useCurrentGroupContext();
  const { taskId: taskUuid } = useParams<{ taskId: string }>();
  const groupId = companyGroup?.uuid || groupUuid;
  const taskId = task?.uuid || taskUuid;
  const { data: invoices = [] } = useGetTaskInvoicesQuery(
    { groupId, taskId },
    { skip: !groupId || !taskId }
  );

  const columns = useMemo(
    () => [
      {
        Header: "TITLE",
        accessor: "name",
        width: "55%",
        textAlign: "left",
        Cell: ({ row }: { row: { original: { name: string } } }) => {
          return (
            <div className="t-text-body-sm t-text-text-100">
              {row.original.name}
            </div>
          );
        },
      },
      {
        Header: "AMOUNT",
        accessor: "amount",
        width: "25%",
        textAlign: "left",
        Cell: ({
          row,
        }: {
          row: { original: { amount: string; status: string } };
        }) => {
          return (
            <div
              className={classNames("t-text-body-sm t-text-text-100", {
                "t-text-red": row.original.status === "pending",
              })}
            >
              ${row.original.amount}
            </div>
          );
        },
      },
      {
        Header: "STATUS",
        accessor: "status",
        textAlign: "left",
        width: "20%",
        Cell: ({ row }: { row: { original: { status: string } } }) => {
          return (
            <div className="t-text-body-sm t-text-text-100">
              {row.original.status}
            </div>
          );
        },
      },
    ],
    []
  );

  const data = useMemo(() => invoices, [invoices]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      // @ts-ignore
      columns,
      // @ts-ignore
      data,
    });

  if (invoices?.length === 0) {
    return <></>;
  }

  return (
    <div className="t-mb-6">
      <div className="t-font-sans t-text-body-sm t-text-neutral-80 t-pb-1.5 ">
        Invoice history
      </div>
      <Table.Container className="!t-h-fit t-max-h-full">
        <Table.Content {...getTableProps()} className="all:unset">
          <Table.Head>
            {headerGroups.map((headerGroup) => (
              <Table.Row
                // @ts-ignore
                key={headerGroup.id}
                {...headerGroup.getHeaderGroupProps()}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    className="t-text-start t-text-subtext-sm"
                    // @ts-ignore
                    key={column.id}
                    {...column.getHeaderProps({})}
                    style={{
                      width: column?.width,
                      // @ts-ignore
                      textAlign: column?.textAlign,
                      // @ts-ignore
                      marginRight: column?.marginRight,
                    }}
                  >
                    {column.render("Header")}
                  </th>
                ))}
              </Table.Row>
            ))}
          </Table.Head>

          <div className="t-max-h-28 t-overflow-scroll">
            <Table.Body {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Table.Row
                    className="t-flex t-items-center t-justify-center t-gap-6 t-border-b t-border-r-0 t-border-t-0 t-border-solid t-border-i-neutral-10 t-border-l-surface-transparent t-px-4 t-py-2"
                    // @ts-ignore
                    key={row.id}
                    {...row.getRowProps()}
                  >
                    {row.cells.map((cell) => {
                      return (
                        <td
                          // @ts-ignore
                          key={cell.value}
                          {...cell.getCellProps({})}
                          style={{
                            width: cell.column?.width,
                            // @ts-ignore
                            textAlign: cell.column?.textAlign,
                            // @ts-ignore
                            marginRight: cell.column?.marginRight,
                          }}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </Table.Row>
                );
              })}
            </Table.Body>
          </div>
        </Table.Content>
      </Table.Container>
    </div>
  );
};
