import { MessageResponse } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import cx from "classnames";
import dayjs from "dayjs";
import { getWholeChar, useChatContext } from "stream-chat-react";
import { useDispatch } from "react-redux";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import { formatDate } from "utils/formatDate";
import { resetReviewBalancePaymentState } from "store/slices/reviewAndBalancePayment";

export const Avatar = ({
  image,
  user,
}: {
  image?: string;
  user?: { name?: string } | null;
}) => {
  if (image) {
    return <img src={image} alt="Pic" className="t-h-7 t-w-7 t-rounded-full" />;
  }

  if (user?.name) {
    return (
      <div className="t-flex t-h-7 t-w-7 t-items-center t-justify-center t-rounded-full t-bg-purple-20 t-text-text-60">
        <span>{getWholeChar(user.name?.toString(), 0)}</span>
      </div>
    );
  }

  return null;
};

export const MessageSearchPreview = ({
  message,
  searchTerm,
  onSelect,
}: {
  message: MessageResponse<DefaultStreamChatGenerics>;
  searchTerm: string;
  onSelect?: (channelId: string, messageId: string) => void;
}) => {
  const { setActiveChannel, channel: activeChannel } = useChatContext();
  const { client } = useChatContext();
  const dispatch = useDispatch();
  const messageCreatedAt = message?.created_at;
  const channel = message.channel;
  const messageSentToday = dayjs(messageCreatedAt).isToday();
  const messageSentYesterday = dayjs(messageCreatedAt).isYesterday();
  const messageSentPastWeek = dayjs().diff(dayjs(messageCreatedAt), "days") < 7;
  const isChannelOpen = channel?.id === activeChannel?.id;
  const messageToScrollTo = useSelector(
    (state: RootState) => state.messageToScrollTo.messageId
  );
  const isMessageOpen = message.id === messageToScrollTo;
  const showMentionedMessages = useSelector(
    (state: RootState) => state.chatSearch.showMentionedMessages
  );

  let date = formatDate(messageCreatedAt);

  if (messageSentPastWeek) {
    date = dayjs(messageCreatedAt).format("dddd");
  }

  if (messageSentToday) {
    date = dayjs(messageCreatedAt).format("hh:mm a");
  }

  if (messageSentYesterday) {
    date = "Yesterday";
  }

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onSelect && channel?.id && message.id) {
      return onSelect(channel.id, message.id);
    }
    if (channel?.id) {
      const channelData = client.channel("messaging", channel?.id, {});
      setActiveChannel(channelData);
      dispatch(setScrollToMessageId(message.id));
      dispatch(resetReviewBalancePaymentState());
    }
  };

  return (
    <button
      aria-label={`Select Channel: ${message.channel?.name || ""}`}
      aria-selected={isMessageOpen}
      className={cx(
        "all:unset t-group t-relative t-box-border t-flex t-h-[92px] t-w-full t-gap-2 t-pb-2 t-pl-6 t-pr-4 t-pt-5",
        {
          "t-bg-surface-purple": isChannelOpen && isMessageOpen,
          "t-bg-white hover:t-bg-surface-lighter-grey": !isChannelOpen,
          // "t-border-b-surface t-border-l-0 t-border-t-0 t-border-r-0 t-border-solid":
          //   unreplied,
          // // @ts-ignore
          // [unrepliedDateDiffColor.componentClassName]:
          //   channel?.data?.needs_reply && unreplied,
        }
      )}
      onClick={onSelectChannel}
      // ref={channelPreviewButton}
      role="option"
    >
      {isMessageOpen && (
        <div className="t-absolute t-left-0 t-top-0 t-h-full t-border-0 t-border-l-4 t-border-solid t-border-purple-40" />
      )}

      <div className="t-flex t-h-10 t-w-10 t-shrink-0 t-justify-center t-rounded-full">
        <Avatar image={message?.user?.image} user={message?.user} />
      </div>

      <div className="t-flex t-flex-col t-leading-[normal]">
        <div className="t-text-subtext t-line-clamp-1">
          {!showMentionedMessages && (
            <>
              {message.user?.name} {">"}
            </>
          )}
          {message.channel?.name}
        </div>

        <div className="t-mt-1 t-w-56 t-break-all t-text-body-sm t-text-text-30 t-line-clamp-1">
          {message.text && (
            <HighlightSearchTerm text={message.text} searchTerm={searchTerm} />
          )}
        </div>
        <div className="t-mt-1.5 t-text-caption t-text-neutral-40">{date}</div>
      </div>
    </button>
  );
};
