import * as RUToggleGroup from "@radix-ui/react-toggle-group";
import classNames from "classnames";
import { motion } from "framer-motion";
import {
  createContext,
  forwardRef,
  useContext,
  useEffect,
  useRef,
  useState,
  type ReactNode,
} from "react";

type Size = "small" | "regular";
type Variant = "primary" | "secondary";

type ContextType = {
  size: Size;
  variant: Variant;
};

type ToggleGroupProps = Omit<RUToggleGroup.ToggleGroupSingleProps, "type"> & {
  size?: Size;
  variant?: Variant;
  type?: RUToggleGroup.ToggleGroupSingleProps["type"];
  children: ReactNode;
  className?: string;
};

const ToggleGroupContext = createContext<ContextType>({
  size: "regular",
  variant: "secondary",
});

export const ToggleGroup = ({
  className = "",
  type = "single",
  size = "regular",
  variant = "secondary",
  children,
  ...props
}: ToggleGroupProps) => {
  const [selected, setSelected] = useState(props.defaultValue || props.value);
  const [highlightStyle, setHighlightStyle] = useState({
    left: 0,
    width: 0,
    height: 0,
  });
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!selected || !containerRef.current) return;

    const selectedButton = containerRef.current.querySelector(
      `[data-value="${selected}"]`
    ) as HTMLButtonElement | null;

    if (selectedButton) {
      setHighlightStyle({
        left: selectedButton.offsetLeft,
        width: selectedButton.offsetWidth,
        height: selectedButton.offsetHeight,
      });
    }
  }, [selected]);

  return (
    <ToggleGroupContext.Provider value={{ size, variant }}>
      <RUToggleGroup.Root
        ref={containerRef}
        type="single"
        {...props}
        className={classNames(
          "t-inline-flex t-rounded-lg t-bg-surface-lighter-grey t-text-center t-p-1 t-border t-border-solid t-border-surface-grey t-gap-1 t-relative",
          className
        )}
        onValueChange={(v) => {
          if (!v) return;
          setSelected(v);
          props.onValueChange?.(v);
        }}
      >
        <motion.div
          className="t-absolute t-inset-y-1 t-rounded-md t-bg-surface t-shadow-sm"
          animate={highlightStyle}
          transition={{ type: "spring", bounce: 0.1, duration: 0.6 }}
        />
        {children}
      </RUToggleGroup.Root>
    </ToggleGroupContext.Provider>
  );
};

export const ToggleGroupItem = forwardRef<
  HTMLButtonElement,
  RUToggleGroup.ToggleGroupItemProps
>(({ value, ...props }, ref) => {
  const { size, variant } = useContext(ToggleGroupContext);

  return (
    <RUToggleGroup.Item
      {...props}
      ref={ref}
      value={value}
      data-value={value}
      tabIndex={0}
      className={classNames(
        "all:unset t-text-subtext-sm t-flex-1 t-text-center t-flex t-items-center t-justify-center t-text-text-30 t-z-10",
        {
          "t-py-2 t-px-3": size === "regular",
          "t-py-1 t-px-2 t-justify-center": size === "small",
          "data-[state=on]:t-text-text-60 hover:t-text-text-60":
            variant === "secondary",
          "data-[state=on]:t-text-purple hover:t-text-purple":
            variant === "primary",
        }
      )}
    />
  );
});
