import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowDown } from "components/icons/ArrowDown";
import { CloudUploadSmall } from "components/icons/CloudUploadSmall";
import { PlusIcon } from "components/icons/PlusIcon";
import { FINANCIAL_RECORD_TYPE } from "constants/vendorBills";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { parse, stringify } from "qs";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { useAddBillOrRequestMutation } from "store/apis/vendorBills";
import { BackendError } from "types/utils/error";
import { BulkBillUpload } from "./BulkBillUpload";

export const AddRequestAndBill = () => {
  const { alertToast } = useToast();
  const bulkUploadModal = useModal();
  const history = useHistory();
  const { uuid: groupId } = useCurrentGroupContext();
  const location = useLocation();
  const { path } = useRouteMatch();
  const entityId = useCurrentEntityId();

  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const [addBillOrRequest, { isLoading: isAdding, originalArgs }] =
    useAddBillOrRequestMutation();

  const searchQuery = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const handleAddBillOrRequest = async ({
    billType,
  }: {
    billType: keyof typeof FINANCIAL_RECORD_TYPE;
  }) => {
    try {
      const { uuid } = await addBillOrRequest({
        bill_type: billType,
        entityId,
        groupId,
      }).unwrap();
      if (billType === FINANCIAL_RECORD_TYPE.BILL) {
        history.push(`${path}/bill/${uuid}${searchQuery}`);
        return;
      }
      if (billType === FINANCIAL_RECORD_TYPE.REQUEST) {
        history.push(`${path}/request/${uuid}${searchQuery}`);
        return;
      }
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const isRequesting =
    originalArgs?.bill_type === FINANCIAL_RECORD_TYPE.REQUEST && isAdding;
  const isAddingBill =
    originalArgs?.bill_type === FINANCIAL_RECORD_TYPE.BILL && isAdding;

  return (
    <>
      <div className="t-flex t-justify-between t-gap-2">
        <Button
          isLoading={isRequesting}
          disabled={isRequesting}
          size="small"
          customType="primary-outlined"
          onClick={() =>
            handleAddBillOrRequest({
              billType: FINANCIAL_RECORD_TYPE.REQUEST,
            })
          }
        >
          Request bill
        </Button>
        <Dropdown.Root>
          <Dropdown.Trigger asChild className="t-group t-text-text-60">
            <div>
              <Button
                isLoading={isAddingBill}
                disabled={isAddingBill}
                size="small"
                customType="primary"
              >
                Add bill
                <div className="group-data-state-open:-t-rotate-180 t-transform t-transition t-duration-300 t-ease-in-out t-text-surface t-ml-1.5">
                  <ArrowDown color="currentColor" strokeWidth="1.5" />
                </div>
              </Button>
            </div>
          </Dropdown.Trigger>
          <Dropdown.Portal>
            <Dropdown.Content
              sideOffset={8}
              side="bottom"
              className="!t-min-w-40"
              align="end"
            >
              <Dropdown.Item onSelect={bulkUploadModal.open}>
                <div className="t-flex t-items-center t-gap-2">
                  <CloudUploadSmall size="14" />
                  <div>Import .xls/.xlsx file</div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                onSelect={() =>
                  handleAddBillOrRequest({
                    billType: FINANCIAL_RECORD_TYPE.BILL,
                  })
                }
              >
                <div className="t-flex t-items-center t-gap-2">
                  <PlusIcon size="12" />
                  <div>Add bill manually</div>
                </div>
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      </div>
      <BulkBillUpload {...bulkUploadModal} />
    </>
  );
};
