import ConditionalToolTip from "components/design/conditionalToolTip";
import { TableUI } from "components/design/TableUI";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import { MagnifyingGlass } from "components/icons/MagnifyingGlass";
import TransactionColumn from "components/Transaction/TransactionColumn";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useGetAllTransactionsDataQuery } from "store/apis/transactions";
import { FiltersValues } from "store/slices/transactionFilter";
import { Transactions } from "types/Models/books";
import { debounce } from "utils/debouncing";
import { AssignVendorFilter } from "./AssignVendorFilter/AssignVendorFilter";
import { assignVendorFilterValues } from "./AssignVendorFilter/assignVendorFilterData";

export const VendorAssign = ({
  isOpen,
  close,
  season,
}: {
  isOpen: boolean;
  close: () => void;
  season: string;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { update } = useUpdateQuery();
  const query = useQuery();
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  const pageSize = query.get("page_size");
  const { isAdmin } = useRoleBasedView();
  const dispatch = useDispatch();
  let updatedAssignVendorFilterValues = assignVendorFilterValues;
  if (season) {
    updatedAssignVendorFilterValues = {
      ...updatedAssignVendorFilterValues,
      startDate: {
        name: "Since",
        value: dayjs(season).startOf("year").format(YYYY_MM_DD),
        type: "transactionDate",
      },
      endDate: {
        name: "Till",
        value: dayjs(season).endOf("year").format(YYYY_MM_DD),
        type: "transactionDate",
      },
      selectPeriod: {
        name: "Select period",
        value: "CUSTOM",
        type: "transactionDate",
      },
    };
  }

  const [filterValues, setFilterValues] = useState(
    updatedAssignVendorFilterValues
  );

  const {
    pageNum,
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
    setTotalPage,
  } = usePagination();

  const updateFilter = (
    name: keyof typeof filterValues,
    value: FiltersValues
  ) => {
    let updatedValue = filterValues[name];

    setFilterValues((prev) => ({
      ...prev,
      [name]: { ...updatedValue, value },
    }));
  };

  const allFilters = useMemo(
    () => ({
      ...filterValues,
      groupId,
      entityId,
      page_num: pageNum,
      searchTerm,
      uncategorized: filterValues.uncategorised,
      minAmount: ((filterValues.minAmount?.value || "") as string)
        ?.replaceAll("$", "")
        .replaceAll(",", ""),
      maxAmount: ((filterValues.maxAmount?.value || "") as string)
        ?.replaceAll("$", "")
        .replaceAll(",", ""),
      pageSize,
      txnStatus: filterValues.excludedTransactions?.value
        ? ("EXCLUDED" as const)
        : undefined,
      startDate: filterValues.startDate,
      endDate: filterValues.endDate,
    }),
    [
      JSON.stringify(filterValues),
      entityId,
      groupId,
      filterValues.maxAmount?.value,
      filterValues.minAmount?.value,
      pageNum,
      pageSize,
      searchTerm,
      filterValues.uncategorised,
      filterValues.excludedTransactions,
      filterValues.endDate.value,
      filterValues.startDate.value,
    ]
  );

  const {
    data: transactionData,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetAllTransactionsDataQuery(allFilters, {
    skip: !groupId || !entityId,
    refetchOnMountOrArgChange: true,
  });

  const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value);
  });

  const transactionsTableData = useMemo(
    () => transactionData?.transactions || [],
    [isFetching]
  );

  const columnHelper = createColumnHelper<Transactions>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("transaction.date", {
        id: "DATE",
        size: 15,
        header: "Date",
        cell: (info) => (
          <div className="t-text-subtext t-text-text-30">
            {dayjs(info.getValue()).format(DD_MMM_YYYY)}
          </div>
        ),
      }),

      columnHelper.accessor("transaction.from", {
        id: "logo",
        size: 2,
        enableSorting: false,
        header: () => (
          <div className="t-flex t-justify-center t-w-full">Source</div>
        ),
        cell: (info) => {
          const transaction = info.row.original.transaction;

          return <TransactionColumn.Source transaction={transaction} />;
        },
      }),

      columnHelper.accessor("transaction.merchant", {
        id: "VENDOR",
        size: 30,
        header: "VENDOR",
        cell: (info) => {
          return <TransactionColumn.Merchant info={info} showSplitVendors />;
        },
      }),

      columnHelper.accessor("transaction.description", {
        id: "DESCRIPTION",
        enableSorting: false,
        size: 15,
        header: "DESCRIPTION",
        cell: (info) => {
          const description = info.getValue();
          return (
            <ConditionalToolTip condition={description}>
              <span className="t-line-clamp-1">{description || "-"}</span>
            </ConditionalToolTip>
          );
        },
      }),

      columnHelper.accessor("transaction.category", {
        id: "category",
        size: 28,
        header: "Category",
        enableSorting: false,
        cell: (info) => {
          return (
            <TransactionColumn.Category
              info={info}
              tooltip
              truncate
              showSplitActions
            />
          );
        },
      }),

      columnHelper.accessor("transaction.amount", {
        id: "AMOUNT",
        size: 8,
        header: () => (
          <span className="t-flex t-justify-end t-ml-auto">Amount</span>
        ),
        cell: (info) => {
          const amount = info.getValue();
          const {
            transaction: { is_credit_card },
          } = info.row.original || {};

          return (
            <TransactionColumn.Amount
              amount={amount}
              isCreditCard={is_credit_card}
            />
          );
        },
      }),
    ],
    [isFetching]
  );

  const table = useReactTable({
    data: transactionsTableData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    manualSorting: true,
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const {
    transactions = [],
    channel_url = "",
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = transactionData || {};

  useEffect(() => {
    setTotalPage(total_pages);
  }, [total_pages]);

  const isEmpty = transactions.length === 0;

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="xxl" useCustomOverlay>
        <Modal.Header>
          <Modal.Title>Assign Vendors</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-flex t-flex-col t-gap-4">
            <div className="t-flex t-gap-4 t-w-full t-items-center">
              <div className="t-w-1/2">
                <Search block onChange={handleChange} placeholder="Search" />
              </div>
            </div>
            <div className="t-flex t-gap-1">
              <AssignVendorFilter
                updateFilter={updateFilter}
                values={filterValues}
                season={season}
              />

              {isSuccess && (
                <div className="t-ml-auto t-flex-shrink-0">
                  <Pagination
                    {...paginationData}
                    goToFirstPage={goToFirstPage}
                    goToPrevPage={goToPrevPage}
                    goToNextPage={goToNextPage}
                    goToLastPage={goToLastPage}
                    showOptions={isAdmin}
                  />
                </div>
              )}
            </div>
            <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
              <Async.Empty>
                <EmptyScreen text="No transactions found">
                  <span className="t-text-i-neutral-10">
                    <MagnifyingGlass size="149" />
                  </span>
                </EmptyScreen>
              </Async.Empty>
              <Async.Success>
                <TableUI table={table} />
              </Async.Success>
            </Async.Root>
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button type="button">Cancel</Button>
          </Modal.RawClose>
          <Button customType="primary" onClick={close}>
            Proceed
          </Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};
