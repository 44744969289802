import { Divider } from "components/design/Divider";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Chip } from "components/DesignSystem/Chips/Chips";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Filter } from "components/DesignSystem/Filter/Filter";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import { Search } from "components/DesignSystem/Search/Search";
import { DateFilter } from "components/Filters/DateFilter";
import { DropDownItem } from "components/GeneralLedger/GeneralLedgerFilters";
import { NumericInput } from "components/NumericInput/NumericInput";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import { viewByOption, ViewOptions } from "constants/reports";
import dayjs from "dayjs";
import { ACCOUNTING_METHOD } from "dictionaries";
import { Form, Formik, useFormikContext } from "formik";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useUpdateQuery } from "hooks/useQuery";
import { parse, stringify } from "qs";
import { ChangeEvent, ReactNode, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  useGetAllAccountQuery,
  useGetAllMerchantsQuery,
} from "store/apis/transactions";
import { getFilterStatus } from "store/selector/transactionFilter";
import {
  FilterName,
  FiltersValues,
  setTxnFilters,
} from "store/slices/transactionFilter";
import { SourceFilter, TxnCategories } from "types/Models/books";
import { debounce } from "utils/debouncing";
import { flattenTypes } from "utils/flattenCOA";

export const Capsule = ({
  children,
  value,
  onCapsuleClick,
  isRemovable = true,
  type = "",
  isFixedFilter = false,
}: {
  children: ReactNode;
  value: boolean | string | null | string[];
  onCapsuleClick: () => void;
  isRemovable?: boolean;
  isFixedFilter?: boolean;
  type?: string;
}) => {
  let displayValue = value;
  if (Array.isArray(value)) {
    displayValue = true;
  }

  if (displayValue === "CASH" || displayValue === "ACCRUAL") {
    displayValue = ACCOUNTING_METHOD[displayValue];
  }

  if (
    viewByOption.map(({ value }) => value).includes(displayValue as ViewOptions)
  ) {
    displayValue =
      viewByOption.find(({ value }) => value === displayValue)?.label || "";
  }

  return (
    <Chip
      onClose={onCapsuleClick}
      isActive={Boolean(displayValue)}
      onFixedFilterClick={onCapsuleClick}
      isFixedFilter={isFixedFilter}
      filterType={type}
      isRemovable={isRemovable}
    >
      {children} {displayValue}
    </Chip>
  );
};

export const CapsuleFilters = () => {
  const { update } = useUpdateQuery();
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  let query = parse(search, { ignoreQueryPrefix: true });
  const { capsuleFilters, getFilterName, fixedFilters } =
    useSelector(getFilterStatus);

  const handleClick = ({ name }: { name: FilterName }) => {
    dispatch(setTxnFilters({ [name]: undefined }));
    update({ query: name, value: null });
  };

  const fixedFilterClicked = ({ name }: { name: FilterName }) => {
    const currentValue = !fixedFilters.filter(
      ({ name: filterName }) => getFilterName(filterName) === name
    )[0]?.value;

    if (Boolean(currentValue)) {
      update({ query: name, value: currentValue });
    } else {
      dispatch(setTxnFilters({ [name]: undefined }));
      update({ query: name, value: null });
    }

    const urlValue = currentValue ? true : null;

    if (name === "intercompany") {
      let updatedQuery = stringify(
        { ...query, intercompany: urlValue },
        { skipNulls: true, addQueryPrefix: true }
      );

      history.replace(`/books/transactions${updatedQuery}`);
    }
  };

  return (
    <>
      {capsuleFilters.map(({ name, value, type }) => {
        let filterValue = value;
        if (type === "transactionDate" && typeof value === "string") {
          filterValue = dayjs(value).format(DD_MMM_YYYY);
        } else if (Array.isArray(value)) {
          filterValue = `(${value.length})`;
        }

        return (
          <Capsule
            key={name}
            value={filterValue}
            onCapsuleClick={() =>
              handleClick({ name: getFilterName(name)! as FilterName })
            }
            type={type}
          >
            {name}
          </Capsule>
        );
      })}
      {fixedFilters.map(({ name, value, type }) => {
        return (
          <Capsule
            isFixedFilter
            key={name}
            value={value}
            onCapsuleClick={() =>
              fixedFilterClicked({ name: getFilterName(name)! as FilterName })
            }
            type={type}
          >
            {name}
          </Capsule>
        );
      })}
    </>
  );
};

export const CashFlow = () => {
  const { update } = useUpdateQuery();
  const { filters } = useSelector(getFilterStatus);
  const [cashFlow, minAmount, maxAmount] = filters.amount;
  const { setValues } = useFormikContext();

  const handleRadioCheck = (value: string) => {
    if (value === "ALL") {
      setValues({ minAmount: "", maxAmount: "" });
      update({ query: minAmount, value: null });
      update({ query: maxAmount, value: null });
      update({
        query: "cashFlow",
        value: "",
      });
      return;
    }
    update({
      query: "cashFlow",
      value,
    });
  };

  return (
    <div className="t-flex t-flex-col t-gap-3">
      <div className="t-text-body-sm t-text-neutral-80 t-w-full">Type</div>
      <Radio.Root
        onValueChange={handleRadioCheck}
        defaultValue={(cashFlow?.value as string) || "ALL"}
      >
        <Radio.Content>
          <Radio.Item asChild value="ALL">
            All
          </Radio.Item>
          <Radio.Item asChild value="CREDIT">
            Credit only
          </Radio.Item>
          <Radio.Item asChild value="DEBIT">
            Debit only
          </Radio.Item>
        </Radio.Content>
      </Radio.Root>
    </div>
  );
};

export const Amount = () => {
  const { update } = useUpdateQuery();
  const { filters } = useSelector(getFilterStatus);
  const [cashFlow, minAmount, maxAmount] = filters.amount;

  const handleAmountChange = debounce((e: ChangeEvent<HTMLFormElement>) => {
    const { value, name } = e.target;
    update({ query: name, value });
  });

  return (
    <Formik
      initialValues={{
        minAmount: minAmount.value || "",
        maxAmount: maxAmount.value || "",
      }}
      onSubmit={() => {}}
    >
      {({ setValues }) => {
        return (
          <div className="t-flex t-flex-col t-gap-5">
            <div className="t-flex t-flex-col t-gap-3">
              <CashFlow />
              <Form
                className="all:unset t-flex t-flex-col t-gap-4"
                onChange={handleAmountChange}
              >
                <NumericInput
                  label="Min. Amount"
                  storeNumeric
                  fieldProps={{ name: "minAmount" }}
                  numericProps={{
                    thousandSeparator: true,
                    prefix: "$",
                    disabled: Boolean(!cashFlow?.value),
                  }}
                />
                <NumericInput
                  label="Max. Amount"
                  storeNumeric
                  fieldProps={{ name: "maxAmount" }}
                  numericProps={{
                    thousandSeparator: true,
                    prefix: "$",
                    disabled: Boolean(!cashFlow?.value),
                  }}
                />
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export const DateRangeFilter = ({
  values,
  updateFilter,
}: {
  values: { START_DATE: string; END_DATE: string; SELECT_PERIOD: string };
  updateFilter: <S extends "START_DATE" | "END_DATE" | "SELECT_PERIOD">(
    name: S,
    newValue: {
      START_DATE: string;
      END_DATE: string;
      SELECT_PERIOD: string;
    }[S]
  ) => void;
}) => {
  const { updateMultiple } = useUpdateQuery();

  useEffect(() => {
    if (values.START_DATE || values.END_DATE) {
      updateMultiple([
        {
          query: "startDate",
          value: values.START_DATE
            ? dayjs(values.START_DATE).format(YYYY_MM_DD)
            : null,
        },
        {
          query: "endDate",
          value: values.END_DATE
            ? dayjs(values.END_DATE).format(YYYY_MM_DD)
            : null,
        },
      ]);
    }
  }, [values.START_DATE, values.END_DATE]);

  return <DateFilter values={values} updateFilter={updateFilter} />;
};

export const isChecked = (array: FiltersValues["value"], uuid: string) => {
  if (Array.isArray(array)) {
    return array?.find((id: string) => id === uuid);
  }
  return false;
};

export const VendorsFilter = () => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [merchantsData, setMerchantsData] = useState<string[]>([]);

  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { filters } = useSelector(getFilterStatus);

  const vendors = filters.vendors?.[0]?.value || [];

  const { data, isLoading } = useGetAllMerchantsQuery(
    {
      groupId,
      entityId,
      page_num: pageNumber,
      search_term: searchTerm,
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const {
    merchants: merchantsRes = [],
    current_page = 1,
    total_pages = 1,
  } = data || {};

  const onSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value) {
      setSearchTerm(value);
    } else {
      setSearchTerm("");
    }
    setPageNumber(1);
  });

  const onHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    if (Array.isArray(vendors)) {
      let newMerchants: Set<string> = new Set();
      if (checked) {
        newMerchants = new Set([...vendors, value]);
      } else {
        newMerchants = new Set(
          [...vendors]?.filter((merchant) => merchant !== value)
        );
      }
      dispatch(
        setTxnFilters({
          vendors: [...newMerchants].length > 0 ? [...newMerchants] : undefined,
        })
      );
    }
  };

  useEffect(() => {
    setPageNumber(current_page);
    setTotalPages(total_pages);
    if (searchTerm || pageNumber === 1) {
      setMerchantsData([...new Set([...merchantsRes])]);
    } else {
      setMerchantsData((merchant) => {
        const updatedMerchants = new Set([...merchant, ...merchantsRes]);
        return [...updatedMerchants];
      });
    }
  }, [merchantsRes, searchTerm]);

  const setPagination = debounce(() => {
    if (pageNumber < totalPages) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  });

  const noMerchantsFound = merchantsData.length === 0;

  return (
    <div className="t-w-full t-flex t-flex-col t-gap-5 t-h-full">
      <Search
        block
        placeholder="Search Vendors"
        onChange={onSearch}
        customSize="regular"
        autoFocus
      />
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {noMerchantsFound ? (
            <div className="t-flex t-items-center t-h-full t-justify-center">
              No vendor found
            </div>
          ) : (
            <div
              onChange={onHandleChange}
              id="scrollableDiv"
              className="t-flex t-flex-col t-h-[320px] t-overflow-auto"
            >
              <InfiniteScroll
                dataLength={merchantsData?.length}
                next={setPagination}
                hasMore={pageNumber < totalPages}
                className="t-flex t-flex-col t-gap-3 t-px-2 t-h-[330px] t-overflow-auto"
                scrollableTarget="scrollableDiv"
                loader={
                  <div className="t-w-full t-text-center">Loading...</div>
                }
              >
                {merchantsData?.map((merchant) => (
                  <Checkbox
                    key={merchant}
                    name={merchant}
                    label={merchant}
                    value={merchant}
                    checked={Boolean(isChecked(vendors, merchant))}
                  />
                ))}
              </InfiniteScroll>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export const CategoryFilter = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { filters } = useSelector(getFilterStatus);

  const categoryIds = filters.category?.[0]?.value || [];

  const { chartOfAccounts } = useChartOfAccounts({
    search,
    hiddenCategoryTypes: ["BANK_ACCOUNT"],
  });

  const onHandleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    types?: TxnCategories[]
  ) => {
    const { value, checked } = e.target;

    const selectedChilds =
      flattenTypes({ accounts: types! })?.map(({ uuid }) => uuid) || [];

    if (Array.isArray(categoryIds)) {
      let newCategories: Set<string> = new Set();
      if (checked) {
        newCategories = new Set([
          ...categoryIds,
          ...selectedChilds,
          value as string,
        ]);
      } else {
        newCategories = new Set(
          [...categoryIds]?.filter(
            (categoryId) =>
              !selectedChilds.includes(categoryId) && categoryId !== value
          )
        );
      }
      dispatch(
        setTxnFilters({
          categoryIds: newCategories.size > 0 ? [...newCategories] : undefined,
        })
      );
    }
  };

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearch(value.toLowerCase() || "");
  });

  const noCOAFound = chartOfAccounts.length === 0;

  return (
    <Dropdown.Root onOpenChange={() => setSearch("")}>
      <Dropdown.Trigger asChild>
        <div className="all:unset form-input w-full t-box-border t-flex t-h-10 t-w-[100%] t-items-center t-rounded t-border t-border-solid t-border-neutral-10 t-bg-surface-lighter-grey t-px-4 t-pr-5 t-font-sans t-text-body !t-font-medium t-text-text-100 t-transition-all">
          <div className="t-overflow-hidden t-text-ellipsis t-whitespace-nowrap t-text-text-30">
            {categoryIds && (categoryIds as [])?.length > 0
              ? (categoryIds as [])?.length + " categories selected"
              : "Select an account"}
          </div>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content
          sideOffset={6}
          align="start"
          className="t-relative t-w-[430px]"
        >
          <Search
            placeholder="Search..."
            onChange={handleSearch}
            autoFocus
            customSize="regular"
            block
          />
          {noCOAFound ? (
            <div className="t-my-1.5 t-flex t-h-48 t-items-center t-justify-center">
              No categories found
            </div>
          ) : (
            <div className="t-max-h-72 t-overflow-auto">
              <DropDownItem
                types={chartOfAccounts}
                level={0}
                onHandleChange={onHandleChange}
                categoryIds={categoryIds as string[]}
              />
            </div>
          )}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

export const FromFilter = () => {
  const { update } = useUpdateQuery();
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const {
    data: froms = [],
    isLoading,
    isSuccess,
  } = useGetAllAccountQuery(
    {
      groupId,
      entityId,
      account_type: "EXCLUDE_STRIPE",
    },
    { skip: !groupId || !entityId }
  );

  const { filters } = useSelector(getFilterStatus);

  let selectFromIds = filters.fromIds?.[0]?.value || [];
  const otherIds = filters.fromIds?.filter(({ name }) => name !== "Source");

  const banksByName = useMemo(
    () =>
      froms.reduce((acc, cc) => {
        if (!acc[cc.brand_name]) {
          acc[cc.brand_name] = [];
        }
        acc[cc.brand_name].push(cc);
        return acc;
      }, {} as Record<string, SourceFilter[]>),
    [isSuccess]
  );

  const onBankNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    const allbanks = new Set(banksByName[name].map(({ uuid }) => uuid));

    if (Array.isArray(selectFromIds)) {
      let newSelectedIds: Set<string>;

      if (checked) {
        newSelectedIds = new Set([...selectFromIds, ...allbanks]);
      } else {
        newSelectedIds = new Set(
          selectFromIds.filter((fromId) => !allbanks.has(fromId))
        );
      }

      dispatch(
        setTxnFilters({
          fromIds:
            newSelectedIds.size > 0 ? Array.from(newSelectedIds) : undefined,
        })
      );
    }
  };

  const onHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;

    if (Array.isArray(selectFromIds)) {
      let newSelectedIds: Set<string> = new Set();
      if (checked) {
        newSelectedIds = new Set([...selectFromIds, value]);
      } else {
        newSelectedIds = new Set(
          [...selectFromIds]?.filter((fromId) => fromId !== value)
        );
      }

      dispatch(
        setTxnFilters({
          fromIds: newSelectedIds.size > 0 ? [...newSelectedIds] : undefined,
        })
      );
    }
  };

  const onOtherHandleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (checked) {
      if (name === "Manual Entry") {
        update({ query: "manualEntry", value: true });
      } else if (name === "Bank statement") {
        update({ query: "bankStatement", value: true });
      }
    } else {
      if (name === "Manual Entry") {
        update({ query: "manualEntry", value: null });
        dispatch(setTxnFilters({ manualEntry: undefined }));
      } else if (name === "Bank statement") {
        update({ query: "bankStatement", value: null });
        dispatch(setTxnFilters({ bankStatement: undefined }));
      }
    }
  };

  return (
    <div className="t-w-full t-flex t-flex-col t-gap-5 ">
      <div className="t-text-body-sm t-text-text-30">Sent from</div>
      <div className="t-flex t-flex-col t-gap-4">
        <Async.Root
          isLoading={isLoading}
          isSuccess={isSuccess}
          isEmpty={froms.length === 0}
          loaderType="secondary"
        >
          <Async.Empty>
            <></>
          </Async.Empty>
          <Async.Success>
            {Object.entries(banksByName).map(([name, banks]) => {
              const checked = banks.every((bank) =>
                new Set(selectFromIds as string[]).has(bank.uuid)
              );

              const indeterminate =
                banksByName[name].some((bank) =>
                  new Set(selectFromIds as string[]).has(bank.uuid)
                ) && !checked;

              return (
                <span className="t-flex t-flex-col t-gap-4" key={name}>
                  <Checkbox
                    name={name}
                    label={<span className="t-text-text-30">{name}</span>}
                    value={name}
                    onChange={onBankNameChange}
                    checked={checked}
                    indeterminate={indeterminate}
                  />
                  <span className="t-ml-6 t-flex t-flex-col t-gap-4">
                    {banks.map((from) => (
                      <Checkbox
                        key={from.uuid}
                        name={from?.uuid}
                        label={
                          <>
                            {from?.nickname}
                            {from?.mask && <> - {from?.mask}</>}
                          </>
                        }
                        value={from?.uuid}
                        checked={new Set(selectFromIds as string[]).has(
                          from?.uuid
                        )}
                        onChange={onHandleChange}
                      />
                    ))}
                  </span>
                  <Divider color="grey" />
                </span>
              );
            })}
          </Async.Success>
        </Async.Root>
        {otherIds?.map((other) => (
          <Checkbox
            key={other.name}
            name={other.name}
            label={other.name}
            value={other?.name}
            checked={Boolean(other.value)}
            onChange={onOtherHandleChange}
          />
        ))}
      </div>
    </div>
  );
};

export const OtherFilters = () => {
  const { update } = useUpdateQuery();
  const dispatch = useDispatch();
  const {
    filters: { others },
    getFilterName,
  } = useSelector(getFilterStatus);

  const handleCheckBox = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    if (checked) {
      update({
        query: name,
        value: checked,
      });
      //@ts-ignore
      dispatch(setTxnFilters({ [name]: checked }));
    } else {
      update({
        query: name,
        value: null,
      });
      dispatch(setTxnFilters({ [name]: undefined }));
    }
  };

  return (
    <div className="t-flex t-flex-col t-gap-3">
      {others.map(({ name, value }) => (
        <Checkbox
          key={name}
          name={getFilterName(name)}
          checked={Boolean(value)}
          label={name}
          onChange={handleCheckBox}
        />
      ))}
    </div>
  );
};

export const TxnFilter = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  let query = parse(search, { ignoreQueryPrefix: true });
  const { appliedFilterCount } = useSelector(getFilterStatus);

  useEffect(() => {
    delete query.company;
    delete query.entity;
    delete query.page;
    delete query.selected_transaction_id;
    delete query.reset_filter;
    delete query.page;
    dispatch(setTxnFilters(query));
  }, [search]);

  const { values: dates, updateFilter: updateDateFilter } = useFilters({
    initialValue: {
      START_DATE: "",
      END_DATE: "",
      SELECT_PERIOD: "" as string,
    },
  });

  return (
    <Filter.Root
      defaultValue="transactionDate"
      title={
        <span className="t-text-body t-font-medium t-leading-none">
          Filters {appliedFilterCount ? <>({appliedFilterCount})</> : ""}
        </span>
      }
      capsule={<CapsuleFilters />}
    >
      <Filter.Portal>
        <Filter.List>
          <Filter.ListItem value="transactionDate">Date</Filter.ListItem>
          <Filter.ListItem value="from">Source</Filter.ListItem>
          <Filter.ListItem value="vendors">Vendors</Filter.ListItem>
          <Filter.ListItem value="category">Category</Filter.ListItem>
          <Filter.ListItem value="amount">Amount</Filter.ListItem>

          <Filter.ListItem value="others">Others</Filter.ListItem>
        </Filter.List>
        <Filter.Body value="transactionDate" block>
          <DateRangeFilter updateFilter={updateDateFilter} values={dates} />
        </Filter.Body>
        <Filter.Body value="from" block>
          <FromFilter />
        </Filter.Body>
        <Filter.Body value="vendors" block>
          <VendorsFilter />
        </Filter.Body>
        <Filter.Body value="category" block>
          <CategoryFilter />
        </Filter.Body>
        <Filter.Body value="amount" block>
          <Amount />
        </Filter.Body>
        <Filter.Body value="others">
          <OtherFilters />
        </Filter.Body>
      </Filter.Portal>
    </Filter.Root>
  );
};
