import classNames from "classnames";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { BRAND_CREATION_PROCEED } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useGetDomainRecognizationQuery } from "store/apis/serviceTeam";
import DomainRecognize from "../../../signInSignUp/domainRecognize";

const BrandOption = ({ setCreateBrand }) => {
  const [isCreateGroup, setCreateGroup] = useState(false);
  const [joinRequest, setjoinRequest] = useState(false);
  const { trackEvent } = useAnalytics();
  const { isUserTypeForeignCA, isForeignCA } = useRoleBasedView();
  const {
    data: groupData = [],
    isLoading,
    isSuccess,
  } = useGetDomainRecognizationQuery();

  useEffect(() => {
    if (isSuccess && groupData.length === 0) {
      setCreateBrand(true);
      setCreateGroup(true);
    }
  }, [groupData, isSuccess]);

  const startCreateBrand = () => {
    trackEvent(BRAND_CREATION_PROCEED, {});
    setCreateBrand(true);
  };

  if (isLoading) {
    return <Loader />;
  }

  const showProceed = isUserTypeForeignCA ? groupData.length === 0 : true;

  return (
    <div
      className={classNames(
        "rightContainer rightBrandOptionContainer !t-h-full !t-pt-0 sm:!t-w-full !t-w-3/5",
        {
          overflowDiv: !isCreateGroup,
        }
      )}
    >
      {showProceed && (
        <>
          <div>
            <p className="rightContainerTitle !t-my-0 !t-text-title-h2-bold !t-text-text-100">
              {isUserTypeForeignCA
                ? "Want to create a new organisation?"
                : "Create a brand"}
            </p>
            <p className="rightContainerSubTitle !t-text-body">
              {isUserTypeForeignCA
                ? "Sign up using a different mail"
                : "Start by entering your business details"}
            </p>
            <Button
              customType="primary"
              size="large"
              block
              onClick={startCreateBrand}
            >
              Proceed
            </Button>
          </div>
          <div className="btnOrOption t-w-full">
            <div className="btnOrRow" />
            <span>or</span>
            <div className="btnOrRow" />
          </div>
        </>
      )}

      {!isCreateGroup && (
        <div>
          <div className="t-mt-1">
            <div className="t-text-title-h2-bold t-text-text-100">
              {isUserTypeForeignCA || isForeignCA
                ? "Join an organisation"
                : "Join a brand"}
            </div>
            <div className="existingGroup t-mt-2 !t-text-body !t-leading-4">
              {isUserTypeForeignCA || isForeignCA
                ? "There is an existing practice created by your team members. Would you like to join it?"
                : "Your teammates are already part of the following brand. Would you like to join them?"}
            </div>
          </div>
          <DomainRecognize
            groups={groupData}
            setCreateGroup={setCreateGroup}
            setjoinRequest={setjoinRequest}
            joinRequest={joinRequest}
          />
        </div>
      )}
    </div>
  );
};
export default BrandOption;
