import { useDispatch } from "react-redux";
import { setPreviewFiles } from "store/slices/chat";
import { AttachmentType } from "./Attachment";
import { Image } from "./Image";

export const Gallery = ({ images }: { images: AttachmentType[] }) => {
  const dispatch = useDispatch();

  const onImageClick = (i: number) => {
    dispatch(setPreviewFiles({ index: i, files: images }));
  };
  const fourthImage = images[3];

  return (
    <div className="t-grid t-grid-cols-2 t-gap-2.5">
      {images.slice(0, 3).map((image, i) => (
        <Image
          key={image.image_url}
          height={120}
          onClick={() => onImageClick(i)}
          src={image.image_url}
          alt={image.fallback}
          className="t-w-full t-rounded t-object-cover"
        />
      ))}
      {fourthImage && (
        <div
          className="t-relative t-h-[120px] t-w-full t-rounded t-object-cover"
          onClick={() => onImageClick(3)}
        >
          <Image
            height={120}
            src={fourthImage.image_url}
            alt={fourthImage.fallback}
            className="t-h-[120px] t-w-full t-rounded t-object-cover"
          />
          {images.length > 4 && (
            <button className="all:unset opaci t-absolute t-inset-0 t-flex t-h-[120px] t-w-full t-items-center t-justify-center t-rounded t-bg-[rgba(0,0,0,0.4)] t-text-display-h3 t-text-white">
              {" "}
              + {images.length - 3}
            </button>
          )}
        </div>
      )}
    </div>
  );
};
