export const DollerIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <g clip-path="url(#clip0_2495_32693)">
        <path
          d="M7 0C3.14008 0 0 3.14008 0 7C0 10.8599 3.14008 14 7 14C10.8599 14 14 10.8599 14 7C14 3.14008 10.8599 0 7 0ZM7 12.8333C3.7835 12.8333 1.16667 10.2165 1.16667 7C1.16667 3.7835 3.7835 1.16667 7 1.16667C10.2165 1.16667 12.8333 3.7835 12.8333 7C12.8333 10.2165 10.2165 12.8333 7 12.8333ZM9.33333 8.16667C9.33333 9.1315 8.54817 9.91667 7.58333 9.91667V10.5C7.58333 10.8226 7.32258 11.0833 7 11.0833C6.67742 11.0833 6.41667 10.8226 6.41667 10.5V9.91667H6.26033C5.63792 9.91667 5.05692 9.58183 4.74483 9.04225C4.58325 8.76283 4.67892 8.40642 4.95717 8.24542C5.23658 8.08267 5.59358 8.1795 5.754 8.45775C5.85842 8.63858 6.05208 8.75 6.25975 8.75H7.58275C7.90475 8.75 8.16608 8.48867 8.16608 8.16667C8.16608 7.94617 8.008 7.7595 7.79042 7.72333L6.0165 7.42758C5.23367 7.2975 4.66608 6.62667 4.66608 5.83333C4.66608 4.8685 5.45125 4.08333 6.41608 4.08333V3.5C6.41608 3.178 6.67683 2.91667 6.99942 2.91667C7.322 2.91667 7.58275 3.178 7.58275 3.5V4.08333H7.73908C8.3615 4.08333 8.9425 4.41875 9.25458 4.95833C9.41617 5.23717 9.3205 5.59358 9.04225 5.75517C8.76225 5.91675 8.40583 5.82108 8.24542 5.54225C8.141 5.362 7.94733 5.25058 7.73967 5.25058H6.41667C6.09467 5.25058 5.83333 5.5125 5.83333 5.83392C5.83333 6.05442 5.99142 6.24108 6.209 6.27725L7.98292 6.573C8.76575 6.70308 9.33333 7.37392 9.33333 8.16725V8.16667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2495_32693">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
