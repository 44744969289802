// Task events
export const ADD_TASK_CLICKED = "ADD_TASK_CLICKED";
export const TASK_CREATED = "TASK_CREATED";
export const TASK_CREATION_FAILED = "TASK_CREATION_FAILED";
export const TASK_OPENED = "TASK_OPENED";
export const TASK_OPEN_FAILED = "TASK_OPENED_FAILED";

// Sign-Up events
export const LANDED_PERSONA_SELECTION = "PERSONA_SELECTION";
export const OPEN_PERSONA_PARTNER = "OPEN_PERSONA_PARTNER";
export const OPEN_PERSONA_USER = "OPEN_PERSONA_USER";
export const OPEN_PERSONA_ACCOUNTANT = "OPEN_PERSONA_ACCOUNTANT";
export const SIGNUP_WITH_EMAIL = "SIGNUP_WITH_EMAIL";
export const SIGNUP_WITH_GOOGLE = "SIGNUP_WITH_GOOGLE";
export const NEW_USER_SIGNUP = "NEW_USER_SIGNUP";
export const NEW_SIGNUP_SUCCESS = "NEW_SIGNUP_SUCCESS";
export const NEW_SIGNUP_FAILURE = "NEW_SIGNUP_FAILURE";
export const SIGNUP_OTP_VERIFICATION_SUCCESS =
  "SIGNUP_OTP_VERIFICATION_SUCCESS";
export const SIGNUP_OTP_VERIFICATION_FAILURE =
  "SIGNUP_OTP_VERIFICATION_FAILURE";
export const MOBILE_NUMBER_SUBMITTED = "MOBILE_NUMBER_SUBMITTED";
export const MOBILE_NUMBER_SUBMIT_FAILURE = "MOBILE_NUMBER_SUBMIT_FAILURE";
export const MOBILE_VERIFICATION_SUCCESS = "MOBILE_VERIFICATION_SUCCESS";
export const MOBILE_VERIFICATION_FAILURE = "MOBILE_VERIFICATION_FAILURE";

// Brand creation events
export const CREATED_BRAND_SUCCESS = "CREATED_BRAND_SUCCESS";
export const CREATED_BRAND_FAILURE = "CREATED_BRAND_FAILURE";
export const ADDED_ENTITIY = "ADDED_ENTITIY";
export const CREATED_ENTITIES_SUCCESS = "CREATED_ENTITIES";
export const CREATED_ENTITIES_FAILURE = "CREATED_ENTITIES";
export const BRAND_CREATION_PROCEED = "BRAND_CREATION_PROCEED";
export const REQUESTED_TO_JOIN_GROUP = "REQUESTED_TO_JOIN_GROUP";
export const REQUESTED_TO_JOIN_GROUP_FAILED = "REQUESTED_TO_JOIN_GROUP_FAILED";
export const CANNOT_REQUEST_TO_JOIN_GROUP = "CANNOT_REQUEST_TO_JOIN_GROUP";
export const ADD_ENTITY_MODAL_OPENED = "ADD_ENTITY_MODAL_OPENED";
export const CREATE_BRAND_ENTITY_CONTINUE_BTN =
  "CREATE_BRAND_ENTITY_CONTINUE_BTN";

// Books-Plaid events
export const CONNECT_NEW_PLAID_ACCOUNT_CLICKED =
  "CONNECT_NEW_PLAID_ACCOUNT_CLICKED";
export const CONNECT_NEW_PLAID_ACCOUNT_SUCCESS =
  "CONNECT_NEW_PLAID_ACCOUNT_SUCCESS";
export const RECONNECT_PLAID_ACCOUNT_SUCCESS =
  "RECONNECT_PLAID_ACCOUNT_SUCCESS";
export const ADD_ENTITY_WITH_PLAID_ACCOUNT_SUCCESS =
  "ADD_ENTITY_WITH_PLAID_ACCOUNT_SUCCESS";
export const ADD_ENTITY_WITH_PLAID_ACCOUNT_FAILURE =
  "ADD_ENTITY_WITH_PLAID_ACCOUNT_FAILURE";
export const SYNC_PLAID_TRANSACTIONS_SUCCESS =
  "SYNC_PLAID_TRANSACTIONS_SUCCESS";
export const SYNC_PLAID_TRANSACTIONS_FAILURE =
  "SYNC_PLAID_TRANSACTIONS_FAILURE";

// Calendar analytics event
export const STARTED_DOWNLOAD_CALENDAR = "STARTED_DOWNLOAD_CALENDAR";
export const FINISHED_DOWNLOAD_CALENDAR = "FINISHED_DOWNLOAD_CALENDAR";
export const TOGGLE_INTERACTION = "TOGGLE_INTERACTION";

export const OPENED_ADD_TO_CALENDAR_MODAL = "OPENED_ADD_TO_CALENDAR_MODAL";
export const ADDED_DEADLINES_TO_CALENDAR = "ADDED_DEADLINES_TO_CALENDAR";

export const OPENED_REMINDER_SUBSCRIPTION_MODAL =
  "OPENED_REMINDER_SUBSCRIPTION_MODAL";
export const SUBSCRIBED_TO_DEADLINE_REMINDERS =
  "SUBSCRIBED_TO_DEADLINE_REMINDERS";

// 7004 events
export const OPENED_SIGNUP_FROM_7004 = "OPENED_SIGNUP_FROM_7004";
export const FORM_7004_SAVE_FAILED = "FORM_7004_SAVE_FAILED";

// Billing event
export const SUBSCRIPTION_CREATED = "SUBSCRIPTION_CREATED";

// TP events
export const COMPLETE_TP_KYC = "COMPLETE_TP_KYC";
export const TP_HOME_PAGE_CARD_CLICK = "TP_HOME_PAGE_CARD_CLICK";

// Home Page events
export const SCHEDULE_ONBOARDING_CALL = "SCHEDULE_ONBOARDING_CALL";
export const BE_12_UPCOMING_DEADLINE_CARD_CLICKED =
  "BE_12_UPCOMING_DEADLINE_CARD_CLICKED";
export const REDIRECTED_TO_BE_12_TASK_CREATION =
  "REDIRECTED_TO_BE_12_TASK_CREATION";

// Task Inprogress Social Share events
export const TASK_INPROGRESS_SHARE_ON_WHATSAPP =
  "TASK_INPROGRESS_SHARE_ON_WHATSAPP";
export const TASK_INPROGRESS_SHARE_ON_TWITTER =
  "TASK_INPROGRESS_SHARE_ON_TWITTER";
export const TASK_INPROGRESS_SHARE_ON_FACEBOOK =
  "TASK_INPROGRESS_SHARE_ON_FACEBOOK";
export const TASK_INPROGRESS_SHARE_ON_LINKEDIN =
  "TASK_INPROGRESS_SHARE_ON_LINKEDIN";
export const TASK_INPROGRESS_SHARE_MODAL_OPEN =
  "TASK_INPROGRESS_SHARE_MODAL_OPEN";

// Task list events
export const TASK_LIST_TAB_CLICK = "TASK_LIST_TAB_CLICK";
export const TASK_LIST_YEAR_ACCORDION_OPENED =
  "TASK_LIST_YEAR_ACCORDION_OPENED";

// Task final document upload events
export const TASK_FINAL_DOCUMENT_FILE_UPLOAD_OPENED =
  "TASK_FINAL_DOCUMENT_FILE_UPLOAD_OPENED";
export const TASK_FINAL_DOCUMENT_FILE_UPLOAD_BUTTON_CLICKED =
  "TASK_FINAL_DOCUMENT_FILE_UPLOAD_BUTTON_CLICKED";
export const TASK_FINAL_DOCUMENT_FILE_UPLOAD_COMPLETED =
  "TASK_FINAL_DOCUMENT_FILE_UPLOAD_COMPLETED";
export const TASK_FINAL_DOCUMENT_FILE_UPLOAD_FAILED =
  "TASK_FINAL_DOCUMENT_FILE_UPLOAD_FAILED";

export const OPENED_MANAGE_PLAN_MODAL = "OPENED_MANAGE_PLAN_MODAL";
export const SUBSCRIPTION_CANCELLATION_MODAL_OPENED =
  "SUBSCRIPTION_CANCELLATION_MODAL_OPENEDED";
export const CLICKED_EXPORT_DOCUMENT_IN_CANCEL_MODAL =
  "CLICKED_EXPORT_DOCUMENT_IN_CANCEL_MODAL";
export const SUBSCRIPTION_CANCELLED = "SUBSCRIPTION_CANCELLED";

// Notification events
export const NOTIFICATION_BELL_CLICKED = "NOTIFICATION_BELL_CLICKED";
export const NOTIFICATION_CLICKED = "NOTIFICATION_CLICKED";
export const MARK_NOTIFICATION_AS_READ = "MARK_NOTIFICATION_AS_READ";

// Browser Notification events
export const BROWSER_NOTIFICATION_NUDGE_ALLOWED =
  "BROWSER_NOTIFICATION_NUDGE_ALLOWED";
export const BROWSER_NOTIFICATION_NUDGE_CLOSED =
  "BROWSER_NOTIFICATION_NUDGE_CLOSED";
export const BROWSER_NOTIFICATION_ALLOWED = "BROWSER_NOTIFICATION_ALLOWED";
export const BROWSER_NOTIFICATION_BLOCKED = "BROWSER_NOTIFICATION_BLOCKED";

// Billing info events
export const BILLING_INFO_OPENED = "BILLING_INFO_OPENED";
export const BILLING_INFO_CARD_ADD_CLICKED = "BILLING_INFO_CARD_ADD_CLICKED";
export const CREDIT_CARD_OPTION_OPENED = "CREDIT_CARD_OPTION_OPENED";
export const CREDIT_CARD_DELETED = "CREDIT_CARD_DELETED";
export const CREDIT_CARD_MADE_DEFAULT = "CREDIT_CARD_MADE_DEFAULT";
export const BILLING_INFO_CONTACT_EDIT_MODAL_OPENED =
  "BILLING_INFO_CONTACT_EDIT_MODAL_OPENED";
export const BILLING_INFO_ADDRESS_EDIT_MODAL_OPENED =
  "BILLING_INFO_ADDRESS_EDIT_MODAL_OPENED";
export const BILLING_INFO_CONTACT_EDITED = "BILLING_INFO_CONTACT_EDITED";
export const BILLING_INFO_ADDRESS_EDITED = "BILLING_INFO_ADDRESS_EDITED";

export const INVESTOR_UPDATE_HOME_VISITED = "INVESTOR_UPDATE_HOME_VISITED";
export const INVESTOR_UPDATE_DRAFT_CLICKED = "INVESTOR_UPDATE_DRAFT_CLICKED";
export const INVESTOR_UPDATE_ADD_CLICKED = "INVESTOR_UPDATE_ADD_CLICKED";
export const INVESTOR_UPDATE_SAVED = "INVESTOR_UPDATE_SAVED";
export const INVESTOR_UPDATE_PRIVIEWED = "INVESTOR_UPDATE_PRIVIEWED";
export const INVESTOR_UPDATE_STARTED_GOOGLE_SSO =
  "INVESTOR_UPDATE_STARTED_GOOGLE_SSO";
export const SENT_INVESTOR_UPDATE = "SENT_INVESTOR_UPDATE";

// perks
export const PERK_OPENED = "PERK_OPENED";
export const PERK_REDEEMED = "PERK_REDEEMED";

// tickets
export const TICKET_OPENED = "TICKET_OPENED";

// preincorp
export const PREINCORP_FLOW_STARTED = "PREINCORP_FLOW_STARTED";

// suggested documents
export const SUGGESTED_DOCS_UPLOADED = "SUGGESTED_DOCS_UPLOADED";

// entity
export const ENTITY_CARD_CLICKED = "ENTITY_CARD_CLICKED";

// chat
export const CHAT_MESSAGE_DELETED = "CHAT_MESSAGE_DELETED";
export const TASK_REVIEW_DOCUMENT_CARD_OPENED =
  "TASK_REVIEW_DOCUMENT_CARD_OPENED";
export const TASK_REVIEW_AND_PAY_CARD_OPENED =
  "TASK_REVIEW_AND_PAY_CARD_OPENED";
export const TASK_ADDITIONAL_PAYMENT_CARD_OPENED =
  "TASK_ADDITIONAL_PAYMENT_CARD_OPENED";
export const TASK_AMENDMENT_COMPLETED_CARD_OPENED =
  "TASK_AMENDMENT_COMPLETED_CARD_OPENED";
export const OPEN_ITEMS_CARD_OPENED = "OPEN_ITEMS_CARD_OPENED";

// Documents
export const FILE_FOLDER_MOVED = "FILE_FOLDER_MOVED";
export const FILE_FOLDER_MOVE_FAILED = "FILE_FOLDER_MOVE_FAILED";
export const NEW_FOLDER_CREATED = "NEW_FOLDER_CREATED";
export const FOLDER_CREATION_FAILED = "FOLDER_CREATION_FAILED";
export const NEW_FILE_UPLOADED = "NEW_FILE_UPLOADED";
export const NEW_FILE_UPLOAD_FAILED = "NEW_FILE_UPLOAD_FAILED";
export const FILE_VIEWED = "FILE_VIEWED";
export const VIEW_FILE_FAILED = "VIEW_FILE_FAILED";
export const FILE_FOLDER_DOWNLOADED = "FILE_FOLDER_DOWNLOADED";
export const FILE_FOLDER_DOWNLOAD_FAILED = "FILE_FOLDER_DOWNLOAD_FAILED";
export const FILE_FOLDER_DELETED = "FILE_FOLDER_DELETED";
export const FILE_FOLDER_DELETE_FAILED = "FILE_FOLDER_DELETE_FAILED";
export const FOLDER_FILE_SHARE_LINK_COPIED = "FOLDER_FILE_SHARE_LINK_COPIED";
export const HIT_404 = "HIT_404";

// Invoices
export const INVOICE_ADD_CLICKED = "INVOICE_ADD_CLICKED";
export const INVOICE_ADD_CLICKED_WITH_CUSTOMER =
  "INVOICE_ADD_CLICKED_WITH_CUSTOMER";
export const INVOICE_HOME_VISITED = "INVOICE_HOME_VISITED";

// COMPLIANCE
export const CLICKED_COMPLIANCE_ON_LEFT_NAV = "CLICKED_COMPLIANCE_ON_LEFT_NAV";
export const CLICKED_ENTITY_SWITCHER_IN_COMPLIANCE =
  "CLICKED_ENTITY_SWITCHER_IN_COMPLIANCE";
export const CLICKED_ADD_COMPLIANCE = "CLICKED_ADD_COMPLIANCE";

// BOOKS INVOICE
export const CLICKED_INVOICE_ON_LEFT_NAV = "CLICKED_INVOICE_ON_LEFT_NAV";
export const CLICKED_CREATE_INVOICE_IN_INVOICES_TAB =
  "CLICKED_CREATE_INVOICE_IN_INVOICES_TAB";
export const CLICKED_DOWNLOAD_IN_DRAFT_INVOICE_VIEW =
  "CLICKED_DOWNLOAD_IN_DRAFT_INVOICE_VIEW";
export const CLICKED_PREVIEW_AND_SEND_IN_DRAFT_INVOICE_VIEW =
  "CLICKED_PREVIEW_AND_SEND_IN_DRAFT_INVOICE_VIEW";
export const CLICKED_SEND_INVOICE_FROM_EMAIL_PREVIEW_MODAL =
  "CLICKED_SEND_INVOICE_FROM_EMAIL_PREVIEW_MODAL";

export const CLICKED_CUSTOMERS_TAB_IN_INVOICE =
  "CLICKED_CUSTOMERS_TAB_IN_INVOICE";
export const CLICKED_ADD_CUSTOMER_IN_INVOICE =
  "CLICKED_ADD_CUSTOMER_IN_INVOICE";
export const CLICKED_SAVE_IN_ADD_CUSTOMER_MODAL =
  "CLICKED_SAVE_IN_ADD_CUSTOMER_MODAL";
export const CLICKED_SAVE_IN_UPDATE_CUSTOMER_MODAL =
  "CLICKED_SAVE_IN_UPDATE_CUSTOMER_MODAL";
export const CLICKED_EDIT_PENCIL_ICON_ON_CUSTOMER_DETAILS_CARD =
  "CLICKED_EDIT_PENCIL_ICON_ON_CUSTOMER_DETAILS_CARD";
export const CLICKED_CUSTOMER_LINE_ITEM_IN_TABLE =
  "CLICKED_CUSTOMER_LINE_ITEM_IN_TABLE";
export const CLICKED_NEW_INVOICE_INSIDE_CUSTOMER_VIEW =
  "CLICKED_NEW_INVOICE_INSIDE_CUSTOMER_VIEW";

// Settings
export const CLICKED_SETTINGS_TAB_IN_INVOICE =
  "CLICKED_SETTINGS_TAB_IN_INVOICE";

// Invoice Actions
export const MARKED_INVOICE_AS_SENT_FROM_THREE_DOTS =
  "MARKED_INVOICE_AS_SENT_FROM_THREE_DOTS";
export const MARKED_INVOICE_AS_PAID_FROM_THREE_DOTS =
  "MARKED_INVOICE_AS_PAID_FROM_THREE_DOTS";
export const DUPLICATED_INVOICE_FROM_THREE_DOTS =
  "DUPLICATED_INVOICE_FROM_THREE_DOTS";

// Intragroup
export const CLICKED_TRANSFER_ON_LEFT_NAV = "CLICKED_TRANSFER_ON_LEFT_NAV";
export const CLICKED_INITIATE_TRANSFER = "CLICKED_INITIATE_TRANSFER";
export const CLICKED_TRANSFER_LINE_ITEM_IN_TABLE =
  "CLICKED_TRANSFER_LINE_ITEM_IN_TABLE";
export const CLICKED_ON_INTER_COMPANY_TRANSACTIONS_GET_STARTED_CTA =
  "CLICKED_ON_INTER_COMPANY_TRANSACTIONS_GET_STARTED_CTA";
export const ENTERED_AMOUNT_IN_SEND_IN_FX_CALCULATOR =
  "ENTERED_AMOUNT_IN_SEND_IN_FX_CALCULATOR";
export const CLICKED_ON_VIEW_BREAKUP_CTA_IN_CALCULATOR =
  "CLICKED_ON_VIEW_BREAKUP_CTA_IN_CALCULATOR";
export const CLICKED_ON_SCHEDULE_A_DEMO_CALL_INSIDE_RESOURCES =
  "CLICKED_ON_SCHEDULE_A_DEMO_CALL_INSIDE_RESOURCES";
export const CLICKED_INTRAGROUP_SERVICES_ON_LEFT_NAV =
  "CLICKED_INTRAGROUP_SERVICES_ON_LEFT_NAV";
export const CLICKED_ON_START_A_INTRAGROUP_SERVICES_FROM_CARD =
  "CLICKED_ON_START_A_INTRAGROUP_SERVICES_FROM_CARD";
export const CLICKED_ON_REQUEST_A_INTRAGROUP_SERVICES_CTA =
  "CLICKED_ON_REQUEST_A_INTRAGROUP_SERVICES_CTA";
export const CLICKED_ON_SERVICE_FROM_INTRAGROUP_SERVICES_LIST_MODAL =
  "CLICKED_ON_SERVICE_FROM_INTRAGROUP_SERVICES_LIST_MODAL";
export const LAUNCHED_INTRAGROUP_SERVICE = "LAUNCHED_INTRAGROUP_SERVICE";

export const UPGRADE_PLAN_MODAL_OPENED = "UPGRADE_PLAN_MODAL_OPENED";
export const UPGRADE_PLAN_CLICKED = "UPGRADE_PLAN_CLICKED";

// APP switcher events
export const APP_SWITCHER_CLICKED = "APP_SWITCHER_CLICKED";

// Global CTAs
export const NAVBAR_COLLAPSER_CLICKED = "NAVBAR_COLLAPSER_CLICKED";
export const ENTITY_SWITCHER_CLICKED = "ENTITY_SWITCHER_CLICKED";
export const CALL_US_CLICKED = "CALL_US_CLICKED";
export const CHAT_CLICKED_FROM_SIDEBAR = "CHAT_CLICKED_FROM_SIDEBAR";
export const ACTION_ITEMS_CLICKED_FROM_SIDEBAR = "ACTION_ITEMS_CLICKED";
export const DOCUMENTS_CLICKED_FROM_SIDEBAR = "DOCUMENTS_CLICKED_FROM_SIDEBAR";

// Books comments
export const COMMENT_TAB_OPENED = "COMMENT_TAB_OPENED";
export const ADD_COMMENT_CLICKED = "ADD_COMMENT_CLICKED";
export const COMMENT_ADDED = "COMMENT_ADDED";
export const COMMENT_CLICKED = "COMMENT_CLICKED";

// Todos
export const TODOS_PAGE_OPENED = "TODOS_PAGE_OPENED";
export const TODO_TAB_CLICKED_IN_HOMEPAGE = "TODO_TAB_CLICKED_IN_HOMEPAGE";
export const TODO_TAB_CLICKED_INSIDE_TODO = "TODO_TAB_CLICKED_INSIDE_TODO";

// Cart
export const MOVE_TO_SAVE_FOR_LATER = "MOVE_TO_SAVE_FOR_LATER";
export const MOVE_TO_CART = "MOVE_TO_CART";
