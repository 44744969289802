import { CartModal } from "components/CartModal/CartModal";
import { Button } from "components/DesignSystem/Button/Button";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { SmallArrowUpRight } from "components/icons/SmallArrowUpRight";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import AnnualCatchupIcon from "static/images/AnnualCatchup.svg";
import SmallInkleBooksLogo from "static/images/SmallInkleBooks.svg";
import {
  taskAlarmApis,
  useLazyGetTaskListForServiceTeamQuery,
  useLazyGetTaskListForUserQuery,
} from "store/apis/taskList";
import { AddAnnualCatchup } from "./AddAnnualCatchup/AddAnnualCatchup";

const bookkeepingAnnualCatchupProduct =
  process.env.PUBLIC_BOOKKEEPING_ANNUAL_CATCHUP;

type AnnualCatchupProps = {
  season: string;
  entityIdFromProps: string;
  taskCount: number;
  controlBasetask: string;
  isFetching: boolean;
};

export const AnnualCatchup = ({
  season,
  entityIdFromProps,
  taskCount,
  controlBasetask,
  isFetching,
}: AnnualCatchupProps) => {
  const annualCatchup = useModal();
  const cartModal = useModal();
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const { successToast, alertToast } = useToast();
  const [isRefreshingTasks, setIsRefreshingTasks] = useState(false);
  const { isCustomer } = useRoleBasedView();
  const [fetchUserTasks] = useLazyGetTaskListForUserQuery();
  const [fetchServiceTeamTasks] = useLazyGetTaskListForServiceTeamQuery();

  const startCheckingIfTaskCreated = async (
    resolve: () => void,
    reject: (error: Error) => void,
    totalSeconds = 0
  ) => {
    try {
      const data = isCustomer
        ? await fetchUserTasks({
            baseTaskKey: controlBasetask,
            seasons: season,
            showArchive: false,
          }).unwrap()
        : await fetchServiceTeamTasks({
            baseTaskKey: controlBasetask,
            seasons: season,
            showArchive: false,
            groupId,
          }).unwrap();

      if (totalSeconds === 1 * 60 * 1000) {
        reject(new Error("Task creation timed out. Please try again."));
      }

      if ((data.count || 0) > taskCount) {
        resolve();
      } else {
        setTimeout(
          () =>
            startCheckingIfTaskCreated(resolve, reject, totalSeconds + 2000),
          2000
        );
      }
    } catch (error) {
      reject(
        new Error("Failed to fetch task list. Please check your network.")
      );
    }
  };

  const handleCartClose = async () => {
    try {
      setIsRefreshingTasks(true);
      cartModal.close();
      await new Promise<void>((resolve, reject) =>
        startCheckingIfTaskCreated(resolve, reject)
      );
      dispatch(taskAlarmApis.util.invalidateTags(["TaskList"]));
      successToast({ title: "Filings from the cart have been launched." });
    } catch (error) {
      if (error instanceof Error) {
        alertToast({ message: error.message });
      } else {
        alertToast({ message: "Something went wrong" });
      }
    } finally {
      setIsRefreshingTasks(false);
    }
  };

  if (isRefreshingTasks || isFetching) {
    return (
      <div className="t-h-80 t-w-full t-flex t-items-center t-justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <div className="t-text-body-sm t-text-text-30 t-mb-4">
        You can request your bookkeeper to provide an income statement or have
        it prepared through Inkle. Our team of dedicated bookkeepers will handle
        the preparation of your income statement for you.
      </div>

      <div className="t-flex t-justify-between t-border t-border-solid t-border-purple-10 t-bg-purple-0 t-rounded-lg p-3 t-relative">
        <div className="t-flex t-flex-col t-gap-3 t-w-11/12">
          <div className="t-flex t-flex-col t-gap-1">
            <div className="t-text-subtitle-sm t-text-text-100">
              Let us prepare your financials 🌟
            </div>
            <div className="t-text-body-sm t-text-text-30">
              Need to close previous year’s books? Inkle will ensure everything
              is handled accurately.
            </div>
          </div>

          <div>
            <Button type="button" onClick={annualCatchup.open}>
              <div className="t-flex t-items-center t-gap-1.5">
                Get Bookkeeping Catchup <SmallArrowUpRight />
              </div>
            </Button>
          </div>

          <div className="t-flex t-gap-1 t-items-center">
            <div className="t-text-caption t-text-text-30">Powered by</div>
            <img
              src={SmallInkleBooksLogo}
              alt="Small Inkle Books Logo"
              className="t-h-2.5"
            />
          </div>
        </div>

        <img
          src={AnnualCatchupIcon}
          alt=""
          className="t-absolute t-right-0 t-bottom-0"
          aria-hidden="true"
        />

        {annualCatchup.isOpen && (
          <AddAnnualCatchup
            isOpen={annualCatchup.isOpen}
            close={annualCatchup.close}
            entityId={entityIdFromProps}
            groupId={groupId}
            productId={bookkeepingAnnualCatchupProduct!}
            currentSeason={Number(season)}
            onAdd={cartModal.open}
          />
        )}

        {cartModal.isOpen && (
          <CartModal
            open={cartModal.isOpen}
            onClose={cartModal.close}
            entityIdFromProps={entityIdFromProps}
            shouldRedirectToTask={false}
            handlePaymentSuccess={handleCartClose}
          />
        )}
      </div>
    </div>
  );
};
