import { ParentSelector } from "components/ChartOfAccounts/ParentSelector";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import Loader from "components/design/loader";
import { Form, Formik } from "formik";
import { AnimatePresence, motion } from "framer-motion";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RowSelectionState } from "react-table-8.10.7";
import {
  useCategorizeGroupTransactionsMutation,
  useGetAllTransactionsDataQuery,
} from "store/apis/transactions";
import { setSelectedTransactionIds } from "store/slices/transactions";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { flattenTypes } from "utils/flattenCOA";
import { object, string } from "yup";
import { RuleConfirmation } from "./RuleConfirmation";
import {
  coaList,
  coaOptions,
  getCOALabel,
  SourceTransaction,
} from "./TransactionColumn";
import { setRuleInfo } from "store/slices/ruleEngine";
import { useAppSelector } from "hooks/useAppSelector";
import { TransactionsView } from "components/TransactionsView/TransactionsView";
import { useGetTransactionsFilterSet } from "hooks/useGetTransactionsFilterSet";
import { LinkTransaction } from "./LinkTransaction";
import { InternalTransaction } from "./AddTransaction/AddTransactionManuallyModal";

type BulkCategoriseProps = ModalProps & {
  deselectRows: () => void;
  unSelectedRowsIds: string[];
  isAllTxnSelected: boolean;
};

const useCOA = () => {
  const { chartOfAccounts = [] } = useChartOfAccounts({
    hiddenCategoryTypes: ["BANK_ACCOUNT"],
  });

  return coaOptions(chartOfAccounts);
};

const DisclaimerUI = ({
  txnCount,
  previouslyCategorizedTxnsCount,
  previouslyLinkedTxnsCount,
  selectedCategory,
}: {
  txnCount: number;
  previouslyCategorizedTxnsCount: number;
  previouslyLinkedTxnsCount: number;
  selectedCategory: string;
}) => {
  return (
    <div className="t-text-body">
      You have assigned{" "}
      <motion.b
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ ease: "easeInOut", duration: 0.4 }}
        key={selectedCategory}
      >
        {" "}
        "{selectedCategory}"
      </motion.b>{" "}
      to {txnCount} transactions
      {previouslyCategorizedTxnsCount > 0 && (
        <>
          {" "}
          that includes
          <b> {previouslyCategorizedTxnsCount} categorized transactions</b>
        </>
      )}
      {previouslyLinkedTxnsCount > 0 && (
        <>
          {" "}
          and
          <b> {previouslyLinkedTxnsCount} linked </b>
          transactions
        </>
      )}
      . Are you sure you want to proceed?
    </div>
  );
};

const Disclaimer = ({
  selectedCategory,
}: {
  selectedCategory: { value: string; label: string } | null;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { selectedTransactionIds } = useSelector(
    (store: RootState) => store.transactions
  );

  const { data: transactionData } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      transactionIds: selectedTransactionIds.join(","),
    },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );

  const allCategorisedTransactions =
    transactionData?.transactions.filter(({ transaction: { category } }) =>
      Boolean(category?.uuid)
    ) || [];

  const allLinkedTransactions =
    transactionData?.transactions.filter(
      ({ transaction: { linked_transaction } }) => Boolean(linked_transaction)
    ) || [];

  return (
    <DisclaimerUI
      previouslyCategorizedTxnsCount={allCategorisedTransactions.length}
      previouslyLinkedTxnsCount={allLinkedTransactions.length}
      txnCount={selectedTransactionIds.length}
      selectedCategory={selectedCategory?.label || ""}
    />
  );
};

const LINK_REQUIRED_CATEGORIES = ["BANK_TRANSFER", "PAY_DOWN_CREDIT"];

export const BulkCategorise = ({
  close,
  isOpen,
  deselectRows,
  isAllTxnSelected,
  unSelectedRowsIds,
}: BulkCategoriseProps) => {
  const dispatch = useDispatch();
  const flattenChartOfAccounts = useCOA();
  const { chartOfAccounts = [] } = useChartOfAccounts({
    hiddenCategoryTypes: ["BANK_ACCOUNT"],
  });
  const { successToast, alertToast } = useToast();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const [groupCategorize, { isLoading: categorizing }] =
    useCategorizeGroupTransactionsMutation();

  const {
    isOpen: isOpenConfirmation,
    open: openConfirmation,
    close: closeConfirmation,
  } = useModal();
  const { selectedTransactionIds } = useAppSelector(
    (store) => store.transactions
  );
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const { data, isLoading: isFilterSetLoading } = useGetTransactionsFilterSet({
    filter_operation: "CATEGORIZE",
    unSelectedRowsIds,
    skip: !isAllTxnSelected || !isOpen,
  });

  const {
    previously_categorized_txns_count = 0,
    txns_count = 0,
    txns_hash_key = "",
    categorized_txns_with_linked_transaction_count = 0,
  } = data || {};

  const { data: transactionData, isLoading } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      transactionIds: selectedTransactionIds.join(","),
    },
    {
      skip: !groupId || !entityId || isAllTxnSelected,
      refetchOnMountOrArgChange: true,
    }
  );

  const { transactions = [] } = transactionData || {};

  const merchants = transactions?.map(
    ({ transaction: { merchant_uuid } }) => merchant_uuid
  );

  const merchantIds = merchants.join(",");

  const parentSelectorModal = useModal();

  useEffect(() => {
    const selectedTxns = selectedTransactionIds.reduce(
      (accumulator, currentId, i) => {
        return { ...accumulator, [currentId]: true };
      },
      {}
    );
    setRowSelection(selectedTxns);
  }, [selectedTransactionIds]);

  const [showLinkModal, setShowLinkModal] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");

  const onClose = () => {
    close();
    dispatch(setSelectedTransactionIds([]));
  };

  const onSubmit = async ({
    category,
  }: {
    category: string;
    txns_hash_key: string;
  }) => {
    try {
      const selectedCategory = flattenChartOfAccounts.find(
        (coa) => coa.value === category
      );

      if (
        LINK_REQUIRED_CATEGORIES.includes(selectedCategory?.categoryType || "")
      ) {
        setSelectedCategoryId(category);
        setShowLinkModal(true);
        return;
      }

      await groupCategorize({
        groupId,
        entityId,
        payload: {
          transaction_category_uuid: category,
          transaction_ids: txns_hash_key
            ? undefined
            : selectedTransactionIds.join(", "),
          transactions_hash_key: txns_hash_key,
        },
      }).unwrap();
      dispatch(setRuleInfo({ merchantIds, categoryId: category }));
      openConfirmation();
      onClose();
      successToast({
        message: "Transactions Categorised",
      });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const categorizeAfterLink = async (
    linkedTransactions: Record<string, InternalTransaction>
  ) => {
    try {
      await groupCategorize({
        groupId,
        entityId,
        payload: {
          transaction_category_uuid: selectedCategoryId,
          link_transaction_dict: Object.entries(linkedTransactions).reduce(
            (acc, [key, value]) => {
              return { ...acc, [key]: value.uuid };
            },
            {}
          ),
        },
      }).unwrap();
      onClose();
      setShowLinkModal(false);
      successToast({
        message: "Transactions Categorised",
      });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const selectedCategory = flattenChartOfAccounts.find(
    (coa) => coa.value === selectedCategoryId
  );

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={onClose}>
        <Formik
          initialValues={{ category: "", txns_hash_key }}
          onSubmit={onSubmit}
          validateOnChange
          validateOnMount
          enableReinitialize
          validationSchema={object({
            category: string().required("Please select Category"),
          })}
        >
          {({
            submitForm,
            isSubmitting,
            isValid,
            values: { category },
            resetForm,
            setFieldValue,
          }) => {
            const flattenedCOAs = flattenTypes({
              accounts: chartOfAccounts,
            }).map((account) => {
              const label = getCOALabel({ account });

              return {
                value: account.uuid,
                label: label,
                data: label,
                isDisabled: account.topLevel,
              };
            });

            const selectedCategory =
              flattenedCOAs.find(({ value }) => value === category) || null;

            const linkRequired = LINK_REQUIRED_CATEGORIES.includes(
              flattenChartOfAccounts.find((coa) => coa.value === category)
                ?.categoryType || ""
            );
            return (
              <Modal.Content
                useCustomOverlay
                size={Boolean(category) && !linkRequired ? "xl" : "large"}
              >
                <Modal.Header>
                  <Modal.Title>Categorise transactions</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body className="t-flex t-flex-col t-gap-6">
                  {isFilterSetLoading ? (
                    <Loader size="small" />
                  ) : (
                    <Form className="t-m-0 t-flex t-flex-col t-gap-4">
                      <Combobox
                        withForm
                        name="category"
                        label="Category"
                        placeholder="Select account"
                        menuPortalTarget={document.body}
                        value={selectedCategory}
                        options={flattenChartOfAccounts}
                        filterOption={(v, i) =>
                          v.data.data
                            ?.toLocaleLowerCase()
                            .includes(i.toLocaleLowerCase()) || false
                        }
                        actions={
                          <Button
                            customType="link"
                            type="button"
                            onClick={parentSelectorModal.open}
                          >
                            Add a new account
                          </Button>
                        }
                      />

                      {category && !linkRequired && (
                        <AnimatePresence>
                          {isAllTxnSelected ? (
                            <DisclaimerUI
                              previouslyCategorizedTxnsCount={
                                previously_categorized_txns_count
                              }
                              previouslyLinkedTxnsCount={
                                categorized_txns_with_linked_transaction_count
                              }
                              selectedCategory={selectedCategory?.label || ""}
                              txnCount={txns_count}
                            />
                          ) : (
                            <Disclaimer selectedCategory={selectedCategory} />
                          )}

                          {isAllTxnSelected ? (
                            <></>
                          ) : (
                            <motion.div
                              layout
                              initial={{ opacity: 0, y: 100 }}
                              animate={{ opacity: 1, y: 0 }}
                              transition={{
                                ease: "easeInOut",
                                duration: 0.5,
                              }}
                            >
                              {isLoading ? (
                                <Loader />
                              ) : (
                                <TransactionsView
                                  transactions={transactions}
                                  rowSelection={rowSelection}
                                  setRowSelection={setRowSelection}
                                  showCheckbox
                                />
                              )}
                            </motion.div>
                          )}
                        </AnimatePresence>
                      )}
                    </Form>
                  )}
                </Modal.Body>
                <Modal.Footer>
                  <div className="t-flex t-justify-end t-gap-3">
                    <Button
                      type="reset"
                      onClick={() => {
                        resetForm();
                        onClose();
                      }}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                    <Button
                      customType="primary"
                      onClick={submitForm}
                      isLoading={isSubmitting}
                      disabled={isSubmitting || !isValid}
                    >
                      Categorise all
                    </Button>
                  </div>
                </Modal.Footer>
                <ParentSelector
                  isOpen={parentSelectorModal.isOpen}
                  close={parentSelectorModal.close}
                  open={parentSelectorModal.open}
                  onSuccess={({ uuid }) => {
                    setFieldValue("category", uuid);
                  }}
                />
              </Modal.Content>
            );
          }}
        </Formik>
      </Modal.Root>
      <RuleConfirmation
        isOpen={isOpenConfirmation}
        close={closeConfirmation}
        deselectRows={deselectRows}
      />
      {showLinkModal &&
        transactionData?.transactions &&
        transactionData?.transactions.length > 0 &&
        selectedCategory && (
          <LinkTransaction
            isLinkTransactionOpen={showLinkModal}
            closeLinkTransaction={() => setShowLinkModal(false)}
            // @ts-ignore
            sourceTransaction={transactionData?.transactions.map(
              ({ transaction: t }) => ({
                transactionInfo: {
                  uuid: t.uuid,
                  amount: t.amount,
                  date: t.date,
                  merchant: t.merchant,
                  category: t.category,
                  from: t.from,
                },
                category: {
                  categoryType: selectedCategory.categoryType!,
                  // @ts-ignore
                  categoryName: selectedCategory.label!,
                  categoryId: selectedCategory.value!,
                  data: selectedCategory.data!,
                },
              })
            )}
            onSelect={categorizeAfterLink}
            categoryId={selectedCategoryId}
            isUpdating={categorizing}
          />
        )}
    </>
  );
};
