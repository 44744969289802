import React, { useContext, useEffect, useRef, useState } from "react";
import { ACTIVE } from "../../constants/adhocTaskIdentifier";

//context
import authContext from "../../jwt_context&axios/authContext";

//components
import { FormEngine } from "./step0";
import DriveEngine from "./step1";
import PaymentEngine from "./step2";
import ReviewForm from "./step3";
import SuccessBox from "./step5";
import { AdhocScopeOfWork } from "./adhocScopeOfWork";
import { AdhocTaskCompleted } from "./adhocTaskCompleted";
import { AdhocTaskInProgress } from "./adhocTaskInProgress";
import { AdhocAcceptPay } from "./adhocAcceptPay";
import { TaskContractors } from "components/TaskContractors";
import { Button } from "components/DesignSystem/Button/Button";
import { stateUpdate } from "apis/stateUpdate";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { TaskConnectBank } from "components/TaskConnectBank";
import TaskButtonBar from "./TaskButtonBar";
import { useToast } from "hooks/useToast";
import { AmendFiling } from "./AmendFiling";
import { useParams } from "react-router-dom";
import { WSeriesForm } from "components/WSeriesForm";
import { useDispatch, useSelector } from "react-redux";
import { setIsPricingStep, setShowPricingModal } from "store/slices/task";
import { useSetNoContractorsFoundMutation } from "store/apis/taskContractorsRequirements";
import { taskApis } from "store/apis/task";
import { Info } from "components/icons/Info";
import dayjs from "dayjs";
import { YYYY_MM_DD } from "constants/date";

export function getByValue(map, searchValue) {
  for (let [key, value] of map.entries()) {
    if (value === searchValue) return key;
  }
}

export default function GetIndex({
  updatedTaskFormFile,
  data,
  currentStateStep,
  setCurrentStateStep,
  setTaskStepName,
  selectedState,
  chk,
  docId,
  description,
  pricingData,
  pricingAmount,
  title,
  uid,
  mainId,
  form_data,
  task,
  inkleDocId,
  setLoad,
  groupId,
  isArchived,
  parentRef,
  updateAssignee,
  refetchTask,
  isFromServices,
}) {
  const [activeScopeOfWork, setActiveScopeOfWork] = useState({});
  const { alertToast } = useToast();
  const dispatch = useDispatch();
  const isPricingStep = useSelector((state) => state.taskSlice.isPricingStep);

  const isWseriesForm = task?.template_type === "W_SERIES";

  const current = currentStateStep || selectedState;
  const isCurrentStateSelected = currentStateStep === selectedState;

  let isAdhocTask = useRef(null);
  const check = new Map();
  const indexing = new Map();
  data?.forEach((element, index) => {
    check.set(element.uuid, element.type);
    indexing.set(element.uuid, index);
  });
  const { isCustomer, isAdmin } = useRoleBasedView();
  const { taskId } = useParams();
  const [setNoContractorsFound] = useSetNoContractorsFoundMutation();

  const { authtoken } = useContext(authContext);
  const chatHeader = document.getElementsByClassName(
    "sendbird-chat-header__left__title"
  )[0];
  const nextStateUuid = getByValue(indexing, indexing.get(current) + 1);
  const prevStateUuid = getByValue(indexing, indexing.get(current) - 1);
  const isPrevPricingStep =
    task?.task_states?.find(({ uuid }) => uuid === prevStateUuid)?.type === 2;
  const currentStateUuid = task?.state?.uuid;
  const isEditableForAdmin =
    (check.get(currentStateUuid) === 0 ||
      check.get(currentStateUuid) === 2 ||
      !currentStateUuid) &&
    isAdmin;

  useEffect(() => {
    if (task?.adhoc_data_updates?.length > 0) {
      isAdhocTask.current = true;
      return;
    }
    if (check.get(current) === 2 && nextStateUuid && !isArchived) {
      dispatch(setIsPricingStep(true));
      dispatch(setShowPricingModal(true));
    }
  }, [task]);

  useEffect(() => {
    if (chatHeader != undefined) {
      chatHeader.innerText = "Filing Chat";
    }
  }, [chatHeader]);

  useEffect(() => {
    let currentScopeOfWork =
      task?.adhoc_data_updates?.filter(
        ({ is_active }) => is_active === ACTIVE
      ) || [];
    setActiveScopeOfWork(...currentScopeOfWork);
  }, [task]);

  const goToStep = async (next) => {
    try {
      const id = next;
      await stateUpdate({ taskId, id });
      window.location.reload();
    } catch (error) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const moveToPreviousState = () =>
    goToStep(getByValue(indexing, indexing.get(current) - 1));

  const moveToNextState = () => {
    if (task?.state?.is_step_replaceable) {
      goToStep(task?.state?.new_state_id);
    } else {
      goToStep(getByValue(indexing, indexing.get(current) + 1));
    }
  };

  const currentState = task?.task_states.find((t) => t.uuid === current);
  const isSectionGroupAvailable =
    form_data.data?.data?.section_group?.[0]?.sections?.length > 0;
  const isPaid = task?.payment_status === "PAID";

  const coupon = task?.coupon;

  let finalPrice = coupon?.discounted_price || pricingAmount;
  const payBtnText = isPaid ? "Successfully paid" : `Pay $${finalPrice}`;

  const noContractorsFound = async () => {
    try {
      await setNoContractorsFound({
        taskId,
        groupId,
        isContractorsPresent: false,
      });
      dispatch(taskApis.util.invalidateTags([{ type: "Tasks", id: taskId }]));
    } catch (error) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const year = task?.season ? task?.season : dayjs().year() - 1;

  const getFilingStateView = (stepTypeToCheck) => {
    switch (stepTypeToCheck) {
      case 0: // state_type: FORM_FILING, state: W-Series
        return isAdhocTask.current ? (
          <AdhocScopeOfWork
            next={getByValue(indexing, indexing.get(current) + 1)}
            activeScopeOfWork={activeScopeOfWork}
            task={task}
            setLoad={setLoad}
            check={indexing.get(uid?.uuid)}
            groupId={groupId}
            hasCustomerApproved={activeScopeOfWork?.has_customer_approved}
            isArchived={isArchived}
            parentRef={parentRef}
          />
        ) : (
          <FormEngine
            updatedTask={updatedTaskFormFile}
            inkleDocId={inkleDocId}
            next={getByValue(indexing, indexing.get(current) + 1)}
            description={description}
            uuid={mainId}
            mainId={current === mainId ? true : false}
            form_data={form_data}
            data={data}
            current={current}
            groupId={task?.group_uuid}
            task={task}
            isArchived={isArchived}
            isFiling={check.get(current) === 4}
            parentRef={parentRef}
            entityId={task?.entity.uuid}
            isEditableForAdmin={isEditableForAdmin}
            isSectionGroupAvailable={isSectionGroupAvailable}
          />
        );
      case 1:
        return (
          <DriveEngine
            isWseries={isWseriesForm}
            inkleDocId={inkleDocId}
            groupId={task?.group_uuid}
            chk={chk}
            prev={getByValue(indexing, indexing.get(current) - 1)}
            next={getByValue(indexing, indexing.get(current) + 1)}
            docId={docId}
            uuid={mainId}
            mainId={current === mainId ? true : false}
            current={current}
            task={task}
            data={data}
            isArchived={isArchived}
            parentRef={parentRef}
            isPrevPricingStep={isPrevPricingStep}
          />
        );
      case 2:
        return (
          isAdhocTask.current && (
            <AdhocAcceptPay
              title={activeScopeOfWork?.scope_of_work}
              pricingAmount={activeScopeOfWork?.price}
              prev={getByValue(indexing, indexing.get(current) - 1)}
              next={getByValue(indexing, indexing.get(current) + 1)}
              check={indexing.get(uid?.uuid)}
              hasCustomerApproved={activeScopeOfWork?.has_customer_approved}
              groupId={groupId}
              task={task}
              isArchived={isArchived}
              parentRef={parentRef}
            />
          )
        );
      case 3:
        return (
          <ReviewForm
            inkleDocId={inkleDocId}
            prev={getByValue(indexing, indexing.get(current) - 1)}
            next={getByValue(indexing, indexing.get(current) + 1)}
            uuid={mainId}
            mainId={current === mainId ? true : false}
            current={current}
            data={data}
            groupId={task?.group_uuid}
            isArchived={isArchived}
            parentRef={parentRef}
            isWSeries={isWseriesForm}
            isSectionGroupAvailable={isSectionGroupAvailable}
            isFromServices={isFromServices}
          />
        );
      case 4: // state: FILINGIN_PROGRESS
        if (isWseriesForm) {
          return (
            <div className="d-flex flex-column t-w-full">
              <TaskContractors
                noContractorsFound={task?.are_contractors_present === false}
                description={
                  <div className="m-4">
                    <p className="mb-2 fw-bold block fa-2">Filing Form 1099</p>
                    <p className="m-0">
                      Thank you for sharing all the contractor details. We are
                      now in the process of filing Form 1099 and will share the
                      status update for each form below:
                    </p>
                  </div>
                }
                taskId={task?.uuid}
                groupId={task?.group_uuid}
                withFormProgress
                entityId={task?.entity.uuid}
              />
              {!task?.state?.is_final && (
                <TaskButtonBar
                  justifyContent={isPrevPricingStep ? "end" : "between"}
                  parentRef={parentRef}
                  addMarginLeft={false}
                >
                  {!isPrevPricingStep && (
                    <Button
                      onClick={moveToPreviousState}
                      customType="secondary"
                      size="regular"
                    >
                      Previous
                    </Button>
                  )}
                  <Button
                    disabled={isCustomer}
                    customType="primary"
                    size="regular"
                    onClick={moveToNextState}
                  >
                    Next
                  </Button>
                </TaskButtonBar>
              )}
            </div>
          );
        }
        return isAdhocTask.current ? (
          <AdhocTaskInProgress
            task={task}
            title={activeScopeOfWork?.scope_of_work}
            next={getByValue(indexing, indexing.get(current) + 1)}
            hasCustomerApproved={activeScopeOfWork?.has_customer_approved}
            activeScopeOfWork={activeScopeOfWork}
            isCompleted={task?.state?.is_final}
            groupId={task?.group_uuid}
            isArchived={isArchived}
            parentRef={parentRef}
          />
        ) : (
          <>
            {authtoken.is_service_user ? (
              <FormEngine
                prev={getByValue(indexing, indexing.get(current) - 1)}
                next={getByValue(indexing, indexing.get(current) + 1)}
                inkleDocId={inkleDocId}
                mainId={current === mainId ? true : false}
                form_data={form_data}
                uuid={mainId}
                current={current}
                data={data}
                groupId={task?.group_uuid}
                task={task}
                isArchived={isArchived}
                docId={docId}
                isFiling={check.get(current) === 4}
                parentRef={parentRef}
                entityId={task?.entity.uuid}
                isEditableForAdmin={isEditableForAdmin}
                isSectionGroupAvailable={isSectionGroupAvailable}
              />
            ) : (
              <ReviewForm
                prev={null}
                next={null}
                txt="In Progress"
                mainId={current === mainId ? true : false}
                current={current}
                inkleDocId={inkleDocId}
                data={data}
                uuid={mainId}
                groupId={task?.group_uuid}
                isArchived={isArchived}
                task={task}
                parentRef={parentRef}
                isInprogress
                isWSeries={isWseriesForm}
                isSectionGroupAvailable={isSectionGroupAvailable}
                isFromServices={isFromServices}
              />
            )}
          </>
        );
      case 5:
        return isAdhocTask.current ? (
          <AdhocTaskCompleted
            activeScopeOfWork={activeScopeOfWork}
            groupId={task?.group_uuid}
            task={task}
            parentRef={parentRef}
          />
        ) : (
          <>
            {authtoken.is_service_user ? (
              <ReviewForm
                prev={null}
                next={null}
                txt="Successful"
                mainId={current === mainId ? true : false}
                current={current}
                inkleDocId={inkleDocId}
                data={data}
                uuid={mainId}
                groupId={task?.group_uuid}
                isArchived={isArchived}
                task={task}
                isSuccessful
                parentRef={parentRef}
                isWSeries={isWseriesForm}
                isSectionGroupAvailable={isSectionGroupAvailable}
                isFromServices={isFromServices}
              />
            ) : (
              <SuccessBox
                task={task}
                parentRef={parentRef}
                isWSeries={isWseriesForm}
              />
            )}
          </>
        );
      case 7:
        return (
          <div className="t-flex t-flex-col t-mx-auto t-mt-8 t-w-full t-gap-10 t-p-5 t-overflow-auto">
            {currentState?.state_json.heading && (
              <div
                dangerouslySetInnerHTML={{
                  __html: `${currentState?.state_json.heading}`,
                }}
              />
            )}
            {currentState?.state_json.description && (
              <div
                dangerouslySetInnerHTML={{
                  __html: currentState?.state_json.description,
                }}
              />
            )}
            <TaskConnectBank
              entityId={task?.entity.uuid}
              groupId={groupId}
              date={dayjs(`${year}-12-31`).format(YYYY_MM_DD)}
            />
          </div>
        );
      case 8:
        return (
          <SuccessBox
            task={task}
            parentRef={parentRef}
            isAmendInProgress
            isWSeries={isWseriesForm}
          />
        );
      case 9:
        return (
          <AmendFiling
            task={task}
            parentRef={parentRef}
            isWSeries={isWseriesForm}
          />
        );
      case 10:
        return (
          <div className="d-flex flex-column t-w-full t-h-full">
            <WSeriesForm
              noContractorsFound={task?.are_contractors_present === false}
              groupId={task?.group_uuid}
              withFormProgress
              taskSeason={task?.season}
              shouldLoadBooksOnboarding={task?.should_load_books_onboarding}
              entityId={task?.entity.uuid}
            />
            {!task?.state?.is_final && (
              <TaskButtonBar
                justifyContent="end"
                parentRef={parentRef}
                addMarginLeft={false}
              >
                {!isCustomer && task?.are_contractors_present !== false && (
                  <div className="t-mr-2">
                    <Button onClick={noContractorsFound} customType="danger">
                      No contractors
                    </Button>
                  </div>
                )}
                {!isPaid && isPricingStep ? (
                  <Button
                    customType="primary"
                    onClick={() => dispatch(setShowPricingModal(true))}
                  >
                    {payBtnText}
                  </Button>
                ) : (
                  <Button
                    disabled={isCustomer}
                    customType="primary"
                    size="regular"
                    onClick={moveToNextState}
                  >
                    Next
                  </Button>
                )}
              </TaskButtonBar>
            )}
          </div>
        );
      default:
        return <div className="taxing"></div>;
    }
  };

  return (
    <div className="t-flex t-flex-col t-w-full">
      {isAdmin && !isCurrentStateSelected && (
        <div className="t-mx-3 t-my-3 t-p-3 t-bg-surface-lighter-grey t-border t-border-solid t-border-neutral-0 t-rounded-lg t-text-body-sm t-text-text-30 t-flex t-gap-2 t-items-center">
          <Info color="currentColor" stroke="1.4" />
          <span className="t-flex">
            You are currently viewing a previous step.&nbsp;
            <Button
              customType="link"
              size="extrasmall"
              onClick={() => {
                setCurrentStateStep(selectedState);
                setTaskStepName(task.state?.name);
              }}
            >
              Click here
            </Button>
            &nbsp;or refresh the page to return to the ongoing step.
          </span>
        </div>
      )}
      {check.get(current) === 2 && nextStateUuid && !isAdhocTask.current
        ? getFilingStateView(check.get(nextStateUuid))
        : getFilingStateView(check.get(current))}
      <PaymentEngine
        pricingData={pricingData}
        pricingAmount={pricingAmount}
        next={getByValue(indexing, indexing.get(current) + 1)}
        title={title}
        mainId={
          current === mainId && !Boolean(task?.state?.uuid) ? true : false
        }
        groupId={task?.group_uuid}
        task={task}
        isArchived={isArchived}
        updateAssignee={updateAssignee}
        refetchTask={refetchTask}
        isFromServices={isFromServices}
      />
    </div>
  );
}
