import { getEntityTree } from "apis/getEntities";
import { Button } from "components/DesignSystem/Button/Button";
import EntityTree from "components/Entity/EntityTree";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import {
  useCreateEntityMutation,
  useLazyGetGroupDataQuery,
} from "store/apis/group";
import AddIcon from "../../static/images/PlusCircle.svg";
import AddEntity from "../Entity/addMoreEntity";
import { LargeCard } from "components/home/Home";
import { OnboardingHomeCard } from "components/OnboardingHomeCard/OnboardingHomeCard";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import { useGetOnboardingProductsQuery } from "store/apis/productOnboarding";
import { openOnboardingWidget } from "store/slices/onboardingWidget";
import { useAppDispatch } from "hooks/useAppDispatch";

export default function Entities({
  entities,
  group_id,
  refetch: refetcFromProps,
}) {
  const { alertToast } = useToast();
  const [showAddEntity, setShowAddEntity] = useState(false);
  const [tree, setTree] = useState([]);
  const { dispatch } = useCurrentGroupContext();
  const [getGroupData] = useLazyGetGroupDataQuery();
  const appDispatch = useAppDispatch();
  const [createEntity, { isLoading: isCreating }] = useCreateEntityMutation();

  const setEntitiesData = async () => {
    try {
      const entityTree = await getEntityTree({ groupUuid: group_id });
      setTree(entityTree.data.data);
    } catch (e) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  useEffect(() => {
    setEntitiesData();
  }, []);

  const refetch = async () => {
    await setEntitiesData();
    await refetcFromProps();
  };

  const openEntityModal = () => {
    setShowAddEntity(true);
  };

  const { data: products } = useGetOnboardingProductsQuery(
    {
      groupId: group_id,
    },
    { skip: !group_id }
  );

  const platformOnboarding = products?.find(
    (p) =>
      p.name === PRODUCT_ONBOARDING.TAX || p.name === PRODUCT_ONBOARDING.TAXV2
  );

  const addEntityData = async (groupData) => {
    try {
      let entityData = {
        company_name: groupData.company_name,
        formation_type_id: groupData.formation_type_id,
        country_id: groupData.country,
        state_id: groupData.state_id,
      };
      await createEntity({ groupId: group_id, payload: entityData }).unwrap();
      const response = await getGroupData({ groupId: group_id }).unwrap();
      setShowAddEntity(false);
      dispatch({ type: "SET_GROUP", payload: response });
      refetch();
    } catch (e) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const entityMap = entities?.reduce((val, obj) => {
    val.set(obj.uuid, obj);
    return val;
  }, new Map());

  return (
    <div className="t-px-8">
      <div className="t-flex t-justify-end">
        <Button onClick={openEntityModal} customType="primary">
          <img src={AddIcon} alt="add icon" />
          Add Entity
        </Button>
      </div>
      <div className="t-mb-2 t-w-[462px]">
        <LargeCard>
          <OnboardingHomeCard
            onClick={() => {
              appDispatch(
                openOnboardingWidget({ product: platformOnboarding?.name })
              );
            }}
            progress={platformOnboarding?.progress || 0}
          />
        </LargeCard>
      </div>
      <div className="-t-ml-10 md:-t-ml-[72px]">
        <EntityTree
          tree={tree}
          entities={entityMap}
          groupId={group_id}
          refetch={refetch}
        />
      </div>
      <AddEntity
        addEntityData={addEntityData}
        showAddEntity={showAddEntity}
        setShowAddEntity={setShowAddEntity}
        isCreating={isCreating}
      />
    </div>
  );
}
