export const TITLE = "TITLE";
export const SUBTITLE = "SUBTITLE";
export const INPUT = "INPUT";
export const MOBILE_INPUT = "MOBILE_INPUT";
export const FILE_UPLOAD = "FILE_UPLOAD";
export const IMAGE = "IMAGE";
export const CTA = "CTA";
export const BANK_ACCOUNTS = "BANK_ACCOUNTS";
export const BULLET_POINT = "BULLET_POINT";
export const CHECKBOX = "CHECKBOX";
export const NOTES = "NOTES";
export const CLIENT_CONFIRMATION = "CLIENT_CONFIRMATION";
export const CUSTOM_FIELD = "CUSTOM_FIELD";
export const PLATFORM_PLAN = "PLATFORM_PLAN";
export const INCORPORATED_YEAR = "INCORPORATED_YEAR";
export const HIDDEN = "HIDDEN";
export const CONNECT_BANK = "CONNECT_BANK";
export const SUBSCRIPTION_COMPLETE = "SUBSCRIPTION_COMPLETE";
/**
 * @deprecated In new onboarding
 */
export const ACCOUNTING_SOFTWARE = "ACCOUNTING_SOFTWARE";
/**
 * @deprecated In new onboarding
 */
export const BOOKKEEPING_PLANS = "BOOKKEEPING_SUBSCRIPTION";
/**
 * @deprecated In new onboarding
 */
export const ENTER_INFORMATION = "ENTER_INFORMATION";
