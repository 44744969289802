import React from "react";
import { TaxTodos } from "./TaxTodos";
import { BooksTodos } from "./BooksTodos";
import { TaxActionItemType } from "store/apis/actionItems";

export const AllTodos = ({
  taxActionItems,
}: {
  taxActionItems: TaxActionItemType[];
}) => {
  return (
    <div>
      <div className="t-py-3 t-text-subtext t-text-text-60 t-border t-border-solid t-border-t-0 t-border-l-0 t-border-r-0 t-border-neutral-0">
        Tax
      </div>
      <TaxTodos taxActionItems={taxActionItems} />
      <div className="t-py-3 t-mt-6 t-text-subtext t-text-text-60 t-border t-border-solid t-border-t-0 t-border-l-0 t-border-r-0 t-border-neutral-0">
        Books
      </div>
      <BooksTodos />
    </div>
  );
};
