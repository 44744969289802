import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Formik, FormikValues } from "formik";
import { shareholderSchema } from "formValidations/ShareholderSchema";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React from "react";
import { useParams } from "react-router-dom";
import {
  useCreateShareholderMutation,
  useUpdateShareholderMutation,
} from "store/apis/Shareholders";
import { Shareholder } from "types/Models/Shareholders";
import { BackendError } from "types/utils/error";
import { AddShareholderForm } from "./AddShareholderForm";

export const PERSONA = "PERSONA";
export const COMPANY_ENTITY = "COMPANY_ENTITY";

export const AddShareholder = ({
  isOpen,
  close,
  shareholder,
  onSuccess,
  entityId: entityIdFromProps,
}: {
  isOpen: boolean;
  close: () => void;
  shareholder?: Shareholder | null;
  onSuccess?: (value: Shareholder) => void;
  entityId?: string;
}) => {
  const [addShareholder, { isLoading: isAdding }] =
    useCreateShareholderMutation();
  const [updateShareholder, { isLoading: isUpdating }] =
    useUpdateShareholderMutation();
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const { entityId } = useParams<{ entityId: string }>();
  const entity = entityId || entityIdFromProps;

  const handleCreateShareholder = async (values: FormikValues) => {
    try {
      const shareholder = await addShareholder({
        payload: {
          instance_id:
            values.shareholderType === PERSONA ? values.persona : values.entity,
          ownership_stake: values.ownership,
          shareholder_type: values.shareholderType,
          country_id: values.country_id,
          tin: values.tin,
          address_id: values.address,
        },
        groupId,
        entityId: entity!,
      }).unwrap();
      close();
      onSuccess?.(shareholder);
      successToast({ message: "Shareholder added successfully" });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const handleEditShareholder = async (values: FormikValues) => {
    try {
      if (shareholder?.uuid) {
        const updatedShareholder = await updateShareholder({
          payload: {
            instance_id:
              values.shareholderType === PERSONA
                ? values.persona
                : values.entity,
            ownership_stake: values.ownership,
            shareholder_type: values.shareholderType,
            country_id: values.country_id,
            tin: values.tin,
            address_id: values.address,
          },
          groupId,
          shareholderId: shareholder.uuid,
        }).unwrap();
        onSuccess?.(updatedShareholder);
        successToast({ message: "Shareholder updated successfully" });
      }
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const defaultValue = {
    shareholderType: shareholder?.shareholder_type || "",
    persona: shareholder?.instance_id || "",
    entity: shareholder?.instance_id || "",
    tin: shareholder?.tin || "",
    ownership: shareholder?.ownership_stake || "",
    country_id: shareholder?.country?.uuid || "",
    address: shareholder?.address?.uuid || "",
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content useCustomOverlay>
        <Formik
          initialValues={defaultValue}
          onSubmit={
            shareholder?.uuid ? handleEditShareholder : handleCreateShareholder
          }
          validationSchema={shareholderSchema}
          validateOnBlur={true}
          validateOnChange={true}
        >
          {({ submitForm }) => {
            return (
              <>
                <Modal.Header>
                  <Modal.Title>
                    {shareholder?.uuid ? "Edit" : "Add"} Shareholder
                  </Modal.Title>
                  <Modal.Close onClick={close} />
                </Modal.Header>
                <Modal.Body>
                  <AddShareholderForm entityId={entity} />
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button type="button">Cancel</Button>
                  </Modal.RawClose>
                  <Button
                    onClick={submitForm}
                    customType="primary"
                    type="submit"
                    isLoading={isAdding || isUpdating}
                    disabled={isAdding || isUpdating}
                  >
                    Save
                  </Button>
                </Modal.FooterButtonGroup>
              </>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
