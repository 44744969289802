import { Button } from "components/DesignSystem/Button/Button";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useToast } from "hooks/useToast";
import { useContext, useEffect, useState } from "react";
import { getEntities, getEntityTree } from "../../apis/getEntities";
import authContext from "../../jwt_context&axios/authContext";
import AddIcon from "../../static/images/PlusCircle.svg";
import "../../static/styles/components/entity.css";
import Loader from "../design/loader";
import AddEntity from "./addMoreEntity";
import DefinedRelationShip from "./entityModal/defineRelationShip";
import EntityTree from "./EntityTree";
import { useCreateEntityMutation } from "store/apis/group";

const EntityTab = () => {
  usePageTitle("Entities");
  const { alertToast } = useToast();
  const { authtoken, setGroupEntities } = useContext(authContext);
  const { uuid: groupUuid, dispatch } = useCurrentGroupContext();
  const [entities, setEntities] = useState([]);
  const [tree, setTree] = useState([]);
  const [groupName, setGroupName] = useState("");
  const [showAddEntity, setShowAddEntity] = useState(false);
  const [loader, setLoader] = useState(true);
  const [reloadValue, setReloadValue] = useState(true);
  const [showDefineRelationshipModal, setShowDefineRelationshipModal] =
    useState(false);
  const [createEntity, { isLoading: isCreating }] = useCreateEntityMutation();

  const setEntitiesData = async () => {
    setLoader(true);
    try {
      const response = await getEntities();
      setGroupEntities(response.data);
      setEntities(response.data.data.groups[0].entities);
      dispatch({ type: "SET_GROUP", payload: response.data.data.groups[0] });
      setGroupName(response.data.data.groups[0].name);
      const entityTree = await getEntityTree({
        groupUuid: response.data.data.groups[0].uuid,
      });
      setTree(entityTree.data.data);
      localStorage.setItem(
        "docId",
        response.data.data.groups[0].documents_group_id
      );

      setLoader(false);
    } catch (e) {
      alertToast({ message: e?.response?.data?.error?.message });
      setLoader(false);
    }
  };

  useEffect(() => {
    if (authtoken?.is_group_created) setEntitiesData();
  }, [reloadValue]);

  const handleDefineModal = () => {
    setShowDefineRelationshipModal(true);
  };

  const openEntityModal = () => {
    setShowAddEntity(true);
  };

  const addEntityData = async (groupData) => {
    try {
      setLoader(true);
      let entityData = {
        company_name: groupData.company_name,
        formation_type_id: groupData.formation_type_id,
        country_id: groupData.country,
        state_id: groupData.state_id,
      };
      await createEntity({
        groupId: groupUuid,
        payload: entityData,
      }).unwrap();
      setReloadValue(!reloadValue);
      setLoader(false);
      setShowAddEntity(false);
    } catch (e) {
      alertToast({ message: e?.response?.data?.error?.message });
    }
  };

  const entityMap = entities?.reduce((val, obj) => {
    val.set(obj.uuid, obj);
    return val;
  }, new Map());

  return (
    <div className="entityContainer">
      <div className="entityHeaderBar">
        <div className="">
          <h6 className="t-text-body">Overall Brand</h6>
          <h4 className="sm:t-text-subtitle t-text-title-h2-bold sm:t-leading-4 ">
            {groupName}
          </h4>
        </div>
        <Button size="small" customType="primary" onClick={openEntityModal}>
          <img src={AddIcon} alt="add icon" />
          Add Entity
        </Button>
      </div>
      {loader ? (
        <Loader />
      ) : (
        <div className="t-mt-8">
          <h6 className="t-mb-4 t-text-subtext">Your Brand Entities</h6>
          <div className="-t-ml-10 md:-t-ml-[72px]">
            <EntityTree
              tree={tree}
              entities={entityMap}
              groupId={groupUuid}
              refetch={() => setEntitiesData()}
            />
          </div>
          <div className="d-none">
            <Button size="medium" onClick={handleDefineModal}>
              Set Entities Relationship and IP Location.
            </Button>
            <DefinedRelationShip
              defineModalShow={showDefineRelationshipModal}
              setShowDefineRelationshipModal={setShowDefineRelationshipModal}
            />
          </div>
          <AddEntity
            addEntityData={addEntityData}
            showAddEntity={showAddEntity}
            setShowAddEntity={setShowAddEntity}
            isCreating={isCreating}
          />
        </div>
      )}
    </div>
  );
};

export default EntityTab;
