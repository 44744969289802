import React, { useMemo, useState } from "react";
import {
  useGetAllNexusTrackersQuery,
  useGetNexusTrackerTransactionsQuery,
  usePopulateBulkSalesTaxInvoiceMutation,
  useUpdateSalesTaxRegistrationMutation,
  useUploadBulkSalesTaxInvoiceMutation,
} from "store/apis/salesTax";
import {
  Cell,
  CellContext,
  createColumnHelper,
  getCoreRowModel,
  Row,
  useReactTable,
} from "react-table-8.10.7";
import { StateData, NexusTrackerTransaction } from "types/Models/nexusTracker";
import { TableUI } from "components/design/TableUI";
import DashboardContainer from "components/dashboard/DashboardContainer";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { CheckCircleFilled } from "components/icons/CheckCircleFilled";
import { SolidCheck } from "components/icons/SolidCheck";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ProgressBar from "components/DesignSystem/ProgressBar/ProgressBar";
import { InfoSolid } from "components/InfoSolid";
import { Warning } from "components/icons/Warning";
import { CircleWarning } from "components/icons/CircleWarning";
import { EmptySalesTax } from "components/Illustrations/EmptySalesTax";
import { Button } from "components/DesignSystem/Button/Button";
import { ConditionalLink } from "components/conditionalLink";
import { useGetAllConnections } from "hooks/useGetAllConnections";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import Slider from "components/DesignSystem/Slider/Slider";
import { formatDate } from "utils/formatDate";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { usePagination } from "hooks/usePagination";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { BankLogo } from "components/icons/BankLogo";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { PlusIcon } from "components/icons/PlusIcon";
import { Import } from "components/icons/Import";
import { CaretDown } from "components/icons/CaretDown";
import { BulkInvoiceUpload } from "components/InvoiceList/BulkInvoiceUpload";
import { useModal } from "hooks/useModal";
import {
  useCreateEntityProductOnboardingMutation,
  useCreateProductOnboardingMutation,
  useGetEntityOnboardingProductsQuery,
  useGetOnboardingProductsQuery,
} from "store/apis/productOnboarding";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import { useDispatch } from "react-redux";
import { openOnboardingWidget } from "store/slices/onboardingWidget";
import Modal from "components/DesignSystem/Modal/Modal";
import { FormikForm } from "components/FormikForm/FormikForm";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { stopPropagation } from "utils/stopPropagation";
import { useFilters } from "hooks/useFilter";
import { UserGroup } from "components/icons/UserGroup";
import ToolTip from "components/design/toolTip";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import { BULK_SALES_TAX_INVOICE_EXCEL_TEMPLATE_URL } from "types/contants/sampleExcelSheet";

const columnHelper = createColumnHelper<StateData>();
const transactionColumnHelper = createColumnHelper<NexusTrackerTransaction>();

const NexusTransactions = ({ jurisdiction }: { jurisdiction: string }) => {
  const [pageNum, setPageNum] = useState(1);
  const entityId = useCurrentEntityId();

  const { data: transactionsData, isLoading } =
    useGetNexusTrackerTransactionsQuery({
      entityId,
      jurisdictionName: jurisdiction,
      pageNum,
    });

  const paginationFns = usePagination({
    pageNumber: pageNum,
    onPageNumChange: (page) => setPageNum(page),
    totalPage: transactionsData?.total_pages,
  });

  const paginationData = {
    totalPage: transactionsData?.total_pages!,
    currentPage: transactionsData?.current_page!,
    itemsPerPage: transactionsData?.per_page!,
    totalItemCount: transactionsData?.total_count!,
    ...paginationFns,
  };

  const columns = useMemo(
    () => [
      transactionColumnHelper.accessor("date", {
        header: "Date",
        cell: (info) => formatDate(info.getValue()),
        size: 30,
      }),
      transactionColumnHelper.accessor("customer_name", {
        header: "Customer",
        cell: (info) => (
          <div>
            <div className="t-text-body t-text-text-60">{info.getValue()}</div>
            <div className="t-text-body-sm t-text-text-30">
              {info.row.original.customer_email}
            </div>
          </div>
        ),
        size: 30,
      }),
      transactionColumnHelper.accessor("amount", {
        header: () => <div className="t-text-right">Amount</div>,
        cell: (info) => (
          <div className="t-text-right">
            <AmountSuperScript amount={info.getValue()} />
          </div>
        ),
        size: 25,
      }),
      transactionColumnHelper.accessor("source", {
        header: "Source",
        cell: (info) => {
          const { logo_url, customer_name } = info.row.original;
          return (
            <div className="t-flex t-justify-center">
              {logo_url ? (
                <ToolTip text={customer_name}>
                  <span>
                    <Avatar size="small" src={logo_url} alt={info.getValue()} />
                  </span>
                </ToolTip>
              ) : (
                <ToolTip text="Manual">
                  <span>
                    <UserGroup />
                  </span>
                </ToolTip>
              )}
            </div>
          );
        },
        size: 15,
      }),
    ],
    []
  );

  const table = useReactTable({
    data: transactionsData?.transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (isLoading) {
    return (
      <div className="t-flex t-items-center t-justify-center t-h-full">
        <Loader />
      </div>
    );
  }

  return (
    <div className="t-mt-4">
      <div className="t-flex t-justify-between t-items-center t-mb-4">
        <p className="t-text-subtitle-sm t-text-text-60 t-m-0">Transactions</p>
        <Pagination {...paginationData} />
      </div>
      <TableUI table={table} size="regular" />
    </div>
  );
};

const statusMapping = {
  MONITORING: "Monitoring",
  APPROACHING: "Approaching",
  EXCEEDED: "Exceeding",
};

const statusColorMapping = {
  MONITORING: "gray" as const,
  APPROACHING: "yellow" as const,
  EXCEEDED: "red" as const,
};

const statusEmptyTextMapping = {
  MONITORING: {
    title: "No sales found",
    description:
      "We'll automatically start tracking as sales get recorded.  Some states may be approaching or exceeding threshold.",
  },
  APPROACHING: {
    title: "No jurisdiction approaching threshold",
    description:
      "We are actively tracking your sales. Any jurisdiction that approaches threshold will move here automatically.",
  },
  EXCEEDED: {
    title: "No jurisdiction has exceeded threshold",
    description:
      "We are actively tracking your sales. Any jurisdiction that crosses threshold will move here automatically.",
  },
};

const statusFontColorMapping = {
  /* @tw */
  MONITORING: "" as const,
  /* @tw */
  APPROACHING: "t-text-yellow-90" as const,
  /* @tw */
  EXCEEDED: "t-text-red" as const,
};

const SALES_TAX_PRODUCT_ID = process.env.PUBLIC_SALES_TAX_PRODUCT_ID!;

const Register = ({
  row: {
    original: { jurisdiction, state_registration_id, tax_detail_id },
  },
}: CellContext<StateData, void>) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entity = useCurrentEntityId();

  const { successToast, alertToast } = useToast();

  const {
    data: products,
    isLoading,
    isSuccess,
  } = useGetEntityOnboardingProductsQuery(
    {
      groupId: groupId!,
      entityId: entity!,
    },
    { skip: !groupId || !entity }
  );

  const [createOnboarding] = useCreateEntityProductOnboardingMutation();
  const [updateRegistration, { isLoading: updatingRegistrations }] =
    useUpdateSalesTaxRegistrationMutation();

  const dispatch = useDispatch();
  const registrationModal = useModal();

  const onClick = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const salesTaxOnboarding = products?.find(
      (p) => p.name === PRODUCT_ONBOARDING.SALES_TAX
    )!;

    if (!salesTaxOnboarding) {
      await createOnboarding({
        productId: SALES_TAX_PRODUCT_ID,
        groupId,
        entityId: entity,
      }).unwrap();

      return dispatch(
        openOnboardingWidget({
          product: PRODUCT_ONBOARDING.SALES_TAX,
          entityId: entity,
        })
      );
    }

    if (salesTaxOnboarding.status !== "COMPLETED") {
      return dispatch(
        openOnboardingWidget({
          product: PRODUCT_ONBOARDING.SALES_TAX,
          entityId: entity,
        })
      );
    }

    registrationModal.open();
  };

  const onAddRegistration = async (values: { tax_detail_id: string }) => {
    try {
      await updateRegistration({
        entityId: entity,
        registration_id: state_registration_id,
        payload: {
          tax_detail_id: values.tax_detail_id,
        },
      }).unwrap();
      registrationModal.close();
      successToast({
        message: "Registration added successfully",
      });
    } catch (error: any) {
      alertToast({
        message: (error as BackendError).data?.error?.message,
      });
    }
  };

  if (tax_detail_id) {
    return <span className="t-text-text-60">Registered</span>;
  }

  return (
    <>
      <Button size="small" customType="primary" onClick={onClick}>
        Register
      </Button>
      <Modal.Root
        open={registrationModal.isOpen}
        onOpenChange={registrationModal.close}
      >
        <Modal.Content onClick={stopPropagation}>
          <FormikForm
            initialValues={{
              tax_detail_id: "",
            }}
            // @ts-ignore
            onSubmit={onAddRegistration}
          >
            <Modal.Header>
              <Modal.Title>Add Registrations</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TextInput
                label="Existing tax accounts ID in this state"
                note={
                  <span className="t-text-neutral-40">
                    e.g. Sales Tax ID Number, EIN, SOS ID, Reseller Certificate
                    Number
                  </span>
                }
                name="tax_detail_id"
              />
            </Modal.Body>
            <Modal.Footer>
              <Modal.FooterButtonGroup>
                <Button onClick={registrationModal.close}>Cancel</Button>
                <Button
                  type="submit"
                  customType="primary"
                  isLoading={updatingRegistrations}
                  disabled={updatingRegistrations}
                >
                  Add
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Footer>
          </FormikForm>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};

export const SalesNexusTracker = () => {
  const entityId = useCurrentEntityId();
  const group = useCurrentGroupContext();
  const [activeState, setActiveState] = useState<string | null>(null);
  const { isAdmin } = useRoleBasedView();
  const bulkInvoiceModal = useModal();

  const { values, updateFilter } = useFilters<{
    STATUS: keyof typeof statusMapping;
  }>({
    initialValue: {
      STATUS: "MONITORING",
    },
  });

  const {
    data: nexusTrackerData,
    isLoading,
    isSuccess,
  } = useGetAllNexusTrackersQuery({
    pageNum: 1,
    entityId,
    filter_status: values.STATUS,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("jurisdiction", {
        header: "State",
        cell: (info) => info.getValue(),
        size: 25,
      }),
      columnHelper.accessor("transactions_amount", {
        header: "Sales",
        cell: (info) => (
          <div className="t-text-right t-flex t-gap-1">
            <AmountSuperScript amount={info.getValue()} />{" "}
            <>
              /{" "}
              {Boolean(info.row.original.transaction_amount_limit) ? (
                <AmountSuperScript
                  amount={info.row.original.transaction_amount_limit ?? 0}
                />
              ) : (
                "None"
              )}
            </>
          </div>
        ),
        size: 15,
      }),
      columnHelper.accessor("transaction_count", {
        header: "Transactions",
        cell: (info) => (
          <div className="t-text-right t-flex t-gap-1">
            <span className={statusFontColorMapping[info.row.original.status]}>
              {info.getValue()}
            </span>

            <>/ {info.row.original.transaction_count_limit || " None"}</>
          </div>
        ),
        size: 15,
      }),
      columnHelper.accessor("physical_nexus_available", {
        header: "Nexus type",
        cell: (info) => <span>Economic {info.getValue() && `, Physical`}</span>,
        size: 15,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (info) => (
          <Tag icon={false} tagType={statusColorMapping[info.getValue()]}>
            {statusMapping[info.getValue()]}
          </Tag>
        ),
        size: 10,
      }),
      columnHelper.display({
        header: "Action",
        cell: (info) => <Register {...info} />,
        size: 10,
      }),
    ],
    []
  );

  const table = useReactTable({
    data: nexusTrackerData?.states_data!,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const openSlider = (row: Row<StateData>) => {
    setActiveState(row.original.jurisdiction);
  };

  const activeStateDetails = nexusTrackerData?.states_data.find(
    (state) => state.jurisdiction === activeState
  );

  return (
    <DashboardLayout header={<Header v2 title="Nexus Tracker" />}>
      <Async.Root isLoading={isLoading} isEmpty={false} isSuccess={isSuccess}>
        <Async.Success>
          <DashboardContainer className="t-h-full">
            <DashboardContainer.Content>
              <div className="t-flex t-justify-between">
                <ToggleGroup
                  value={values.STATUS || undefined}
                  onValueChange={(value: keyof typeof statusMapping) =>
                    updateFilter("STATUS", value)
                  }
                >
                  <ToggleGroupItem value="MONITORING">
                    Monitoring
                  </ToggleGroupItem>
                  <ToggleGroupItem value="APPROACHING">
                    Approaching
                  </ToggleGroupItem>
                  <ToggleGroupItem value="EXCEEDED">Exceeding</ToggleGroupItem>
                </ToggleGroup>

                <div className="t-flex t-gap-1.5 t-ml-auto">
                  <Dropdown.Root>
                    <Dropdown.Trigger asChild className="t-group">
                      <div>
                        <Button size="small">
                          Add
                          <div className="group-data-state-open:t-rotate-180 t-ml-2">
                            <CaretDown />
                          </div>
                        </Button>
                      </div>
                    </Dropdown.Trigger>
                    <Dropdown.Content align="end">
                      <Dropdown.Arrow />
                      <Dropdown.Item
                        icon={<Import />}
                        onClick={bulkInvoiceModal.open}
                      >
                        Add invoices via excel file
                      </Dropdown.Item>
                    </Dropdown.Content>
                  </Dropdown.Root>
                </div>
              </div>
              <div className="t-pt-4">
                {nexusTrackerData?.states_data.length === 0 ? (
                  <div className="t-h-full t-flex t-flex-col t-items-center t-justify-center">
                    <div className="t-mt-40">
                      <EmptySalesTax />
                    </div>
                    <>
                      <p className="t-text-text-100 t-text-body-sm t-mb-0 t-mt-6">
                        {statusEmptyTextMapping[values.STATUS].title}
                      </p>
                      <p className="t-text-text-30 t-text-body-sm t-mb-0 t-mt-1 t-max-w-[450px]">
                        {statusEmptyTextMapping[values.STATUS].description}
                      </p>
                    </>
                  </div>
                ) : (
                  <TableUI
                    onRowClick={openSlider}
                    sticky={false}
                    table={table}
                    size="regular"
                  />
                )}
              </div>
            </DashboardContainer.Content>
          </DashboardContainer>
        </Async.Success>
      </Async.Root>

      <BulkInvoiceUpload
        isOpen={bulkInvoiceModal.isOpen}
        close={bulkInvoiceModal.close}
        usePopulateMutation={usePopulateBulkSalesTaxInvoiceMutation}
        useUploadMutation={useUploadBulkSalesTaxInvoiceMutation}
        templateUrl={BULK_SALES_TAX_INVOICE_EXCEL_TEMPLATE_URL}
      />

      {activeStateDetails && (
        <Slider.Root
          open={Boolean(activeState)}
          onOpenChange={() => setActiveState(null)}
        >
          <Slider.Content width={540}>
            <Slider.Header>
              <Slider.Title>
                <div className="t-flex t-gap-2 t-items-center">
                  <span>{activeState}</span>
                  <Tag
                    icon={false}
                    tagType={statusColorMapping[activeStateDetails?.status]}
                  >
                    {statusMapping[activeStateDetails?.status]}
                  </Tag>
                </div>
              </Slider.Title>
              <Slider.Close />
            </Slider.Header>
            <Slider.Body>
              <div className="t-flex t-flex-col t-gap-2">
                <div className="t-flex t-gap-4 t-border-solid t-border t-border-neutral-10 t-rounded-lg">
                  <div className="t-flex t-flex-col t-gap-2 t-p-4 t-w-1/2">
                    <p className="t-text-subtext t-text-text-30 t-m-0">Sales</p>
                    <div className="t-flex t-gap-1.5 t-items-center">
                      <span className="t-text-subtitle t-text-text-100 t-m-0">
                        <AmountSuperScript
                          amount={activeStateDetails?.transactions_amount}
                        />{" "}
                        <span className="t-text-body t-text-neutral-40">/</span>
                      </span>{" "}
                      {
                        <p className="t-text-body-sm t-text-text-30 t-m-0">
                          {activeStateDetails?.transaction_amount_limit ? (
                            <AmountSuperScript
                              amount={
                                activeStateDetails?.transaction_amount_limit
                              }
                            />
                          ) : (
                            "None"
                          )}
                        </p>
                      }
                    </div>
                    {activeStateDetails && (
                      <div className="t-flex t-gap-1.5 t-items-center">
                        {activeStateDetails?.transaction_amount_limit && (
                          <div className="t-flex-1">
                            <ProgressBar.Root type="grey">
                              <ProgressBar.Indicator
                                progress={
                                  (activeStateDetails?.transactions_amount /
                                    activeStateDetails?.transaction_amount_limit) *
                                  100
                                }
                              />
                            </ProgressBar.Root>
                          </div>
                        )}

                        {activeStateDetails?.transactions_amount &&
                          activeStateDetails?.transaction_amount_limit && (
                            <span className="t-text-text-30 t-text-body-sm">
                              {(activeStateDetails?.transactions_amount /
                                activeStateDetails?.transaction_amount_limit) *
                                100}
                              %
                            </span>
                          )}
                      </div>
                    )}
                  </div>
                  <div className="t-flex t-flex-col t-gap-2 t-p-4 t-w-1/2">
                    <p className="t-text-subtext t-text-text-30 t-m-0">
                      Transactions
                    </p>

                    <div className="t-flex t-gap-1.5 t-items-center">
                      <span className="t-text-subtitle t-text-text-100 t-m-0">
                        {activeStateDetails?.transaction_count}{" "}
                        <span className="t-text-body t-text-neutral-40">/</span>
                      </span>{" "}
                      <p className="t-text-body-sm t-text-text-30 t-m-0">
                        {activeStateDetails?.transaction_count_limit
                          ? activeStateDetails?.transaction_count_limit
                          : "None"}
                      </p>
                    </div>

                    {activeStateDetails && (
                      <div className="t-flex t-gap-1.5 t-items-center">
                        {activeStateDetails?.transaction_count_limit && (
                          <div className="t-flex-1">
                            <ProgressBar.Root type="grey">
                              <ProgressBar.Indicator
                                progress={
                                  (activeStateDetails?.transaction_count /
                                    activeStateDetails?.transaction_count_limit) *
                                  100
                                }
                              />
                            </ProgressBar.Root>
                          </div>
                        )}

                        {activeStateDetails?.transaction_count &&
                          activeStateDetails?.transaction_count_limit && (
                            <span className="t-text-text-30 t-text-body-sm">
                              {(activeStateDetails?.transaction_count /
                                activeStateDetails?.transaction_count_limit) *
                                100}
                              %
                            </span>
                          )}
                      </div>
                    )}
                  </div>
                </div>

                {activeState && (
                  <NexusTransactions jurisdiction={activeState} />
                )}
              </div>
            </Slider.Body>
          </Slider.Content>
        </Slider.Root>
      )}
    </DashboardLayout>
  );
};
