import classNames from "classnames";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { DashboardLayout } from "components/DashboardLayout";
import Loader from "components/design/loader";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { Header } from "components/DesignSystem/Header/Header";
import { PriceInput } from "components/PriceInput/PriceInput";
import { Form, Formik, FormikProps } from "formik";
import { taxCalculateSchema } from "formValidations/taxCalculatorSchema";
import { motion } from "framer-motion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useToast } from "hooks/useToast";
import { ReactNode, useEffect, useRef, useState } from "react";
import { MultiValue, SingleValue } from "react-select";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import ArrowElbowDownRight from "static/images/ArrowElbowDownRight.svg";
import { ArrowDown } from "components/icons/ArrowDown";

import {
  CalculateTaxPayload,
  useCalculateTaxMutation,
  useGetAllTaxCodeQuery,
} from "store/apis/taxCalculator";
import { TaxCalculateResponse, TaxCode } from "types/Models/taxCalculate";
import { BackendError } from "types/utils/error";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { SelectAutofillCombobox } from "components/SelectAutofillCombobox";
import { AddressAutofill } from "types/Models/addressAutofill";
import { usePageTitle } from "hooks/usePageTitle";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Async from "components/DesignSystem/AsyncComponents/Async";
import * as Accordion from "@radix-ui/react-accordion";
import { ToolTipIcon } from "components/icons/TooltipIcon";

type SelectOption = {
  label: string | JSX.Element;
  value: string;
  data?: string;
};

type CategoryGroup = {
  label: string;
  options: SelectOption[];
  data?: string;
};

const calculateTaxOnItem = ({ itemAmount = 0, taxRate = 0 }) => {
  const taxRateDecimal = taxRate / 100;
  const taxAmount = itemAmount * taxRateDecimal;

  const totalAmountAfterTax = itemAmount + taxAmount;

  return {
    taxAmount: taxAmount?.toFixed(2),
    totalAmountAfterTax: totalAmountAfterTax?.toFixed(2),
  };
};

const sortDataByCategory = (taxCodes: TaxCode[]) => {
  const categoryPriority: Record<string, TaxCode[]> = {
    saas: [],
    "custom software": [],
    services: [],
    others: [],
  };

  taxCodes?.forEach((taxCode) => {
    categoryPriority[taxCode?.category?.toLowerCase()]?.push(taxCode);
  });
  return Object.values(categoryPriority)?.flat() || [];
};

const transformTaxCodes = (taxCodes: TaxCode[]): CategoryGroup[] => {
  const sortedTaxCodes = sortDataByCategory(taxCodes);

  return sortedTaxCodes?.reduce<CategoryGroup[]>((acc, taxCode) => {
    let categoryGroup = acc.find((group) => group.label === taxCode.category);

    if (!categoryGroup) {
      categoryGroup = {
        label: taxCode.category,
        options: [],
      };
      acc.push(categoryGroup);
    }
    categoryGroup.options.push({
      label: (
        <div className="t-flex t-gap-1.5 t-w-full">
          <img src={ArrowElbowDownRight} alt="ArrowElbowDownRight" />
          <span>{taxCode.description}</span>
        </div>
      ),
      value: taxCode.uuid,
      data: taxCode.description.toString().concat(taxCode.category),
    });

    return acc;
  }, []);
};

const EstimatedTaxDetailsItem = ({
  label,
  value,
  underline = false,
  type,
  children,
}: {
  label: string;
  value?: string | ReactNode;
  underline?: boolean;
  type?: string;
  children?: ReactNode;
}) => {
  return (
    <div
      className={classNames("t-flex t-justify-between t-items-center t-py-3", {
        "t-border-0 t-border-b t-border-solid t-border-neutral-0": underline,
      })}
    >
      <span
        className={classNames("t-text-text-30 t-text-body-sm t-m-0", {
          "t-text-subtext-sm": type === "header",
          "!t-text-text-100 t-text-subtitle-sm": type === "result",
        })}
      >
        {label}
      </span>
      {children || (
        <span
          className={classNames(
            "t-text-text-60 t-text-subtext t-m-0 t-max-w-32 t-overflow-x-clip t-text-ellipsis",
            {
              "t-text-subtext-sm": type === "header",
              "t-text-text-100 t-text-subtitle-sm": type === "result",
            }
          )}
        >
          {value}
        </span>
      )}
    </div>
  );
};

export const TaxCalculator = () => {
  usePageTitle("Tax Calculator");
  const entityId = useCurrentEntityId();
  const { data: productDescription, isLoading: isTaxCodeLoading } =
    useGetAllTaxCodeQuery({ entityId }, { skip: !entityId });
  const [calculateTax, { isLoading: isCalculatingTax, data: taxData, reset }] =
    useCalculateTaxMutation();

  const { alertToast } = useToast();

  const { data: countriesData } = useGetCountriesDataQuery();

  const onSubmit = async (values: CalculateTaxPayload) => {
    const sellerCountryCode = countriesData?.find(
      (country) => country.name === values.seller_address.country
    )?.code_alpha_2;
    const buyerCountryCode = countriesData?.find(
      (country) => country.name === values.buyer_address.country
    )?.code_alpha_2;

    if (!sellerCountryCode || !buyerCountryCode) {
      return;
    }

    const payload = {
      ...values,
      seller_address: {
        ...values.seller_address,
        countryCode: sellerCountryCode,
      },
      buyer_address: {
        ...values.buyer_address,
        countryCode: buyerCountryCode,
      },
      product_detail: {
        ...values.product_detail,
      },
    };

    delete payload.buyer_address.uuid;
    delete payload.buyer_address.country;
    delete payload.seller_address.uuid;
    delete payload.seller_address.country;

    try {
      await calculateTax({
        payload,
        entityId,
      }).unwrap();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };
  const formikRef = useRef<FormikProps<CalculateTaxPayload>>(null);

  const onCalculateNewTax = () => {
    if (formikRef.current) {
      formikRef?.current?.resetForm();
      reset();
    }
  };

  const filterOption = (
    option: FilterOptionOption<OptionData>,
    searchedTerm: string
  ) => {
    if (!option.data?.data) {
      return true;
    }
    const optionData = option.data as SelectOption;
    const searchTerm = searchedTerm.toLowerCase();
    const categoryData = optionData?.data?.toLowerCase();

    return Boolean(categoryData?.includes(searchTerm));
  };

  useEffect(() => {
    reset();
    formikRef?.current?.resetForm();
  }, [entityId]);

  return (
    <DashboardLayout>
      <DashboardContainer className="t-min-h-full  t-items-center">
        <DashboardContainer.Content hasMaximumWidth className="t-min-h-full">
          <div>
            <Header v2 title="Tax Calculator" />
          </div>
          <div className="t-grid t-gap-3 t-grid-cols-2 t-mt-4">
            <Formik<CalculateTaxPayload>
              initialValues={{
                seller_address: {
                  zipCode: "",
                  state: "",
                  city: "",
                  address1: "",
                  uuid: "",
                },
                buyer_address: {
                  zipCode: "",
                  state: "",
                  city: "",
                  address1: "",
                },
                product_detail: {
                  uuid: "",
                  tax_code: "",
                  label: "",
                },
              }}
              validationSchema={taxCalculateSchema}
              validateOnChange
              onSubmit={onSubmit}
              innerRef={formikRef}
            >
              {({
                submitForm,
                isSubmitting,
                values,
                setFieldValue,
                validateForm,
              }) => {
                const onChange = (
                  option: MultiValue<OptionData> | SingleValue<OptionData>
                ) => {
                  if (option instanceof Array) {
                    return;
                  }
                  const selectedTaxCode = productDescription?.find(
                    (item) => item.uuid === option?.value
                  );
                  if (selectedTaxCode) {
                    setFieldValue("product_detail", {
                      uuid: selectedTaxCode?.uuid,
                      tax_code: selectedTaxCode?.tax_code,
                      label: selectedTaxCode?.description,
                    });
                  }
                };

                const addAddress = (address: AddressAutofill, key: string) => {
                  setFieldValue(key, {
                    country: address?.country,
                    zipCode: address?.zipcode,
                    state: address?.state,
                    city: address?.city,
                    address1: address?.street_address,
                    uuid: address?.uuid,
                  });
                };

                const handleSubmit = async () => {
                  try {
                    const validated = await validateForm();
                    if (Boolean(Object.keys(validated).length)) {
                      return alertToast({
                        title: "Unable to calculate",
                        message: "Enter all mentioned info.",
                      });
                    } else {
                      submitForm();
                    }
                  } catch (error) {
                    alertToast({
                      title: "Unable to calculate",
                      message: "Enter all mentioned info.",
                    });
                  }
                };

                return (
                  <Form>
                    <div className="t-rounded-md t-transition-all t-border t-min-h-fit t-max-h-fit t-border-solid t-border-neutral-10 t-flex t-flex-col t-gap-3 t-p-4">
                      <p className="t-text-text-60 t-text-subtitle t-pr-20 t-mb-8">
                        Enter following details to get accurate sales tax rate{" "}
                      </p>
                      <SelectAutofillCombobox
                        withForm
                        type="addresses"
                        name="seller_address"
                        allowedCountries={["United States"]}
                        selected={values?.seller_address?.uuid}
                        label="Seller address"
                        entityId={entityId}
                        onSelect={(address) =>
                          addAddress(
                            address as AddressAutofill,
                            "seller_address"
                          )
                        }
                      />
                      <SelectAutofillCombobox
                        withForm
                        type="addresses"
                        allowedCountries={["United States"]}
                        name="buyer_address.uuid"
                        selected={values?.buyer_address?.uuid}
                        label="Buyer address"
                        entityId={entityId}
                        onSelect={(address) =>
                          addAddress(
                            address as AddressAutofill,
                            "buyer_address"
                          )
                        }
                      />
                      <Combobox
                        label="Production Description"
                        size="regular"
                        name={"product_detail."}
                        onChange={onChange}
                        isLoading={isTaxCodeLoading}
                        placeholder="Select"
                        value={
                          Boolean(values.product_detail.tax_code)
                            ? {
                                label: values.product_detail.label,
                                value: values.product_detail.tax_code,
                              }
                            : null
                        }
                        filterOption={(option, searchedTerm) =>
                          filterOption(option, searchedTerm)
                        }
                        options={
                          productDescription
                            ? transformTaxCodes(productDescription)
                            : []
                        }
                        isClearable={false}
                      />

                      <Button
                        onClick={handleSubmit}
                        isLoading={isSubmitting}
                        disabled={Boolean(isSubmitting)}
                        customType="primary"
                        block
                      >
                        Calculate
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
            <EstimatedSalesTax
              onCalculateNewTax={onCalculateNewTax}
              isLoading={isCalculatingTax}
              taxData={taxData}
            />
          </div>
        </DashboardContainer.Content>
      </DashboardContainer>
    </DashboardLayout>
  );
};

const EstimatedSalesTax = ({
  taxData,
  isLoading,
  onCalculateNewTax,
}: {
  taxData?: TaxCalculateResponse;
  isLoading: boolean;
  onCalculateNewTax: () => void;
}) => {
  return (
    <div className="t-bg-estimatedtax-gradient t-p-4 t-min-fit t-max-h-fit t-border-[1px] t-rounded-md t-border-solid t-border-neutral-0">
      <p className="t-text-text-60 t-text-subtitle t-font-bold t-pr-60 t-min-w-fit t-text-nowrap">
        Estimated Sales Tax rate
      </p>
      <div className="t-flex t-justify-center t-items-center t-h-20 t-shadow-md t-rounded-md  t-bg-surface">
        <Async.Root
          isLoading={isLoading}
          isSuccess={Boolean(!isLoading)}
          isEmpty={Boolean(!isLoading && !taxData)}
          isError={false}
          customLoader={
            <div>
              <Loader size="small" />
              <p className="t-text-text-60 t-text-body-sm t-m-0 t-mt-2">
                Calculating your sales tax rate
              </p>
            </div>
          }
        >
          <Async.Empty>
            <p className="t-text-title-h2-bold t-text-text-100 t-m-0">{`-%`}</p>
          </Async.Empty>
          <Async.Success>
            <p className="t-text-title-h2-bold t-text-text-100 t-m-0">
              {taxData?.total_tax_rate?.toFixed(2)}%
            </p>
          </Async.Success>
        </Async.Root>
      </div>
      {Boolean(!isLoading && taxData) && (
        <div>
          <div className="t-bg-surface t-px-3 t-mt-4">
            <EstimatedTaxDetailsItem
              label={"REGION"}
              type="header"
              value={"TAX RATE"}
              underline
            />
            <EstimatedTaxDetailsItem
              label={`State Tax (${taxData?.jurisdiction?.state})`}
              value={`${taxData?.state_tax_rate?.toFixed(2)}%`}
              underline
            />
            <EstimatedTaxDetailsItem
              label={`County Tax (${taxData?.jurisdiction?.county})`}
              value={`${taxData?.county_tax_rate?.toFixed(2)}%`}
              underline
            />
            <EstimatedTaxDetailsItem
              label={`City Tax (${taxData?.jurisdiction?.city})`}
              value={`${taxData?.city_tax_rate?.toFixed(2)}%`}
              underline
            />
            <EstimatedTaxDetailsItem
              label={"Total tax rate"}
              value={`${taxData?.total_tax_rate?.toFixed(2)}%`}
              type="result"
            />
          </div>
          <CalculateTotalAmount taxRate={taxData?.total_tax_rate} />
          <div className="t-mt-2 t-flex t-gap-1 t-items-center">
            <div>
              <span
                onClick={onCalculateNewTax}
                className="t-text-purple t-text-subtext-sm t-m-0 t-cursor-pointer"
              >
                Calculate new
              </span>
            </div>
            <ToolTip
              text={
                <p className="t-m-0 ">
                  Current data will be lost with this action.
                  <br />
                  Please ensure you have recorded it.
                </p>
              }
              side="bottom"
            >
              <div>
                <ToolTipIcon />
              </div>
            </ToolTip>
          </div>
        </div>
      )}
    </div>
  );
};

const CalculateTotalAmount = ({ taxRate }: { taxRate?: number }) => {
  const [itemAmount, setItemAmount] = useState(0);

  const taxDetails = calculateTaxOnItem({ taxRate, itemAmount });

  return (
    <div className="t-bg-surface t-px-3 t-mt-4 t-rounded-sm t-py-2">
      <Accordion.Root
        className="AccordionRoot"
        type="single"
        defaultValue=""
        collapsible
      >
        <Accordion.Item className="AccordionItem" value="item-1">
          <Accordion.Trigger className="!t-bg-surface t-group t-text-left t-border-none t-w-full">
            <div className="t-flex t-cursor-pointer t-border-0 t-border-b group-data-state-closed:!t-border-none t-border-solid t-border-neutral-0 t-pb-3">
              <div>
                <div
                  className={`t-transition-all t-origin-center -t-rotate-90 group-data-state-open:-t-rotate-0 duration-200 ease-in-out`}
                >
                  <ArrowDown />
                </div>
              </div>
              <div className="t-ml-2 ">
                <p className="t-text-text-60 t-text-subtitle-sm t-m-0">
                  Calculate total amount
                </p>
                <span className="t-text-text-30 t-text-body-sm t-m-0">
                  See your sales tax rate applied to any item price
                </span>
              </div>
            </div>
          </Accordion.Trigger>
          <Accordion.Content>
            <motion.div
              layout
              initial={{ height: 0 }}
              animate={{ height: "auto" }}
              transition={{ ease: "easeInOut", duration: 0.2 }}
              exit={{ height: 0 }}
              className="t-overflow-hidden"
            >
              <div>
                <EstimatedTaxDetailsItem label={"Enter item price"} underline>
                  <Formik initialValues={{ rate: "" }} onSubmit={() => {}}>
                    {() => {
                      return (
                        <div className="t-w-32">
                          <PriceInput
                            rightAlign
                            onValueChange={(value) => {
                              setItemAmount(value.floatValue || 0);
                            }}
                            customSize="small"
                            name={`rate`}
                            label=""
                          />
                        </div>
                      );
                    }}
                  </Formik>
                </EstimatedTaxDetailsItem>
                <EstimatedTaxDetailsItem
                  label={"Total tax amount"}
                  value={
                    <AmountSuperScript
                      amount={Number(taxDetails?.taxAmount || 0)}
                    />
                  }
                  underline
                />

                <div className="t-flex t-justify-between t-items-center t-py-3">
                  <span className="t-text-text-100 t-text-subtitle-sm t-m-0">
                    Total amount{" "}
                    <span className="t-text-text-30 t-text-body-sm">{`(including tax)`}</span>
                  </span>

                  <span className="t-text-text-100 t-text-subtext t-m-0 t-max-w-32 t-overflow-x-clip t-text-ellipsis">
                    <AmountSuperScript
                      amount={Number(taxDetails?.totalAmountAfterTax || 0)}
                    />
                  </span>
                </div>
              </div>
            </motion.div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  );
};
