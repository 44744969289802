import React from "react";

export const RowsPlusBottom = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M13 7H3C2.73478 7 2.48043 7.10536 2.29289 7.29289C2.10536 7.48043 2 7.73478 2 8V9.5C2 9.76522 2.10536 10.0196 2.29289 10.2071C2.48043 10.3946 2.73478 10.5 3 10.5H13C13.2652 10.5 13.5196 10.3946 13.7071 10.2071C13.8946 10.0196 14 9.76522 14 9.5V8C14 7.73478 13.8946 7.48043 13.7071 7.29289C13.5196 7.10536 13.2652 7 13 7ZM13 9.5H3V8H13V9.5ZM13 2.5H3C2.73478 2.5 2.48043 2.60536 2.29289 2.79289C2.10536 2.98043 2 3.23478 2 3.5V5C2 5.26522 2.10536 5.51957 2.29289 5.70711C2.48043 5.89464 2.73478 6 3 6H13C13.2652 6 13.5196 5.89464 13.7071 5.70711C13.8946 5.51957 14 5.26522 14 5V3.5C14 3.23478 13.8946 2.98043 13.7071 2.79289C13.5196 2.60536 13.2652 2.5 13 2.5ZM13 5H3V3.5H13V5ZM10 13.5C10 13.6326 9.94732 13.7598 9.85355 13.8536C9.75979 13.9473 9.63261 14 9.5 14H8.5V15C8.5 15.1326 8.44732 15.2598 8.35355 15.3536C8.25979 15.4473 8.13261 15.5 8 15.5C7.86739 15.5 7.74021 15.4473 7.64645 15.3536C7.55268 15.2598 7.5 15.1326 7.5 15V14H6.5C6.36739 14 6.24021 13.9473 6.14645 13.8536C6.05268 13.7598 6 13.6326 6 13.5C6 13.3674 6.05268 13.2402 6.14645 13.1464C6.24021 13.0527 6.36739 13 6.5 13H7.5V12C7.5 11.8674 7.55268 11.7402 7.64645 11.6464C7.74021 11.5527 7.86739 11.5 8 11.5C8.13261 11.5 8.25979 11.5527 8.35355 11.6464C8.44732 11.7402 8.5 11.8674 8.5 12V13H9.5C9.63261 13 9.75979 13.0527 9.85355 13.1464C9.94732 13.2402 10 13.3674 10 13.5Z"
        fill="currentColor"
      />
    </svg>
  );
};
