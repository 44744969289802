import cx from "classnames";
import { ReactNode, useState } from "react";
import { Link } from "react-router-dom";

import { ContactCalendar } from "components/calendar/BottomBar";
import { Calendar } from "components/calendar/Calendar";
import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import Logo from "components/inkleLogo";
import { AnimatePresence, motion } from "framer-motion";
import BooksSvg from "static/images/Books.svg";
import CalendarCheck from "static/images/CalendarCheck.svg";
import ChatText from "static/images/ChatText.svg";
import CheckSquareOffset from "static/images/CheckSquareOffset.svg";
import ComplianceIcon from "static/images/Compliance.svg";
import EntityIcon from "static/images/Entity.svg";
import Folder from "static/images/Folder.svg";
import House from "static/images/House.svg";
import IntercoIcon from "static/images/interco.svg";
import Note from "static/images/Note.svg";
import RegisteredAgentIcon from "static/images/RegisteredAgent.svg";
import Resources from "static/images/Resources.svg";
import Reward from "static/images/Reward.svg";
import SidebarMenuIcon from "static/images/SidebarMenu.svg";
import TaxIcon from "static/images/TaxIcon.svg";
import Cross from "static/images/X.svg";
import { CalendarYearSelector } from "../Calender/CalendarYearSelector";

let sideBarData: {
  image: string;
  title: string;
  id: number;
  selected?: boolean;
}[] = [
  {
    image: House,
    title: "Home",
    id: 1,
  },
  {
    image: ChatText,
    title: "Chat",
    id: 2,
  },
  {
    image: CheckSquareOffset,
    title: "Filings",
    id: 3,
  },
  {
    image: CalendarCheck,
    title: "Deadline Alerts",
    id: 4,
    selected: true,
  },
  {
    image: Folder,
    title: "Documents",
    id: 5,
  },
  {
    image: Note,
    title: "Billing",
    id: 6,
  },
  {
    image: EntityIcon,
    title: "My Entities",
    id: 7,
  },
  {
    image: IntercoIcon,
    title: "Transfer Pricing",
    id: 8,
  },
  {
    image: TaxIcon,
    title: "Tax",
    id: 9,
  },
  {
    image: ComplianceIcon,
    title: "Compliance",
    id: 12,
  },
  {
    image: BooksSvg,
    title: "Books",
    id: 14,
  },
  {
    image: RegisteredAgentIcon,
    title: "Mailroom & Reg Agent",
    id: 17,
  },
  {
    image: Reward,
    title: "Rewards & Perks",
    id: 18,
  },
  {
    image: Resources,
    title: "Resources",
    id: 19,
  },
];

const Drawer = ({
  children,
  isOpen,
  onClose,
}: {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <AnimatePresence>
      <motion.div
        className="t-absolute t-bottom-0 t-top-0 t-z-10 t-w-full"
        layout
        style={{
          left: isOpen ? 0 : "-100%",
        }}
      >
        <div
          className="t-absolute t-inset-0 t-bg-neutral-100 t-opacity-50"
          onClick={onClose}
        />
        <div className="t-relative t-h-full t-w-4/5 t-bg-white">
          <div className="t-mb-4 t-ml-auto t-flex t-justify-between t-px-2 t-pb-2 t-pl-7 t-pr-5 t-pt-5">
            <ConditionalLink to="/" target="_blank">
              <Logo isExternal className="t-m-0 t-h-6 t-w-16" />
            </ConditionalLink>
            <img src={Cross} alt="Close navbar" onClick={onClose} />
          </div>
          <div className="t-relative t-h-full t-px-4">{children}</div>
          <div className="t-absolute t-bottom-2/4 t-left-4 t-flex t-w-4/5 t-transform t-flex-col t-justify-center t-gap-3 t-rounded-lg t-bg-white t-p-4 t-shadow-[0px_0px_12px_rgba(152,152,152,0.12)]">
            <span className="t-text-center t-text-body t-font-bold">
              Sign up to access
            </span>
            <ConditionalLink target="_blank" to="/signup">
              <Button size="small" customType="primary" block>
                Sign up
              </Button>
            </ConditionalLink>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
};

const MenuItem = ({
  children,
  selected,
}: {
  children: ReactNode;
  selected: boolean;
}) => {
  return (
    <button
      className={cx("all:unset t-flex t-gap-4 t-rounded t-px-3 t-py-2 ", {
        "t-relative t-z-10 t-w-4/5 t-border t-border-solid t-border-neutral-10 t-bg-white t-shadow-[0px_0.852713px_10.2326px_rgba(187,171,252,0.12)]":
          selected,
      })}
    >
      {children}
    </button>
  );
};

export const PublicCalendar = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={cx("t-h-screen", {
        "t-overflow-auto md:t-overflow-hidden": !isOpen,
        "t-overflow-hidden": isOpen,
      })}
    >
      <div className="t-border-0 t-border-b t-border-solid t-border-neutral-10">
        <div className="t-flex t-items-center t-gap-3 t-py-[7px] t-pl-5 t-pr-4 md:t-pl-7">
          <img
            className="md:t-hidden"
            src={SidebarMenuIcon}
            alt="Open navbar"
            onClick={() => setIsOpen(true)}
          />
          <ConditionalLink to="/" target="_blank">
            <Logo className="t-m-0 t-h-6 t-w-16" />
          </ConditionalLink>

          <div className="vr" />

          <h6 className="t-m-0 t-text-body md:t-text-title-h2-bold">
            Tax Deadlines
          </h6>

          <div className="t-ml-auto t-hidden md:t-block">
            <ContactCalendar />
          </div>

          <Link
            to="/signup"
            target="_blank"
            className="t-ml-auto t-no-underline md:t-ml-0"
          >
            <Button customType="primary" size="small">
              Sign up
            </Button>
          </Link>

          <Drawer isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <div className="t-flex-colt-flex t-gap-2">
              <div className="t-absolute t-inset-0 t-z-0 t-bg-white t-opacity-70" />

              {sideBarData.map(({ title, image, selected, id }) => (
                <MenuItem selected={Boolean(selected)} key={id}>
                  <img src={image} alt="Home" className="w-h-6 t-w-6" />
                  <p className="m-0 t-font-medium">{title}</p>
                </MenuItem>
              ))}
            </div>
          </Drawer>
        </div>
      </div>
      <div className="t-relative t-flex">
        <div className="t-group t-relative t-hidden t-flex-col t-gap-1 t-border-0 t-border-r t-border-solid t-border-neutral-10 t-bg-neutral-0 t-p-4 t-pr-0 md:t-flex md:t-w-1/5">
          <div className="t-absolute t-inset-0 t-z-0 t-bg-white t-opacity-80" />

          {sideBarData.map(({ title, image, selected, id }) => (
            <MenuItem selected={Boolean(selected)} key={id}>
              <img src={image} alt="Home" className="w-h-6 t-w-6" />
              <p className="m-0 t-pt-1 t-text-body t-font-medium">{title}</p>
            </MenuItem>
          ))}

          <div className="t-invisible t-absolute t-bottom-1/2 t-left-1/2 t-flex t-w-10/12 -t-translate-x-1/2 t-transform t-flex-col t-justify-center t-gap-3 t-rounded-lg t-bg-white t-p-4 t-opacity-0 t-shadow-[0px_0px_12px_rgba(152,152,152,0.12)] t-transition-opacity group-hover:t-visible group-hover:t-opacity-100">
            <span className="t-text-center t-text-body t-font-bold">
              Sign up to access
            </span>
            <ConditionalLink to="/signup" target="_blank">
              <Button block size="small" customType="primary">
                Sign up
              </Button>
            </ConditionalLink>
          </div>
        </div>
        <div className="t-h-[calc(100vh-50px)] t-w-full">
          <Calendar
            header={
              <Header
                title="Deadline Alerts"
                right={<CalendarYearSelector />}
              />
            }
            publicCalendar
            extraContentHeight="70px"
          />
        </div>
      </div>
    </div>
  );
};
