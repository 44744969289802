import { NotificationNudge } from "NotificationNudge";
import { ChatPreviewModal } from "components/ChatPreviewModal/ChatPreviewModal";
import { OnboardingWidget } from "components/OnboardingWidget/OnboardingWidget";
import { RequestDocumentModal } from "components/RequestDocumentModal/RequestDocumentModal";
import { SlackConnectedModal } from "components/Slack/SlackConnectedModal";
import { AddRequestInfo } from "components/Transaction/AddRequestInfo";
import CardAdd from "components/billing/CardAdd";
import { MeetingWithTranq } from "components/chat/MeetingWithTranq";
import { BalancingPaymentReviewAndPay } from "components/chat/ReviewAndBalancePayment/BalancingPaymentReviewAndPay";
import FreeSubscriptionModal from "components/tasks/task7004/FreeSubscriptionModal";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useHistory, useRouteMatch } from "react-router-dom";
import { closeOnboardingWidget } from "store/slices/onboardingWidget";
import {
  closeCardAddModal,
  closeFreeSubscription,
} from "store/slices/subscriptionModal";
import { DocumentReview } from "components/chat/DocumentReview/DocumentReview";
import { TransactionHiddenByFilterConfirmation } from "components/TransactionHiddenByFilterConfirmation/TransactionHiddenByFilterConfirmation";
import { PlanBaseSubscriptionModal } from "components/SubscriptionModal/PlanBaseSubscriptionModal";
import { useShowNotificationsNudgeQuery } from "store/apis/notifiaction";
import { useEffect } from "react";
import { setNotificationNudgeModal } from "store/slices/notification";
import { AddonsModal } from "components/Addons/AddonsModal";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useSelector } from "react-redux";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { RootState } from "store/store";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { setCloseCheckoutModal } from "store/slices/addons";
import { BOOKKEEPING_V2, MAILROOM, REGISTERED_AGENT } from "constants/addons";
import { stateRegistrationsApis } from "store/apis/StateRegistrations";
import { TransferOrderDetails } from "components/billing/BankTransfer/TransferOrderDetails";
import { ACCOUNTING_METHOD_TYPES } from "constants/bookkeeping";

export const GlobalModals = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { isCustomer } = useRoleBasedView();
  const { url } = useRouteMatch();
  const { show: showBankOrderDetails } = useAppSelector(
    (state) => state.bankTransfer
  );

  const { isOpenFreeSubscription, isOpenCardAddModal } = useAppSelector(
    (state) => state.subscriptionModal
  );
  const { isOpen: isOnboardingWidgetOpen, product } = useAppSelector(
    (state) => state.onboardingWidget
  );

  const { showDocumentReviewModal } = useAppSelector(
    (state) => state.reviewAndBalancePayment
  );

  const { showCheckoutModal } = useSelector(
    (state: RootState) => state.addonsSlice
  );
  const { addonPayload, addonData } = useSelector(
    (state: RootState) => state.addonsSlice
  );

  const { show } = useSelector((state: RootState) => state.bankTransfer);

  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const { data: allSubscriptions = [], refetch } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const closeCheckoutModal = () => {
    dispatch(
      stateRegistrationsApis.util.invalidateTags([
        "STATE_REGISTRATIONS",
        "PLATFORM_SUBSCRIPTION_PRODUCT",
      ])
    );

    dispatch(setCloseCheckoutModal());
    refetch();
  };

  let selectedSubscription = allSubscriptions?.find(
    ({ uuid }) => uuid === addonPayload?.uuid
  );

  if (selectedSubscription?.subscription_type === MAILROOM) {
    const mailroomSubscriptionData = allSubscriptions?.find(
      (subscription) => subscription.subscription_type === MAILROOM
    );

    const { amount, stripe_coupon } = mailroomSubscriptionData || {};
    const updatedAmount = stripe_coupon?.discounted_amount.toString() || amount;

    selectedSubscription = {
      ...selectedSubscription,
      amount: Number(updatedAmount),
    };
  }

  if (selectedSubscription?.subscription_type === REGISTERED_AGENT) {
    const registeredAgentSubscriptionData = allSubscriptions?.find(
      (subscription) => subscription.subscription_type === REGISTERED_AGENT
    );

    const { amount, stripe_coupon } = registeredAgentSubscriptionData || {};
    const updatedAmount =
      stripe_coupon?.discounted_amount.toString() ||
      addonPayload?.amount ||
      amount;

    selectedSubscription = {
      ...selectedSubscription,
      subscription_name: `${addonData?.subscription_name!} - ${
        addonPayload?.selectedState
      } `,
      amount: Number(updatedAmount),
      metadata: {
        ra_state: addonData?.selectedState || addonPayload?.selectedState,
      },
    };
  }

  if (addonPayload?.tierId && selectedSubscription) {
    const tier = selectedSubscription.tier_information?.find(
      (tier) => tier.uuid === addonPayload?.tierId
    );

    selectedSubscription = {
      ...selectedSubscription,
      amount: Number(tier?.amount) || selectedSubscription.amount,
      // @ts-ignore
      selectedTierAmount: Number(tier?.amount) || selectedSubscription.amount,
    };

    if (selectedSubscription?.stripe_coupon) {
      selectedSubscription = {
        ...selectedSubscription,
        stripe_coupon: {
          ...selectedSubscription.stripe_coupon,
          discounted_amount:
            tier?.discounted_amount ||
            selectedSubscription.stripe_coupon?.discounted_amount ||
            selectedSubscription.amount,
        },
      };
    }
  }

  const { data: notificationsNudge, isSuccess } =
    useShowNotificationsNudgeQuery();

  const closeFreeSubscriptionModal = () => {
    dispatch(closeFreeSubscription());
    history.push(url);
  };

  useEffect(() => {
    if (isSuccess) {
      dispatch(setNotificationNudgeModal(notificationsNudge));
    }
  }, [isSuccess]);

  return (
    <>
      <FreeSubscriptionModal
        openSubscription={isOpenFreeSubscription}
        closeSubscription={closeFreeSubscriptionModal}
      />
      <NotificationNudge />
      {isOpenCardAddModal && (
        <CardAdd
          openAddCard={isOpenCardAddModal}
          closeAddCard={() => dispatch(closeCardAddModal())}
        />
      )}

      <PlanBaseSubscriptionModal />

      <OnboardingWidget
        open={isOnboardingWidgetOpen}
        onClose={() => dispatch(closeOnboardingWidget())}
        currentTab={product}
      />
      {showBankOrderDetails && <TransferOrderDetails />}
      {showDocumentReviewModal && <DocumentReview />}
      <AddRequestInfo />
      <RequestDocumentModal />
      <ChatPreviewModal />
      <MeetingWithTranq />
      <BalancingPaymentReviewAndPay />
      <SlackConnectedModal />
      <TransactionHiddenByFilterConfirmation />
      <AddonsModal />
      <CheckoutModal
        entityId={entityId}
        type="subscription"
        title="Subscription"
        tierId={addonPayload?.tierId}
        onClose={closeCheckoutModal}
        open={showCheckoutModal}
        scheduleDate={addonPayload?.scheduleDate}
        accountingMethod={
          addonPayload?.accountingMethod as ACCOUNTING_METHOD_TYPES
        }
        subscription={selectedSubscription!}
        onSubscribed={closeCheckoutModal}
        customPrice={
          addonPayload?.customPrice
            ? Number(addonPayload?.customPrice)
            : undefined
        }
      />
    </>
  );
};
