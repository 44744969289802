import { ActionItemRow } from "components/HomeActionItems/ActionItemRow";
import { CartActionItem } from "components/HomeActionItems/CartActionItem";
import { FilingList } from "components/HomeActionItems/FilingList";
import { PendingInvoicesRow } from "components/HomeActionItems/PendingInvoicesRow";
import { UnreadChatRow } from "components/HomeActionItems/UnreadChatRow";
import { useAuth } from "hooks/useAuth";
import React from "react";
import { TaxActionItemType } from "store/apis/actionItems";

export const TaxTodos = ({
  taxActionItems,
}: {
  taxActionItems: TaxActionItemType[];
}) => {
  const userProfile = useAuth();

  return (
    <div>
      {taxActionItems.map((item, i) => {
        if (item.custom_type === "FILING_IN_ADD_DETAILS_CUSTOM_TYPE") {
          return <FilingList key={i} item={item} />;
        }
        if (item.custom_type === "CART") {
          return <CartActionItem key={i} item={item} />;
        }
        if (item.custom_type === "INVOICES_DUE") {
          return <PendingInvoicesRow key={i} item={item} />;
        }
        return <ActionItemRow item={item} key={i} />;
      })}
      {userProfile.email === "demo1@inkle.io" && <UnreadChatRow />}{" "}
      {/* //TODO REMOVE THIS CHECK AFTER TESTING  */}
    </div>
  );
};
