import { ActionRequiredPopOver } from "components/ActionRequiredPopover/ActionRequiredPopover";
import { Header } from "components/DesignSystem/Header/Header";
import { useActionItems } from "hooks/useActionItems";
import { COATable } from "../../../components/ChartOfAccounts/COATable";
import { JournalEntry } from "components/JournalEntry/JournalEntry";
import DashboardContainer from "components/dashboard/DashboardContainer";

export const ChartOfAccounts = () => {
  const { isReconciliationRequired } = useActionItems();

  return (
    <DashboardContainer className="t-h-full">
      <DashboardContainer.Header className="t-px-10 t-z-header">
        <Header
          v2
          title={
            <p>
              Chart of Accounts{" "}
              {isReconciliationRequired && (
                <ActionRequiredPopOver
                  type="RECONCILIATION"
                  btnText="Reconcile"
                />
              )}
            </p>
          }
          right={<div className="t-flex t-gap-2"></div>}
        />
      </DashboardContainer.Header>

      <DashboardContainer.Content>
        <div className="t-h-full t-w-full">
          <COATable />
        </div>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
