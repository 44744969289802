import cx from "classnames";
import { Button as NewButton } from "components/DesignSystem/Button/Button";
import { Form as FormikForm, Formik, useFormikContext } from "formik";
import { useContext, useState, useRef, useEffect, useMemo } from "react";
import { Accordion, Card, Form } from "react-bootstrap";

//components
import EditForm from "./editForm";
import { FormControl, FormTooltip } from "./formControl";

//api
import { stateUpdate } from "../../apis/stateUpdate";
import { putForm } from "../../apis/taskForm";

//context
import classNames from "classnames";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { createAutofill } from "../../apis/autofill";
import authContext from "../../jwt_context&axios/authContext";
import {
  getAutofillFields,
  getKeysAndValuesFromFields,
} from "../../utils/formEngineHelpers";
import TaskButtonBar from "./TaskButtonBar";
import { UpdateTaskPrice } from "./updateTaskPrice";
import { FillFormStepper } from "./fillFormStepper/FillFormStepper";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { openTaskReview, setTaskUpdatedSection } from "store/slices/TaskReview";
import { FilingReviewModal } from "./FilingReviewModal";
import { useParams } from "react-router-dom";
import { setShowPricingModal } from "store/slices/task";
import { ADDRESS_AUTOFILL_VERSION_2_0 } from "constants/task";
import FilingInprogressIllustration from "static/images/FilingInprogressIllustration.svg";
import { Trash } from "components/icons/Trash";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { DD_MMM_YYYY } from "constants/date";
import { STATE_FRANCHISE_TAX_DELAWARE } from "types/Models/services";
import { TaskNoteIcon } from "components/icons/TaskNoteIcon";
import { getValidationSchema } from "formValidations/filingFormSchema";
import { useToast } from "hooks/useToast";
import { useWindowSize } from "hooks/useWindowSize";

dayjs.extend(customParseFormat);

const Fields = ({
  fields,
  hide,
  hidden,
  current,
  data,
  uuid,
  repeatableId,
  groupId,
  updatedTask,
  formId,
  sectionKey,
  isArchived,
  formData,
  section,
  entityId,
  season,
  deadline,
}) => {
  const { authtoken } = useContext(authContext);
  const { isAdmin } = useRoleBasedView();
  let cpa_user = authtoken.is_service_user;
  const { setFieldValue, values } = useFormikContext();
  const [radioValues, setRadioValues] = useState(() => {
    return (
      fields &&
      fields.reduce((acc, field) => {
        if (field.type === "radio") {
          acc[field.field_key] = field.value;
        }
        return acc;
      }, {})
    );
  });

  const onChange = (collapsable) => (event) => {
    if (collapsable) {
      hide?.(event.target.checked);
    }
  };

  const onRadioChange = (fieldKey, value) => {
    setFieldValue(fieldKey, value);
    setRadioValues((prev) => ({ ...prev, [fieldKey]: value }));
  };

  if (!fields) {
    return null;
  }

  return fields?.map((field, index) => {
    const nextColumnField = fields[index + 1];
    const showDescriptionBefore =
      field?.show_description_before ||
      (nextColumnField?.show_description_before && field.is_first_column); // is_first_column is to check if it comes in multi column input otherwise it will come twice (first: for next column and then current column )
    const showDescriptionAfter =
      field?.show_description_after ||
      (nextColumnField?.show_description_after && field.is_first_column);

    let hideField = field.radio
      ? !Object.keys(field.radio).some(
          (key) => radioValues[key] === field.radio[key]
        )
      : false;

    if (field.is_second_column || hideField) {
      return <></>;
    }

    return (
      (!hidden || field.collapse_section) && (
        <>
          {showDescriptionBefore && (
            <span
              dangerouslySetInnerHTML={{
                __html: showDescriptionBefore,
              }}
            />
          )}
          <Form.Group
            className={cx({
              "opacity-50": field.is_hidden_for_customer,
              "p-2":
                (!field.is_hidden_for_customer || isAdmin) &&
                !field.should_hide_section_field,
              "t-flex t-gap-4": field.is_first_column,
            })}
            key={field.field_key}
          >
            <div
              className={classNames("t-w-full", {
                "t-hidden": field.should_hide_section_field,
              })}
            >
              <FormControl
                authtoken={authtoken}
                sectionKey={sectionKey}
                formId={formId}
                updatedTask={updatedTask}
                disabled={
                  (!cpa_user && field.field_key === "grose_assets") ||
                  field.disabled
                }
                type={field.type}
                id={field.field_key}
                name={field.field_key}
                label={field.field_label}
                placeholder={field.placeholder}
                options={field.options}
                tooltip={field.tooltip}
                defaultValue={field.value}
                onChange={onChange(field.collapse_section)}
                descript={field?.description}
                mandatory={field?.required}
                current={current}
                data={data}
                uuid={uuid}
                groupId={groupId}
                autofillKey={field.autofill_key}
                autofillPrefix={field.autofill_prefix}
                autofillSuffix={repeatableId}
                tag={field.tag}
                document={field.document}
                field={field}
                isArchived={isArchived}
                formData={formData}
                section={section}
                entityId={entityId}
                fields={fields}
                onRadioChange={(value) => onRadioChange(field.field_key, value)}
                season={season}
                deadline={deadline}
              />
            </div>

            {field.is_first_column && nextColumnField?.is_second_column && (
              <div className="t-w-full">
                <FormControl
                  authtoken={authtoken}
                  sectionKey={sectionKey}
                  formId={formId}
                  updatedTask={updatedTask}
                  disabled={
                    (!cpa_user &&
                      nextColumnField.field_key === "grose_assets") ||
                    nextColumnField.disabled
                  }
                  type={nextColumnField.type}
                  id={nextColumnField.field_key}
                  name={nextColumnField.field_key}
                  label={nextColumnField.field_label}
                  placeholder={nextColumnField.placeholder}
                  options={nextColumnField.options}
                  tooltip={nextColumnField.tooltip}
                  defaultValue={nextColumnField.value}
                  onChange={onChange(nextColumnField.collapse_section)}
                  descript={nextColumnField?.description}
                  mandatory={nextColumnField?.required}
                  current={current}
                  data={data}
                  uuid={uuid}
                  groupId={groupId}
                  autofillKey={nextColumnField.autofill_key}
                  autofillPrefix={nextColumnField.autofill_prefix}
                  autofillSuffix={repeatableId}
                  tag={nextColumnField.tag}
                  document={nextColumnField.document}
                  field={nextColumnField}
                  isArchived={isArchived}
                  formData={formData}
                  section={section}
                  entityId={entityId}
                  fields={fields}
                  onRadioChange={(value) =>
                    onRadioChange(field.field_key, value)
                  }
                  deadline={deadline}
                  season={season}
                />
              </div>
            )}
          </Form.Group>
          {showDescriptionAfter && (
            <span
              dangerouslySetInnerHTML={{
                __html: showDescriptionAfter,
              }}
            />
          )}
        </>
      )
    );
  });
};

const RepeatableFields = ({
  repeatableFields,
  duplicationAllowed,
  title,
  hidden,
  updateSectionKeyIdPair,
  sectionKey,
  updateRemovedSectionIds,
  current,
  data,
  uuid,
  groupId,
  updatedTask,
  formId,
  isArchived,
  section,
  entityId,
  formData,
  season,
  deadline,
}) => {
  const [repeatables, setFields] = useState(repeatableFields);
  const addField = () => {
    const ids = repeatables.map(({ id }) => id);
    const maxId = Math.max(...ids);
    const nextId = maxId + 1;
    const templateFields = repeatables[0].fields.map((field) => {
      let keyOnly = field.field_key.split("_");
      keyOnly = keyOnly.slice(0, keyOnly.length - 1);
      keyOnly = keyOnly.join("_");
      return {
        ...field,
        value: "",
        field_key: `${keyOnly}_${nextId}`,
      };
    });
    setFields((r) => [
      ...r,
      { id: nextId, fields: templateFields, selected_autofill_details: {} },
    ]);
    updateSectionKeyIdPair({ key: sectionKey, id: nextId });
  };

  useEffect(() => {
    setFields(repeatableFields);
  }, [repeatableFields]);

  const updatedSection = { ...section, repeatable_fields: repeatables };

  if (hidden) {
    return null;
  }

  const onRemove = (removeId) => () => {
    setFields((r) => r.filter(({ id }) => id !== removeId));
    updateRemovedSectionIds({ key: sectionKey, id: removeId });
  };

  return (
    <>
      {repeatables?.map(({ fields, id }, index) => (
        <Card key={id} className="mb-4">
          <Card.Body>
            <div className="t-flex t-justify-between t-items-center t-px-2 t-mb-2">
              <div className="t-text-text-100 t-text-subtext">
                {section.section_title} {index + 1}
              </div>
              <NewButton
                customType="icon"
                size="small"
                onClick={onRemove(id)}
                disabled={repeatables.length === 1}
                type="button"
              >
                <Trash />
              </NewButton>
            </div>
            <Fields
              formId={formId}
              updatedTask={updatedTask}
              fields={fields}
              repeatableId={id.toString()}
              current={current}
              data={data}
              uuid={uuid}
              groupId={groupId}
              sectionKey={sectionKey}
              isArchived={isArchived}
              formData={formData}
              section={updatedSection}
              entityId={entityId}
              season={season}
              deadline={deadline}
            />
          </Card.Body>
        </Card>
      ))}
      {duplicationAllowed && (
        <NewButton
          type="button"
          customType="primary-outlined"
          size="small"
          onClick={addField}
        >
          Add {title}
        </NewButton>
      )}
    </>
  );
};

const AllFields = ({
  section,
  updateSectionKeyIdPair,
  updateRemovedSectionIds,
  current,
  data,
  uuid,
  groupId,
  updatedTask,
  formId,
  isArchived,
  entityId,
  formData,
  season,
  deadline,
}) => {
  const field = section?.section_fields?.[0];
  const [hidden, hide] = useState(
    field?.type === "checkbox" && field?.value === "true"
  );

  return (
    <>
      <p
        className="t-text-body-sm t-text-text-60 t-ml-2 t-mt-1"
        dangerouslySetInnerHTML={{ __html: section.section_description }}
      ></p>
      <Fields
        sectionKey={section.section_key}
        formId={formId}
        updatedTask={updatedTask}
        fields={section?.section_fields}
        removable={section?.is_removable}
        hide={hide}
        hidden={hidden}
        current={current}
        data={data}
        uuid={uuid}
        sectionId={section.uuid}
        groupId={groupId}
        isArchived={isArchived}
        formData={formData}
        section={section}
        entityId={entityId}
        season={season}
        deadline={deadline}
      />
      {section.should_hide_repeatable_fields ? (
        <></>
      ) : (
        <RepeatableFields
          formId={formId}
          updatedTask={updatedTask}
          updateSectionKeyIdPair={updateSectionKeyIdPair}
          duplicationAllowed={section.section_duplication_allowed}
          sectionKey={section.section_key}
          repeatableFields={section.repeatable_fields}
          title={section?.section_title}
          hidden={hidden}
          updateRemovedSectionIds={updateRemovedSectionIds}
          current={current}
          data={data}
          uuid={uuid}
          groupId={groupId}
          isArchived={isArchived}
          section={section}
          entityId={entityId}
          formData={formData}
          season={season}
          deadline={deadline}
        />
      )}
    </>
  );
};
export const FormEngine = ({
  next,
  prev,
  inkleDocId,
  description,
  mainId,
  form_data,
  current,
  data,
  uuid,
  groupId,
  task,
  updatedTask,
  isArchived,
  docId,
  isFiling,
  parentRef,
  entityId,
  isEditableForAdmin,
  isSectionGroupAvailable,
}) => {
  const { isCustomer, isAdmin } = useRoleBasedView();
  const { authtoken } = useContext(authContext);
  const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false);
  const [cBtn, setCBtn] = useState(0);
  const [sectionKeyIdPair, setSectionKeyIdPair] = useState({});
  const [removedSectionIds, setRemovedSectionIds] = useState({});
  const [apiLoading, setApiLoading] = useState(false);
  const [isVariablePricing, setIsVariablePricing] = useState(false);
  const formSections = form_data?.data?.data?.section_group?.[0]?.sections.map(
    (section) => ({
      sectionTitle: section?.section_title,
      sectionKey: section?.section_key,
      is_hidden_for_customer: section?.is_hidden_for_customer,
    })
  );
  const [activeSectionKey, setActiveSectionKey] = useState(
    formSections?.[0]?.section_key
  );
  const updatedFormSectionsForIntersection =
    form_data?.data?.data?.section_group?.[0]?.sections.map((section) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const { ref, inView } = useInView({ rootMargin: "0px 0px -600px 0px" });
      return {
        interSectionRef: ref,
        inView,
        section,
      };
    });
  const { showTaskReviewStep, showTaskReview } = useSelector(
    (state) => state.taskReview
  );
  const isPricingStep = useSelector((state) => state.taskSlice.isPricingStep);
  const dispatch = useDispatch();
  const [form, setForm] = useState();
  const [formValues, setFormValues] = useState();
  const { alertToast } = useToast();
  const { width } = useWindowSize();
  const isSmallScreen = width <= 640;

  const setActiveSection = (activeSection) => {
    const section = document.getElementById(activeSection);
    section?.scrollIntoView({ behavior: "smooth" });
    setActiveSectionKey(activeSection);
  };

  const updateSectionKeyIdPair = ({ key, id }) =>
    setSectionKeyIdPair((keyIdPairs) => ({ ...keyIdPairs, [key]: id }));

  const updateRemovedSectionIds = ({ key, id }) =>
    setRemovedSectionIds((keyIdPairs) => ({
      ...keyIdPairs,
      [key]: [...(keyIdPairs[key] || []), id],
    }));

  const { taskId } = useParams();

  const getUpdatedSections = (formValues, values) => {
    const formData = new FormData(formValues);
    let formNameValue = { ...values };
    for (var pair of formData.entries()) {
      formNameValue[pair[0]] = pair[1];
    }
    const updatedValue = (key, type) => {
      if (type === "multiselect") {
        return values[key];
      }

      if (type === "date") {
        return formNameValue[key]
          ? dayjs(formNameValue[key], DD_MMM_YYYY).format("YYYY-MM-DD")
          : "";
      }

      return formNameValue[key];
    };

    const updatedSections =
      form_data?.data?.data?.section_group?.[0].sections.map((section) => {
        const updatedSectionFields = section?.section_fields?.map((field) => {
          if (field.type === "checkbox") {
            const currentCheckboxValue = updatedValue(
              field.field_key,
              field.type
            );
            return {
              ...field,
              value:
                currentCheckboxValue === "" || currentCheckboxValue === "true"
                  ? "true"
                  : null,
            };
          }
          if (field.type === "file_upload") {
            return {
              ...field,
              documents: values[field.field_key],
              values: values[field.field_key],
            };
          }
          return {
            ...field,
            value: updatedValue(field.field_key, field.type),
          };
        });

        let updatedSection = {
          ...section,
          section_fields: updatedSectionFields,
        };

        if (section.repeatable_fields?.length > 0) {
          if (
            section.section_key === "directors" &&
            section.should_hide_repeatable_fields
          ) {
            return {
              ...updatedSection,
              repeatable_fields:
                form_data?.data?.data?.section_group?.[0].sections.find(
                  (section) => section.section_key === "directors"
                ).repeatable_fields,
            };
          }

          if (
            section.section_key === "shareholders" &&
            form_data?.data?.data.address_autofill_version ===
              ADDRESS_AUTOFILL_VERSION_2_0
          ) {
            return {
              ...section,
              repeatable_fields:
                form_data?.data?.data?.section_group?.[0].sections.find(
                  (section) => section.section_key === "shareholders"
                ).repeatable_fields,
            };
          }

          updatedSection.repeatable_fields = updatedSection?.repeatable_fields
            ?.filter(
              ({ id }) =>
                !removedSectionIds[updatedSection.section_key]?.includes(id)
            )
            .map((repeatable) => ({
              ...repeatable,
              id: repeatable.id,
              fields: repeatable.fields?.map((field) => ({
                ...field,
                value:
                  updatedValue(field?.field_key, field?.type) || field.value,
              })),
            }));

          const finalId = sectionKeyIdPair[section.section_key];
          const allId = updatedSection?.repeatable_fields?.map((f) => f.id);
          const existingMaxId = Math.max(...allId);

          if (finalId && finalId - existingMaxId > 0) {
            const noOfFieldsToCreate = finalId - existingMaxId;

            const separateFieldKey = (fieldWithId) => {
              let nameOnly = fieldWithId.split("_");
              nameOnly = nameOnly.slice(0, nameOnly.length - 1);
              return nameOnly.join("_");
            };

            for (let i = 1; i <= noOfFieldsToCreate; i++) {
              const fields = updatedSection.repeatable_fields[0].fields.map(
                (field) => {
                  let keyOnly = separateFieldKey(field.field_key);
                  let autofillSection = field.autofill_section
                    ? separateFieldKey(field.autofill_section)
                    : null;
                  const fieldKey = `${keyOnly}_${existingMaxId + i}`;
                  const returnObj = {
                    ...field,
                    value: updatedValue(fieldKey, field?.type),
                    field_key: fieldKey,
                  };

                  if (autofillSection) {
                    returnObj.autofill_section = `${autofillSection}_${
                      existingMaxId + i
                    }`;
                  }

                  return returnObj;
                }
              );

              updatedSection.repeatable_fields.push({
                id: existingMaxId + i,
                fields,
              });
            }
          }
        }
        return updatedSection;
      });

    return updatedSections;
  };

  const openTaskReviewModal = (e, values) => {
    e?.preventDefault();
    const form = e.target;
    setForm(form);
    setFormValues(values);
    const updatedSection = getUpdatedSections(form, values);
    console.log({ updatedSection });
    if (cBtn === 2) {
      handleFormSubmit(form, values);
    } else {
      dispatch(setTaskUpdatedSection(updatedSection));
      dispatch(openTaskReview());
    }
  };

  const handleSubmit = (e, values) => {
    e?.preventDefault();
    handleFormSubmit(e.target, values);
  };

  const handleFormSubmit = async (formValues, values) => {
    const updatedSections = getUpdatedSections(formValues, values);

    if (
      form_data.data.data.address_autofill_version !==
      ADDRESS_AUTOFILL_VERSION_2_0
    ) {
      const [keyAndSectionMap, sectionToAutofillFieldsMap] =
        getAutofillFields(updatedSections);

      Object.entries(sectionToAutofillFieldsMap).map(
        async ([section, fields]) => {
          await createAutofill({
            entityId,
            groupId,
            fields: getKeysAndValuesFromFields(fields),
            autofillKey: keyAndSectionMap[section],
          });
        }
      );
    }

    const params = {
      form_data: {
        ...form_data,
        data: {
          ...form_data.data.data,
          section_group: [{ sections: updatedSections }],
        },
      },
    };

    putForm({
      formId: form_data.uuid,
      params,
    }).then((response) => {
      setApiLoading(false);
      let id;

      // cBtn = 1 -> save and stateChange to next
      // cBtn = 2 -> save and stay on same screen
      // cBtn = 3 -> save and stateChange to prev
      if (cBtn === 1) {
        id = next;
        if (!isCustomer && task.is_next_state_final) {
          setShowUpdatePriceModal(true);
          if (task.is_pricing_fixed) {
            setIsVariablePricing(false);
          } else {
            setIsVariablePricing(true);
          }
        } else {
          stateUpdate({
            taskId,
            id: task?.state?.is_step_replaceable
              ? task?.state?.new_state_id
              : id,
          }).then(() => {
            window.location.reload();
          });
        }
      } else if (cBtn === 2) {
        window.location.reload();
      } else if (cBtn === 3) {
        id = prev;
        stateUpdate({
          taskId,
          id,
        }).then(() => {
          window.location.reload();
        });
      }
    });
  };

  if (!form_data) {
    return null;
  }

  const formInitialValues =
    form_data?.data?.data?.section_group?.[0]?.sections?.reduce(
      (currentValue, section) => {
        const addFieldValue = (field) => {
          if (field.type === "checkbox") {
            currentValue[field.field_key] = Boolean(field.value);
          } else if (field.type === "file_upload") {
            currentValue[field.field_key] = field.documents;
          } else {
            currentValue[field.field_key] = field.value;
          }
        };

        section.section_fields?.forEach((field) => {
          addFieldValue(field);
        });

        section.repeatable_fields?.forEach(({ fields }) =>
          fields?.forEach((field) => {
            addFieldValue(field);
          })
        );

        return currentValue;
      },
      {}
    );

  const isDftTask = task.base_task_key === STATE_FRANCHISE_TAX_DELAWARE;
  const nextBtnText = isDftTask ? "Review & Submit" : "Next";

  return (
    <div className="t-h-full t-w-full t-pt-4">
      {!isArchived && (
        <EditForm current={current} data={data} uuid={uuid} isFromStep0 />
      )}
      <Formik
        initialValues={formInitialValues}
        className="t-h-full"
        enableReinitialize
        validationSchema={getValidationSchema(form_data)}
        validateOnChange={false}
        validateOnBlur={false}
        validateOnMount={false}
      >
        {({ values, validateForm, setTouched }) => {
          return (
            <FormikForm
              onSubmit={(e) => {
                e?.preventDefault();
                validateForm().then((validationErrors) => {
                  if (Object.keys(validationErrors).length === 0) {
                    showTaskReviewStep
                      ? openTaskReviewModal(e, values)
                      : handleSubmit(e, values);
                  } else {
                    let touch = {};
                    Object.keys(validationErrors)?.forEach((error) => {
                      touch[error] = true;
                    });
                    setTouched(touch);
                    alertToast({
                      title: "Validation Error",
                      message: "Please fill all the required fields",
                    });
                  }
                });
              }}
              className="taxing t-overflow-y-hidden t-w-full"
            >
              {isSectionGroupAvailable ? (
                <div
                  className={cx("t-h-full t-w-full", {
                    disabledForm:
                      (!mainId && !isEditableForAdmin) ||
                      isArchived ||
                      (isPricingStep && !isAdmin),
                  })}
                >
                  {isSmallScreen ? (
                    <Accordion
                      defaultActiveKey="0"
                      className="t-w-full t-block"
                    >
                      {form_data?.data?.data?.section_group?.[0]?.sections?.map(
                        (section, index) => {
                          return (
                            <Accordion.Item
                              eventKey={String(index)}
                              key={section?.section_title}
                            >
                              <Accordion.Header>
                                {section?.section_title}
                              </Accordion.Header>
                              <Accordion.Body
                                className={classNames({
                                  "t-pointer-events-none": isPricingStep,
                                })}
                              >
                                <AllFields
                                  updatedTask={updatedTask}
                                  formId={form_data.uuid}
                                  section={section}
                                  updateSectionKeyIdPair={
                                    updateSectionKeyIdPair
                                  }
                                  updateRemovedSectionIds={
                                    updateRemovedSectionIds
                                  }
                                  current={current}
                                  data={data}
                                  uuid={uuid}
                                  groupId={groupId}
                                  isArchived={isArchived}
                                  entityId={entityId}
                                  formData={form_data}
                                  season={task.season}
                                  deadline={task.deadline}
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                          );
                        }
                      )}
                    </Accordion>
                  ) : (
                    <div className="t-w-full t-h-full t-overflow-hidden t-flex t-mt-2 t-relative">
                      <div className="t-w-1/4 t-sticky t-mt-0.5">
                        <FillFormStepper>
                          {formSections
                            ?.filter(
                              ({ is_hidden_for_customer }) =>
                                !(is_hidden_for_customer && isCustomer)
                            )
                            ?.map(({ sectionKey, sectionTitle }, index) => (
                              <FillFormStepper.Step
                                key={sectionKey}
                                isActive={activeSectionKey === sectionKey}
                                notFirstStep={index !== 0}
                                sectionKey={sectionKey}
                                setActiveSectionKey={setActiveSection}
                              >
                                {sectionTitle}
                              </FillFormStepper.Step>
                            ))}
                        </FillFormStepper>
                      </div>
                      <div className="t-w-3/4 t-overflow-y-scroll t-h-full t-px-2 show-scrollbar">
                        <div className="t-w-full sm:t-max-h-[500px] t-sticky">
                          {form_data?.data?.data?.task_note && (
                            <div className="t-border t-border-b-0 t-border-r-0 t-border-t-0 t-border-solid t-border-l-neutral-10 t-pb-6 t-pl-6 t-text-body-sm">
                              <div className="t-text-text-30 t-p-3 t-rounded-lg t-border t-border-solid t-border-blue-10 t-bg-blue-0 t-flex t-gap-2">
                                <span className="t-text-blue-70">
                                  <TaskNoteIcon />
                                </span>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: form_data.data.data.task_note,
                                  }}
                                />
                              </div>
                            </div>
                          )}
                          {updatedFormSectionsForIntersection
                            ?.filter(
                              ({ section }) =>
                                !(section.is_hidden_for_customer && isCustomer)
                            )
                            ?.map(
                              ({ interSectionRef, inView, section }, index) => {
                                const isActiveSection =
                                  section.section_key === activeSectionKey;

                                return (
                                  <div
                                    ref={(ref) => {
                                      interSectionRef(ref);
                                      if (inView) {
                                        setActiveSectionKey(
                                          section.section_key
                                        );
                                      }
                                    }}
                                    id={section.section_key}
                                    eventKey={String(index)}
                                    key={section?.section_title}
                                    className={classNames(
                                      "t-pb-36 t-border t-border-b-0 t-border-r-0 t-border-t-0 t-border-solid t-border-l-neutral-10",
                                      {
                                        "t-pointer-events-none":
                                          isPricingStep && !isAdmin,
                                      }
                                    )}
                                  >
                                    <div className="t-flex t-items-start t-gap-3">
                                      <div
                                        className={classNames(
                                          "t-h-4 t-w-4 t-rounded-full -t-ml-2 t-mr-2",
                                          {
                                            "t-bg-purple-50": isActiveSection,
                                            "t-bg-purple-20": !isActiveSection,
                                          }
                                        )}
                                      ></div>
                                      <div className="t-text-text-100 t-text-subtext t-leading-4">
                                        {section?.section_title}{" "}
                                        {section?.tooltip && (
                                          <FormTooltip
                                            tooltipText={
                                              <div
                                                className="t-text-left"
                                                dangerouslySetInnerHTML={{
                                                  __html: section.tooltip,
                                                }}
                                              ></div>
                                            }
                                          />
                                        )}
                                      </div>
                                    </div>
                                    <div className="t-ml-5">
                                      <AllFields
                                        updatedTask={updatedTask}
                                        formId={form_data.uuid}
                                        section={section}
                                        updateSectionKeyIdPair={
                                          updateSectionKeyIdPair
                                        }
                                        updateRemovedSectionIds={
                                          updateRemovedSectionIds
                                        }
                                        current={current}
                                        data={data}
                                        uuid={uuid}
                                        groupId={groupId}
                                        isArchived={isArchived}
                                        entityId={entityId}
                                        formData={form_data}
                                        deadline={task.deadline}
                                        season={task.season}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="t-h-full t-w-full t-flex t-justify-center t-items-center">
                  <img
                    src={FilingInprogressIllustration}
                    alt="FilingInprogressIllustration"
                  />
                </div>
              )}

              {!isArchived && mainId && !apiLoading && !isPricingStep && (
                <TaskButtonBar justifyContent="end" parentRef={parentRef}>
                  <div
                    className={classNames("t-flex t-gap-4 t-w-full ", {
                      "t-justify-between": prev && authtoken.is_service_user,
                      "t-justify-end": !(prev && authtoken.is_service_user),
                    })}
                  >
                    {prev && authtoken.is_service_user && (
                      <NewButton
                        type="submit"
                        onClick={() => setCBtn(3)}
                        customType="primary-outlined"
                      >
                        Prev
                      </NewButton>
                    )}
                    <div className="t-flex t-gap-2">
                      <NewButton
                        type="submit"
                        onClick={() => setCBtn(2)}
                        customType="primary-outlined"
                      >
                        Save
                      </NewButton>
                      <NewButton
                        type="submit"
                        onClick={() => setCBtn(1)}
                        customType="primary"
                      >
                        {nextBtnText}
                      </NewButton>
                    </div>
                  </div>
                </TaskButtonBar>
              )}
              {isPricingStep && (
                <TaskButtonBar
                  justifyContent="end"
                  parentRef={parentRef}
                  addMarginLeft={false}
                >
                  <div
                    className={classNames("t-flex t-gap-4", {
                      "t-pointer-events-auto t-justify-between": isAdmin,
                    })}
                  >
                    {isAdmin && (
                      <NewButton
                        type="submit"
                        onClick={() => setCBtn(2)}
                        customType="primary-outlined"
                      >
                        Save
                      </NewButton>
                    )}
                    <NewButton
                      type="button"
                      onClick={() => dispatch(setShowPricingModal(true))}
                      customType="primary"
                    >
                      Pay Now
                    </NewButton>
                  </div>
                </TaskButtonBar>
              )}
            </FormikForm>
          );
        }}
      </Formik>

      <UpdateTaskPrice
        show={showUpdatePriceModal}
        setShow={setShowUpdatePriceModal}
        closeModal={() => setShowUpdatePriceModal(false)}
        groupId={groupId}
        newStateId={
          task?.state?.is_step_replaceable ? task?.state?.new_state_id : next
        }
        taskId={taskId}
        task={task}
        isVariablePricing={isVariablePricing}
        entityId={entityId}
      />
      {showTaskReview && (
        <FilingReviewModal
          isArchived={isArchived}
          handleSubmit={() => handleFormSubmit(form, formValues)}
        />
      )}
    </div>
  );
};
