import React, { createContext, ReactNode, useContext } from "react";
import classNames from "classnames";
import { WhiteTick } from "components/icons/WhiteTick";
import { LargeWhiteTick } from "components/icons/LargeWhiteTick";
import { SmallWhiteTick } from "components/icons/SmallWhiteTick";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import ReactCountryFlag from "react-country-flag";
import { useChangeStepMutation } from "store/apis/productOnboarding";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import Loader from "components/design/loader";

export type StepperProps = {
  children: ReactNode | string;
  size?: "small" | "regular" | "large";
  direction?: "vertical" | "horizontal";
};

type StepperContextValue = Omit<StepperProps, "children">;

type StepProps = {
  step: number;
  isActive?: boolean;
  isCompleted?: boolean;
  isPending?: boolean;
  isDisabled?: boolean;
  notFirstStep?: boolean;
  notLastStep?: boolean;
  description?: string;
  children: ReactNode;
  onClick?: () => void;
  clickable?: boolean;
  showEntities?: boolean;
  selectedEntity?: string;
  formStepId?: string;
  isCurrentStepDisabled?: boolean;
  setSelectedEntity: (entityId: string) => void;
};

const StepperContext = createContext<StepperContextValue | undefined>(
  undefined
);

const Step = ({
  children,
  step,
  isActive,
  isCompleted,
  isPending,
  notFirstStep,
  notLastStep,
  isDisabled,
  description,
  onClick,
  clickable,
  showEntities,
  selectedEntity,
  formStepId,
  isCurrentStepDisabled,
  setSelectedEntity,
}: StepProps) => {
  const context = useContext(StepperContext);
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const usEntities = entities.filter((e) => e.country_code === "US");
  const [changeFormStep, { isLoading, originalArgs }] = useChangeStepMutation();
  const { alertToast } = useToast();

  const updateCurrentEntity = async (entityId: string) => {
    try {
      if (isCurrentStepDisabled) {
        setSelectedEntity(entityId);
        return;
      }

      await changeFormStep({
        groupId: groupId || "",
        formStepId: formStepId,
        action: "SAVE_ENTITY_DATA",
        payload: {
          entity_id: entityId,
        },
      }).unwrap();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const { size, direction } = context || {};
  let whiteTick;
  let stepHeight = 24;

  switch (size) {
    case "large":
      whiteTick = <LargeWhiteTick />;
      break;
    case "small":
      whiteTick = <SmallWhiteTick />;
      break;
    default:
      whiteTick = <WhiteTick />;
      break;
  }

  if (showEntities) {
    stepHeight = stepHeight + 24 * entities.length - 10;
  }

  return (
    <>
      <div
        data-size={size}
        data-direction={direction}
        className={classNames(
          "t-flex t-items-center data-[size=small]:t-h-6 data-[size=small]:t-min-w-6 data-[size=regular]:t-h-8 data-[size=regular]:t-min-w-8 data-[size=large]:t-h-10 data-[size=large]:t-min-w-10 data-[direction=vertical]:t-gap-4",
          {
            "t-cursor-pointer": clickable,
          }
        )}
        onClick={() => clickable && onClick?.()}
      >
        <div
          data-size={size}
          data-direction={direction}
          className={classNames(
            "t-border t-flex t-items-center t-justify-center t-border-solid data-[size=large]:t-border-[2.5px] data-[size=regular]:t-border-[2px] data-[size=small]:t-border-[1.5px] t-rounded-full data-[size=small]:t-h-6 data-[size=small]:t-min-w-6 data-[size=regular]:t-h-8 data-[size=regular]:t-min-w-8 data-[size=large]:t-h-10 data-[size=large]:t-min-w-10 data-[size=large]:t-text-body-lg data-[size=regular]:t-text-body-sm data-[size=small]:t-text-body-sm data-[direction=horizontal]:t-mr-2",
            {
              "t-border-purple-50 t-bg-purple-50 t-text-purple-0 !t-font-semibold":
                isActive && !isDisabled && !isCompleted,
              "t-border-dark_green-50 t-bg-dark_green-50":
                isCompleted && !isDisabled,
              "t-border-neutral-20 t-bg-neutral-0 t-text-neutral-30":
                (isPending || isDisabled) && !isCompleted,
            }
          )}
        >
          {isCompleted ? whiteTick : step}
        </div>
        <div>
          <div
            data-size={size}
            data-direction={direction}
            className={classNames(
              "data-[size=small]:t-text-subtext data-[size=regular]:t-text-subtitle data-[size=large]:t-text-title-h2-bold",
              {
                "t-text-text-100": !isDisabled && !isActive,
                "t-text-neutral-30": isDisabled && !isActive,
                "t-text-purple-50": isActive,
              }
            )}
          >
            {children}
          </div>
          {description && (
            <div
              data-size={size}
              data-direction={direction}
              className={classNames(
                "data-[size=small]:t-text-body-sm data-[size=regular]:t-text-body data-[size=large]:t-text-body-lg",
                {
                  "t-text-neutral": !isDisabled,
                  "t-text-neutral-20": isDisabled,
                }
              )}
            >
              {description}
            </div>
          )}
        </div>

        {notLastStep && (
          <div
            data-size={size}
            data-direction={direction}
            className={classNames(
              "t-h-0.5 data-[size=small]:t-ml-3 data-[size=small]:t-w-6 data-[size=regular]:t-ml-4 data-[size=regular]:t-w-8 data-[size=large]:t-ml-5 data-[size=large]:t-w-10 data-[direction=vertical]:t-hidden data-[direction=horizontal]:!t-mx-2",
              {
                "t-bg-green-80": isCompleted,
                "t-bg-purple-10": !isCompleted || isActive,
              }
            )}
          ></div>
        )}
      </div>
      {notLastStep && (
        <div
          data-size={size}
          data-direction={direction}
          className={classNames(
            "t-w-full data-[direction=horizontal]:t-hidden data-[size=small]:t-ml-3 t-border t-border-solid t-border-t-0 t-border-r-0 t-border-b-0 t-border-2",
            {
              "t-border-purple-10": (!isCompleted && isActive) || !isCompleted,
              "t-border-green-80": isCompleted,
            }
          )}
          style={{ height: stepHeight + "px" }}
        >
          {showEntities && (
            <div className="t-ml-6 t-space-y-1">
              {usEntities.map((entity) => (
                <button
                  key={entity.uuid}
                  className={classNames(
                    "all:unset t-flex t-gap-1 t-items-center t-p-1 t-rounded t-w-fit t-max-w-40 t-text-ellipsis t-whitespace-nowrap t-overflow-x-hidden",
                    {
                      "t-shadow-box-shadow t-bg-surface":
                        selectedEntity === entity.uuid,
                    }
                  )}
                  onClick={() => updateCurrentEntity(entity.uuid)}
                  type="button"
                >
                  <ReactCountryFlag
                    style={{ height: "0.75em", width: "1.25em" }}
                    countryCode={entity.country_code}
                    svg
                  />
                  <span className="t-text-subtext-sm t-text-text-100 t-truncate">
                    {entity.name}
                  </span>

                  {isLoading &&
                    originalArgs?.payload?.entity_id === entity.uuid && (
                      <Loader size="small" customType="secondary" />
                    )}
                </button>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export const OnboardingStepper = ({
  size = "regular",
  direction = "vertical",
  children,
}: StepperProps) => {
  const contextValue = { size, direction };
  return (
    <StepperContext.Provider value={contextValue}>
      <div
        className={classNames("t-flex t-flex-wrap t-gap-y-2", {
          "t-flex-col": direction === "vertical",
        })}
      >
        {children}
      </div>
    </StepperContext.Provider>
  );
};

OnboardingStepper.Step = Step;
