import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import Table from "components/DesignSystem/Table/V2/Table";
import { Cross } from "components/icons/Cross";
import { Info } from "components/icons/Info";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { NoVendors } from "components/icons/NoVendors";
import { DocumentPreviewModal } from "components/PreviewModal";
import { useModal } from "hooks/useModal";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { setMerchantToMerge } from "store/slices/vendors";
import { CPA_REVIEWED } from "types/Models/vendors";
import { VendorAssign } from "./AssignVendors";
import { MarkMerchantAsVendor } from "./MarkMerchantAsVendorModal";
import { MarkMultipleMerchantsAsRequired } from "./MarkMultipleMerchantsAsRequired";
import { MerchantTableDropdown } from "./MerchantTableDropdown";
import { MergeMerchants } from "./MergeMerchants";
import VendorColumn, { Form1099Handler, WForm } from "./VendorColumn";
import { VendorTransactions } from "./VendorTransactions";
import { usePagination } from "hooks/usePagination";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Contacts } from "components/icons/LeftNav/Books/Contacts";
import { DollerIcon } from "components/icons/DollerIcon";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";

export const MerchantTable = ({
  merchants,
  groupId,
  showWform,
  totalPages,
  paginationData,
  noMerchant,
  sorting,
  setSorting,
  transactionsWithoutVendorsCount,
  entityId,
  showHeader = true,
  selectedSeason,
  filters = <></>,
  isShowEstimated1099 = true,
}) => {
  const { update } = useUpdateQuery();
  const query = useQuery();
  const page = Number(query.get("page")) || 1;
  const assignVendors = useModal();
  const [showAssignVendor, setShowAssignVendor] = useState(
    transactionsWithoutVendorsCount > 0
  );

  const {
    open: openMarkAsVendorModal,
    close: closeMarkAsVendorModal,
    isOpen: showMarkAsVendorModal,
  } = useModal();
  const {
    open: openMergeMerchantsModal,
    close: closeMergeMerchantsModal,
    isOpen: showMergeMerchantsModal,
  } = useModal();
  const { isCustomer, isAdmin } = useRoleBasedView();
  const dispatch = useDispatch();
  const [editMerchantId, setEditMerchantId] = useState("");
  const [expandedRow, setExpandedRow] = useState(null);
  const [selectedVendorsForMark, setSelectedVendorsForMark] = useState([]);
  const markFormRequiredConfirmationModal = useModal();
  const history = useHistory();
  const { path } = useRouteMatch();
  const { link } = useConstructInternalLink();

  const handleRowAmountClick = (rowId) => {
    setExpandedRow((prevRowId) => (prevRowId === rowId ? null : rowId));
  };

  const openMarkAsVendor = (merchant) => {
    setEditMerchantId(merchant.uuid);
    openMarkAsVendorModal();
  };

  const { goToFirstPage, goToLastPage, goToNextPage, goToPrevPage } =
    usePagination({
      onPageNumChange: (pageNum) => {
        update({ query: "page", value: pageNum });
      },
      pageNumber: page,
      totalPage: totalPages,
    });

  const mergeMerchants = () => {
    const merchantsToMerge = table
      .getSelectedRowModel()
      .flatRows.map((merchant) => {
        return merchant.original.uuid;
      });
    dispatch(setMerchantToMerge(merchantsToMerge));
    openMergeMerchantsModal();
  };

  const columnHelper = createColumnHelper();

  const goToDetails = (id) => {
    history.push(link(`${path}/${id}`));
  };

  const checkBoxColumn = showHeader
    ? [
        columnHelper.display({
          id: "select",
          size: 3,
          enableSorting: false,
          header: ({ table }) => (
            <Checkbox
              checked={table.getIsAllRowsSelected()}
              indeterminate={table.getIsSomeRowsSelected()}
              onChange={table.getToggleAllRowsSelectedHandler()}
            />
          ),
          cell: ({ row }) => (
            <Checkbox
              checked={row.getIsSelected()}
              disabled={!row.getCanSelect()}
              indeterminate={row.getIsSomeSelected()}
              onChange={row.getToggleSelectedHandler()}
              key={row.id}
              onClick={(e) => e.stopPropagation()}
            />
          ),
        }),
      ]
    : [];

  const amount1099 = isShowEstimated1099
    ? [
        columnHelper.accessor("1099_transaction_amount", {
          cell: (info) => {
            const showTransactions = info.row.original?.name === expandedRow;
            return (
              <div
                className="t-w-full t-flex t-justify-end t-text-subtext t-text-text-60 t-pr-5 t-group"
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowAmountClick(info.row.original?.name || "");
                }}
              >
                <button
                  className={classNames("all:unset t-text-body t-ml-auto", {
                    "hover:!t-underline group-hover:t-text-purple":
                      !showTransactions,
                    "!t-underline t-text-purple": showTransactions,
                  })}
                >
                  <AmountSuperScript amount={Math.abs(info.getValue())} />
                </button>
              </div>
            );
          },
          header: (
            <div className="t-w-full t-flex t-justify-end t-gap-1">
              <span>1099 AMOUNT</span>
              <ToolTip text="Cumulative value of all bills received from a vendor excluding credit card payments">
                <span>
                  <InfoFilledSmall color="currentColor" />
                </span>
              </ToolTip>
            </div>
          ),
          size: 18,
        }),
      ]
    : [];

  const wFormColumn = isShowEstimated1099
    ? [
        columnHelper.accessor("w_form_document", {
          enableSorting: false,
          cell: (info) => {
            return (
              <div
                onClick={(e) => e.stopPropagation()}
                className="t-cursor-default"
              >
                <WForm
                  groupId={groupId}
                  vendor={info.row.original}
                  season={selectedSeason}
                />
              </div>
            );
          },
          header: "W FORM",
          id: "wFormDocument",
          size: 28,
        }),
      ]
    : [];

  const form1099 = isShowEstimated1099
    ? [
        columnHelper.display({
          id: "form_1099_document?.payers_document",
          header: (
            <div className="t-text-center t-w-full t-text-30 t-text-Subtext-sm">
              1099
            </div>
          ),
          enableSorting: false,
          size: 15,
          cell: (info) => {
            return (
              <div
                onClick={(e) => e.stopPropagation()}
                className="t-cursor-default"
              >
                <Form1099Handler
                  entityId={entityId}
                  vendor={info.row.original}
                  season={selectedSeason}
                />
              </div>
            );
          },
        }),
      ]
    : [];

  const totalTxnAmount = isShowEstimated1099
    ? []
    : [
        columnHelper.accessor("transaction_amount", {
          cell: (info) => {
            const showTransactions = info.row.original?.name === expandedRow;
            return (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  handleRowAmountClick(info.row.original?.name || "");
                }}
                className={classNames(
                  "all:unset t-text-body t-ml-auto t-flex t-justify-end t-pr-5",
                  {
                    "hover:!t-underline hover:t-text-purple t-text-text-60":
                      !showTransactions,
                    "!t-underline t-text-purple": showTransactions,
                  }
                )}
              >
                <AmountSuperScript amount={Math.abs(info.getValue())} />
              </button>
            );
          },
          header: (
            <div className="t-w-full t-flex t-justify-end t-gap-1">
              <span>TOTAL TRANSACTED AMOUNT</span>
              <ToolTip
                text={
                  <>
                    Sum of all billed or un-billed transactions
                    <br />
                    including payments made and received
                  </>
                }
              >
                <span>
                  <InfoFilledSmall color="currentColor" />
                </span>
              </ToolTip>
            </div>
          ),
          size: 40,
        }),
      ];

  const sourceColumn = isShowEstimated1099
    ? []
    : [
        columnHelper.accessor("source", {
          header: () => (
            <div className="t-flex t-justify-center t-flex-1">SOURCE</div>
          ),
          size: 10,
          enableSorting: false,
          cell: (info) => {
            const source = info.getValue();
            return (
              <div className="t-flex t-justify-center">
                <ConditionalToolTip
                  condition={
                    <div className="t-lowercase first-letter:t-capitalize">
                      {source}
                    </div>
                  }
                >
                  <div
                    className="t-rounded-full t-bg-white t-overflow-hidden t-size-6 t-text-text-30 t-border-neutral-10 t-border t-border-solid t-text-center"
                    role="img"
                  >
                    {source === "MANUAL" && <Contacts />}
                    {source === "TRANSACTION" && <DollerIcon />}
                  </div>
                </ConditionalToolTip>
              </div>
            );
          },
        }),
      ];

  const columns = useMemo(
    () => [
      ...checkBoxColumn,
      columnHelper.accessor("logo", {
        enableSorting: false,
        cell: (info) => {
          const merchantName = info.row.original.name;

          return (
            <div className="t-flex t-gap-2 t-items-center">
              <Avatar
                src={info.getValue()}
                alt={merchantName}
                variant="primary"
                size="regular"
              />
              <div>
                <button
                  className={classNames(
                    "all:unset t-text-subtext t-text-text-60 t-group t-cursor-pointer hover:t-underline hover:t-text-purple-50"
                  )}
                  onClick={(e) => goToDetails(info.row.original.uuid)}
                >
                  {merchantName}
                </button>
                <div>
                  <VendorColumn.Email
                    info={info}
                    editMerchantId={editMerchantId}
                    setEditMerchantId={setEditMerchantId}
                  />
                </div>
              </div>
            </div>
          );
        },
        header: "VENDOR",
        size: 35,
      }),

      ...amount1099,
      ...wFormColumn,
      ...form1099,

      ...totalTxnAmount,
      ...sourceColumn,

      columnHelper.display({
        header: "",
        id: "actions",
        enableSorting: false,
        cell: (info) => {
          const isManualMerchant = info.row.original.source === "MANUAL";
          const isVendor =
            info.row.original.is_w_form_required !== "NOT_REQUIRED";
          const showOption = showWform
            ? info.row.original?.is_w_form_required === CPA_REVIEWED
            : true;

          if (!showOption) {
            return;
          }

          return (
            <div className="t-flex t-justify-end t-w-full">
              <MerchantTableDropdown
                vendor={info.row.original}
                openMarkAsVendor={() => openMarkAsVendor(info.row.original)}
                merchantId={info.row.original.uuid}
                season={info.row.original?.w_form_season}
                groupId={groupId}
                isManualMerchant={isManualMerchant}
                isVendor={isVendor}
                showTransactions={setExpandedRow}
                expandedRow={expandedRow}
                vendorName={info.row.original.name}
                form1099Status={info.row.original.form_1099_status}
              />
            </div>
          );
        },
        size: showWform ? 5 : 10,
      }),
    ],
    [
      entityId,
      groupId,
      isCustomer,
      selectedSeason,
      showWform,
      expandedRow,
      isShowEstimated1099,
    ]
  );

  const table = useReactTable({
    data: merchants,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualSorting: true,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    enableMultiSort: false,
    enableSortingRemoval: false,
    defaultColumn: {
      minSize: 1,
    },
  });

  const markFormRequired = async () => {
    const selectedMerchants = table
      .getSelectedRowModel()
      .flatRows.map((merchant) => {
        return merchant.original.uuid;
      });
    setSelectedVendorsForMark(selectedMerchants);
    markFormRequiredConfirmationModal.open();
  };

  const handleSuccess = () => {
    setSelectedVendorsForMark([]);
    table.resetRowSelection();
    markFormRequiredConfirmationModal.close();
  };

  return (
    <>
      {showHeader && (
        <>
          <div className="t-flex t-items-center">
            {table?.getSelectedRowModel?.()?.flatRows.length === 0 ? (
              filters
            ) : (
              <div className="t-flex t-gap-3 t-items-center">
                <div className="t-flex t-text-body-sm t-text-text-30 t-items-center t-gap-2">
                  <Checkbox
                    onChange={() => table.resetRowSelection()}
                    indeterminate={
                      table.getSelectedRowModel().flatRows.length > 0
                    }
                    label={`${
                      table.getSelectedRowModel().flatRows.length
                    } Selected`}
                  />
                </div>
                {table?.getSelectedRowModel?.()?.flatRows.length > 1 && (
                  <Button size="small" onClick={mergeMerchants}>
                    Merge
                  </Button>
                )}
                {isAdmin && selectedSeason && (
                  <Button size="small" onClick={markFormRequired}>
                    Mark form required
                  </Button>
                )}
              </div>
            )}

            <div className="t-ml-auto">
              <Pagination
                {...paginationData}
                goToFirstPage={goToFirstPage}
                goToPrevPage={goToPrevPage}
                goToNextPage={goToNextPage}
                goToLastPage={goToLastPage}
              />
            </div>
          </div>
          {showAssignVendor && (
            <div className="t-p-3 t-rounded-lg t-border t-border-solid t-border-purple-10 t-bg-purple-0 t-flex t-items-center t-justify-between">
              <div className="t-flex t-gap-2 t-items-center">
                <span className="t-text-purple-40 t-flex">
                  <Info stroke="1.4" />
                </span>
                <div className="t-text-body-sm">
                  Vendors missing for {transactionsWithoutVendorsCount}{" "}
                  transactions , assign to ensure accurate payment calculation
                </div>
              </div>
              <div className="t-flex t-gap-3 t-items-center">
                <Button
                  size="small"
                  customType="link"
                  onClick={() => assignVendors.open()}
                >
                  Assign Vendors
                </Button>
                <Button
                  size="extra-small"
                  customType="transparent"
                  onClick={() => setShowAssignVendor(false)}
                >
                  <Cross />
                </Button>
              </div>
            </div>
          )}
        </>
      )}
      {noMerchant ? (
        <>
          <div className="t-pt-6">
            <EmptyScreen text="No vendors found">
              <NoVendors />
            </EmptyScreen>
          </div>
        </>
      ) : (
        <Table.Container
          layout="fixed"
          className={classNames("t-overflow-auto")}
        >
          <Table.Content className="t-w-full">
            <Table.Head>
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.HeadRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeadCell
                      className={classNames(
                        "t-text-subtext-sm t-uppercase t-px-2 t-py-4 t-group t-border-0 t-border-solid t-border-neutral-30 t-border-b",
                        { "!t-border-none": header.id === "select" }
                      )}
                      onClick={header.column.getToggleSortingHandler()}
                      key={header.id}
                      style={{ width: `${header.getSize()}%` }}
                      {...(header.column.getCanSort()
                        ? { role: "button" }
                        : {})}
                    >
                      <span className="t-flex t-gap-1 t-items-center">
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {header.column.getCanSort() && (
                          <Table.HeadCellSort
                            nextSortOrder={header.column.getNextSortingOrder()}
                            isSorted={header.column.getIsSorted()}
                          />
                        )}
                      </span>
                    </Table.HeadCell>
                  ))}
                </Table.HeadRow>
              ))}
            </Table.Head>
            <Table.Body>
              {table.getRowModel().rows.map((row) => (
                <React.Fragment key={row.id}>
                  <Table.Row onRowClick={() => goToDetails(row.original.uuid)}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        style={{ width: `${cell.column.getSize()}%` }}
                        className="t-p-2"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </Table.Row>
                  {expandedRow === row.original.name && (
                    <Table.Row className="!t-border-b-0">
                      <Table.Cell
                        colSpan={row.getVisibleCells().length}
                        className="t-pl-[3%] !t-pb-0"
                      >
                        <VendorTransactions
                          vendorName={expandedRow}
                          season={selectedSeason}
                          entityId={entityId}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )}
                </React.Fragment>
              ))}
            </Table.Body>
          </Table.Content>
        </Table.Container>
      )}
      <DocumentPreviewModal />

      <MarkMerchantAsVendor
        groupId={groupId}
        show={showMarkAsVendorModal}
        closeModal={closeMarkAsVendorModal}
        merchantId={editMerchantId}
        season={selectedSeason}
      />
      <MergeMerchants
        show={showMergeMerchantsModal}
        closeModal={closeMergeMerchantsModal}
        groupId={groupId}
        resetSelectedMerchant={() => table.resetRowSelection()}
        merchants={merchants}
      />
      {assignVendors.isOpen && (
        <VendorAssign {...assignVendors} season={selectedSeason} />
      )}
      <MarkMultipleMerchantsAsRequired
        isOpen={markFormRequiredConfirmationModal.isOpen}
        close={markFormRequiredConfirmationModal.close}
        entityId={entityId}
        merchantIds={selectedVendorsForMark}
        season={selectedSeason}
        onSuccess={handleSuccess}
      />
    </>
  );
};
