import qs from "qs";
import { FiltersValues } from "store/slices/transactionFilter";
import { GeneralLedger, Transactions } from "types/Models/books";
import { emptyApi } from "./emptyApi";
import { RECURRING_JOUNRAL_ENTRY_UPDATE_TYPES_T } from "types/contants/recurringJournalEntryUpdateTypes";
import { STOP_RECURRING_JOUNAL_ENTRY_TYPES_T } from "types/contants/stopRecurringJounalEntryTypes";

type JournalEntryResult = {
  ledger_entry: {
    uuid: string;
    entry_number: number;
    entry_name: string;
    settings?: {
      is_recurring_enabled: boolean;
      max_recurring_count?: number;
      recurring_count?: number;
      frequency?: string;
      end_date?: string;
    };
    date: string;
  };
  transactions: Transactions[];
};

export type GeneralLedgerParams = {
  cashFlow: FiltersValues;
  minAmount: string;
  maxAmount: string;
  accountingMethod: FiltersValues;
  startDate: FiltersValues;
  endDate: FiltersValues;
  hideZero: FiltersValues;
  categoryIds: FiltersValues;
  searchTerm?: string | null;
};

export const categoryFilterValuesToParams = (params: GeneralLedgerParams) => {
  return qs.stringify(
    {
      search_term: params.searchTerm,
      start_date: params.startDate?.value,
      end_date: params.endDate?.value,
      cash_flow: params.cashFlow?.value,
      min_amount: params.minAmount,
      max_amount: params.maxAmount,
      accounting_method: params.accountingMethod.value,
      show_non_zero_accounts: params.hideZero.value || false,
      category_ids: (params.categoryIds?.value as string[])?.join(","),
    },
    { addQueryPrefix: true, skipNulls: true }
  );
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getGeneralLedger: build.query<
      GeneralLedger[],
      {
        groupId: string;
        entityId: string;
      } & GeneralLedgerParams
    >({
      query: ({ groupId, entityId, ...params }) => {
        let queryUrl = categoryFilterValuesToParams(params);

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/general_ledger/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["GENERAL_LEDGER"] : []),
    }),

    addLedgerEntry: build.mutation<
      JournalEntryResult,
      {
        groupId: string;
        entityId: string;
        transactions: {
          description: string;
          amount: number;
          merchant_data_id: string;
          transaction_category_id: string;
          invoice_id: string | null;
        }[];
        ledger_entry_name: string;
        transaction_date: string;
        is_recurring_enabled: boolean;
        frequency?: string;
        end_date?: string;
        max_recurring_count?: number;
      }
    >({
      query: ({
        groupId,
        entityId,
        transactions,
        ledger_entry_name,
        transaction_date,
        is_recurring_enabled,
        frequency,
        max_recurring_count,
        end_date,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/ledger-entry/`,
          method: "POST",
          body: {
            transactions,
            ledger_entry_name,
            transaction_date,
            is_recurring_enabled,
            frequency,
            max_recurring_count,
            end_date,
          },
        };
      },
      invalidatesTags: (result) =>
        result ? ["GENERAL_LEDGER", "JOURNAL_ENTRIES"] : [],
    }),

    getRelatedLedgerEntry: build.query<
      JournalEntryResult,
      {
        groupId: string;
        entityId: string;
        transactionId: string;
      }
    >({
      query: ({ groupId, entityId, transactionId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/ledger-entry/?transaction_id=${transactionId}`,
        };
      },
      providesTags: (result) => (result ? ["GENERAL_LEDGER"] : []),
    }),

    updatedLedgerEntry: build.mutation<
      JournalEntryResult,
      {
        groupId: string;
        entityId: string;
        ledgerEntryId: string;
        transactions: {
          description: string | null;
          transaction_id?: string;
          memo: string | null;
          logo: string | null;
          amount: number;
          merchant_data_id: string | null;
          transaction_category_id: string;
        }[];
        ledger_entry_name: string;
        transaction_date: string;
        is_recurring_enabled: boolean;
        max_recurring_count?: number;
        frequency?: string;
        end_date?: string;
        event_update_type: RECURRING_JOUNRAL_ENTRY_UPDATE_TYPES_T;
        stop_recurrence_type?: STOP_RECURRING_JOUNAL_ENTRY_TYPES_T;
      }
    >({
      query: ({
        groupId,
        entityId,
        ledgerEntryId,
        transactions,
        ledger_entry_name,
        transaction_date,
        is_recurring_enabled,
        max_recurring_count,
        frequency,
        end_date,
        event_update_type,
        stop_recurrence_type,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/ledger-entry/${ledgerEntryId}/`,
          method: "PUT",
          body: {
            transactions,
            ledger_entry_name,
            transaction_date,
            is_recurring_enabled,
            max_recurring_count,
            frequency,
            end_date,
            event_update_type,
            stop_recurring_type: stop_recurrence_type,
          },
        };
      },
      invalidatesTags: (result) =>
        result
          ? ["GENERAL_LEDGER", "BOOKS_SINGLE_TRANSACTION", "JOURNAL_ENTRIES"]
          : [],
    }),

    exportLedger: build.mutation<
      { general_ledger_document_id: string },
      {
        groupId: string;
        entityId: string;
        cashFlow: FiltersValues;
        minAmount: string;
        maxAmount: string;
        accountingMethod: FiltersValues;
        startDate: FiltersValues;
        endDate: FiltersValues;
        hideZero: FiltersValues;
        categoryIds: FiltersValues;
        reportType: string;
        downloadType: string;
        export_to_mail?: boolean;
      }
    >({
      query: ({
        groupId,
        entityId,
        cashFlow,
        minAmount,
        maxAmount,
        accountingMethod,
        startDate,
        endDate,
        hideZero,
        categoryIds,
        reportType,
        downloadType,
        export_to_mail,
      }) => {
        let queryUrl = qs.stringify(
          {
            start_date: startDate?.value,
            end_date: endDate?.value,
            cash_flow: cashFlow?.value,
            min_amount: minAmount,
            max_amount: maxAmount,
            accounting_method: accountingMethod.value,
            show_non_zero_accounts: hideZero.value || false,
            category_ids: (categoryIds?.value as string[])?.join(","),
            report_type: reportType,
            action_type: downloadType,
            export_to_mail: export_to_mail,
          },
          { addQueryPrefix: true, skipNulls: true }
        );

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/general_ledger/export/${queryUrl}`,
        };
      },
    }),

    getMoneyInTransit: build.query<
      Transactions[],
      {
        groupId: string;
        entityId: string;
        reportType?: string | null;
        startDate?: string | null;
        endDate?: string | null;
      }
    >({
      query: ({ groupId, entityId, reportType, startDate, endDate }) => {
        let queryUrl = qs.stringify(
          {
            start_date: startDate,
            end_date: endDate,
            report_type: reportType,
          },
          { addQueryPrefix: true, skipNulls: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/in_transit_transfers/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["MONEY_IN_TRANSIT"] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGeneralLedgerQuery,
  useAddLedgerEntryMutation,
  useGetRelatedLedgerEntryQuery,
  useUpdatedLedgerEntryMutation,
  useExportLedgerMutation,
  useGetMoneyInTransitQuery,
} = extendedApi;
