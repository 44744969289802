import { Button } from "components/DesignSystem/Button/Button";
import { useFormikContext } from "formik";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import {
  useCreateSalesTaxBusinessMutation,
  useGetSalexTaxBusinessQuery,
  useUpdateSalesTaxBusinessMutation,
} from "store/apis/salesTax";
import { BackendError } from "types/utils/error";

export const SalesTaxOnboardingSubmit = ({
  changeStep,
  showComplete,
}: {
  changeStep: (step: "PREV_STEP" | "NEXT_STEP") => void;
  showComplete?: boolean;
}) => {
  const entityId = useAppSelector((state) => state.onboardingWidget.entityId);
  const { alertToast } = useToast();
  const { data: business, isLoading } = useGetSalexTaxBusinessQuery(
    { entityId: entityId! },
    { skip: !entityId }
  );

  const { values } = useFormikContext();

  const [createSalesTaxBusiness] = useCreateSalesTaxBusinessMutation();
  const [updateSalesTaxBusiness] = useUpdateSalesTaxBusinessMutation();

  const onClick = async () => {
    try {
      if (business) {
        await updateSalesTaxBusiness({
          entityId: entityId!,
          payload: values as any,
        }).unwrap();
      } else {
        await createSalesTaxBusiness({
          entityId: entityId!,
          payload: values as any,
        }).unwrap();
      }

      return changeStep("NEXT_STEP");
    } catch (error: any) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error
      );
    }
  };

  return (
    <div className="t-flex t-gap-2">
      <Button onClick={onClick}>
        {showComplete ? "Complete onboarding" : "Next"}
      </Button>
    </div>
  );
};
