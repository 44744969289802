import { APIProvider, useMapsLibrary } from "@vis.gl/react-google-maps";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik, FormikValues, getIn, useFormikContext } from "formik";
import { useToast } from "hooks/useToast";
import { useEffect, useRef, useState } from "react";
import { MenuPlacement, MultiValue, SingleValue } from "react-select";
import {
  useCreateAutofillMutation,
  useGetAutofillQuery,
  useUpdateAutofillMutation,
} from "store/apis/autofill";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import { AddressAutofill } from "types/Models/addressAutofill";
import { Countries } from "types/Models/countries";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import { object, string } from "yup";
import {
  getCity,
  getCountry,
  getState,
  getStreetAddress,
  getZipCode,
} from "./AddressComponentFieldValues";
import {
  CustomGoogleSuggestedOption,
  CustomSavedAddressOption,
} from "./AddressComponentOptions";

const countryNameToCode: Record<string, string> = {
  "United States": "us",
};

const API_KEY = process.env.PUBLIC_GOOGLE_MAP_KEY;

type PlaceAutocompleteProps = {
  availableCountriesData: Countries[];
  formPrefix?: string;
  required?: boolean;
  label?: string;
  groupId: string;
  entityId: string;
  selectedAddressId?: string | null;
  onAddressSelect: (address: AddressAutofill | null) => void;
  onCreateManually: () => void;
  field_prefix?: string;
  field_suffix?: string;
  onEditAddress: (addressId: string) => void;
  hideClear?: boolean;
  allowedCountries?: string[];
};

export const AddressAutocomplete = ({
  availableCountriesData,
  formPrefix = "",
  required = true,
  label = "Address",
  groupId,
  entityId,
  selectedAddressId,
  onAddressSelect,
  onCreateManually,
  field_prefix = "",
  field_suffix = "",
  onEditAddress,
  hideClear,
  allowedCountries,
}: PlaceAutocompleteProps) => {
  const place = useMapsLibrary("places");
  const tokenRef = useRef<any>();
  const [suggestedPlaces, setSuggestedPlaces] = useState<
    google.maps.places.AutocompleteSuggestion[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setFieldValue, errors } = useFormikContext<{
    street_address: string;
  }>();
  const [defaultAddress, setDefaultAddress] = useState<AddressAutofill>();
  const { data: autofillsData = [] } = useGetAutofillQuery(
    {
      groupId,
      autofillKey: "addresses",
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const autofills = Boolean(allowedCountries?.length)
    ? autofillsData.filter((item) => allowedCountries?.includes(item.country))
    : autofillsData;

  const allowedCountryCodes = allowedCountries?.map(
    (name) => countryNameToCode[name]
  );
  const [search, setSearch] = useState("");

  const savedAddresses = autofills.filter((address) =>
    address.autofill_string
      .toLocaleLowerCase()
      .includes(search.toLocaleLowerCase())
  );

  useEffect(() => {
    if (place?.AutocompleteSessionToken) {
      tokenRef.current = new place.AutocompleteSessionToken();
    }
  }, [place?.AutocompleteSessionToken]);

  const onChange = async (newValue: string) => {
    if (place) {
      const input = newValue;
      const request = {
        input,
        language: "en-US",
        sessionToken: tokenRef.current,
        contries: allowedCountryCodes,
      };

      try {
        setIsLoading(true);
        const { suggestions } =
          await place.AutocompleteSuggestion.fetchAutocompleteSuggestions(
            request
          );
        setSuggestedPlaces(suggestions);
      } catch (error) {}
      setIsLoading(false);
    }
  };

  const onSelect = async (
    e: SingleValue<OptionData> | MultiValue<OptionData>
  ) => {
    try {
      onCreateManually();
      if (!e) {
        setFieldValue(`${formPrefix}${field_prefix}city`, "");
        setFieldValue(`${formPrefix}${field_prefix}country`, "");
        setFieldValue(`${formPrefix}${field_prefix}state`, "");
        setFieldValue(`${formPrefix}${field_prefix}zipcode`, "");
        setFieldValue(`${formPrefix}${field_prefix}street_address`, "");
        setFieldValue(`${formPrefix}${field_prefix}street_address_line_2`, "");
        setFieldValue(`${formPrefix}${field_prefix}uuid`, "");
      }

      if (!(e instanceof Array) && place && e?.value) {
        const selectedPlace = new place.Place({
          id: e.value,
        });

        const placeInfo = await selectedPlace.fetchFields({
          fields: ["addressComponents"],
        });

        const city = getCity(placeInfo.place);
        const state = getState(placeInfo.place, availableCountriesData);
        const country = getCountry(placeInfo.place, availableCountriesData);
        const zipcode = getZipCode(placeInfo.place);
        const streetAddress = getStreetAddress(placeInfo.place);

        setFieldValue(`${formPrefix}${field_prefix}city`, city);
        setFieldValue(`${formPrefix}${field_prefix}country`, country);
        setFieldValue(`${formPrefix}${field_prefix}state`, state);
        setFieldValue(`${formPrefix}${field_prefix}zipcode`, zipcode);
        setFieldValue(
          `${formPrefix}${field_prefix}street_address`,
          streetAddress
        );
        setFieldValue(`${formPrefix}${field_prefix}street_address_line_2`, "");
        setFieldValue(`${formPrefix}${field_prefix}uuid`, "");
      }
    } catch (error) {}
  };

  const onSavedAddressSelect = async (
    option: SingleValue<OptionData> | MultiValue<OptionData>
  ) => {
    try {
      const selectedAddress = savedAddresses.find(
        (address) => address.uuid === (option as SingleValue<OptionData>)?.value
      );

      const city = selectedAddress?.city || "";
      const state = selectedAddress?.state || "";
      const country = selectedAddress?.country || "";
      const zipcode = selectedAddress?.zipcode || "";
      const street_address = selectedAddress?.street_address || "";
      const street_address_line_2 =
        selectedAddress?.street_address_line_2 || "";

      onAddressSelect(selectedAddress || null);

      setFieldValue(`${formPrefix}${field_prefix}city`, city);
      setFieldValue(`${formPrefix}${field_prefix}country`, country);
      setFieldValue(`${formPrefix}${field_prefix}state`, state);
      setFieldValue(`${formPrefix}${field_prefix}zipcode`, zipcode);
      setFieldValue(
        `${formPrefix}${field_prefix}street_address`,
        street_address
      );
      setFieldValue(
        `${formPrefix}${field_prefix}street_address_line_2`,
        street_address_line_2
      );
      setFieldValue(`${formPrefix}${field_prefix}uuid`, selectedAddress?.uuid);
    } catch (error) {}
  };

  const handleCreateManually = () => {
    setFieldValue(`${formPrefix}${field_prefix}city`, "");
    setFieldValue(`${formPrefix}${field_prefix}country`, "");
    setFieldValue(`${formPrefix}${field_prefix}state`, "");
    setFieldValue(`${formPrefix}${field_prefix}zipcode`, "");
    setFieldValue(`${formPrefix}${field_prefix}street_address`, "");
    setFieldValue(`${formPrefix}${field_prefix}street_address_line_2`, "");
    setFieldValue(`${formPrefix}${field_prefix}uuid`, "");
    onCreateManually();
  };

  const debouncedOnChange = debounce(onChange);

  const error = getIn(errors, `${formPrefix}street_address`);
  const savedAddressesLength = savedAddresses.length;

  useEffect(() => {
    const selectedAddress = savedAddresses?.find(
      (address) => address.uuid === selectedAddressId
    );

    setDefaultAddress(selectedAddress);
  }, [selectedAddressId, savedAddresses]);

  const clearComponent = hideClear ? { ClearIndicator: () => null } : {};

  const commonComboboxProps = {
    isLoading: isLoading,
    required: required,
    label: label,
    onInputChange: (newValue: string) => {
      setSearch(newValue);
      debouncedOnChange(newValue);
    },
    placeholder: "Start typing to search",
    onMenuClose: () => setSuggestedPlaces([]),
    menuPortalTarget: document.body,
    menuPlacement: "auto" as MenuPlacement,
    minMenuHeight: 400,
  };

  return (
    <div className="autocomplete-container">
      {savedAddressesLength > 0 ? (
        <Combobox
          onChange={onSavedAddressSelect}
          options={savedAddresses?.map((autofill) => ({
            value: autofill.uuid,
            label: autofill.autofill_string,
          }))}
          value={
            defaultAddress
              ? {
                  value: defaultAddress.uuid,
                  label: defaultAddress.autofill_string,
                }
              : null
          }
          components={{
            Option: (props) => (
              <CustomSavedAddressOption
                {...props}
                editAddress={() =>
                  onEditAddress((props.data as OptionData).value)
                }
              />
            ),
            ...clearComponent,
          }}
          {...commonComboboxProps}
          shouldWrapText
          allowCopy
          allowEdit
          handleEdit={(selectedOption) => onEditAddress(selectedOption.value)}
        />
      ) : (
        <Combobox
          actions={
            suggestedPlaces.length == 0 ? (
              <div className="t-py-1">
                <Button
                  type="button"
                  customType="link"
                  onClick={handleCreateManually}
                >
                  Add address manually
                </Button>
              </div>
            ) : null
          }
          onChange={onSelect}
          filterOption={() => true}
          // @ts-ignore
          options={suggestedPlaces
            .map((suggestion) => ({
              value: suggestion.placePrediction?.toPlace().id,
              label: suggestion.placePrediction?.text.text,
            }))
            .filter(({ value }) => value)}
          type={error && "error"}
          components={{
            Option: (props) => <CustomGoogleSuggestedOption {...props} />,
          }}
          {...commonComboboxProps}
        />
      )}

      {error && (
        <div className="t-mt-1.5 t-text-body-sm t-text-red">{error}</div>
      )}
    </div>
  );
};

export const AddressComponents = ({
  formPrefix = "",
  required = true,
  groupId,
  entityId,
  selectedAddressId,
  onAddressSelect,
  label,
  onClearAddress,
  handleCreateManually,
  fieldPrefix = "",
  fieldSuffix = "",
  srollToSave,
  hideClear,
  allowedCountries = [],
}: {
  formPrefix?: string;
  required?: boolean;
  groupId: string;
  entityId: string;
  selectedAddressId?: string;
  onAddressSelect: (address: AddressAutofill | null) => void;
  label?: string;
  onClearAddress?: () => void;
  handleCreateManually?: () => void;
  fieldPrefix?: string;
  fieldSuffix?: string;
  srollToSave?: boolean;
  hideClear?: boolean;
  allowedCountries?: string[];
}) => {
  const { data: countries } = useGetCountriesDataQuery();
  const [createManually, setCreateManually] = useState(false);
  const { successToast, alertToast } = useToast();
  const [editAddressId, setEditAddressId] = useState<string | null>(null);

  const [createAutofill, { isLoading: addingAutofill }] =
    useCreateAutofillMutation();
  const [editAutofill, { isLoading: isUpdating }] = useUpdateAutofillMutation();
  const { data: autofills = [] } = useGetAutofillQuery(
    {
      groupId,
      autofillKey: "addresses",
      entityId,
    },
    { skip: !groupId || !entityId }
  );
  const isLoading = isUpdating || addingAutofill;
  const saveButtonRef = useRef<HTMLButtonElement>(null);
  const streetAddressKey = `${formPrefix}${fieldPrefix}street_address`;
  const cityKey = `${formPrefix}${fieldPrefix}city`;
  const countryKey = `${formPrefix}${fieldPrefix}country`;
  const zipcodeKey = `${formPrefix}${fieldPrefix}zipcode`;
  const streetLine2Key = `${formPrefix}${fieldPrefix}street_address_line_2`;
  const stateKey = `${formPrefix}${fieldPrefix}state`;

  const { values, setFieldValue } = useFormikContext<Record<string, any>>();

  const defaultAddress = autofills.find(
    (address) => address.uuid === selectedAddressId
  );

  const updateAddressFields = (address?: AddressAutofill) => {
    setFieldValue(cityKey, address?.city || "");
    setFieldValue(countryKey, address?.country || "");
    setFieldValue(stateKey, address?.state || "");
    setFieldValue(zipcodeKey, address?.zipcode || "");
    setFieldValue(streetAddressKey, address?.street_address || "");
    setFieldValue(streetLine2Key, address?.street_address_line_2 || "");
  };

  const updateLocalAddressFields = (
    setLocalFieldValue: (field: string, value: any) => void,
    address?: AddressAutofill
  ) => {
    setLocalFieldValue(cityKey, address?.city || "");
    setLocalFieldValue(countryKey, address?.country || "");
    setLocalFieldValue(stateKey, address?.state || "");
    setLocalFieldValue(zipcodeKey, address?.zipcode || "");
    setLocalFieldValue(streetAddressKey, address?.street_address || "");
    setLocalFieldValue(streetLine2Key, address?.street_address_line_2 || "");
  };

  const clearAddress = (
    setLocalFieldValue: (field: string, value: any) => void
  ) => {
    updateLocalAddressFields(setLocalFieldValue, defaultAddress);
    updateAddressFields(defaultAddress);
    setFieldValue(`${formPrefix}${fieldPrefix}uuid`, selectedAddressId);
    onClearAddress?.();
    setCreateManually(false);
    setEditAddressId(null);
  };

  const getAddressPayload = (values: Record<string, any>) => {
    return {
      street_address: values[streetAddressKey],
      street_address_line_2: values[streetLine2Key],
      city: values[cityKey],
      country: values[countryKey],
      state: values[stateKey],
      zipcode: values[zipcodeKey],
    };
  };

  const addAddress = async (values: FormikValues) => {
    try {
      const autofill = await createAutofill({
        fields: getAddressPayload(values),
        groupId,
        autofillKey: "addresses",
        entityId,
      }).unwrap();
      onAddressSelect(autofill as AddressAutofill);
      updateAddressFields(autofill as AddressAutofill);
      successToast({ message: "Autofill added successfully" });
      setCreateManually(false);
      setEditAddressId(null);
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const editAddress = async (values: FormikValues) => {
    try {
      const autofill = await editAutofill({
        fields: getAddressPayload(values),
        groupId,
        autofillKey: "addresses",
        entityId,
        uuid: editAddressId!,
      }).unwrap();
      onAddressSelect(autofill as AddressAutofill);
      updateAddressFields(autofill as AddressAutofill);
      successToast({ message: "Autofill updated successfully" });
      setCreateManually(false);
      setEditAddressId(null);
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const onCreateManually = () => {
    setCreateManually(true);
    handleCreateManually?.();
    setEditAddressId(null);
  };

  const onEditAddress = (
    addressId: string,
    setLocalFieldValue: (field: string, value: any) => void
  ) => {
    const selectedAddress = autofills.find(({ uuid }) => uuid === addressId);
    updateLocalAddressFields(setLocalFieldValue, selectedAddress);
    updateAddressFields(selectedAddress);
    setFieldValue(`${formPrefix}${fieldPrefix}uuid`, addressId);
    onCreateManually();
    setCreateManually(true);
    setEditAddressId(addressId);
  };

  useEffect(() => {
    if (srollToSave && saveButtonRef.current) {
      saveButtonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [editAddressId, saveButtonRef.current, srollToSave, createManually]);

  return (
    <Formik
      validateOnChange
      validationSchema={object({
        [streetAddressKey]: string().required("Address Line 1 Required"),
        [cityKey]: string().required("City Required"),
        [countryKey]: string().required("Country Required"),
        [stateKey]: string().required("State Required"),
        [zipcodeKey]: string().required("Required"),
      })}
      onSubmit={editAddressId ? editAddress : addAddress}
      initialValues={{
        [streetAddressKey]: defaultAddress?.street_address || "",
        [streetLine2Key]: defaultAddress?.street_address_line_2 || "",
        [cityKey]: defaultAddress?.city || "",
        [countryKey]: defaultAddress?.country || "",
        [stateKey]: defaultAddress?.state || "",
        [zipcodeKey]: defaultAddress?.zipcode || "",
      }}
      enableReinitialize
      validateOnMount={false}
    >
      {({ submitForm, setFieldValue: setLocalFieldValue, values }) => {
        const selectedCountry = getIn(values, countryKey);
        const selectedState = getIn(values, stateKey);
        return (
          <Form>
            <>
              {!createManually && (
                <APIProvider
                  apiKey={API_KEY || ""}
                  solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
                >
                  <div className="autocomplete-control">
                    <AddressAutocomplete
                      formPrefix={formPrefix}
                      availableCountriesData={countries || []}
                      required={required}
                      groupId={groupId}
                      entityId={entityId}
                      selectedAddressId={selectedAddressId}
                      onAddressSelect={onAddressSelect}
                      allowedCountries={allowedCountries}
                      onCreateManually={onCreateManually}
                      label={label}
                      field_prefix={fieldPrefix}
                      field_suffix={fieldSuffix}
                      onEditAddress={(addressId: string) =>
                        onEditAddress(addressId, setLocalFieldValue)
                      }
                      hideClear={hideClear}
                    />
                  </div>
                </APIProvider>
              )}
              {createManually && (
                <div>
                  <Label>{label}</Label>
                  <div className="t-shadow-light-30 t-space-y-4 t-border t-border-solid t-border-neutral-0 t-rounded-lg t-p-4">
                    <div className="t-text-subtitle-sm t-text-text-100">
                      {editAddressId ? "Edit Address" : "Add Address"}
                    </div>
                    <div className="t-relative t-w-full">
                      <TextInput
                        block
                        required={required}
                        label="Address Line 1"
                        placeholder="Street address"
                        name={streetAddressKey}
                      />
                    </div>
                    <div>
                      <TextInput
                        block
                        label="Address Line 2"
                        placeholder="Apartment, suite, building, floor etc."
                        name={streetLine2Key}
                      />
                    </div>
                    <div>
                      <Combobox
                        required={required}
                        name={countryKey}
                        withForm
                        label="Country"
                        placeholder="Select Country"
                        options={
                          countries?.map((country) => ({
                            value: country.name,
                            label: country.name,
                          }))!
                        }
                        value={
                          selectedCountry
                            ? {
                                label: selectedCountry,
                                value: selectedCountry,
                              }
                            : null
                        }
                      />
                    </div>
                    <div>
                      <Combobox
                        name={stateKey}
                        required={required}
                        withForm
                        label="State"
                        placeholder="Select State"
                        options={
                          countries
                            ?.find((c) => c.name === selectedCountry)
                            ?.states?.map((state) => ({
                              value: state.name,
                              label: state.name,
                            }))!
                        }
                        value={
                          selectedState
                            ? { label: selectedState, value: selectedState }
                            : null
                        }
                      />
                    </div>
                    <div className="t-flex t-gap-6 t-w-full">
                      <div className="t-w-full">
                        <TextInput
                          required={required}
                          block
                          label="City"
                          placeholder="Enter City"
                          name={cityKey}
                        />
                      </div>
                      <div className="t-w-full">
                        <TextInput
                          required={required}
                          block
                          placeholder="Enter Zip, postal or pin code"
                          label="Zip, postal or pin code"
                          name={zipcodeKey}
                        />
                      </div>
                    </div>
                    <div className="t-flex t-justify-end t-gap-4">
                      <Button
                        customType="ghost"
                        type="button"
                        onClick={() => clearAddress(setLocalFieldValue)}
                        size="small"
                      >
                        Cancel
                      </Button>
                      <Button
                        customType="primary"
                        type="submit"
                        disabled={isLoading}
                        isLoading={isLoading}
                        size="small"
                        ref={saveButtonRef}
                        onClick={submitForm}
                      >
                        Save address
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </>
          </Form>
        );
      }}
    </Formik>
  );
};
