export const Import = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.60642 1H4.29167C3.53358 1 2.91667 1.61692 2.91667 2.375V6.5H3.83333V2.375C3.83333 2.12246 4.03867 1.91667 4.29167 1.91667H7.95833V5.125H11.1667V11.0833H3.83333V9.25H2.91667V12H12.0833V4.47692L8.60642 1ZM8.875 2.56475L10.5186 4.20833H8.875V2.56475ZM6.58196 8.33333H2V7.41667H6.587L5.53192 6.36662L6.18 5.71854L7.69204 7.23013C8.04679 7.58533 8.04679 8.16375 7.69204 8.51896L6.17954 10.031L5.53146 9.38292L6.5815 8.33287L6.58196 8.33333Z"
      fill="#41395C"
    />
  </svg>
);
