import DashboardContainer from "components/dashboard/DashboardContainer";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { CATEGORISATION_TYPE } from "constants/booksHomePage";
import { useFilters } from "hooks/useFilter";
import { useLocalStorage } from "hooks/useLocalStorage";
import { Header } from "components/DesignSystem/Header/Header";
import { CashFlow } from "./CashFlow";
import { FinancialHealth } from "./FinancialHealth";

type FilterType = {
  CATEGORISATION_TYPE: keyof typeof CATEGORISATION_TYPE;
};

const ToggleTransactionType = ({
  values,
  updateFilter,
}: {
  values: FilterType;
  updateFilter: <S extends keyof FilterType>(
    name: S,
    newValue: FilterType[S]
  ) => void;
}) => {
  const { setItem, localData: showToolTip } = useLocalStorage(
    "showCompanyInsightsToolTip",
    true
  );

  return (
    <ConditionalToolTip
      condition={
        showToolTip && (
          <div className="t-w-72 t-flex t-flex-col t-gap-4">
            <div className="t-flex t-items-start t-flex-col t-gap-1.5 t-justify-start">
              <div className="t-text-subtext-sm t-text-white">
                Verified Transactions
              </div>
              <div className="t-text-body-sm t-text-neutral-30 t-text-start">
                Verified transactions are manually
                <br />
                categorised, which are highly accurate.
              </div>
            </div>
            <div className="t-border t-border-text-30 t-border-dashed t-w-full" />
            <div className="t-flex t-items-start t-flex-col t-gap-1.5 t-justify-start">
              <div className="t-text-subtext-sm t-text-white">
                All Transactions
              </div>
              <div className="t-text-body-sm t-text-neutral-30 t-text-start">
                All transactions include AI-categorised
                <br />
                data, which may not always be accurate.
              </div>
            </div>

            <div className="t-self-end">
              <Button
                size="small"
                customType="primary"
                onClick={() => setItem(false)}
              >
                Got it
              </Button>
            </div>
          </div>
        )
      }
    >
      <div className="t-w-max">
        <ToggleGroup
          className="t-w-80"
          value={values.CATEGORISATION_TYPE}
          onValueChange={(value: keyof typeof CATEGORISATION_TYPE) => {
            if (!value) return;
            updateFilter("CATEGORISATION_TYPE", value);
          }}
        >
          <ConditionalToolTip
            condition={
              !showToolTip && (
                <div className="t-text-body-sm t-text-white t-text-start">
                  Verified transactions are manually
                  <br />
                  categorised, which are highly accurate.
                </div>
              )
            }
          >
            <ToggleGroupItem value={CATEGORISATION_TYPE.VERIFIED}>
              Verified Transactions
            </ToggleGroupItem>
          </ConditionalToolTip>
          <ConditionalToolTip
            condition={
              !showToolTip && (
                <div className="t-text-body-sm t-text-white t-text-start">
                  All transactions include AI-categorised
                  <br />
                  data, which may not always be accurate.
                </div>
              )
            }
          >
            <ToggleGroupItem value={CATEGORISATION_TYPE.ALL}>
              All Transactions
            </ToggleGroupItem>
          </ConditionalToolTip>
        </ToggleGroup>
      </div>
    </ConditionalToolTip>
  );
};

export const BusinessOverview = () => {
  const { values, updateFilter } = useFilters<FilterType>({
    initialValue: {
      CATEGORISATION_TYPE: CATEGORISATION_TYPE.VERIFIED,
    },
    useQueryParams: true,
  });

  return (
    <DashboardContainer>
      <DashboardContainer.Header className="t-flex t-gap-4 t-flex-col">
        <Header
          right={
            <ToggleTransactionType
              values={values}
              updateFilter={updateFilter}
            />
          }
          v2
          title="Business Overview"
        />
      </DashboardContainer.Header>
      <DashboardContainer.Content className="t-mt-4 t-grid t-gap-6 t-grid-cols-2 t-h-96">
        <CashFlow categorisationType={values.CATEGORISATION_TYPE} />
        <FinancialHealth categorisationType={values.CATEGORISATION_TYPE} />
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
