import * as Progress from "@radix-ui/react-progress";
import classNames from "classnames";

const Root = ({
  type = "primary",
  disabled = false,
  ...props
}: Progress.ProgressProps & {
  type?: "green" | "primary" | "grey";
  disabled?: boolean;
}) => {
  return (
    <Progress.Root
      {...props}
      className={classNames(
        `t-rounded-lg t-h-2 t-overflow-hidden`,
        props?.className || "",
        {
          [`t-group/${type}`]: !disabled,
          "t-bg-neutral-10": type !== "grey",
          "t-bg-neutral-0": type === "grey",
        }
      )}
    >
      {props?.children}
    </Progress.Root>
  );
};

const Indicator = (
  props: Progress.ProgressIndicatorProps & { progress: number }
) => {
  return (
    <Progress.Indicator
      {...props}
      className={classNames(
        `t-h-full t-transition-transform t-duration-1000  t-ease-in-out group-[&]/primary:t-bg-purple-30 group-[&]/green:t-bg-dark_green-50 group-[&]/grey:t-bg-neutral-10`,
        props?.className || ""
      )}
      style={
        props.style || { transform: `translateX(-${100 - props.progress}%)` }
      }
    />
  );
};

const ProgressBar = {
  Root,
  Indicator,
};

export default ProgressBar;
