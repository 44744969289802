import * as RadioGroupR from "@radix-ui/react-radio-group";
import classNames from "classnames";
import { ComponentProps, ReactNode } from "react";

export type ContentProps = {
  children?: ReactNode;
  className?: string;
  text?: string;
};

export const RadioGroupItem = ({
  value,
  children,
  ...rest
}: { value: string } & RadioGroupR.RadioGroupItemProps) => {
  return (
    <RadioGroupR.Item
      {...rest}
      value={value}
      className="t-bg-white t-border-none t-flex t-gap-2 t-items-center"
    >
      <div className="all:unset t-min-w-[18px] t-min-h-[18px] t-max-w-[18px] t-max-h-[18px] t-rounded-full t-outline-none t-cursor-default t-border t-border-solid t-border-neutral hover:t-border-purple hover:t-border-1 data-state-checked:t-border-purple-50 t-flex t-justify-center t-items-center">
        <RadioGroupR.Indicator>
          <div className="t-w-2 t-h-2 t-bg-purple-50 t-rounded-[50%]" />
        </RadioGroupR.Indicator>
      </div>
      {children}
    </RadioGroupR.Item>
  );
};

const Item = ({
  children,
  className = "",
  value = "",
  asChild,
  align = "center",
  ...props
}: RadioGroupR.RadioGroupItemProps &
  React.RefAttributes<HTMLDivElement> &
  ContentProps & {
    align?: "start" | "center" | "end";
  }) => {
  return (
    <label
      className={classNames("t-flex t-gap-3 t-w-full t-h-5", className, {
        "t-items-center": align === "center",
        "t-items-start": align === "start",
        "t-items-end": align === "end",
      })}
      htmlFor={props.id || value}
      aria-label={props["aria-label"] || undefined}
    >
      <RadioGroupItem {...props} value={value} id={props.id || value}>
        <div
          className={classNames(
            "t-w-full t-h-full t-text-text-100 t-cursor-pointer t-align-middle",
            {
              "t-opacity-50 t-cursor-no-drop": props.disabled,
            }
          )}
        >
          {children}
        </div>
      </RadioGroupItem>
    </label>
  );
};

const Content = ({
  children,
  className = "",
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={classNames("t-flex t-flex-col", {
        [className]: className,
        "t-gap-2": !className,
      })}
    >
      {children}
    </div>
  );
};

const RadioGroup = {
  ...RadioGroupR,
  Item: Item,
  Content: Content,
};

export default RadioGroup;
