import classNames from "classnames";
import { PreviewModal } from "components/ChatPreviewModal/ChatPreviewModal";
import { FILE_VIEWED } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import PreviewImageName from "static/images/PreviewImageName.svg";
import {
  useGetFolderOrFileQuery,
  useGetPreviewUrlQuery,
} from "store/apis/previewUrl";
import { RootState } from "store/store";
import { ModalProps } from "types/utils/modal";
import Async from "./DesignSystem/AsyncComponents/Async";
import { SwitchCase } from "./DesignSystem/SwitchCase/SwitchCase";

const ImagePreview = ({
  name,
  preview_url,
}: {
  name: string;
  preview_url: string;
}) => {
  return (
    <img
      src={preview_url}
      className="t-object-contain t-max-h-[90%] t-self-center t-w-10/12 t-justify-self-center -t-top-8 t-relative"
      alt={name}
    />
  );
};

const PdfPreview = ({
  preview_url,
  name,
}: {
  preview_url: string;
  name: string;
}) => {
  return (
    <iframe className="t-w-full t-h-[96%]" src={preview_url} title={name} />
  );
};

const NonPreviewableFile = ({
  preview_url,
  name,
  fullWidth = false,
}: {
  preview_url: string;
  name: string;
  fullWidth?: boolean;
}) => {
  return (
    <div
      className={classNames(
        "t-flex t-items-center t-justify-center t-gap-1 t-rounded-lg t-p-7 t-bg-neutral-80 t-text-white t-h-14 t-self-center t-justify-self-center",
        {
          "t-w-2/5": !fullWidth,
          "t-w-full": fullWidth,
        }
      )}
    >
      File not previewable,{" "}
      <a
        href={preview_url}
        download
        className="t-text-blue-50 hover:t-text-blue"
      >
        click here
      </a>{" "}
      to download the file.
    </div>
  );
};

export const PreviewBody = ({
  file,
  preview_url,
  fullWidth = false,
}: {
  file: any;
  preview_url: string;
  fullWidth?: boolean;
}) => {
  if (preview_url) {
    return (
      <SwitchCase
        value={file.file_type}
        cases={{
          IMAGE: <ImagePreview name={file.name} preview_url={preview_url} />,
          PDF: <PdfPreview name={file.name} preview_url={preview_url} />,
        }}
        default={
          <NonPreviewableFile
            name={file.name}
            preview_url={preview_url}
            fullWidth={fullWidth}
          />
        }
      />
    );
  }
  return null;
};

export const Preview = ({
  showModal,
  closeModal,
  groupId: groupIdFromProps,
  previewId: previewIdFromProps,
}: {
  showModal: boolean;
  closeModal: () => void;
  groupId?: string;
  previewId?: string | null;
}) => {
  const { trackEvent } = useAnalytics();
  const { previewId } = useParams<{ previewId: string }>();
  const { uuid } = useCurrentGroupContext();
  const { activeChannelGroupId } = useSelector(
    (state: RootState) => state.reviewAndBalancePayment
  );
  const groupId = (groupIdFromProps as string) || uuid || activeChannelGroupId;
  const docId = previewIdFromProps || previewId;

  const { data: file, ...fileApiState } = useGetFolderOrFileQuery(
    { groupId: groupId, fileId: docId },
    { skip: !Boolean(docId) || !groupId || !showModal }
  );

  const { data, ...previewApiState } = useGetPreviewUrlQuery(
    {
      groupId: groupId,
      fileId: file?.uuid,
    },
    { skip: !Boolean(file?.uuid) || !groupId, refetchOnMountOrArgChange: true }
  );

  const { preview_url } = data || {};

  useEffect(() => {
    if (previewApiState.isSuccess) {
      trackEvent(FILE_VIEWED, { file_id: file?.uuid! });
    }
  }, [previewApiState.isSuccess]);

  const isLoading = previewApiState.isLoading || fileApiState.isLoading;
  const isSuccess = previewApiState.isSuccess && fileApiState.isSuccess;
  const isEmpty = !data || !file;
  const isError = previewApiState.isError || fileApiState.isError;

  return (
    <PreviewModal
      onClose={closeModal}
      title={
        <>
          <img src={PreviewImageName} alt={file?.name} />
          {file?.name}
        </>
      }
      open={showModal}
    >
      <Async.Root
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        isEmpty={isEmpty}
      >
        <Async.Empty>
          <>sd</>
        </Async.Empty>
        <Async.ErrorHandler>
          <div className="t-flex t-items-center t-justify-center t-gap-1 t-rounded-lg t-p-7 t-bg-neutral-80 t-text-white t-h-14 t-self-center t-justify-self-center">
            Some issue opening the document, please contact us if this happens
            in multiple instances
          </div>
        </Async.ErrorHandler>
        <Async.Success>
          <PreviewBody file={file} preview_url={preview_url!} />
        </Async.Success>
      </Async.Root>
    </PreviewModal>
  );
};

export const DocumentPreviewModal = ({
  groupId,
}: {
  groupId?: string;
}): JSX.Element | null => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { search } = useLocation();

  const closeModal = () => {
    history.push(`${url}${search}`);
  };

  return (
    <Switch>
      <Route path={`${url}/preview/:previewId/`}>
        <Preview closeModal={closeModal} groupId={groupId} showModal={true} />
      </Route>
    </Switch>
  );
};

export const LocalFilePreview = ({
  file,
  isOpen,
  close,
}: { file: File } & ModalProps) => {
  const preview_url = URL.createObjectURL(file);

  const isPdf = file.type === "application/pdf";
  const isImage = file.type.startsWith("image/");

  return (
    <PreviewModal
      title={
        <>
          <img src={PreviewImageName} alt={file?.name} />
          {file?.name}
        </>
      }
      open={isOpen}
      onClose={close}
    >
      <SwitchCase
        value="true"
        cases={{
          [String(isPdf)]: (
            <PdfPreview preview_url={preview_url} name={file.name} />
          ),
          [String(isImage)]: (
            <ImagePreview preview_url={preview_url} name={file.name} />
          ),
        }}
        default={
          <NonPreviewableFile name={file.name} preview_url={preview_url} />
        }
      />
    </PreviewModal>
  );
};
