import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import ToolTip from "components/design/toolTip";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { useCreateSeasonSummaryMutation } from "store/apis/onboarding";
import { useChangeStepMutation } from "store/apis/productOnboarding";
import { setPaymentTitle, setScheduledPayment } from "store/slices/credit";
import { RootState } from "store/store";
import {
  OnboardingProduct,
  Step,
  StepComponent,
} from "types/Models/onboarding";
import { Subscription } from "types/Models/subscription";
import { BackendError } from "types/utils/error";
import { ENTITY_DETAILS_STEP } from "constants/onBoarding";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { CONNECT_BANK } from "constants/fieldTypes";
import { SalesTaxOnboardingSubmit } from "components/SalesTaxOnboardingSubmit/SalesTaxOnboardingSubmit";
import { PRODUCT_ONBOARDING_STEP_KEYS } from "constants/productOnboardingStepKeys";

type OnboardingCustomerProps = {
  currentStep: Step | undefined;
  stepSubscription?: Subscription | undefined;
  setSubscriptionModal: (newState: React.SetStateAction<boolean>) => void;
  termAndConditionChecked: boolean;
  setTermAndConditionChecked: (newState: React.SetStateAction<boolean>) => void;
  clientConfirmation: StepComponent | undefined;
  showOnboardingComplete: boolean;
  setShowOnboardingComplete: (newState: React.SetStateAction<boolean>) => void;
  completeOnboarding: (
    groupId: string,
    productId: string,
    closeModal: boolean
  ) => Promise<void>;
  currentProduct: OnboardingProduct;
  setCurrentStep: (step: Step) => void;
  disabled: boolean;
  onClose: () => void;
  selectedEntity?: string | null;
};

const BtnWrapperComponent = ({
  useTooltip,
  children,
  btnTooltipText,
}: {
  useTooltip: boolean;
  children: ReactNode;
  btnTooltipText: string;
}) => {
  if (useTooltip) {
    return (
      <ToolTip text={btnTooltipText}>
        <div>{children}</div>
      </ToolTip>
    );
  } else {
    return <div>{children}</div>;
  }
};

const NextBtn = ({
  btnTooltipText,
  disableNext,
  currentStep,
  isBooksOnboarding,
  changeStep,
  onClose,
}: {
  btnTooltipText: string;
  disableNext: boolean;
  currentStep: Step | undefined;
  isBooksOnboarding: boolean;
  changeStep: (step: "PREV_STEP" | "NEXT_STEP") => void;
  onClose: () => void;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityIdOnSelect = useAppSelector(
    (state) => state.onboardingWidget.entityId
  );
  const entityId = useCurrentEntityId() || entityIdOnSelect!;
  const nextButtonText = currentStep?.is_final ? "Complete onboarding" : "Next";
  const isCurrentStepCompleted = currentStep?.status === "COMPLETED";
  const { alertToast } = useToast();

  const { bookkeepingSoftware } = useSelector(
    (state: RootState) => state.booksOnboarding
  );

  const [createSeasonSummary, { isLoading }] = useCreateSeasonSummaryMutation();

  switch (currentStep?.step_key) {
    case "share_software_access":
      const onNext = async () => {
        try {
          const payload = { bookkeeping_software: bookkeepingSoftware };
          await createSeasonSummary({ groupId, entityId, payload }).unwrap();
          changeStep("NEXT_STEP");
        } catch (error) {
          alertToast({
            message: (error as BackendError)?.data?.error?.message,
          });
        }
      };

      return (
        <div>
          <Button
            customType="primary"
            onClick={onNext}
            size="small"
            disabled={disableNext}
          >
            Next
          </Button>
        </div>
      );

    case "sales_tax_business_details":
      return <SalesTaxOnboardingSubmit changeStep={changeStep} />;
    case "sales_tax_business_owner_details":
      return <SalesTaxOnboardingSubmit changeStep={changeStep} />;
    case "sales_tax_business_activity_details":
      return (
        <SalesTaxOnboardingSubmit
          changeStep={changeStep}
          showComplete={currentStep.should_show_complete_onboarding_cta}
        />
      );

    case "bookkeeping_plan":
      const onBookkeepingNext = async () => {
        try {
          changeStep("NEXT_STEP");
        } catch (error) {
          alertToast({
            message: (error as BackendError)?.data?.error?.message,
          });
        }
      };
      return (
        <Button
          customType="primary"
          onClick={onBookkeepingNext}
          size="small"
          disabled={disableNext}
        >
          Next
        </Button>
      );

    case "inkle_subscription_complete":
      return (
        <Button customType="primary" onClick={onClose} size="small">
          Complete
        </Button>
      );

    case "enter_information_complete":
      return (
        <Button customType="primary" onClick={onClose} size="small">
          Complete
        </Button>
      );

    default:
      return (
        <BtnWrapperComponent
          useTooltip={isBooksOnboarding}
          btnTooltipText={btnTooltipText}
        >
          <Button
            size="small"
            customType="primary"
            onClick={() => changeStep("NEXT_STEP")}
            disabled={disableNext || isCurrentStepCompleted}
          >
            {nextButtonText}
          </Button>
        </BtnWrapperComponent>
      );
  }
};

export const OnboardingCustomFooter = ({
  currentStep,
  stepSubscription,
  setSubscriptionModal,
  termAndConditionChecked,
  setTermAndConditionChecked,
  clientConfirmation,
  showOnboardingComplete,
  setShowOnboardingComplete,
  completeOnboarding,
  currentProduct,
  setCurrentStep,
  disabled,
  onClose,
  selectedEntity,
}: OnboardingCustomerProps) => {
  const { alertToast } = useToast();
  const dispatch = useDispatch();
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const entityIdOnSelect = useAppSelector(
    (state) => state.onboardingWidget.entityId
  );
  const entityId = useCurrentEntityId() || entityIdOnSelect!;
  const [changeFormStep] = useChangeStepMutation();
  const usEntities = entities.filter((e) => e.country_code === "US");
  const currentUsEntityIndex = usEntities.findIndex(
    (entity) => entity.uuid === selectedEntity
  );
  const nextUsEntity = usEntities[currentUsEntityIndex + 1]?.uuid;
  const prevUsEntity = usEntities[currentUsEntityIndex - 1]?.uuid;

  const { data: ledger } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const { accounts = [] } = ledger || {};

  const isGroupAlreadySubscribed = Boolean(
    stepSubscription?.group_subscription
  );

  const isCurrentStepCompleted = currentStep?.status === "COMPLETED";
  const isBooksOnboarding = currentProduct.name === "Books";

  const isBooksStepCompleted = isCurrentStepCompleted && isBooksOnboarding;
  const isTermsAndConditionAccepted =
    clientConfirmation || currentStep?.type === CONNECT_BANK
      ? termAndConditionChecked
      : true;

  let disableNext = isBooksStepCompleted
    ? false
    : (isTermsAndConditionAccepted !== undefined &&
        !isTermsAndConditionAccepted) ||
      (isBooksOnboarding && accounts?.length === 0);

  let isScheduledPayment =
    currentStep?.field_values?.find(
      ({ field_key }) => field_key === "current_year_incorporated"
    )?.value === false ||
    currentStep?.field_values?.find(
      ({ field_key }) => field_key === "current_year_incorporated_v2"
    )?.value;

  const btnTooltipText = "Please complete all actions before proceeding.";

  const changeStep = async (step: "PREV_STEP" | "NEXT_STEP") => {
    // handle entity change in entity details step
    if (
      currentStep?.type === ENTITY_DETAILS_STEP &&
      currentProduct.name === PRODUCT_ONBOARDING.TAXV2
    ) {
      if (step === "PREV_STEP" && prevUsEntity) {
        updateCurrentEntity(prevUsEntity);
        return;
      }
      if (step === "NEXT_STEP" && nextUsEntity) {
        updateCurrentEntity(nextUsEntity);
        return;
      }
    }

    if (currentStep?.is_final && step === "NEXT_STEP") {
      let closeModal = false;
      if (
        showOnboardingComplete ||
        currentStep?.step_key === "share_bank_access" ||
        currentStep?.step_key === "schedule_call_with_ops" ||
        currentStep?.step_key === "choose_bookkeeping_plan" ||
        currentStep?.step_key ===
          PRODUCT_ONBOARDING_STEP_KEYS.SALES_TAX_BUSINESS_ACTIVITY_DETAILS
      ) {
        closeModal = true;
      }

      await completeOnboarding(groupId, currentProduct.uuid, closeModal);

      if (!showOnboardingComplete) {
        setShowOnboardingComplete(true);
      }
      setTermAndConditionChecked(false);
      return;
    }

    const steps = currentProduct.steps
      .slice()
      .sort((a: any, b: any) => a.order - b.order);

    const currentStepIndex =
      steps?.findIndex((step: any) => step.uuid === currentStep?.uuid) || 0;

    const nextStepIndex =
      step === "PREV_STEP" ? currentStepIndex - 1 : currentStepIndex + 1;

    const newStep = await changeFormStep({
      groupId: groupId || "",
      formStepId: currentStep?.uuid,
      action: step,
      payload: {
        new_form_step_id: steps?.[nextStepIndex]?.uuid || "",
      },
    }).unwrap();

    setCurrentStep(newStep);
  };

  const updateCurrentEntity = async (entityId: string) => {
    try {
      const newStep = await changeFormStep({
        groupId: groupId || "",
        formStepId: currentStep?.uuid,
        action: "SAVE_ENTITY_DATA",
        payload: {
          entity_id: entityId,
        },
      }).unwrap();
      setCurrentStep(newStep);
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const amountPayable = isBooksOnboarding
    ? currentStep?.field_values.reduce((acc, cur) => {
        if (cur.value?.amount) {
          acc = Number(cur.value?.amount) + acc;
        }
        return acc;
      }, 0)
    : currentStep?.subscription?.amount;

  const onStartSubscription = () => {
    if (stepSubscription?.subscription_name) {
      setSubscriptionModal(true);
      dispatch(setPaymentTitle(stepSubscription?.subscription_name));
      if (isScheduledPayment) {
        dispatch(setScheduledPayment(true));
      }
    }
  };

  return (
    <>
      <div
        className={classNames("t-flex", {
          "t-justify-end": isBooksOnboarding,
          "t-justify-between": !isBooksOnboarding,
        })}
      >
        {!isBooksOnboarding && (
          <Button
            size="small"
            customType="secondary"
            onClick={() => changeStep("PREV_STEP")}
            disabled={
              currentStep?.order === 0 || currentProduct.status === "COMPLETED"
            }
          >
            Back
          </Button>
        )}
        <div className="t-flex t-gap-3">
          {Number(amountPayable) && !isGroupAlreadySubscribed ? (
            <Button
              size="small"
              customType="primary"
              onClick={onStartSubscription}
            >
              {isScheduledPayment
                ? "Schedule payment"
                : `Pay ${amountPayable && `$${amountPayable}`}`}
            </Button>
          ) : (
            <NextBtn
              disableNext={disableNext}
              btnTooltipText={btnTooltipText}
              changeStep={changeStep}
              isBooksOnboarding={isBooksOnboarding}
              currentStep={currentStep}
              onClose={onClose}
            />
          )}
        </div>
      </div>
    </>
  );
};
