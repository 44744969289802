import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import classNames from "classnames";
import Async from "components/DesignSystem/AsyncComponents/Async";
import Table from "components/DesignSystem/Table/V2/Table";
import { EmptyMetrics } from "components/EmptyMetrics/EmptyMetrics";
import { EmptyTopSpends } from "components/Illustrations/EmptyTopSpends";
import { TransactionSlider } from "components/Transaction/Slider/TransactionSlider";
import TransactionColumn from "components/Transaction/TransactionColumn";
import { TRANSACTIONS } from "constants/chatType";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { MouseEvent } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Table as TableType,
  useReactTable,
} from "react-table-8.10.7";
import { usePushToChatMutation } from "store/apis/transactions";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { openSlider } from "store/slices/transactions";
import { Transactions } from "types/Models/books";
import { TopInsightsProps } from "./BusinessInsights";
import { HideableAmount } from "../HideBalancesAndAmounts";

const TransactionsPreviewTable = ({
  table,
  isFetching,
  onRowClick,
}: {
  table: TableType<Transactions>;
  isFetching: boolean;
  onRowClick?: (
    transactionId: string,
    e: MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>
  ) => void;
}) => {
  const { sliderTransactions } = useAppSelector((state) => state?.transactions);

  return (
    <Table.Container className="t-h-full" isLoading={isFetching}>
      <Table.Content>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.HeadRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  className="t-text-subtext-sm t-uppercase t-px-2 t-py-4 t-group"
                  key={header.id}
                  style={{ width: `${header.getSize()}%` }}
                >
                  <span className="t-flex t-gap-1 t-items-center">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </span>
                </Table.HeadCell>
              ))}
            </Table.HeadRow>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => (
            <>
              <Table.Row
                key={row.id}
                className={classNames({
                  "t-bg-surface-lighter-grey": sliderTransactions.find(
                    ({ transactionId }) =>
                      transactionId === row?.original?.transaction?.uuid
                  ),
                  "hover:t-bg-surface-lighter-grey t-cursor-pointer":
                    Boolean(onRowClick),
                })}
                onClick={(e) => onRowClick?.(row.original.transaction.uuid, e)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Table.Cell
                      key={cell.id}
                      style={{
                        width: `${cell.column.getSize()}%`,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            </>
          ))}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

export const TopTransactions = ({
  channel_url,
  transactions,
  isInsightsFetching,
  isInsightsLoading,
  isInsightsSuccess,
}: {
  channel_url: string;
  transactions: Transactions[];
} & TopInsightsProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const dispatch = useAppDispatch();

  const [pushToChat] = usePushToChatMutation();
  const { successToast, alertToast } = useToast();

  const columnHelper = createColumnHelper<Transactions>();

  const onRowClick = (
    transactionId: string,
    e: Event | React.MouseEvent<HTMLTableRowElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(openSlider(transactionId));
  };

  const sendToChat = async ({
    transactionIds,
  }: {
    transactionIds: string[];
  }) => {
    if (channel_url) {
      dispatch(openFloatingChat());
      dispatch(setToOpenChatId(channel_url));
      try {
        const payload = {
          channel_url: channel_url,
          custom_type: TRANSACTIONS,
          transaction_ids: transactionIds.join(","),
        };
        await pushToChat({ groupId, payload, entityId }).unwrap();
        successToast({ message: "Transaction sent to chat" });
      } catch (error) {
        alertToast({ message: "Failed to send to chat" });
      }
    }
  };

  const columns = [
    columnHelper.accessor("transaction.date", {
      id: "DATE",
      size: 13,
      header: () => <span className="px-1">Date</span>,
      cell: (info) => (
        <div className="t-text-subtext t-text-text-30 t-px-1">
          {dayjs(info.getValue()).format(DD_MMM_YYYY)}
        </div>
      ),
    }),

    columnHelper.accessor("transaction.from", {
      id: "logo",
      size: 5,
      header: () => (
        <div className="t-flex t-justify-center t-w-full">Source</div>
      ),
      cell: (info) => {
        const transaction = info.row.original.transaction;

        return <TransactionColumn.Source transaction={transaction} />;
      },
    }),

    columnHelper.accessor("transaction.merchant", {
      id: "merchant",
      size: 23,
      header: "Vendor",
      cell: (info) => {
        return <TransactionColumn.Merchant info={info} />;
      },
    }),

    columnHelper.accessor("transaction.category", {
      id: "category",
      size: 28,
      header: "Category",
      cell: (info) => {
        return <TransactionColumn.Category info={info} />;
      },
    }),

    columnHelper.accessor("transaction.amount", {
      id: "AMOUNT",
      size: 6,
      header: () => (
        <span className="t-flex t-justify-end t-ml-auto">Amount</span>
      ),
      cell: (info) => {
        const amount = info.getValue();
        const {
          transaction: { is_credit_card },
        } = info.row.original || {};

        return (
          <HideableAmount>
            <TransactionColumn.Amount
              amount={amount}
              isCreditCard={is_credit_card}
            />
          </HideableAmount>
        );
      },
    }),

    columnHelper.accessor("invoices", {
      id: "invoice",
      size: 3,
      header: "Invoice",
      cell: TransactionColumn.Invoice,
    }),

    columnHelper.accessor("transaction", {
      id: "threedots",
      size: 5,
      header: "",
      cell: (info) => (
        <TransactionColumn.Actions
          info={info}
          onRowClick={onRowClick}
          sendToChat={(transactionIds) =>
            sendToChat({ transactionIds: [transactionIds] })
          }
        />
      ),
    }),
  ];

  const table = useReactTable({
    data: transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  return (
    <Async.Root
      {...{
        isLoading: isInsightsLoading,
        isEmpty: transactions.length === 0,
        isSuccess: isInsightsSuccess,
      }}
    >
      <Async.Empty>
        <EmptyMetrics
          illustration={<EmptyTopSpends />}
          title="No transactions found"
          subtitle="Add transactions to see insights"
        />
      </Async.Empty>
      <Async.Success>
        <TransactionsPreviewTable
          table={table}
          isFetching={isInsightsFetching}
          onRowClick={onRowClick}
        />
      </Async.Success>
      <TransactionSlider />
    </Async.Root>
  );
};
