import classNames from "classnames";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { HTMLAttributes, ReactNode, useEffect } from "react";
import { Button } from "../Button/Button";
import CloseIcon from "static/images/Close.svg";
import * as Dialog from "@radix-ui/react-dialog";
import { Label } from "components/DesignSystem/TextInput/TextInput";

type ContentProps = React.ComponentPropsWithoutRef<typeof Dialog.Content> & {
  // origin: "left" | "right" | "top" | "bottom";
  width?: string | number;
  // radius?: number;
  open?: boolean;
  withOverlay?: boolean;
  useCustomOverlay?: boolean;
};

type SliderProps = {
  open: boolean;
  position?: "right" | "left" | "top" | "bottom" | "";
  className?: string;
  children: ReactNode;
  overlay?: boolean;
  width?: number;
  type?: "fixed";
  withOverlay?: boolean;
  onClose?: () => void;
} & HTMLMotionProps<"div">;

const SLIDER_TYPE_CLASSES = {
  /* @tw */
  LEFT: "t-left-0 t-top-0 t-h-screen",
  /* @tw */
  RIGHT: "t-right-0 t-top-0 t-h-screen",
  /* @tw */
  TOP: "t-top-0 t-w-full t-left-0",
  /* @tw */
  BOTTOM: "t-bottom-0 t-w-full t-left-0",
};

const Header = ({
  children,
  bottom,
  ...props
}: HTMLAttributes<HTMLDivElement> & { bottom?: ReactNode }) => {
  return (
    <div className="t-pl-5 t-pr-4 t-border-0 t-border-b t-border-solid t-border-b-neutral-0">
      <div
        {...props}
        className={classNames(
          "t-flex t-flex-shrink-0 t-items-center t-justify-between t-my-3",
          props.className || ""
        )}
      >
        {children}
      </div>
      {bottom}
    </div>
  );
};

const Title = (
  props: Dialog.DialogTitleProps &
    React.RefAttributes<HTMLHeadingElement> & { titleIcon?: JSX.Element }
) => {
  return (
    <Dialog.Title
      {...props}
      asChild
      className={classNames("t-mb-0 t-text-subtitle", props.className || "")}
    >
      <span className="t-flex t-justify-between">
        {props?.children}
        {props?.titleIcon}
      </span>
    </Dialog.Title>
  );
};

const Close = (props: Dialog.DialogCloseProps) => (
  <Dialog.Close {...props} asChild>
    <span>
      <Button customType="ghost_icon" size="small" title="Close Modal">
        <img className="t-h-4 t-w-4" src={CloseIcon} alt="Close Modal" />
      </Button>
    </span>
  </Dialog.Close>
);

const Body = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={classNames("t-overflow-auto t-p-5", props.className || "")}
    />
  );
};

const Footer = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      className={classNames(
        "t-flex-shrink-0 t-px-5 t-py-4 t-bottom-0 t-bg-white t-border-solid t-border-t t-border-0 t-border-neutral-0 t-absolute t-right-0 t-left-0 t-w-full",
        props.className || ""
      )}
    />
  );
};

const CustomOverlay = (
  props: Dialog.DialogOverlayProps & React.RefAttributes<HTMLDivElement>
) => {
  useEffect(() => {
    document.body.classList.add("pointer-events-none");

    return () => {
      document.body.classList.remove("pointer-events-none");
    };
  }, []);

  return (
    <div
      className={classNames(
        "t-fixed t-inset-0 t-z-slider t-bg-text-100 t-opacity-20",
        props.className || ""
      )}
      {...props}
    />
  );
};

export const Content = ({
  withOverlay = true,
  open,
  className,
  children,
  useCustomOverlay,
  width = 480,
  ...props
}: ContentProps) => {
  const OverlayComponent = useCustomOverlay ? CustomOverlay : Dialog.Overlay;

  return (
    <AnimatePresence>
      <Dialog.Portal>
        {withOverlay && (
          <OverlayComponent asChild>
            <motion.div
              key="overlay"
              initial={{ opacity: 0, right: -[width] }}
              animate={{ opacity: 0.2, right: 0 }}
              exit={{ opacity: 0, right: -[width] }}
              className={classNames(
                "t-fixed t-top-0 t-left-0 t-bg-text-100 t-opacity-20 t-w-full t-h-full t-z-slider-overlay data-state-close:!t-w-0 data-state-close:!t-h-0 data-state-close:!t-invisible"
              )}
            />
          </OverlayComponent>
        )}

        <Dialog.Content asChild>
          <motion.div
            key="modal"
            initial={{ right: -[width] }}
            animate={{ right: 0 }}
            exit={{ right: -[width] }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            style={{ width }}
            className={classNames(
              "t-bg-surface t-px-8 t-py-6",
              SLIDER_TYPE_CLASSES.RIGHT,
              "t-border t-border-solid t-border-neutral-10 t-fixed t-bg-surface t-h-full t-z-slider t-top-0 t-right-0 t-overflow-auto !t-p-0"
            )}
          >
            {children}
          </motion.div>
        </Dialog.Content>
      </Dialog.Portal>
    </AnimatePresence>
  );
};

/**
 * @deprecated This component is deprecated and will be removed in future releases. Use import Slider and Slider.Root instead.
 */
export const Slider = ({
  withOverlay,
  type,
  open,
  position = "right",
  className,
  children,
  overlay = false,
  width = 480,
  onClose,
  ...props
}: SliderProps) => {
  return (
    <AnimatePresence>
      <>
        {withOverlay && open && (
          <motion.div
            key="overlay"
            initial={{ opacity: 0, right: -[width] }}
            animate={{ opacity: 0.2, right: 0 }}
            exit={{ opacity: 0, right: -[width] }}
            className={classNames({
              "t-fixed t-top-0 t-left-0 t-bg-text-100 t-opacity-20 t-w-full t-h-full t-z-slider-overlay":
                open,
            })}
            onClick={onClose}
          />
        )}

        {open && (
          <motion.div
            {...props}
            key="modal"
            initial={{ right: -[width] }}
            animate={{ right: 0 }}
            exit={{ right: -[width] }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className={classNames(
              "t-bg-surface t-px-8 t-py-6",
              className || "",
              {
                [SLIDER_TYPE_CLASSES.LEFT]: position === "left",
                [SLIDER_TYPE_CLASSES.RIGHT]: position === "right",
                [SLIDER_TYPE_CLASSES.TOP]: position === "top",
                [SLIDER_TYPE_CLASSES.BOTTOM]: position === "bottom",
                "t-border t-border-solid t-border-neutral-10 t-fixed t-bg-surface t-w-[480px] t-h-full t-z-slider t-top-0 t-right-0 t-overflow-auto !t-p-0":
                  type === "fixed",
                "t-absolute t-z-slider": overlay,
              }
            )}
          >
            {children}
          </motion.div>
        )}
      </>
    </AnimatePresence>
  );
};

const SliderComp = {
  Root: Dialog.Root,
  Trigger: Dialog.Trigger,
  Description: Dialog.Description,
  Content: Content,
  Header,
  Title,
  Close,
  Body,
  Footer,
};

export default SliderComp;
