import {
  HomePageCardHeader,
  HomePageCardTitle,
} from "components/BooksHomePage/HomePageCardHeader";
import { ConditionalLink } from "components/conditionalLink";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import Card from "components/DesignSystem/Card/Card";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { SmallWarningInfo } from "components/icons/SmallWarningInfo";
import { TaxTodos } from "components/TodoList/TaxTodos";
import { TODO_TAB_CLICKED_IN_HOMEPAGE } from "constants/analyticsEvents";
import {
  CHAT_REQUESTS,
  CONNECTIONS,
  DOCUMENTS_REQUESTED,
  DOCUMENT_REQUESTED,
  INFO_REQUESTED,
  PENDING_REVIEW_TRANSACTIONS,
  RECONCILIATION,
} from "constants/bookkeeping";
import { INKLE_BOOKS, INKLE_TAX } from "constants/home";
import { useAnalytics } from "hooks/useAnalytics";
import { useBookActionItems } from "hooks/useBookActionItems";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ArrowsInOut from "static/images/ArrowsInOut.svg";
import BalanceIcon from "static/images/BalanceIcon.svg";
import InfoGray from "static/images/InfoGray.svg";
import NoPendingItems from "static/images/NoPendingItems.svg";
import {
  TaxActionItemType,
  useGetActionItemsQuery,
} from "store/apis/actionItems";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { setActiveChannelGroupId } from "store/slices/reviewAndBalancePayment";
import { BankItems } from "types/Models/books";

const ItemCard = ({
  text,
  logo,
  action,
  showWarning = false,
}: {
  logo?: ReactNode;
  text: string;
  action: ReactNode;
  showWarning?: boolean;
}) => {
  return (
    <div className="t-flex t-justify-between t-border-b t-border-solid t-border-neutral-0 t-border-0 t-flex-wrap t-gap-2 t-py-4 t-pr-0.5">
      <div className="t-flex t-gap-2 t-items-center">
        <div className="t-relative t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10 t-border t-border-solid t-border-neutral-0 t-rounded-full t-flex t-items-center t-justify-center">
          {logo}
          {showWarning && (
            <div className="t-absolute -t-right-0.5 -t-bottom-0.5 t-text-red">
              <SmallWarningInfo />
            </div>
          )}
        </div>
        <div className="t-flex t-flex-col t-gap-0.5">
          <div className="t-text-subtext-sm">{text}</div>
        </div>
      </div>
      {action}
    </div>
  );
};

const ActionItemsCard = ({
  item,
  isGroupBased,
}: {
  item: any;
  isGroupBased: boolean;
}) => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const entityId = useCurrentEntityId() || item.entity_id;

  //@ts-ignore
  switch (item?.item_type) {
    case CONNECTIONS:
      const connectionItem = item as BankItems;
      return (
        <ItemCard
          showWarning
          logo={<Avatar alt={CONNECTIONS} src={connectionItem?.logo} />}
          text={`${connectionItem?.bank_name} accounts are disconnected`}
          action={
            <ConditionalLink
              to={
                isGroupBased
                  ? `/books/data-sources?entity=${entityId}`
                  : `/books/data-sources${search}`
              }
            >
              <Button customType="primary-outlined" size="small">
                Reconnect
              </Button>
            </ConditionalLink>
          }
        />
      );

    case RECONCILIATION:
      return (
        <ItemCard
          logo={<Avatar alt={RECONCILIATION} src={BalanceIcon} />}
          text="Reconcile transactions in Inkle Books"
          action={
            <ConditionalLink
              to={
                isGroupBased
                  ? `/books/transactions/reconciliation?entity=${entityId}`
                  : `/books/transactions/reconciliation${search}`
              }
            >
              <Button customType="secondary" size="small">
                Reconcile
              </Button>
            </ConditionalLink>
          }
        />
      );

    case PENDING_REVIEW_TRANSACTIONS:
      return (
        <ItemCard
          logo={
            <Avatar
              alt={item?.transaction_count?.toString()}
              src={ArrowsInOut}
              size="extra-small"
            />
          }
          text={`Pending review of ${item?.transaction_count} transactions`}
          action={
            <ConditionalLink
              to={
                isGroupBased
                  ? `/books/transactions?entity=${entityId}&notReviewed=true`
                  : `/books/transactions${search}&notReviewed=true`
              }
            >
              <Button customType="secondary" size="small">
                Review
              </Button>
            </ConditionalLink>
          }
        />
      );

    case CHAT_REQUESTS:
      let displayText = item?.header;
      let displayCount = item?.transaction_count;

      if (item?.header === INFO_REQUESTED) {
        displayText = `Information requested for ${item?.transaction_count} transactions`;
      }

      if (item?.header.includes(DOCUMENT_REQUESTED)) {
        displayCount = item?.text.replace(DOCUMENT_REQUESTED, "");
        displayText = DOCUMENT_REQUESTED;
      }

      if (item?.header.includes(DOCUMENTS_REQUESTED)) {
        displayCount = item?.header.replace(DOCUMENTS_REQUESTED, "");
        displayText = DOCUMENTS_REQUESTED;
      }

      const jumpToMsg = () => {
        dispatch(openFloatingChat());
        dispatch(setToOpenChatId(item?.channel_url!));
        dispatch(setScrollToMessageId(item?.id));
      };

      return (
        <ItemCard
          logo={<Avatar alt={displayCount?.toString()} src={InfoGray} />}
          text={displayText}
          action={
            <Button customType="secondary" size="small" onClick={jumpToMsg}>
              Add info
            </Button>
          }
        />
      );

    default:
      return null;
  }
};

export const ActionItems = ({
  isGroupBased = false,
}: {
  isGroupBased?: boolean;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();

  const { allItems, isEmpty, isLoading, isSuccess } = useBookActionItems({
    groupId,
  });

  return (
    <Async.Root
      {...{
        isEmpty,
        isLoading,
        isSuccess,
      }}
      customLoader={
        <div className="t-h-40 t-flex t-justify-center t-items-center">
          <Loader customType="secondary" />
        </div>
      }
    >
      <Async.Empty>
        <div className="t-flex t-flex-col t-items-center t-justify-center t-h-52 t-w-full t-gap-8">
          <img src={NoPendingItems} alt="NoPendingItems" />
          <div className="t-flex t-flex-col t-items-center t-justify-center t-text-subtitle t-text-text-30">
            <div>Awesome!</div>
            <div>You have no pending items</div>
          </div>
        </div>
      </Async.Empty>
      <Async.Success>
        {allItems.map((item) => (
          <ActionItemsCard
            item={item}
            key={item.item_type}
            isGroupBased={isGroupBased}
          />
        ))}
      </Async.Success>
    </Async.Root>
  );
};

export const HomeActionItems = ({
  defaultTab,
}: {
  defaultTab?: typeof INKLE_TAX | typeof INKLE_BOOKS;
}) => {
  const [selectedTab, setSelectedTab] = useState<
    typeof INKLE_TAX | typeof INKLE_BOOKS
  >(defaultTab || INKLE_TAX);
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const isFromBooks = defaultTab === INKLE_BOOKS;
  const { trackEvent } = useAnalytics();

  const { totalActionItemCount } = useBookActionItems({
    groupId,
  });

  const {
    data = { tax_action_items: [] },
    isSuccess,
    isLoading,
  } = useGetActionItemsQuery(
    {
      groupId: groupId,
      type: INKLE_TAX,
    },
    {
      skip: !groupId,
      refetchOnMountOrArgChange: true,
    }
  );

  const taxActionItems = useMemo(() => {
    if (!("tax_action_items" in data)) return [];
    const taxItems = (data as { tax_action_items: TaxActionItemType[] })
      .tax_action_items;
    return [...taxItems].sort((a, b) => {
      if (a.order == null && b.order == null) return 0;
      if (a.order == null) return 1;
      if (b.order == null) return -1;
      return a.order - b.order;
    });
  }, [data]);

  useEffect(() => {
    if (groupId) dispatch(setActiveChannelGroupId(groupId));
  }, [groupId]);

  if (defaultTab === INKLE_TAX && selectedTab === INKLE_TAX && !isSuccess)
    return null;

  return (
    <Card.Root shadow className="t-h-96" borderRounded="lg">
      <HomePageCardHeader>
        <HomePageCardTitle>Action Centre</HomePageCardTitle>
      </HomePageCardHeader>
      <Card.Body>
        <ToggleGroup
          className="t-w-56"
          value={selectedTab}
          onValueChange={(value: string) =>
            setSelectedTab(value as typeof INKLE_TAX | typeof INKLE_BOOKS)
          }
        >
          <ToggleGroupItem
            value={INKLE_TAX}
            onClick={() => trackEvent(TODO_TAB_CLICKED_IN_HOMEPAGE)}
          >
            Tax (
            {
              (data as { tax_action_items: TaxActionItemType[] })
                ?.tax_action_items?.length
            }
            )
          </ToggleGroupItem>
          <ToggleGroupItem
            value={INKLE_BOOKS}
            onClick={() => trackEvent(TODO_TAB_CLICKED_IN_HOMEPAGE)}
          >
            Books ({totalActionItemCount})
          </ToggleGroupItem>
        </ToggleGroup>
        {selectedTab === INKLE_BOOKS && <ActionItems isGroupBased />}
        {selectedTab === INKLE_TAX && (
          <div>
            {isFromBooks && isLoading ? (
              <div className="t-h-40 t-flex t-justify-center t-items-center">
                <Loader customType="secondary" />
              </div>
            ) : (data as { tax_action_items: TaxActionItemType[] })
                ?.tax_action_items?.length === 0 ? (
              <div className="t-flex t-flex-col t-items-center t-justify-center t-h-52 t-w-full t-gap-8">
                <img src={NoPendingItems} alt="NoPendingItems" />
                <div className="t-flex t-flex-col t-items-center t-justify-center t-text-subtitle t-text-text-30">
                  <div>Awesome!</div>
                  <div>You have no pending items</div>
                </div>
              </div>
            ) : (
              <>
                <TaxTodos taxActionItems={taxActionItems} />
              </>
            )}
          </div>
        )}
      </Card.Body>
    </Card.Root>
  );
};
