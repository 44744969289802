import Modal from "components/DesignSystem/Modal/Modal";

export const ConditionalAddProductToCartWrapper = ({
  useOwnModal,
  title,
  onClose,
  children,
}: {
  useOwnModal?: boolean;
  title?: string;
  onClose?: () => void;
  children: React.ReactNode;
}) => {
  if (useOwnModal) {
    return <>{children}</>;
  }

  return (
    <Modal.Root open onOpenChange={onClose}>
      <Modal.Content useCustomOverlay>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        {children}
      </Modal.Content>
    </Modal.Root>
  );
};
