import { array, object, string } from "yup";

export const vendorAddSchema = object({
  name: string().required("Name is required"),
  email: string().notRequired().email("Should be a valid email"),
  address_obj: object().when("is_add_billing_address", {
    is: true,
    then: object({
      city: string().required("City is required"),
      country: string().required("Country is required"),
      state: string().required("State is required"),
      street_address: string().required("Street is required"),
      zipcode: string().required("Zip is required"),
    }),
    otherwise: object().nullable(),
  }),
  payment_instructions: string().when("is_add_payment_instructions", {
    is: true,
    then: string().required("Payment instructions are required"),
    otherwise: string().nullable(),
  }),
});

export const bulkVendorAddSchema = object({
  merchants: array().of(
    object({
      name: string().required("Name is required"),
      email: string().notRequired().email("Should be a valid email"),
    })
  ),
});

export const vendorEditSchema = object({
  name: string().required("Name is required"),
  email: string().notRequired().email("Should be a valid email"),
});

export const merchantEmailAddSchema = () =>
  object({
    email: string()
      .required("Email is required")
      .email("Should be a valid email"),
  });
