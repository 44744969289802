import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import Loader from "components/design/loader";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import Table from "components/DesignSystem/Table/V2/Table";
import { Badge } from "components/Task/Badge";
import { TaskSlider } from "components/TaskSlider/TaskSlider";
import { TASK_AMENDMENT_COMPLETED } from "constants/chatType";
import { ALL, ONGOING } from "constants/task";
import {
  FORM_FILING_TYPE,
  SUBMITTED_TYPE,
  TASK_AMENDMENT,
} from "constants/taskStates";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { SERVICE_LIST_FILTER_TYPES } from "dictionaries";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  Row,
  useReactTable,
} from "react-table-8.10.7";
import ArrowBend from "static/images/ArrowBendDownRight.svg";
import Redirect from "static/images/Redirect.svg";
import Search from "static/images/Search.svg";
import VerticalLine from "static/images/VerticalLine.svg";
import {
  useGetTaskListForServiceTeamQuery,
  useGetTaskListForUserQuery,
} from "store/apis/taskList";
import { setTaskCount } from "store/slices/serviceList";
import { PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING } from "types/Models/services";
import { Task } from "types/Models/task";
import { useDebounce } from "utils/debounce";
import { formatDate, formatTimeToMonthYear } from "utils/formatDate";

dayjs.extend(relativeTime);

const TASK_STATUS = {
  Processing: "PROCESSING",
  Completed: "COMPLETED",
  Archived: "ARCHIVED",
};

export const ServicesTable = ({
  serviceType,
  taskCategoryType,
  parentRoute,
  addEntityInUrl = false,
}: {
  serviceType: "services" | "intragroup" | "salestax";
  taskCategoryType: "BOOKS" | "INTRA_GROUP" | "SALES_TAX";
  parentRoute: string;
  addEntityInUrl?: boolean;
}) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const search = query.get("search");
  const searchTerm = useDebounce(search, 500);
  const taskType = (query.get(serviceType) ||
    ALL) as keyof typeof SERVICE_LIST_FILTER_TYPES;
  const filterValues = SERVICE_LIST_FILTER_TYPES[taskType];
  const { uuid: groupId } = useCurrentGroupContext();
  const { isCpa, isCustomer, isAdmin, isForeignCA } = useRoleBasedView();
  const [taskId, setTaskId] = useState<string | null>(null);
  const entityId = useCurrentEntityId();

  const { data: userTaskData, isLoading: isUserTaskLoading } =
    useGetTaskListForUserQuery(
      {
        ...filterValues,
        searchTerm,
        taskCategoryType: taskCategoryType,
      },
      { skip: isCpa || isForeignCA, refetchOnMountOrArgChange: true }
    );

  const { data: cpaTaskList, isLoading: isCPATaskLoading } =
    useGetTaskListForServiceTeamQuery(
      {
        ...filterValues,
        searchTerm,
        groupId,
        taskCategoryType: taskCategoryType,
      },
      { skip: isCustomer || !groupId, refetchOnMountOrArgChange: true }
    );

  const {
    ongoing_count,
    count,
    completed_count,
    archived_count,
    tasks: taskList = [],
  } = (userTaskData || cpaTaskList || {}) as {
    tasks?: Task[];
    ongoing_count?: number;
    count?: number;
    completed_count?: number;
    archived_count?: number;
  };

  const noTaskFound = Boolean(!taskList.length);

  const isTaskLoading = isCPATaskLoading || isUserTaskLoading;

  const data: (Task & { subRows?: Task[] })[] = useMemo(() => {
    const templateMap = new Map<string, Array<any>>();

    taskList.forEach((task: any) => {
      if (!templateMap.get(task?.template?.uuid)) {
        templateMap.set(task?.template?.uuid, []);
      }

      templateMap.get(task?.template?.uuid)?.push(task);
    });

    const subTaskTemplateMap = taskList.reduce((acc, task) => {
      if (
        task.parent_task_template_id &&
        templateMap.get(task.parent_task_template_id)?.length
      ) {
        return {
          [task.parent_task_template_id]: [
            ...(acc[task.parent_task_template_id] || []),
            task,
          ],
        };
      }

      return acc;
    }, {} as Record<string, Task[]>);

    const tasksAndTheirSubtasks = taskList.map((parent) => {
      const subTasks = subTaskTemplateMap[parent?.template?.uuid]
        ?.filter((subTask) => subTask.entity.uuid === parent.entity.uuid)
        ?.map((task, i, arr) => {
          const isLast = i === arr.length - 1;

          return {
            ...task,
            isSubTask: true,
            isLast,
          };
        });

      return {
        ...parent,
        subRows: subTasks || [],
        toggleAllRowsExpanded: true,
      };
    });

    const subTaskIds = tasksAndTheirSubtasks.flatMap((t) =>
      t.subRows.map((st) => st.uuid)
    );

    return tasksAndTheirSubtasks.filter(
      (task) => !subTaskIds.includes(task.uuid)
    );
  }, [taskList]);

  const columnHelper = createColumnHelper<
    Task & { isLast?: boolean; subRows?: Task[] }
  >();

  const formatDescription = ({
    base_task_key,
    season,
    from_date,
    to_date,
  }: {
    base_task_key: string | undefined;
    season: string | undefined;
    from_date: string | undefined;
    to_date: string | undefined;
  }) => {
    if (base_task_key === PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING) {
      return season;
    } else if (from_date && to_date) {
      return `${formatDate(from_date)} to ${formatDate(to_date)}`;
    } else {
      return `${formatTimeToMonthYear(from_date)}`;
    }
  };

  useEffect(() => {
    if (
      ongoing_count !== undefined &&
      count !== undefined &&
      completed_count !== undefined &&
      archived_count !== undefined
    ) {
      dispatch(setTaskCount({ ongoing_count, count, completed_count }));
    }
  }, [ongoing_count, count, completed_count, archived_count, data]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("title", {
        header: "Name",
        size: 40,
        cell: (info) => {
          const { row } = info;
          const value = info.getValue();

          const {
            original: { is_archived, uuid, isLast },
          } = row;

          // Checks the subrow id to see if there is only one subtask
          // Row id would be something like 2.0
          const hasSingleSubtask = row.id.split(".")[1] === "0";
          let taskRedirectUrl = `${parentRoute}/${uuid}`;

          if (isCustomer && addEntityInUrl) {
            taskRedirectUrl += `?entity=${entityId}`;
          }

          if (!isCustomer) {
            taskRedirectUrl += addEntityInUrl
              ? `?company=${groupId}&entity=${entityId}`
              : `?company=${groupId}`;
          }

          return (
            <div
              className="t-relative t-flex t-gap-1"
              style={{
                paddingLeft: `${row.depth}rem`,
              }}
            >
              <span>
                {row.depth > 0 ? (
                  <>
                    <img src={ArrowBend} alt="Subtask" />
                    <img
                      className={classNames("t-absolute t-left-[16.8px] ", {
                        "t-top-[-64px]": isLast,
                        "-t-top-[20px]": !isLast,
                        "t-hidden": row?.original?.isLast && hasSingleSubtask,
                      })}
                      src={VerticalLine}
                      alt="Subtask"
                    />
                  </>
                ) : null}
              </span>
              <span className="t-flex t-items-center t-gap-2 t-pr-3 t-text-subtitle-sm t-font-medium t-text-text-100 t-group">
                <span
                  className="t-line-clamp-2 hover:t-underline t-text-purple"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <ConditionalLink
                    to={taskRedirectUrl}
                    target="_blank"
                    className="t-text-purple hover:t-text-purple"
                  >
                    <HighlightSearchTerm
                      text={value}
                      searchTerm={search || ""}
                    />
                  </ConditionalLink>
                </span>
                {is_archived && (
                  <span className="t-rounded t-bg-neutral-10 t-px-2 t-py-1 t-text-neutral-90">
                    A
                  </span>
                )}
                <img
                  src={Redirect}
                  alt="redirect"
                  className="group-hover:!t-visible t-invisible t-h-4 t-w-4"
                />
              </span>
            </div>
          );
        },
      }),

      columnHelper.accessor("display_text", {
        header: "Description",
        size: 30,
        cell: (info) => {
          const rowData = info.row.original;
          const { from_date, to_date, season, base_task_key } = rowData || {};
          return (
            <div className="t-text-body-sm">
              {formatDescription({ base_task_key, season, from_date, to_date })}
            </div>
          );
        },
      }),

      columnHelper.accessor("status", {
        header: "Status",
        size: 30,
        cell: (info) => {
          const { row } = info;

          if (isCpa || isAdmin || isForeignCA) {
            const stateType = row?.original?.state_details?.type;
            const isStatusFinal =
              stateType === SUBMITTED_TYPE ||
              stateType === TASK_AMENDMENT_COMPLETED;
            const isStatusInitial =
              (stateType === FORM_FILING_TYPE ||
                row?.original?.state === null ||
                stateType === TASK_AMENDMENT) &&
              stateType !== SUBMITTED_TYPE &&
              stateType !== TASK_AMENDMENT_COMPLETED;
            const isStatusInprogress =
              stateType !== FORM_FILING_TYPE &&
              row?.original?.state !== null &&
              stateType !== SUBMITTED_TYPE &&
              stateType !== TASK_AMENDMENT_COMPLETED &&
              stateType !== TASK_AMENDMENT;

            return (
              <span
                className={classNames(
                  "t-text-body-sm t-px-2 t-py-1 t-rounded",
                  {
                    statusBoxFinal: isStatusFinal,
                    statusBoxInitial: isStatusInitial,
                    statusBoxInProgress: isStatusInprogress,
                  }
                )}
              >
                {/* @ts-ignore */}
                {row.original.state || "Not started"}
              </span>
            );
          }
          return (
            <Badge
              type={
                info.getValue() === "Action required"
                  ? "ACTION_REQUIRED"
                  : // @ts-ignore
                    TASK_STATUS[info.getValue()]?.toString()
              }
            >
              {info.getValue() || "-"}
            </Badge>
          );
        },
      }),
    ],
    [isTaskLoading, search]
  );

  const table = useReactTable({
    columns,
    autoResetExpanded: false,
    data,
    getSubRows: (row) => row.subRows,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      expanded: true,
    },
    enableExpanding: true,
    defaultColumn: {
      size: 10,
      minSize: 5,
    },
  });

  if (isTaskLoading) {
    return (
      <div className="t-w-full">
        <Loader />;
      </div>
    );
  }

  // Returns styles for rows with subtasks
  const setRowStyles = (
    row: Row<
      Task & {
        isLast?: boolean | undefined;
        isSubTask?: boolean | undefined;
      }
    >
  ) => {
    if (row.original?.isLast) {
      return "";
    }

    if (row.subRows.length || row.original?.isSubTask) {
      return "t-border-b-0";
    }
    return "";
  };

  return (
    <div className="t-flex t-h-full t-gap-2 t-w-full t-pt-4">
      <Table.Container>
        <Table.Content className="t-w-full t-overflow-auto">
          <Table.Head className="t-inline-table t-w-full t-bg-neutral-10">
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <Table.HeadCell
                    key={header.id}
                    style={{ width: `${header.getSize()}%` }}
                    className="t-py-4 t-px-3 t-uppercase t-text-subtext-sm"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </Table.HeadCell>
                ))}
              </Table.Row>
            ))}
          </Table.Head>

          {noTaskFound ? (
            <div className="t-flex t-h-[calc(100vh-260px)] t-flex-col t-items-center t-justify-center t-gap-4 t-text-subtitle-sm t-font-medium t-text-text-30">
              <img src={Search} alt="No task found" />
              Search result not found
            </div>
          ) : (
            <Table.Body className="all:unset t-inline-table t-w-full">
              {table.getRowModel().rows.map((row) => (
                <Table.Row
                  onRowClick={() => setTaskId(row.original.uuid)}
                  key={row.id}
                  className={`t-items-center t-justify-center t-gap-6 t-border-b t-border-r-0 t-border-t-0 t-border-solid t-border-i-neutral-10 t-border-l-surface-transparent hover:t-bg-purple-0 t-h-max ${setRowStyles(
                    row
                  )}`}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell
                      key={cell.id}
                      style={{ width: `${cell.column.getSize()}%` }}
                      className="t-align-middle t-px-3 t-py-2"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          )}
        </Table.Content>
      </Table.Container>

      <TaskSlider
        taskId={taskId}
        onClose={() => setTaskId(null)}
        isOpen={Boolean(taskId)}
      />
    </div>
  );
};
