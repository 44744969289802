import DashboardContainer from "components/dashboard/DashboardContainer";
import { TableUI } from "components/design/TableUI";
import {
  CellContext,
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";

import ThreeDotsIcon from "../../static/images/ThreeDots.svg";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Button } from "components/DesignSystem/Button/Button";
import {
  forwardRef,
  Ref,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import Modal from "components/DesignSystem/Modal/Modal";
import { SetReminder } from "components/SetReminder/SetReminder";
import { FormikForm } from "components/FormikForm/FormikForm";
import { usePagination } from "hooks/usePagination";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import {
  ReminderSetting,
  ReminderSettingPayload,
  useGetAllRemindersQuery,
  useUpdateReminderSettingMutation,
  useGetUpcomingRemindersQuery,
  useUpdateReminderMutation,
  Reminder,
  ReminderPayload,
  useSendReminderMutation,
} from "store/apis/reminders";
import {
  EVERY_3_DAYS,
  EVERY_5_DAYS,
  EVERY_7_DAYS,
} from "constants/reminderFrequencies";
import { ONE_TIME } from "constants/billing";
import { Tag } from "components/DesignSystem/Tag/Tag";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { formatDate } from "utils/formatDate";
import { useGetAllChannelMemberQuery } from "store/apis/chat";
import { reminderSettingValidation } from "formValidations/reminderSettingValidation";
import { upcomingReminderValidation } from "formValidations/upcomingReminderValidation";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { produce } from "immer";
import ToolTip from "components/design/toolTip";
import { CheckOutline } from "components/icons/CheckOutline";
import { CrossIcon } from "components/icons/CrossIcon";
import { useToast } from "hooks/useToast";
import { useModal } from "hooks/useModal";
import { Info } from "components/icons/Info";
import { EmptyReminders } from "components/Illustrations/EmptyReminders";
import { ChatReminderSlider } from "components/ChatReminderSlider/ChatReminderSlider";
import { ChatAlertSlider } from "components/ChatReminderSlider/ChatAlertSlider";
import { BackendError } from "types/utils/error";
import { ConditionalLink } from "components/conditionalLink";
import { useFilters } from "hooks/useFilter";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import { MultiGroupFilter } from "components/Filters/MultiGroupFilter";
import dayjs from "dayjs";
import { Chip } from "components/DesignSystem/Chips/Chips";
import { Chat } from "components/icons/LeftNav/Chat";
import { useGetServiceTeamQuery } from "store/apis/serviceTeam";
import { NO_ASSGINEE_KEY, NO_ASSIGNEE } from "constants/agent";
import authContext from "jwt_context&axios/authContext";

const createColumn = createColumnHelper<ReminderSetting>();
const createReminderColumn = createColumnHelper<Reminder>();

export const FREQUENCY_MAP = {
  [EVERY_3_DAYS]: "Every 3 days",
  [EVERY_5_DAYS]: "Every 5 days",
  [EVERY_7_DAYS]: "Every 7 days",
  [ONE_TIME]: "Send once",
};

export const STATUS_MAP = {
  DRAFT: "Scheduled",
  SKIPPED: "Skipped",
  SENT: "Sent",
};

const EditUpcomingReminder = forwardRef(
  (
    {
      reminder,
      onClose,
      onSubmit,
      isLoading,
      ...rest
    }: {
      reminder: Reminder;
      onClose: () => void;
      isLoading: boolean;
      onSubmit: (
        values: ReminderPayload["reminder_notification_details"]
      ) => void;
    },
    ref: Ref<HTMLFormElement>
  ) => {
    const channelId = reminder.reminder_setting.content_details.channel_id;
    const groupId = reminder.reminder_setting.company_group.uuid;

    const { data: members, isLoading: isMembersLoading } =
      useGetAllChannelMemberQuery(
        {
          groupId: groupId,
          channelId: channelId,
        },
        {
          skip: !channelId || !groupId,
        }
      );

    const initialValues = {
      message_text: reminder.reminder.message_text,
      notification_channels: reminder.reminder.notification_channels,
      subscribers: reminder.reminder.subscribers.map((s) => s.uuid),
    };

    const memberOptions = members
      ?.filter(({ mobile }) => Boolean(mobile))
      .map(({ mobile, name, profile_id }) => ({
        label: name!,
        value: profile_id,
      }));

    return (
      <FormikForm
        ref={ref}
        initialValues={initialValues}
        // @ts-ignore
        onSubmit={onSubmit}
        validationSchema={upcomingReminderValidation}
        {...rest}
      >
        {({ values: valuesUntyped, setFieldValue }) => {
          const values = valuesUntyped as typeof initialValues;

          const selectedMembers =
            values.subscribers.length > 0 &&
            memberOptions?.filter(({ value }) =>
              values.subscribers.includes(value)
            );

          return (
            <>
              <Modal.Header>
                <Modal.Title>Edit Reminder</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <div className="t-flex t-flex-col t-gap-4">
                  <TextArea
                    note={
                      <span className="t-flex t-gap-1 t-items-center">
                        <Info stroke="1.4" size="12" />
                        <span>
                          Editing this message will not update the future
                          reminder messages
                        </span>
                      </span>
                    }
                    name="message_text"
                    label="Message"
                  />
                  <div className="t-flex t-flex-col t-gap-3">
                    <label className="t-text-body-sm">Send reminders to</label>

                    <div className="t-flex t-gap-4">
                      <Checkbox
                        checked={values.notification_channels.includes("CHAT")}
                        disabled
                        label="Chat"
                      />

                      <Checkbox
                        name="SEND_TO_WHATSAPP"
                        onChange={(e) => {
                          const channels = new Set(
                            values.notification_channels
                          );

                          if (e.target.checked) {
                            channels.add("WHATSAPP");
                            return setFieldValue(
                              "notification_channels",
                              Array.from(channels)
                            );
                          }

                          channels.delete("WHATSAPP");
                          return setFieldValue(
                            "notification_channels",
                            Array.from(channels)
                          );
                        }}
                        checked={values.notification_channels.includes(
                          "WHATSAPP"
                        )}
                        label="WhatsApp"
                      />
                    </div>
                  </div>

                  {values.notification_channels.includes("WHATSAPP") && (
                    <Combobox
                      withForm
                      isLoading={isMembersLoading}
                      isMulti
                      name="subscribers"
                      label="Send to"
                      options={memberOptions}
                      value={selectedMembers || null}
                      menuPortalTarget={document.body}
                    />
                  )}
                </div>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Button onClick={onClose}>Cancel</Button>
                <Button
                  customType="primary"
                  type="submit"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Save
                </Button>
              </Modal.FooterButtonGroup>
            </>
          );
        }}
      </FormikForm>
    );
  }
);

const UpcomingActions = (cell: CellContext<Reminder, unknown>) => {
  const [updateReminder, { isLoading: isUpdating }] =
    useUpdateReminderMutation();
  const [sendReminder, { isLoading: isSending }] = useSendReminderMutation();

  const skipConfirmationModal = useModal();
  const sendConfirmationModal = useModal();

  const currentValues = {
    message_text: cell.row.original.reminder.message_text,
    skip_message: cell.row.original.reminder.status === "SKIPPED",
    notification_channels:
      cell.row.original.reminder_setting.notification_channel,
    subscribers: cell.row.original.reminder_setting.subscribed_profiles.map(
      (s) => s.uuid
    ),
  };

  const { successToast, alertToast } = useToast();

  const onSkipConfirm = async () => {
    try {
      await updateReminder({
        groupId: cell.row.original.reminder_setting.company_group.uuid,
        reminderId: cell.row.original.reminder.uuid,
        payload: {
          reminder_notification_details: {
            ...currentValues,
            skip_message: true,
          },
        },
      }).unwrap();
      skipConfirmationModal.close();
      successToast({ message: "Reminder skipped" });
    } catch (error) {
      alertToast({ message: "Failed to skip reminder" });
    }
  };

  const onSendConfirm = async () => {
    try {
      await sendReminder({
        groupId: cell.row.original.reminder_setting.company_group.uuid,
        reminderId: cell.row.original.reminder.uuid,
      });
      sendConfirmationModal.close();
      successToast({ message: "Reminder sent" });
    } catch (error) {
      alertToast({ message: "Failed to send reminder" });
    }
  };

  return (
    <div className="t-flex t-gap-1" onClick={(e) => e.stopPropagation()}>
      <ToolTip text="Send">
        <Button
          customType="icon"
          size="small"
          onClick={sendConfirmationModal.open}
        >
          <CheckOutline />
        </Button>
      </ToolTip>

      <ToolTip text="Skip">
        <Button
          customType="icon"
          size="small"
          onClick={skipConfirmationModal.open}
        >
          <CrossIcon />
        </Button>
      </ToolTip>

      <Modal.Root
        open={skipConfirmationModal.isOpen}
        onOpenChange={skipConfirmationModal.close}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Skip this reminder?</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            This alert will be skipped, but future reminders will continue as
            scheduled.
          </Modal.Body>

          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button>Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="primary"
              onClick={onSkipConfirm}
              isLoading={isUpdating}
              disabled={isUpdating}
            >
              Skip
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>

      <Modal.Root
        open={sendConfirmationModal.isOpen}
        onOpenChange={sendConfirmationModal.close}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Send this reminder?</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            This reminder will be sent in the selected channel and to all added
            recipients.
          </Modal.Body>

          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button>Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="primary"
              onClick={onSendConfirm}
              isLoading={isSending}
              disabled={isSending}
            >
              Send
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};

const UpcomingReminders = () => {
  const [selectedReminder, setSelectedReminder] = useState<Reminder | null>(
    null
  );
  const [updateReminder, { isLoading: isUpdating }] =
    useUpdateReminderMutation();
  const { successToast, alertToast } = useToast();
  const [selectedReminderForSliderId, setSelectedReminderForSliderId] =
    useState<string | null>(null);
  const editModal = useModal();
  const { authtoken } = useContext(authContext);
  const [updateReminderSetting, { isLoading: isUpdatingSetting }] =
    useUpdateReminderSettingMutation();
  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      STATUS: [] as string[],
      GROUPS: [] as string[],
      CREATED_BY: [authtoken?.uuid] as string[],
    },
  });
  const [tabFromParent, setTabFromParent] = useState<"DETAILS" | "CHAT">(
    "DETAILS"
  );
  const { data: serviceTeam = [] } = useGetServiceTeamQuery({}, {});
  const agents = [...serviceTeam, { name: NO_ASSIGNEE, uuid: NO_ASSGINEE_KEY }];

  const { goToFirstPage, goToPrevPage, goToNextPage, goToLastPage, pageNum } =
    usePagination();

  const { data, isLoading, isSuccess } = useGetUpcomingRemindersQuery({
    page_num: pageNum,
    reminder_status:
      filterValues.STATUS.length === 0
        ? "DRAFT"
        : filterValues.STATUS.join(","),
    company_groups: filterValues.GROUPS.join(","),
    created_by: filterValues.CREATED_BY.join(","),
  });

  const {
    total_pages = 0,
    total_count = 0,
    current_page = 1,
    per_page = 25,
  } = data || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const columns = useMemo(
    () => [
      createReminderColumn.accessor("reminder.recurring_date", {
        header: "Name",
        size: 20,
        cell: (cell) => {
          const { reminder_setting } = cell.row.original;
          return (
            <div className="t-flex t-flex-col t-gap-1">
              <div
                onClick={(e) => e.stopPropagation()}
                className="t-truncate t-max-w-full t-block t-w-fit t-text-text-100 t-text-body"
              >
                {reminder_setting?.content_details?.reminder_name || "-"}
              </div>
              <div className="t-flex t-gap-1 t-items-center t-text-text-30 t-text-body-sm">
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="t-truncate t-max-w-full t-block t-w-fit"
                >
                  {reminder_setting?.company_group?.name}
                </div>
                <div>|</div>
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="t-truncate t-max-w-full t-block t-w-fit"
                >
                  {reminder_setting?.content_details?.channel_name}
                </div>
              </div>
            </div>
          );
        },
      }),
      createReminderColumn.accessor("reminder.recurring_date", {
        header: "SCHEDULED FOR",
        size: 13,
        cell: (cell) => <div>{formatDate(cell.getValue())}</div>,
      }),
      createReminderColumn.accessor("reminder.subscribers", {
        header: "Whatsapp Recipients",
        size: 15,
        cell: (cell) => {
          const recipients = cell.getValue();

          return (
            <ToolTip
              text={recipients.map((r) => (
                <div key={r.email}>{r.name}</div>
              ))}
            >
              <div className="t-flex t-gap-1 t-flex-wrap">
                {recipients.toSpliced(2).map((p) => (
                  <Tag tagType="gray" icon={false} key={p.uuid} size="small">
                    {p.name}
                  </Tag>
                ))}

                <span className="t-text-subtext t-text-text-30">
                  {recipients.length > 2 && `+ ${recipients.length - 2}`}
                </span>

                {cell.getValue().length === 0 && "-"}
              </div>
            </ToolTip>
          );
        },
      }),
      createReminderColumn.accessor("reminder.notification_channels", {
        header: "Channels",
        size: 12,
        cell: (cell) => (
          <div className="t-flex t-gap-1 t-flex-wrap">
            {cell.getValue().map((channel: string) => (
              <Tag key={channel} tagType="gray" icon={false} size="small">
                {channel}
              </Tag>
            ))}
          </div>
        ),
      }),
      createReminderColumn.accessor("reminder.status", {
        header: "Status",
        size: 12,
        cell: (info) => {
          const isOverdue = dayjs(
            info.row.original.reminder.recurring_date
          ).isBefore(dayjs());
          return (
            <div>
              <Tag icon={false} tagType={isOverdue ? "red" : "purple"}>
                {isOverdue ? "Overdue" : STATUS_MAP[info.getValue()]}
              </Tag>
            </div>
          );
        },
      }),
      createReminderColumn.display({
        header: "Actions",
        size: 10,
        cell: UpcomingActions,
      }),
      createReminderColumn.accessor("reminder.uuid", {
        id: "actions",
        header: "",
        size: 10,
        cell: (info) => (
          <div className="t-flex t-gap-1 t-items-center">
            <Button
              customType="transparent"
              onClick={() => setTabFromParent("CHAT")}
            >
              <Chat />
            </Button>
            <section onClick={(e) => e.stopPropagation()}>
              <Dropdown.Root>
                <Dropdown.Trigger asChild>
                  <Button customType="ghost_icon" size="small">
                    <img src={ThreeDotsIcon} alt="Three dots" />
                  </Button>
                </Dropdown.Trigger>
                <Dropdown.Content align="end">
                  <Dropdown.Item
                    onSelect={() => setSelectedReminder(info.row.original)}
                  >
                    Edit
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown.Root>
            </section>
          </div>
        ),
      }),
    ],
    []
  );

  const toggleReminder = useCallback(
    async (reminderId: string) => {
      const editingReminder = data?.reminders?.find(
        (d) => d.reminder.uuid === reminderId
      )?.reminder_setting;

      if (!editingReminder) return;

      const values: ReminderSettingPayload = {
        reminder_details: {
          frequency: editingReminder?.frequency,
          is_reminder_enabled: false,
          notification_channels: editingReminder?.notification_channel || [],
          on_date: editingReminder.on_date,
          subscribers:
            editingReminder?.subscribed_profiles.map(
              (profile) => profile.uuid
            ) || [],
          group_id: editingReminder?.company_group.uuid || "",
        },
        content_details: {
          message_id: editingReminder?.content_details.message_id || "",
          message_text: editingReminder?.content_details.message_text || "",
          content_type: editingReminder?.content_details.content_type || "",
          channel_id: editingReminder?.content_details.channel_id || "",
          reminder_name: editingReminder?.content_details.reminder_name || "",
        },
      };

      try {
        if (!editingReminder) return;

        const payload = produce(values as ReminderSettingPayload, (draft) => {
          draft.reminder_details.is_reminder_enabled = false;
        });

        await updateReminderSetting({
          groupId: editingReminder.company_group.uuid,
          reminderId: editingReminder.reminder_setting_id,
          payload,
        }).unwrap();

        editModal.close();

        if (!payload.reminder_details.is_reminder_enabled) {
          successToast({ message: "Reminder stopped" });
          setSelectedReminderForSliderId(null);
        }
      } catch (error) {
        alertToast({ message: (error as BackendError)?.data?.error?.message });
      }
      editModal.close();
    },
    [data?.reminders, updateReminderSetting]
  );

  const handleUpdateReminder = async (
    values: ReminderPayload["reminder_notification_details"]
  ) => {
    if (!selectedReminder) return;

    try {
      await updateReminder({
        groupId: selectedReminder.reminder_setting.company_group.uuid,
        reminderId: selectedReminder.reminder.uuid,
        payload: {
          reminder_notification_details: values,
        },
      }).unwrap();
      setSelectedReminder(null);
      successToast({ message: "Reminder updated" });
    } catch (error) {
      console.error("Failed to update reminder:", error);
      alertToast({ message: "Failed to update reminder" });
    }
  };

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 10,
    },
    data: data?.reminders || [],
  });

  const selectedReminderForSlider = data?.reminders.find(
    (reminder) => reminder.reminder.uuid === selectedReminderForSliderId
  );
  const setSelectedReminderSliderId = (value: string | null) => {
    setTabFromParent("DETAILS");
    setSelectedReminderForSliderId(value);
  };

  const isCreatedByMe =
    filterValues?.CREATED_BY?.length === 1 &&
    filterValues?.CREATED_BY?.includes(authtoken?.uuid);

  const isCreatedByFilterApplied = filterValues?.CREATED_BY?.length > 0;

  const createdByFilterChipContent =
    isCreatedByMe || !isCreatedByFilterApplied
      ? "Created by me"
      : `Created by (${filterValues?.CREATED_BY?.length})`;

  return (
    <div>
      <div className="t-flex t-gap-4 t-items-center t-justify-between t-mt-4">
        <Filter.Root
          defaultValue="GROUPS"
          capsule={
            <>
              <Chip
                filterType="CREATED_BY"
                isFixedFilter
                onClose={() => updateFilter("CREATED_BY", [])}
                isActive={filterValues.CREATED_BY?.length > 0}
                onClick={() =>
                  updateFilter(
                    "CREATED_BY",
                    filterValues.CREATED_BY?.includes(authtoken?.uuid)
                      ? []
                      : [authtoken?.uuid]
                  )
                }
              >
                {createdByFilterChipContent}
              </Chip>
              {filterValues?.GROUPS.length > 0 && (
                <Chip
                  onClose={() => updateFilter("GROUPS", [])}
                  isActive
                  filterType="DATE"
                >
                  Company group ({filterValues.GROUPS.length})
                </Chip>
              )}

              {filterValues?.STATUS.length > 0 && (
                <Chip
                  onClose={() => updateFilter("STATUS", [])}
                  isActive
                  filterType="DATE"
                >
                  Status ({filterValues.STATUS.length})
                </Chip>
              )}
            </>
          }
        >
          <Filter.Portal>
            <Filter.List>
              <Filter.ListItem value="GROUPS">Company Group</Filter.ListItem>
              <Filter.ListItem value="STATUS">Status</Filter.ListItem>
              <Filter.ListItem value="CREATED_BY">Created by</Filter.ListItem>
            </Filter.List>
            <Filter.Body value="STATUS">
              <MultiSelectFilter
                isSearchable={false}
                onChange={(e) => updateFilter("STATUS", e)}
                options={[
                  { label: "Scheduled", value: "SCHEDULED" },
                  { label: "Overdue", value: "OVERDUE" },
                ]}
                selected={filterValues.STATUS}
              />
            </Filter.Body>
            <Filter.Body value="GROUPS" block>
              <MultiGroupFilter
                updateFilter={updateFilter}
                values={filterValues}
              />
            </Filter.Body>
            <Filter.Body value="CREATED_BY" block>
              <MultiSelectFilter
                onChange={(value) => {
                  updateFilter("CREATED_BY", value);
                }}
                options={agents.map(({ name, uuid }) => ({
                  value: uuid,
                  label: uuid === authtoken?.uuid ? "Me" : name,
                }))}
                selected={filterValues.CREATED_BY}
              />
            </Filter.Body>
          </Filter.Portal>
        </Filter.Root>
        <Pagination
          {...paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>

      <Async.Root
        isLoading={isLoading}
        isEmpty={data?.reminders.length === 0}
        isSuccess={isSuccess}
      >
        <Async.Empty>
          <div className="t-h-full t-w-full t-flex t-justify-center t-items-center">
            <div className="t-flex t-gap-10 t-flex-col t-items-center">
              <EmptyReminders />
              <span className="t-h-5 t-text-text-30">
                No Upcoming Reminders
              </span>
            </div>
          </div>
        </Async.Empty>
        <Async.Success>
          <DashboardContainer.Header>
            <div className="t-flex t-flex-col t-gap-3 t-mb-5"></div>
          </DashboardContainer.Header>
          <div className="t-mt-4">
            <TableUI
              layout="fixed"
              table={table}
              onRowClick={(row) =>
                setSelectedReminderForSliderId(row.original.reminder.uuid)
              }
            />
            {selectedReminderForSliderId && selectedReminderForSlider && (
              <ChatAlertSlider
                selectedReminder={selectedReminderForSlider}
                setSelectedReminder={setSelectedReminderSliderId}
                setEditReminder={setSelectedReminder}
                toggleReminder={() =>
                  toggleReminder(selectedReminderForSliderId)
                }
                isUpdating={isUpdatingSetting}
                {...editModal}
                tabFromParent={tabFromParent}
              />
            )}
            <Modal.Root
              open={selectedReminder !== null}
              onOpenChange={() => setSelectedReminder(null)}
            >
              <Modal.Content asChild>
                {selectedReminder && (
                  <EditUpcomingReminder
                    reminder={selectedReminder}
                    onClose={() => setSelectedReminder(null)}
                    onSubmit={handleUpdateReminder}
                    isLoading={isUpdating}
                  />
                )}
              </Modal.Content>
            </Modal.Root>
          </div>
        </Async.Success>
      </Async.Root>
    </div>
  );
};

const AllReminders = () => {
  const [editReminder, setEditReminder] = useState<string | null>(null);
  const { successToast } = useToast();
  const [selectedReminderId, setSelectedReminderId] = useState<string | null>(
    null
  );
  const [tabFromParent, setTabFromParent] = useState<"DETAILS" | "CHAT">(
    "DETAILS"
  );

  const { data: serviceTeam = [] } = useGetServiceTeamQuery({}, {});
  const agents = [...serviceTeam, { name: NO_ASSIGNEE, uuid: NO_ASSGINEE_KEY }];
  const { authtoken } = useContext(authContext);

  const editModal = useModal();
  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      STATUS: [] as string[],
      GROUPS: [] as string[],
      CREATED_BY: [authtoken?.uuid] as string[],
    },
  });

  const { goToFirstPage, goToPrevPage, goToNextPage, goToLastPage, pageNum } =
    usePagination();

  let status;

  if (filterValues.STATUS.length > 0) {
    status = filterValues.STATUS.includes("ACTIVE") ? "ACTIVE" : "INACTIVE";
  }

  if (filterValues.STATUS.length === 2) {
    status = undefined;
  }

  const { data, isLoading, isSuccess } = useGetAllRemindersQuery({
    page_num: pageNum,
    reminder_status: status,
    company_groups: filterValues.GROUPS.join(","),
    created_by: filterValues.CREATED_BY.join(","),
  });

  const currentReminder = data?.reminders?.find(
    (d) => d.reminder_setting_id === editReminder
  );

  const [updateReminderSetting, { isLoading: isUpdating }] =
    useUpdateReminderSettingMutation();

  const onSubmit = async (values: ReminderSettingPayload) => {
    try {
      if (!currentReminder) return;

      await updateReminderSetting({
        groupId: currentReminder.company_group.uuid,
        reminderId: currentReminder.reminder_setting_id,
        payload: values,
      }).unwrap();

      setEditReminder(null);
    } catch (error) {
      console.error("Failed to update reminder:", error);
    }
  };

  const initialValue: ReminderSettingPayload | {} = currentReminder
    ? {
        uuid: currentReminder?.reminder_setting_id || "",
        reminder_details: {
          frequency: currentReminder?.frequency,
          is_reminder_enabled: currentReminder?.is_reminder_enabled || false,
          notification_channels: currentReminder?.notification_channel || [],
          on_date: currentReminder.on_date,
          subscribers:
            currentReminder?.subscribed_profiles.map(
              (profile) => profile.uuid
            ) || [],
          group_id: currentReminder?.company_group.uuid || "",
        },
        content_details: {
          message_id: currentReminder?.content_details.message_id || "",
          message_text: currentReminder?.content_details.message_text || "",
          content_type: currentReminder?.content_details.content_type || "",
          channel_id: currentReminder?.content_details.channel_id || "",
        },
      }
    : {};

  const toggleReminder = useCallback(
    async (reminderId: string) => {
      const editingReminder = data?.reminders?.find(
        (d) => d.reminder_setting_id === reminderId
      );

      if (!editingReminder) return;

      const values: ReminderSettingPayload = {
        reminder_details: {
          frequency: editingReminder?.frequency,
          is_reminder_enabled: editingReminder?.is_reminder_enabled || false,
          notification_channels: editingReminder?.notification_channel || [],
          on_date: editingReminder.on_date,
          subscribers:
            editingReminder?.subscribed_profiles.map(
              (profile) => profile.uuid
            ) || [],
          group_id: editingReminder?.company_group.uuid || "",
        },
        content_details: {
          message_id: editingReminder?.content_details.message_id || "",
          message_text: editingReminder?.content_details.message_text || "",
          content_type: editingReminder?.content_details.content_type || "",
          channel_id: editingReminder?.content_details.channel_id || "",
          reminder_name: editingReminder?.content_details.reminder_name || "",
        },
      };

      try {
        if (!editingReminder) return;

        const payload = produce(values as ReminderSettingPayload, (draft) => {
          draft.reminder_details.is_reminder_enabled =
            !draft.reminder_details.is_reminder_enabled;
        });

        await updateReminderSetting({
          groupId: editingReminder.company_group.uuid,
          reminderId: editingReminder.reminder_setting_id,
          payload,
        }).unwrap();

        setEditReminder(null);

        if (!payload.reminder_details.is_reminder_enabled) {
          successToast({ message: "Reminder stopped" });
        }
      } catch (error) {
        console.error("Failed to update reminder:", error);
      }
      editModal.close();
    },
    [data?.reminders, updateReminderSetting]
  );

  const columns = useMemo(
    () => [
      createColumn.accessor("content_details.reminder_name", {
        header: "Name",
        size: 20,
        cell: (cell) => {
          const { company_group, content_details } = cell.row.original;
          return (
            <div className="t-flex t-flex-col t-gap-1">
              <div
                onClick={(e) => e.stopPropagation()}
                className="t-truncate t-max-w-full t-block t-w-fit t-text-text-100 t-text-body"
              >
                {content_details?.reminder_name || "-"}
              </div>
              <div className="t-flex t-gap-1 t-items-center t-text-text-30 t-text-body-sm">
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="t-truncate t-max-w-full t-block t-w-fit"
                >
                  {company_group?.name}
                </div>
                <div>|</div>
                <div
                  onClick={(e) => e.stopPropagation()}
                  className="t-truncate t-max-w-full t-block t-w-fit"
                >
                  {content_details?.channel_name}
                </div>
              </div>
            </div>
          );
        },
      }),

      createColumn.accessor("company_group.uuid", {
        header: "Company Group",
        size: 10,
        cell: (cell) => {
          const link = `/admin/crm/${cell.getValue()}`;

          return (
            <span
              onClick={(e) => e.stopPropagation()}
              className="t-truncate t-max-w-full t-block t-w-fit"
            >
              <ConditionalLink to={link} target="_blank">
                <span className="hover:t-underline !t-text-purple">
                  {cell.row.original.company_group.name}
                </span>
              </ConditionalLink>
            </span>
          );
        },
      }),

      createColumn.accessor("frequency", {
        header: "Frequency",
        size: 15,
        cell: (cell) => (
          <span className="t-flex t-flex-col t-gap-1 t-text-text-60">
            <span>{FREQUENCY_MAP[cell.getValue()]}</span>
            {cell.getValue() === "ONE_TIME" ? (
              <span className="t-text-text-30 t-text-body-sm">
                {formatDate(cell.row.original.on_date)}
              </span>
            ) : (
              <span className="t-text-text-30 t-text-body-sm">
                From {formatDate(cell.row.original.created_at)}
              </span>
            )}
          </span>
        ),
      }),

      createColumn.accessor("subscribed_profiles", {
        header: "Send To",
        size: 15,
        cell: (cell) => (
          <div className="t-flex t-gap-1 t-flex-wrap">
            {cell.getValue().map((p) => (
              <Tag tagType="gray" icon={false} key={p.uuid}>
                {p.name}
              </Tag>
            ))}
            {cell.getValue().length === 0 && "-"}
          </div>
        ),
      }),

      createColumn.accessor("notification_channel", {
        header: "Channels",
        size: 20,
        cell: (cell) => (
          <div className="t-flex t-gap-2 t-flex-wrap">
            {cell.getValue().map((channel: string) => (
              <Tag key={channel} tagType="gray" icon={false} size="small">
                {channel}
              </Tag>
            ))}
          </div>
        ),
      }),

      createColumn.accessor("is_reminder_enabled", {
        header: "Status",
        size: 10,
        cell: (cell) => (
          <div className="t-flex t-gap-2 t-flex-wrap">
            {cell.getValue() ? (
              <Tag tagType="green" icon={false}>
                Active
              </Tag>
            ) : (
              <Tag tagType="red" icon={false}>
                Stopped
              </Tag>
            )}
          </div>
        ),
      }),

      createColumn.accessor("reminder_setting_id", {
        id: "Action",
        header: () => null,
        size: 10,
        cell: (info) => (
          <section className="t-flex t-gap-2 t-items-center">
            <Button
              customType="transparent"
              onClick={() => setTabFromParent("CHAT")}
            >
              <Chat />
            </Button>
            <div onClick={(e) => e.stopPropagation()}>
              <Modal.Root>
                <Dropdown.Root>
                  <Dropdown.Trigger asChild>
                    <Button customType="ghost_icon" size="small">
                      <img src={ThreeDotsIcon} alt="Three dots" />
                    </Button>
                  </Dropdown.Trigger>
                  <Dropdown.Content align="end">
                    <Dropdown.Item
                      onSelect={() => setEditReminder(info.getValue())}
                    >
                      Edit
                    </Dropdown.Item>
                    <Modal.Trigger asChild>
                      <Dropdown.Item>
                        {info.row.original.is_reminder_enabled
                          ? "Stop Reminder"
                          : "Enable Reminder"}
                      </Dropdown.Item>
                    </Modal.Trigger>
                  </Dropdown.Content>
                </Dropdown.Root>
                <Modal.Content>
                  <Modal.Header>
                    <Modal.Title>
                      {info.row.original.is_reminder_enabled
                        ? "Stop"
                        : "Enable"}{" "}
                      this reminder?
                    </Modal.Title>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body>
                    {info.row.original.is_reminder_enabled
                      ? "This action will cancel all scheduled and future alerts associated with this reminder."
                      : "Future alerts for this reminder will resume and be sent to the selected channel and recipients."}
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Modal.RawClose asChild>
                      <Button>Cancel</Button>
                    </Modal.RawClose>
                    <Button
                      customType="primary"
                      onClick={() =>
                        // @ts-ignore
                        info.table.options.meta?.toggleReminder?.(
                          info.row.original.reminder_setting_id
                        )
                      }
                      isLoading={isUpdating}
                      disabled={isUpdating}
                    >
                      {info.row.original.is_reminder_enabled ? "Stop" : "Start"}
                    </Button>
                  </Modal.FooterButtonGroup>
                </Modal.Content>
              </Modal.Root>
            </div>
          </section>
        ),
      }),
    ],
    [isUpdating]
  );
  const {
    reminders = [],
    total_pages = 0,
    total_count = 0,
    current_page = 1,
    per_page = 25,
  } = data || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const table = useReactTable({
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 10,
    },
    data: reminders,
    meta: { toggleReminder },
  });

  const selectedReminder = reminders.find(
    ({ reminder_setting_id }) => reminder_setting_id === selectedReminderId
  );
  const setSelectedReminder = (value: string | null) => {
    setTabFromParent("DETAILS");
    setSelectedReminderId(value);
  };

  const isCreatedByMe =
    filterValues?.CREATED_BY?.length === 1 &&
    filterValues?.CREATED_BY?.includes(authtoken?.uuid);

  const isCreatedByFilterApplied = filterValues?.CREATED_BY?.length > 0;

  const createdByFilterChipContent =
    isCreatedByMe || !isCreatedByFilterApplied
      ? "Created by me"
      : `Created by (${filterValues?.CREATED_BY?.length})`;

  return (
    <div>
      <div className="t-flex t-flex-col t-gap-3 t-mb-5 t-mt-4">
        <div className="t-flex t-gap-4 t-items-center t-justify-between">
          <Filter.Root
            defaultValue="CREATED_BY"
            capsule={
              <Chip
                isFixedFilter
                filterType="CREATED_BY"
                onClose={() => updateFilter("CREATED_BY", [])}
                isActive={filterValues.CREATED_BY?.length > 0}
                onClick={() =>
                  updateFilter(
                    "CREATED_BY",
                    filterValues.CREATED_BY?.includes(authtoken?.uuid)
                      ? []
                      : [authtoken?.uuid]
                  )
                }
              >
                {createdByFilterChipContent}
              </Chip>
            }
          >
            <Filter.Portal>
              <Filter.List>
                <Filter.ListItem value="GROUPS">Company Group</Filter.ListItem>
                <Filter.ListItem value="STATUS">Status</Filter.ListItem>
                <Filter.ListItem value="CREATED_BY">Created by</Filter.ListItem>
              </Filter.List>
              <Filter.Body value="STATUS">
                <MultiSelectFilter
                  isSearchable={false}
                  onChange={(e) => updateFilter("STATUS", e)}
                  options={[
                    { label: "Active", value: "ACTIVE" },
                    { label: "Stopped", value: "INACTIVE" },
                  ]}
                  selected={filterValues.STATUS}
                />
              </Filter.Body>
              <Filter.Body value="GROUPS" block>
                <MultiGroupFilter
                  updateFilter={updateFilter}
                  values={filterValues}
                />
              </Filter.Body>
              <Filter.Body value="CREATED_BY" block>
                <MultiSelectFilter
                  onChange={(value) => {
                    updateFilter("CREATED_BY", value);
                  }}
                  options={agents.map(({ name, uuid }) => ({
                    value: uuid,
                    label: uuid === authtoken?.uuid ? "Me" : name,
                  }))}
                  selected={filterValues.CREATED_BY}
                />
              </Filter.Body>
            </Filter.Portal>
          </Filter.Root>
          <Pagination
            {...paginationData}
            goToFirstPage={goToFirstPage}
            goToPrevPage={goToPrevPage}
            goToNextPage={goToNextPage}
            goToLastPage={goToLastPage}
          />
        </div>
      </div>
      <Async.Root
        isLoading={isLoading}
        isEmpty={reminders.length === 0}
        isSuccess={isSuccess}
      >
        <Async.Empty>
          <div className="t-h-full t-w-full t-flex t-justify-center t-items-center">
            <div className="t-flex t-gap-10 t-flex-col t-items-center">
              <EmptyReminders />
              <span className="t-h-5 t-text-text-30">No Reminders</span>
            </div>
          </div>
        </Async.Empty>
        <Async.Success>
          <TableUI
            table={table}
            layout="fixed"
            onRowClick={(row) =>
              setSelectedReminderId(row.original.reminder_setting_id)
            }
          />
          {selectedReminderId && selectedReminder && (
            <ChatReminderSlider
              selectedReminder={selectedReminder}
              setSelectedReminder={setSelectedReminder}
              setEditReminder={setEditReminder}
              toggleReminder={() => toggleReminder(selectedReminderId)}
              isUpdating={isUpdating}
              {...editModal}
              tabFromParent={tabFromParent}
            />
          )}
          <Modal.Root
            open={editReminder !== null}
            onOpenChange={() => setEditReminder(null)}
          >
            <Modal.Content asChild useCustomOverlay>
              <FormikForm
                initialValues={initialValue}
                // @ts-ignore
                onSubmit={onSubmit}
                validationSchema={reminderSettingValidation}
              >
                {currentReminder && (
                  <SetReminder
                    title="Edit Reminder"
                    channelId={currentReminder?.content_details.channel_id}
                    isLoading={isUpdating}
                    groupId={currentReminder?.company_group.uuid}
                  />
                )}
              </FormikForm>
            </Modal.Content>
          </Modal.Root>
        </Async.Success>
      </Async.Root>
    </div>
  );
};

export const Reminders = () => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { pathname } = useLocation();

  const isReminderSetting = pathname.includes("/reminder-settings");

  return (
    <div className="t-p-5 t-h-full">
      <ToggleGroup value={isReminderSetting ? "REMINDERS" : "UPCOMING"}>
        <ToggleGroupItem value="UPCOMING" onClick={() => history.push(url)}>
          <span className="t-text-nowrap">Upcoming Reminders</span>
        </ToggleGroupItem>
        <ToggleGroupItem
          value="REMINDERS"
          onClick={() => history.push(`${url}/reminder-settings`)}
        >
          <span className="t-text-nowrap">All</span>
        </ToggleGroupItem>
      </ToggleGroup>
      <Switch>
        <Route path={`${url}/reminder-settings`}>
          <AllReminders />
        </Route>
        <Route path={url}>
          <UpcomingReminders />
        </Route>
      </Switch>
    </div>
  );
};
