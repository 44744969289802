import Modal from "components/DesignSystem/Modal/Modal";
import { FC } from "react";
import { Button } from "components/DesignSystem/Button/Button";
import { TaskPriceLedgerTable } from "./TaskPriceLedgerTable";

interface ITaskPriceLedgerProps {
  show: boolean;
  closeModal: () => void;
  taskChangeLogs: {
    created_at: string;
    description: string;
    price: string;
    uuid: string;
  }[];
  finalPrice: string | number;
}

export const TaskPriceLedger: FC<ITaskPriceLedgerProps> = ({
  show,
  closeModal,
  taskChangeLogs = [],
  finalPrice,
}) => {
  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content size="xl">
        <Modal.Header>
          <Modal.Title>Pricing Ledger</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-overflow-auto t-h-[500px] t-gap-8 t-flex t-flex-col t-justify-between">
          <div className="t-h-[calc(100%-64px)] t-overflow-scroll">
            <TaskPriceLedgerTable tableData={taskChangeLogs} />
          </div>
          <div className="t-h-16 t-p-4 t-bg-neutral-0 t-flex t-justify-end t-items-center t-rounded t-gap-5">
            <div className="t-flex t-flex-col t-items-end">
              <div className="t-text-caption t-text-text-100">
                Inkle Pricing
              </div>
              <div className="t-text-subtitle t-text-text-100">
                {isNaN(finalPrice as number) ? (
                  <div className="t-flex t-gap-4 t-items-center">
                    {finalPrice}
                  </div>
                ) : (
                  <div className="t-flex t-gap-4 t-items-center">
                    <>{`$${finalPrice}`}</>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-justify-end t-gap-3">
            <Button customType="primary" onClick={closeModal}>
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
