import { BarCustomLayerProps, ResponsiveBar } from "@nivo/bar";
import ToolTip from "components/design/toolTip";
import {
  BAR_GAP,
  BAR_RADIUS,
  DOT_SIZE,
  EXPENSE_BAR_COLOR,
  EXPENSE_BAR_CROSS_LINE_COLOR,
  GRID_LINE_COLOR,
  LINE_SPACING,
  PROFIT_LOSS_LINE_COLOR,
  REVENUE_BAR_COLOR,
  REVENUE_BAR_CROSS_LINE_COLOR,
} from "constants/booksHomePage";
import { motion } from "framer-motion";
import { ReactNode } from "react";
import { RevenueMetrics } from "store/apis/metrics";
import { formatAmount } from "utils/foramtAmount";
import {
  ChartItemInfo,
  LegendDot,
  MonthYearChartBottomAxisTicks,
} from "../ChartComponents";
import { HideableAmount } from "../HideBalancesAndAmounts";

const InfoToolTip = ({
  data,
  children,
}: {
  data: RevenueMetrics;
  children: ReactNode;
}) => {
  return (
    <ToolTip
      propClass="!t-bg-neutral-100"
      text={
        <div className="t-text-start t-flex t-flex-col t-gap-2 t-text-body-sm">
          <div className="t-text-white">{data.period}</div>
          <ChartItemInfo
            dot={<LegendDot color={REVENUE_BAR_COLOR} />}
            lable="Revenue"
            value={
              <HideableAmount>
                {formatAmount(Number(data.revenue || 0))}
              </HideableAmount>
            }
          />
          <ChartItemInfo
            dot={<LegendDot color={EXPENSE_BAR_COLOR} />}
            lable="Expense"
            value={
              <HideableAmount>
                {formatAmount(Number(data.expense || 0))}
              </HideableAmount>
            }
          />
          <ChartItemInfo
            dot={<LegendDot color={PROFIT_LOSS_LINE_COLOR} />}
            lable={Number(data.profit) < 0 ? "Loss" : "Profit"}
            value={
              <HideableAmount>
                {formatAmount(Number(data.profit || 0))}
              </HideableAmount>
            }
          />
        </div>
      }
    >
      {children}
    </ToolTip>
  );
};

const Bar = (props: BarCustomLayerProps<RevenueMetrics>) => {
  const revenue = props.bars.filter((bar) => bar.data.id === "revenue");
  const expense = props.bars.filter((bar) => bar.data.id === "expense");

  const barwidth = ({
    numberofColumns,
    width,
  }: {
    numberofColumns: number;
    width: number;
  }) => {
    switch (numberofColumns) {
      case 3:
        return width * 0.5;
      case 6:
        return width * 0.6;
      default:
        return width;
    }
  };

  const xPoint = ({
    numberofColumns,
    x,
    width,
  }: {
    numberofColumns: number;
    x: number;
    width: number;
  }) => {
    switch (numberofColumns) {
      case 3:
        return x + width * 0.25;
      case 6:
        return x + width * 0.2;
      default:
        return x;
    }
  };

  return (
    <>
      {/* Bar */}
      {[...revenue, ...expense].map(
        ({ x, y, width, height, color, key, label, data }) => {
          const isRevenue = data.id === "revenue";
          const numberofColumns = isRevenue ? revenue.length : expense.length;

          const responsiveWidth = barwidth({
            numberofColumns,
            width,
          });

          const responsiveX = xPoint({
            numberofColumns,
            x,
            width,
          });

          const finalHeight = height - BAR_GAP;

          const crossLineColor = isRevenue
            ? REVENUE_BAR_CROSS_LINE_COLOR
            : EXPENSE_BAR_CROSS_LINE_COLOR;

          const numberOfLines = Math.floor(height);
          const lines = Array.from(
            { length: numberOfLines },
            (_, index) => index
          );

          return (
            <motion.g
              key={key}
              initial={{ x: responsiveX, y: y }}
              animate={{ x: responsiveX, y: y }}
              transform={`translate(${responsiveX}, ${y})`}
              aria-label={label}
            >
              {/* Clip path for the bar */}
              <defs>
                <clipPath id={`clip-${key}`}>
                  <rect
                    width={responsiveWidth}
                    height={finalHeight}
                    rx={props.borderRadius}
                    ry={props.borderRadius}
                  />
                </clipPath>
              </defs>
              <InfoToolTip data={data.data} key={key}>
                <motion.rect
                  initial={{ height: "100%" }}
                  animate={{
                    height: finalHeight,
                    width: responsiveWidth,
                  }}
                  fill={color}
                  strokeWidth="0"
                  stroke={color}
                  focusable="false"
                  rx={props.borderRadius}
                  ry={props.borderRadius}
                />
              </InfoToolTip>
              {/* Group for diagonal lines with clip path */}
              <g clipPath={`url(#clip-${key})`}>
                {lines.map((_, index, arr) => (
                  <motion.line
                    key={`${key}-line-${index}-${arr.length}`}
                    initial={{ x1: 0, x2: 0, y1: 0, y2: 0 }}
                    animate={{
                      x1: 0,
                      x2: width * 1.5,
                      y1: -0.76,
                      y2: -0.76,
                    }}
                    transition={{
                      delay: 0.1,
                    }}
                    transform={`matrix(0.73343 -0.679765 0.73343 0.679765 -1.45459 ${
                      index * LINE_SPACING + 1.095
                    })`}
                    stroke={crossLineColor}
                    strokeWidth={1.52}
                  />
                ))}
              </g>
            </motion.g>
          );
        }
      )}

      {/* Dotted lines */}
      {[...revenue, ...expense].map(({ x, width, key, label, data }) => {
        const revenuepoints = revenue.find(
          (bar) => bar.data.index === data.index
        );
        const expensepoints = expense.find(
          (bar) => bar.data.index === data.index
        );

        const th = revenuepoints?.height! - expensepoints?.height!;

        const pointsX = x + width / 2;
        const pointsY = expensepoints?.y! - th - 5;

        const x2revenuePoints = revenue.find(
          (bar) => bar.data.index === data.index + 1
        );
        const y2expensepoints = expense.find(
          (bar) => bar.data.index === data.index + 1
        );

        const x2 = x2revenuePoints?.x! + x2revenuePoints?.width! / 2;

        const y2 =
          y2expensepoints?.y! -
          (x2revenuePoints?.height! - y2expensepoints?.height!);

        return (
          <motion.g key={key}>
            <motion.g
              transform={`translate(${pointsX - DOT_SIZE / 2}, ${pointsY})`}
              aria-label={label}
              aria-details={data.value?.toString()}
            >
              <InfoToolTip data={data.data} key={key}>
                <motion.rect
                  fill={PROFIT_LOSS_LINE_COLOR}
                  width={DOT_SIZE}
                  height={DOT_SIZE}
                  key={key}
                  rx={DOT_SIZE / 2}
                  ry={DOT_SIZE / 2}
                  z={2}
                />
              </InfoToolTip>
            </motion.g>
            {x2 && y2 && (
              <motion.line
                strokeDasharray="4 4"
                x1={pointsX}
                y1={pointsY + DOT_SIZE / 2}
                x2={x2}
                y2={y2}
                stroke={PROFIT_LOSS_LINE_COLOR}
                strokeWidth="2"
                z={2}
              />
            )}
          </motion.g>
        );
      })}
    </>
  );
};

const Grid = () => {
  return (
    <g>
      <line
        strokeDasharray="4 4"
        opacity="1"
        x1="10"
        x2="511.5"
        y1="130"
        y2="130"
        stroke={GRID_LINE_COLOR}
        strokeWidth="1"
      />
      <line
        strokeDasharray="4 4"
        opacity="1"
        x1="10"
        x2="511.5"
        y1="-10"
        y2="-10"
        stroke={GRID_LINE_COLOR}
        strokeWidth="1"
      />
    </g>
  );
};

export const BarChart = ({ data }: { data: RevenueMetrics[] }) => {
  return (
    <ResponsiveBar
      padding={0.2}
      borderRadius={BAR_RADIUS}
      data={data}
      keys={["revenue", "expense"]}
      indexBy="period"
      margin={{ bottom: 60, top: 10, left: 0, right: 0 }}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={[REVENUE_BAR_COLOR, EXPENSE_BAR_COLOR]}
      enableGridX={false}
      enableGridY={false}
      axisBottom={{
        renderTick: (props) => (
          <MonthYearChartBottomAxisTicks {...props} data_length={data.length} />
        ),
      }}
      axisLeft={null}
      enableLabel={false}
      layers={[
        Grid,
        "axes",
        Bar,
        "totals",
        "markers",
        "legends",
        "annotations",
      ]}
    />
  );
};
