import classNames from "classnames";
import Loader from "components/design/loader";
import Modal from "components/DesignSystem/Modal/Modal";
import { Search } from "components/DesignSystem/Search/Search";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import NoTaskTemplatesState from "static/images/NoTaskTemplates.svg";
import { useGetBaseTaskTemplatesQuery } from "store/apis/getTemplates";
import { useDebounce } from "utils/debounce";
import "../../../static/styles/components/addTask.css";
import { TaskDetails } from "./TaskDetails";
import { setAddonData, setAddonType } from "store/slices/addons";
import { toggleCartSubscription } from "store/slices/cartSubscription";
import { setServiceActionType } from "store/slices/serviceList";
import {
  AUDIT,
  GROUP_BOOKS_CONSOLIDATION_ONE_TIME,
  ServiceType,
  FINANCIAL_MODELLING,
  GROUP_BOOKS_CONSOLIDATION_RECURRING,
  PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING,
  PRO_MONTHLY_BOOKKEEPING,
  GAAP_FINANCIALS,
  TP_PAYMENTS_ONBOARDING,
  PAN_CARD_APPLICATION,
  US_BOOKKEEPING_ONE_TIME,
  US_BOOKKEEPING_SUBSCRIPTION_PARENT,
} from "types/Models/services";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useDispatch, useSelector } from "react-redux";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { useModal } from "hooks/useModal";
import { ServiceActionModal } from "pages/Books/Services/ServiceListModal/ServiceActionModal";
import { GROUP_BOOKS_CONSOLIDATION } from "constants/addons";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { IntragroupServiceActionModal } from "components/IntragroupServices/IntragroupServiceModal";
import { RootState } from "store/store";
import {
  openIntragroupTask,
  TemplateType,
} from "store/slices/IntragroupService";
import { useToast } from "hooks/useToast";
import { SOMETHING_WENT_WRONG } from "constants/apiCallError";
import { BaseTaskTemplate } from "types/Models/taskTemplate";
import { SalesTaxServiceModal } from "components/SalesTaxService/SalesTaxServiceModal";
import { openSalesTaxTask } from "store/slices/SalesTaxService";
import {
  TRANSFER_PRICING_AUDIT,
  TRANSFER_PRICING_BENCHMARKING_STUDY,
  TRANSFER_PRICING_HEALTH_CHECK,
} from "constants/intragroupServices";
import { SALES_TAX_NEXUS_STUDY } from "constants/salesTax";
import {
  useGetTaskListForServiceTeamQuery,
  useGetTaskListForUserQuery,
} from "store/apis/taskList";
import { BOOKS } from "constants/taskCategoryType";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

const NoTaskTemplates = () => {
  return (
    <div className="t-flex t-h-[390px] t-w-full t-flex-col t-items-center t-justify-center t-text-text-100">
      <img src={NoTaskTemplatesState} alt="No task found" />
      <div className="t-text-subtitle-sm">No results found</div>
      <div className="t-text-center t-text-body-sm">
        We couldn't find what you searched for.
        <br />
        Try searching again.
      </div>
    </div>
  );
};

export default function TemplateMenu({
  to,
  taskCategoryType = "TAX,RND",
}: {
  to: string;
  taskCategoryType?: "TAX,RND" | "BOOKS" | "INTRA_GROUP" | "SALES_TAX";
}) {
  const { update } = useUpdateQuery();
  const query = useQuery();
  const searchTerm = query.get("filing_search") || "";
  const searchValue = useDebounce(searchTerm, 500) || "";
  const history = useHistory();
  const parentRef = useRef<HTMLDivElement>(null);
  const [currentCategory, setCurrentCategory] = useState("");
  useState("");
  const [scrollCC, setScrollCc] = useState("");
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const entityId = useCurrentEntityId();
  const { data: allSubscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );
  const {
    open: openServiceActionModal,
    close: closeServiceActionModal,
    isOpen: showServiceActionModal,
  } = useModal();
  const showIntragroupTaskLaunchModal = useSelector(
    (state: RootState) => state.intragroupList.showIntragroupTaskLaunchModal
  );
  const showSalesTaxTaskLaunchModal = useSelector(
    (state: RootState) => state.salexTaxList.showSalesTaxTaskLaunchModal
  );
  const { isCustomer, isCpa, isForeignCA } = useRoleBasedView();
  const { alertToast } = useToast();

  const {
    data = {},
    isError,
    isSuccess,
    isFetching,
  } = useGetBaseTaskTemplatesQuery({
    searchTerm: searchValue,
    ...(isCustomer && { task_category_type: taskCategoryType }),
  });

  const {
    data: userTaskData,
    isLoading: isUserLoading,
    isSuccess: isUserSuccess,
  } = useGetTaskListForUserQuery(
    {
      taskCategoryType: BOOKS,
    },
    { skip: isCpa, refetchOnMountOrArgChange: true }
  );

  const {
    data: cpaTaskList,
    isLoading: isAdminLoading,
    isSuccess: isAdminSuccess,
  } = useGetTaskListForServiceTeamQuery(
    {
      groupId,
      taskCategoryType: BOOKS,
    },
    { skip: isCustomer || !groupId, refetchOnMountOrArgChange: true }
  );
  const closeModal = () => {
    history.replace(to);
  };

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "filing_search", value: value || null });
  };

  const categories = useMemo(() => {
    return Object.keys(data);
  }, [data]);

  const noTaskTemplates = Object.keys(data).length === 0;
  let modalTitle = noTaskTemplates ? "No templates found" : "Launch Filing";
  const isFromBooks = taskCategoryType === "BOOKS";
  const isFromIntragroup = taskCategoryType === "INTRA_GROUP";
  const isFromSalesTax = taskCategoryType === "SALES_TAX";
  const isFromServices = isFromBooks || isFromIntragroup || isFromSalesTax;
  if (isFromBooks) {
    modalTitle = "Bookkeeping Services";
  }
  if (isFromIntragroup) {
    modalTitle = "Intragroup Services";
  }
  if (isFromSalesTax) {
    modalTitle = "Sales Tax Services";
  }

  const templates = Object.values(data).map((el) =>
    el.map((e, i) => ({
      ...e,
      is_first: i === 0,
    }))
  );
  const { tasks: taskData = [] } = userTaskData || cpaTaskList || {};

  const handleScroll = (): void => {
    if (parentRef.current) {
      const parentRect = parentRef.current.getBoundingClientRect();
      const childElements = parentRef.current.children;

      for (let child of Array.from(childElements)) {
        const childRect = (child as HTMLElement).getBoundingClientRect();
        const isInView =
          childRect.top >= parentRect.top &&
          childRect.bottom <= parentRect.bottom;

        if (isInView) {
          const cc = child.getAttribute("aria-details") || "";
          setScrollCc(cc);
          setCurrentCategory("");
          break;
        }
      }
    }
  };

  const onGroupMIS = () => {
    const groupConsolidation = allSubscriptions.filter(
      (ele) => ele.subscription_type === GROUP_BOOKS_CONSOLIDATION
    );

    const groupConsolidationMinAmount = groupConsolidation.reduce(
      (min, obj) => {
        return Number(obj?.amount) < Number(min)
          ? Number(obj?.amount)
          : Number(min);
      },
      Number(groupConsolidation?.[0]?.amount)
    );

    const groupConsolidationMappings =
      groupConsolidation
        ?.filter(
          (obj) =>
            obj?.group_subscription && obj?.group_subscription?.length > 0
        )
        ?.map((ele) => {
          return {
            groupSubscriptionId:
              ele?.group_subscription?.[0]?.group_subscription_id,
            subscriptionName: ele?.subscription_name,
            amount: ele?.amount,
            billingCycle: ele?.billing_cycle,
          };
        }) || [];

    const addon = {
      amount: groupConsolidationMinAmount,
      billing_cycle: "quarterly or monthly",
      description: "Consolidated financials of all your entities",
      dropdownContent: groupConsolidation,
      subscription_name: "Group Books Consolidation",
      subscription_type: GROUP_BOOKS_CONSOLIDATION,
      ...(groupConsolidationMappings?.length > 0
        ? { group_subscription: groupConsolidationMappings }
        : {}),
      uuid: "",
    };

    dispatch(toggleCartSubscription(true));
    dispatch(setAddonData(addon));
    dispatch(setAddonType(addon?.subscription_type!));
    history.push(
      isCustomer
        ? "/settings/billing"
        : isForeignCA
        ? `/practice/crm/${groupId}/billing`
        : `/admin/crm/${groupId}/billing`
    );
  };

  const openServiceAction = (serviceType: ServiceType) => {
    dispatch(setServiceActionType(serviceType));
    openServiceActionModal();
  };

  const openIntragroupService = ({
    selectedTemplate,
  }: {
    selectedTemplate?: BaseTaskTemplate;
  }) => {
    if (selectedTemplate?.approx_price) {
      dispatch(openIntragroupTask(selectedTemplate as unknown as TemplateType));
    } else {
      alertToast({ message: SOMETHING_WENT_WRONG });
    }
  };

  const openSalesTaxService = ({
    selectedTemplate,
  }: {
    selectedTemplate?: BaseTaskTemplate;
  }) => {
    if (selectedTemplate?.approx_price) {
      dispatch(openSalesTaxTask(selectedTemplate as unknown as TemplateType));
    } else {
      alertToast({ message: SOMETHING_WENT_WRONG });
    }
  };

  const handleServiceTaskTrigger = (templateId: string) => {
    const selectedTemplate = templates
      .flat()
      ?.find((template) => template.uuid === templateId);

    switch (selectedTemplate?.base_task_key) {
      case GROUP_BOOKS_CONSOLIDATION_RECURRING: {
        onGroupMIS();
        break;
      }
      case GROUP_BOOKS_CONSOLIDATION_ONE_TIME: {
        openServiceAction(GROUP_BOOKS_CONSOLIDATION_ONE_TIME);
        break;
      }

      case US_BOOKKEEPING_ONE_TIME: {
        openServiceAction(US_BOOKKEEPING_ONE_TIME);
        break;
      }

      case US_BOOKKEEPING_SUBSCRIPTION_PARENT: {
        openServiceAction(US_BOOKKEEPING_SUBSCRIPTION_PARENT);
        break;
      }

      case GAAP_FINANCIALS: {
        openServiceAction(GAAP_FINANCIALS);
        break;
      }
      case AUDIT: {
        openServiceAction(AUDIT);
        break;
      }
      case FINANCIAL_MODELLING: {
        openServiceAction(FINANCIAL_MODELLING);
        break;
      }
      case PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING: {
        openServiceAction(PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING);
        break;
      }
      case PRO_MONTHLY_BOOKKEEPING: {
        openServiceAction(PRO_MONTHLY_BOOKKEEPING);
        break;
      }
      case TRANSFER_PRICING_AUDIT: {
        openIntragroupService({ selectedTemplate });
        break;
      }
      case TRANSFER_PRICING_BENCHMARKING_STUDY: {
        openIntragroupService({ selectedTemplate });
        break;
      }
      case TRANSFER_PRICING_HEALTH_CHECK: {
        openIntragroupService({ selectedTemplate });
        break;
      }
      case SALES_TAX_NEXUS_STUDY: {
        openSalesTaxService({ selectedTemplate });
        break;
      }
      case TP_PAYMENTS_ONBOARDING: {
        openIntragroupService({ selectedTemplate });
        break;
      }
      case PAN_CARD_APPLICATION: {
        openIntragroupService({ selectedTemplate });
        break;
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setScrollCc(categories[0]);
      setCurrentCategory(categories[0]);
    }
  }, [isSuccess]);

  const isProEnabled = Boolean(
    taskData.filter(
      ({ base_task_key }) => base_task_key === PRO_MONTHLY_BOOKKEEPING
    ).length
  );
  const isPayGEnabled = Boolean(
    taskData.filter(
      ({ base_task_key }) => base_task_key === PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING
    ).length
  );
  let currentBookkeepingService = "";

  if (isProEnabled) {
    currentBookkeepingService = PRO_MONTHLY_BOOKKEEPING;
  }

  if (isPayGEnabled) {
    currentBookkeepingService = PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING;
  }

  const showTaskLaunchModal =
    !showServiceActionModal &&
    !showIntragroupTaskLaunchModal &&
    !showSalesTaxTaskLaunchModal;

  if (isError) {
    return null;
  }

  return (
    <>
      {showTaskLaunchModal && (
        <Modal.Root open={isSuccess} onOpenChange={closeModal}>
          <Modal.Content size="xl">
            <Modal.Header>
              <Modal.Title>{modalTitle}</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body className="t-h-[600px] !t-px-0 !t-py-0">
              <div className="t-px-8 t-pt-5">
                <Search
                  onChange={onSearchChange}
                  value={searchTerm || ""}
                  placeholder="Search..."
                  autoFocus
                  customSize="large"
                  className="t-w-96"
                />
                <hr className="t-mb-4 t-border-2 t-border-t-neutral-10" />
              </div>

              {isFetching ? (
                <Loader />
              ) : (
                <>
                  {noTaskTemplates ? (
                    <NoTaskTemplates />
                  ) : (
                    <div className="t-flex t-pl-8 t-pb-5">
                      {!isFromServices && (
                        <div className="t-pr-3 t-border-0 t-border-neutral-10 t-border-r t-border-solid t-sticky t-h-[450px] t-top-0 t-w-[29%]">
                          <h4 className="t-ml-0">Categories</h4>
                          <div className="t-flex t-flex-col t-gap-5">
                            {categories?.map((category) => (
                              <button
                                key={category}
                                className={classNames(
                                  "all:unset t-text-body hover:t-text-purple-70",
                                  {
                                    "t-text-purple-70": scrollCC === category,
                                  }
                                )}
                                onClick={() => setCurrentCategory(category)}
                              >
                                {category}
                              </button>
                            ))}
                          </div>
                        </div>
                      )}
                      <div
                        className={classNames("t-ml-3 ", {
                          "t-w-[71%]": !isFromServices,
                          "t-w-full": isFromServices,
                        })}
                      >
                        {!isFromServices && (
                          <h4 className="t-ml-0">Templates</h4>
                        )}
                        <div
                          ref={parentRef}
                          className="t-overflow-auto t-h-[410px] t-pr-8"
                          onScroll={handleScroll}
                        >
                          {templates.flat()?.map((template) => (
                            <TaskDetails
                              key={template.uuid}
                              data={template}
                              currentCategory={currentCategory}
                              handleServiceTaskTrigger={
                                handleServiceTaskTrigger
                              }
                              currentBookkeepingService={
                                currentBookkeepingService
                              }
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Modal.Body>
          </Modal.Content>
        </Modal.Root>
      )}
      {showServiceActionModal && (
        <ServiceActionModal
          isOpen={showServiceActionModal}
          close={closeServiceActionModal}
        />
      )}
      {showIntragroupTaskLaunchModal && (
        <IntragroupServiceActionModal
          isOpen={showIntragroupTaskLaunchModal}
          parentRoute="books/intragroup"
        />
      )}
      {showSalesTaxTaskLaunchModal && (
        <SalesTaxServiceModal
          isOpen={showSalesTaxTaskLaunchModal}
          parentRoute="sales-tax"
        />
      )}
    </>
  );
}
