import { DashboardLayout } from "components/DashboardLayout";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import Breadcrumb from "components/DesignSystem/Breadcrumb/Breadcrumb";
import { CURRENT_SEASON } from "constants/vendor";
import { usePageTitle } from "hooks/usePageTitle";
import { useGetVendorDetailQuery } from "store/apis/vendors";
import { VendorBillsAndTxn } from "./VendorBillsAndTxn";
import { VendorDeatilAccordion } from "./VendorDeatilAccordion";

export const VendorDetail = ({
  breadcrumb,
  entityId,
  groupId,
  vendorId,
}: {
  breadcrumb: { link?: string; name: string }[];
  entityId: string;
  groupId: string;
  vendorId: string;
}) => {
  const { data, isLoading, isSuccess, isError } = useGetVendorDetailQuery(
    {
      entityId,
      vendorId,
      season: CURRENT_SEASON,
    },
    {
      skip: !vendorId || !entityId,
    }
  );

  usePageTitle(data?.name || "Vendor");

  return (
    <DashboardLayout
      header={
        <Breadcrumb.Root>
          {breadcrumb.map((item, index) => (
            <Breadcrumb.Item key={index} link={item.link}>
              {item.name}
            </Breadcrumb.Item>
          ))}
          {isLoading ? (
            <Loader customType="secondary" size="small" />
          ) : (
            <Breadcrumb.Item>{data && data.name}</Breadcrumb.Item>
          )}
        </Breadcrumb.Root>
      }
    >
      <Async.Root
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        isEmpty={!data}
      >
        <Async.ErrorHandler>
          <div className="t-text-red t-h-1/2 t-flex t-justify-center t-items-center">
            Something went wrong
          </div>
        </Async.ErrorHandler>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          {data && (
            <div className="t-relative">
              <VendorDeatilAccordion
                vendor={data}
                entityId={entityId}
                groupId={groupId}
              />
              <VendorBillsAndTxn
                entityId={entityId}
                vendor={data}
                vendorId={vendorId}
              />
            </div>
          )}
        </Async.Success>
      </Async.Root>
    </DashboardLayout>
  );
};
