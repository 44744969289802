import { ClockCountdown } from "components/icons/ClockCountdown";
import { Info } from "components/icons/Info";
import { SLA } from "constants/chatSettingBannerTypes";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useGetCurrentBannerQuery } from "store/apis/chatSettings";

export const MessageInputInfo = () => {
  const { isCustomer } = useRoleBasedView();
  const group = useCurrentGroupContext();
  const { data: currentChatBanner, isSuccess } = useGetCurrentBannerQuery(
    {
      groupId: group.uuid,
    },
    {
      skip: !group.uuid,
    }
  );

  if (!isSuccess || !isCustomer) return null;

  if (currentChatBanner?.banner_type === SLA) {
    return (
      <div className="t-text-neutral-40 t-mx-4 t-flex t-gap-1.5 t-items-center t-text-body-sm t-py-2 t-bg-surface-grey">
        <ClockCountdown />
        <div>{currentChatBanner.banner_text}</div>
      </div>
    );
  }

  return (
    <div className="t-text-neutral-40 t-px-4 t-py-1 t-w-full t-bg-surface-grey">
      <div className="t-bg-blue-0 t-px-3 t-py-1.5 t-border-solid t-border t-border-blue-10 t-rounded-lg t-flex t-gap-2 t-items-center t-w-full">
        <span className="t-text-blue t-flex">
          <Info stroke="1.5" size="20" />
        </span>
        <p className="t-m-0 t-text-body-sm t-text-blue-90">
          {currentChatBanner?.banner_text}
        </p>
      </div>
    </div>
  );
};
