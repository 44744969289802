import classNames from "classnames";
import { ArrowRight } from "components/icons/ArrowRight";
import { FC } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setTaskReviewBalancePaymentStep } from "store/slices/reviewAndBalancePayment";
import { RootState } from "store/store";

type ITaskReviewBalancePaymentStepperProps = {
  disable2ndStep?: boolean;
};

export const TaskReviewBalancePaymentStepper: FC<
  ITaskReviewBalancePaymentStepperProps
> = ({ disable2ndStep }) => {
  const dispatch = useDispatch();
  const taskReviewBalancePaymentStep = useSelector(
    (state: RootState) =>
      state.reviewAndBalancePayment.taskReviewBalancePaymentStep
  );

  return (
    <div className="t-flex t-items-center">
      <button
        type="button"
        onClick={() => dispatch(setTaskReviewBalancePaymentStep(1))}
        className={classNames(
          "all:unset p-0 t-flex t-items-center t-text-neutral",
          {
            "t-text-text-black": taskReviewBalancePaymentStep >= 1,
          }
        )}
      >
        <div
          className={classNames(
            "t-mr-2 t-flex t-max-h-[22px] !t-max-w-[22px] t-items-center t-rounded-full t-p-2 t-text-body-sm ",
            {
              "t-bg-purple t-text-surface": taskReviewBalancePaymentStep >= 1,
              "t-bg-neutral-10 t-text-text-black":
                taskReviewBalancePaymentStep < 1,
            }
          )}
        >
          1
        </div>
        <span>Upload Review Documents</span>
      </button>
      <ArrowRight />
      <button
        type="button"
        className={classNames(
          "all:unset p-0 t-flex t-items-center t-text-neutral",
          {
            "t-text-text-black": taskReviewBalancePaymentStep >= 2,
          }
        )}
        onClick={() =>
          !disable2ndStep && dispatch(setTaskReviewBalancePaymentStep(2))
        }
        disabled={disable2ndStep}
      >
        <div
          className={classNames(
            "t-mr-2 t-flex t-max-h-[22px] !t-max-w-[22px] t-items-center t-rounded-full  t-p-2 t-text-body-sm ",
            {
              "t-bg-purple t-text-surface": taskReviewBalancePaymentStep >= 2,
              "t-bg-neutral-10 t-text-text-black":
                taskReviewBalancePaymentStep < 2,
            }
          )}
        >
          2
        </div>
        <span>Balance Payment</span>
      </button>
    </div>
  );
};
