import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { TableUI } from "components/design/TableUI";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { BankLogo } from "components/icons/BankLogo";
import { MerchantComponent } from "components/Transaction/MerchantSelector";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useMemo } from "react";
import {
  RowSelectionState,
  OnChangeFn,
  createColumnHelper,
  useReactTable,
  getCoreRowModel,
} from "react-table-8.10.7";
import { Transactions } from "types/Models/books";

export const TransactionsView = ({
  transactions,
  rowSelection,
  setRowSelection,
  showCheckbox = false,
  disableUncategoriseTransaction,
}: {
  transactions: Transactions[];
  rowSelection?: RowSelectionState;
  setRowSelection?: OnChangeFn<RowSelectionState>;
  showCheckbox?: boolean;
  disableUncategoriseTransaction?: boolean;
}) => {
  const createColumn = createColumnHelper<Transactions>();
  const checkBoxColumn = showCheckbox
    ? [
        createColumn.display({
          id: "select",
          size: 1,
          enableSorting: false,
          header: ({ table }) => (
            <Checkbox
              {...{
                checked: table.getIsAllRowsSelected(),
                indeterminate: table.getIsSomeRowsSelected(),
                onChange: table.getToggleAllRowsSelectedHandler(),
              }}
            />
          ),

          cell: ({ row }) => {
            const disableCheckBox =
              disableUncategoriseTransaction &&
              !row.original?.transaction?.category &&
              !row?.original?.split_transactions?.length;

            return (
              <ConditionalToolTip
                condition={
                  disableCheckBox && "This transaction has no category."
                }
              >
                <span>
                  <Checkbox
                    {...{
                      checked: row.getIsSelected(),
                      disabled: disableCheckBox,
                      indeterminate: row.getIsSomeSelected(),
                      onChange: row.getToggleSelectedHandler(),
                      key: row.id,
                    }}
                    onClick={(e) => e.stopPropagation()}
                  />
                </span>
              </ConditionalToolTip>
            );
          },
        }),
      ]
    : [];

  const columns = useMemo(
    () => [
      ...checkBoxColumn,
      createColumn.accessor("transaction.date", {
        size: 15,
        header: "DATE",
        cell: (info) => (
          <div className="t-py-3">
            {dayjs(info.getValue()).format(DD_MMM_YYYY)}
          </div>
        ),
      }),

      createColumn.accessor("transaction.from", {
        size: 15,
        header: () => (
          <div className="t-flex t-justify-center t-w-full">Source</div>
        ),
        cell: (info) => {
          const from = info.getValue();
          const { bank_account } = from || {};
          const { bank_brand } = bank_account || {};
          const { logo_url } = bank_brand || {};

          const mask = from?.bank_account?.mask
            ? `•••• ${from?.bank_account?.mask}`
            : "";

          return (
            <div className="t-flex t-items-center t-w-full t-justify-center">
              <ConditionalToolTip
                condition={
                  from?.bank_account &&
                  `${from?.bank_account?.nickname} ${mask}`
                }
              >
                <span>
                  {logo_url ? (
                    <Avatar
                      src={logo_url}
                      alt={from?.bank_account?.mask || "Bank"}
                    />
                  ) : (
                    <BankLogo />
                  )}
                </span>
              </ConditionalToolTip>
            </div>
          );
        },
      }),

      createColumn.accessor("transaction.merchant", {
        size: 25,
        header: "VENDOR",
        cell: (info) => {
          const {
            row: {
              original: { transaction },
            },
          } = info;

          if (!transaction.merchant) {
            return "-";
          }

          return (
            <MerchantComponent
              name={transaction.merchant}
              logo={transaction.logo!}
            />
          );
        },
      }),

      createColumn.accessor("transaction.category", {
        size: 25,
        header: () => "CATEGORY",
        cell: (info) => {
          const {
            row: {
              original: { transaction, split_transactions },
            },
          } = info;

          if (!transaction?.category && !split_transactions?.length) {
            return "-";
          }
          return (
            <>
              {split_transactions?.length ? (
                <div>
                  {split_transactions?.at(0)?.category?.name}{" "}
                  <span className="t-text-purple t-font-semibold">
                    +{split_transactions.length - 1}
                  </span>
                </div>
              ) : (
                <>{transaction?.category?.name}</>
              )}
            </>
          );
        },
      }),

      createColumn.accessor("transaction.amount", {
        size: 20,
        header: () => <div className="t-flex t-justify-end">AMOUNT</div>,
        cell: (info) => {
          const amount = info.getValue();
          const { is_credit_card } = info.row.original?.transaction || {};

          const isCredit = amount > 0;

          if (!amount) {
            return "-";
          }

          return (
            <div
              className={classNames("t-flex t-justify-end", {
                "t-text-dark_green-50": isCredit && !is_credit_card,
              })}
            >
              <AmountSuperScript amount={amount} />
            </div>
          );
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: transactions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: showCheckbox,
    onRowSelectionChange: setRowSelection ?? (() => {}),
    state: {
      rowSelection,
    },
    getRowId: ({ transaction }) => transaction?.uuid,
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  return <TableUI table={table} />;
};
