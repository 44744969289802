import UpgradeBooksPlusPlan from "components/BooksDashboard/UpgradeBooksPlusPlan";
import { DashboardLayout } from "components/DashboardLayout";
import Loader from "components/design/loader";
import { Header } from "components/DesignSystem/Header/Header";
import { HomeActionItems } from "components/HomeActionItems/HomeActionItems";
import * as PRODUCT_ONBOARDING from "constants/productOnboardings";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import { useGetOnboardingProductsQuery } from "store/apis/productOnboarding";
import { BooksOnboarding } from "./BooksOnboarding";
import { INKLE_BOOKS } from "constants/home";
import {
  HideBalancesAndAmounts,
  HideBalancesAndAmountsProvider,
} from "components/BooksHomePage/HideBalancesAndAmounts";
import { CashBalance } from "components/BooksHomePage/CashBalance/CashBalance";
import { BusinessOverview } from "components/BooksHomePage/BusinessOverview/BusinessOverview";
import { BusinessInsights } from "components/BooksHomePage/BusinessInsights/BusinessInsights";
import { CATEGORISATION_TYPE } from "constants/booksHomePage";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { useQuery } from "hooks/useQuery";

const BooksHomePage = () => {
  usePageTitle("Books Dashboard");
  const { uuid: groupId } = useCurrentGroupContext();
  const query = useQuery();
  const CATEGORISATION_TYPE_FROM_URL = query.get(
    "CATEGORISATION_TYPE"
  ) as keyof typeof CATEGORISATION_TYPE;

  const {
    authtoken: { first_name },
  } = useContext(authContext);

  const {
    data: products,
    isLoading,
    isSuccess,
  } = useGetOnboardingProductsQuery(
    {
      groupId: groupId!,
    },
    { skip: !groupId }
  );

  const isBooksOnboardingComplete =
    products?.find((p) => p.name === PRODUCT_ONBOARDING.BOOKS)?.status ===
    "COMPLETED";

  if (isLoading || !isSuccess) {
    return <Loader />;
  }

  if (isBooksOnboardingComplete) {
    return (
      <DashboardContainer className="t-items-center">
        <DashboardContainer.Content hasMaximumWidth className="t-px-10">
          <div className="t-flex t-gap-5 t-flex-col">
            <UpgradeBooksPlusPlan />
            <Header
              right={<HideBalancesAndAmounts />}
              v2
              title={`Welcome ${first_name}`}
            />
          </div>
          <div className="t-flex t-gap-8 t-flex-col t-relative t-mt-4">
            <div className="t-grid t-gap-6 t-grid-cols-2 t-h-96">
              <HomeActionItems defaultTab={INKLE_BOOKS} />
              <CashBalance />
            </div>
            <BusinessOverview />
            <BusinessInsights
              categorisationType={
                CATEGORISATION_TYPE_FROM_URL || CATEGORISATION_TYPE.ALL
              }
            />
          </div>
        </DashboardContainer.Content>
      </DashboardContainer>
    );
  }

  return (
    <DashboardLayout header={<Header v2 title={`Welcome ${first_name}`} />}>
      <BooksOnboarding />
    </DashboardLayout>
  );
};

export const BooksHome = () => {
  return (
    <HideBalancesAndAmountsProvider>
      <BooksHomePage />
    </HideBalancesAndAmountsProvider>
  );
};
