import { AxisTickProps } from "@nivo/axes";
import { ScaleValue } from "@nivo/scales";
import { DOT_SIZE } from "constants/booksHomePage";
import { colors } from "constants/colors";
import dayjs from "dayjs";
import { motion } from "framer-motion";
import { ReactNode } from "react";

export const MonthYearChartBottomAxisTicks = <Value extends ScaleValue>(
  tick: AxisTickProps<Value> & {
    data_length: number;
  }
) => {
  const isValidDate = dayjs(tick.value as string).isValid();
  const isLast = tick.tickIndex === tick.data_length - 1;
  const isFirst = tick.tickIndex === 0;

  if (isFirst || isLast) {
    return (
      <motion.g
        initial={{
          opacity: 0,
          x: 0,
          y: 0,
        }}
        animate={{
          opacity: 1,
          x: tick.x,
          y: tick.y,
        }}
      >
        <motion.text
          dy={40}
          textAnchor="middle"
          fontSize={12}
          fill={colors.text[30]}
        >
          {isValidDate ? (
            <>{dayjs(tick.value as string).format("MMM YY")}</>
          ) : (
            (tick.value as string)
          )}
        </motion.text>
      </motion.g>
    );
  }

  return <></>;
};

export const LegendDot = ({ color }: { color: string }) => {
  return (
    <div
      className="t-rounded-full"
      style={{
        background: color,
        width: DOT_SIZE,
        height: DOT_SIZE,
      }}
    />
  );
};

export const Legend = ({ dot, lable }: { dot: ReactNode; lable: string }) => {
  return (
    <div className="t-flex t-gap-2 t-items-center t-text-body-sm t-text-text-30">
      {dot}
      {lable}
    </div>
  );
};

export const ChartItemInfo = ({
  dot,
  lable,
  value,
}: {
  dot: ReactNode;
  lable: string;
  value: ReactNode;
}) => {
  return (
    <div className="t-flex t-gap-2 t-items-center t-justify-between t-text-body-sm">
      <div className="t-flex t-gap-2 t-items-center">
        {dot}
        <span className="t-text-neutral-20">{lable}: </span>
      </div>
      <div className="t-text-white">{value}</div>
    </div>
  );
};
