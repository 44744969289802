import { FieldProps, Form, Formik } from "formik";
import { MouseEvent, useEffect, useMemo, useState } from "react";
import { Slider } from "components/DesignSystem/Slider/Slider";
import TicketIcon from "./TicketIcon";
import { Cross } from "components/icons/Cross";
import { Divider } from "components/design/Divider";
import { formatDate, formatTime } from "utils/formatDate";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import TicketTagsCombobox from "./TicketTagsCombobox";
import { TicketAssigneeDropdown } from "./TicketAssigneeDropdown";
import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import {
  ChatUpdatePayload,
  TicketNotes,
  TicketPayment,
} from "types/Models/chatTicket";
import {
  useCreateTicketNotesMutation,
  useGetSingleChatTicketsQuery,
  useGetTicketETDHistoryQuery,
  useGetTicketNotesQuery,
  useUpdateChatTicketMutation,
} from "store/apis/chatTicket";
import { debounce } from "utils/debouncing";
import PricedTicketTag from "static/images/PricedTicketTag.svg";
import NonPricedTicketTag from "static/images/NonPricedTicketTag.svg";
import DisabledPricedTicketTag from "static/images/DisabledPricedTicketTag.svg";
import ClockUpdate from "static/images/ClockUpdate.svg";
import FilePdf from "static/images/FilePdf.svg";
import TicketResolvedIcon from "static/images/TicketResolved.svg";
import { TicketUnresolved } from "components/icons/TicketUnresolved";
import { openLink } from "utils/openLink";
import { useToast } from "hooks/useToast";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { NOTE_CREATED_SUCCESSFULLY } from "constants/ticket";
import InfiniteScroll from "react-infinite-scroll-component";
import { StreamChat } from "stream-chat";
import { PriceInput } from "components/PriceInput/PriceInput";
import { MultiValue, SingleValue } from "react-select";
import { Loader } from "components/DesignSystem/Loader/Loader";
import { BackendError } from "types/utils/error";
import { MessageBubbleUI } from "components/MessageBubble/MessageBubble";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { ticketPriceSchema } from "formValidations/ticketSchema";
import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import { InfoItem, useSliderTicket } from "./CustomerTicket/TicketSlider";
import dayjs from "dayjs";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import ThreeDots from "static/images/ThreeDots.svg";
import { CreateChannelModal } from "./CreateChannelModal";
import { useModal } from "hooks/useModal";
import { useGetMessage } from "hooks/useGetMessage";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import Tab from "components/DesignSystem/Tab/Tab";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useDispatch, useSelector } from "react-redux";
import {
  setSliderAccordionValue,
  setTicketSliderTab,
} from "store/slices/ticket";
import { RootState } from "store/store";
import { TicketTypeUpdateConfirm } from "./TicketTypeUpdateConfirm";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { FormikListener } from "components/FormikListener/FormikListener";
import ReactCountryFlag from "react-country-flag";
import { TicketEtdModal } from "./TicketEtdModal";
import { Field } from "formik";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { EtdUpdateHistoryCard } from "components/TaskSlider/EtdUpdateHistoryCard";
import { ChatTab } from "components/ChatTab/ChatTab";
import { UnstyledChannel } from "components/UnstyledChannel/UnstyledChannel";
import { useChatContext } from "stream-chat-react";

const Header = () => {
  return (
    <div className="t-w-full t-px-5 t-pt-3 t-border t-border-solid t-border-b t-border-l-0 t-border-t-0 t-border-r-0 t-border-neutral-0 t-bg-surface t-flex t-flex-col t-sticky t-top-0 t-z-header">
      <Tab.List>
        <Tab.Trigger value="DETAILS">Details</Tab.Trigger>
        <Tab.Trigger value="CHAT">Chat</Tab.Trigger>
      </Tab.List>
    </div>
  );
};

const ChannelCreateDropDown = ({
  ticketId,
  messageId,
}: {
  ticketId: string;
  messageId?: string | null;
}) => {
  const { isOpen, close, open } = useModal();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <div>
            <Button size="small" customType="ghost_icon">
              <img src={ThreeDots} alt="Action" className="t-select-none" />
            </Button>
          </div>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content sideOffset={8} side="bottom" className="t-mr-16">
            <Dropdown.Item onSelect={open}>Create chat channel</Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      {isOpen && (
        <CreateChannelModal
          close={close}
          isOpen={isOpen}
          ticketId={ticketId}
          messageId={messageId}
        />
      )}
    </div>
  );
};

export const TicketSlider = ({
  resolvedStatus,
  openSlider,
  setOpenSlider,
  ticketNotes,
  setTicketNotes,
  ticketNotesPage,
  setTicketNotesPage,
  chatClient,
  isInsideGroup,
}: {
  resolvedStatus: boolean;
  openSlider: boolean;
  setOpenSlider: (v: boolean) => void;
  ticketNotes: TicketNotes[];
  setTicketNotes: (v: TicketNotes[]) => void;
  ticketNotesPage: number;
  setTicketNotesPage: (v: number) => void;
  chatClient: StreamChat<DefaultStreamChatGenerics>;
  isInsideGroup: boolean;
}) => {
  const { alertToast, successToast } = useToast();
  const [loadingPushPayment, setLoadingPushPayment] = useState(false);
  const [description, setDescription] = useState("");
  const [disablePushPayment, setDisablePushPayment] = useState(false);
  const [updateChatTicket] = useUpdateChatTicketMutation();
  const defaultTab = useSelector((store: RootState) => store.ticket.sliderTab);
  const ticketTypeUpdate = useModal();
  const queryData = useQuery();
  const { update } = useUpdateQuery();
  const ticketIdFromQueryParam = queryData.get("ticketUuid") || "";
  const { isAdmin } = useRoleBasedView();
  const etdModal = useModal();

  const [getPreviewUrl, { isLoading: isPreviewFetching }] =
    useLazyGetPreviewUrlQuery();

  const [createTicketNote, { isLoading: isNoteCreating }] =
    useCreateTicketNotesMutation();

  const { data: currentTicket, isLoading: isChatTicketLoading } =
    useGetSingleChatTicketsQuery(
      {
        ticketUuid: ticketIdFromQueryParam,
      },
      {
        skip: !ticketIdFromQueryParam,
        refetchOnMountOrArgChange: true,
      }
    );

  const { data: ticketNotesData } = useGetTicketNotesQuery(
    {
      groupId: currentTicket?.company_group?.uuid!,
      ticketId: currentTicket?.uuid!,
      page_num: ticketNotesPage,
    },
    {
      skip: !currentTicket?.company_group?.uuid || !currentTicket?.uuid,
    }
  );

  const { data: ticketETDHistory = [] } = useGetTicketETDHistoryQuery(
    {
      ticketId: currentTicket?.uuid!,
    },
    {
      skip: !currentTicket?.uuid || !isAdmin,
    }
  );

  const ticketMessage = useGetMessage(currentTicket?.message_id!);
  const { notes = [], total_pages = 1 } = ticketNotesData || {};
  const isTicketPaymentCreated =
    currentTicket?.ticket_payments && currentTicket.ticket_payments.length > 0;
  const isPriceFinalized =
    isTicketPaymentCreated &&
    currentTicket.ticket_payments.some(
      (ticket_payments) => ticket_payments.is_price_finalized
    );
  const isAnyTicketPaymentPaid =
    isTicketPaymentCreated &&
    currentTicket.ticket_payments.some(
      (ticket_payments) => ticket_payments.is_ticket_paid
    );
  const ticketTypeTooltipText = isAnyTicketPaymentPaid
    ? "Changes are not allowed for tickets with payments already made."
    : "Changes are not allowed for tickets with pending payments.";

  const handleDisablePushPayment = () => {
    setDisablePushPayment(true);
  };

  useEffect(() => {
    if (ticketNotesPage === 1) {
      setTicketNotes([...notes]);
    } else {
      setTicketNotes([...ticketNotes, ...notes]);
    }
  }, [notes]);

  /* functions */
  const closeSlider = () => {
    setOpenSlider(false);
    update({ query: "ticketUuid", value: null });
  };

  const onDownload = async (
    preview: boolean,
    document_id: string,
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    e.stopPropagation();
    try {
      if (currentTicket && currentTicket.company_group?.uuid && document_id) {
        const { preview_url, download_url } = await getPreviewUrl({
          groupId: currentTicket.company_group.uuid,
          fileId: document_id,
        }).unwrap();
        if (preview) {
          openLink(preview_url);
        } else {
          openLink(download_url);
        }
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };
  const groupUuid = currentTicket?.company_group?.uuid;
  const entityId = currentTicket?.entity?.uuid;

  const channelMessageUrl = `${
    currentTicket?.channel_url ||
    currentTicket?.company_group?.secondary_channel
  }/${ticketMessage?.id || ""}`;

  const chatUrl = isInsideGroup
    ? `/admin/crm/${groupUuid}/chat/${channelMessageUrl}`
    : `/chat/${channelMessageUrl}`;

  const { isForeignCA } = useRoleBasedView();

  const companyUrl = isForeignCA
    ? `/practice/crm/${groupUuid}`
    : `/admin/crm/${groupUuid}`;

  const entityUrl = isForeignCA
    ? `/practice/crm/${groupUuid}/entity/${entityId}`
    : `/admin/crm/${groupUuid}/entity/${entityId}`;

  const onFormValuesChange = debounce(
    async (formValues: Partial<ChatUpdatePayload>, isDirty: boolean) => {
      /* isDirty checks if initialValues are different from the current form values */
      try {
        if (currentTicket) {
          await updateChatTicket({
            ticket_id: currentTicket.uuid,
            ...formValues,
          }).unwrap();
        }
        setDisablePushPayment(false);
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
        setDisablePushPayment(false);
      }
    }
  );
  const addNotes = async (e: any) => {
    const isOnlyEnterPressed = e.keyCode === 13 && !e.shiftKey;
    try {
      if (description && isOnlyEnterPressed && currentTicket) {
        await createTicketNote({
          ticketId: currentTicket?.uuid,
          groupId: currentTicket?.company_group?.uuid!,
          description: description,
        }).unwrap();
        successToast({ message: NOTE_CREATED_SUCCESSFULLY });
        setDescription("");
        setTicketNotesPage(1);
      }
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const goToNextPage = () => {
    if (ticketNotesPage < total_pages) {
      setTicketNotesPage(ticketNotesPage + 1);
    }
  };

  const pushPayment = async () => {
    try {
      if (currentTicket) {
        setLoadingPushPayment(true);
        setDisablePushPayment(true);
        await updateChatTicket({
          ticket_id: currentTicket.uuid,
          is_price_finalized: true,
        }).unwrap();
        setLoadingPushPayment(false);
        setDisablePushPayment(false);
        successToast({ message: "Payment request successfully pushed" });
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };
  const markTicketStatus = async (resolvedStatus: boolean) => {
    try {
      if (currentTicket) {
        const res = await updateChatTicket({
          ticket_id: currentTicket.uuid,
          resolved_status: resolvedStatus,
        }).unwrap();
        if (res.resolved) {
          successToast({ message: "Ticket has been marked as resolved" });
        } else {
          successToast({ message: "Ticket has been marked as unresolved" });
        }
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const ticket_price = currentTicket?.is_priced_ticket
    ? currentTicket.ticket_payments[0] &&
      !currentTicket.ticket_payments[0].is_price_finalized
      ? Number(currentTicket.ticket_payments[0].ticket_price)
      : 0
    : 0;

  const ticketTypeOptions = [
    {
      name: "Unpriced tickets",
      img: NonPricedTicketTag,
      value: "UNPRICED",
    },
    {
      name: "Priced tickets",
      img: isPriceFinalized ? DisabledPricedTicketTag : PricedTicketTag,
      value: "PRICED",
    },
  ].map(({ name, img, value }) => ({
    label: (
      <div className="t-flex t-items-center t-gap-2">
        <img src={img} alt="PricedTicketTag" />
        {name}
      </div>
    ),
    value,
  }));

  const defaultTicketType = ticketTypeOptions.filter(
    ({ value }) =>
      value === (currentTicket?.is_priced_ticket ? "PRICED" : "UNPRICED")
  )[0];

  const { client } = useChatContext();

  const channel = useMemo(() => {
    return client.channel("messaging", currentTicket?.channel_url);
  }, [currentTicket?.channel_url]);

  return (
    <>
      <Slider open={openSlider} withOverlay type="fixed" onClose={closeSlider}>
        {isChatTicketLoading || !currentTicket ? (
          <div className="t-flex t-w-full t-h-full t-justify-center t-items-center">
            <Loader />
          </div>
        ) : (
          <>
            <div className="t-flex t-px-5 t-pt-3 t-w-full t-justify-between t-items-center">
              <div className="t-flex t-w-full t-justify-space-around t-items-center t-gap-x-1">
                <div className="t-text-subtitle-sm">{currentTicket?.name}</div>
              </div>
              <Button
                onClick={closeSlider}
                customType="ghost_icon"
                size="small"
              >
                <Cross />
              </Button>
            </div>
            <Tab.Root
              defaultValue={defaultTab || "DETAILS"}
              className="t-h-full"
            >
              <div className="t-flex t-flex-col t-h-full">
                <Header />
                <Tab.Content value="DETAILS" className="t-h-full">
                  <Formik
                    initialValues={{
                      ticket_title: currentTicket?.ticket_title,
                      ticket_price,
                      is_priced_ticket: currentTicket?.is_priced_ticket,
                    }}
                    validationSchema={ticketPriceSchema}
                    validateOnChange
                    onSubmit={() => {}}
                  >
                    {({ values, setFieldValue, dirty }) => (
                      <Form
                        onChange={handleDisablePushPayment}
                        className="t-m-0"
                      >
                        <FormikListener
                          values={values}
                          callback={(values) => {
                            onFormValuesChange(values, dirty);
                          }}
                        />

                        <div className="t-p-4 t-flex t-flex-col t-gap-4">
                          <Accordion.Root
                            type="multiple"
                            defaultValue={[
                              "TICKET_DETAILS",
                              "RELATED_MESSAGE",
                              "TICKET_TYPE",
                              "UPDATE",
                              "ETD_DETAILS",
                            ]}
                            className="t-flex t-gap-4 t-flex-col"
                          >
                            <Accordion.Item value="TICKET_DETAILS">
                              <Accordion.Trigger>
                                Ticket Details
                              </Accordion.Trigger>
                              <Accordion.Content>
                                <span className="t-grid t-gap-6 t-grid-cols-1 t-grid-flow-row">
                                  <span className="t-grid t-gap-6 t-grid-cols-2">
                                    <InfoItem label="Company">
                                      {isInsideGroup ? (
                                        <span className="t-text-black">
                                          {currentTicket?.company_group?.name}
                                        </span>
                                      ) : (
                                        <ConditionalLink
                                          target="_blank"
                                          to={companyUrl}
                                        >
                                          <span className="t-text-purple">
                                            {currentTicket?.company_group?.name}
                                          </span>
                                        </ConditionalLink>
                                      )}
                                    </InfoItem>
                                    {currentTicket.entity && (
                                      <InfoItem label="Entity">
                                        <ConditionalLink
                                          target="_blank"
                                          to={entityUrl}
                                        >
                                          <div className="t-flex t-items-center t-gap-1">
                                            <ReactCountryFlag
                                              countryCode={
                                                currentTicket.entity
                                                  ?.code_alpha_2
                                              }
                                              svg
                                              title={currentTicket.entity?.name}
                                              style={{
                                                width: "1.25em",
                                                height: "1.25em",
                                              }}
                                              className="t-rounded"
                                            />
                                            {currentTicket.entity?.name}
                                          </div>
                                        </ConditionalLink>
                                      </InfoItem>
                                    )}
                                  </span>

                                  <span className="t-grid t-gap-6 t-grid-cols-2">
                                    {resolvedStatus ? (
                                      <>
                                        <InfoItem label="Created by">
                                          {currentTicket?.assigned_by}
                                        </InfoItem>
                                        <InfoItem label="Created on">
                                          {dayjs(
                                            currentTicket.created_on
                                          ).format(DD_MMM_YYYY)}
                                        </InfoItem>
                                        <InfoItem label="Resolved by">
                                          {currentTicket?.resolved_by}
                                        </InfoItem>
                                        <InfoItem label="Resolved on">
                                          {dayjs(
                                            currentTicket?.resolved_at
                                          ).format(DD_MMM_YYYY)}
                                        </InfoItem>
                                      </>
                                    ) : (
                                      <>
                                        <InfoItem label="Created by">
                                          {currentTicket?.assigned_by}
                                        </InfoItem>
                                        <InfoItem label="Created on">
                                          {dayjs(
                                            currentTicket.created_on
                                          ).format(DD_MMM_YYYY)}
                                        </InfoItem>
                                      </>
                                    )}
                                  </span>
                                  <TextInput
                                    label="Title"
                                    name="ticket_title"
                                    value={values?.ticket_title}
                                  />
                                  <InfoItem label="Tags">
                                    <TicketTagsCombobox
                                      ticketId={currentTicket?.uuid || ""}
                                      currentTags={currentTicket?.tags}
                                    />
                                  </InfoItem>
                                  <InfoItem label="Assignees">
                                    <div className="t-w-full">
                                      <TicketAssigneeDropdown
                                        currentTicketAssignees={
                                          currentTicket?.assignee || []
                                        }
                                        ticketId={currentTicket?.uuid || ""}
                                        showValuesInControlField
                                      />
                                    </div>
                                  </InfoItem>
                                </span>
                              </Accordion.Content>
                            </Accordion.Item>
                            {isAdmin && (
                              <Accordion.Item value="ETD_DETAILS">
                                <Accordion.Trigger>
                                  Estimated time of delivery
                                </Accordion.Trigger>
                                <Accordion.Content>
                                  <Formik
                                    enableReinitialize
                                    initialValues={{
                                      estimatedDeliveryTime:
                                        currentTicket.etd_date || "",
                                    }}
                                    onSubmit={() => {}}
                                  >
                                    {({ values, setFieldValue }) => {
                                      return (
                                        <Form className="t-m-0">
                                          <div>
                                            <Field name="estimatedDeliveryTime">
                                              {({ field }: FieldProps) => {
                                                return (
                                                  <>
                                                    <DateInput
                                                      customSize="small"
                                                      disabled={
                                                        currentTicket.resolved
                                                      }
                                                      {...field}
                                                      saveFormat={YYYY_MM_DD}
                                                      portalId="add-manual-bank-date"
                                                      label="Estimated time of delivery"
                                                      block
                                                      onDateChange={(date) => {
                                                        setFieldValue(
                                                          "estimatedDeliveryTime",
                                                          date
                                                        );
                                                        etdModal.open();
                                                      }}
                                                    />
                                                  </>
                                                );
                                              }}
                                            </Field>
                                          </div>

                                          {ticketETDHistory?.length > 0 && (
                                            <div className="t-mt-6">
                                              <InfoItem label="History">
                                                <div className="t-flex t-flex-col t-w-full t-space-y-2 t-ml-2 t-mt-1">
                                                  {ticketETDHistory?.map(
                                                    (
                                                      {
                                                        created_by,
                                                        created_at,
                                                        description,
                                                        reason,
                                                        uuid,
                                                      },
                                                      index
                                                    ) => (
                                                      <EtdUpdateHistoryCard
                                                        key={uuid}
                                                        isLastStep={
                                                          index ===
                                                          ticketETDHistory.length -
                                                            1
                                                        }
                                                        description={
                                                          description
                                                        }
                                                        reason={reason}
                                                        eventDate={created_at}
                                                        profileName={created_by}
                                                        event=""
                                                      />
                                                    )
                                                  )}
                                                </div>
                                              </InfoItem>
                                            </div>
                                          )}
                                          {etdModal.isOpen &&
                                            values.estimatedDeliveryTime && (
                                              <TicketEtdModal
                                                isOpen={etdModal.isOpen}
                                                close={etdModal.close}
                                                date={
                                                  values.estimatedDeliveryTime
                                                }
                                                ticketId={currentTicket.uuid}
                                              />
                                            )}
                                        </Form>
                                      );
                                    }}
                                  </Formik>
                                </Accordion.Content>
                              </Accordion.Item>
                            )}
                            <Accordion.Item value="RELATED_MESSAGE">
                              <Accordion.Trigger>
                                <div className="t-w-full t-flex t-justify-between t-items-center">
                                  Related Message
                                  {!currentTicket?.is_ticket_channel_created && (
                                    <ChannelCreateDropDown
                                      ticketId={currentTicket?.uuid || ""}
                                      messageId={currentTicket.message_id}
                                    />
                                  )}
                                </div>
                              </Accordion.Trigger>
                              <Accordion.Content>
                                <span className="t-grid t-gap-4 t-grid-cols-1 t-grid-flow-row">
                                  <InfoItem label="Channel">
                                    <ConditionalLink
                                      target="_blank"
                                      to={chatUrl}
                                    >
                                      <span className="t-text-purple">
                                        {currentTicket?.channel_name ||
                                          "General Help"}
                                      </span>
                                    </ConditionalLink>
                                  </InfoItem>
                                  {!currentTicket?.is_non_chat_ticket &&
                                    ticketMessage && (
                                      <>
                                        <div className="t-bg-i-surface-grey t-rounded t-p-1">
                                          <UnstyledChannel channel={channel}>
                                            <MessageBubbleUI
                                              hideOptions
                                              message={ticketMessage!}
                                              otherData={{
                                                sentByTheUser: false,
                                              }}
                                            />
                                          </UnstyledChannel>
                                        </div>
                                      </>
                                    )}
                                  <ConditionalLink target="_blank" to={chatUrl}>
                                    <Button
                                      customType="secondary"
                                      size="small"
                                      type="button"
                                    >
                                      Go to chat
                                    </Button>
                                  </ConditionalLink>
                                </span>
                              </Accordion.Content>
                            </Accordion.Item>
                            <Accordion.Item value="TICKET_TYPE">
                              <Accordion.Trigger>Ticket Type</Accordion.Trigger>
                              <Accordion.Content>
                                <span className="t-grid t-gap-4 t-grid-cols-1 t-grid-flow-row">
                                  <ConditionalToolTip
                                    condition={
                                      isPriceFinalized && ticketTypeTooltipText
                                    }
                                  >
                                    <span>
                                      <Combobox
                                        name="is_priced_ticket"
                                        label="Type"
                                        isMulti={false}
                                        isClearable={false}
                                        isSearchable={false}
                                        isDisabled={
                                          resolvedStatus || isPriceFinalized
                                        }
                                        menuPortalTarget={document.body}
                                        onChange={(
                                          option:
                                            | MultiValue<OptionData>
                                            | SingleValue<OptionData>
                                        ) => {
                                          if (option instanceof Array) {
                                            return null;
                                          }
                                          if (
                                            defaultTicketType.value === "PRICED"
                                          ) {
                                            ticketTypeUpdate.open();
                                            return;
                                          }
                                          setFieldValue(
                                            "is_priced_ticket",
                                            option?.value === "PRICED"
                                          );
                                        }}
                                        value={defaultTicketType}
                                        options={ticketTypeOptions}
                                      />
                                    </span>
                                  </ConditionalToolTip>
                                  {values.is_priced_ticket && (
                                    <InfoItem label="Amount">
                                      <div className="t-grid t-gap-4 t-grid-flow-col">
                                        <PriceInput
                                          name="ticket_price"
                                          type="number"
                                          placeholder="Enter amount"
                                          value={Number(values.ticket_price)}
                                          allowNegative={false}
                                          disabled={resolvedStatus}
                                          required
                                          block
                                        />
                                        <Button
                                          onClick={pushPayment}
                                          isLoading={loadingPushPayment}
                                          disabled={
                                            disablePushPayment ||
                                            values.ticket_price <= 0
                                          }
                                          block
                                        >
                                          Push payment
                                        </Button>
                                      </div>
                                    </InfoItem>
                                  )}
                                  {isTicketPaymentCreated && (
                                    <InfoItem label="Payment History">
                                      <div className="t-overflow-auto t-h-full">
                                        <div className="t-flex t-flex-col t-gap-4 t-pt-1.5 t-items-center ">
                                          {currentTicket.ticket_payments
                                            .filter(
                                              (ticket_payments) =>
                                                ticket_payments.is_price_finalized
                                            )
                                            .map(
                                              (
                                                ticketPayment: TicketPayment,
                                                index
                                              ) => {
                                                return (
                                                  <div
                                                    className="t-flex t-w-full t-justify-between t-items-center"
                                                    key={
                                                      ticketPayment
                                                        .ticket_invoice?.uuid
                                                    }
                                                  >
                                                    <div>
                                                      {ticketPayment.is_ticket_paid ? (
                                                        <div>
                                                          <img
                                                            src={
                                                              TicketResolvedIcon
                                                            }
                                                            alt="TicketResolvedIcon"
                                                          />
                                                        </div>
                                                      ) : (
                                                        <div className="t-text-yellow-50">
                                                          <TicketUnresolved />
                                                        </div>
                                                      )}
                                                    </div>
                                                    <div>
                                                      <div className="t-flex t-flex-col t-w-[240px]">
                                                        <span className=" t-text-neutral-70 t-text-body-sm">
                                                          {`${
                                                            currentTicket.name
                                                          } - #${
                                                            currentTicket
                                                              .ticket_payments
                                                              .length - index
                                                          }`}
                                                        </span>
                                                        <span className=" t-text-neutral-30 t-text-body-sm">
                                                          {`Payment ${
                                                            ticketPayment.is_ticket_paid
                                                              ? "completed"
                                                              : "pending"
                                                          }: $ ${
                                                            ticketPayment
                                                              .ticket_invoice
                                                              ?.amount
                                                          }`}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    {/* ticket payment pdf view */}
                                                    <div>
                                                      <Button
                                                        customType="ghost_icon"
                                                        onClick={(e) =>
                                                          onDownload(
                                                            true,
                                                            ticketPayment
                                                              .ticket_invoice
                                                              ?.document
                                                              ?.uuid || "",
                                                            e
                                                          )
                                                        }
                                                        isLoading={
                                                          isPreviewFetching
                                                        }
                                                      >
                                                        <img
                                                          src={FilePdf}
                                                          alt="PdfIcon"
                                                        />
                                                      </Button>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                    </InfoItem>
                                  )}
                                </span>
                                <TicketTypeUpdateConfirm
                                  {...ticketTypeUpdate}
                                  updateType={() =>
                                    setFieldValue("is_priced_ticket", false)
                                  }
                                />
                              </Accordion.Content>
                            </Accordion.Item>
                            <Accordion.Item value="UPDATE">
                              <Accordion.Trigger>Update</Accordion.Trigger>
                              <Accordion.Content>
                                <TextArea
                                  placeholder="Add your update here..."
                                  onChange={(e) =>
                                    setDescription(e.target.value)
                                  }
                                  onKeyUp={addNotes}
                                  value={description}
                                  disabled={isNoteCreating}
                                  note="Press Shift + Enter for a new line"
                                />
                                {ticketNotes.length > 0 && (
                                  <InfoItem label="Update History">
                                    <div
                                      className="t-pt-2 t-gap-8 t-flex t-flex-col t-overflow-y-auto t-max-h-80"
                                      id="update-card"
                                    >
                                      <InfiniteScroll
                                        dataLength={
                                          ticketNotes.length * total_pages
                                        }
                                        next={goToNextPage}
                                        hasMore={ticketNotesPage < total_pages}
                                        scrollableTarget="update-card"
                                        loader={
                                          <div className="t-w-full t-pb-2 t-text-center t-text-subtitle-sm">
                                            Loading updates...
                                          </div>
                                        }
                                      >
                                        <div className="t-flex t-flex-col t-gap-4">
                                          {ticketNotes?.map(
                                            (
                                              ticketNote: TicketNotes,
                                              index: number
                                            ) => (
                                              <div
                                                key={index}
                                                className="t-flex t-gap-2 t-items-start t-w-full"
                                              >
                                                <img
                                                  src={ClockUpdate}
                                                  alt="Clock Update Icon"
                                                />
                                                <div className="t-flex t-flex-col t-gap-1">
                                                  <span className="t-text-body-sm t-text-neutral-70 t-text-justify t-whitespace-pre-wrap">
                                                    {ticketNote?.description ??
                                                      "No description available"}
                                                  </span>
                                                  <span className="t-text-body-sm t-text-neutral-30">
                                                    {ticketNote.created_by.name}{" "}
                                                    added on{" "}
                                                    {formatDate(
                                                      ticketNote.created_at
                                                    )}{" "}
                                                    at{" "}
                                                    {formatTime(
                                                      ticketNote.created_at
                                                    )}
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          )}
                                        </div>
                                      </InfiniteScroll>
                                    </div>
                                  </InfoItem>
                                )}
                              </Accordion.Content>
                            </Accordion.Item>
                          </Accordion.Root>
                        </div>
                        <div className=" t-bg-surface t-sticky t-bottom-0 t-flex t-flex-col t-gap-4 t-p-4  t-border t-border-solid  t-border-b-0 t-border-r-0 t-border-l-0 t-border-t-neutral-10">
                          {currentTicket.resolved ? (
                            <Button
                              onClick={() => {
                                markTicketStatus(false);
                              }}
                              customType="primary"
                            >
                              Mark as unresolved
                            </Button>
                          ) : (
                            <Button
                              onClick={() => {
                                markTicketStatus(true);
                              }}
                              customType="success"
                            >
                              Mark as resolved
                            </Button>
                          )}
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Tab.Content>

                <Tab.Content value="CHAT" className="t-h-full t-relative">
                  <ChatTab
                    channelId={currentTicket.channel_url}
                    messageId={currentTicket?.message_id || undefined}
                  />
                </Tab.Content>
              </div>
            </Tab.Root>
          </>
        )}
      </Slider>
    </>
  );
};
