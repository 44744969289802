import { BlockedButton } from "components/BlockedButton/BlockedButton";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { ArrowRight } from "components/icons/ArrowRight";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import { PlaidToDirectMigration } from "components/PlaidToDirectMigration/PlaidToDirectMigration";
import {
  CONNECT_BANK_ACCOUNT,
  CONNECT_REVENUE_SOURCE,
} from "constants/subscriptionPermissionFeatures";
import { onConnectArgs } from "hooks/useBankConnect";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { AccountCard } from "pages/Books/DataSources/DataSourcesList";
import { ComponentProps, useState } from "react";
import PlaidMultipleBankLogoCircle from "static/images/PlaidMultipleBankLogoCircle.svg";
import {
  Connection,
  useGetAllConnectionsQuery,
} from "store/apis/booksConnections";
import { OtherBankingInstitution } from "./OtherBankingInstitution";
import { Crown } from "components/icons/Crown";
// import BareEntityCombobox from "components/EntitySelector/BareEntityCombobox";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { EntitySelector } from "components/EntitySelector/EntitySelector";

export type ConnectionsModalProps = {
  onConnect: (args: onConnectArgs) => void;
  entityIdFromParent?: string;
  showEntityLabel?: boolean;
};

export type BankingConnectionsProps = {
  connection: Connection;
  onConnect: (args: onConnectArgs) => void;
  onPlaidToDirectMigration: (connectionId: string) => void;
  entityId: string;
};

export const RevenueConnections = ({
  connection,
  onConnect,
  entityId,
}: {
  connection: Connection;
  entityId: string;
} & Pick<ComponentProps<typeof ConnectionsModal>, "onConnect">) => {
  const { isCpa } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();

  return (
    <AccountCard
      key={connection.uuid}
      logo={
        <img
          className="t-w-8 t-h-8 t-rounded-full"
          src={connection.logo_url}
          alt=""
        />
      }
      title={
        <div className="t-flex t-flex-col t-gap-1.5">
          <div>{connection.name}</div>
          <div className="t-text-purple t-text-body-sm t-flex t-gap-1 t-items-center">
            <Crown /> Books Standard Plan Exclusive
          </div>
        </div>
      }
      description="Direct API integration for fetching revenue data"
      CTA={
        <PermissionBasedUI
          errorMessage="You need to upgrade to Plus Plan to connect revenue data sources."
          feature={CONNECT_REVENUE_SOURCE}
          blockedUI={<BlockedButton size="small">Connect</BlockedButton>}
          entityId={entityId}
        >
          <Button
            size="small"
            onClick={() =>
              onConnect({
                connectionId: connection.uuid,
                entityIdInArg: entityId,
                invokedFrom: isCpa
                  ? `/books/data-sources?company=${groupId}&entity=${entityId}`
                  : `/books/data-sources?entity=${entityId}`,
              })
            }
          >
            Connect <ArrowRight color="currentColor" />
          </Button>
        </PermissionBasedUI>
      }
    />
  );
};

export const BankingConnections = ({
  connection,
  onConnect,
  onPlaidToDirectMigration,
  entityId,
}: BankingConnectionsProps &
  Pick<ComponentProps<typeof ConnectionsModal>, "onConnect">) => {
  const { isCpa } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();

  const onConnectClick = (connectionId: string) => {
    if (connection?.is_plaid_account_connected) {
      onPlaidToDirectMigration(connectionId);

      return;
    }

    if (connection) {
      onConnect({
        connectionId: connection.uuid,
        entityIdInArg: entityId,
        invokedFrom: isCpa
          ? `/books/data-sources?company=${groupId}&entity=${entityId}`
          : `/books/data-sources?entity=${entityId}`,
      });
    }
  };

  return (
    <AccountCard
      key={connection.uuid}
      logo={
        <img
          className="t-w-8 t-h-8 t-rounded-full"
          src={connection.logo_url}
          alt=""
        />
      }
      title={connection.name}
      description="Direct API integration for fetching transactions data"
      CTA={
        <>
          {!connection.is_connection_available ? (
            <PermissionBasedUI
              errorMessage="You need to upgrade to Plus Plan to connect data sources."
              feature={CONNECT_BANK_ACCOUNT}
              blockedUI={<BlockedButton size="small">Connect</BlockedButton>}
              entityId={entityId}
            >
              <Button
                size="small"
                onClick={() => onConnectClick(connection.uuid)}
              >
                Connect <ArrowRight color="currentColor" />
              </Button>
            </PermissionBasedUI>
          ) : (
            <Tag tagType="green">
              <span className="t-text-body-sm">Connected</span>
            </Tag>
          )}
        </>
      }
    />
  );
};

export const ConnectionsModal = ({
  onConnect,
  entityIdFromParent,
  showEntityLabel = true,
  onComplete,
  onManualBankAddSuccess,
}: ConnectionsModalProps &
  Pick<
    ComponentProps<typeof OtherBankingInstitution>,
    "onManualBankAddSuccess" | "onComplete"
  >) => {
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const entityIdFromQuery = useCurrentEntityId();
  const entityId = entityIdFromQuery || entityIdFromParent!;

  const {
    data: connections,
    isLoading,
    isSuccess,
  } = useGetAllConnectionsQuery(
    {
      groupId: groupId!,
      entityId: entityId!,
    },
    { skip: !groupId || !entityId }
  );
  const [currentConnectionId, setCurrentConnectionId] = useState<string | null>(
    null
  );
  const plaidToDirectMigrationStrategyModal = useModal();
  const otherBankingInstitutionModal = useModal();

  const onPlaidToDirectMigration = (connectionId: string) => {
    plaidToDirectMigrationStrategyModal.open();
    setCurrentConnectionId(connectionId);
  };

  const revenueConnections = connections?.filter(
    ({ connection_provider }) => connection_provider === "STRIPE"
  );
  const bankingConnections = connections?.filter(
    ({ connection_provider }) => connection_provider !== "STRIPE"
  );

  return (
    <>
      <Async.Root
        isSuccess={isSuccess}
        isLoading={isLoading}
        isEmpty={entities?.length === 0}
      >
        <Async.Empty>
          <EmptyScreen
            text={
              <div className="t-text-text-30 t-flex t-flex-col">
                <span className="t-text-subtext">No data sources found</span>
              </div>
            }
          ></EmptyScreen>
        </Async.Empty>
        <Async.Success>
          <div>
            {showEntityLabel ? (
              <div className="t-flex t-items-center t-mb-4 t-gap-2 t-text-text-30">
                Connecting for:
                {entities && (
                  <EntitySelector
                    showOnlyUSEntities={true}
                    disableNonUsEntities={true}
                    showGoToEntity={true}
                    toolTipPlacement="right"
                    left={0}
                  />
                )}
              </div>
            ) : null}
            <div className="t-flex t-flex-col t-gap-3">
              {bankingConnections?.map((connection) => (
                <BankingConnections
                  key={connection.uuid}
                  connection={connection}
                  onConnect={onConnect}
                  onPlaidToDirectMigration={onPlaidToDirectMigration}
                  entityId={entityId}
                />
              ))}
              {revenueConnections?.map((connection) => (
                <RevenueConnections
                  key={connection.uuid}
                  connection={connection}
                  onConnect={onConnect}
                  entityId={entityId}
                />
              ))}
              <AccountCard
                logo={
                  <img
                    src={PlaidMultipleBankLogoCircle}
                    className="t-rounded-full t-w-6 t-h-6"
                    alt=""
                  />
                }
                title="Other banking institutions"
                description="Connect other banks securely via Plaid or add manually"
                CTA={
                  groupId && (
                    <PermissionBasedUI
                      errorMessage="You need to upgrade to Plus Plan to connect data sources."
                      feature={CONNECT_BANK_ACCOUNT}
                      blockedUI={
                        <BlockedButton size="small">Search</BlockedButton>
                      }
                      entityId={entityId}
                    >
                      <Modal.Root
                        open={otherBankingInstitutionModal.isOpen}
                        onOpenChange={otherBankingInstitutionModal.toggle}
                      >
                        <Modal.Trigger asChild>
                          <Button size="small">
                            Search <ArrowRight color="currentColor" />
                          </Button>
                        </Modal.Trigger>
                        <Modal.Content size="large">
                          <Modal.Header>
                            <Modal.Title>Other Banking Institution</Modal.Title>
                            <Modal.Close tabIndex={-1} />
                          </Modal.Header>
                          <OtherBankingInstitution
                            entityId={entityId}
                            onConnect={onConnect}
                            onPlaidToDirectMigration={onPlaidToDirectMigration}
                            onComplete={(args) =>
                              onComplete && onComplete(args)
                            }
                            onManualBankAddSuccess={(args) => {
                              onManualBankAddSuccess &&
                                onManualBankAddSuccess(args);
                              otherBankingInstitutionModal.close();
                            }}
                          />
                        </Modal.Content>
                      </Modal.Root>
                    </PermissionBasedUI>
                  )
                }
              />
            </div>
          </div>

          <Modal.Root
            open={plaidToDirectMigrationStrategyModal.isOpen}
            onOpenChange={plaidToDirectMigrationStrategyModal.close}
          >
            <Modal.Content>
              {currentConnectionId && (
                <PlaidToDirectMigration
                  connectionId={currentConnectionId}
                  onClose={plaidToDirectMigrationStrategyModal.close}
                  entityId={entityId}
                />
              )}
            </Modal.Content>
          </Modal.Root>
        </Async.Success>
      </Async.Root>
    </>
  );
};
