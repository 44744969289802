import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TransactionState {
  merchantsToMerge: string[];
}

const initialState: TransactionState = {
  merchantsToMerge: [],
};

export const vendors = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    setMerchantToMerge: (state, action: PayloadAction<string[]>) => {
      state.merchantsToMerge = action.payload;
    },
  },
});

export const { setMerchantToMerge } = vendors.actions;

export default vendors.reducer;
