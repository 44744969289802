import { ResponsiveLine } from "@nivo/line";
import { Crosshair } from "components/RevenueMetrics/RevenueChart";
import { colors } from "constants/colors";
import { DD_MMM_YYYY, MMM_YY } from "constants/date";
import dayjs from "dayjs";
import { useState } from "react";
import { formatAmount } from "utils/foramtAmount";
import { HideableAmount } from "../HideBalancesAndAmounts";

const Chart = ({
  title,
  chartData,
}: {
  title: string;
  chartData: {
    x: string;
    y: number;
  }[];
}) => {
  const [hoverSectionId, setHoverPoint] = useState("");

  const data = [
    {
      id: "cash",
      data: chartData,
    },
  ];

  return (
    <ResponsiveLine
      key={title}
      onMouseMove={(p) => {
        // @ts-ignore
        const [point] = p.points;
        setHoverPoint(point?.id);
      }}
      onMouseLeave={() => setHoverPoint("")}
      margin={{ bottom: 20, left: 20, right: 20 }}
      enableArea={true}
      enablePoints={false}
      data={data}
      pointColor={colors.purple[40]}
      defs={[
        {
          id: "cashArea",
          type: "linearGradient",
          colors: [
            { offset: 0, color: colors.purple[30] },
            { offset: 100, color: colors.purple[0] },
          ],
          gradientTransform: "rotate(350 0.5 0.5)",
        },
      ]}
      fill={[{ match: { id: "cash" }, id: "cashArea" }]}
      colors={[colors.purple[40]]}
      enableGridX={false}
      enableGridY={false}
      lineWidth={1.5}
      enableTouchCrosshair={true}
      useMesh={true}
      axisLeft={null}
      axisBottom={{
        renderTick: (tick) => {
          const isLast = tick.tickIndex === chartData.length - 1;
          const isFirst = tick.tickIndex === 0;

          if (isFirst || isLast) {
            return (
              <g transform={`translate(${tick.x},${tick.y})`}>
                <text dy={16} textAnchor="middle" fontSize={10} fill="#666">
                  {dayjs(tick.value).format(MMM_YY)}
                </text>
              </g>
            );
          }

          return <></>;
        },
      }}
      sliceTooltip={({ slice }) => {
        return (
          <div
            className={
              "t-bg-neutral-100 t-p-3 t-rounded t-text-subtext-sm t-text-white t-min-w-56 t-min-h-16 t-flex t-flex-col t-gap-2 t-justify-center t-items-center"
            }
          >
            <div>
              {dayjs(slice.points[0].data.xFormatted).format(DD_MMM_YYYY)}
            </div>
            <div className="t-flex t-gap-2">
              <div
                style={{
                  background:
                    "linear-gradient(350deg, #F7F4FF 4.17%, #BBA7FF 129.05%)",
                }}
                className="t-w-4 t-h-4 t-rounded-sm"
              ></div>
              {title}:
              <HideableAmount>
                {formatAmount(Number(slice.points[0].data.y))}
              </HideableAmount>
            </div>
          </div>
        );
      }}
      enableSlices="x"
      layers={[
        "grid",
        "markers",
        "axes",
        "areas",
        (props) => <Crosshair {...props} hoverSectionId={hoverSectionId} />,
        "lines",
        "slices",
        "points",
        "mesh",
        "legends",
      ]}
    />
  );
};

export const CashBalanceChart = ({
  chartData,
}: {
  chartData: {
    x: string;
    y: number;
  }[];
}) => {
  return (
    <div className="t-w-full t-h-48">
      <Chart chartData={chartData} title="Cash Balance" />
    </div>
  );
};
