import { TooltipContentProps } from "@radix-ui/react-tooltip";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { ENTITY_SWITCHER_CLICKED } from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useEffect } from "react";
import { useGetGroupDataQuery } from "store/apis/group";
import { entitiesSortedByCountry } from "utils/entitiesSortedByCountry";
import BareEntityCombobox from "./BareEntityCombobox";

type EntitySelectorProps = {
  showOnlyUSEntities?: boolean;
  handleEntitySelect?: (entityId: string) => void;
  toolTipPlacement?: TooltipContentProps["side"];
  right?: number;
  disableNonUsEntities?: boolean;
  showGoToEntity?: boolean;
  size?: "small" | "regular" | "large";
  left?: number;
};

export const EntitySelector = ({
  showOnlyUSEntities = false,
  toolTipPlacement = "left",
  handleEntitySelect,
  right = 0,
  disableNonUsEntities = false,
  showGoToEntity = false,
  size = "regular",
  left,
}: EntitySelectorProps) => {
  const { update } = useUpdateQuery();
  const query = useQuery();
  let selectedEntityId = query.get("entity");
  const groupId = query.get("company") || "";
  const { entities } = useCurrentGroupContext();
  const { sortedEntities, usEntities } = entitiesSortedByCountry(entities);
  const displayedEntities = showOnlyUSEntities ? usEntities : sortedEntities;
  selectedEntityId ??= displayedEntities?.[0]?.uuid;
  const { trackEvent } = useAnalytics();
  const { isCustomer } = useRoleBasedView();

  const { isFetching } = useGetGroupDataQuery(
    { groupId },
    { skip: !groupId || isCustomer }
  );

  const {
    name: selectedEntityName,
    country: selectedEntityCountry,
    country_code: selectedEntityCountryCode,
    uuid: currentSelectedEntityId,
  } = sortedEntities.find(({ uuid }) => uuid === selectedEntityId) ||
  displayedEntities?.[0] ||
  {};

  useEffect(() => {
    if (currentSelectedEntityId) {
      update({
        query: "entity",
        value: currentSelectedEntityId,
      });
    }
  }, [currentSelectedEntityId]);

  const onEntitySelect = ({ uuid }: { uuid: string }) => {
    trackEvent(ENTITY_SWITCHER_CLICKED, {
      url: window.location.href,
    });
    if (handleEntitySelect) {
      handleEntitySelect(uuid);
      return;
    }
    update({ query: "entity", value: uuid });
  };

  const isEntitySelected =
    selectedEntityId &&
    selectedEntityCountry &&
    selectedEntityName &&
    selectedEntityCountryCode;
  return (
    <ConditionalToolTip
      condition={isEntitySelected && selectedEntityName}
      side={toolTipPlacement}
    >
      <span>
        <BareEntityCombobox
          size={size}
          isDisabled={!isEntitySelected || isFetching}
          isLoading={!isEntitySelected || isFetching}
          onChange={(value) => {
            onEntitySelect({ uuid: value });
          }}
          right={right}
          left={left}
          value={selectedEntityId}
          showOnlyUSEntities={showOnlyUSEntities}
          disableNonUsEntities={disableNonUsEntities}
          isEntitySelected={isEntitySelected}
          showGoToEntity={showGoToEntity}
        />
      </span>
    </ConditionalToolTip>
  );
};
