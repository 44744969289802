import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { TableUI } from "components/design/TableUI";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import { PriceInput } from "components/PriceInput/PriceInput";
import { Field, FieldProps, useFormikContext } from "formik";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { MerchantCSVResponse } from "types/Models/vendors";
import { InfoToolTip } from "./AddVendor/AddVendorModal";

const DeleteVendor = ({ vendor }: { vendor: MerchantCSVResponse }) => {
  const { values, setFieldValue } = useFormikContext<{
    merchants: MerchantCSVResponse[];
  }>();

  const deleteMerchantRow = (id?: string) => {
    const newValues = values.merchants.filter(
      (merchants) => merchants.id !== id
    );
    setFieldValue("merchants", newValues);
  };

  const disableDelete = values.merchants?.length <= 1;

  return (
    <ConditionalToolTip condition={disableDelete && "One merchant is required"}>
      <div
        className={classNames("t-flex t-items-center", {
          "t-opacity-30": disableDelete,
        })}
      >
        <Button
          type="button"
          customType="ghost_icon"
          onClick={() => deleteMerchantRow(vendor?.id)}
          disabled={disableDelete}
        >
          <span className="t-text-text-30">
            <DeleteIcon />
          </span>
        </Button>
      </div>
    </ConditionalToolTip>
  );
};

const createColumn = createColumnHelper<MerchantCSVResponse>();

const columns = [
  createColumn.accessor("name", {
    size: 24,
    header: () => <Label required>Name</Label>,
    cell: (info) => {
      const rowIndex = info.row.index;
      return (
        <TextInput
          hideError
          name={`merchants[${rowIndex}].name`}
          block
          placeholder="John Doe"
          required
          customSize="small"
        />
      );
    },
  }),

  createColumn.accessor("email", {
    size: 24,
    header: "Email",
    cell: (info) => {
      const rowIndex = info.row.index;
      return (
        <TextInput
          block
          hideError
          placeholder="vendor@inkle.io"
          type="email"
          customSize="small"
          name={`merchants[${rowIndex}].email`}
        />
      );
    },
  }),

  createColumn.accessor("season", {
    size: 24,
    header: "Season",
    cell: (info) => {
      const rowIndex = info.row.index;
      return (
        <Field name={`merchants[${rowIndex}].season`}>
          {({ field }: FieldProps) => (
            <DateInput
              {...field}
              name={field.name}
              showYearPicker
              showYearDropdown
              required
              customSize="small"
              placeholder="YYYY"
              dateFormat="yyyy"
            />
          )}
        </Field>
      );
    },
  }),

  createColumn.accessor("total_amount", {
    size: 24,
    header: () => (
      <div className="t-flex t-justify-end t-items-center t-gap-2">
        1099 AMOUNT
        <InfoToolTip
          info={
            <>
              Sum of the payments made during the season
              <br />
              that qualify to be reported on IRS Form 1099.
            </>
          }
        />
      </div>
    ),
    cell: (info) => {
      const rowIndex = info.row.index;
      return (
        <Field name={`merchants[${rowIndex}].total_amount`}>
          {({ field }: FieldProps) => (
            <div>
              <PriceInput
                {...field}
                required
                rightAlign
                placeholder="$ 0.00"
                customSize="small"
              />
            </div>
          )}
        </Field>
      );
    },
  }),

  createColumn.display({
    size: 4,
    header: "",
    id: "ACTIONS",
    cell: (info) => {
      return <DeleteVendor vendor={info.row.original} />;
    },
  }),
];

export const AddMerchantsTable = () => {
  const { values } = useFormikContext<{
    merchants: MerchantCSVResponse[];
  }>();

  const merchants = values.merchants || [];

  const table = useReactTable({
    data: merchants,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return <TableUI table={table} />;
};
