import { VendorBills } from "components/VendorBills/VendorBills";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { usePageTitle } from "hooks/usePageTitle";

export const Bills = () => {
  usePageTitle("Bills");
  const entityId = useCurrentEntityId();

  return <VendorBills entityId={entityId} />;
};
