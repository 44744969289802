import { VendorDetail } from "components/Vendors/VendorDetail/VendorDetail";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useParams } from "react-router-dom";

export const VendorDetailPage = ({
  breadcrumb,
}: {
  breadcrumb: { link?: string; name: string }[];
}) => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { vendorId } = useParams<{ vendorId: string }>();

  return (
    <VendorDetail
      breadcrumb={breadcrumb}
      entityId={entityId}
      groupId={groupId}
      vendorId={vendorId}
    />
  );
};
