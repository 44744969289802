import React from "react";

export const CrossedEye = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_2726_23059)">
        <path
          d="M15.8806 7.45406C15.2365 6.0572 14.2931 4.81908 13.1172 3.82739L15.1386 1.80606L14.1952 0.862061L11.9999 3.05539C10.7848 2.35254 9.40359 1.98812 7.99991 2.00006C2.99991 2.00006 0.704581 6.17406 0.119247 7.45406C0.040673 7.6256 0 7.81205 0 8.00073C0 8.1894 0.040673 8.37586 0.119247 8.54739C0.763338 9.94425 1.70673 11.1824 2.88258 12.1741L0.861914 14.1954L1.80458 15.1381L3.99991 12.9447C5.21501 13.6476 6.59624 14.012 7.99991 14.0001C12.9999 14.0001 15.2952 9.82606 15.8806 8.54606C15.959 8.37472 15.9996 8.1885 15.9996 8.00006C15.9996 7.81163 15.959 7.6254 15.8806 7.45406ZM1.33325 8.00739C1.83325 6.91073 3.79525 3.33339 7.99991 3.33339C9.04631 3.32728 10.079 3.57139 11.0119 4.04539L9.83525 5.22206C9.1952 4.79713 8.42785 4.60672 7.6634 4.68316C6.89895 4.75959 6.18449 5.09815 5.64125 5.6414C5.09801 6.18464 4.75944 6.8991 4.68301 7.66355C4.60658 8.428 4.79698 9.19535 5.22191 9.83539L3.83591 11.2214C2.7695 10.353 1.91383 9.25411 1.33325 8.00739ZM9.99991 8.00006C9.99991 8.53049 9.7892 9.0392 9.41413 9.41427C9.03906 9.78935 8.53035 10.0001 7.99991 10.0001C7.70292 9.99891 7.41005 9.93054 7.14325 9.80006L9.79991 7.14339C9.93039 7.41019 9.99876 7.70307 9.99991 8.00006ZM5.99991 8.00006C5.99991 7.46963 6.21063 6.96092 6.5857 6.58585C6.96077 6.21077 7.46948 6.00006 7.99991 6.00006C8.29691 6.00121 8.58978 6.06959 8.85658 6.20006L6.19991 8.85673C6.06944 8.58993 6.00106 8.29705 5.99991 8.00006ZM7.99991 12.6667C6.95352 12.6728 5.92082 12.4287 4.98791 11.9547L6.16458 10.7781C6.80463 11.203 7.57198 11.3934 8.33643 11.317C9.10088 11.2405 9.81534 10.902 10.3586 10.3587C10.9018 9.81548 11.2404 9.10102 11.3168 8.33657C11.3932 7.57212 11.2028 6.80477 10.7779 6.16473L12.1632 4.77939C13.2318 5.6486 14.0879 6.7501 14.6666 8.00006C14.1572 9.10473 12.1946 12.6667 7.99991 12.6667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2726_23059">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
