import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { NumericInput } from "components/NumericInput/NumericInput";
import {
  RaiseSliderCard,
  RaiseSliderItem,
} from "components/RaiseProfileInfo/RaiseProfileInfo";
import { DeleteIcon } from "components/icons/delete";
import { FieldArray, Form, useFormikContext } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { ReactNode } from "react";
import {
  RaisePofile,
  RaiseProfileUpdatePayload,
  useGetRaiseChoicesQuery,
} from "store/apis/raiseProfiles";
import { useGetTeamMembersQuery } from "store/apis/teamSetting";
import randomBytes from "randombytes";

const FounderDetail = ({
  index,
  remove,
}: {
  index: number;
  remove: (obj: any) => void;
}) => {
  const { values } = useFormikContext<RaiseProfileUpdatePayload>();

  return (
    <div className="t-flex t-flex-col t-gap-3">
      <div className="t-flex t-justify-between t-items-center">
        <div className="t-text-subtext-sm">Founder {index + 1}</div>
        {values?.cofounder_data.length > 1 && (
          <Button
            customType="ghost_icon"
            size="small"
            type="button"
            onClick={() => remove(index)}
          >
            <DeleteIcon color="currentColor" />
          </Button>
        )}
      </div>
      <div className="t-flex t-flex-col t-gap-4">
        <div className="t-flex t-gap-4">
          <TextInput
            name={`cofounder_data.${index}.cofounder_name`}
            label="Name"
          />
          <TextInput
            name={`cofounder_data.${index}.cofounder_linkedIn_url`}
            label="LinkedIn"
          />
        </div>
        <TextInput
          name={`cofounder_data.${index}.cofounder_email`}
          label="Email"
        />
      </div>
    </div>
  );
};

export const RaiseProfileEdit = ({ children }: { children: ReactNode }) => {
  const { uuid: groupId, name } = useCurrentGroupContext();
  const { data: choices, isLoading } = useGetRaiseChoicesQuery();
  const { data: teamMembers } = useGetTeamMembersQuery({
    groupId,
  });

  const { values } = useFormikContext<RaiseProfileUpdatePayload>();

  const founderData = {
    cofounder_name: "",
    cofounder_email: "",
    cofounder_linkedIn_url: "",
    id: randomBytes(10).toString("hex"),
  };

  return (
    <Form className="t-m-0">
      <div className="t-flex t-flex-col t-gap-6 t-p-5 t-pb-0">
        <RaiseSliderCard title="Startup details" column={false}>
          <RaiseSliderItem label="Brand name">{name}</RaiseSliderItem>
          <TextInput
            name="description"
            label="Startup description (max 150 characters)"
          />
          <TextArea
            name="company_pitch"
            label="Startup description (max 150 words)"
          />
          <TextInput name="group_website" label="Website" />
          <TextInput name="group_linkedIn_url" label="LinkedIn" />
          <TextInput name="year_founded" label="Founded Year" />
        </RaiseSliderCard>

        <FieldArray name="cofounder_data">
          {({ push, remove }) => (
            <RaiseSliderCard
              title={
                <div className="t-flex t-justify-between t-items-center">
                  <div>Founder details</div>
                  <Button
                    type="button"
                    size="small"
                    onClick={() => push(founderData)}
                  >
                    Add
                  </Button>
                </div>
              }
              column={false}
            >
              {values.cofounder_data?.map(({ id }, index) => (
                <FounderDetail key={id} index={index} remove={remove} />
              ))}
            </RaiseSliderCard>
          )}
        </FieldArray>

        <RaiseSliderCard title="Other details" column={false}>
          <NumericInput
            label="Raised till date"
            storeNumeric
            fieldProps={{ name: "raised_till_date" }}
            numericProps={{
              thousandSeparator: true,
              prefix: "$",
            }}
          />

          <Combobox
            withForm
            label="Type of next round"
            name="round_type"
            components={{ ClearIndicator: () => null }}
            value={{
              value: values.round_type,
              label: values.round_type,
            }}
            options={choices?.round_types.map((r) => ({
              label: r,
              value: r,
            }))}
          />

          <Combobox
            withForm
            label="Markets catered to"
            name="markets"
            components={{ ClearIndicator: () => null }}
            isMulti
            value={values.markets.map((i) => ({
              label: i,
              value: i,
            }))}
            options={choices?.markets.map((r) => ({
              label: r,
              value: r,
            }))}
          />

          <Combobox
            withForm
            label="Category of industry"
            name="industry_types"
            components={{ ClearIndicator: () => null }}
            isMulti
            creatable
            value={values.industry_types.map((i) => ({
              label: i,
              value: i,
            }))}
            options={choices?.industry_types.map((r) => ({
              label: r,
              value: r,
            }))}
          />

          <Combobox
            withForm
            label="Business model"
            name="business_models"
            components={{ ClearIndicator: () => null }}
            isMulti
            value={values.business_models.map((i) => ({
              label: i,
              value: i,
            }))}
            options={choices?.business_models.map((r) => ({
              label: r,
              value: r,
            }))}
          />

          <TextInput
            label="Current ARR"
            name="current_annual_revenue"
            customPrefix="$"
          />
        </RaiseSliderCard>
        {children}
      </div>
    </Form>
  );
};
