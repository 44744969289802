import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import ProgressBar from "components/DesignSystem/ProgressBar/ProgressBar";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { Cross } from "components/icons/Cross";
import { SolidCheck } from "components/icons/SolidCheck";
import { DeleteIcon } from "components/icons/delete";
import { useToast } from "hooks/useToast";
import { ComponentProps, ReactNode, useEffect, useState } from "react";
import {
  DropzoneOptions,
  ErrorCode,
  FileRejection,
  useDropzone,
} from "react-dropzone";
import UploadFile from "static/images/UploadFile.svg";
import { FileIcon } from "utils/fileTypeIcon";
import Loader from "./loader";

type UploadFileSectionProps = DropzoneOptions & {
  subText?: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
};

export const DnDArea = (props: UploadFileSectionProps) => {
  const { alertToast } = useToast();

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections[0].errors.forEach((e) => {
      if (e.code === ErrorCode.FileTooLarge) {
        alertToast({ message: e.message || "File is too large" });
      }

      if (e.code === ErrorCode.FileInvalidType) {
        const supportedFileTypes = Object.values(props.accept ?? {}).flatMap(
          (v) => v
        );

        alertToast({
          message: `Unsupported file uploaded. We only support ${supportedFileTypes.join(
            ","
          )}`,
        });
      }
    });
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      onDropRejected: onDropRejected,
      maxSize: 100000000,
      ...props,
    });

  return (
    <button
      disabled={props.disabled}
      {...getRootProps()}
      className={classNames(
        "all:unset t-border t-rounded-lg t-border-dashed t-px-6 t-py-8 t-text-body t-text-text-100 t-flex t-items-center t-justify-center t-flex-col t-gap-3 t-bg-surface-grey t-h-56 disabled:t-cursor-not-allowed disabled:t-opacity-50 focus-visible:t-ring-1 focus-visible:t-ring-purple-40 focus-visible:t-ring-offset-1 hover:t-border-purple-40 active:t-border-purple-40 t-w-full",
        {
          "t-border-dark_green": isDragAccept,
          "t-border-red-50": isDragReject,
          "t-border-neutral-40": !isDragReject && !isDragAccept,
        }
      )}
      {...getRootProps()}
      onClick={open}
      id="file"
    >
      <input {...getInputProps()} id="file" />
      {props.isLoading ? (
        <Loader size="regular" />
      ) : (
        <>
          <img
            src={UploadFile}
            alt="Upload File"
            className={classNames({
              "t-animate-bounce": isDragAccept,
            })}
          />
          <p className="t-text-subtext t-text-text-100 t-m-0">
            Click or drag and drop to upload
          </p>
          {props.subText && (
            <p className="t-text-body-sm t-text-text-30 t-m-0">
              {props.subText}
            </p>
          )}
        </>
      )}
    </button>
  );
};

type FileProps = {
  file: File;
  onCancel: () => void;
  isUploading?: boolean;
  isUploaded?: boolean;
  onDelete?: () => void;
};

export const File = ({
  file,
  isUploading,
  isUploaded,
  onCancel,
  onDelete,
}: FileProps) => {
  const fileSize = (size: number) => {
    if (size > 1024 * 1024) {
      return `${(size / (1024 * 1024)).toFixed(2)} MB`;
    } else {
      return `${(size / 1024).toFixed(2)} KB`;
    }
  };

  return (
    <>
      <div className="t-border t-border-solid t-rounded t-border-neutral-20 t-bg-surface-lighter-grey t-p-3 t-flex t-items-center t-gap-3 t-w-full">
        <FileIcon fileType={file.type} width="40" height="40" />
        <div className="t-text-text-100 t-text-body-sm t-grid t-gap-1 t-w-full">
          <div className="t-text-blue-100 t-text-subtitle-sm t-w-4/5 t-truncate">
            {file.name}
          </div>
          <span className="t-text-neutral t-text-caption">
            {fileSize(file.size)}
          </span>
          <FakeProgress isSuccess={isUploaded} isLoading={isUploading} />
        </div>
        {isUploaded ? (
          <div className="t-ml-auto t-text-neutral-70">
            <Button customType="ghost_icon" size="small" onClick={onDelete}>
              <DeleteIcon />
            </Button>
          </div>
        ) : (
          <div className="t-ml-auto">
            <Button customType="ghost_icon" size="small" onClick={onCancel}>
              <Cross />
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export const UploadLabel = (props: ComponentProps<typeof Label>) => {
  return <Label {...props} className="t-text-text-60 t-text-body-sm" />;
};

export const FakeProgress = ({
  isSuccess,
  isLoading,
  interval = 500,
  showUploadCompleted = true,
}: {
  isLoading?: boolean;
  isSuccess?: boolean;
  interval?: number;
  showUploadCompleted?: boolean;
}) => {
  const [progress, setProgress] = useState(0);
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (isLoading) {
      const timer = setInterval(() => {
        setProgress((prev) =>
          Math.min(prev + Math.floor(Math.random() * 11), 95)
        );
      }, interval);
      return () => clearInterval(timer);
    }
  }, [isLoading, interval]);

  useEffect(() => {
    if (isSuccess) {
      setProgress(100);
      const timer = setTimeout(() => {
        setShowSuccess(true);
      }, interval);
      return () => clearTimeout(timer);
    }
  }, [isSuccess, interval]);

  if (showSuccess && showUploadCompleted) {
    return (
      <div className="t-flex t-gap-1 t-items-center">
        <SolidCheck size="12" />
        <span className="t-text-text-30 t-text-body-sm">Upload Completed</span>
      </div>
    );
  }

  return (
    <div className="t-flex t-items-center t-gap-3">
      <div className="t-w-full">
        <ProgressBar.Root type={isSuccess ? "green" : "primary"}>
          <ProgressBar.Indicator progress={progress} />
        </ProgressBar.Root>
      </div>
      <span className="t-text-neutral t-text-caption">{progress}%</span>
    </div>
  );
};
