import { Subscription } from "types/Models/subscription";
import { emptyApi } from "./emptyApi";
import { SavePaymentResponse } from "../../types/Models/SavedCards";
import { stringify } from "qs";
import { AddonData } from "store/slices/addons";
import { ACCOUNTING_METHOD_TYPES } from "constants/bookkeeping";

export type IncompletePaymentIntent = {
  created_subscription_id?: string;
  status: string;
  payment_intent_id: string;
  payment_intent_status: string;
  payment_intent_client_secret: string;
  subscription_status?: string;
  stripe_user_profile_id: string;
  payment_not_required: string;
};

export const subscriptionApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getSubscriptions: build.query<
      Subscription[],
      {
        get_inactive?: boolean;
        subscription_types?: string;
        groupId?: string;
        refetchArg?: string;
        entityId?: string;
      }
    >({
      query: ({ subscription_types, get_inactive, groupId, entityId }) => {
        let queryUrl = stringify(
          {
            get_inactive,
            subscription_types,
            group_id: groupId,
            entity_id: entityId,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/payments/subscriptions/${queryUrl}`,
        };
      },
      providesTags: ["Subscriptions"],
    }),

    savePaymentMethod: build.mutation<
      SavePaymentResponse[],
      {
        groupId: string;
        entityId: string;
        stripeUserProfileId?: string;
        paymentMethodId: string;
        isDefaultCard: boolean;
      }
    >({
      query: ({
        stripeUserProfileId,
        paymentMethodId,
        isDefaultCard,
        groupId,
        entityId,
      }) => {
        const stripeUserId = stripeUserProfileId
          ? { stripe_user_profile_id: stripeUserProfileId }
          : {};
        return {
          url: `api/inkle/payments/${groupId}/entity/${entityId}/saved/`,
          method: "post",
          body: {
            ...stripeUserId,
            payment_method_id: paymentMethodId,
            is_default_card: isDefaultCard,
          },
        };
      },
      invalidatesTags: ["SavedCards"],
    }),
    cancelSubscription: build.mutation<
      { company_group: string; subscription: Subscription },
      { groupId: string; subscriptionId: string; entityId: string }
    >({
      query: ({ groupId, subscriptionId, entityId }) => {
        return {
          url: `/api/inkle/payments/${groupId}/entity/${entityId}/subscription/`,
          method: "delete",
          body: { group_subscription_id: subscriptionId },
        };
      },
      invalidatesTags: ["Subscriptions", "PLATFORM_SUBSCRIPTION_PRODUCT"],
    }),
    freeMonthSubscription: build.mutation<
      { success: boolean },
      {
        groupId: string;
        subscription_uuid: string;
        entityId: string;
      }
    >({
      query: ({ groupId, subscription_uuid, entityId }) => {
        return {
          url: `api/inkle/payments/group/${groupId}/entity/${entityId}/subscription/extend/`,
          method: "post",
          body: { subscription_uuid: subscription_uuid },
        };
      },
    }),
    reviveSubscription: build.mutation<
      {
        payment_intent_id: string;
        payment_intent_status: string;
        payment_intent_client_secret: string;
        stripe_user_profile_id: string;
      },
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/payments/group/${groupId}/entity/${entityId}/subscription/revive/`,
          method: "post",
        };
      },
    }),
    getSubscriptionStats: build.query<
      {
        pro_customers: string;
        standard_customers: string;
      },
      null
    >({
      query: () => {
        return {
          url: `api/inkle/company/subscription_stats/`,
        };
      },
    }),
    createSubscription: build.mutation<
      IncompletePaymentIntent,
      {
        groupId: string;
        entityId: string;
        payload: {
          subscription_uuid: string;
          ra_state_name?: string | null;
          cart_item_id?: string | null;
          scheduled_date?: string | null;
          accounting_method?: ACCOUNTING_METHOD_TYPES;
          custom_price?: number | null;
          tier_id?: string;
        };
      }
    >({
      query: ({ payload, groupId, entityId }) => {
        return {
          url: `/api/inkle/payments/${groupId}/entity/${entityId}/subscription/`,
          body: payload,
          method: "POST",
        };
      },
      invalidatesTags: ["Subscriptions"],
    }),

    updateSubscription: build.mutation<
      IncompletePaymentIntent,
      {
        entityId: string;
        subscriptionId: string;
        payload: {
          scheduled_date: string;
          accounting_method: string;
          custom_price?: number;
          tier_id?: string;
        };
      }
    >({
      query: ({ payload, entityId, subscriptionId }) => {
        return {
          url: `/api/inkle/payments/entity/${entityId}/subscription/${subscriptionId}/`,
          body: payload,
          method: "PUT",
        };
      },
      invalidatesTags: ["Subscriptions"],
    }),

    getSubscriptionById: build.query<
      AddonData,
      {
        subscriptionId: string;
      }
    >({
      query: ({ subscriptionId }) =>
        `/api/inkle/payments/subscription/${subscriptionId}/`,
    }),

    getSubscriptionPermission: build.query<
      {
        feature_map: Record<
          string,
          {
            blocked: boolean;
            message: string;
            subscription_required: {
              uuid: string;
            };
          }
        >;
        route_map: Record<
          string,
          {
            blocked: boolean;
            message: string;
            subscription_required: {
              uuid: string;
            };
          }
        >;
      },
      { entityId: string; product_type?: "TAX" | "BOOKS" }
    >({
      query: ({ entityId, product_type = "BOOKS" }) => ({
        url: `/api/inkle/payments/entity/${entityId}/subscription-permissions/?product_type=${product_type}`,
      }),
      providesTags: ["Subscriptions"],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateSubscriptionMutation,
  useCancelSubscriptionMutation,
  useSavePaymentMethodMutation,
  useFreeMonthSubscriptionMutation,
  useReviveSubscriptionMutation,
  useGetSubscriptionsQuery,
  useLazyGetSubscriptionsQuery,
  useGetSubscriptionStatsQuery,
  useGetSubscriptionByIdQuery,
  useUpdateSubscriptionMutation,
  useGetSubscriptionPermissionQuery,
} = subscriptionApis;
