import { AppLayout } from "components/AppLayout/AppLayout";
import { ChatLeftNav } from "components/ChatLeftNav/ChatLeftNav";
import Loader from "components/design/loader";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { FilingDetails } from "components/fileTax/FilingDetails";
import { GroupSelectorWithDefaultGroup } from "components/GroupSelector/GroupSelectorWithDefaultGroup";
import { Accounting } from "components/icons/LeftNav/Books/Accounting";
import { BookkeepingServices } from "components/icons/LeftNav/Books/BookkeepingServices";
import { DataSources as DataSourcesIcon } from "components/icons/LeftNav/Books/DataSources";
import { Transactions as TransactionsIcon } from "components/icons/LeftNav/Books/Transactions";
import { Chat } from "components/icons/LeftNav/Chat";
import { Transfers } from "components/icons/LeftNav/Intragroup/Transfers";
import { Ticket } from "components/icons/LeftNav/Ticket";
import { InkleBooksLogo } from "components/icons/Logos/InkleBooksLogo";
import { InkleBooksSymbol } from "components/icons/Logos/InkleBooksSymbol";
import { ReconciliationTxn } from "components/Reconciliation/ReconciliationTxn";
import { ReportViewWithId } from "components/Reports/ReportViewById";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import {
  LeftBar,
  LeftBarItem,
  LeftBarLogo,
  LeftBarSeparator,
} from "components/Sidebar/LeftBar";
import TemplateMenu from "components/tasks/modal/AllTemplatesModal";
import { TodoLeftNav } from "components/TodoLeftNav/TodoLeftNav";
import { TopBar } from "components/TopBar/TopBar";
import { Upgrade } from "components/TopBar/Upgrade";
import InitiateTransferModal from "components/TPPayments/TransferModal/InitiateTransferModal";
import { BOOKS_PLUS_PLAN } from "constants/addons";
import {
  ACTION_ITEMS_CLICKED_FROM_SIDEBAR,
  CHAT_CLICKED_FROM_SIDEBAR,
  DOCUMENTS_CLICKED_FROM_SIDEBAR,
} from "constants/analyticsEvents";
import { MONTHLY } from "constants/billingCycles";
import { YYYY_MM_DD } from "constants/date";
import { BOOKS } from "constants/taskCategoryType";
import { useCurrentAppContext } from "contexts/CurrentAppContext";
import dayjs from "dayjs";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useTransactionsFilter } from "hooks/useTransactionsFilter";
import { useUpgradePlan } from "hooks/useUpgradePlan";
import { Invoices } from "pages/TPPayments/Invoices";
import { parse, stringify } from "qs";
import React, { useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { RestrictedRoute } from "RestrictedRoute";
import { DashboardBanner } from "signInSignUp/DashboardBanner";
import "static/styles/containers/dashboard.css";
import { useBankConnectionsPrefetch } from "store/apis/bankConnections";
import { useBooksConnectionsPrefetch } from "store/apis/booksConnections";
import { useReportsPrefetch } from "store/apis/reports";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { useTransactionApisPrefetch } from "store/apis/transactions";
import { BillsAndVendors } from "../BillsAndVendors/BillsAndVendors";
import { BooksHome } from "../BooksHome/BooksHome";
import { DataSources } from "../DataSources/DataSources";
import { FinancialClosing } from "../FinancialClosing/FinancialClosing";
import { FinancialClosingDetails } from "../FinancialClosing/FinancialClosingDetails";
import { FixedAsset } from "../FixedAsset/FixedAsset";
import { GeneralLedgerApp } from "../GeneralLedger/GeneralLedger";
import { InvoicesHome } from "../Invoicing/InvoicesHome";
import { MyProducts } from "../MyProducts/MyProducts";
import { Reports } from "../Reports/Reports";
import { RevenueMetrics } from "../RevenueMetrics/RevenueMetrics";
import { Services } from "../Services/Services";
import { TransactionsPage } from "../Transactions/Transactions";
import { VendorDetailPage } from "../BillsAndVendors/VendorDetailPage";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Financials as FinancialsIcon } from "components/icons/LeftNav/Books/Financials";
import { SavedReport } from "../SavedReports/SavedReports";
import { Revenue as RevenueIcon } from "components/icons/LeftNav/Books/Revenue";
import { Customers } from "../Revenue/Customers/Customers";
import { Expenses as ExpensesIcon } from "components/icons/LeftNav/Books/Expenses";
import { Vendors } from "../Expenses/Vendors";
import { ChartOfAccounts } from "../Accounting/ChartOfAccounts";
import { JournalEntries } from "../Accounting/JournalEntries";
import { Reconciliation } from "../Accounting/Reconciliation";
import { HomeIcon } from "components/icons/LeftNav/Books/HomeIcon";
import { DocumentsIcon } from "components/icons/LeftNav/DocumentsIcon";
import { SubMenu } from "components/Sidebar/SubMenu";
import { IntragroupServices } from "components/IntragroupServices/IntragroupServices";

const BooksNav = () => {
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const { trackEvent } = useAnalytics();

  const { isCpa, isForeignCA, isCustomer } = useRoleBasedView();
  const { entities } = useCurrentGroupContext();

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const isValidIntragroup = entities?.some(
    (entity) => entity.country_code === "IN"
  );

  return (
    <div className="t-h-full t-flex t-flex-col t-justify-between t-overflow-hidden">
      <div className="t-overflow-y-auto">
        <LeftBarItem exact to={search} icon={HomeIcon}>
          Home
        </LeftBarItem>
        <LeftBarItem to={`/transactions${search}`} icon={TransactionsIcon}>
          Transactions
        </LeftBarItem>
        <LeftBarItem to={`/data-sources${search}`} icon={DataSourcesIcon}>
          Data Sources
        </LeftBarItem>
        <SubMenu.Root
          defaultRoute={`/live-financials${search}`}
          childPaths={[
            "/live-financials",
            "/saved-reports",
            "/financial-closing",
          ]}
        >
          <SubMenu.Trigger icon={FinancialsIcon}>Financials</SubMenu.Trigger>
          <SubMenu.Container hoverTitle="Financials">
            <SubMenu.Item to={`/live-financials${search}`} useParentPath>
              Live Financials
            </SubMenu.Item>
            <SubMenu.Item to={`/saved-reports${search}`} useParentPath>
              Saved Reports
            </SubMenu.Item>
            <SubMenu.Item to={`/financial-closing${search}`} useParentPath>
              Financial Closing
            </SubMenu.Item>
          </SubMenu.Container>
        </SubMenu.Root>

        <SubMenu.Root
          childPaths={[
            "/revenue-metrics",
            "/invoices-and-customers",
            "/my-products",
            "revenue/customers",
          ]}
          defaultRoute={`/invoices-and-customers${search}`}
        >
          <SubMenu.Trigger icon={RevenueIcon}>Revenue</SubMenu.Trigger>
          <SubMenu.Container hoverTitle="Revenue">
            <SubMenu.Item to={`/invoices-and-customers${search}`} useParentPath>
              Invoices
            </SubMenu.Item>
            <SubMenu.Item to={`/revenue-metrics${search}`} useParentPath>
              Metrics
            </SubMenu.Item>
            <SubMenu.Item to={`/revenue/customers${search}`} useParentPath>
              Customers
            </SubMenu.Item>

            <SubMenu.Item to={`/my-products${search}`} useParentPath>
              My Products
            </SubMenu.Item>
          </SubMenu.Container>
        </SubMenu.Root>

        <SubMenu.Root
          childPaths={["/expenses/vendors", "/bills-and-vendors"]}
          defaultRoute={`/expenses/vendors${search}`}
        >
          <SubMenu.Trigger icon={ExpensesIcon}>Expenses</SubMenu.Trigger>

          <SubMenu.Container hoverTitle="Expenses">
            <SubMenu.Item to={`/expenses/vendors${search}`} useParentPath>
              Vendors
            </SubMenu.Item>
            <SubMenu.Item to={`/bills-and-vendors${search}`} useParentPath>
              Bills
            </SubMenu.Item>
          </SubMenu.Container>
        </SubMenu.Root>

        <SubMenu.Root
          childPaths={[
            "/categories",
            "/chart-of-accounts",
            "/reconciliation",
            "/journal-entries",
            "/fixed-assets",
          ]}
          defaultRoute={`/categories${search}&hideZero=${true}`}
        >
          <SubMenu.Trigger icon={Accounting}>Accounting</SubMenu.Trigger>

          <SubMenu.Container hoverTitle="Accounting">
            <SubMenu.Item
              to={`/categories${search}&hideZero=${true}`}
              useParentPath
            >
              General Ledger
            </SubMenu.Item>
            <SubMenu.Item to={`/chart-of-accounts${search}`} useParentPath>
              Chart of Accounts
            </SubMenu.Item>
            <SubMenu.Item to={`/reconciliation${search}`} useParentPath>
              Reconciliations
            </SubMenu.Item>

            <SubMenu.Item to={`/journal-entries${search}`} useParentPath>
              Journal Entries
            </SubMenu.Item>
            <SubMenu.Item to={`/fixed-assets${search}`} useParentPath>
              Fixed Assets
            </SubMenu.Item>
          </SubMenu.Container>
        </SubMenu.Root>
        {isValidIntragroup && (
          <SubMenu.Root
            defaultRoute={`/intragroup/transfers${search}`}
            childPaths={["/intragroup/transfers", "/intragroup/services"]}
          >
            <SubMenu.Trigger icon={Transfers}>Intragroup</SubMenu.Trigger>
            <SubMenu.Container hoverTitle="Intragroup">
              <SubMenu.Item to={`/intragroup/transfers${search}`} useParentPath>
                Transfers
              </SubMenu.Item>
              <SubMenu.Item to={`/intragroup/services${search}`} useParentPath>
                Services
              </SubMenu.Item>
            </SubMenu.Container>
          </SubMenu.Root>
        )}

        <LeftBarItem to={`/services${search}`} icon={BookkeepingServices}>
          Bookkeeping Services
        </LeftBarItem>
      </div>
      <div>
        <div className=" t-bg-surface-lighter-grey">
          {(isCpa || isForeignCA) && (
            <div>
              <div className="t-py-3">
                <LeftBarSeparator />
              </div>
              <button
                className="all:unset t-w-full"
                onClick={() => trackEvent(ACTION_ITEMS_CLICKED_FROM_SIDEBAR)}
              >
                <LeftBarItem to={`/tickets${search}`} icon={Ticket}>
                  Tickets
                </LeftBarItem>
              </button>
              <button
                className="all:unset t-w-full"
                onClick={() => trackEvent(CHAT_CLICKED_FROM_SIDEBAR)}
              >
                <LeftBarItem to={`/chat/unreplied${search}`} icon={Chat}>
                  Chat
                </LeftBarItem>
              </button>
            </div>
          )}
        </div>
        <div className="t-bg-surface-lighter-grey">
          {isCustomer && (
            <div>
              <div className="t-pb-3">
                <LeftBarSeparator />
              </div>
              <button
                className="all:unset t-w-full"
                onClick={() => trackEvent(CHAT_CLICKED_FROM_SIDEBAR)}
              >
                <ChatLeftNav to={`/chat${search}`} />
              </button>
              <button
                className="all:unset t-w-full"
                onClick={() => trackEvent(ACTION_ITEMS_CLICKED_FROM_SIDEBAR)}
              >
                <TodoLeftNav />
              </button>
              <button
                className="all:unset t-w-full"
                onClick={() => trackEvent(DOCUMENTS_CLICKED_FROM_SIDEBAR)}
              >
                <LeftBarItem to={`/documents${search}`} icon={DocumentsIcon}>
                  Documents
                </LeftBarItem>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const BooksDashboard = () => {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const { isCpa, isCustomer, isForeignCA } = useRoleBasedView();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { upgrade } = useUpgradePlan();
  const history = useHistory();

  const allFilters = useTransactionsFilter({
    searchTerm: null,
    pageNum: 1,
  });

  const prefetchTransactions = useTransactionApisPrefetch(
    "getAllTransactionsData"
  );
  const prefetchLedgerBanks = useBankConnectionsPrefetch("getEntityBanks");
  const prefetchLiveReports = useReportsPrefetch("newInteractiveReport");
  const prefetchConnections = useBooksConnectionsPrefetch("getAllConnections");

  useEffect(() => {
    if (entityId && groupId) {
      prefetchTransactions(allFilters);
      prefetchLiveReports({
        groupId,
        entityId,
        reportType: "BALANCE_SHEET",
        accountingMethod: "CASH",
        startDate: dayjs().startOf("year").format(YYYY_MM_DD),
        endDate: dayjs().format(YYYY_MM_DD),
        // @ts-ignore
        showNonZeroAccounts: true,
        comparison_report_type: "TOTALS",
      });
      prefetchLedgerBanks({
        groupId,
        entityId,
      });
      prefetchConnections({
        groupId,
        entityId,
      });
    }
  }, [allFilters, entityId]);

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const {
    data: bookkeepingSubscriptionPlans = [],
    isFetching,
    isSuccess,
  } = useGetSubscriptionsQuery(
    {
      subscription_types: BOOKS_PLUS_PLAN,
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const activeBooksPlusPlan = bookkeepingSubscriptionPlans?.find(
    ({ group_subscription = [] }) => group_subscription?.length > 0
  );

  const booksMonthlyPlusPlan = bookkeepingSubscriptionPlans?.find(
    (ele) => ele.billing_cycle === MONTHLY
  );

  const onUpgrade = () => {
    upgrade({ addon: booksMonthlyPlusPlan! });
    history.push(`/settings/billing?entity=${entityId}`);
  };

  const [isLoading, setIsLoading] = React.useState(false);
  const { dispatch: currentAppDispatch } = useCurrentAppContext();

  useEffect(() => {
    currentAppDispatch({
      type: "SET_CURRENT_APP",
      payload: { currentApp: isCustomer ? "Books" : "Books Admin" },
    });
  }, [isCustomer]);

  return (
    <AppLayout
      sidebar={
        <LeftBar
          useInternalLink
          showCollapse
          logo={
            <>
              <LeftBarLogo logo={InkleBooksLogo} symbol={InkleBooksSymbol} />
              {(isCpa || isForeignCA) && (
                <div className="t-px-2 t-pb-4 t-flex t-gap-2 t-flex-col">
                  <GroupSelectorWithDefaultGroup
                    onGroupChange={({ isLoading }) => setIsLoading(isLoading)}
                  />
                  <EntitySelector />
                </div>
              )}
            </>
          }
        >
          <BooksNav />
        </LeftBar>
      }
      topbar={
        <TopBar
          upgradeButton={
            isCustomer && (
              <Async.Root
                isEmpty={Boolean(activeBooksPlusPlan)}
                isLoading={!isFetching}
                isSuccess={isSuccess}
                customLoader={<></>}
              >
                <Async.Empty>{null}</Async.Empty>
                <Async.Success>
                  <>
                    {Boolean(activeBooksPlusPlan) ? undefined : (
                      <Upgrade onUpgrade={onUpgrade} />
                    )}
                  </>
                </Async.Success>
              </Async.Root>
            )
          }
          logo={
            <>
              <LeftBarLogo logo={InkleBooksLogo} symbol={InkleBooksSymbol} />

              <div className="t-px-2 t-pb-4 t-flex t-gap-2 t-flex-col">
                {(isForeignCA || isCpa) && (
                  <GroupSelectorWithDefaultGroup
                    onGroupChange={({ isLoading }) => setIsLoading(isLoading)}
                  />
                )}
                <EntitySelector right={0} size="small" />
              </div>
            </>
          }
          nav={
            <LeftBar logo={<></>}>
              <BooksNav />
            </LeftBar>
          }
        >
          {isCustomer && (
            <EntitySelector
              toolTipPlacement="bottom"
              right={0}
              disableNonUsEntities
              showGoToEntity
              size="small"
            />
          )}
        </TopBar>
      }
      banner={<DashboardBanner />}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <Switch>
          <Route
            path={`${path}/transactions/reconciliation/start/:bankAccountId`}
          >
            {(props) => (
              <Redirect
                to={`${path}/data-sources/reconciliation/start/${props.match?.params.bankAccountId}${search}`}
              />
            )}
          </Route>
          <Redirect
            from={`${path}/transactions/reconciliation`}
            to={`${path}/data-sources/reconciliation${search}`}
          />
          <Redirect
            from={`${path}/connections`}
            to={`${path}/data-sources${search}`}
          />
          <RestrictedRoute path={`${path}/transactions`}>
            <TransactionsPage />
          </RestrictedRoute>
          <RestrictedRoute exact path={`${path}/services/addtask`}>
            <TemplateMenu
              to={`/books/services${search}`}
              taskCategoryType={BOOKS}
            />
          </RestrictedRoute>
          <RestrictedRoute exact path={`${path}/intragroup/services/addtask`}>
            <TemplateMenu
              to={`/books/intragroup/services${search}`}
              taskCategoryType="INTRA_GROUP"
            />
          </RestrictedRoute>
          <RestrictedRoute exact path={`${path}/intragroup/services/:taskId`}>
            <FilingDetails
              isFromServices
              parentRoute="/books/services"
              addEntityInUrl
            />
          </RestrictedRoute>
          <RestrictedRoute exact path={`${path}/services/:taskId`}>
            <FilingDetails
              isFromServices
              parentRoute="/books/services"
              addEntityInUrl
            />
          </RestrictedRoute>
          <RestrictedRoute
            exact
            path={`${path}/services/:taskId/:financialClosingId`}
          >
            {({ match }) => {
              return (
                <FinancialClosingDetails
                  breadcrumbs={[
                    {
                      name: "Service",
                      link: `/books/services/${match?.params.taskId}${search}`,
                    },
                  ]}
                  close={() =>
                    history.push(
                      `/books/services/${match?.params.taskId}${search}`
                    )
                  }
                />
              );
            }}
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/services`}>
            <Services />
          </RestrictedRoute>
          <RestrictedRoute
            exact
            path={`${path}/reconciliation/:reconcilationId`}
          >
            <ReconciliationTxn />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/financial-closing`}>
            <FinancialClosing />
          </RestrictedRoute>
          <RestrictedRoute exact path={`${path}/merchants`}>
            <Redirect to={`${url}/bills-and-vendors${search}`} />
          </RestrictedRoute>
          <Redirect
            from={`${path}/invoices-and-customers/customers`}
            to={`${path}/revenue/customers${search}`}
          />
          <RestrictedRoute path={`${path}/invoices-and-customers`}>
            <InvoicesHome />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/revenue/customers`}>
            <Customers />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/invoicing`}>
            <Redirect to={`${url}/invoices-and-customers${search}`} />
          </RestrictedRoute>
          <Redirect
            from={`${path}/categories/chart-of-accounts`}
            to={`${path}/chart-of-accounts${search}`}
          />
          <Redirect
            from={`${path}/categories/journalentry`}
            to={`${path}/journal-entries${search}`}
          />
          <Redirect
            from={`${path}/data-sources/reconciliation`}
            to={`${path}/reconciliation${search}`}
          />
          <RestrictedRoute path={`${path}/chart-of-accounts`}>
            <ChartOfAccounts />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/journal-entries`}>
            <JournalEntries />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/reconciliation`}>
            <Reconciliation />
            <JournalEntries />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/categories`}>
            <GeneralLedgerApp />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/data-sources`}>
            <DataSources />
          </RestrictedRoute>
          <RestrictedRoute
            path={`${path}/customers/:customerId`}
            render={({ match }) => {
              return (
                <Redirect
                  to={`/books/invoices-and-customers/customers/${match.params.customerId}${search}`}
                />
              );
            }}
          />
          <RestrictedRoute path={`${path}/customers`}>
            <Redirect to={`${url}/invoices-and-customers/customers${search}`} />
          </RestrictedRoute>
          <Redirect
            from={`${path}/bills-and-vendors/vendors`}
            to={`${path}/expenses/vendors${search}`}
          />
          <Redirect
            from={`${path}/bills-and-vendors/vendors/:vendorId`}
            to={`${path}/expenses/vendors/:vendorId${search}`}
          />
          <Route exact path={`${path}/expenses/vendors/:vendorId`}>
            <VendorDetailPage
              breadcrumb={[
                {
                  link: `${url}/expenses/vendors${search}`,
                  name: "Vendor",
                },
              ]}
            />
          </Route>
          <RestrictedRoute path={`${path}/bills-and-vendors`}>
            <BillsAndVendors />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/expenses/vendors`}>
            <Vendors />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/vendors`}>
            <Redirect to={`${url}/bills-and-vendors/vendors${search}`} />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/customers-and-vendors`}>
            <Redirect to={`${url}/bills-and-vendors/vendors${search}`} />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/my-products`}>
            <MyProducts />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/products-and-services`}>
            <Redirect to={`${url}/my-products${search}`} />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/revenue-metrics`}>
            <RevenueMetrics />
          </RestrictedRoute>
          <Redirect
            from={`${path}/live-financials/saved-reports`}
            to={`${path}/saved-reports${search}`}
          />
          <RestrictedRoute path={`${path}/live-financials`}>
            <Reports />
          </RestrictedRoute>
          <Redirect
            from={`${path}/live-financial/:reportId`}
            to={`${path}/saved-reports/:reportId/${search}`}
          />
          <Route path={`${path}/saved-reports/:reportId`}>
            <ReportViewWithId />
          </Route>
          <RestrictedRoute path={`${path}/saved-reports`}>
            <SavedReport />
          </RestrictedRoute>
          <Route path={`${path}/fixed-assets`}>
            <FixedAsset />
          </Route>
          <Redirect
            from={`${path}/accounting`}
            to={`${path}/categories${search}`}
          />
          <Redirect
            from={`${path}/reports`}
            to={`${path}/live-financials${search}`}
          />{" "}
          <Redirect from={`${path}/metrics`} to={`${path}${search}`} />
          <Redirect
            from={`${path}/report`}
            to={`${path}/live-financial${search}`}
          />
          <Redirect
            from={`${path}/report/:reportId`}
            to={`${path}/live-financial/:reportId${search}`}
          />
          {(isCpa || isForeignCA) && (
            <RestrictedRoute path={`${path}/transfers/transfer/`}>
              <InitiateTransferModal to={`${path}/transfers`} />
            </RestrictedRoute>
          )}
          <RestrictedRoute path={`${path}/intragroup/transfers/transfer/`}>
            <InitiateTransferModal to={`${path}/intragroup/transfers`} />
          </RestrictedRoute>
          {(isCpa || isForeignCA) && (
            <RestrictedRoute path={`${path}/transfers/`}>
              <Invoices />
            </RestrictedRoute>
          )}
          <RestrictedRoute path={`${path}/intragroup/transfers`}>
            <Invoices />
          </RestrictedRoute>
          <RestrictedRoute path={`${path}/intragroup/services`}>
            <IntragroupServices />
          </RestrictedRoute>
          <RestrictedRoute exact path={`${path}/dashboard`}>
            <Redirect to={path} />
          </RestrictedRoute>
          {isCustomer && (
            <RestrictedRoute exact path={`${path}/action-items`}>
              <Redirect to={`${path}/tickets`} />
            </RestrictedRoute>
          )}
          <RestrictedRoute exact path={`${path}/monthly-closing`}>
            <Redirect to={`${path}/financial-closing${search}`} />
          </RestrictedRoute>
          <RestrictedRoute exact path={path} component={BooksHome} />
          <RoutesAccrossApps />
        </Switch>
      )}
    </AppLayout>
  );
};
