export const Expand = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.00014 10.3327C7.82507 10.333 7.65166 10.2987 7.48989 10.2318C7.32811 10.1649 7.18116 10.0666 7.05748 9.9427L3.52881 6.41337L4.47148 5.4707L8.00014 8.99937L11.5288 5.4707L12.4715 6.41337L8.94281 9.94204C8.81918 10.0661 8.67225 10.1644 8.51047 10.2315C8.3487 10.2985 8.17526 10.3329 8.00014 10.3327Z"
      fill="#41395C"
    />
  </svg>
);
