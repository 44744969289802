import { object, string } from "yup";

export const directorAutofill = object({
  street_address: string().required("Address Line 1 Required"),
  city: string().required("City Required"),
  country: string().required("Country Required"),
  state: string().required("State Required"),
  zipcode: string().required("Required"),
  fullName: string().required("Full Name Required"),
  address: string().required("Address Required"),
});
