import { useParams } from "react-router-dom";
import { useGetEntitiesQuery, useGetGroupDataQuery } from "store/apis/group";
import { useRoleBasedView } from "./useRoleBasedView";
import { useQuery } from "./useQuery";

export const useCurrentGroup = () => {
  const { groupId: groupIdFromParams } = useParams<{ groupId?: string }>();
  const { isCustomer } = useRoleBasedView();
  const query = useQuery();

  const companyId = query.get("company");
  const groupIdFromQuery = query.get("group");

  const groupId = groupIdFromParams || groupIdFromQuery || companyId;

  const { data: { groups: [customerGroup] = [null] } = {}, ...otherData } =
    useGetEntitiesQuery(undefined, {
      skip: !isCustomer,
    });

  const { data: crmGroup, ...otherDataFromCrm } = useGetGroupDataQuery(
    { groupId: groupId! },
    { skip: !groupId || isCustomer }
  );

  if (isCustomer) {
    return { ...customerGroup, ...otherData };
  }

  return { ...crmGroup, ...otherDataFromCrm };
};
