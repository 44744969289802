import * as RUSwitch from "@radix-ui/react-switch";
import classNames from "classnames";

export type SwitchProps = RUSwitch.SwitchProps & { size?: "small" | "regular" };
export const Switch = ({
  name,
  size = "regular",
  disabled,
  ...props
}: SwitchProps) => (
  <RUSwitch.Root
    className={classNames(
      "all:unset SwitchRoot t-relative t-rounded-full t-bg-neutral-10 data-[disabled]:!t-bg-neutral-30 data-[state=checked]:t-bg-purple-50 t-flex t-transform t-transition t-duration-500",
      {
        "t-h-6 t-w-12": size === "regular",
        "t-h-[18px] t-w-[30px]": size === "small",
        "t-opacity-50 t-cursor-not-allowed": disabled === true,
      }
    )}
    id={name}
    data-testid={name}
    name={name}
    disabled={disabled}
    {...props}
  >
    <RUSwitch.Thumb
      className={classNames(
        "t-inline-block t-rounded-full data-[state=checked]:t-bg-white data-[state=unchecked]:t-bg-neutral t-drop-shadow-toggle t-transform t-transition t-duration-300 t-ease-in-out",
        {
          "t-absolute t-top-1/2 t-h-4 t-w-4 -t-translate-y-1/2 t-translate-x-1 data-[state=checked]:t-translate-x-[28px]":
            size === "regular",
          "t-absolute t-top-1/2  t-h-3 t-w-3 -t-translate-y-1/2 t-translate-x-[3px] data-[state=checked]:t-translate-x-[16px]":
            size === "small",
        }
      )}
    />
  </RUSwitch.Root>
);
