import Loader from "components/design/loader";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useGetVendorsQuery } from "store/apis/vendors";
import { setDefaultChannel } from "store/slices/chat";
import { MerchantTable } from "./merchantTable";
import { useDebounce } from "utils/debounce";
import { VENDOR_TYPE } from "constants/vendor";

export const MerchantsPanel = ({ filters, vendorFilter }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const query = useQuery();
  const page = Number(query.get("page")) || 1;
  let selectedEntityId = useCurrentEntityId();
  const debouncedSearchTerm = useDebounce(filters.SEARCH_TERM, 500);
  const [sorting, setSorting] = useState([
    {
      id: "AMOUNT",
      desc: false,
    },
  ]);

  const sortOrder = sorting[0]?.desc;
  const isShowEstimated1099 = filters.SHOW_ESTIMATED_1099;

  const {
    data: merchantData,
    isLoading,
    isSuccess,
  } = useGetVendorsQuery(
    {
      groupId,
      search_term: debouncedSearchTerm,
      type: filters.VENDOR_TYPE,
      page_num: page,
      season: filters.SEASON,
      entityId: selectedEntityId,
      sort_order: sortOrder ? "DSC" : "ASC",
      merchant_source: filters.SOURCE,
      sort_key: isShowEstimated1099 ? "1099_TXN_AMOUNT" : "TOTAL_TXN_AMOUNT",
    },
    { skip: !groupId || !selectedEntityId, refetchOnMountOrArgChange: true }
  );

  const {
    merchants = [],
    channel_url,
    current_page,
    per_page,
    total_count,
    total_pages,
    transactions_without_vendors_count,
  } = merchantData || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  useEffect(() => {
    dispatch(setDefaultChannel(channel_url));
  }, [channel_url]);

  const noMerchant = merchants.length === 0;

  if (isLoading || !isSuccess) {
    return <Loader />;
  }

  return (
    <MerchantTable
      filters={vendorFilter}
      merchants={merchants}
      groupId={groupId}
      showWform={filters.VENDOR_TYPE === VENDOR_TYPE.VENDORS}
      totalPages={total_pages}
      paginationData={paginationData}
      noMerchant={noMerchant}
      sorting={sorting}
      setSorting={setSorting}
      transactionsWithoutVendorsCount={transactions_without_vendors_count}
      entityId={selectedEntityId}
      selectedSeason={filters.SEASON}
      isShowEstimated1099={isShowEstimated1099}
    />
  );
};
