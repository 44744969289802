import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

// icons
import AcceptedChecks from "../icons/AcceptedChecks";
import TaskLog from "../../static/images/TaskLog.svg";
import ActiveTaskLog from "../../static/images/ActiveTaskLog.svg";

// constants
import { SHADE7GREEN } from "../../constants/adhocTaskIdentifier";

// context
import authContext from "../../jwt_context&axios/authContext";

// utils
import { getDate } from "../../utils/formatDayJs";
import TaskLogModal from "./taskLogModal";
import { Button } from "components/DesignSystem/Button/Button";
import TaskButtonBar from "./TaskButtonBar";
import { PERCENT } from "constants/stripeCouponTypes";
import { Badge } from "components/design/badge";

export const AdhocTaskCompleted = ({
  activeScopeOfWork,
  groupId,
  task,
  parentRef,
}) => {
  const history = useHistory();
  const { authtoken } = useContext(authContext);
  const [isShowLog, setShowLog] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const { price, scope_of_work, updated_at } = activeScopeOfWork;

  const gotoBilling = () => {
    !authtoken?.is_service_user && !authtoken?.is_any_service_user
      ? history.push("/billing")
      : history.push(`/crm/${groupId}/billing`);
  };

  const openLogModal = () => setShowLog(true);
  const closeLogModal = () => setShowLog(false);

  const coupon = task?.coupon;

  const discountOff =
    coupon?.discount_type === PERCENT
      ? `${coupon?.discount_rate}%`
      : `$${coupon?.discount_rate}`;

  let finalPrice = coupon?.discounted_price || price;

  return (
    <div className="taxing scopeOfWorkContainer adhocTaskCompleted">
      <div className="d-flex justify-content-between w-100">
        <h6 className="pricingSubtitle">Task Status</h6>
        <div className="actionContainer">
          <img
            onClick={openLogModal}
            onMouseEnter={() => {
              setHovered(true);
            }}
            onMouseLeave={() => {
              setHovered(false);
            }}
            className="icon"
            src={isHovered ? ActiveTaskLog : TaskLog}
            alt="TaskLog"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between w-100 headerContainer">
        <span className="scopeOfWork">
          <AcceptedChecks color={SHADE7GREEN} /> &nbsp; Adhoc task successfully
          completed
        </span>
        <span className="pricingSubtitle">
          Task Completion Date
          <br />
          <span className="taskCreationDate">{getDate(updated_at)}</span>
        </span>
      </div>
      <div className="pricingAmount">
        <h6 className="pricingSubtitle">Inkle Pricing</h6>
        <div className="d-flex justify-content-between w-100 pricingDetails">
          <span className="scopeOfWork content">{scope_of_work}</span>
          <span className="scopeOfWork">$ {price}</span>
        </div>

        {coupon && (
          <div className="d-flex justify-content-between w-100 t-items-center pricingDetails">
            <span className="scopeOfWork content">Discount</span>
            <span className="scopeOfWork">
              {coupon && (
                <div className="t-mt-2 t-flex t-items-center">
                  <span className="t-mr-2 t-text-body-sm t-text-text-30">
                    {coupon.coupon_code}
                  </span>
                  <Badge color="light-green">
                    <span className="t-text-body-sm t-font-medium">
                      {discountOff} off
                    </span>
                  </Badge>
                </div>
              )}
            </span>
          </div>
        )}

        <div className="d-flex justify-content-between w-100 pricingTotal">
          <span className="scopeOfWork">Total</span>
          <span className="scopeOfWork">$ {finalPrice}</span>
        </div>
      </div>
      <TaskButtonBar parentRef={parentRef} justifyContent="end">
        <Button onClick={gotoBilling} customType="primary">
          Go to billing
        </Button>
      </TaskButtonBar>
      {isShowLog && (
        <TaskLogModal
          closeModal={closeLogModal}
          taskLogs={task.adhoc_data_updates}
        />
      )}
    </div>
  );
};
