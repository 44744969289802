import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { FieldArray, Form, Formik } from "formik";
import { practiceTeamManagementInvite } from "formValidations/practiceInviteTeammate";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import { useInviteFspUserMutation } from "store/apis/practiceCrm";
import { UserInputFields } from "types/Models/teamSettings";
import { BackendError } from "types/utils/error";
import { InvitePracticeFields } from "./InvitePracticeFields";

export const InvitePracticeCrm = ({
  isOpen,
  close,
}: {
  isOpen: boolean;
  close: () => void;
}) => {
  const { alertToast, successToast } = useToast();
  const { authtoken } = useContext(authContext);
  const domain = authtoken.email.split("@")?.[1];
  const [inviteTeamMember, { isLoading }] = useInviteFspUserMutation();
  const userInputFields: UserInputFields = {
    first_name: "",
    last_name: "",
    email: "",
  };

  const handleInvite = async ({
    invite_users,
  }: {
    invite_users: UserInputFields[];
  }) => {
    try {
      await inviteTeamMember({
        users: invite_users,
      }).unwrap();
      successToast({ message: "Invite sent successfully." });
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Formik
        onSubmit={handleInvite}
        initialValues={{ invite_users: [userInputFields] }}
        validationSchema={practiceTeamManagementInvite}
      >
        {({ submitForm, values, isValid }) => (
          <Form className="all:unset">
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>Invite Practice</Modal.Title>
                <Modal.Close type="button" />
              </Modal.Header>

              <Modal.Body className="t-flex t-flex-col t-gap-4">
                <FieldArray name="invite_users">
                  {({ push, remove }) => (
                    <>
                      {values.invite_users.map((user, index) => (
                        <InvitePracticeFields
                          key={index}
                          domain={domain}
                          userNumber={index}
                          remove={remove}
                        />
                      ))}

                      {values.invite_users.length === 1 && (
                        <div>
                          <Button
                            type="button"
                            onClick={() => push(userInputFields)}
                          >
                            Invite another member
                          </Button>
                        </div>
                      )}
                    </>
                  )}
                </FieldArray>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button type="button">Cancel</Button>
                </Modal.RawClose>
                <Button
                  customType="primary"
                  isLoading={isLoading}
                  disabled={isLoading || !isValid}
                  onClick={submitForm}
                  data-testid="invite-practice-button"
                >
                  Invite
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
