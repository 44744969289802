import {
  BareInput,
  ErrorMessage,
  INPUT_CLASSNAMES,
  InputProps,
  Label,
  NoteMessage,
} from "components/DesignSystem/TextInput/TextInput";
import { Field, FieldAttributes, FieldProps, getIn } from "formik";
import {
  ComponentProps,
  HTMLAttributes,
  InputHTMLAttributes,
  MouseEvent,
  ReactNode,
} from "react";
import {
  NumberFormatValues,
  NumericFormat,
  SourceInfo,
} from "react-number-format";
import { default as classNames, default as cx } from "classnames";
import ToolTip from "components/design/toolTip";
import { ToolTipIcon } from "components/icons/TooltipIcon";

type PriceInputProps = FieldAttributes<{}> & {
  label?: ReactNode;
  allowNegative?: boolean;
  note?: string | null;
  rightAlign?: boolean;
  rightComponent?: ReactNode;
  customSize?: InputProps["customSize"];
  placeholder?: string | null;
  error?: string;
  hideError?: boolean;
  showErrorOnceTouched?: boolean;
  block?: boolean;
  tooltipText?: string;
  unit?: "USD" | "$";
  onValueChange?: (values: NumberFormatValues, sourceInfo: SourceInfo) => void;
};

const CustomInput = (
  props: InputHTMLAttributes<HTMLInputElement> & {
    rightComponent?: ReactNode;
  } & ComponentProps<typeof BareInput>
) => {
  if (!props.rightComponent) {
    return <BareInput {...props} />;
  }

  return (
    <div className={INPUT_CLASSNAMES("regular", undefined)}>
      <input
        id={props.name}
        className={cx("all:unset t-appearance-textfield", {
          "t-w-3/4": props.rightComponent,
          "t-w-full": !props.rightComponent,
        })}
        {...props}
      />
      <div>{props.rightComponent && props.rightComponent}</div>
    </div>
  );
};

export const PriceInput = ({ unit = "$", ...props }: PriceInputProps) => {
  return (
    <Field {...props}>
      {({ field, form: { errors, setFieldValue, touched } }: FieldProps) => {
        let hasError = getIn(errors, field.name) && getIn(touched, field.name);
        const showError = !props.hideError && (hasError || props.error);

        return (
          <div className="t-relative t-block t-w-full">
            <div className="t-flex">
              {Boolean(props.label) && (
                <div className="t-flex t-items-center">
                  <Label htmlFor={field.name} required={props.required}>
                    {props.label}
                  </Label>
                  {props.tooltipText && (
                    <div className="t-ml-1 t-pb-1.5">
                      <ToolTip text={props.tooltipText} side="right">
                        <span>
                          <ToolTipIcon />
                        </span>
                      </ToolTip>
                    </div>
                  )}
                </div>
              )}
            </div>

            <NumericFormat
              placeholder={props.placeholder || undefined}
              style={{ textAlign: props.rightAlign ? "right" : "left" }}
              onBlur={field.onBlur}
              defaultValue={props.defaultValue as string | null | number}
              allowNegative={props.allowNegative}
              disabled={props.disabled}
              customSize={props.customSize}
              onClick={(event: MouseEvent<HTMLInputElement>) => {
                // @ts-ignore
                if (event.target.value === `${unit} 0.00`) {
                  // @ts-ignore
                  event.target.select();
                }
              }}
              name={props.name}
              allowLeadingZeros={false}
              onValueChange={(values, ...rest) => {
                const { floatValue } = values;
                props.onValueChange?.(values, ...rest);

                if (field.name) {
                  return setFieldValue(field.name, floatValue || 0.0);
                }
              }}
              rightComponent={props.rightComponent}
              value={props.value || field.value}
              customInput={CustomInput}
              isAllowed={({ value }) => {
                if (value === "") {
                  setFieldValue(field.name, 0.0);
                }
                return value !== "";
              }}
              valueIsNumericString
              prefix={`${unit} `}
              thousandSeparator=","
              decimalScale={2}
              thousandsGroupStyle="thousand"
              fixedDecimalScale
              aria-label={props["aria-label"]}
              customType={showError && "error"}
            />
            {showError && (
              <ErrorMessage>
                {getIn(errors, field.name) || props.error}
              </ErrorMessage>
            )}
            {props.note && <NoteMessage>{props.note}</NoteMessage>}
          </div>
        );
      }}
    </Field>
  );
};
