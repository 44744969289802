import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import Card from "components/DesignSystem/Card/Card";
import Slider from "components/DesignSystem/Slider/Slider";
import { FilePreviewTag } from "components/FilePreviewTag/FilePreviewTag";
import { DD_MMM_YYYY } from "constants/date";
import { CURRENT_SEASON } from "constants/vendor";
import dayjs from "dayjs";
import {
  useGetVendorAllDocumentsQuery,
  useGetVendorDetailQuery,
} from "store/apis/vendors";
import { FileObject } from "types/Models/fileObject";
import { ModalProps } from "types/utils/modal";
import { Fragment } from "react";

type VendorDocumentsSliderProps = {
  vendorId: string;
  entityId: string;
} & ModalProps;

const Document = ({
  document,
  season,
}: {
  document: FileObject;
  season?: string;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-3">
      {season && (
        <div className="t-text-text-60 t-text-subtext-sm">{season}</div>
      )}
      <div className="t-flex t-flex-col t-gap-2">
        <div className="t-text-text-30 t-text-body-sm">
          Added on {dayjs(document.created_at).format(DD_MMM_YYYY)}
        </div>
        <FilePreviewTag file={document} size="small" />
      </div>
    </div>
  );
};

export const VendorDocumentsSlider = ({
  close,
  isOpen,
  entityId,
  vendorId,
}: VendorDocumentsSliderProps) => {
  const { data: vendorDocuments, ...vendorDocApiState } =
    useGetVendorAllDocumentsQuery(
      {
        entityId,
        vendorId,
      },
      {
        skip: !vendorId || !entityId,
      }
    );

  const { data: vendor, ...vendorApiState } = useGetVendorDetailQuery(
    {
      entityId,
      vendorId,
      season: CURRENT_SEASON,
    },
    {
      skip: !vendorId || !entityId,
    }
  );

  return (
    <Slider.Root open={isOpen} onOpenChange={close}>
      <Slider.Content>
        <Slider.Header>
          <Slider.Title>
            {vendorApiState.isLoading ? (
              <Loader customType="secondary" size="small" />
            ) : (
              vendor?.name
            )}
          </Slider.Title>
          <Slider.Close />
        </Slider.Header>
        <Slider.Body className="t-flex t-flex-col t-gap-4">
          <Async.Root
            isLoading={vendorDocApiState.isLoading}
            isSuccess={vendorDocApiState.isSuccess}
            isEmpty={!vendorDocuments}
          >
            <Async.Empty>
              <></>
            </Async.Empty>
            <Async.Success>
              <Card.Root>
                <Card.Header>
                  <Card.Title>W-Forms</Card.Title>
                </Card.Header>
                <Card.Body className="t-gap-4 t-flex t-flex-col">
                  {vendorDocuments?.w_forms.length === 0 ? (
                    <div className="t-text-text-60 t-text-subtext-sm">
                      No documents found
                    </div>
                  ) : (
                    <>
                      {vendorDocuments?.w_forms.map(
                        ({ w_form_document, season }) => (
                          <Document
                            key={season}
                            document={w_form_document}
                            season={season}
                          />
                        )
                      )}
                    </>
                  )}
                </Card.Body>
              </Card.Root>

              <Card.Root>
                <Card.Header>
                  <Card.Title>1099 Forms</Card.Title>
                </Card.Header>
                <Card.Body className="t-gap-4 t-flex t-flex-col">
                  {vendorDocuments?.["1099_forms"].length === 0 ? (
                    <div className="t-text-text-60 t-text-subtext-sm">
                      No documents found
                    </div>
                  ) : (
                    <>
                      {vendorDocuments?.["1099_forms"].map(
                        ({ season, payers_document, recipients_document }) => {
                          return (
                            <Fragment key={season}>
                              <Document
                                document={payers_document}
                                season={season}
                              />
                              <Document document={recipients_document} />
                            </Fragment>
                          );
                        }
                      )}
                    </>
                  )}
                </Card.Body>
              </Card.Root>
            </Async.Success>
          </Async.Root>
        </Slider.Body>
      </Slider.Content>
    </Slider.Root>
  );
};
