import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Badge } from "components/design/badge";
import ToolTip from "components/design/toolTip";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import { DynamicStar } from "components/DesignSystem/Star/DynamicStar";
import Table from "components/DesignSystem/Table/V2/Table";
import OpsStage from "components/salesCrm/OpsStage";
import SalesCrmStage from "components/salesCrm/salesCrmStage";
import TagCreation from "components/tagCreation/tagCreation";
import { Badge as TaskBadge } from "components/Task/Badge";
import { NOT_STARTED } from "constants/taskStatus";
import { CRM_SUBSCRIPTION_TYPE } from "dictionaries";
import { usePageTitle } from "hooks/usePageTitle";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import BankWarningCircle from "static/images/BankWarningCircle.svg";
import CrossRed from "static/images/CrossRed.svg";
import GreenTick from "static/images/GreenTick.svg";
import {
  useGetAllSalesCRMGroupsQuery,
  useUpdateGroupDataMutation,
} from "store/apis/salesCrm";
import { CompanyGroup } from "types/Models/groups";
import { debounce } from "utils/debouncing";
import { getTags } from "../../apis/getGroupTags";
import { AssignAgent } from "./AssignAgent";
import CrmTabBar from "./crmTabs/crmTabBar";
import Tags from "./Tags";
import { TASK_TEMPLATE_FILTER } from "types/contants/crm";
import { CrmUnarchiveAction } from "components/CrmTableDropdown/CrmUnarchiveActionDropdown";

const BankItem = ({
  children,
  text,
}: {
  children: ReactNode;
  text: string;
}) => {
  return <ToolTip text={text}>{children}</ToolTip>;
};

export const ArchivedCrm = () => {
  usePageTitle("Archive");
  const query = useQuery();
  const { update } = useUpdateQuery();
  const searchTerm = query.get("search_term") || "";
  const { isAdmin, isCpa, isForeignCA } = useRoleBasedView();
  const [showTagModal, setShowTagModal] = useState(false);
  const pageNumber = Number(query.get("page") || 1);

  const selectedTask1099Filter = query.get(TASK_TEMPLATE_FILTER) || null;
  const [tags, setTags] = useState([]);
  const viewFilter = "OPS_CRM";

  const task1099Filter = selectedTask1099Filter
    ? {
        task_season: "2023",
        taskState: selectedTask1099Filter,
      }
    : {};

  const {
    data: group,
    isLoading,
    isSuccess,
    isFetching,
    refetch,
  } = useGetAllSalesCRMGroupsQuery(
    {
      pageNumber: pageNumber,
      searchTerm: searchTerm,
      viewFilter: viewFilter,
      base_task_template_id:
        process.env.PUBLIC_BASE_TASK_TEMPLATE_ID_STATE_FRANCHISE_TAX,
      archived: true,
      ...task1099Filter,
    },
    {
      refetchOnMountOrArgChange: true,
    }
  );

  const [updateGroup, { data: patchedResp, isLoading: isPatchBtnLoading }] =
    useUpdateGroupDataMutation();

  const {
    company_groups = [],
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = group || {};

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  const { goToFirstPage, goToPrevPage, goToNextPage, goToLastPage } =
    usePagination({
      totalPage: total_pages,
      pageNumber: current_page,
      onPageNumChange: (pageNum) => {
        update({ query: "page", value: pageNum });
      },
    });

  useEffect(() => {
    getTags().then((res) => {
      setTags(res.data.data);
    });
  }, [showTagModal]);

  const onSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search_term", value: value || null });
  });

  const columnHelper = createColumnHelper<CompanyGroup>();
  const Sales = isAdmin
    ? [
        columnHelper.accessor("sales_stage", {
          id: "sales_stage",
          header: "Sales",
          size: 5,
          cell: ({ row }) => {
            const { sales_stage, uuid } = row.original;
            return (
              <>
                <SalesCrmStage
                  currentStage={sales_stage!}
                  groupId={uuid}
                  updateSalesStage={updateGroup}
                />
              </>
            );
          },
        }),
      ]
    : [];

  const Ops = isAdmin
    ? [
        columnHelper.accessor("ops_stage", {
          id: "ops_stage",
          header: "Ops",
          size: 5,
          cell: ({ row }) => {
            const { ops_stage, uuid } = row.original;
            return (
              <OpsStage
                currentStage={ops_stage!}
                groupId={uuid}
                updateSalesStage={updateGroup}
              />
            );
          },
        }),
      ]
    : [];

  const Filings = isForeignCA
    ? [
        columnHelper.accessor("ongoing_tasks", {
          id: "ongoing_tasks",
          header: "Pending Filings",
          size: isForeignCA ? 35 : isCpa ? 35 : 5,
          cell: ({ row }) => {
            const { ongoing_tasks, ongoing_tasks_count } = row.original;
            return (
              <ToolTip text={ongoing_tasks}>
                <div>{ongoing_tasks_count ? ongoing_tasks_count : "-"}</div>
              </ToolTip>
            );
          },
        }),
      ]
    : [];

  const AssignedAgent = isAdmin
    ? [
        columnHelper.accessor("assigned_agent", {
          header: "Agent",
          size: 15,
          cell: ({
            row: {
              original: { assigned_agent, uuid },
            },
          }) => <AssignAgent assignedAgent={assigned_agent} groupId={uuid} />,
        }),
      ]
    : [];

  const Task1099 = isCpa
    ? [
        columnHelper.accessor("task", {
          header: "Task 1099",
          size: isAdmin ? 15 : 25,
          cell: ({ row }) => {
            const { task } = row.original;
            if (task?.current_state_name) {
              return (
                <TaskBadge
                  children={task.current_state_name}
                  type="FORM_FILING"
                />
              );
            }
            if (task?.uuid) {
              return (
                <TaskBadge type="ACTION_REQUIRED" children={NOT_STARTED} />
              );
            }
            return <TaskBadge type="ACTION_REQUIRED" children="Not Created" />;
          },
        }),
      ]
    : [];

  const Bank = !isForeignCA
    ? [
        columnHelper.accessor("connected_banks", {
          header: "Banks",
          size: isAdmin ? 10 : isCpa ? 30 : 5,
          cell: (info) => {
            const totalCount = info.getValue()?.length;

            const failedCount = info
              .getValue()
              ?.filter(({ in_error_state }: any) => in_error_state).length;

            if (totalCount === 0) {
              return (
                <BankItem text="No accounts connected">
                  <img src={CrossRed} alt="no accounts" />
                </BankItem>
              );
            }

            if (failedCount) {
              return (
                <BankItem text={failedCount + " accounts failed"}>
                  <img src={BankWarningCircle} alt="failed" />
                </BankItem>
              );
            }

            return (
              <BankItem text={totalCount - failedCount + " accounts connected"}>
                <img src={GreenTick} alt="success" />
              </BankItem>
            );
          },
        }),
      ]
    : [];

  const DueAmount = isAdmin
    ? [
        columnHelper.accessor("due_amount", {
          header: () => (
            <div className="t-w-full t-flex t-justify-end">Due Amount</div>
          ),
          size: isCpa ? 25 : 20,
          cell: (info) => {
            return (
              <div className="t-w-full t-flex t-justify-end t-pr-4">
                {info.getValue() ? (
                  <AmountSuperScript
                    currencyCode="USD"
                    amount={info.getValue() || 0}
                  />
                ) : (
                  <>-</>
                )}
              </div>
            );
          },
        }),
      ]
    : [];

  const columns = [
    columnHelper.accessor("name", {
      id: "name",
      size: isForeignCA ? 30 : isCpa ? 30 : 12,
      header: "Group Names",
      cell: ({ row }) => {
        return (
          <div className="t-flex t-gap-2 t-truncate t-items-center">
            <ToolTip side="top" text={row.original?.name}>
              <Link
                className="startTask"
                to={`/crm/${row.original.uuid}`}
                target="_blank"
                aria-hidden={true}
              >
                {row.original?.name}
              </Link>
            </ToolTip>
            {row.original.subscription_name &&
              row.original.subscription_name !== "NOT_STARTED" && (
                <Badge
                  size="small"
                  //@ts-ignore
                  color={CRM_SUBSCRIPTION_TYPE[row.original.subscription_name]}
                >
                  {row.original.subscription_name?.[0]}
                </Badge>
              )}
            {row.original.is_vip && <DynamicStar isActive />}
          </div>
        );
      },
    }),
    ...Sales,
    ...Ops,
    ...Filings,
    columnHelper.accessor("tags", {
      id: "tags",
      header: "Tags",
      size: isForeignCA ? 17 : isCpa ? 18 : 8,
      cell: ({ row }) => (
        <Tags
          taskId={row.original.uuid}
          currentTags={row.original?.tags}
          tags={tags}
          updateTags={updateGroup}
        />
      ),
    }),
    ...AssignedAgent,
    ...Task1099,
    ...Bank,
    ...DueAmount,
    columnHelper.accessor((row) => row, {
      id: "threedots",
      header: "",
      size: 5,
      cell: (info) => {
        const { uuid, name } = info.row.original;
        return <CrmUnarchiveAction actionOn={"group"} uuid={uuid} />;
      },
    }),
  ];

  const table = useReactTable({
    data: company_groups || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      size: 10,
      minSize: 1,
      maxSize: 100,
    },
  });

  const isEmpty = company_groups.length === 0;

  return (
    <div className="t-flex t-w-full t-h-full t-flex-col t-relative">
      <CrmTabBar selectedTabIs="admin/archivedcrm" />
      <div className="t-px-6 t-py-4 t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 t-flex t-justify-between t-gap-4 t-items-center">
        <Search
          placeholder="Search"
          onChange={onSearch}
          defaultValue={searchTerm}
        />
        <Pagination
          {...paginationData}
          goToFirstPage={goToFirstPage}
          goToPrevPage={goToPrevPage}
          goToNextPage={goToNextPage}
          goToLastPage={goToLastPage}
        />
      </div>
      <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
        <Async.Empty>
          <EmptyScreen text="No Archived Groups found"></EmptyScreen>
        </Async.Empty>
        <Async.Success>
          <div className="t-px-6 t-py-5 t-relative">
            <Table.Container>
              <Table.Content>
                <Table.Head>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <Table.Row key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <Table.HeadCell
                          key={header.id}
                          style={{ width: `${header.getSize()}%` }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                        </Table.HeadCell>
                      ))}
                    </Table.Row>
                  ))}
                </Table.Head>
                <Table.Body>
                  {table.getRowModel().rows.map((row) => (
                    <Table.Row
                      key={row.id}
                      className={classNames(
                        "hover:t-bg-surface-lighter-grey t-cursor-pointer"
                      )}
                    >
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <Table.Cell
                            key={cell.id}
                            style={{ width: `${cell.column.getSize()}%` }}
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </Table.Cell>
                        );
                      })}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table.Content>
            </Table.Container>
            <TagCreation
              show={showTagModal}
              handleClose={() => setShowTagModal(false)}
            />
          </div>
        </Async.Success>
      </Async.Root>
    </div>
  );
};
