import Card from "components/DesignSystem/Card/Card";
import { ComponentProps } from "react";

export const HomePageCardHeader = (
  props: ComponentProps<typeof Card.Header>
) => {
  return (
    // height to match home all page header
    <Card.Header {...props} className="t-h-[61px]" />
  );
};

export const HomePageCardTitle = (props: ComponentProps<typeof Card.Title>) => {
  return <Card.Title {...props} className="t-text-subtitle t-text-text-100" />;
};
