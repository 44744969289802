import { Button } from "components/DesignSystem/Button/Button";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import { useEffect } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import EmptyEllipse from "static/images/EmptyEllipse.svg";
import Equals from "static/images/Equals.svg";
import GreenCircle from "static/images/GreenCircle.svg";
import MinusEllipse from "static/images/MinusEllipse.svg";
import XCross from "static/images/XCross.svg";
import { useLazyGetQuoteQuery } from "store/apis/transferPricing";
import { setAmount, setQuoteResponse } from "store/slices/transferpricing";
import { RootState } from "store/store";
import { currency } from "utils/Currency";
import { debounce } from "utils/debouncing";
import { number, object } from "yup";
import { AmountInput } from "./AmountInput";
import classNames from "classnames";

export const AmountStep = ({
  backPath,
  setCurrentStep,
  isInitiateTransferModal = true,
}: {
  setCurrentStep?: (v: number) => void;
  backPath: string;
  isInitiateTransferModal?: boolean;
}) => {
  const { alertToast } = useToast();
  const dispatch = useDispatch();
  const { quote } = useSelector((state: RootState) => state.tpPaymentsSlice);
  const history = useHistory();
  const { search } = useLocation();
  const [getConvertedAmount] = useLazyGetQuoteQuery();

  const handleOnChange = debounce(async (e: any) => {
    const amount = e.target.value?.replaceAll(",", "");

    try {
      if (amount && !isNaN(+amount)) {
        const resp = await getConvertedAmount({ amount }).unwrap();
        dispatch(setQuoteResponse(resp));
      }
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  });

  const handleAmountSubmit = (values: any) => {
    const amount = values?.amount.replaceAll(",", "");
    dispatch(setAmount(+amount));
    setCurrentStep?.(2);
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <div>
      {isInitiateTransferModal && (
        <div className="t-pl-3 t-text-title-h2-bold">
          How much do you want to transfer?
        </div>
      )}
      <div
        className={classNames("t-mt-6", {
          "t-w-[420px]": isInitiateTransferModal,
          "t-w-[470px]": !isInitiateTransferModal,
        })}
      >
        <Formik
          initialValues={{
            amount: quote?.sender_amount,
            inramount: "",
          }}
          validateOnChange
          validateOnBlur={false}
          validationSchema={object({
            amount: number()
              .transform((value, originalValue) => {
                if (typeof originalValue === "string") {
                  return parseFloat(originalValue.replace(/,/g, ""));
                }
                return value;
              })
              .typeError("Amount must be a number")
              .moreThan(0, "Enter positive amount")
              .required("Please enter amount"),
          })}
          onSubmit={handleAmountSubmit}
        >
          <Form onChange={handleOnChange}>
            <AmountInput isInitiateTransferModal={isInitiateTransferModal} />
            <div className="t-mb-10 t-mt-6 t-flex t-flex-col t-gap-5">
              <div className="t-flex t-justify-between">
                <div className="t-flex t-gap-3">
                  <img src={MinusEllipse} alt="minus" />
                  <div className="t-text-subtitle-sm">$0.00</div>
                </div>
                <div className="t-text-body">Our fees</div>
              </div>
              <div className="t-flex t-justify-between">
                <div className="t-flex t-gap-3">
                  <img src={Equals} alt="equals" />
                  <div className="t-text-subtitle-sm">
                    {quote?.sender_amount
                      ? currency({ amount: quote?.sender_amount })
                      : "0.00"}
                  </div>
                </div>
                <div className="t-text-subtitle-sm">Amount we'll convert</div>
              </div>
              <div className="t-flex t-justify-between">
                <div className="t-flex t-gap-3">
                  {quote?.mid_market_rate ? (
                    <img src={GreenCircle} alt="filled" />
                  ) : (
                    <img src={EmptyEllipse} alt="empty" />
                  )}
                  <div className="t-text-subtitle-sm">
                    {quote?.mid_market_rate || "-"}
                  </div>
                </div>
                <div className="t-text-body">Live mid-market rate</div>
              </div>
              <div className="t-flex t-justify-between">
                <div className="t-flex t-gap-3">
                  <img src={MinusEllipse} alt="minus" />
                  <div className="t-text-subtitle-sm">0.25% (25 bps)</div>
                </div>
                <div className="t-text-body">Our FX markup fee</div>
              </div>
              <div className="t-flex t-justify-between">
                <div className="t-flex t-gap-3">
                  <img src={XCross} alt="xcross" />
                  <div className="t-text-subtitle-sm">
                    {quote?.inkle_rate || "-"}
                  </div>
                </div>
                <div className="t-text-subtitle-sm">Conversion rate</div>
              </div>
            </div>
            <TextInput
              label="Get (indicative amount)"
              name="inramount"
              disabled
              value={
                quote?.receiver_amount
                  ? currency({
                      amount: Number(quote?.receiver_amount),
                      currencyCode: "INR",
                    }).replace("₹", "")
                  : ""
              }
              icon={
                <div className="t-flex t-items-center t-gap-2">
                  <ReactCountryFlag
                    countryCode="IN"
                    svg
                    style={{ width: "20px", height: "24px" }}
                  />
                  <div>INR</div>
                </div>
              }
            />
            <div className="t-mt-1 t-text-body-sm t-text-text-30">
              Disclaimer: The conversion rate may vary depending on the exact
              time of transaction.
            </div>
            {isInitiateTransferModal && (
              <div className="t-flex t-justify-end t-gap-3 t-pt-10">
                <Button
                  type="button"
                  onClick={() => {
                    history.replace(backPath);
                  }}
                >
                  Back
                </Button>
                <Button customType="primary" type="submit">
                  Continue
                </Button>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    </div>
  );
};
