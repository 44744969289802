import { AmountSuperScript } from "components/design/AmountSuperScript";
import Modal from "components/DesignSystem/Modal/Modal";
import { LockSecure } from "components/icons/LockSecure";
import { getCoreRowModel, useReactTable } from "react-table-8.10.7";
import { AccountType, AccountsTable, bankColumns } from "./Accounts";
import { HideableAmount } from "../HideBalancesAndAmounts";

type BalanceModalProps = {
  accounts: AccountType[];
  cash: number;
};

const columns = [
  bankColumns.bank({
    size: 40,
  }),
  bankColumns.spends({
    size: 20,
  }),
  bankColumns.divider({
    size: 10,
  }),
  bankColumns.assets({
    size: 20,
  }),
];

export const BalanceModal = ({ accounts, cash }: BalanceModalProps) => {
  const table = useReactTable({
    data: accounts,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 10,
    },
  });

  return (
    <Modal.Content size="large">
      <Modal.Header>
        <Modal.Title>Balance</Modal.Title>
        <Modal.Close />
      </Modal.Header>
      <Modal.Body>
        <div className="t-mb-6">
          <label className="t-text-body t-text-text-30">Cash</label>
          <div className="t-text-[24px] t-font-medium t-text-text-60">
            {cash ? (
              <HideableAmount>
                <AmountSuperScript amount={cash} />
              </HideableAmount>
            ) : (
              "-"
            )}
          </div>
        </div>
        <AccountsTable table={table} />
      </Modal.Body>
      <Modal.Footer>
        <div className="t-flex t-items-center t-gap-3">
          <div>
            <LockSecure />
          </div>
          <p className="t-m-0 t-text-text-30 t-text-body-sm">
            Inkle connects your account securely in compliance with industry
            standards. Inkle will only have read-only access to your
            transactions.
          </p>
        </div>
      </Modal.Footer>
    </Modal.Content>
  );
};
