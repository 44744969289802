import { TPPaymentBanner } from "pages/TPPayments/TPPaymentsBanner";

interface HeaderI {
  sender?: string | null;
  receiver?: string | null;
  isOnboardingPending: boolean;
  tosAccepted: boolean;
  setInstructionsModal: (b: boolean) => void;
}

export function TransferMoneyCard({
  sender,
  receiver,
  isOnboardingPending,
  setInstructionsModal,
  tosAccepted,
}: HeaderI) {
  let toolTipContent = null;

  if (isOnboardingPending) {
    toolTipContent = "Please agree to the updated Terms of Service to proceed.";
  }

  if (!tosAccepted) {
    toolTipContent = "Please complete KYC to proceed.";
  }

  const steps = [
    "Click ‘Create Transfer’ and enter the amount you wish to move.",
    "Transfer money from your bank to the virtual account shown at the end of the transfer flow.",
    "Get the money in your Indian bank account in T+1 days, with the FIRA available shortly after",
  ];

  return (
    <>
      <TPPaymentBanner
        isOnboardingPending={isOnboardingPending}
        steps={steps}
        isOnboardingPendingHandler={() => setInstructionsModal(true)}
        sender={sender}
        receiver={receiver}
      />
    </>
  );
}
