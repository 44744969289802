export const Address = ({
  address,
}: {
  address: {
    street_address: string;
    street_address_line_2: string | null;
    city: string;
    state: string;
    zipcode: string;
    country: string;
    uuid: string;
  };
}) => {
  return (
    <>
      <span> {address?.street_address && `${address?.street_address},`}</span>
      {address?.street_address_line_2 && (
        <span> {address?.street_address_line_2},</span>
      )}
      <span> {address?.city && `${address?.city},`}</span>
      <span> {address?.state}</span>
      <br />
      <span> {address?.country && `${address?.country},`}</span>
      <span> {address?.zipcode && address?.zipcode}</span>
    </>
  );
};
