export const Transactions = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_284_15348)">
      <path
        d="M16 12.4995H3.75004L5.2667 14.0162L4.44012 14.8393L2.34012 12.7393C2.1214 12.5205 1.99854 12.2238 1.99854 11.9144C1.99854 11.6051 2.1214 11.3084 2.34012 11.0896L4.42204 9.00769L5.24687 9.83253L3.75004 11.3329H16V12.4995ZM12.7485 8.16711L13.5734 8.99194L15.6582 6.90769C15.8769 6.68891 15.9998 6.39222 15.9998 6.08286C15.9998 5.7735 15.8769 5.47681 15.6582 5.25803L13.5623 3.16211L12.7375 3.98694L14.25 5.49953H2.00004V6.66619H14.25L12.7485 8.16711Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_284_15348">
        <rect width="14" height="14" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);
