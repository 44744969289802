import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { BALANCE_SHEET } from "constants/bookkeeping";
import { REPORT_TYPE } from "dictionaries";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import BalanceSheetTemplate from "static/images/BalanceSheetTemplate.svg";
import IncomeStatementTemplate from "static/images/IncomeStatementTemplate.svg";
import ThreeDots from "static/images/ThreeDots.svg";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  useGetAllInteractiveReportsQuery,
  useUpdateInteractiveReportMutation,
} from "store/apis/reports";
import { InteractiveReport } from "types/Models/report";
import { BackendError } from "types/utils/error";
import { DeleteReport } from "./DeleteReport";
import dayjs from "dayjs";
import { DD_MMM_YYYY_LT } from "constants/date";
import EmptyReports from "static/images/EmptyReports.svg";
import { usePageTitle } from "hooks/usePageTitle";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { COPIED_TEXT, FAILED_COPIED_TEXT } from "constants/documents";
import ToolTip from "components/design/toolTip";

const ReportUI = ({ report }: { report: InteractiveReport }) => {
  const { url } = useRouteMatch();
  const { alertToast, successToast } = useToast();
  const history = useHistory();
  const { search } = useLocation();
  const { uuid: groupId } = useCurrentGroupContext();
  const { created_at, uuid: reportId, type, document_id } = report;
  const entityId = useCurrentEntityId();
  const editModal = useModal();

  const image =
    type === BALANCE_SHEET ? BalanceSheetTemplate : IncomeStatementTemplate;

  const [downloadReport, { isLoading: isDownloading }] =
    useLazyGetPreviewUrlQuery();

  const openReport = (uuid: string) => {
    history.push(`/books/live-financial/${uuid}/${search}`);
  };

  const {
    close: closeDeleteModal,
    isOpen: isDeleteModalOpen,
    open: openDeleteModal,
  } = useModal();

  const onDownload = async () => {
    try {
      const { download_url } = await downloadReport({
        groupId,
        fileId: document_id,
      }).unwrap();
      window.open(download_url);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const [updateReport, { isLoading: updating }] =
    useUpdateInteractiveReportMutation();

  const renameReport = async ({ name }: { name: string }) => {
    try {
      const { report_name } = await updateReport({
        groupId,
        entityId,
        reportId: reportId!,
        payload: {
          report_name: name,
        },
      }).unwrap();
      editModal.close();
      successToast({
        message: `Renamed to ${report_name}`,
      });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const reportName =
    REPORT_TYPE[report.report_name || ""] || report.report_name;

  const copyReportsLink = async () => {
    const reportLink = `${window.location.origin}/books/live-financial/${reportId}?company=${groupId}&entity=${entityId}`;
    try {
      await navigator.clipboard.writeText(reportLink);
      successToast({ message: COPIED_TEXT });
    } catch (err) {
      alertToast({ message: FAILED_COPIED_TEXT });
    }
    return false;
  };

  return (
    <div className="t-flex t-flex-col t-w-[184px] t-gap-3">
      <button className="all:unset" onClick={() => openReport(reportId!)}>
        <img
          key={reportId}
          src={image}
          alt="BalanceSheetTemplate"
          className="t-w-[184px] t-h-[200px] t-px-5 t-py-3 t-border-0.5 t-border-solid t-rounded-sm t-border-neutral-10 t-shadow-light-30"
        />
      </button>

      <div className="t-flex t-gap-0.5 t-justify-between">
        <div className="t-flex t-flex-col">
          <div className="t-text-subtext t-text-text-60 t-truncate t-max-w-32">
            {reportName}
          </div>
          <span className="t-text-text-30 t-text-body-sm">
            {dayjs(created_at).format(DD_MMM_YYYY_LT)}
          </span>
        </div>
        <Dropdown.Root>
          <Dropdown.Trigger asChild>
            <div>
              <Button customType="ghost_icon" size="small">
                <img src={ThreeDots} alt="Show more options" />
              </Button>
            </div>
          </Dropdown.Trigger>
          <Dropdown.Portal>
            <Dropdown.Content sideOffset={8} align="start">
              <Dropdown.Item onSelect={() => openReport(reportId!)}>
                View
              </Dropdown.Item>
              <Dropdown.Item disabled={isDownloading} onSelect={onDownload}>
                Download
              </Dropdown.Item>
              <Dropdown.Item onSelect={editModal.open}>Rename</Dropdown.Item>
              <ToolTip text="Shareable link for your teammates" side="left">
                <div>
                  <Dropdown.Item onSelect={copyReportsLink}>
                    Copy link
                  </Dropdown.Item>
                </div>
              </ToolTip>
              <Dropdown.Item onSelect={openDeleteModal}>
                <span className="t-text-red">Delete</span>
              </Dropdown.Item>
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      </div>

      <DeleteReport
        close={closeDeleteModal}
        isOpen={isDeleteModalOpen}
        reportId={reportId!}
      />

      <Modal.Root open={editModal.isOpen} onOpenChange={editModal.toggle}>
        <Modal.Content>
          <Formik
            initialValues={{
              name: reportName || "",
            }}
            onSubmit={renameReport}
          >
            {({ submitForm, dirty }) => (
              <>
                <Modal.Header>
                  <Modal.Title>Rename {reportName}</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <Form className="t-m-0">
                    <TextInput label="Name" name="name" />
                  </Form>
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button>Cancel</Button>
                  </Modal.RawClose>
                  <Button
                    onClick={submitForm}
                    customType="primary"
                    isLoading={updating}
                    disabled={updating || !dirty}
                  >
                    Save Changes
                  </Button>
                </Modal.FooterButtonGroup>
              </>
            )}
          </Formik>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};

export const YourReports = () => {
  usePageTitle("Saved Reports");
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const {
    data: yourReports,
    isLoading,
    isSuccess,
  } = useGetAllInteractiveReportsQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId, refetchOnMountOrArgChange: true }
  );

  const { reports = [] } = yourReports || {};

  const isEmpty = reports?.length === 0;

  return (
    <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
      <Async.Empty>
        <div className="t-flex t-flex-col t-gap-3 t-w-full t-justify-center t-h-full t-items-center">
          <img
            src={EmptyReports}
            alt="No Reports saved"
            className="t-flex t-justify-center"
          />
          <div className="t-text-body-lg">No reports saved yet</div>
        </div>
      </Async.Empty>
      <Async.Success>
        <section className="t-flex t-gap-6 t-flex-col t-h-full t-pl-10 t-pt-5">
          <div className="t-flex t-gap-8 t-flex-wrap">
            {reports?.map((report: InteractiveReport) => (
              <ReportUI report={report} key={report.uuid} />
            ))}
          </div>
        </section>
      </Async.Success>
    </Async.Root>
  );
};
