import { TodoListIcon } from "components/icons/LeftNav/TodoList";
import {
  LeftBarItem,
  LeftBarTag,
  useLeftBar,
} from "components/Sidebar/LeftBar";
import { CHAT_REQUESTS } from "constants/bookkeeping";
import { REQUESTED_INFO_CUSTOM_TYPE } from "constants/chatType";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePaginatedMessages } from "hooks/usePaginatedMessages";
import { parse, stringify } from "qs";
import { ComponentProps } from "react";
import { useLocation } from "react-router-dom";
import {
  TaxActionItemType,
  useGetActionItemsQuery,
} from "store/apis/actionItems";
import { useChatContext } from "stream-chat-react";
import { BooksActionItems } from "types/Models/books";

export const TodoLeftNav = (
  props: Partial<ComponentProps<typeof LeftBarItem>>
) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { client } = useChatContext();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const {
    data = { tax_action_items: [] },
    isSuccess: isTaxSuccess,
    isLoading: isTaxLoading,
  } = useGetActionItemsQuery(
    {
      groupId: groupId,
      type: "TAX",
    },
    {
      skip: !groupId,
    }
  );

  const {
    data: booksData = { book_action_items: [] },
    isSuccess: isBooksSuccess,
    isLoading: isBooksLoading,
  } = useGetActionItemsQuery(
    {
      groupId: groupId,
      type: "BOOKS",
    },
    {
      skip: !groupId,
    }
  );

  const { action_items_response, channel_url } =
    (booksData as BooksActionItems) || {};

  const actionItems =
    action_items_response?.filter(
      ({ item_type }) =>
        item_type !== "Reconciliation" && item_type !== "Financial Closing"
    ) || [];

  const { messages: chatActionItems, loading } = usePaginatedMessages(
    client,
    {
      id: channel_url,
    },
    {},
    {},
    {
      custom_type: {
        $in: [REQUESTED_INFO_CUSTOM_TYPE],
      },
      card_status: { $eq: "REQUESTED" },
    },
    !channel_url
  );

  const chatCards = chatActionItems?.map(
    ({
      message: {
        custom_data: { header, transaction_count },
        id,
      },
    }: any) => ({
      header,
      transaction_count,
      id,
      channel_url,
      item_type: CHAT_REQUESTS,
    })
  );

  const allBooksActionItems = actionItems.flatMap(({ items, item_type }) =>
    items.map((item) => ({ ...item, item_type }))
  );

  const totalBooksItemsCount = allBooksActionItems.length + chatCards.length;

  const totalTaxItemsCount = (data as { tax_action_items: TaxActionItemType[] })
    ?.tax_action_items?.length;

  const totalCount = totalBooksItemsCount + totalTaxItemsCount;
  const { open } = useLeftBar();

  const IconWithBadge = () => {
    return (
      <div className="t-relative">
        <TodoListIcon />
        {Boolean(totalCount && !open) &&
          isBooksSuccess &&
          isTaxSuccess &&
          totalCount > 0 && (
            <LeftBarTag type="closed">
              {totalCount > 99 ? "+99" : totalCount}
            </LeftBarTag>
          )}
      </div>
    );
  };
  return (
    <LeftBarItem
      suffix={
        Boolean(totalCount) &&
        isBooksSuccess &&
        isTaxSuccess &&
        totalCount > 0 && (
          <LeftBarTag type="blue">
            {totalCount > 99 ? "+99" : totalCount}
          </LeftBarTag>
        )
      }
      to={`/todolist${search}`}
      icon={IconWithBadge}
      {...props}
    >
      Action Centre
    </LeftBarItem>
  );
};
