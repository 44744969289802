import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import Table from "components/DesignSystem/Table/V2/Table";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Loader from "components/design/loader";
import { BankLogo } from "components/icons/BankLogo";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { MerchantComponent } from "components/Transaction/MerchantSelector";
import TransactionColumn, {
  SourceTransaction,
} from "components/Transaction/TransactionColumn";
import { useEffect, useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useGetAllAccountQuery,
  useGetAllTransactionsDataQuery,
  useGetSuggestionsToLinkTransactionsQuery,
} from "store/apis/transactions";
import { Transaction, Transactions } from "types/Models/books";
import { ModalProps } from "types/utils/modal";
import classNames from "classnames";
import { Search } from "components/DesignSystem/Search/Search";
import { TableUI } from "components/design/TableUI";
import * as Accordion from "@radix-ui/react-accordion";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { SolidRoundCheck } from "components/icons/SolidRoundCheck";
import { WaringCircle } from "components/icons/Chat/WaringCircle";
import { CircleWarning } from "components/icons/CircleWarning";
import { produce } from "immer";
import { InternalTransaction } from "./AddTransaction/AddTransactionManuallyModal";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { currency } from "utils/Currency";
import ToolTip from "components/design/toolTip";

export const SelectedRow = ({
  from,
  logo,
  merchant,
  amount,
  date,
  category,
  unstyled,
}: Pick<
  Transactions["transaction"],
  "amount" | "from" | "logo" | "merchant" | "date"
> & { category?: string; unstyled?: boolean }) => {
  return (
    <Table.Container
      className={classNames("t-h-full t-w-full", {
        "t-mt-3": !unstyled,
        "t-mt-0": unstyled,
      })}
    >
      <Table.Content>
        <Table.Body>
          <Table.Row
            className={classNames(
              "all:unset t-flex !t-rounded !t-w-full !t-px-3 t-items-center",
              {
                "!t-border-0": unstyled,
                "!t-border  !t-border-neutral-0": !unstyled,
              }
            )}
          >
            <Table.Cell style={{ width: "15%" }}>
              <div className="t-text-subtext t-text-text-30">
                {dayjs(date).format(DD_MMM_YYYY)}
              </div>
            </Table.Cell>
            <Table.Cell style={{ width: "15%" }}>
              <ConditionalToolTip
                condition={
                  from &&
                  `${from?.bank_account.nickname} ${from.bank_account.mask}`
                }
              >
                <div className="t-w-full t-flex t-justify-center">
                  {from?.bank_account.bank_brand?.logo_url ? (
                    <Avatar
                      src={from.bank_account.bank_brand?.logo_url}
                      alt={"Bank"}
                    />
                  ) : (
                    <BankLogo />
                  )}
                </div>
              </ConditionalToolTip>
            </Table.Cell>
            <Table.Cell style={{ width: "25%" }}>
              {logo || merchant ? (
                <MerchantComponent logo={logo} name={merchant || ""} />
              ) : (
                "-"
              )}
            </Table.Cell>
            <Table.Cell style={{ width: "25%" }}>
              <div>{category || "-"}</div>
            </Table.Cell>
            <Table.Cell style={{ width: "20%" }}>
              <div className="t-flex t-justify-end">
                {amount ? <AmountSuperScript amount={amount} /> : "-"}
              </div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

const columnHelper = createColumnHelper<Transactions>();

const columns = [
  columnHelper.accessor("transaction.date", {
    id: "date",
    size: 10,
    header: "Date",
    cell: (info) => (
      <div className="t-text-subtext t-text-text-30">
        {dayjs(info.getValue()).format(DD_MMM_YYYY)}
      </div>
    ),
  }),

  columnHelper.accessor("transaction.from", {
    id: "logo",
    size: 12,
    header: () => (
      <div className="t-flex t-justify-center t-w-full">Source</div>
    ),
    cell: (info) => {
      const transaction = info.row.original.transaction;

      return <TransactionColumn.Source transaction={transaction} />;
    },
  }),

  columnHelper.accessor("transaction.merchant", {
    id: "merchant",
    size: 30,
    header: "Vendor",
    cell: ({
      row: {
        original: {
          transaction: { merchant, logo },
        },
      },
    }) => (
      <>
        {merchant || logo ? (
          <MerchantComponent logo={logo} name={merchant || ""} />
        ) : (
          "-"
        )}
      </>
    ),
  }),

  columnHelper.accessor("transaction.category", {
    id: "category",
    size: 35,
    header: "Category",
    cell: ({
      row: {
        original: {
          transaction: { category },
        },
      },
    }) => {
      return <div>{category?.name ? category?.name : "-"}</div>;
    },
  }),

  columnHelper.accessor("transaction.amount", {
    id: "amount",
    size: 12,
    header: () => <div className="t-flex t-justify-end t-mr-10">Amount</div>,
    cell: (info) => {
      const amount = info.getValue();
      const {
        transaction: { is_credit_card },
      } = info.row.original || {};

      return (
        <TransactionColumn.Amount
          amount={amount}
          isCreditCard={is_credit_card}
        />
      );
    },
  }),
];

const useGetSuggestedTransactionForInternalTransactions = ({
  sourceTransaction,
  skip,
}: {
  sourceTransaction: SourceTransaction;
  skip: boolean;
}) => {
  const { transactionInfo, category } = sourceTransaction || {};
  const group = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const groupId = group.uuid;

  const { data: froms = [], isLoading: accountLoading } = useGetAllAccountQuery(
    {
      groupId,
      entityId,
    },
    {
      skip: !groupId || !entityId || skip,
    }
  );

  const fromIds = froms
    .filter(({ uuid }) => uuid !== transactionInfo.from?.bank_account.uuid!)
    .map(({ uuid }) => uuid);

  let cashFlowValue = "";
  const amount = Math.abs(transactionInfo.amount!).toString();

  const isSuggested = category?.categoryType === "SUGGESTED";

  if (
    category?.categoryType === "BANK_TRANSFER" ||
    (isSuggested && category.data.includes("Bank Transfer"))
  ) {
    if (transactionInfo && transactionInfo.amount > 0) {
      cashFlowValue = "DEBIT";
    } else {
      cashFlowValue = "CREDIT";
    }
  } else if (
    category?.categoryType === "PAY_DOWN_CREDIT" ||
    (isSuggested && category.data.includes("Pay Down Credit"))
  ) {
    if (transactionInfo && transactionInfo.amount >= 0) {
      cashFlowValue = "DEBIT";
    } else {
      cashFlowValue = "CREDIT";
    }
  }

  const filters = {
    startDate: {
      name: "Since",
      value:
        dayjs(transactionInfo.date.toString())
          .subtract(10, "days")
          .format(YYYY_MM_DD)
          .toString() || "",
      type: "startDate",
    },
    endDate: {
      name: "Till",
      value:
        dayjs(transactionInfo.date.toString())
          .add(10, "days")
          .format(YYYY_MM_DD)
          .toString() || "",
      type: "endDate",
    },
    fromIds: { name: "Source", type: "fromIds", value: fromIds },
    cashFlow: {
      name: "Cash Flow",
      value: cashFlowValue,
      type: "amount",
    },
  };

  const { data: transactionData, isLoading } = useGetAllTransactionsDataQuery(
    {
      groupId,
      entityId,
      minAmount: amount,
      maxAmount: amount,
      ...filters,
    },
    { skip: !groupId || !entityId || skip, refetchOnMountOrArgChange: true }
  );

  if (transactionData?.transactions) {
    return {
      data: {
        [sourceTransaction.transactionInfo.uuid]: transactionData?.transactions,
      },
      isLoading: accountLoading || isLoading,
    };
  }

  return {
    data: {
      [sourceTransaction.transactionInfo.uuid]: [],
    },
    isLoading: accountLoading || isLoading,
  };
};

const SuggestedTransactions = ({
  transactions,
  onValueChange,
  mapToTransactionId,
  linkTransactionsMap,
}: {
  transactions: Transactions[];
  onValueChange: (id: string) => void;
  linkTransactionsMap: Record<string, string | null>;
  mapToTransactionId: string;
}) => {
  const table = useReactTable({
    columns,
    data: transactions,
    getCoreRowModel: getCoreRowModel(),
  });

  if (transactions.length === 0) {
    return (
      <div className="t-flex t-justify-center t-items-center t-w-full t-h-40 t-text-text-30">
        No transactions found to link
      </div>
    );
  }

  return (
    <Table.Container layout="flex" size="regular" className="t-h-full t-w-full">
      <Table.Content>
        <Table.Head>
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.HeadRow key={headerGroup.id} className="t-pl-10">
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  key={header.id}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.HeadRow>
          ))}
        </Table.Head>
        <Table.Body>
          <Radio.Root
            onValueChange={onValueChange}
            // @ts-ignore // undefined takes the default value and doesn't clear the selection
            value={linkTransactionsMap[mapToTransactionId] || null}
            asChild
          >
            <Radio.Content className="!t-w-full t-flex t-flex-col t-h-full">
              {table.getRowModel().rows.map((row) => {
                const disabled = Object.values(
                  Object.fromEntries(
                    Object.entries(linkTransactionsMap).filter(
                      ([key, _]) => key !== mapToTransactionId
                    )
                  )
                ).includes(row.original.transaction.uuid);

                return (
                  <ConditionalToolTip
                    keepOpenOnActivation
                    key={row.id}
                    condition={
                      disabled &&
                      "Already selected to link to another transaction"
                    }
                  >
                    <span>
                      <span
                        className={classNames({
                          "t-pointer-events-none": disabled,
                        })}
                      >
                        <Radio.Item
                          aria-label={`Select transaction, source: ${
                            row.original.transaction.merchant || "Unassingned"
                          }, amount: ${currency({
                            amount: Number(row.original.transaction.amount),
                          })}`}
                          asChild
                          disabled={disabled}
                          value={row.original.transaction.uuid}
                          id={`${mapToTransactionId}-${row.original.transaction.uuid}`}
                          className="!t-h-12"
                        >
                          <Table.Row
                            key={row.id}
                            className={classNames(
                              "hover:t-bg-surface-lighter-grey t-cursor-pointer t-w-full t-h-full",
                              {
                                "t-bg-surface-lighter-grey":
                                  linkTransactionsMap[mapToTransactionId] ===
                                  row?.original?.transaction?.uuid,
                              }
                            )}
                          >
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <Table.Cell
                                  key={cell.id}
                                  style={{
                                    width: `${cell.column.getSize()}%`,
                                  }}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Table.Cell>
                              );
                            })}
                          </Table.Row>
                        </Radio.Item>
                      </span>
                    </span>
                  </ConditionalToolTip>
                );
              })}
            </Radio.Content>
          </Radio.Root>
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

const LinkAccordions = ({
  suggestedTransactions,
  sourceTransaction,
  linkTransactionsMapping,
  setLinkTransactionsMapping,
  updateLinkTransaction,
}: {
  suggestedTransactions: Record<string, Transactions[]>;
  sourceTransaction: SourceTransaction[];
  linkTransactionsMapping: Record<string, string | null>;
  setLinkTransactionsMapping: React.Dispatch<
    React.SetStateAction<Record<string, string | null>>
  >;
  updateLinkTransaction: (
    transactionId: string,
    linkTransactionId: string
  ) => void;
}) => {
  return (
    <Accordion.Root
      className="t-flex t-flex-col t-gap-8"
      type="multiple"
      defaultValue={Object.keys(suggestedTransactions)}
    >
      {Object.entries(suggestedTransactions).map(
        ([transactionId, suggestions]) => {
          const currentSuggestion = sourceTransaction.find(
            (s) => s.transactionInfo.uuid === transactionId
          );

          const invertedLinkTransactionsMapping = Object.fromEntries(
            Object.entries(linkTransactionsMapping).map(([key, value]) => [
              value,
              key,
            ])
          );

          const transactionLinkedToParent =
            invertedLinkTransactionsMapping[transactionId];

          return (
            <ConditionalToolTip
              keepOpenOnActivation
              key={transactionId}
              condition={
                transactionLinkedToParent &&
                "Already selected to link to another transaction"
              }
            >
              <div>
                <div
                  className={classNames("t-flex t-gap-3 t-w-full", {
                    "t-pointer-events-none t-opacity-60":
                      transactionLinkedToParent,
                  })}
                >
                  <div className="t-mt-[18px]">
                    <Checkbox
                      aria-label={`Select transaction, source: ${
                        currentSuggestion?.transactionInfo.merchant ||
                        "Unassingned"
                      }, amount: ${currency({
                        amount: Number(
                          currentSuggestion?.transactionInfo.amount
                        ),
                      })}`}
                      alignTop
                      disabled={transactionLinkedToParent}
                      checked={
                        linkTransactionsMapping[transactionId] !== undefined
                      }
                      onChange={(e) =>
                        setLinkTransactionsMapping((m) =>
                          produce(m, (draft) => {
                            const alreadyLinkedToTransaction = Object.entries(
                              m
                            ).find(([_, value]) => value === transactionId);

                            if (alreadyLinkedToTransaction?.[0]) {
                              draft[alreadyLinkedToTransaction[0]] = null;
                            }
                            delete draft[transactionId];

                            if (e.target.checked) {
                              draft[transactionId] = null;
                            }
                          })
                        )
                      }
                    />
                  </div>

                  <Accordion.Item
                    className="t-overflow-hidden t-rounded t-border-solid t-border t-border-neutral-10 t-flex-1 data-state-open:t-shadow-light-30"
                    value={transactionId}
                  >
                    <Accordion.Trigger className="all:unset t-text-subtitle-sm t-text-text-60 t-flex t-gap-2 t-group t-w-full t-bg-surface-lighter-grey t-border-0 t-border-solid t-border-neutral-10 t-border-b t-py-2">
                      {currentSuggestion && (
                        <SelectedRow
                          unstyled
                          {...currentSuggestion.transactionInfo}
                          category={currentSuggestion.category.categoryName}
                        />
                      )}
                    </Accordion.Trigger>
                    <Accordion.Content className="t-py-4 t-px-3">
                      <SuggestedTransactions
                        mapToTransactionId={transactionId}
                        transactions={suggestions.filter(
                          (s) => s.transaction.uuid !== transactionId
                        )}
                        onValueChange={(id) =>
                          updateLinkTransaction(transactionId, id)
                        }
                        linkTransactionsMap={
                          transactionLinkedToParent
                            ? {
                                ...linkTransactionsMapping,
                                [transactionId]: transactionLinkedToParent,
                              }
                            : linkTransactionsMapping
                        }
                      />
                    </Accordion.Content>
                  </Accordion.Item>

                  <div
                    className={classNames("t-mt-[18px]", {
                      "t-opacity-0 t-invisible t-pointer-events-none":
                        linkTransactionsMapping[transactionId] === undefined,
                    })}
                  >
                    {linkTransactionsMapping[transactionId] ? (
                      <ToolTip text="This transaction is linked to another transaction">
                        <span className="t-text-purple-40">
                          <SolidRoundCheck size="20" />
                        </span>
                      </ToolTip>
                    ) : (
                      <ToolTip text="No transaction selected to link to">
                        <span className="t-text-red">
                          <CircleWarning />
                        </span>
                      </ToolTip>
                    )}
                  </div>
                </div>
              </div>
            </ConditionalToolTip>
          );
        }
      )}
    </Accordion.Root>
  );
};

type LinkTransactionProps = {
  isLinkTransactionOpen: boolean;
  closeLinkTransaction: () => void;
  sourceTransaction: SourceTransaction | SourceTransaction[];
  onSelect: (
    transactionMap: Record<string, InternalTransaction>
  ) => Promise<void> | void;
  isUpdating?: boolean;
  excludeTransactionIds?: string[];
};

export const LinkTransaction = ({
  isLinkTransactionOpen,
  closeLinkTransaction,
  sourceTransaction: sourceTransactionFromProps,
  onSelect,
  isUpdating,
  excludeTransactionIds,
}: LinkTransactionProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { isOpen, close, open } = useModal();

  const [linkTransactionsMapping, setLinkTransactionsMapping] = useState<
    Record<string, string | null>
  >({});

  const sourceTransaction =
    sourceTransactionFromProps instanceof Array
      ? sourceTransactionFromProps
      : [sourceTransactionFromProps];

  const { data: transactionData, isLoading: loadingForInternal } =
    useGetSuggestedTransactionForInternalTransactions({
      sourceTransaction: sourceTransaction[0],
      skip: sourceTransaction.length > 1,
    });

  const {
    data: suggestedTransactionsResult,
    isLoading: loadingSuggestions,
    isSuccess: loadedSuggestions,
  } = useGetSuggestionsToLinkTransactionsQuery(
    {
      entityId,
      payload: {
        transaction_ids: sourceTransaction.map(
          ({ transactionInfo }) => transactionInfo.uuid
        ),
        category_id: sourceTransaction[0].category.categoryId,
      },
    },
    { skip: !groupId || !entityId }
  );

  let suggestedTransactions =
    sourceTransaction.length > 1
      ? suggestedTransactionsResult
      : transactionData;

  suggestedTransactions = useMemo(() => {
    return Object.fromEntries(
      Object.entries(suggestedTransactions || {}).filter(([_, suggestions]) =>
        suggestions.filter(
          ({ transaction }) =>
            !excludeTransactionIds?.includes(transaction.uuid)
        )
      )
    );
  }, [
    JSON.stringify(suggestedTransactions),
    JSON.stringify(excludeTransactionIds),
  ]);

  useEffect(() => {
    if (suggestedTransactions) {
      let suggestedTransactionsMap = Object.entries(
        suggestedTransactions
      ).reduce((acc, [transactionId, suggestions]) => {
        if (suggestions.length > 0) {
          const currentSuggestion = suggestions[0].transaction.uuid;
          const addedAlready = Object.values(acc).includes(currentSuggestion);

          if (suggestions.length === 1 && !addedAlready) {
            acc[transactionId] = currentSuggestion;
            return acc;
          }
        }

        acc[transactionId] = null;
        return acc;
      }, {} as Record<string, string | null>);

      suggestedTransactionsMap = Object.fromEntries(
        Object.entries(suggestedTransactionsMap).filter(
          ([transactionId, suggestionId]) =>
            !Object.values(suggestedTransactionsMap).includes(transactionId)
        )
      );

      setLinkTransactionsMapping(suggestedTransactionsMap);
    }
  }, [suggestedTransactions]);

  const onUpdateCategory = async () => {
    close();

    try {
      const allSuggestions = Object.values(suggestedTransactions).flat();
      await onSelect(
        Object.entries(linkTransactionsMapping).reduce(
          (acc, [transactionId, suggestionId]) => {
            const suggestion = allSuggestions.find(
              ({ transaction }) => transaction.uuid === suggestionId
            )?.transaction;

            if (!suggestion) {
              return acc;
            }

            acc[transactionId] = {
              id: 0,
              date: suggestion.date,
              merchant: suggestion.merchant!,
              category: suggestion.category?.uuid!,
              amount: suggestion.amount,
              description: suggestion.description!,
              invoice: null,
              uuid: suggestion.uuid,
            };
            return acc;
          },
          {} as Record<string, InternalTransaction>
        )
      );
    } catch (error) {}
  };

  if (sourceTransaction.length === 1) {
    const transactionId = sourceTransaction[0].transactionInfo.uuid;
    const currentSource = sourceTransaction.find(
      (s) => s.transactionInfo.uuid === transactionId
    );
    return (
      <div>
        <Modal.Root
          open={isLinkTransactionOpen}
          onOpenChange={closeLinkTransaction}
        >
          <Modal.Content size="xxl">
            <Modal.Header>
              <div className="t-flex t-flex-col t-gap-1">
                <Modal.Title>Link Transaction</Modal.Title>
              </div>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <div className="">
                <p className="t-text-subtext t-mb-2 t-text-text-30">
                  Selected transaction:
                </p>
                <div className="all:unset t-text-subtitle-sm t-text-text-60 t-flex t-gap-2 t-group t-w-full t-rounded t-border-solid t-border-neutral-10 t-border t-py-2 t-mb-6">
                  {currentSource && (
                    <SelectedRow
                      unstyled
                      {...currentSource.transactionInfo}
                      category={currentSource.category?.categoryName}
                    />
                  )}
                </div>
                <Async.Root
                  isLoading={Boolean(loadingForInternal)}
                  isSuccess={!Boolean(loadingForInternal)}
                  isEmpty={Object.keys(transactionData || {}).length === 0}
                >
                  <Async.Empty>No transactions found to link</Async.Empty>
                  <Async.Success>
                    <>
                      <p className="t-text-subtext t-mb-3 t-text-text-30">
                        Choose a transaction to link with the above selection:
                      </p>
                      {suggestedTransactions &&
                        suggestedTransactions[transactionId] && (
                          <SuggestedTransactions
                            mapToTransactionId={transactionId}
                            transactions={suggestedTransactions[
                              transactionId
                            ].filter(
                              (s) => s.transaction.uuid !== transactionId
                            )}
                            onValueChange={(id) =>
                              setLinkTransactionsMapping((map) => ({
                                ...map,
                                [transactionId]: id,
                              }))
                            }
                            linkTransactionsMap={linkTransactionsMapping}
                          />
                        )}
                    </>
                  </Async.Success>
                </Async.Root>
              </div>
            </Modal.Body>
            <Modal.Footer className="t-flex t-justify-end t-gap-3">
              <Button onClick={closeLinkTransaction}>Cancel</Button>
              <Button
                customType="primary"
                onClick={open}
                disabled={
                  Object.values(linkTransactionsMapping).filter(Boolean)
                    .length !== Object.keys(linkTransactionsMapping).length ||
                  isUpdating
                }
                isLoading={isUpdating}
              >
                Link transaction
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal.Root>
        {isOpen && (
          <ConfirmationModal
            isOpen={isOpen}
            close={close}
            onUpdateCategory={onUpdateCategory}
          />
        )}
      </div>
    );
  }

  const updateLinkTransaction = (
    transactionId: string,
    linkTransactionId: string
  ) => {
    setLinkTransactionsMapping((map) =>
      produce(map, (draft) => {
        draft[transactionId] = linkTransactionId;
        delete draft[linkTransactionId];
      })
    );
  };

  return (
    <div>
      <Modal.Root
        open={isLinkTransactionOpen}
        onOpenChange={closeLinkTransaction}
      >
        <Modal.Content size="xxl">
          <Modal.Header>
            <div className="t-flex t-flex-col t-gap-1">
              <Modal.Title>Link Transactions</Modal.Title>
            </div>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <Async.Root
              isLoading={loadingSuggestions}
              isSuccess={loadedSuggestions}
              isEmpty={false}
            >
              <Async.Success>
                <div className="">
                  {suggestedTransactions && (
                    <LinkAccordions
                      suggestedTransactions={suggestedTransactions}
                      sourceTransaction={sourceTransaction}
                      linkTransactionsMapping={linkTransactionsMapping}
                      setLinkTransactionsMapping={setLinkTransactionsMapping}
                      updateLinkTransaction={updateLinkTransaction}
                    />
                  )}
                </div>
              </Async.Success>
            </Async.Root>
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-end t-gap-3">
            <Button onClick={closeLinkTransaction}>Cancel</Button>
            <Button
              customType="primary"
              onClick={open}
              disabled={
                Object.values(linkTransactionsMapping).filter(Boolean)
                  .length !== Object.keys(linkTransactionsMapping).length ||
                isUpdating
              }
              isLoading={isUpdating}
            >
              Link transactions
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      {isOpen && (
        <ConfirmationModal
          isOpen={isOpen}
          close={close}
          onUpdateCategory={onUpdateCategory}
        />
      )}
    </div>
  );
};

const ConfirmationModal = ({
  isOpen,
  close,
  onUpdateCategory,
}: {
  onUpdateCategory: () => {};
} & ModalProps) => {
  return (
    <div>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Confirm Categorisation</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            This action will remove existing categorisation and unlink
            previously linked transactions. Are you sure?
          </Modal.Body>
          <Modal.Footer className="t-flex t-justify-end t-gap-3">
            <Button onClick={close}>Cancel</Button>
            <Button customType="primary" onClick={onUpdateCategory}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
