import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Divider } from "components/design/Divider";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { KeyDownWrapper } from "components/DesignSystem/KeydownWrapper/KeydownWrapper";
import Slider from "components/DesignSystem/Slider/Slider";
import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import { Label } from "components/DesignSystem/TextInput/TextInput";
import { BankLogo } from "components/icons/BankLogo";
import { Cross } from "components/icons/Cross";
import { DeleteJournalEntry } from "components/JournalEntry/DeleteJournalEntry";
import { EditJournalEntry } from "components/JournalEntry/EditJournalEntry";
import { EditModal } from "components/Transaction/EditModal";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { TRANSACTION_SOURCE } from "dictionaries";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useGetTransaction } from "hooks/useGetTransaction";
import { useModal } from "hooks/useModal";
import { ReactNode } from "react";
import { useDispatch, useSelector } from "react-redux";
import ThreeDots from "static/images/ThreeDots.svg";
import { closeSlider, openSlider } from "store/slices/transactions";
import { RootState } from "store/store";
import { Transaction } from "types/Models/books";
import { SplitCategoryOnPopover } from "../SplitCategoryOnPopover";
import { CategorySelector, MerchantSelectDelete } from "../TransactionColumn";
import { UnLinkTransaction } from "../UnLinkTransaction";
import { Comments } from "./Comment";
import { DeleteTransaction } from "./DeleteTransaction";
import { Invoices } from "./Invoice";
import { Memo } from "./Memo";
import { SplitTransactionDetail } from "./SplitTransactionDetail";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { useUpdateQuery } from "hooks/useQuery";
import { AUTO_MATCHED } from "constants/invoiceTransactionMappingStatus";
import { ConditionalLink } from "components/conditionalLink";
import { ViewJournalEntryModal } from "components/JournalEntry/ViewJournalEntry";
import { useGetRelatedLedgerEntryQuery } from "store/apis/generalLedger";
import { NavLink } from "react-router-dom";
import { LinkedInvoices } from "./LinkedInvoices";
import Pencil from "components/icons/pencil";
import { LinkInkleInvoiceModal } from "../LinkInkleInvoiceModal";
import { useGetMappedInvoiceQuery } from "store/apis/transactions";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

const Header = ({ transactionId }: { transactionId: string }) => {
  const dispatch = useDispatch();
  const { data: transactionDetail } = useGetTransaction({
    transactionId,
  });

  const { transaction: { logo, merchant, amount, from } = {} as Transaction } =
    transactionDetail || {};

  const {
    isOpen: isEditModalOpen,
    open: openEditModal,
    close: closeEditModal,
  } = useModal();

  const {
    isOpen: isDeleteModalOpen,
    open: openDeleteModal,
    close: closeDeleteModal,
  } = useModal();

  const {
    isOpen: isEditJournalEntryModalOpen,
    open: openEditJournalEntryModal,
    close: closeEditJournalEntryModal,
  } = useModal();

  const {
    isOpen: isDeleteJournalEntryModalOpen,
    open: openDeleteJournalEntryModal,
    close: closeDeleteJournalEntryModal,
  } = useModal();

  const isEditAndDeleteAllowed =
    from?.source === "BANK_STATEMENT" ||
    from?.source === "JOURNAL_ENTRY" ||
    from?.source === "LEDGER_ENTRY";

  const isLedgerEntryTransaction = from?.source === "LEDGER_ENTRY";

  const onDelete = () => {
    if (isLedgerEntryTransaction) {
      openDeleteJournalEntryModal();
    } else {
      openDeleteModal();
    }
  };

  const onEdit = () => {
    if (isLedgerEntryTransaction) {
      openEditJournalEntryModal();
    } else {
      openEditModal();
    }
  };

  return (
    <>
      <div className="t-w-full t-px-5 t-py-3 t-border t-border-solid t-border-b t-border-l-0 t-border-t-0 t-border-r-0 t-border-neutral-0 t-bg-surface t-flex t-justify-between t-sticky t-top-0 t-z-header">
        <div className="t-flex t-gap-2 t-items-center">
          {merchant && <Avatar src={logo || ""} alt={merchant!} size="large" />}
          <Slider.Title>
            <div className="t-text-subtitle-sm t-break-words t-max-w-32 t-line-clamp-2">
              {merchant || <AmountSuperScript amount={amount} />}
            </div>
          </Slider.Title>
        </div>
        <div className="t-flex t-gap-2 t-items-center">
          {isEditAndDeleteAllowed && (
            <Dropdown.Root>
              <Dropdown.Trigger asChild>
                <div>
                  <Button size="small" customType="ghost_icon">
                    <img
                      src={ThreeDots}
                      alt="Action"
                      className="t-select-none"
                    />
                  </Button>
                </div>
              </Dropdown.Trigger>
              <Dropdown.Portal>
                <Dropdown.Content
                  sideOffset={8}
                  side="bottom"
                  className="t-mr-16"
                >
                  <Dropdown.Item key="edit" onSelect={onEdit}>
                    Edit
                  </Dropdown.Item>
                  <Dropdown.Item key="delete" onSelect={onDelete}>
                    Delete
                  </Dropdown.Item>
                </Dropdown.Content>
              </Dropdown.Portal>
            </Dropdown.Root>
          )}
          <Button
            customType="ghost_icon"
            size="small"
            onClick={() => {
              dispatch(closeSlider(transactionId));
            }}
          >
            <Cross color="currentColor" />
          </Button>
        </div>
      </div>
      <KeyDownWrapper>
        <EditModal
          transactionId={transactionId}
          isOpen={isEditModalOpen}
          close={closeEditModal}
        />
        <DeleteTransaction
          transactionId={transactionId}
          close={closeDeleteModal}
          isOpen={isDeleteModalOpen}
        />

        <DeleteJournalEntry
          transactionId={transactionId}
          close={closeDeleteJournalEntryModal}
          isOpen={isDeleteJournalEntryModalOpen}
        />
        <EditJournalEntry
          transactionId={transactionId}
          close={closeEditJournalEntryModal}
          isOpen={isEditJournalEntryModalOpen}
        />
      </KeyDownWrapper>
    </>
  );
};

export const InfoItem = ({
  label,
  children,
}: {
  label: string | ReactNode;
  children: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-1">
      <Label className="!t-pb-0">{label}</Label>
      <span className="t-text-subtext">{children}</span>
    </div>
  );
};

const Category = ({ transactionId }: { transactionId: string }) => {
  const { data: transactionDetail } = useGetTransaction({
    transactionId,
  });

  const { split_transactions = [], transaction } = transactionDetail || {};
  let tooltipText = transaction?.category?.name || "";

  if (split_transactions) {
    tooltipText = split_transactions.reduce(
      (ac, txn) => (ac += ac ? `, ${txn.category?.name}` : txn.category?.name),
      tooltipText
    );
  }

  if (transactionDetail) {
    if (split_transactions) {
      return (
        <InfoItem label="Category">
          <KeyDownWrapper>
            <SplitCategoryOnPopover
              transaction={transactionDetail}
              outline
              showSplitActions
              tooltipText={tooltipText}
            />
          </KeyDownWrapper>
        </InfoItem>
      );
    }
    return (
      <InfoItem label="Category">
        <KeyDownWrapper>
          <CategorySelector
            transaction={transactionDetail}
            outline
            showSplitActions
            txnsToShowInLabel={tooltipText}
            tooltip
            tooltipText={tooltipText}
          />
        </KeyDownWrapper>
      </InfoItem>
    );
  }

  return null;
};

const Info = ({ transactionId }: { transactionId: string }) => {
  const { data: transactionDetail } = useGetTransaction({
    transactionId,
  });

  const dispatch = useDispatch();
  const {
    isOpen: isUnlinkOpen,
    close: closeUnlink,
    open: openUnlink,
  } = useModal();

  const {
    isOpen: isViewJournalEntryModalOpen,
    close: closeViewJournalEntryModal,
    open: openViewJournalEntryModal,
  } = useModal();

  const {
    transaction: {
      date,
      amount,
      from,
      description,
      linked_transaction = "",
      ledger_entry,
    } = {} as Transaction,
  } = transactionDetail || {};
  const isSplitTransaction = Array.isArray(
    transactionDetail?.split_transactions
  );
  const splitTransactionVendors = transactionDetail?.split_transactions
    ?.map((transaction) => transaction.merchant)
    .join(", ");

  const {
    entry_name,
    entry_number,
    uuid: ledger_entry_id,
  } = ledger_entry || {};

  const { data: ledgerEntryData } = useGetRelatedLedgerEntryQuery(
    {
      groupId: "null",
      entityId: "null",
      transactionId: transactionId,
    },
    {
      skip: !ledger_entry_id,
    }
  );

  return (
    <div className="t-rounded-lg t-border-neutral-10 t-border t-border-solid">
      <div className="!t-border-b t-border-0 t-border-neutral-10 t-p-4 t-border-solid t-text-title-h2-bold">
        <AmountSuperScript amount={Number(amount)} />
      </div>
      <div className="t-p-4 t-flex t-gap-6 t-flex-col">
        <div className="t-flex t-justify-between t-gap-4">
          {Boolean(date) && (
            <InfoItem label="Date">
              <>{dayjs(date).format(DD_MMM_YYYY)}</>
            </InfoItem>
          )}
        </div>

        {transactionDetail?.transaction && (
          <InfoItem label="Vendor">
            <KeyDownWrapper>
              <MerchantSelectDelete
                outline
                transactions={transactionDetail}
                splitTransactionVendors={transactionDetail?.split_transactions}
                showSplitVendors={Boolean(splitTransactionVendors)}
                splitTransactionsMerchantCount={
                  transactionDetail?.split_transactions_merchant_count
                }
              />
            </KeyDownWrapper>
          </InfoItem>
        )}

        <InfoItem label="From">
          <div className="t-flex t-gap-1 t-items-center">
            {from?.bank_account?.bank_brand?.logo_url ? (
              <div>
                <Avatar
                  src={from?.bank_account?.bank_brand?.logo_url}
                  alt={from?.bank_account?.bank_brand?.name}
                />
              </div>
            ) : (
              <BankLogo />
            )}
            <div className="t-flex t-gap-1">
              {from?.bank_account && (
                <div>
                  {from?.bank_account?.nickname} ••••
                  {from?.bank_account?.mask}
                </div>
              )}
              {from?.source! == "LEDGER_ENTRY" ? (
                <span
                  className=" t-text-purple hover:t-cursor-pointer hover:t-text-purple-70 hover:t-underline"
                  onClick={openViewJournalEntryModal}
                >
                  <div>{TRANSACTION_SOURCE[from?.source!]}</div>
                </span>
              ) : (
                <div>({TRANSACTION_SOURCE[from?.source!]})</div>
              )}
            </div>
          </div>
        </InfoItem>
        <Category transactionId={transactionId} />
        {Boolean(isSplitTransaction) && (
          <SplitTransactionDetail transactionId={transactionId} />
        )}
        {Boolean(description) && (
          <InfoItem label="Description">{description}</InfoItem>
        )}

        {Boolean(entry_name) && (
          <InfoItem label="Journal Entry Title">
            #{entry_number} {entry_name}
          </InfoItem>
        )}
        {Boolean(linked_transaction) && (
          <InfoItem
            label={
              <div className="t-flex t-flex-row t-justify-between">
                <div>Linked to</div>
                <Button customType="link" size="small" onClick={openUnlink}>
                  Unlink
                </Button>
              </div>
            }
          >
            <Button
              customType="link"
              size="small"
              onClick={() => {
                dispatch(openSlider(linked_transaction));
              }}
            >
              Go to transaction
            </Button>
          </InfoItem>
        )}
      </div>
      {isUnlinkOpen && (
        <UnLinkTransaction
          isOpen={isUnlinkOpen}
          close={closeUnlink}
          transactionDetail={transactionDetail}
        />
      )}
      {isViewJournalEntryModalOpen && (
        <ViewJournalEntryModal
          isOpen={isViewJournalEntryModalOpen}
          close={closeViewJournalEntryModal}
          transactionId={transactionId!}
        />
      )}
    </div>
  );
};

const TransactionBody = ({
  sendToChat,
  transactionId,
}: {
  sendToChat?: (() => void) | null;
  transactionId: string;
}) => {
  const dispatch = useDispatch();
  const linkInkleInvoiceModal = useModal();
  const linkInkleInvoiceModalEdit = useModal();
  const { isLoading, isSuccess } = useGetTransaction({
    transactionId,
  });

  const { silderAccordionValue } = useSelector(
    (state: RootState) => state?.transactions
  );

  const { data: transaction } = useGetTransaction({
    transactionId,
  });

  const isCreditCard = transaction?.transaction?.is_credit_card;
  const isCredit =
    transaction?.transaction?.amount && transaction?.transaction.amount > 0;

  const hasLinkedInvoice = transaction?.invoices.some(
    ({ has_inkle_invoice }) => has_inkle_invoice
  );

  return (
    <Async.Root {...{ isEmpty: false, isLoading, isSuccess }}>
      <Async.Empty>
        <></>
      </Async.Empty>
      <Async.Success>
        <div className="t-flex t-flex-col">
          <Header transactionId={transactionId} />

          <div className="t-p-5 t-flex t-gap-4 t-flex-col t-pb-24">
            <Info transactionId={transactionId} />
            <Accordion.Root
              type="multiple"
              defaultValue={["COMMENT", "INVOICE_AND_MEMO"]}
              className="t-flex t-gap-4 t-flex-col"
            >
              <Accordion.Item value="INVOICE_AND_MEMO">
                <Accordion.Trigger>
                  <div className="t-flex t-gap-3">
                    <span>Invoice & Memo</span>
                    {transaction?.invoices.some(
                      (i) => i.file_data?.invoice_status === AUTO_MATCHED
                    ) && (
                      <Tag tagType="yellow" rounded icon={false}>
                        Review associated invoice(s)
                      </Tag>
                    )}
                  </div>
                </Accordion.Trigger>
                <Accordion.Content>
                  <span className="t-flex t-gap-4 t-flex-col">
                    <KeyDownWrapper>
                      <Invoices transactionId={transactionId} />
                    </KeyDownWrapper>
                    {isCredit && !isCreditCard && !hasLinkedInvoice && (
                      <div className="t-flex t-justify-between t-py-1 t-px-3 t-items-center t-border t-border-solid t-rounded t-border-purple-10 t-bg-surface-purple">
                        <div className="t-text-body-sm">
                          Link transaction to Inkle invoices
                        </div>
                        <Button
                          size="small"
                          onClick={linkInkleInvoiceModal.open}
                        >
                          Link
                        </Button>
                      </div>
                    )}
                    <Divider />
                    {transaction?.linked_inkle_invoices &&
                      transaction?.linked_inkle_invoices?.invoices?.length >
                        0 &&
                      hasLinkedInvoice && (
                        <>
                          <KeyDownWrapper>
                            <InfoItem
                              label={
                                <div className="t-flex t-justify-between t-items-center">
                                  <span>Linked Inkle Invoice</span>
                                  <Button
                                    customType="icon"
                                    size="small"
                                    onClick={linkInkleInvoiceModalEdit.open}
                                  >
                                    <Pencil color="#706A85" size="16" />
                                  </Button>
                                </div>
                              }
                            >
                              <LinkedInvoices
                                transactionId={transactionId}
                                invoices={
                                  transaction?.linked_inkle_invoices.invoices
                                }
                              />
                            </InfoItem>
                          </KeyDownWrapper>
                          <Divider />
                        </>
                      )}

                    <KeyDownWrapper>
                      <Memo transactionId={transactionId} />
                    </KeyDownWrapper>
                  </span>
                </Accordion.Content>
              </Accordion.Item>
              <Comments transactionId={transactionId} />
            </Accordion.Root>
          </div>
          {Boolean(sendToChat) && (
            <div className="t-p-4 t-bg-surface t-fixed t-bottom-0 t-shadow-tasks-slider-bottom t-w-[480px]">
              <Button customType="primary" onClick={() => sendToChat?.()} block>
                Send to Chat
              </Button>
            </div>
          )}
          {linkInkleInvoiceModal.isOpen && (
            <LinkInkleInvoiceModal
              transactionId={transactionId}
              transactionAmount={transaction?.transaction.amount!}
              isOpen={linkInkleInvoiceModal.isOpen}
              close={linkInkleInvoiceModal.close}
            />
          )}
          {linkInkleInvoiceModalEdit.isOpen && (
            <LinkInkleInvoiceModal
              isEditModal={true}
              transactionId={transactionId}
              transactionAmount={transaction?.transaction.amount!}
              isOpen={linkInkleInvoiceModalEdit.isOpen}
              close={linkInkleInvoiceModalEdit.close}
            />
          )}
        </div>
      </Async.Success>
    </Async.Root>
  );
};

export const TransactionSlider = ({
  sendToChat,
}: {
  sendToChat?: ({ transactionId }: { transactionId: string }) => void;
}) => {
  const dispatch = useAppDispatch();

  const { sliderTransactions } = useSelector(
    (state: RootState) => state.transactions
  );
  const { update } = useUpdateQuery();

  const onOpenChange = (transactionId: string) => {
    dispatch(closeSlider(transactionId));
    update({
      query: "transaction_id",
      value: null,
    });
  };

  return (
    <>
      {sliderTransactions.map(({ transactionId, animationId }) => (
        <Slider.Root
          key={animationId}
          open={Boolean(transactionId)}
          onOpenChange={() => onOpenChange(transactionId)}
        >
          <Slider.Content open={Boolean(transactionId)} useCustomOverlay>
            <TransactionBody
              sendToChat={
                sendToChat ? () => sendToChat?.({ transactionId }) : null
              }
              transactionId={transactionId}
            />
          </Slider.Content>
        </Slider.Root>
      ))}
    </>
  );
};
