import classNames from "classnames";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { RequestInfo } from "components/Transaction/RequestInfo";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { LoadingToast } from "components/design/LoadingToast";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { CaretDown } from "components/icons/CaretDown";
import { FileArrowDown } from "components/icons/FileArrowDown";
import { MagnifyingGlass } from "components/icons/MagnifyingGlass";
import { Sync } from "components/icons/Sync";
import { VendorDefault } from "components/icons/VendorDefault";
import { AnimatePresence, motion } from "framer-motion";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { parse, stringify } from "qs";
import React, {
  ChangeEvent,
  Fragment,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDrag } from "react-dnd";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import {
  Row,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useLazyExportTxnQuery } from "store/apis/books";
import { useAddOpenItemsMutation } from "store/apis/openItem";
import {
  useGetAllTransactionsDataQuery,
  useRefreshTransactionsMutation,
} from "store/apis/transactions";
import { getFilterStatus } from "store/selector/transactionFilter";
import {
  setMessageIdForTransactionList,
  setPopulateTransactionIDs,
} from "store/slices/chat";
import { setFiltersAplied } from "store/slices/transactionCardPreview";
import { resetTxnFilters, setTxnFilters } from "store/slices/transactionFilter";
import {
  openSlider,
  setSelectedTransactionIds,
} from "store/slices/transactions";
import { setTransactionsInChat } from "store/slices/transactionsInChat";
import { RootState } from "store/store";
import type {
  Transaction,
  Transactions as TransactionsType,
  TxnAccountType,
} from "types/Models/books";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import { EmptyScreen } from "../../pages/Books/EmptyScreen";
import { AddTransactionOptions } from "./AddTransaction/AddTransactionOptions";
import { AutoAssignModal } from "./AutoAssignModal";
import { FetchReceiptsModal } from "./FetchReceiptsModal";
import { RuleConfirmation } from "./RuleConfirmation";
import { TransactionSlider } from "./Slider/TransactionSlider";
import { SplitTransaction } from "./SplitTransaction/SplitTransaction";
import { TableSelectionControls } from "./TableSelectionControls";
import { useTransactionsFilter } from "hooks/useTransactionsFilter";
import {
  TransactionTableColumns,
  transactionColumn,
  TransactionTableHandler,
} from "./TransactionsTable";
import {
  FIXED_TRANSACTION_COLUMNS,
  TRANSACTION_COLUMNS,
} from "constants/transaction";
import { useFilters } from "hooks/useFilter";
import { getCommonPinningStyles } from "utils/getCommonPinningStyles";

const AnimateRow = ({
  row,
  onAnimateComplete,
}: {
  row: Row<TransactionsType>;
  onAnimateComplete(): void;
}) => {
  const content = useRef<null | HTMLTableRowElement>(null);
  const [refAvailable, setRefAvailable] = useState(false);

  return (
    <motion.tr
      className={classNames(
        "t-cursor-pointer t-absolute t-overflow-hidden t-rounded-md t-z-[9999] t-w-14 t-h-14"
      )}
      ref={(ref) => {
        content.current = ref;
        setRefAvailable(true);
      }}
      animate={
        refAvailable
          ? {
              opacity: [null, 0],
              x: [
                null,
                document.body.getBoundingClientRect().right -
                  200 -
                  (content.current?.getBoundingClientRect()?.right ?? 0),
              ],
              y: [
                null,
                document.body.getBoundingClientRect().bottom -
                  32 -
                  (content.current?.getBoundingClientRect()?.bottom || 0),
              ],
            }
          : {}
      }
      transition={{
        type: "tween",
        duration: 0.6,
        ease: "easeInOut",
      }}
      onAnimationComplete={() => {
        onAnimateComplete();
      }}
    >
      {row.getVisibleCells().map((cell: any) => {
        if (!cell.id.includes("merchant")) {
          return null;
        }

        return (
          <td
            key={cell.id}
            style={{
              width: "100px",
              opacity: cell?.id?.includes("merchant") ? 1 : 0,
            }}
          >
            <div className="t-flex t-gap-2 t-items-center t-border t-border-solid t-border-neutral-30 t-w-7 t-h-7 t-justify-center t-rounded-full">
              {row.original.transaction.merchant ? (
                <Avatar
                  src={row.original.transaction.logo || ""}
                  alt={row.original.transaction.merchant}
                />
              ) : (
                <VendorDefault />
              )}
            </div>
          </td>
        );
      })}
    </motion.tr>
  );
};

const DraggableRow = (
  props: React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLTableRowElement>,
    HTMLTableRowElement
  > & { row: Row<TransactionsType> }
) => {
  const query = useQuery();
  const selectedTransactionId = query.get("selected_transaction_id");
  const { sliderTransactions } = useSelector(
    (state: RootState) => state?.transactions
  );

  const { transaction } = props.row.original;

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: "transaction",
      item: { transaction: JSON.stringify(transaction) },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [transaction.uuid]
  );

  const ref = selectedTransactionId
    ? (ref: HTMLTableRowElement) => {
        if (transaction.uuid === selectedTransactionId) {
          ref?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }
    : drag;

  return (
    <tr
      ref={ref}
      {...props}
      className={classNames(
        "hover:t-bg-surface-lighter-grey t-cursor-pointer t-border-neutral-0 t-border-b t-border-0 t-text-body t-px-3 t-border-solid",
        {
          "t-bg-surface-lighter-grey":
            sliderTransactions.find(
              ({ transactionId }) => transactionId === transaction?.uuid
            ) || isDragging,
          "animate-transaction-row": transaction.uuid === selectedTransactionId,
          [props.className || ""]: props.className,
        }
      )}
    />
  );
};

export const Transactions = ({
  setChannelId,
  txnAccountType,
}: {
  setChannelId?(v: string): void;
  txnAccountType?: TxnAccountType;
}) => {
  usePageTitle("Transactions");
  const [animateTransactions, setAnimateTransactions] = useState<string[]>([]);
  const [sorting, setSorting] = useState<
    {
      desc: boolean;
      id: "AMOUNT" | "DATE" | "VENDOR";
    }[]
  >([
    {
      id: TRANSACTION_COLUMNS.DATE,
      desc: true,
    },
  ]);
  const { alertToast, successToast } = useToast();
  const { populateTransactionIDsList, messageIdForTransactionList } =
    useSelector((state: RootState) => state.chat);
  const { update } = useUpdateQuery();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const searchTerm = query.get("search_term");
  const page = query.get("page");
  const pageSize = query.get("page_size");
  const selectedTransactionId = query.get("selected_transaction_id");
  const resetFilter = query.get("reset_filter");
  const transactionId = query.get("transaction_id");
  const [rowSelection, setRowSelection] = useState({});
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const history = useHistory();
  const location = useLocation();
  const [exportTxn, { isLoading: isExporting }] = useLazyExportTxnQuery();
  const { appliedFilterCount } = useSelector(getFilterStatus);
  const isFilterApplied = appliedFilterCount > 0;
  const { isAdmin, isCpa } = useRoleBasedView();
  const [refreshTransactions, { isLoading: isRefreshing }] =
    useRefreshTransactionsMutation();

  const { isOpen } = useSelector(
    (state: RootState) => state.similarTransactions
  );
  const { showSplitTransactionModal } = useSelector(
    (state: RootState) => state.splitTransaction
  );

  const [duplicatingTransction, setDuplicatingTransaction] = useState<
    Transaction | undefined
  >();

  useEffect(() => {
    if (transactionId) {
      dispatch(openSlider(transactionId));
    }
  }, [transactionId]);

  const {
    pageNum,
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
    setTotalPage,
  } = usePagination({
    pageNumber: Number(page),
    onPageNumChange: (currentPageNumber) => {
      update({ query: "page", value: currentPageNumber });
    },
  });

  const sortCol = sorting[0]?.id;
  const sortOrder = sorting[0]?.desc;

  useEffect(() => {
    let currentSearch = parse(location.search, { ignoreQueryPrefix: true });
    if (currentSearch.reset_filter) {
      delete currentSearch.reset_filter;
    }

    if (Boolean(resetFilter)) {
      dispatch(setPopulateTransactionIDs(""));
      dispatch(setMessageIdForTransactionList(""));
      dispatch(resetTxnFilters());
    }

    let timeout: NodeJS.Timeout;
    if (selectedTransactionId || Boolean(resetFilter)) {
      if (currentSearch.selected_transaction_id) {
        delete currentSearch.selected_transaction_id;
      }

      timeout = setTimeout(() => {
        history.replace(
          `${location.pathname}${stringify(currentSearch, {
            addQueryPrefix: true,
          })}`
        );
      }, 5000);
    }

    return () => clearTimeout(timeout);
  }, [resetFilter, selectedTransactionId, page]);

  const allFilters = useTransactionsFilter({
    txnAccountType,
    pageSize,
    searchTerm,
    sortCol: sortCol,
    sortedToDec: sortOrder,
    pageNum,
    transactionIds: populateTransactionIDsList,
    messageId: messageIdForTransactionList,
  });

  useEffect(() => {
    table.toggleAllRowsSelected(false);
  }, [searchTerm]);

  useEffect(() => {
    dispatch(setFiltersAplied(allFilters));
  }, [allFilters]);

  const {
    data: transactionData,
    isLoading,
    isSuccess,
    isFetching,
  } = useGetAllTransactionsDataQuery(allFilters, {
    skip: !groupId || !entityId,
    refetchOnMountOrArgChange: true,
  });

  const onExport = async () => {
    try {
      const { download_url } = await exportTxn(allFilters).unwrap();
      successToast({ message: "Transaction export successful" });
      window.open(download_url);
    } catch (e) {
      alertToast({ message: (e as BackendError).data?.error?.message });
    }
  };

  const loading = Boolean(populateTransactionIDsList) ? isFetching : isLoading;

  const [
    txnRequestInfo,
    {
      data: requestedTxnInfo,
      isSuccess: isRequested,
      reset,
      isLoading: isRequesting,
    },
  ] = useAddOpenItemsMutation();

  const {
    transactions = [],
    channel_url = "",
    current_page = 1,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = transactionData || {};

  const isEmpty = transactions.length === 0;

  const paginationData = {
    totalPage: total_pages,
    currentPage: current_page,
    itemsPerPage: per_page,
    totalItemCount: total_count,
  };

  useEffect(() => {
    setTotalPage(total_pages);
  }, [total_pages]);

  useEffect(() => {
    if (channel_url) {
      setChannelId?.(channel_url);
    }
  }, [channel_url]);

  const handleChange = debounce((e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search_term", value: value || null });
    dispatch(setTxnFilters({ search_term: value }));
  });

  const onRowClick = (
    transactionId: string,
    e: Event | React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => {
    e.stopPropagation();
    dispatch(openSlider(transactionId));
  };

  const onAnimateComplete = () => {
    const transactionsToSendtoChat = transactions
      .filter((txn) => animateTransactions.includes(txn.transaction.uuid))
      .map((txn) => ({
        id: txn.transaction.uuid,
        amount: txn.transaction.amount,
        source: txn.transaction.source,
        date: txn.transaction.date,
        vendor: {
          logo: txn.transaction.logo,
          name: txn.transaction.merchant,
        },
        isCreditCard: txn.transaction.is_credit_card,
      }));

    dispatch(
      setTransactionsInChat({
        transactionsInChat: transactionsToSendtoChat,
        entityId: entityId,
      })
    );
    setAnimateTransactions([]);
  };

  const sendToChat = async ({
    transactionIds,
  }: {
    transactionIds: string[];
  }) => {
    setAnimateTransactions(transactionIds);
  };

  const onRequestInfo = async ({
    transactionIds,
  }: {
    transactionIds: string;
  }) => {
    try {
      await txnRequestInfo({
        groupId,
        transactionIds,
      }).unwrap();
      deselectRows();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const transactionsTableData = useMemo(
    () => transactionData?.transactions || [],
    [isFetching]
  );

  const onDuplicate = (transaction: Transaction) => {
    addTransactionModal.open();
    setDuplicatingTransaction(transaction);
  };

  const closeAddTransaction = () => {
    addTransactionModal.close();
    setDuplicatingTransaction(undefined);
  };

  const { values, updateFilter } = useFilters<TransactionTableColumns>({
    initialValue: {
      COLUMNS: [
        ...FIXED_TRANSACTION_COLUMNS,
        TRANSACTION_COLUMNS.FROM,
        TRANSACTION_COLUMNS.VENDOR,
      ],
    },
  });

  const columns = useMemo(
    () => [
      transactionColumn.select(),
      transactionColumn.date(),
      transactionColumn.from(),
      transactionColumn.merchant(),
      transactionColumn.description(),
      transactionColumn.amount(),
      transactionColumn.category(),
      transactionColumn.invoice({
        onRowClick,
      }),
      transactionColumn.comments({
        onRowClick,
      }),
      transactionColumn.options({
        onDuplicate,
        onRequestInfo,
        sendToChat,
        onRowClick,
      }),
    ],
    []
  );

  const getIsShown = (column: keyof typeof TRANSACTION_COLUMNS) => {
    return {
      [column]: values.COLUMNS.includes(column),
    };
  };

  const table = useReactTable({
    data: transactionsTableData || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    manualSorting: true,
    state: {
      columnVisibility: {
        ...getIsShown(TRANSACTION_COLUMNS.SELECT),
        ...getIsShown(TRANSACTION_COLUMNS.DATE),
        ...getIsShown(TRANSACTION_COLUMNS.FROM),
        ...getIsShown(TRANSACTION_COLUMNS.VENDOR),
        ...getIsShown(TRANSACTION_COLUMNS.DESCRIPTION),
        ...getIsShown(TRANSACTION_COLUMNS.AMOUNT),
        ...getIsShown(TRANSACTION_COLUMNS.CATEGORY),
        ...getIsShown(TRANSACTION_COLUMNS.INVOICE),
        ...getIsShown(TRANSACTION_COLUMNS.COMMENTS),
        ...getIsShown(TRANSACTION_COLUMNS.OPTIONS),
      },
      rowSelection,
      sorting,
      columnPinning: {
        right: [
          TRANSACTION_COLUMNS.INVOICE,
          TRANSACTION_COLUMNS.COMMENTS,
          TRANSACTION_COLUMNS.OPTIONS,
        ],
      },
    },
    // @ts-ignore
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    enableMultiSort: false,
    enableSortingRemoval: false,
  });

  const deselectRows = () => {
    table.resetRowSelection();
    dispatch(setSelectedTransactionIds([]));
  };

  const addTransactionModal = useModal();
  const openConfirmation = useModal();
  const fetchReceiptsModal = useModal();

  const refreshTransactionsData = async () => {
    try {
      await refreshTransactions({ groupId, entityId }).unwrap();
      successToast({ message: "Transactions refreshed" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const isRowsSelected = table?.getSelectedRowModel?.()?.flatRows.length > 0;

  const unSelectedRowsIds = transactionsTableData
    .filter(
      (txn) =>
        !table
          .getSelectedRowModel()
          .flatRows.find(
            (selectedTxn) =>
              selectedTxn.original.transaction.uuid === txn.transaction.uuid
          )
    )
    .map(({ transaction }) => transaction.uuid);

  const selectionCount = table.getSelectedRowModel().flatRows.length;

  return (
    <>
      <DashboardContainer className="t-gap-4 t-pl-6">
        <DashboardContainer.Header sticky>
          <div className="t-bg-surface t-flex t-flex-col t-gap-4 t-pt-5">
            <div className="t-flex t-gap-4 t-w-full t-items-center">
              <div className="t-w-1/2">
                <Search block onChange={handleChange} placeholder="Search" />
              </div>
              <div className="t-flex t-gap-2 t-ml-auto">
                <Dropdown.Root>
                  <Dropdown.Trigger asChild>
                    <Button size="small">
                      <span className="t-flex t-gap-2 t-items-center">
                        {isRefreshing ? "Refreshing" : "Refresh"}
                        {isRefreshing ? (
                          <motion.span
                            className={classNames("t-flex t-items-center", {
                              "t-animate-spin": isRefreshing,
                            })}
                          >
                            <Sync />
                          </motion.span>
                        ) : (
                          <CaretDown />
                        )}
                      </span>
                    </Button>
                  </Dropdown.Trigger>
                  <Dropdown.Portal>
                    <Dropdown.Content sideOffset={4} align="end">
                      <Dropdown.Item
                        onSelect={refreshTransactionsData}
                        className=" t-flex t-gap-2 t-items-center"
                      >
                        <span className=" t-text-neutral">
                          <Sync size="15" />
                        </span>
                        <span>New transactions</span>
                      </Dropdown.Item>

                      <Dropdown.Item
                        onSelect={fetchReceiptsModal.open}
                        className=" t-flex t-gap-2 t-items-center"
                      >
                        <span className=" t-text-neutral">
                          <FileArrowDown />
                        </span>

                        <span>Invoices and Memos</span>
                      </Dropdown.Item>
                    </Dropdown.Content>
                  </Dropdown.Portal>
                </Dropdown.Root>
                {Boolean(total_count) && (
                  <ConditionalToolTip
                    condition={
                      isFilterApplied
                        ? `Export ${total_count} filtered transactions as a .CSV file`
                        : `Export ${total_count} transactions as a .CSV file`
                    }
                  >
                    <span>
                      <Button
                        onClick={onExport}
                        isLoading={isExporting}
                        disabled={isExporting}
                        customType="secondary"
                        size="small"
                      >
                        Export
                      </Button>
                    </span>
                  </ConditionalToolTip>
                )}

                <Button
                  customType="primary"
                  size="small"
                  onClick={addTransactionModal.open}
                >
                  Add transaction
                </Button>
              </div>
            </div>
            <div className="t-flex t-gap-1">
              <AnimatePresence>
                <TableSelectionControls
                  unSelectedRowsIds={unSelectedRowsIds}
                  txnAccountType={txnAccountType}
                  isAllTxnSelected={false}
                  table={table}
                  sendToChat={sendToChat}
                  onRequestInfo={onRequestInfo}
                  deselectRows={deselectRows}
                  selectionCount={selectionCount}
                />
              </AnimatePresence>

              {isSuccess && (
                <div className="t-ml-auto t-flex-shrink-0">
                  <Pagination
                    {...paginationData}
                    goToFirstPage={goToFirstPage}
                    goToPrevPage={goToPrevPage}
                    goToNextPage={goToNextPage}
                    goToLastPage={goToLastPage}
                    showOptions={isAdmin || isCpa}
                  />
                </div>
              )}
            </div>
          </div>
        </DashboardContainer.Header>
        <DashboardContainer.Content>
          <Async.Root {...{ isLoading: loading, isEmpty, isSuccess }}>
            <Async.Empty>
              <EmptyScreen text="No transactions found">
                <span className="t-text-i-neutral-10">
                  <MagnifyingGlass size="149" />
                </span>
              </EmptyScreen>
            </Async.Empty>
            <Async.Success>
              <div className="t-relative">
                <div className="t-absolute t-top-0 t-right-0 t-z-table-head t-p-2">
                  <TransactionTableHandler
                    updateFilter={updateFilter}
                    values={values}
                  />
                </div>
                <Table.Container className="t-overflow-x-auto scrollbar-visible t-h-full t-w-full t-pb-6">
                  <Table.Content>
                    <Table.Head className="!t-border-none">
                      {table.getHeaderGroups().map((headerGroup) => {
                        return (
                          <Table.HeadRow
                            key={headerGroup.id}
                            className="!t-border-none"
                          >
                            {headerGroup.headers.map((header) => {
                              const styles = getCommonPinningStyles(
                                header.column
                              );

                              return (
                                <Table.HeadCell
                                  className={classNames(
                                    "t-text-subtext-sm t-uppercase t-px-2 t-py-4 t-group t-border-0 t-border-solid t-border-neutral-30 t-border-b",
                                    {
                                      "!t-border-none":
                                        header.id ===
                                        TRANSACTION_COLUMNS.SELECT,
                                    }
                                  )}
                                  onClick={header.column.getToggleSortingHandler()}
                                  key={header.id}
                                  style={{
                                    minWidth: header.getSize(),
                                    ...styles,
                                  }}
                                  {...(header.column.getCanSort()
                                    ? { role: "button" }
                                    : {})}
                                >
                                  <span className="t-flex t-gap-1 t-items-center">
                                    {header.isPlaceholder
                                      ? null
                                      : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                    {header.column.getCanSort() && (
                                      <Table.HeadCellSort
                                        nextSortOrder={header.column.getNextSortingOrder()}
                                        isSorted={header.column.getIsSorted()}
                                      />
                                    )}
                                  </span>
                                </Table.HeadCell>
                              );
                            })}
                          </Table.HeadRow>
                        );
                      })}
                    </Table.Head>
                    <Table.Body>
                      {table.getRowModel().rows.map((row) => {
                        return (
                          <Fragment key={row.id}>
                            <DraggableRow
                              row={row}
                              onClick={(e) =>
                                onRowClick(row.original.transaction.uuid, e)
                              }
                              className="t-group !t-border-none"
                            >
                              {row.getVisibleCells().map((cell) => {
                                const styles = getCommonPinningStyles(
                                  cell.column
                                );

                                return (
                                  <Table.Cell
                                    id={cell.id}
                                    key={cell.id}
                                    style={{
                                      ...styles,
                                      minWidth: cell.column.getSize(),
                                    }}
                                    className={classNames(
                                      "t-border-solid t-border-neutral-0 t-border-b t-border-0",
                                      {
                                        " group-hover:!t-visible !t-border-surface-transparent":
                                          cell.id ===
                                          `${row.index}_${TRANSACTION_COLUMNS.SELECT}`,
                                        "t-invisible":
                                          cell.id ===
                                            `${row.index}_${TRANSACTION_COLUMNS.SELECT}` &&
                                          !isRowsSelected,
                                        "!t-visible": isRowsSelected,
                                      }
                                    )}
                                  >
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </Table.Cell>
                                );
                              })}
                            </DraggableRow>
                            {animateTransactions.includes(
                              row.original.transaction.uuid
                            ) && (
                              <AnimateRow
                                onAnimateComplete={onAnimateComplete}
                                row={row}
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </Table.Body>
                  </Table.Content>
                </Table.Container>
              </div>

              {isOpen && (
                <AutoAssignModal openConfirmation={openConfirmation.open} />
              )}
              <RuleConfirmation
                isOpen={openConfirmation.isOpen}
                close={openConfirmation.close}
              />
            </Async.Success>
          </Async.Root>
        </DashboardContainer.Content>
      </DashboardContainer>

      {fetchReceiptsModal.isOpen && (
        <FetchReceiptsModal
          isOpen={fetchReceiptsModal.isOpen}
          onClose={fetchReceiptsModal.close}
        />
      )}
      {isRequested && (
        <RequestInfo
          isOpen={isRequested}
          onClose={reset}
          csvId={requestedTxnInfo?.csv_id || ""}
          channelUrl={channel_url}
        />
      )}
      <LoadingToast loading={isRequesting} title="Please wait">
        Requesting...
      </LoadingToast>
      <TransactionSlider
        sendToChat={({ transactionId }) =>
          sendToChat({
            transactionIds: [transactionId],
          })
        }
      />
      <AddTransactionOptions
        defaultTransaction={duplicatingTransction}
        close={closeAddTransaction}
        isOpen={addTransactionModal.isOpen}
        openAddTransactionModal={addTransactionModal.open}
      />
      {showSplitTransactionModal && (
        <SplitTransaction isOpen={showSplitTransactionModal} />
      )}
    </>
  );
};
