import { colors } from "./colors";

export const CATEGORISATION_TYPE = {
  VERIFIED: "VERIFIED",
  ALL: "ALL",
} as const;

export const ACCOUNT_TYPE = {
  CASH: "CASH",
  CREDIT: "CREDIT",
  TOTAL: "TOTAL",
} as const;

export const VIEW_TYPE = {
  CURRENT: "CURRENT",
  TRENDS: "TRENDS",
} as const;

export const INSIGHT_TYPE = {
  REVENUE: "REVENUE",
  EXPENSE: "EXPENSE",
} as const;

export const GRID_LINE_COLOR = colors.neutral[10];
export const PROFIT_LOSS_LINE_COLOR = colors.neutral.DEFAULT;
export const REVENUE_BAR_COLOR = colors.purple[30];
export const REVENUE_BAR_CROSS_LINE_COLOR = colors.purple[40];
export const EXPENSE_BAR_COLOR = colors.neutral[30];
export const EXPENSE_BAR_CROSS_LINE_COLOR = colors.neutral[40];
export const DOT_SIZE = 8;
export const BAR_RADIUS = 4;
export const LINE_SPACING = 12;
export const BAR_GAP = 2;
