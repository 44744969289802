import { AddressFormFields } from "components/AddressFormFields/AddressFormFields";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { useFormikContext } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import {
  useCreateCustomerAddressMutation,
  useGetAllCustomerAddressesQuery,
  useUpdateCustomerAddressMutation,
} from "store/apis/invoices";
import { BackendError } from "types/utils/error";
import { initialCustomerValues } from "./InvoiceCustomerModal";
import Pencil from "components/icons/pencil";
import { MultiValue, SingleValue } from "react-select";
import { InvoiceCustomerAddressComponents } from "./InvoiceCustomerAddressComponent";

export const InvoiceCustomerAddress = ({
  isShipping,
}: {
  isShipping?: boolean;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { alertToast, successToast } = useToast();
  const { values, setFieldValue } = useFormikContext<
    typeof initialCustomerValues & {
      created_customer_id: string;
      shipping_same_as_billing: boolean;
      to_edit_address_id: string;
    }
  >();
  const addressType = isShipping ? "SHIPPING" : "BILLING";
  const addressId = isShipping
    ? values?.shipping_address?.uuid
    : values?.billing_address?.uuid;

  return (
    <div className="t-flex t-flex-col t-gap-3">
      <p className="t-m-0 t-text-subtitle">{isShipping ? "Ship" : "Bill"} to</p>
      <InvoiceCustomerAddressComponents
        formPrefix=""
        entityId={entityId}
        groupId={groupId}
        selectedAddressId={
          isShipping
            ? values?.shipping_address?.uuid
            : values?.billing_address?.uuid
        }
        label="Address"
        onAddressSelect={(address) => {
          setFieldValue(
            isShipping ? "shipping_address_id" : "billing_address_id",
            address?.uuid
          );
          setFieldValue(
            isShipping ? "shipping_address" : "billing_address",
            address
          );
        }}
        hideClear
        customerId={values?.created_customer_id}
        addressType={isShipping ? "SHIPPING" : "BILLING"}
        srollToSave
      />
    </div>
  );
};
