import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

// context
import { useRoleBasedView } from "hooks/useRoleBasedView";

export const ShareDocuments = () => {
  const { docId, groupId } = useParams();
  const { isAnyServiceUser, isForeignCA } = useRoleBasedView();

  useEffect(() => {
    if (isForeignCA) {
      window.location.href = `${process.env.PUBLIC_URL}practice/crm/${groupId}/documents/${docId}`;
      return;
    }

    if (isAnyServiceUser) {
      window.location.href = `${process.env.PUBLIC_URL}admin/crm/${groupId}/documents/${docId}`;
      return;
    }

    window.location.href = `${process.env.PUBLIC_URL}tax/documents/${docId}`;
    return;
  }, []);

  return <></>;
};
