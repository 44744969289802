import { ComponentType } from "react";
import { InkleTaxSymbol } from "components/icons/Logos/InkleTaxSymbol";
import { InkleBooksSymbol } from "components/icons/Logos/InkleBooksSymbol";
import { InkleCommunitySymbol } from "components/icons/Logos/InkleCommunitySymbol";
import { InkleIntragroupSymbol } from "components/icons/Logos/InkleIntragroupSymbol";
import { InkleMailroomSymbol } from "components/icons/Logos/InkleMailroomSymbol";
import { InkleSalesTaxSymbol } from "components/icons/Logos/InkleSalesTaxSymbol";
import { ConditionalLink } from "components/conditionalLink";
import { usePageTitle } from "hooks/usePageTitle";
import { InkleTaxCreditSymbol } from "components/icons/Logos/InkleTaxCreditSymbol";

export const AppTile = ({
  logo: Logo,
  link,
  name,
}: {
  logo: ComponentType;
  link: string;
  name: string;
}) => {
  return (
    <ConditionalLink
      to={link}
      className="t-text-text-100 hover:t-text-text-100 t-no-underline t-flex t-flex-col t-items-center t-gap-2"
    >
      <div className="t-w-32 t-h-32 t-flex t-items-center t-justify-center app-switcher-gradient t-rounded-lg t-shadow-[0px_1px_8px_-1px_#1F0C5C14] hover:t-shadow-[0px_1px_32px_4px_#1F0C5C14] t-transition-all">
        <Logo />
      </div>
      <span>{name}</span>
    </ConditionalLink>
  );
};

export const AppSwitcher = () => {
  usePageTitle("Apps");

  return (
    <div className="t-flex t-flex-col t-h-screen t-justify-center t-items-center t-gap-10">
      <p className="t-text-3xl  t-text-display-h3 t-m-0">
        Choose an app to go to
      </p>
      <div className="t-flex t-gap-10">
        <AppTile logo={InkleBooksSymbol} link="/books" name="Books" />
        <AppTile logo={InkleTaxSymbol} link="/tax" name="Tax" />
        <AppTile logo={InkleMailroomSymbol} link="/mailroom" name="Mailroom" />
      </div>
      <div className="t-flex t-gap-10 t-mt-10">
        <AppTile
          logo={InkleCommunitySymbol}
          link="/community"
          name="Community"
        />
        <AppTile
          logo={InkleSalesTaxSymbol}
          link="/sales-tax"
          name="Sales Tax"
        />
      </div>
    </div>
  );
};
