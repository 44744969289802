import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { SwitchCase } from "components/DesignSystem/SwitchCase/SwitchCase";
import { AICategorised } from "components/icons/AICategorised";
import { AICategorisedAccepted } from "components/icons/AICategorisedAccepted";
import { RuleCategorised } from "components/icons/RuleCategorised";
import { SolidRoundCheck } from "components/icons/SolidRoundCheck";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { MouseEvent } from "react";
import { useUpdateTransactionMutation } from "store/apis/transactions";
import { Transactions } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { CategorisationStatusInfo } from "./CategorisationStatusInfo";

type CategoryStatusProps = {
  groupId: string;
  entityId: string;
  transaction: Transactions;
};

const CategorisedIcon = {
  AI_CATEGORISED: AICategorised,
  AI_CATEGORISED_ACCEPTED: AICategorisedAccepted,
  MANUAL_CATEGORISED: SolidRoundCheck,
  RULE_CATEGORIZED: RuleCategorised,
  NOT_CATEGORISED: () => null,
};

export const CategoryStatus = ({
  entityId,
  groupId,
  transaction,
}: CategoryStatusProps) => {
  const [updateTransaction, { isLoading }] = useUpdateTransactionMutation();
  const { alertToast } = useToast();
  const categorisationStatusInfoModal = useModal();

  const { category, categorisation_status, uuid } = transaction.transaction;

  const isAICategorised = categorisation_status === "AI_CATEGORISED";
  const CategorisationStatusIcon = categorisation_status
    ? CategorisedIcon[categorisation_status]
    : CategorisedIcon.NOT_CATEGORISED;

  const onCategoryReview = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    try {
      await updateTransaction({
        groupId,
        entityId,
        previewTxnId: uuid,
        payload: {
          ai_category_confirmation: true,
          transaction_category_uuid: category?.uuid || null,
        },
      }).unwrap();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <>
      <ConditionalToolTip
        condition={
          <SwitchCase
            value={categorisation_status}
            cases={{
              AI_CATEGORISED: (
                <div className="t-items-start t-flex t-flex-col t-text-start">
                  Categorised by AI,
                  <br />
                  click to confirm
                  <br />
                  <br />
                  <button
                    className="all:unset t-text-body-sm t-text-white t-underline"
                    onClick={categorisationStatusInfoModal.open}
                  >
                    Tell me more
                  </button>
                </div>
              ),
              MANUAL_CATEGORISED: "Categorised Manually",
              RULE_CATEGORIZED: "Categorised by Rules",
              AI_CATEGORISED_ACCEPTED: (
                <div className="t-text-start">
                  Categorised by AI and
                  <br />
                  manually confirmed
                </div>
              ),
              NOT_CATEGORISED: "",
            }}
            default={null}
          />
        }
        side="right"
      >
        <span className="t-ml-1">
          <Button
            size="extra-small"
            customType="ghost_icon"
            onClick={(e) => {
              isAICategorised && onCategoryReview(e);
            }}
            disabled={isLoading || !isAICategorised}
            isLoading={isLoading}
          >
            <CategorisationStatusIcon />
          </Button>
        </span>
      </ConditionalToolTip>
      <CategorisationStatusInfo {...categorisationStatusInfoModal} />
    </>
  );
};
