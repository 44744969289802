export const DataSources = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_284_15354)">
      <path
        d="M2 14.8323H16V15.9989H2V14.8323ZM16 6.61252V7.83227H14.25V12.4989H15.4167V13.6656H2.58333V12.4989H3.75V7.83227H2V6.61252C1.99994 6.29813 2.08458 5.98953 2.24502 5.71915C2.40546 5.44878 2.63577 5.22662 2.91175 5.07602L8.16175 2.21244C8.41875 2.07178 8.70702 1.99805 9 1.99805C9.29298 1.99805 9.58125 2.07178 9.83825 2.21244L15.0883 5.07602C15.3642 5.22662 15.5945 5.44878 15.755 5.71915C15.9154 5.98953 16.0001 6.29813 16 6.61252ZM4.91667 12.4989H6.66667V7.83227H4.91667V12.4989ZM7.83333 7.83227V12.4989H10.1667V7.83227H7.83333ZM13.0833 7.83227H11.3333V12.4989H13.0833V7.83227ZM14.8333 6.61252C14.8334 6.50773 14.8051 6.40486 14.7517 6.31473C14.6982 6.22461 14.6214 6.15056 14.5294 6.10036L9.27942 3.23677C9.19371 3.19001 9.09764 3.1655 9 3.1655C8.90236 3.1655 8.80629 3.19001 8.72058 3.23677L3.47058 6.10036C3.37859 6.15056 3.30182 6.22461 3.24834 6.31473C3.19486 6.40486 3.16665 6.50773 3.16667 6.61252V6.66561H14.8333V6.61252Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_284_15354">
        <rect width="14" height="14" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);
