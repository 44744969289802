import classNames from "classnames";
import { DashboardLayout } from "components/DashboardLayout";
import { Divider } from "components/design/Divider";
import { InvoiceHeader } from "components/design/InvoiceHeader";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { FormikListener } from "components/FormikListener/FormikListener";
import { PreviewBody } from "components/PreviewModal";
import { BILL_STATE, BILL_STATUS_LABEL, TAG_TYPE } from "constants/vendorBills";
import { Form, Formik } from "formik";
import { vendorBillSchema } from "formValidations/vendorBillSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useToast } from "hooks/useToast";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";
import UploadFile from "static/images/UploadFile.svg";
import { useGetPreviewUrlQuery } from "store/apis/previewUrl";
import {
  UpdatePayload,
  useGetBillOrRequestQuery,
  useUpdateBillOrRequestMutation,
  useUploadBillMutation,
} from "store/apis/vendorBills";
import { BackendError } from "types/utils/error";
import {
  BillDetails,
  ItemDetails,
  PaymentDetails,
  TaxAndDiscount,
  VendorAddress,
  VendorDetails,
} from "./BillConsole";

type AddBillProps = {
  close: () => void;
};

export const AddBillPage = ({ close }: AddBillProps) => {
  const { billId } = useParams<{ billId: string }>();
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [uploadBill, uploadBillStates] = useUploadBillMutation();

  const onDrop = async (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      return;
    }

    try {
      await uploadBill({
        billId,
        entityId,
        groupId,
        bill_document: acceptedFiles[0],
      }).unwrap();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError)?.data?.error?.message,
        },
        error as Error
      );
    }
  };

  const onDropRejected = () => {
    alertToast({ message: "Please upload a PDF file exclusively." });
  };

  const { getRootProps, getInputProps, isDragAccept, isDragReject, open } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      onDrop,
      maxSize: 25000000,
      accept: {
        "application/pdf": [".pdf"],
      },
      onDropRejected,
      multiple: false,
    });

  const { data, isLoading, isSuccess, isError, error } =
    useGetBillOrRequestQuery(
      { groupId, entityId, billId },
      {
        skip: !billId || !entityId || !groupId,
      }
    );

  usePageTitle(data?.bill_number ? `Add ${data?.bill_number}` : "Add Bill");

  const fileurl = useGetPreviewUrlQuery(
    {
      fileId: data?.bill_document?.uuid,
      groupId,
    },
    {
      skip: !data?.bill_document?.uuid || !groupId,
    }
  );

  const [
    updateBillOrRequest,
    { isLoading: isUpdating, isSuccess: isUpdated, isError: isUpdateError },
  ] = useUpdateBillOrRequestMutation();

  const onChange = async (value: Partial<UpdatePayload>) => {
    try {
      await updateBillOrRequest({
        billId,
        entityId,
        groupId,
        payload: value,
      }).unwrap();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError)?.data?.error?.message,
        },
        error as Error
      );
    }
  };

  const [markActive, { isLoading: isMarkingActive }] =
    useUpdateBillOrRequestMutation();

  const onAddBill = async () => {
    try {
      await markActive({
        billId,
        entityId,
        groupId,
        payload: { mark_as_active: true },
      }).unwrap();
      close();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError)?.data?.error?.message,
        },
        error as Error
      );
    }
  };

  return (
    <Modal.Root open={true} modal={false}>
      <Async.Root
        isLoading={isLoading}
        isSuccess={isSuccess}
        isEmpty={false}
        isError={isError}
        customLoader={
          <Modal.Content size="fullscreen">
            <Loader />
          </Modal.Content>
        }
      >
        <Async.ErrorHandler>
          <Modal.Content size="fullscreen">
            <Modal.Body className="t-min-h-80 t-w-full t-h-full t-flex t-justify-center t-flex-col t-items-center t-gap-2 t-text-subtitle-sm t-text-red">
              <>
                {(error as BackendError)?.data?.error?.message ||
                  "Something went wrong, Please try again later."}
              </>
              <Button onClick={close} size="small">
                Go back
              </Button>
            </Modal.Body>
          </Modal.Content>
        </Async.ErrorHandler>
        <Async.Empty>
          <></>
        </Async.Empty>
        <Async.Success>
          {data && (
            <Modal.Content size="fullscreen" className="t-grid t-grid-cols-2">
              <Formik<UpdatePayload>
                initialValues={data}
                enableReinitialize={isUpdateError}
                onSubmit={onAddBill}
                validateOnChange
                validationSchema={vendorBillSchema}
              >
                {({ values, isValid, submitForm }) => (
                  <>
                    <Form className="t-overflow-auto">
                      <FormikListener
                        debounce
                        values={values}
                        callback={onChange}
                      />
                      <DashboardLayout
                        className=" t-pt-6"
                        header={
                          <InvoiceHeader
                            onBackClick={close}
                            status={
                              <Tag
                                rounded
                                icon={false}
                                tagType={TAG_TYPE[data?.bill_payment_status]}
                              >
                                {BILL_STATUS_LABEL[data?.bill_payment_status]}
                              </Tag>
                            }
                            title={data?.bill_number}
                          />
                        }
                      >
                        <div className="t-flex t-flex-col t-gap-12 t-pb-10">
                          <VendorDetails bill={values} onChange={onChange} />
                          <VendorAddress bill={values} onChange={onChange} />
                          <BillDetails bill={values} />
                          <ItemDetails bill={values} />
                          <TaxAndDiscount bill={values} />
                          <PaymentDetails bill={values} paymentlink />
                        </div>
                      </DashboardLayout>
                    </Form>
                    <DashboardLayout
                      className="t-pt-6 t-bg-surface-lighter-grey"
                      header={
                        <div className="t-flex t-gap-2 t-justify-end !t-bg-surface-lighter-grey">
                          <Async.Root
                            isLoading={isUpdating || uploadBillStates.isLoading}
                            customLoader={
                              <div className="t-flex t-items-center">
                                <Loader customType="secondary" />
                              </div>
                            }
                            isEmpty={false}
                            isSuccess={isUpdated || uploadBillStates.isSuccess}
                          >
                            <Async.Empty>
                              <></>
                            </Async.Empty>
                            <Async.Success>
                              <span className="t-flex t-items-center t-gap-2 t-transition-all t-text-text-30 t-text-body-sm">
                                <img src={DoubleGreenTick} alt="Saved" />
                                Changes saved
                              </span>
                            </Async.Success>
                          </Async.Root>
                          {data.state === BILL_STATE.INACTIVE && (
                            <Button
                              size="small"
                              type="submit"
                              customType="primary"
                              onClick={submitForm}
                              isLoading={isMarkingActive}
                              disabled={isMarkingActive || !isValid}
                            >
                              Add bill
                            </Button>
                          )}
                        </div>
                      }
                    >
                      <div className="t-flex t-flex-col t-gap-4 t-h-full">
                        <h4 className="t-text-subtitle t-text-text-100 t-m-0">
                          Bill Attachment
                        </h4>
                        <Divider />
                        <Async.Root
                          isLoading={fileurl.isLoading}
                          isSuccess={fileurl.isSuccess || isSuccess}
                          isEmpty={!fileurl.data || !data.bill_document}
                        >
                          <Async.Empty>
                            <button
                              type="button"
                              className={classNames(
                                "all:unset t-flex t-flex-col t-items-center t-justify-center t-bg-white t-rounded-md t-shadow-light-30 t-h-full t-border-dashed t-border-purple-30 t-border t-text-center t-text-text-30 t-text-body",
                                {
                                  "!t-border-green": isDragAccept,
                                  "!t-border-red-50": isDragReject,
                                }
                              )}
                              {...getRootProps()}
                              onClick={open}
                            >
                              <input {...getInputProps()} id="csv" />
                              <img src={UploadFile} alt="UploadFile" />
                              <>
                                Click or drag and drop to upload the in .pdf
                                format
                              </>
                            </button>
                          </Async.Empty>
                          <Async.Success>
                            {data.bill_document &&
                              fileurl.data?.preview_url && (
                                <PreviewBody
                                  file={data.bill_document}
                                  preview_url={fileurl.data?.preview_url}
                                  fullWidth
                                />
                              )}
                          </Async.Success>
                        </Async.Root>
                      </div>
                    </DashboardLayout>
                  </>
                )}
              </Formik>
            </Modal.Content>
          )}
        </Async.Success>
      </Async.Root>
    </Modal.Root>
  );
};
