import "chartjs-adapter-dayjs-4/dist/chartjs-adapter-dayjs-4.esm";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import Card from "components/DesignSystem/Card/Card";
import { Chip } from "components/DesignSystem/Chips/Chips";
import { Filter } from "components/DesignSystem/Filter/Filter";
import Tab from "components/DesignSystem/Tab/Tab";
import { EmptyMetrics } from "components/EmptyMetrics/EmptyMetrics";
import { DateFilter } from "components/Filters/DateFilter";
import { EmptyCategory } from "components/Illustrations/EmptyCategory";
import { EmptyVendor } from "components/Illustrations/EmptyVendor";
import { CATEGORISATION_TYPE, INSIGHT_TYPE } from "constants/booksHomePage";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import * as DATE_PERIOD from "constants/dateFilter";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { Insight, useGetInsightsQuery } from "store/apis/metrics";
import { BackendError } from "types/utils/error";
import { formatDate } from "utils/formatDate";
import { getDateRange } from "utils/getDateRange";
import { TopFiveCategoriesChart } from "./TopFiveCategoriesChart";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { Header } from "components/DesignSystem/Header/Header";
import { TopTransactions } from "./TopTransactions";
import { HomePageCardHeader, HomePageCardTitle } from "../HomePageCardHeader";
import { HideableAmount } from "../HideBalancesAndAmounts";

type TopInsightsFilterType = {
  INSIGHT_TYPE: keyof typeof INSIGHT_TYPE;
  START_DATE: string;
  END_DATE: string;
  SELECT_PERIOD: string;
};

export type TopInsightsProps = {
  isInsightsSuccess: boolean;
  isInsightsLoading: boolean;
  isInsightsFetching: boolean;
};

const TopFiveVendors = ({
  top_five_merchants,
  isInsightsSuccess,
  isInsightsLoading,
  isInsightsFetching,
}: {
  top_five_merchants: Insight["merchants"];
} & TopInsightsProps) => {
  return (
    <Async.Root
      {...{
        isLoading: isInsightsLoading,
        isEmpty: top_five_merchants.length === 0,
        isSuccess: isInsightsSuccess,
      }}
      customLoader={
        <div className="t-h-40 t-flex t-justify-center t-items-center">
          <Loader customType="secondary" />
        </div>
      }
    >
      <Async.Empty>
        <EmptyMetrics
          illustration={<EmptyVendor />}
          title="No vendors found"
          subtitle="Add transactions to see top vendors"
        />
      </Async.Empty>
      <Async.Success>
        <div className="t-grid t-grid-cols-1 t-gap-5">
          {top_five_merchants.map(({ amount, logo, merchant_name }, i) => (
            <div
              key={i}
              className="t-grid t-grid-cols-2 t-justify-between t-text-text-60 t-text-body t-gap-2 t-items-center"
            >
              <div className="t-flex t-gap-4 t-items-center">
                {isInsightsFetching ? (
                  <>
                    <div className="t-h-8 t-w-8 t-animate-pulse t-bg-neutral-20 t-rounded-full t-py-2" />
                    <div className="t-h-6 t-animate-pulse t-bg-neutral-20 t-rounded-md t-py-2 t-w-4/5" />
                  </>
                ) : (
                  <>
                    <Avatar alt={merchant_name} src={logo!} />
                    {merchant_name}
                  </>
                )}
              </div>
              <div className="t-text-end t-justify-self-end">
                {isInsightsFetching ? (
                  <div className="t-h-6 t-animate-pulse t-bg-neutral-20 t-rounded-md t-py-2 t-w-28" />
                ) : (
                  <HideableAmount>
                    <AmountSuperScript amount={amount} />
                  </HideableAmount>
                )}
              </div>
            </div>
          ))}
        </div>
      </Async.Success>
    </Async.Root>
  );
};

const TopFiveCategories = ({
  top_five_categories,
  isInsightsSuccess,
  isInsightsLoading,
  isInsightsFetching,
}: {
  top_five_categories: Insight["categories"];
} & TopInsightsProps) => {
  return (
    <Async.Root
      {...{
        isLoading: isInsightsFetching,
        isEmpty: top_five_categories.length === 0,
        isSuccess: isInsightsSuccess,
      }}
      customLoader={
        <div className="t-h-40 t-flex t-justify-center t-items-center">
          <Loader customType="secondary" />
        </div>
      }
    >
      <Async.Empty>
        <EmptyMetrics
          illustration={<EmptyCategory />}
          title="No transactions categorised"
          subtitle="Add/categorise transactions to see top categories"
        />
      </Async.Empty>
      <Async.Success>
        <TopFiveCategoriesChart topFiveCategories={top_five_categories} />
      </Async.Success>
    </Async.Root>
  );
};

export const BusinessInsights = ({
  categorisationType,
}: {
  categorisationType: keyof typeof CATEGORISATION_TYPE;
}) => {
  const defaultDateRange = getDateRange(DATE_PERIOD.CURRENT_YEAR);
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { values, updateFilter } = useFilters<TopInsightsFilterType>({
    initialValue: {
      INSIGHT_TYPE: INSIGHT_TYPE.REVENUE,
      START_DATE: dayjs(defaultDateRange.startDate).format(YYYY_MM_DD),
      END_DATE: dayjs(defaultDateRange.endDate).format(YYYY_MM_DD),
      SELECT_PERIOD: DATE_PERIOD.CURRENT_YEAR,
    },
  });

  const {
    data: insights,
    isLoading: isInsightsLoading,
    isSuccess: isInsightsSuccess,
    isFetching: isInsightsFetching,
    isError,
    error,
  } = useGetInsightsQuery(
    {
      groupId,
      entityId,
      start_date: dayjs(values.START_DATE).format(YYYY_MM_DD),
      end_date: dayjs(values.END_DATE).format(YYYY_MM_DD),
      limit: 5,
      insight_type: values.INSIGHT_TYPE,
      categorisation_type: categorisationType,
    },
    {
      skip:
        !groupId ||
        !entityId ||
        !values.START_DATE ||
        !values.END_DATE ||
        !values.INSIGHT_TYPE ||
        !categorisationType,
      refetchOnMountOrArgChange: true,
    }
  );

  const {
    categories = [],
    merchants = [],
    transactions = [],
    channel_url = "",
  } = insights || {};

  return (
    <DashboardContainer className="t-mb-5">
      <DashboardContainer.Header className="t-flex t-gap-4 t-flex-col">
        <Header
          v2
          title="Business Insights"
          bottom={
            <Tab.Root
              defaultValue={values.INSIGHT_TYPE}
              onValueChange={(value) => {
                updateFilter(
                  "INSIGHT_TYPE",
                  value as keyof typeof INSIGHT_TYPE
                );
              }}
            >
              <Tab.List>
                <Tab.Trigger value={INSIGHT_TYPE.REVENUE}>Revenue</Tab.Trigger>
                <Tab.Trigger value={INSIGHT_TYPE.EXPENSE}>Expense</Tab.Trigger>
              </Tab.List>
            </Tab.Root>
          }
        />
      </DashboardContainer.Header>
      <DashboardContainer.Content className="t-mt-4">
        <div className="t-grid t-grid-cols-2 t-gap-6">
          <div className="t-col-span-2">
            <Filter.Root
              defaultValue="DATE"
              title="Filter"
              capsule={
                <>
                  {values.START_DATE && (
                    <Chip
                      onClose={() => {
                        updateFilter(
                          "START_DATE",
                          dayjs(defaultDateRange.startDate).format(DD_MMM_YYYY)
                        );
                        updateFilter(
                          "END_DATE",
                          dayjs(defaultDateRange.endDate).format(DD_MMM_YYYY)
                        );
                      }}
                      isActive
                      isRemovable={false}
                      filterType="DATE"
                    >
                      From: {formatDate(values.START_DATE)} To:{" "}
                      {formatDate(values.END_DATE)}
                    </Chip>
                  )}
                </>
              }
            >
              <Filter.Portal>
                <Filter.List>
                  <Filter.ListItem value="DATE">Date</Filter.ListItem>
                </Filter.List>
                <Filter.Body value="DATE" block>
                  <DateFilter values={values} updateFilter={updateFilter} />
                </Filter.Body>
              </Filter.Portal>
            </Filter.Root>
          </div>
          <Async.Root
            isLoading={isInsightsLoading}
            isEmpty={!insights}
            isSuccess={isInsightsSuccess}
            isError={isError}
            customLoader={
              <div className="t-flex t-justify-center t-items-center t-h-40">
                <Loader customType="secondary" />
              </div>
            }
          >
            <Async.Empty>
              <EmptyMetrics
                illustration={<EmptyCategory />}
                title="No transactions categorised"
                subtitle="Add/categorise transactions to see top categories"
              />
            </Async.Empty>
            <Async.ErrorHandler>
              <div className="t-col-span-2 t-flex t-justify-center t-items-center t-h-40 t-text-red-50">
                {(error as BackendError)?.data?.error?.message ||
                  "Something went wrong!"}
              </div>
            </Async.ErrorHandler>
            <Async.Success>
              <Card.Root borderRounded="lg">
                <HomePageCardHeader>
                  <HomePageCardTitle>
                    {values.INSIGHT_TYPE === INSIGHT_TYPE.REVENUE
                      ? "Top Customer"
                      : "Top Vendors"}
                  </HomePageCardTitle>
                </HomePageCardHeader>
                <Card.Body>
                  <TopFiveVendors
                    isInsightsFetching={isInsightsFetching}
                    isInsightsLoading={isInsightsLoading}
                    isInsightsSuccess={isInsightsSuccess}
                    top_five_merchants={merchants}
                  />
                </Card.Body>
              </Card.Root>

              <Card.Root borderRounded="lg">
                <HomePageCardHeader>
                  <HomePageCardTitle>Top Categories</HomePageCardTitle>
                </HomePageCardHeader>
                <Card.Body>
                  <TopFiveCategories
                    top_five_categories={categories}
                    isInsightsSuccess={isInsightsSuccess}
                    isInsightsLoading={isInsightsLoading}
                    isInsightsFetching={isInsightsFetching}
                  />
                </Card.Body>
              </Card.Root>
              <div className="t-col-span-2">
                <Card.Root borderRounded="lg">
                  <HomePageCardHeader>
                    <HomePageCardTitle>
                      {values.INSIGHT_TYPE === INSIGHT_TYPE.REVENUE
                        ? "Top Income"
                        : "Top Spends"}
                    </HomePageCardTitle>
                  </HomePageCardHeader>
                  <Card.Body className="!t-p-0 t-pt-3">
                    <TopTransactions
                      isInsightsFetching={isInsightsFetching}
                      isInsightsLoading={isInsightsLoading}
                      isInsightsSuccess={isInsightsSuccess}
                      transactions={transactions}
                      channel_url={channel_url}
                    />
                  </Card.Body>
                </Card.Root>
              </div>
            </Async.Success>
          </Async.Root>
        </div>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
