import { RestrictedRoute } from "RestrictedRoute";
import { AdminNotificationDashboard } from "components/AdminNotification/AdminNotificationDashboard";
import { AppLayout } from "components/AppLayout/AppLayout";
import { Button } from "components/DesignSystem/Button/Button";
import { RoutesAccrossApps } from "components/RoutesAcrossApps/RoutesAcrossApps";
import { LeftBar, LeftBarItem } from "components/Sidebar/LeftBar";
import { TopBar } from "components/TopBar/TopBar";
import BillingTabBar from "components/billing/BillingTabBar";
import { ConditionalLink } from "components/conditionalLink";
import { PageNotFound } from "components/design/PageNotFound";
import { ArrowLeft } from "components/icons/ArrowLeft";
import { Home } from "components/icons/LeftNav/Home";
import { MyEntities } from "components/icons/LeftNav/MyEntities";
import { SmallBellIcon } from "components/icons/LeftNav/SmallBellIcon";
import { Team } from "components/icons/Navbar/Team";
import AddTeamMember from "components/teamMembers/addTeamMember";
import { Settings } from "components/userProfile/settings";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { parse, stringify } from "qs";
import { Redirect, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { DashboardBanner } from "signInSignUp/DashboardBanner";
import "static/styles/containers/dashboard.css";
import { AuditLogs } from "./AuditLogs/AuditLogs";
import { UpgradeTaxPlan } from "./Tax/UpgradeTaxPlan";
import { Tickets as CustomerTickets } from "components/Ticket/CustomerTicket/Tickets";
import { Ticket } from "components/icons/LeftNav/Ticket";

export const SettingsDashboard = () => {
  const { path, url } = useRouteMatch();

  const { isCpa, isInvestor, isForeignCA, isCustomer, isAdmin } =
    useRoleBasedView();

  let redirectTo = "/tax";
  if (isInvestor) {
    redirectTo = "/raise/investor";
  } else if (isForeignCA) {
    redirectTo = "/practice";
  } else if (isCpa) {
    redirectTo = "/admin";
  }

  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <AppLayout
      sidebar={
        <LeftBar
          logo={
            <ConditionalLink to={redirectTo} className="t-block t-px-4 t-py-2">
              <Button customType="transparent">
                <div className="t-flex t-items-center t-gap-2 t-text-text-30">
                  <span className="t-text-text-30">
                    <ArrowLeft size="16" />{" "}
                  </span>
                  Settings
                </div>
              </Button>
            </ConditionalLink>
          }
        >
          <div className="t-h-full">
            {isCustomer && (
              <LeftBarItem to={`/billing${search}`} icon={MyEntities}>
                Billing
              </LeftBarItem>
            )}
            <LeftBarItem exact to="/profile" icon={Home}>
              Profile Settings
            </LeftBarItem>
            {isAdmin && (
              <LeftBarItem exact to="/notifications" icon={SmallBellIcon}>
                Notifications
              </LeftBarItem>
            )}
            {isCustomer && (
              <>
                <LeftBarItem to="/team" icon={Team}>
                  Team Settings
                </LeftBarItem>
                <LeftBarItem to={`/auditlog${search}`} icon={MyEntities}>
                  Audit logs
                </LeftBarItem>
                <LeftBarItem to={"/tickets"} icon={Ticket}>
                  Tickets
                </LeftBarItem>
              </>
            )}
          </div>
        </LeftBar>
      }
      topbar={<TopBar upgradeButton={<UpgradeTaxPlan />} />}
      banner={<DashboardBanner />}
    >
      <Switch>
        <RestrictedRoute
          exact
          path={`${path}/auditlog`}
          component={AuditLogs}
        />
        <RestrictedRoute path={`${path}/billing`}>
          <BillingTabBar />
        </RestrictedRoute>
        <RestrictedRoute path={`${path}/tickets`} component={CustomerTickets} />

        <RestrictedRoute path={`${path}/team`}>
          <AddTeamMember />
        </RestrictedRoute>

        <RestrictedRoute exact path={`${path}`}>
          <Redirect to={`${path}/profile`} />
        </RestrictedRoute>

        <RestrictedRoute exact path={`${path}/profile`} component={Settings} />
        <RestrictedRoute
          path={`${path}/notifications`}
          component={isAdmin ? AdminNotificationDashboard : PageNotFound}
        />
        <RoutesAccrossApps />
      </Switch>
    </AppLayout>
  );
};
