import { BankConnectModal } from "components/ChartOfAccounts/BankConnectModal";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { BlurFade } from "components/design/BlurFade";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Card from "components/DesignSystem/Card/Card";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { ChartLine } from "components/icons/ChartLine";
import { Expand } from "components/icons/Expand";
import { RowsPlusBottom } from "components/icons/RowsPlusBottom";
import { monthPeriod } from "constants/bookkeeping";
import {
  ACCOUNT_TYPE,
  CATEGORISATION_TYPE,
  VIEW_TYPE,
} from "constants/booksHomePage";
import { YYYY_MM_DD } from "constants/date";
import * as DATE_PERIOD from "constants/dateFilter";
import dayjs from "dayjs";
import { useBankConnect } from "hooks/useBankConnect";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useModal } from "hooks/useModal";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { components, MultiValue, SingleValue } from "react-select";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { useGetCashBalanceQuery } from "store/apis/metrics";
import { DateRangeValue, getDateRange } from "utils/getDateRange";
import { Accounts } from "./Accounts";
import { BalanceModal } from "./BalanceModal";
import { CashBalanceChart } from "./Chart";
import { HomePageCardHeader, HomePageCardTitle } from "../HomePageCardHeader";
import { HideableAmount } from "../HideBalancesAndAmounts";

type FilterType = {
  VIEW_TYPE: keyof typeof VIEW_TYPE;
  PERIOD: DateRangeValue;
};

export const CashBalance = () => {
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const connectBank = useModal();
  const { onConnect } = useBankConnect();
  const balanceModal = useModal();
  const { values, updateFilter } = useFilters<FilterType>({
    initialValue: {
      VIEW_TYPE: VIEW_TYPE.TRENDS,
      PERIOD: DATE_PERIOD.LAST_6_MONTHS,
    },
  });

  const { endDate, startDate } = getDateRange(values.PERIOD);

  const cashRangeOption = monthPeriod.filter(
    (item) => item.value !== DATE_PERIOD.LAST_12_MONTHS
  );

  const value = cashRangeOption.find((item) => item.value === values.PERIOD);

  const { data, ...cashBalanceApiState } = useGetCashBalanceQuery(
    {
      entityId,
      start_date: dayjs(startDate).format(YYYY_MM_DD),
      end_date: dayjs(endDate).format(YYYY_MM_DD),
      account_type: ACCOUNT_TYPE.CASH,
      categorisation_type: CATEGORISATION_TYPE.ALL,
    },
    {
      skip: !startDate || !endDate || !entityId,
    }
  );

  const { data: banksAccounts, ...bankApiState } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const banksAccountsGroupByBank =
    banksAccounts?.accounts?.reduce(
      (acc: { [key: string]: typeof banksAccounts.accounts }, curr) => {
        const bankName = curr.bank_brand.name;
        if (!acc[bankName]) {
          acc[bankName] = [];
        }
        acc[bankName].push(curr);
        return acc;
      },
      {}
    ) || [];

  const accounts = Object.entries(banksAccountsGroupByBank).map(
    ([_, bank_accounts]) => {
      const cash_accounts = bank_accounts.filter(
        (account) => !account.is_credit_account
      );
      const credit_card_accounts = bank_accounts.filter(
        (account) => account.is_credit_account
      );

      const cash_balance = cash_accounts.reduce((acc, curr) => {
        return acc + curr.account.current_balance;
      }, 0);

      const credit_card_balance = credit_card_accounts.reduce((acc, curr) => {
        return acc + curr.account.current_balance;
      }, 0);

      const bank_brand = bank_accounts[0].bank_brand;

      return {
        cash_balance,
        credit_card_balance,
        bank_brand,
        credit_card_accounts_count: credit_card_accounts.length,
        cash_accounts_count: cash_accounts.length,
      };
    }
  );

  const { trends = [], bank_account_balance = 0 } = data || {};

  const chartData = Object.values(
    trends
      .flatMap(({ data }) => data)
      .reduce<Record<string, { x: string; y: number }>>(
        (acc, { date, amount }) => {
          if (!acc[date]) {
            acc[date] = { x: date, y: 0 };
          }
          acc[date].y += amount;
          return acc;
        },
        {}
      )
  );

  const isFetching = cashBalanceApiState.isFetching || bankApiState.isFetching;
  const isSuccess = cashBalanceApiState.isSuccess && bankApiState.isSuccess;
  const isError = cashBalanceApiState.isError || bankApiState.isError;
  const isTrendsEmpty = trends?.length === 0;
  const isBankAccountEmpty = accounts.length === 0;
  const isEmpty =
    values.VIEW_TYPE === VIEW_TYPE.TRENDS ? isTrendsEmpty : isBankAccountEmpty;

  return (
    <Card.Root
      shadow
      className="t-overflow-hidden t-relative"
      borderRounded="lg"
    >
      <HomePageCardHeader>
        <HomePageCardTitle>Balance</HomePageCardTitle>
        <Modal.Root
          open={balanceModal.isOpen}
          onOpenChange={balanceModal.toggle}
        >
          <Modal.Trigger asChild>
            <Button
              size="small"
              customType="ghost_icon"
              disabled={isFetching || isBankAccountEmpty}
              isLoading={isFetching}
            >
              <div className="t-text-text-30">
                <Expand />
              </div>
            </Button>
          </Modal.Trigger>
          <BalanceModal accounts={accounts} cash={bank_account_balance} />
        </Modal.Root>
      </HomePageCardHeader>
      <Card.Body className="t-flex t-flex-col t-gap-6 t-h-full">
        <div className="t-flex t-justify-between t-items-center t-gap-5">
          <div>
            <label className="t-text-body t-text-text-30">Cash</label>
            <div className="t-text-[24px] t-font-medium t-text-text-60">
              {bank_account_balance ? (
                <HideableAmount>
                  <AmountSuperScript amount={bank_account_balance} />
                </HideableAmount>
              ) : (
                "-"
              )}
            </div>
            {values.VIEW_TYPE === VIEW_TYPE.TRENDS && (
              <BlurFade>
                <Combobox
                  isSearchable={false}
                  components={{
                    Control: (props) => (
                      <components.Control
                        {...props}
                        className="t-w-32 !t-min-h-5"
                      />
                    ),
                  }}
                  onChange={(
                    value: SingleValue<OptionData> | MultiValue<OptionData>
                  ) => {
                    if (value instanceof Array) {
                      return;
                    }

                    const range = (value as SingleValue<OptionData>)?.value!;

                    if (range) {
                      updateFilter("PERIOD", range as DateRangeValue);
                    }
                  }}
                  size="small"
                  menuPortalTarget={document.body}
                  options={cashRangeOption}
                  isClearable={false}
                  defaultValue={value}
                />
              </BlurFade>
            )}
          </div>
          <ToggleGroup
            className="t-self-start"
            value={values.VIEW_TYPE}
            onValueChange={(value: keyof typeof VIEW_TYPE) => {
              updateFilter("VIEW_TYPE", value);
            }}
          >
            <ToggleGroupItem value={VIEW_TYPE.CURRENT}>
              <RowsPlusBottom />
            </ToggleGroupItem>
            <ToggleGroupItem value={VIEW_TYPE.TRENDS}>
              <ChartLine />
            </ToggleGroupItem>
          </ToggleGroup>
        </div>
        <Async.Root
          isEmpty={isEmpty}
          isLoading={isFetching}
          isSuccess={isSuccess}
          isError={isError}
          customLoader={
            <div className="t-flex t-justify-center t-items-center t-h-40">
              <Loader customType="secondary" />
            </div>
          }
        >
          <Async.Empty>
            <EmptyScreen
              text={
                <div className="t-text-subtext t-text-text-30">
                  No account found
                </div>
              }
              className="!t-h-32"
            />
          </Async.Empty>
          <Async.ErrorHandler>
            <div className="t-flex t-justify-center t-items-center t-h-40 t-text-red-50">
              Something went wrong!
            </div>
          </Async.ErrorHandler>
          <Async.Success>
            {values.VIEW_TYPE === VIEW_TYPE.TRENDS ? (
              <CashBalanceChart chartData={chartData} key={values.PERIOD} />
            ) : (
              <Accounts accounts={accounts.slice(0, 3)} />
            )}
          </Async.Success>
        </Async.Root>
      </Card.Body>
      <BankConnectModal
        isOpen={connectBank.isOpen}
        close={connectBank.close}
        onConnect={onConnect}
      />
    </Card.Root>
  );
};
