export const Calculator = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_45_30732)">
      <path
        d="M11 4H5V7H11V4Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 13.5V2.5C13 2.22386 12.7761 2 12.5 2L3.5 2C3.22386 2 3 2.22386 3 2.5V13.5C3 13.7761 3.22386 14 3.5 14H12.5C12.7761 14 13 13.7761 13 13.5Z"
        stroke="currentColor"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.5 10C5.91421 10 6.25 9.66421 6.25 9.25C6.25 8.83579 5.91421 8.5 5.5 8.5C5.08579 8.5 4.75 8.83579 4.75 9.25C4.75 9.66421 5.08579 10 5.5 10Z"
        fill="currentColor"
      />
      <path
        d="M8 10C8.41421 10 8.75 9.66421 8.75 9.25C8.75 8.83579 8.41421 8.5 8 8.5C7.58579 8.5 7.25 8.83579 7.25 9.25C7.25 9.66421 7.58579 10 8 10Z"
        fill="currentColor"
      />
      <path
        d="M10.5 10C10.9142 10 11.25 9.66421 11.25 9.25C11.25 8.83579 10.9142 8.5 10.5 8.5C10.0858 8.5 9.75 8.83579 9.75 9.25C9.75 9.66421 10.0858 10 10.5 10Z"
        fill="currentColor"
      />
      <path
        d="M5.5 12.5C5.91421 12.5 6.25 12.1642 6.25 11.75C6.25 11.3358 5.91421 11 5.5 11C5.08579 11 4.75 11.3358 4.75 11.75C4.75 12.1642 5.08579 12.5 5.5 12.5Z"
        fill="currentColor"
      />
      <path
        d="M8 12.5C8.41421 12.5 8.75 12.1642 8.75 11.75C8.75 11.3358 8.41421 11 8 11C7.58579 11 7.25 11.3358 7.25 11.75C7.25 12.1642 7.58579 12.5 8 12.5Z"
        fill="currentColor"
      />
      <path
        d="M10.5 12.5C10.9142 12.5 11.25 12.1642 11.25 11.75C11.25 11.3358 10.9142 11 10.5 11C10.0858 11 9.75 11.3358 9.75 11.75C9.75 12.1642 10.0858 12.5 10.5 12.5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_45_30732">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
