import { ConnectionsModal } from "components/Connections/ConnectionsModal";
import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { AccountCard } from "components/OnboardingWidget/Tax/ConnectBank";
import { useBankConnect } from "hooks/useBankConnect";
import { useEffect } from "react";
import CheckCircle from "static/images/CheckCircle.svg";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";

export const AnnualCatchupConnectBank = ({
  entityId,
  groupId,
  setCurrentStep,
  connectedBankCount,
  setConnectedBankCount,
}: {
  entityId: string;
  groupId: string;
  setCurrentStep: (step: number) => void;
  connectedBankCount: number;
  setConnectedBankCount: (count: number) => void;
}) => {
  const {
    data: banksAccounts,
    isLoading,
    isSuccess,
    refetch,
  } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );
  const { onConnect, isLoading: isConnecting } = useBankConnect({
    onConnectSuccess: refetch,
  });

  const accounts = banksAccounts?.accounts || [];

  useEffect(() => {
    if (isSuccess && connectedBankCount == -1) {
      setConnectedBankCount(accounts.length);
    }
  }, [isSuccess]);

  return (
    <>
      <Modal.Body className="t-h-[482px]">
        <div className="t-text-subtitle t-text-text-60">
          Connect all your banks
        </div>
        <div className="t-text-body t-text-text-30 t-mt-2 t-mb-4">
          We need to ensure all your accounts are connected to gather all your
          transactions for preparing your financial statements
        </div>
        <Async.Root
          {...{
            isLoading: isLoading,
            isSuccess: isSuccess,
            isEmpty: isSuccess && accounts.length === 0,
          }}
        >
          <Async.Empty>
            <></>
          </Async.Empty>
          <Async.Success>
            <Accordion.Root
              type="single"
              className="t-mb-4 t-transition-all"
              collapsible
            >
              <Accordion.Item value="accountsConnected">
                <Accordion.Trigger>
                  <div className="t-text-subtext t-text-text-100 t-flex t-items-center t-gap-2">
                    {banksAccounts?.accounts?.length} accounts connected
                    <img src={CheckCircle} alt="CheckCircle" />
                  </div>
                </Accordion.Trigger>
                <Accordion.Content className="t-text-body-sm t-text-text-60 t-mt-3 t-grid t-gap-4">
                  {accounts.length > 0 && (
                    <div className="t-flex t-flex-col">
                      <div className="t-mb-3 t-flex t-flex-col t-gap-3 t-max-h-72 t-overflow-y-auto">
                        {accounts.map((account) => (
                          <AccountCard
                            key={account.account.uuid}
                            mask={account.account.mask}
                            bankName={account.bank_brand.name}
                            accountName={account.account.nickname}
                            logo={account.bank_brand.logo}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </Async.Success>
        </Async.Root>
        <ConnectionsModal
          onConnect={onConnect}
          entityIdFromParent={entityId}
          showEntityLabel={false}
        />
      </Modal.Body>
      <Modal.FooterButtonGroup>
        <Button
          customType="primary"
          onClick={() => setCurrentStep(2)}
          disabled={accounts.length === 0}
        >
          Next
        </Button>
      </Modal.FooterButtonGroup>
    </>
  );
};
