import classNames from "classnames";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Loader } from "components/DesignSystem/Loader/Loader";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { ClockCountdown } from "components/icons/ClockCountdown";
import { DeleteIcon } from "components/icons/delete";
import { EmptyCart } from "components/TPPayments/Illustration/EmptyCart";
import { CART_ITEM_STATUS } from "constants/cart";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useModal } from "hooks/useModal";
import { useQuery } from "hooks/useQuery";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { flexRender, getCoreRowModel, useReactTable } from "react-table-8.10.7";
import WarningCircle from "static/images/WarningCircle.svg";
import {
  Cart as CartT,
  productsApi,
  useCheckoutCartMutation,
  useGetCartQuery,
  useGetCartItemsQuery,
  useRemoveProductFromCartMutation,
  useUpdateCartItemMutation,
  CartItem,
} from "store/apis/products";
import { refferalApis, useGetCreditsQuery } from "store/apis/refrral";
import { subscriptionApis } from "store/apis/subscriptions";
import { taskApis } from "store/apis/task";
import { ArrayElement } from "types/utils/ArrayElement";
import { BackendError } from "types/utils/error";
import { pluralize } from "utils/pluralize";
import { cartColumn, createCartColumn } from "./CartTableColumn";
import { SaveForLaterCard } from "./SaveForLaterCard";
import React from "react";
import Async from "components/DesignSystem/AsyncComponents/Async";
import ToolTip from "components/design/toolTip";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { useAnalytics } from "hooks/useAnalytics";
import { MOVE_TO_SAVE_FOR_LATER } from "constants/analyticsEvents";
import { Postpaid } from "components/icons/Postpaid";
dayjs.extend(isSameOrBefore);

export const PERIOD_MAP = {
  MONTHLY: "month",
  QUARTERLY: "quarter",
  YEARLY: "year",
};

export const CartItemActions = ({
  cartItem,
  entityId,
}: {
  cartItem: ArrayElement<CartT["cart_items"]>;
  entityId: string;
}) => {
  const { trackEvent } = useAnalytics();
  const { successToast, alertToast } = useToast();
  const group = useCurrentGroup();
  const groupId = group.uuid;
  const [removeCartItem, { isLoading: isRemoving }] =
    useRemoveProductFromCartMutation();
  const [saveForLater, { isLoading: isSaving }] = useUpdateCartItemMutation();

  const onDelete = async () => {
    if (groupId && cartItem) {
      try {
        await removeCartItem({
          groupId,
          cartItemId: cartItem.uuid,
          entityId: entityId,
        }).unwrap();
        successToast({ message: "Item has been deleted!" });
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  const onSaveForLater = async () => {
    try {
      await saveForLater({
        entityId,
        payload: {
          cartItemId: cartItem.uuid,
          status: CART_ITEM_STATUS.SAVED_FOR_LATER,
        },
      }).unwrap();
      trackEvent(MOVE_TO_SAVE_FOR_LATER, {
        cart_item_id: cartItem.uuid,
      });
      successToast({ message: "Item has been moved to Save for later" });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <div className="t-flex t-justify-end">
      <ToolTip text="Delete">
        <Button
          isLoading={isRemoving}
          disabled={isRemoving}
          customType="ghost_icon"
          size="small"
          onClick={onDelete}
        >
          <span className="t-text-text-30">
            <DeleteIcon stroke="1.5" />
          </span>
        </Button>
      </ToolTip>
      <ToolTip text="Save for later">
        <Button
          isLoading={isSaving}
          disabled={isSaving}
          customType="ghost_icon"
          size="small"
          onClick={onSaveForLater}
        >
          <span className="t-text-text-30">
            <ClockCountdown />
          </span>
        </Button>
      </ToolTip>
    </div>
  );
};

export const CartModal = ({
  open,
  onClose,
  entityIdFromProps,
  shouldRedirectToTask = true,
  handlePaymentSuccess,
}: {
  open: boolean;
  onClose: () => void;
  entityIdFromProps?: string;
  shouldRedirectToTask?: boolean;
  handlePaymentSuccess?: () => void;
}) => {
  const group = useCurrentGroup();
  const query = useQuery();
  const highlightTemplate = query.get("from_template");
  const groupId = group.uuid;

  const [checkoutCart, { isLoading: isCheckoutLoading, data: cartInvoice }] =
    useCheckoutCartMutation();

  const history = useHistory();
  const [isPayViaCredits, setIsPayViaCredits] = useState(false);
  const [showInsufficentError, setShowInsufficentError] = useState(false);

  const dispatch = useAppDispatch();
  const entityUuid = useCurrentEntityId();
  const { isAdmin } = useRoleBasedView();
  const entityId = entityIdFromProps || entityUuid;

  const { data: cart, ...cartApiState } = useGetCartQuery(
    { groupId: groupId!, entityId: entityId! },
    { skip: !groupId || !entityId }
  );

  const { data: saveForLaterCart, ...saveForLaterCartApiState } =
    useGetCartItemsQuery(
      { entityId, status: CART_ITEM_STATUS.SAVED_FOR_LATER },
      {
        skip: !entityId,
      }
    );

  const isCartLoading =
    saveForLaterCartApiState.isLoading || cartApiState.isLoading;
  const isCartRefreshing =
    saveForLaterCartApiState.isFetching || cartApiState.isFetching;
  const isSuccess =
    saveForLaterCartApiState.isSuccess && cartApiState.isSuccess;
  const isEmpty =
    Boolean(cart) &&
    cart?.cart_items.length === 0 &&
    Boolean(saveForLaterCart) &&
    saveForLaterCart?.cart_items.length === 0;

  const isPostpaid = (item: CartItem) => {
    return (
      item.product_details.base_task_key === "US_BOOKKEEPING_ONE_TIME" &&
      (isNaN(Number(item.subtotal)) || Number(item.subtotal) === 0)
    );
  };

  const isPostpaidIncluded = cart?.cart_items.some((item) => isPostpaid(item));
  const isOnlyPostpaidRequired = cart?.cart_items.every((item) =>
    isPostpaid(item)
  );

  const { data: creditsData } = useGetCreditsQuery(
    { groupId: groupId! },
    { skip: !groupId }
  );

  const { total_credits } = creditsData || {};

  const onPaymentSuccess = async () => {
    if (groupId) {
      dispatch(productsApi.util.invalidateTags([{ type: "CART" }]));
      dispatch(taskApis.util.invalidateTags([{ type: "Tasks" }]));
      dispatch(
        subscriptionApis.util.invalidateTags([{ type: "Subscriptions" }])
      );
      dispatch(refferalApis.util.invalidateTags([{ type: "CREDITS" }]));

      closePayment();
      onClose();
      handlePaymentSuccess?.();
      if (shouldRedirectToTask) {
        history.push("/filings?check_for_tasks=true");
      }
    }
  };

  const columns = [
    cartColumn.name(),
    cartColumn.quantity(),
    cartColumn.season(),
    cartColumn.total(),
    createCartColumn.display({
      header: "",
      id: "actions",
      cell: (info) => {
        return (
          <CartItemActions
            cartItem={info.row.original}
            // @ts-ignore
            entityId={info.table.options.meta.entityId}
          />
        );
      },
      size: 5,
    }),
  ];

  const table = useReactTable({
    data: cart?.cart_items || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: { minSize: 10 },
    meta: {
      entityId: cart?.entity_id || entityId!,
    },
  });

  const { alertToast } = useToast();
  const {
    isOpen: isPaymentOpen,
    close: closePayment,
    open: openPayment,
  } = useModal();

  const onCheckout = async () => {
    if (groupId && cart?.uuid) {
      try {
        await checkoutCart({
          groupId,
          cartId: cart.uuid,
          entityId: cart.entity_id,
        }).unwrap();
        openPayment();
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  const handleCreditCheckboxChange = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (cart && cart.order_summary.total <= total_credits!) {
      await checkoutCart({
        groupId: groupId!,
        cartId: cart.uuid,
        entityId: cart.entity_id,
      }).unwrap();
      setIsPayViaCredits(e.target.checked);
    } else {
      if (!e.target.checked) setShowInsufficentError(false);
      else setShowInsufficentError(true);
    }
  };

  return (
    <>
      <Modal.Root open={open} onOpenChange={onClose}>
        <Modal.Content size="xxl">
          <Modal.Header>
            <Modal.Title>
              Your Cart{" "}
              {cart && cart.order_summary.items_count > 0 && (
                <>
                  ({pluralize(cart.order_summary.items_count, "item", "items")})
                </>
              )}
            </Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="!t-pt-0 t-mt-5">
            <div className="t-w-52 t-mb-5">
              <EntitySelector size="small" />
            </div>
            <Async.Root
              isLoading={isCartLoading}
              isEmpty={isEmpty}
              isSuccess={isSuccess}
              customLoader={
                <div className="t-flex t-justify-center t-items-center t-flex-col t-gap-3">
                  <Loader />
                </div>
              }
            >
              <Async.Empty>
                <div className="t-flex t-justify-center t-items-center t-flex-col t-gap-3">
                  <EmptyCart />
                  <p className="t-text-text-30 t-text-body-sm">
                    Your cart is empty right now
                  </p>
                </div>
              </Async.Empty>
              <Async.Success>
                <div className="t-flex t-gap-6">
                  <div className="t-w-8/12 t-flex t-flex-col t-gap-5">
                    {cart && cart.cart_items.length > 0 && (
                      <Table.Container className="t-w-full">
                        <Table.Content className="t-w-full">
                          <Table.Head>
                            {table.getHeaderGroups().map((headerGroup) => (
                              <Table.Row key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                  <Table.HeadCell
                                    key={header.id}
                                    className="t-text-subtext-sm t-uppercase t-py-2"
                                    style={{ width: `${header.getSize()}%` }}
                                  >
                                    {header.isPlaceholder
                                      ? null
                                      : flexRender(
                                          header.column.columnDef.header,
                                          header.getContext()
                                        )}
                                  </Table.HeadCell>
                                ))}
                              </Table.Row>
                            ))}
                          </Table.Head>
                          <Table.Body>
                            {table.getRowModel().rows.map((row) => (
                              <Table.Row
                                key={row.id}
                                className={classNames({
                                  "t-bg-blue-10":
                                    highlightTemplate ===
                                    row.original.product_details.base_task_key,
                                })}
                              >
                                {row.getVisibleCells().map((cell) => (
                                  <td
                                    key={cell.id}
                                    style={{
                                      width: `${cell.column.getSize()}%`,
                                    }}
                                    className="t-py-4 t-px-2"
                                  >
                                    {flexRender(
                                      cell.column.columnDef.cell,
                                      cell.getContext()
                                    )}
                                  </td>
                                ))}
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table.Content>
                      </Table.Container>
                    )}
                    {Boolean(saveForLaterCart) &&
                      (saveForLaterCart?.cart_items ?? []).length > 0 && (
                        <SaveForLaterCard entityId={entityId} />
                      )}
                  </div>

                  {cart && (
                    <div className="t-p-4 t-w-4/12 t-bg-surface-lighter-grey t-border-solid t-border t-border-neutral-0 t-rounded t-min-h-72 t-flex t-flex-col t-sticky t-top-0">
                      <p className="t-pb-4 t-border-solid t-border-b t-border-neutral-0 t-border-0 t-text-subtext">
                        Order Summary
                      </p>
                      <p className="t-flex t-m-0 t-justify-between t-text-body t-mr-2">
                        <span>
                          Subtotal (
                          {pluralize(
                            cart.order_summary.items_count || 0,
                            "item",
                            "items"
                          )}
                          )
                        </span>
                        <div className="t-flex t-flex-col t-items-end">
                          {isOnlyPostpaidRequired ? (
                            <div className="t-text-body t-text-text-60">
                              Postpaid
                            </div>
                          ) : (
                            <span>
                              <AmountSuperScript
                                amount={cart.order_summary.subtotal}
                              />
                            </span>
                          )}
                          {isPostpaidIncluded && !isOnlyPostpaidRequired && (
                            <div className="t-text-body- t-text-text-30">
                              + Postpaid
                            </div>
                          )}
                        </div>
                      </p>

                      {Boolean(cart.order_summary.discount) &&
                        (cart.order_summary.discount?.discount_value || 0) >
                          0 && (
                          <p className="t-flex t-m-0 t-justify-between t-text-body t-mt-4">
                            <span>
                              Discount (
                              <span className="t-text-dark_green">
                                {
                                  cart.order_summary.discount?.coupon
                                    ?.coupon_code
                                }
                              </span>
                              )
                            </span>
                            <span className="t-text-dark_green">
                              -
                              <AmountSuperScript
                                amount={
                                  cart.order_summary.discount?.discount_value ||
                                  0
                                }
                              />
                            </span>
                          </p>
                        )}

                      {isAdmin && (
                        <div className="t-mt-4">
                          <Checkbox
                            label={"Pay using Inkle credits"}
                            onChange={handleCreditCheckboxChange}
                          />
                          <div className="t-text-body-sm t-pl-6 t-flex t-flex-col t-gap-0.5">
                            <span className="t-text-neutral-40">
                              {total_credits?.toString()} credits available
                            </span>
                            {showInsufficentError && (
                              <span className="t-flex t-items-center t-gap-1">
                                <span className="t-text-red">
                                  Insufficient credit balance{" "}
                                </span>
                                <img
                                  src={WarningCircle}
                                  alt="Warning circle"
                                  className="t-h-3.5"
                                />
                              </span>
                            )}
                          </div>
                        </div>
                      )}

                      <p className="t-flex t-m-0 t-justify-between t-text-subtext t-pt-4 t-border-solid t-border-0 t-border-t t-border-neutral-0 t-mt-auto t-mr-2">
                        <span>Total</span>
                        <div className="t-flex t-flex-col t-items-end">
                          {isOnlyPostpaidRequired ? (
                            <div className="t-text-subtitle t-text-text-60">
                              Postpaid
                            </div>
                          ) : (
                            <span>
                              <AmountSuperScript
                                amount={cart.order_summary.total}
                              />
                            </span>
                          )}
                          {isPostpaidIncluded && !isOnlyPostpaidRequired && (
                            <div className="t-text-body t-text-text-30">
                              + Postpaid
                            </div>
                          )}
                        </div>
                      </p>
                      {isPostpaidIncluded && (
                        <div className="t-flex t-items-baseline t-gap-1.5 t-mt-4">
                          <span className="t-text-text-30">
                            <Postpaid />
                          </span>
                          <div className="t-text-body-sm t-text-text-30">
                            Postpaid charges are paid after the service has been
                            completed
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Async.Success>
            </Async.Root>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <Button onClick={onClose}>Back</Button>
              {isPayViaCredits && cart ? (
                <CheckoutModal
                  type="cart"
                  onCartPaid={onPaymentSuccess}
                  cart={{
                    ...cart,
                    cart_items: cart.cart_items.map((item) =>
                      item.subscription
                        ? {
                            ...item,
                            subscription: {
                              ...item.subscription,
                              selectedTierAmount: item.tier_information?.amount,
                              raStateName: item.ra_state_name,
                              cartItemId: item.uuid,
                            },
                          }
                        : item
                    ),
                  }}
                  cartInvoice={cartInvoice?.invoice}
                  title="Cart Payment"
                  onClose={closePayment}
                  open={isPaymentOpen}
                  onlyCTA={isPayViaCredits}
                  useCredits={isPayViaCredits}
                />
              ) : (
                <Button
                  onClick={onCheckout}
                  customType="primary"
                  isLoading={isCheckoutLoading}
                  disabled={
                    isCheckoutLoading ||
                    !cart ||
                    cart.cart_items.length === 0 ||
                    isCartRefreshing ||
                    (showInsufficentError && isPayViaCredits)
                  }
                >
                  Checkout
                </Button>
              )}
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>

      {!isPayViaCredits && open && isPaymentOpen && cart && (
        <CheckoutModal
          type="cart"
          onCartPaid={onPaymentSuccess}
          cart={{
            ...cart,
            cart_items: cart.cart_items.map((item) =>
              item.subscription
                ? {
                    ...item,
                    subscription: {
                      ...item.subscription,
                      selectedTierAmount: item.tier_information?.amount,
                      raStateName: item.ra_state_name,
                      cartItemId: item.uuid,
                      customPrice: item.unit_price,
                    },
                  }
                : item
            ),
          }}
          cartInvoice={cartInvoice?.invoice}
          title="Cart Payment"
          onClose={() => {
            closePayment();
            onClose();
          }}
          open={isPaymentOpen}
        />
      )}
    </>
  );
};
