import { ComingSoonIllustration } from "components/Illustrations/ComingSoon";
import DashboardContainer from "components/dashboard/DashboardContainer";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";

export const ComingSoon = () => {
  const { authtoken } = useContext(authContext);

  return (
    <DashboardContainer className="t-h-full">
      <DashboardContainer.Header>
        <h2 className="!t-m-0 t-text-title-h2-bold t-px-6 t-pb-3 t-pt-6">
          {<span>Welcome {authtoken?.first_name}</span>}
        </h2>
      </DashboardContainer.Header>
      <DashboardContainer.Content className="t-justify-center t-flex t-items-center">
        <ComingSoonIllustration />
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
