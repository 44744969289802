import classNames from "classnames";
import { Button } from "components/DesignSystem/Button/Button";
import { KeyDownWrapper } from "components/DesignSystem/KeydownWrapper/KeydownWrapper";
import { NoteCard } from "components/DesignSystem/Notes/NoteCard";
import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Seperator } from "components/icons/Chat/Seperator";
import { OutlinedPaperPlaneRight } from "components/icons/OutlinedPaperPlaneRight";
import dayjs from "dayjs";
import { Form, Formik, FormikValues } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetTransaction } from "hooks/useGetTransaction";
import { useToast } from "hooks/useToast";
import { useEffect, useRef } from "react";
import { useAddTransactionCommentMutation } from "store/apis/transactions";
import { Comment } from "types/Models/books";
import { BackendError } from "types/utils/error";

const Note = ({
  note,
  transactionId,
}: {
  note: Comment;
  transactionId: string;
}) => {
  const { created_at, created_by, description, uuid } = note || {};
  const commentRef = useRef<HTMLDivElement>(null);
  const { data: transaction } = useGetTransaction({
    transactionId,
  });

  const { comments } = transaction || {};
  const { notes = [] } = comments || {};
  const lastNoteId = notes[notes.length - 1].uuid;

  useEffect(() => {
    if (lastNoteId === uuid) {
      commentRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [lastNoteId, uuid]);

  return <NoteCard ref={commentRef} note={note} />;
};

export const Comments = ({ transactionId }: { transactionId: string }) => {
  const { data: transaction } = useGetTransaction({
    transactionId,
  });
  const { comments } = transaction || {};
  const { notes = [] } = comments || {};
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast } = useToast();
  const [addComment, { isLoading }] = useAddTransactionCommentMutation();

  const onAddComment = async (
    values: { comment: string },
    { resetForm }: FormikValues
  ) => {
    try {
      const payload = { description: values?.comment };
      await addComment({
        groupId,
        previewTxnId: transactionId,
        payload,
      }).unwrap();
      resetForm();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const noComments = notes.length === 0;

  return (
    <Accordion.Item value="COMMENT">
      <Accordion.Trigger>
        Comments {notes.length > 0 && `(${notes.length})`}
      </Accordion.Trigger>
      <Accordion.Content>
        <span>
          <KeyDownWrapper>
            <div className="t-overflow-auto t-flex t-flex-col t-gap-2 t-max-h-80">
              {!noComments && (
                <div className="t-flex t-flex-col t-gap-2">
                  {notes?.map((note) => (
                    <Note
                      note={note}
                      key={note.uuid}
                      transactionId={transactionId}
                    />
                  ))}
                </div>
              )}
              <Formik initialValues={{ comment: "" }} onSubmit={onAddComment}>
                {() => (
                  <Form
                    className={classNames("t-w-full t-m-0 t-sticky t-bottom-0")}
                  >
                    <TextInput
                      placeholder="Add your comment here"
                      name="comment"
                      customSize="large"
                      rightComponent={
                        <Button
                          customType="ghost_icon"
                          isLoading={isLoading}
                          disabled={isLoading}
                        >
                          <OutlinedPaperPlaneRight size="20" color="#706A85" />
                        </Button>
                      }
                    />
                  </Form>
                )}
              </Formik>
            </div>
          </KeyDownWrapper>
        </span>
      </Accordion.Content>
    </Accordion.Item>
  );
};
