import { ChannelListFilter } from "components/ChannelSearch/ChannelListFilter";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { DividerLine } from "components/icons/DividerLine";
import { useToast } from "hooks/useToast";
import { ComponentProps, memo, useContext } from "react";
import { useToggleChannelRepliedForBulkMutation } from "store/apis/chat";
import { Button } from "components/DesignSystem/Button/Button";
import { SelectedChannelsContext } from "./SelectedChannelContext";
import { ChannelSearch } from "components/ChannelSearch/ChannelSearch";

const MemoizedChannelSearch = memo(ChannelSearch);

export const ChannelFilterOrSearch = ({
  hideFilters,
  unreplied,
}: {
  unreplied: boolean;
} & Pick<ComponentProps<typeof ChannelListFilter>, "hideFilters">) => {
  const { setSelectedChannels, selectedChannels } = useContext(
    SelectedChannelsContext
  );
  const [toggleChannelRepliedForBulk, { isLoading }] =
    useToggleChannelRepliedForBulkMutation();
  const { successToast, alertToast } = useToast();

  const markSelectedChannels = async (needsReply: boolean) => {
    try {
      await toggleChannelRepliedForBulk({
        channelIds: selectedChannels,
        channelNeedsReply: needsReply,
      }).unwrap();

      successToast({
        message: `Channels marked as ${
          needsReply ? "unreplied" : "replied"
        } successfully`,
      });
    } catch (error: any) {
      alertToast({
        message: error?.data?.error?.message || "Something went wrong",
      });
    }
  };

  return (
    <div className="t-flex t-gap-4">
      {selectedChannels.length > 0 ? (
        <div className="t-flex t-gap-3 t-items-center">
          <Checkbox
            onChange={() => {
              setSelectedChannels([]);
            }}
            indeterminate
          />
          <span className="t-text-text-30 t-text-body">
            {selectedChannels.length} Selected
          </span>
          <span className="t-text-text-30 t-text-body t-flex t-items-center">
            <DividerLine />{" "}
          </span>
          {unreplied ? (
            <Button
              size="small"
              customType="primary-outlined"
              onClick={() => markSelectedChannels(false)}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Mark as replied
            </Button>
          ) : (
            <Button
              size="small"
              customType="primary-outlined"
              onClick={() => markSelectedChannels(true)}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Mark as unreplied
            </Button>
          )}
        </div>
      ) : (
        <MemoizedChannelSearch hideFilters={hideFilters} />
      )}
    </div>
  );
};
