import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { FileInput } from "components/FileInput/FileInput";
import { Form1099 } from "components/Form1099/Form1099";
import { SmallPlusIcon } from "components/icons/export const SmallPlusIcon";
import { Preview } from "components/PreviewModal";
import { FileRequestOrUpload } from "components/WformFileRequestOrUpload";
import { WSeriesFormUpload } from "components/WSeriesFormUpload";
import { AWAITING } from "constants/merchantFormStatuses";
import { Form, Formik } from "formik";
import { merchantEmailAddSchema } from "formValidations/vendorSchema";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { CellContext } from "react-table-8.10.7";
import HourGlassTimer from "static/images/PinkHourglass.svg";
import { taskMerchantApis } from "store/apis/taskMerchants";
import { useEditVendorsMutation, vendorsApi } from "store/apis/vendors";
import { FileObject } from "types/Models/fileObject";
import { NOT_REQUIRED, Vendor } from "types/Models/vendors";
import { FileIcon } from "utils/fileTypeIcon";

const AddVendorEmail = ({
  show,
  closeModal,
  editMerchantId,
}: {
  show: boolean;
  closeModal: () => void;
  editMerchantId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const [editVendor, { isLoading: isEmailAdding }] = useEditVendorsMutation();
  const { uuid: groupId } = useCurrentGroupContext();

  const handleSubmit = async (payload: { email: string }) => {
    try {
      await editVendor({ groupId, uuid: editMerchantId, payload }).unwrap();
      successToast({ message: "Vendor email has been added" });
      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Modal.Content size="regular">
        <Modal.Header>
          <Modal.Title>Add Email</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Formik
            initialValues={{
              email: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={merchantEmailAddSchema}
            validateOnChange
            validateOnMount={false}
          >
            {({ isValid }) => (
              <Form className="t-m-0">
                <div className="t-flex t-w-full t-flex-col t-gap-5">
                  <TextInput
                    block
                    type="email"
                    name="email"
                    label="Email"
                    placeholder="vendor@inkle.io"
                    required
                  />
                </div>
                <div className="t-flex t-gap-3 t-justify-end t-mt-6">
                  <Button
                    customType="secondary"
                    size="regular"
                    type="reset"
                    onClick={closeModal}
                  >
                    Cancel
                  </Button>
                  <Button
                    customType="primary"
                    size="regular"
                    type="submit"
                    disabled={!isValid || isEmailAdding}
                    isLoading={isEmailAdding}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};

const Email = ({ info }: { info: CellContext<Vendor, Vendor["email"]> }) => {
  const rowData = info.row.original;

  const addVendorEmailModal = useModal();

  return (
    <div onClick={(e) => e.stopPropagation()}>
      {rowData?.email ? (
        <span className="t-text-text-30 t-text-body-sm">{rowData?.email}</span>
      ) : (
        <>
          <button
            className="all:unset t-text-text-30 t-flex t-gap-0.5 t-items-center"
            onClick={addVendorEmailModal.open}
          >
            <SmallPlusIcon />
            <div className="t-text-body-sm">Add email</div>
          </button>
          {addVendorEmailModal.isOpen && (
            <AddVendorEmail
              show={addVendorEmailModal.isOpen}
              closeModal={addVendorEmailModal.close}
              editMerchantId={rowData.uuid}
            />
          )}
        </>
      )}
    </div>
  );
};

export const WForm = ({
  vendor,
  season,
  groupId,
}: {
  vendor: Vendor;
  season: string;
  groupId: string;
}) => {
  const dispatch = useAppDispatch();
  const formUploadModal = useModal();

  const getAllMerchants = () => {
    dispatch(vendorsApi.util.invalidateTags(["VENDORS"]));
    dispatch(taskMerchantApis.util.invalidateTags(["Merchants"]));
  };

  if (vendor.is_w_form_required === NOT_REQUIRED) {
    return (
      <Tag tagType="transparent" icon={false} rounded size="medium">
        Not required
      </Tag>
    );
  }

  if (!vendor.w_form_document) {
    return (
      <div className="t-w-4/5">
        <FileRequestOrUpload
          taskId=""
          merchant={vendor}
          groupId={groupId}
          updateMerchant={getAllMerchants}
          editable
          wFormStatus={vendor.form_status}
          season={season}
          isSuccessPage={false}
        />
      </div>
    );
  }

  if (vendor.form_status === AWAITING) {
    return (
      <div className="t-w-4/5">
        <ToolTip text="Awaiting response">
          <span className="t-w-full t-flex t-gap-1 t-items-center">
            <div className="t-text-body t-text-text-30">Requested</div>
            <img src={HourGlassTimer} alt="HourGlassTimer" />
          </span>
        </ToolTip>
      </div>
    );
  }

  if (vendor.w_form_document) {
    return (
      <div className="t-w-4/5">
        <div
          className="t-flex t-items-center t-gap-1 t-text-text-30"
          role="button"
          onClick={formUploadModal.open}
        >
          <FileIcon
            fileType={vendor?.w_form_document?.file_type}
            width="24px"
            height="24px"
          />
          <div className="t-text-body-sm">{vendor?.w_form_choice}</div>
        </div>
        <WSeriesFormUpload
          show={formUploadModal.isOpen}
          closeModal={formUploadModal.close}
          groupId={groupId}
          merchant={vendor}
          updateMerchant={getAllMerchants}
          editable
          openModal={formUploadModal.open}
          season={season}
        />
      </div>
    );
  }
};

const FormPreview = ({
  document,
  documentType,
  tooltipText,
}: {
  document?: FileObject;
  tooltipText: string;
  documentType: string;
}) => {
  const filePreview = useModal();

  return (
    <>
      <ToolTip text={tooltipText}>
        <span className="t-flex t-items-center t-gap-1">
          <FileInput
            file={document}
            variant="icon"
            onFileClick={filePreview.open}
          />
          <span className="t-text-text-60 t-text-body-sm">{documentType}</span>
        </span>
      </ToolTip>
      <Preview
        closeModal={filePreview.close}
        showModal={filePreview.isOpen}
        previewId={document?.uuid}
      />
    </>
  );
};

export const Form1099Handler = ({
  vendor,
  entityId,
  season,
}: {
  vendor: Vendor;
  season: string;
  entityId: string;
}) => {
  const {
    form_1099_document,
    is_w_form_required,
    w_form_choice,
    form_1099_status,
    merchant_data_w_form_map_id,
    form_1099_type,
  } = vendor;

  const isWFormNotRequired = is_w_form_required === NOT_REQUIRED;

  if (form_1099_status === "NOT_REQUIRED") {
    return (
      <div className="t-text-center t-text-body t-text-text-60">
        <Tag tagType="transparent" icon={false} rounded size="medium">
          Not required
        </Tag>
      </div>
    );
  }

  if (
    form_1099_document?.payers_document ||
    form_1099_document?.recipients_document
  ) {
    return (
      <div className="t-flex t-justify-center t-gap-4 t-items-center">
        {form_1099_document?.payers_document && (
          <FormPreview
            documentType="Payer"
            tooltipText="Payer 1099 Form"
            document={form_1099_document?.payers_document}
          />
        )}
        {form_1099_document?.recipients_document && (
          <FormPreview
            documentType="Recipient"
            tooltipText="Recipient 1099 Form"
            document={form_1099_document?.recipients_document}
          />
        )}
      </div>
    );
  }

  if (form_1099_status === "IN_PROGRESS") {
    return (
      <Form1099
        wFormMapId={merchant_data_w_form_map_id}
        form1099Type={form_1099_type}
        season={season}
        entityId={entityId}
        isInprogress
      />
    );
  }

  if (vendor.w_form_choice === "W-9") {
    return (
      <Form1099
        wFormMapId={merchant_data_w_form_map_id}
        form1099Type={form_1099_type}
        season={season}
        entityId={entityId}
      />
    );
  }

  if (
    isWFormNotRequired ||
    w_form_choice === "W-8BEN" ||
    w_form_choice === "W-8BEN-E"
  ) {
    return (
      <div className="t-text-center t-text-body t-text-text-60">
        <Tag tagType="transparent" icon={false} rounded size="medium">
          Not required
        </Tag>
      </div>
    );
  }

  return <div className="t-text-center t-text-body t-text-text-60">-</div>;
};

const VendorColumn = {
  Email,
};

export default VendorColumn;
