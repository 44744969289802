import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Cross } from "components/icons/Cross";
import { SelectAutofillCombobox } from "components/SelectAutofillCombobox";
import { AUTOFILL_ADDRESS_TYPE_TAG } from "constants/billing";
import { MAILROOM_ADDRESS } from "constants/mailRoom";
import { Form, Formik, FormikHelpers, FormikValues } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import {
  useGetAutofillQuery,
  useUpdateAutofillMutation,
} from "store/apis/autofill";
import { useGetTagsForCategoryQuery } from "store/apis/billing";
import { AddressAutofill } from "types/Models/addressAutofill";
import { BankAutofill } from "types/Models/bankAutofill";
import { PersonaAutofill } from "types/Models/personaAutofill";
import { BackendError } from "types/utils/error";

export const DefaultForwardMessage = ({
  isOpen,
  close,
  openForwardModal,
  defaultForwardAddress,
}: {
  isOpen: boolean;
  close: () => void;
  openForwardModal: () => void;
  defaultForwardAddress?: string;
}) => {
  const { data: tagsData = [] } = useGetTagsForCategoryQuery({
    tagType: AUTOFILL_ADDRESS_TYPE_TAG,
  });
  const entityId = useCurrentEntityId();

  const mailroomAddressTagId = tagsData.find(
    ({ title }) => title === MAILROOM_ADDRESS
  )?.uuid;

  const { uuid: groupId } = useCurrentGroupContext();
  const { data: autofills = [] } = useGetAutofillQuery(
    {
      groupId,
      autofillKey: "addresses",
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const { alertToast, successToast } = useToast();
  const [detailsForEdit, setDetailsForEdit] = useState<
    BankAutofill | AddressAutofill | PersonaAutofill | undefined
  >();
  const [updateAutofill, { isLoading: isUpdating }] =
    useUpdateAutofillMutation();

  const updateMailroomForwardAddress = async (values: FormikValues) => {
    try {
      const currentSelectedAutofillDetails = autofills.find(
        ({ uuid }) => uuid === values.address
      );
      await updateAutofill({
        fields: {
          ...currentSelectedAutofillDetails,
          tag_id: mailroomAddressTagId,
        },
        groupId,
        uuid: currentSelectedAutofillDetails?.uuid || "",
        autofillKey: "addresses",
        entityId,
      }).unwrap();
      successToast({ message: "Address saved" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <>
      {isOpen && (
        <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
          <Modal.Content useCustomOverlay>
            <Modal.Header>
              <Modal.Title>Default Forwarding Address</Modal.Title>
              <Modal.Close />
            </Modal.Header>
            <Formik
              validateOnChange={false}
              enableReinitialize
              initialValues={{
                address: detailsForEdit?.uuid || defaultForwardAddress || "",
              }}
              onSubmit={updateMailroomForwardAddress}
            >
              {({ values, setFieldValue }) => {
                return (
                  <Form className="t-m-0">
                    <Modal.Body className="t-flex t-gap-4 t-flex-col t-w-full t-overflow-auto">
                      <div className="t-max-h-[460px]">
                        <SelectAutofillCombobox
                          type="addresses"
                          name="address"
                          label="Address"
                          withForm
                          entityId={entityId}
                          selected={values.address}
                          onChange={(addressId) =>
                            setFieldValue("address", addressId)
                          }
                          srollToSave
                        />
                      </div>
                    </Modal.Body>
                    <Modal.Footer className="t-justify-end t-flex t-gap-3">
                      <Button type="button" onClick={close}>
                        Cancel
                      </Button>
                      <Button
                        customType="primary"
                        isLoading={isUpdating}
                        disabled={isUpdating || !Boolean(values.address)}
                        type="submit"
                      >
                        Confirm
                      </Button>
                    </Modal.Footer>
                  </Form>
                );
              }}
            </Formik>
          </Modal.Content>
        </Modal.Root>
      )}
    </>
  );
};
