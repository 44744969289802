import React, { useMemo } from "react";
import {
  Registration,
  useGetSalesTaxRegistrationsQuery,
} from "store/apis/salesTax";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { TableUI } from "components/design/TableUI";
import DashboardContainer from "components/dashboard/DashboardContainer";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { EmptySalesTax } from "components/Illustrations/EmptySalesTax";

const columnHelper = createColumnHelper<Registration>();

export const SalesTaxRegistrations = () => {
  const entityId = useCurrentEntityId();
  const { isAdmin } = useRoleBasedView();

  const {
    data: registrations,
    isLoading,
    isSuccess,
  } = useGetSalesTaxRegistrationsQuery({
    entityId,
  });

  const columns = useMemo(
    () => [
      columnHelper.accessor("state", {
        header: "State",
        cell: (info) => info.getValue(),
        size: 30,
      }),
      columnHelper.accessor("tax_detail_id", {
        header: "Tax ID",
        cell: (info) => info.getValue() || "-",
        size: 40,
      }),
    ],
    []
  );

  const table = useReactTable({
    data: registrations ? registrations : [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <DashboardLayout header={<Header v2 title="Registrations" />}>
      <Async.Root
        isLoading={isLoading}
        isEmpty={!registrations || Object.keys(registrations).length === 0}
        isSuccess={isSuccess}
      >
        <Async.Empty>
          <div className="t-h-full t-flex t-flex-col t-items-center t-justify-center">
            <div>
              <EmptySalesTax />
            </div>
            <p className="t-text-text-100 t-text-body-sm t-mb-0 t-mt-6">
              No sales tax registrations found.
            </p>
            <p className="t-text-text-30 t-text-body-sm t-mb-0 t-mt-1">
              Register for sales tax to start tracking your obligations.
            </p>
          </div>
        </Async.Empty>
        <Async.Success>
          <DashboardContainer>
            <DashboardContainer.Content>
              <div className="t-pt-4">
                <TableUI sticky={false} table={table} size="regular" />
              </div>
            </DashboardContainer.Content>
          </DashboardContainer>
        </Async.Success>
      </Async.Root>
    </DashboardLayout>
  );
};
