import classNames from "classnames";
import { ImgHTMLAttributes, useEffect, useState } from "react";

type AvatarProps = Omit<ImgHTMLAttributes<HTMLImageElement>, "src"> & {
  alt: string;
  src?: string | null;
  size?: "small" | "regular" | "large" | "extra-small" | "extra-large";
  lettersCount?: number;
  variant?: "primary" | "secondary";
};

export const Avatar = ({
  alt,
  src,
  size = "small",
  lettersCount = 1,
  variant = "secondary",
  ...props
}: AvatarProps) => {
  const [imgSrc, setImgSrc] = useState(src);

  useEffect(() => {
    setImgSrc(src);
  }, [src]);

  if (!imgSrc) {
    return (
      <span
        className={classNames(
          "t-flex t-items-center t-justify-center t-rounded-full t-text-subtitle-sm t-font-medium t-uppercase t-overflow-hidden",
          props.className || "",
          {
            "t-min-h-5 t-min-w-5 t-max-h-5 t-max-w-5": size === "extra-small",
            "t-min-h-6 t-min-w-6 t-max-h-6 t-max-w-6": size === "small",
            "t-min-h-8 t-min-w-8 t-max-h-8 t-max-w-8": size === "regular",
            "t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10": size === "large",
            "t-min-h-[58px] t-min-w-[58px] t-max-h-[58px] t-max-w-[58px]":
              size === "extra-large",
            "t-bg-neutral-10 t-text-text-black": variant === "secondary",
            "t-bg-purple-40 t-text-text": variant === "primary",
          }
        )}
        role="img"
        aria-label={alt}
      >
        {alt?.slice(0, lettersCount)?.toUpperCase()}
      </span>
    );
  }

  return (
    <img
      {...props}
      onError={(e) => {
        setImgSrc(null);
      }}
      className={classNames(
        "t-rounded-full t-overflow-hidden t-text-caption",
        props.className || "",
        {
          "t-min-h-5 t-min-w-5 t-max-h-5 t-max-w-5": size === "extra-small",
          "t-min-h-6 t-min-w-6 t-max-h-6 t-max-w-6": size === "small",
          "t-min-h-8 t-min-w-8 t-max-h-8 t-max-w-8": size === "regular",
          "t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10": size === "large",
          "t-min-h-[58px] t-min-w-[58px] t-max-h-[58px] t-max-w-[58px]":
            size === "extra-large",
        }
      )}
      src={imgSrc}
      alt={alt}
    />
  );
};
