import React from "react";

export const PoweredByGoogleIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="36"
    height="11"
    viewBox="0 0 36 11"
    fill="none"
  >
    <g clip-path="url(#clip0_5189_3473)">
      <path
        d="M4.39143 3.925V5.1H7.22705C7.15176 5.75 6.92592 6.25 6.57461 6.575C6.1731 6.975 5.52066 7.425 4.39143 7.425C2.65995 7.425 1.30488 6.025 1.30488 4.3C1.30488 2.575 2.63486 1.175 4.39143 1.175C5.31991 1.175 6.02254 1.55 6.52442 2.025L7.35252 1.2C6.64989 0.525 5.69632 0 4.39143 0C2.00751 0 0 1.925 0 4.3C0 6.675 2.00751 8.6 4.39143 8.6C5.67122 8.6 6.64989 8.175 7.4027 7.4C8.18062 6.625 8.43155 5.525 8.43155 4.65C8.43155 4.375 8.40646 4.125 8.38137 3.925H4.39143Z"
        fill="#706A85"
      />
      <path
        d="M11.9448 2.9502C10.4141 2.9502 9.13428 4.1252 9.13428 5.7252C9.13428 7.3252 10.389 8.5002 11.9448 8.5002C13.5006 8.5002 14.7553 7.3502 14.7553 5.7502C14.7553 4.1252 13.5006 2.9502 11.9448 2.9502ZM11.9448 7.4252C11.0916 7.4252 10.3639 6.7252 10.3639 5.7502C10.3639 4.7502 11.0916 4.0752 11.9448 4.0752C12.798 4.0752 13.5257 4.7502 13.5257 5.7502C13.5257 6.7252 12.798 7.4252 11.9448 7.4252Z"
        fill="#706A85"
      />
      <path
        d="M25.6713 3.5752H25.6211C25.345 3.2502 24.8181 2.9502 24.1405 2.9502C22.7353 2.9502 21.5308 4.1502 21.5308 5.7252C21.5308 7.2752 22.7353 8.5002 24.1405 8.5002C24.8181 8.5002 25.345 8.2002 25.6211 7.8752H25.6713V8.2752C25.6713 9.3252 25.0941 9.9002 24.1907 9.9002C23.4379 9.9002 22.9862 9.3752 22.7855 8.9002L21.7315 9.3502C22.0326 10.0752 22.8607 11.0002 24.2158 11.0002C25.6462 11.0002 26.8758 10.1502 26.8758 8.1002V3.1002H25.7214V3.5752H25.6713ZM24.266 7.4252C23.4128 7.4252 22.7855 6.7002 22.7855 5.7502C22.7855 4.7752 23.4379 4.0752 24.266 4.0752C25.0941 4.0752 25.7465 4.8002 25.7465 5.7752C25.7716 6.7252 25.1192 7.4252 24.266 7.4252Z"
        fill="#706A85"
      />
      <path
        d="M18.1933 2.9502C16.6626 2.9502 15.3828 4.1252 15.3828 5.7252C15.3828 7.3252 16.6375 8.5002 18.1933 8.5002C19.7492 8.5002 21.0038 7.3502 21.0038 5.7502C21.0038 4.1252 19.7492 2.9502 18.1933 2.9502ZM18.1933 7.4252C17.3401 7.4252 16.6124 6.7252 16.6124 5.7502C16.6124 4.7502 17.3401 4.0752 18.1933 4.0752C19.0465 4.0752 19.7742 4.7502 19.7742 5.7502C19.7742 6.7252 19.0465 7.4252 18.1933 7.4252Z"
        fill="#706A85"
      />
      <path d="M27.8042 0.125H29.0087V8.525H27.8042V0.125Z" fill="#706A85" />
      <path
        d="M32.7222 7.4252C32.0949 7.4252 31.6683 7.1502 31.3672 6.5752L35.1062 5.0502L34.9807 4.7252C34.7548 4.1002 34.0271 2.9502 32.5968 2.9502C31.1664 2.9502 29.9619 4.0752 29.9619 5.7252C29.9619 7.2752 31.1413 8.5002 32.7222 8.5002C34.002 8.5002 34.7298 7.72519 35.056 7.2752L34.1024 6.6502C33.7762 7.1252 33.3496 7.4252 32.7222 7.4252ZM32.647 4.0002C33.1488 4.0002 33.5754 4.2502 33.7009 4.6002L31.1915 5.6502C31.1664 4.5002 32.0196 4.0002 32.647 4.0002Z"
        fill="#706A85"
      />
    </g>
    <defs>
      <clipPath id="clip0_5189_3473">
        <rect width="35.1064" height="11" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
