export const FilledGreenTick = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.99995" cy="9.99995" r="9.99995" fill="currentColor" />
    <path
      d="M13.8192 7.56885L8.95813 12.4299L6.52759 9.99939"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
