import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { SelectAutofillCombobox } from "components/SelectAutofillCombobox";
import { Field, FieldProps, useFormikContext } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { SalesTaxBusinessPayload } from "store/apis/salesTax";

export const SalesTaxEntityOwnerDetails = () => {
  const group = useCurrentGroupContext();
  const entityId = group.entities?.[0]?.uuid;
  const { values, setFieldValue } = useFormikContext<
    SalesTaxBusinessPayload & {
      mailing_same_as_business_address: boolean;
    }
  >();

  return (
    <div className="t-flex t-justify-center">
      <div className="t-w-[650px]">
        <div>
          <p className="t-text-title-h2-bold t-mb-1">Business owner details</p>
          <p className="t-text-body-sm t-text-text-30">
            This business information is required for enabling automated tax
            remittance.
          </p>
        </div>

        <div className="t-flex t-flex-col t-gap-4">
          <div className="t-grid t-grid-cols-2 t-gap-4">
            <SelectAutofillCombobox
              type="authorized_persons"
              name="owner_id"
              onChange={(uuid) => setFieldValue("owner_id", uuid)}
              label="Owner"
              placeholder="Select owner"
              entityId={entityId}
              selected={values.owner_id}
            />
            <TextInput name="owner_ssn" label="SSN" placeholder="Enter SSN" />
          </div>

          <div className="t-grid t-grid-cols-2 t-gap-4">
            <TextInput
              name="owner_designation"
              label="Job title"
              placeholder="Enter business owner's job title"
            />
            <Field name="owner_position_start_date">
              {({ field }: FieldProps) => (
                <DateInput
                  {...field}
                  showMonthYearPicker
                  label="Position start date"
                  required
                  portalId={field.name}
                />
              )}
            </Field>
          </div>

          <div className="t-grid t-grid-cols-2 t-gap-4">
            <div className="t-flex t-flex-col">
              <MobileInput
                customSize="regular"
                name="owner_phone"
                label="Phone no"
                placeholder="Enter number"
              />
            </div>
            <Field name="owner_date_of_birth">
              {({ field }: FieldProps) => (
                <DateInput
                  {...field}
                  showMonthYearPicker
                  label="Owner's date of birth"
                  required
                  portalId={field.name}
                />
              )}
            </Field>
          </div>

          <TextInput
            name="owner_government_id"
            label="Government issued National Identification number"
            placeholder="Enter details"
          />
        </div>
      </div>
    </div>
  );
};
