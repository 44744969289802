import { profileData } from "apis/profileData";
import {
  CLICKED_EXPORT_DOCUMENT_IN_CANCEL_MODAL,
  SUBSCRIPTION_CANCELLED,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import { useSelector } from "react-redux";
import CreditCoin from "static/images/CreditCoin.svg";
import { useExportFolderMutation } from "store/apis/documents";
import { useCancelSubscriptionMutation } from "store/apis/subscriptions";
import { RootState } from "store/store";
import { ConditionalLink } from "./conditionalLink";
import { CrossListItem } from "./CrossListItem";
import { NotificationDot } from "./dashboard/sideBar/notificationDot";
import { LoadingToast } from "./design/LoadingToast";
import { Button } from "./DesignSystem/Button/Button";
import Modal from "./DesignSystem/Modal/Modal";
import { Export } from "./icons/Export";
import { InfoSolid } from "./InfoSolid";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

const SubscriptionCancelModal = ({
  isConfirmationOpen,
  onCloseConfirmationModal,
}: {
  isConfirmationOpen: boolean;
  onCloseConfirmationModal: () => void;
}) => {
  const [exportFolder, { isLoading: isExporting, reset }] =
    useExportFolderMutation();
  const group = useCurrentGroupContext();
  const currentGroupId = group.uuid;
  const entityId = useCurrentEntityId();
  const [cancelSubscription, { isLoading: cancelLoading }] =
    useCancelSubscriptionMutation();
  const { alertToast, successToast } = useToast();
  const { trackEvent } = useAnalytics();
  const { authtoken, updateUser } = useContext(authContext);
  const { credits } = useSelector((state: RootState) => state.credits);

  const {
    open: openCancelModal,
    close: closeCancelModal,
    isOpen: isCancelModal,
  } = useModal();

  const openCancelConfirmation = () => {
    onCloseConfirmationModal();
    openCancelModal();
  };

  const exportFolderCallback = async () => {
    try {
      if (currentGroupId) {
        trackEvent(CLICKED_EXPORT_DOCUMENT_IN_CANCEL_MODAL);

        const { download_url } = await exportFolder({
          groupId: currentGroupId,
        }).unwrap();
        successToast({
          title: "Export Completed",
        });
        window.open(download_url);
        reset();
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const confirmCancelSubscription = async () => {
    const userGroupId = group?.uuid;

    if (userGroupId) {
      try {
        await cancelSubscription({
          groupId: userGroupId,
          subscriptionId: authtoken.platform_subscription.uuid,
          entityId,
        }).unwrap();
        successToast({
          message: "Subscription Cancelled Successfully",
          title: "Subscription Cancelled",
        });
      } catch (error: any) {
        alertToast({ message: error?.data?.error?.message });
      }

      const profile = await profileData();
      updateUser(profile);
      trackEvent(SUBSCRIPTION_CANCELLED, {
        subscription_id: authtoken.platform_subscription.uuid,
      });
      onCloseConfirmationModal();
      closeCancelModal();
    }
  };

  return (
    <>
      <Modal.Root
        open={isConfirmationOpen}
        onOpenChange={onCloseConfirmationModal}
      >
        <Modal.Content size="regular">
          <Modal.Header>
            <Modal.Title>
              <div className="t-flex t-gap-4 t-items-center">
                <div>Cancel Subscription</div>

                <NotificationDot>
                  <span>Danger Zone</span>
                </NotificationDot>
              </div>
            </Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            {Boolean(credits) && (
              <div className="t-mb-5 t-flex t-gap-2 t-rounded t-border-purple-20 t-bg-purple-0 t-p-4">
                <img src={CreditCoin} alt="Rewards" />
                <strong className="t-text-black-100">
                  You will lose {credits} Inkle Credits
                </strong>
              </div>
            )}
            <p className="t-mb-5 t-font-semibold">Here’s what you’ll lose:</p>

            <ul className="all:unset t-mb-9 t-flex t-list-none t-flex-col t-gap-7 t-text-body">
              <CrossListItem>
                <ConditionalLink to="/chat">
                  <strong className="t-text-purple">Chat</strong>
                </ConditionalLink>{" "}
                history and any pending or upcoming Tasks.
              </CrossListItem>
              <CrossListItem>
                Clarified transactions &{" "}
                <ConditionalLink to="/books/ledger/intercoledger">
                  <strong className="t-text-purple">Interco ledger</strong>
                </ConditionalLink>{" "}
                in Inkle Books.
              </CrossListItem>
              <CrossListItem>
                Deals on softwares & introductions through{" "}
                <ConditionalLink to="/rewards">
                  <strong className="t-text-purple">Inkle Perks</strong>
                </ConditionalLink>
                .
              </CrossListItem>
              <CrossListItem>
                Access to{" "}
                <ConditionalLink to="/deadlines">
                  <strong className="t-text-purple">Deadline alerts</strong>
                </ConditionalLink>{" "}
                & compliance health checks.
              </CrossListItem>
            </ul>
            <div className="t-mb-3 t-flex t-gap-2 t-rounded t-bg-neutral-10 t-items-center t-p-4  ">
              <span className="t-text-neutral">
                <InfoSolid />
              </span>

              <span className="t-text-black-30 t-text-[0.9rem]">
                Export all your documents before unsubscribing from Inkle.
              </span>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-gap-2">
              <div className="t-ml-auto t-flex t-gap-2">
                <Button
                  customType="secondary"
                  onClick={exportFolderCallback}
                  disabled={isExporting}
                  isLoading={isExporting}
                >
                  <div className="t-flex t-items-center t-gap-3	">
                    <Export />
                    <span>Export all data</span>
                  </div>
                </Button>
                <Button onClick={openCancelConfirmation} customType="primary">
                  Continue
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
      <Modal.Root open={isCancelModal} onOpenChange={closeCancelModal}>
        <Modal.Content size="regular">
          <Modal.Header>
            <Modal.Title>
              <div>Are you sure?</div>
            </Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <p className="t-mb-5">
              Are you sure you want to cancel your subscription?
            </p>
            <ul className="all:unset t-mb-9 t-flex t-list-none t-flex-col t-gap-7 t-text-body">
              <CrossListItem>
                Your account will be permanently deleted after 30 days.
              </CrossListItem>
              {credits > 0 && (
                <CrossListItem>
                  You’ll lose access to {credits}
                  <ConditionalLink to="/rewards/referral">
                    <strong className="t-text-purple"> Inkle credits.</strong>
                  </ConditionalLink>
                </CrossListItem>
              )}
            </ul>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-gap-2">
              <div className="t-ml-auto t-flex t-gap-2">
                <Button onClick={closeCancelModal}>Close</Button>

                <Button
                  customType="danger"
                  onClick={confirmCancelSubscription}
                  isLoading={cancelLoading}
                  disabled={cancelLoading}
                >
                  Cancel subscription
                </Button>
              </div>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>

      <LoadingToast loading={isExporting} title="Preparing Export">
        This may take upto one minute
      </LoadingToast>
    </>
  );
};

export default SubscriptionCancelModal;
