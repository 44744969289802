import { Comment } from "types/Models/books";
import { emptyApi } from "./emptyApi";
import { FileObject } from "types/Models/fileObject";
import {
  CHECKLIST_ITEM_SECTION,
  CHECKLIST_ITEM_TYPE,
  CLOSING_STATUS,
  CLOSING_SUMMARY_KEY,
} from "constants/financialClosing";
import { FINANCIAL_CLOSING_CARD_TYPE } from "constants/chatType";
import qs from "qs";

export type BooksQuote = {
  cash_expense: string;
  transactions_count: number;
  stripe_transaction_count: number;
  credit_card_transaction_count: number;
  bank_transaction_count: number;
  month: string;
  year: string;
  transfers_within_entity: number;
  transfers_across_entities: number;
  billable: number;
  subscriptions: Record<string, number>;
};

export type FinancialClosingDetail = {
  uuid: string;
  accounting_method: "CASH" | "ACCRUAL";
  period: string;
  status: (typeof CLOSING_STATUS)[keyof typeof CLOSING_STATUS];
  checklist_status: {
    completed: number;
    total: number;
  };
  income_statement: FileObject | null;
  balance_sheet: FileObject | null;
  cash_flow_statement: FileObject | null;
  balance_sheet_url: null;
  income_statement_url: null;
  cash_flow_statement_url: null;
  comments: {
    notes: [];
  };
  start_date: string;
  end_date: string;
  creator: {
    name: string;
    email: string;
    is_service_team_user: boolean;
  };
  preparer: string | null;
  other_financials: FileObject[];
};

export type ChecklistItem = {
  uuid: string;
  type: (typeof CHECKLIST_ITEM_TYPE)[keyof typeof CHECKLIST_ITEM_TYPE];
  section: (typeof CHECKLIST_ITEM_SECTION)[keyof typeof CHECKLIST_ITEM_SECTION];
  checklist_item_status: string;
  resolved_by_admin: boolean;
  resolved_by_system: boolean;
  entity_bank_account: {
    uuid: string;
    name: string;
    mask: string;
  };
  file_data?: FileObject | null;
  file_url?: string;
};

type SummaryKeys =
  (typeof CLOSING_SUMMARY_KEY)[keyof typeof CLOSING_SUMMARY_KEY];

export type Summary = {
  [key in SummaryKeys]: {
    value: number;
    title: string;
  };
};

export type FinancialClosing = {
  closing_card_details: FinancialClosingDetail;
  checklist_items: ChecklistItem[];
  other_financials: FileObject[];
  summary: Summary;
};

export const financialClosingApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllFinancialClosing: build.query<
      FinancialClosingDetail[],
      {
        entityId: string;
        startDate?: string;
        endDate?: string;
        sortOrder?: "ASC" | "DSC";
        searchTerm?: string;
        closingStatus?: (typeof CLOSING_STATUS)[keyof typeof CLOSING_STATUS];
      }
    >({
      query: ({
        entityId,
        startDate,
        endDate,
        sortOrder,
        searchTerm,
        closingStatus,
      }) => {
        const query = qs.stringify(
          {
            start_date: startDate || null,
            end_date: endDate || null,
            sort_order: sortOrder,
            search_term: searchTerm || null,
            closing_status: closingStatus || null,
          },
          {
            skipNulls: true,
          }
        );
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/`,
          params: qs.parse(query),
        };
      },
      providesTags: (result) => ["FINANCIAL_CLOSING"],
    }),

    getFinancialClosing: build.query<
      FinancialClosing,
      {
        entityId: string;
        financialClosingId: string;
      }
    >({
      query: ({ entityId, financialClosingId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/`,
        };
      },
      providesTags: (result) => ["FINANCIAL_CLOSING"],
    }),

    addFinancialClosing: build.mutation<
      FinancialClosingDetail,
      {
        entityId: string;
        payload: {
          accounting_method: "CASH" | "ACCRUAL";
          start_date: string;
          end_date: string;
          task_id?: string;
        };
      }
    >({
      query: ({ entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/`,
          body: payload,
          method: "POST",
        };
      },
      invalidatesTags: (result) => (result ? ["FINANCIAL_CLOSING"] : []),
    }),

    updateFinancialClosing: build.mutation<
      FinancialClosingDetail,
      {
        entityId: string;
        financialClosingId: string;
        payload: {
          start_date?: string;
          end_date?: string;
          accounting_method?: "CASH" | "ACCRUAL";
        };
      }
    >({
      query: ({ entityId, financialClosingId, payload }) => {
        const formData = new FormData();

        if (payload.start_date) {
          formData.append("start_date", payload.start_date);
        }
        if (payload.end_date) {
          formData.append("end_date", payload.end_date);
        }
        if (payload.accounting_method) {
          formData.append("accounting_method", payload.accounting_method);
        }

        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/`,
          body: formData,
          method: "PATCH",
        };
      },
      invalidatesTags: (result) => (result ? ["FINANCIAL_CLOSING"] : []),
    }),

    addOtherFinancials: build.mutation<
      FinancialClosingDetail,
      {
        entityId: string;
        financialClosingId: string;
        payload: {
          other_financials?: File[];
          file_document_ids?: string[];
        };
      }
    >({
      query: ({ entityId, financialClosingId, payload }) => {
        const formData = new FormData();

        if (payload.other_financials) {
          payload.other_financials.forEach((file) => {
            formData.append("other_financials", file);
          });
        }

        if (payload.file_document_ids) {
          formData.append(
            "file_document_ids",
            payload.file_document_ids.join(",")
          );
        }

        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/other_financials/`,
          body: formData,
          method: "POST",
        };
      },
      invalidatesTags: (result) => (result ? ["FINANCIAL_CLOSING"] : []),
    }),

    deleteOtherFinancials: build.mutation<
      FinancialClosingDetail,
      {
        entityId: string;
        financialClosingId: string;
        financialId: string;
      }
    >({
      query: ({ entityId, financialClosingId, financialId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/other_financials/`,
          body: {
            delete_financials_ids: [financialId],
          },
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => (result ? ["FINANCIAL_CLOSING"] : []),
    }),

    updateFinancialClosingStatus: build.mutation<
      FinancialClosingDetail,
      {
        entityId: string;
        financialClosingId: string;
        payload: {
          status?: FinancialClosingDetail["status"];
        };
      }
    >({
      query: ({ entityId, financialClosingId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/`,
          body: payload,
          method: "PUT",
        };
      },
      invalidatesTags: (result) => (result ? ["FINANCIAL_CLOSING"] : []),
    }),

    updateFinancialClosingCheckList: build.mutation<
      ChecklistItem,
      {
        entityId: string;
        financialClosingId: string;
        checklistItemId: string;
        payload: {
          admin_resolved?: boolean;
          file_data?: File;
          file_url?: string;
          document_id?: string;
        };
      }
    >({
      query: ({ entityId, financialClosingId, checklistItemId, payload }) => {
        const form = new FormData();

        form.append(
          "admin_resolved",
          payload.admin_resolved === true ? "true" : "false"
        );

        if (payload.file_url) {
          form.append("file_url", payload.file_url || "");
        }

        if (payload.file_data) {
          form.append("file_data", payload.file_data || "");
        }
        if (payload.document_id) {
          form.append("document_id", payload.document_id || "");
        }

        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/checklist_items/${checklistItemId}/`,
          body: form,
          method: "PATCH",
        };
      },
      invalidatesTags: (result) => (result ? ["FINANCIAL_CLOSING"] : []),
    }),

    deleteFinancialClosing: build.mutation<
      void,
      {
        entityId: string;
        financialClosingId: string;
      }
    >({
      query: ({ entityId, financialClosingId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result) => (result ? ["FINANCIAL_CLOSING"] : []),
    }),

    getFinancialClosingSummary: build.query<
      Summary,
      {
        entityId: string;
        financialClosingId: string;
      }
    >({
      query: ({ entityId, financialClosingId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/summary/`,
        };
      },
      providesTags: (result) => [
        "FINANCIAL_CLOSING_SUMMARY",
        "FINANCIAL_CLOSING",
      ],
    }),

    updateFinancialClosingSummary: build.mutation<
      Summary,
      {
        entityId: string;
        financialClosingId: string;
        summary: Record<string, number>;
      }
    >({
      query: ({ entityId, financialClosingId, summary }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/summary/`,
          method: "PUT",
          body: { summary_data: summary },
        };
      },
      invalidatesTags: (result) =>
        result ? ["FINANCIAL_CLOSING_SUMMARY", "FINANCIAL_CLOSING"] : [],
    }),

    getFinancialClosingComments: build.query<
      { notes: Comment[] },
      {
        entityId: string;
        financialClosingId: string;
      }
    >({
      query: ({ entityId, financialClosingId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/comment/`,
        };
      },
      providesTags: (result) => ["FINANCIAL_CLOSING_COMMENTS", "NOTES"],
    }),

    addFinancialClosingComments: build.mutation<
      { notes: Comment[] },
      {
        entityId: string;
        financialClosingId: string;
        description: string;
      }
    >({
      query: ({ entityId, financialClosingId, description }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/comment/`,
          body: {
            description,
          },
          method: "POST",
        };
      },
      invalidatesTags: (result) =>
        result ? ["FINANCIAL_CLOSING_COMMENTS", "NOTES"] : [],
    }),

    sendClosingToChat: build.mutation<
      { message_id: string },
      {
        entityId: string;
        financialClosingId: string;
        card_type: keyof typeof FINANCIAL_CLOSING_CARD_TYPE;
        channel_url: string;
        message?: string;
      }
    >({
      query: ({
        entityId,
        financialClosingId,
        card_type,
        channel_url,
        message,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/financial_closing/${financialClosingId}/push_to_chat/`,
          body: {
            card_type,
            channel_url,
            message,
          },
          method: "POST",
        };
      },
      invalidatesTags: (result) => (result ? ["FINANCIAL_CLOSING"] : []),
    }),

    getBooksQuotes: build.query<
      BooksQuote[], // needs to be checked
      {
        groupId: string;
        entityId: string;
        year: string;
      }
    >({
      query: ({ groupId, entityId, year }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/quote/?year=${year}`,
        };
      },
      providesTags: ["BOOKS_QUOTES"],
    }),
    exportBooksQuotes: build.mutation<
      { excel_document_id: string },
      {
        groupId: string;
        entityId: string;
        year: string;
      }
    >({
      query: ({ groupId, entityId, year }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/quote/csv/?year=${year}`,
          method: "POST",
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllFinancialClosingQuery,
  useGetFinancialClosingQuery,
  useLazyGetFinancialClosingQuery,
  useAddFinancialClosingMutation,
  useUpdateFinancialClosingMutation,
  useDeleteFinancialClosingMutation,
  useUpdateFinancialClosingCheckListMutation,
  useGetFinancialClosingCommentsQuery,
  useGetFinancialClosingSummaryQuery,
  useAddFinancialClosingCommentsMutation,
  useUpdateFinancialClosingSummaryMutation,
  useUpdateFinancialClosingStatusMutation,
  useGetBooksQuotesQuery,
  useExportBooksQuotesMutation,
  useSendClosingToChatMutation,
  useAddOtherFinancialsMutation,
  useDeleteOtherFinancialsMutation,
} = financialClosingApi;
