import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { PlusIcon } from "components/icons/PlusIcon";
import { DD_MMM_YYYY } from "constants/date";
import {
  FIXED_TRANSACTION_COLUMNS,
  TRANSACTION_COLUMNS,
  TRANSACTION_COLUMNS_OPTIONS,
} from "constants/transaction";
import dayjs from "dayjs";
import { ChangeEvent } from "react";
import { createColumnHelper } from "react-table-8.10.7";
import type {
  Transaction,
  Transactions as TransactionsType,
} from "types/Models/books";
import { currency } from "utils/Currency";
import TransactionColumn from "./TransactionColumn";
import { Paperclip } from "components/icons/Chat/Paperclip";
import { ChatTeardropDots } from "components/icons/ChatTeardropDots";

export type TransactionTableColumns = {
  COLUMNS: (keyof typeof TRANSACTION_COLUMNS)[];
};

export const TransactionTableHandler = ({
  values,
  updateFilter,
}: {
  values: TransactionTableColumns;
  updateFilter: <S extends "COLUMNS">(
    name: S,
    newValue: TransactionTableColumns[S]
  ) => void;
}) => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;

    if (checked) {
      updateFilter("COLUMNS", [
        ...values.COLUMNS,
        name as keyof typeof TRANSACTION_COLUMNS,
      ]);
      return;
    }

    updateFilter(
      "COLUMNS",
      values.COLUMNS.filter((column) => column !== name)
    );
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <Button customType="icon" size="small">
          <PlusIcon size="12" />
        </Button>
      </Dropdown.Trigger>
      <Dropdown.Content>
        {TRANSACTION_COLUMNS_OPTIONS.map((option) => {
          const { label, value } = option;

          return (
            <Dropdown.Item key={value} onSelect={(e) => e.preventDefault()}>
              <Checkbox
                disabled={FIXED_TRANSACTION_COLUMNS.includes(value)}
                label={
                  <div className="t-lowercase first-letter:t-uppercase">
                    {label}
                  </div>
                }
                name={value}
                checked={values.COLUMNS.includes(value)}
                onChange={onChange}
              />
            </Dropdown.Item>
          );
        })}
      </Dropdown.Content>
    </Dropdown.Root>
  );
};

export const txnColumnHelper = createColumnHelper<TransactionsType>();

export const transactionColumn = {
  select: () =>
    txnColumnHelper.display({
      id: TRANSACTION_COLUMNS.SELECT,
      size: 1,
      enableSorting: false,
      header: ({ table }) => (
        <Checkbox
          {...{
            checked: table.getIsAllRowsSelected(),
            indeterminate: table.getIsSomeRowsSelected(),
            onChange: (e) => {
              table.toggleAllRowsSelected(e.target.checked);
            },
          }}
        />
      ),

      cell: ({ row }) => (
        <Checkbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
            key: row.id,
          }}
          aria-label={`Select transaction, source: ${
            row.original.transaction.merchant || "Unassingned"
          }, amount: ${currency({
            amount: Number(row.original.transaction.amount || 0),
          })}, and description: ${
            row.original.transaction.description || "Empty"
          }`}
          onClick={(e) => e.stopPropagation()}
        />
      ),
    }),

  date: () =>
    txnColumnHelper.accessor("transaction.date", {
      id: TRANSACTION_COLUMNS.DATE,
      size: 120,
      header: "Date",
      cell: (info) => (
        <div className="t-text-subtext t-text-text-30">
          {dayjs(info.getValue()).format(DD_MMM_YYYY)}
        </div>
      ),
    }),

  from: () =>
    txnColumnHelper.accessor("transaction.from", {
      id: TRANSACTION_COLUMNS.FROM,
      size: 68,
      enableSorting: false,
      header: () => (
        <div className="t-flex t-justify-center t-w-full">Source</div>
      ),
      cell: (info) => {
        const transaction = info.row.original.transaction;

        return <TransactionColumn.Source transaction={transaction} />;
      },
    }),

  merchant: () =>
    txnColumnHelper.accessor("transaction.merchant", {
      id: TRANSACTION_COLUMNS.VENDOR,
      size: 210,
      header: "TO/FROM",
      cell: (info) => {
        return (
          <div className="t-w-60">
            <TransactionColumn.Merchant info={info} showSplitVendors />
          </div>
        );
      },
    }),

  description: () =>
    txnColumnHelper.accessor("transaction.description", {
      id: TRANSACTION_COLUMNS.DESCRIPTION,
      size: 164,
      header: "DESCRIPTION",
      enableSorting: false,
      cell: (info) => {
        const description = info.getValue();
        return (
          <ConditionalToolTip
            condition={
              <div className="t-max-w-60 t-word-break">{description}</div>
            }
          >
            <span className="t-line-clamp-1 t-break-all">
              {description || "-"}
            </span>
          </ConditionalToolTip>
        );
      },
    }),

  amount: () =>
    txnColumnHelper.accessor("transaction.amount", {
      id: TRANSACTION_COLUMNS.AMOUNT,
      size: 100,
      header: () => (
        <span className="t-flex t-justify-end t-ml-auto">Amount</span>
      ),
      cell: (info) => {
        const amount = info.getValue();
        const {
          transaction: { is_credit_card },
        } = info.row.original || {};

        return (
          <TransactionColumn.Amount
            amount={amount}
            isCreditCard={is_credit_card}
          />
        );
      },
    }),

  category: () =>
    txnColumnHelper.accessor("transaction.category", {
      id: TRANSACTION_COLUMNS.CATEGORY,
      size: 240,
      header: "Category",
      enableSorting: false,
      cell: (info) => {
        return (
          <div className="t-w-60">
            <TransactionColumn.Category
              info={info}
              tooltip
              truncate
              showSplitActions
            />
          </div>
        );
      },
    }),

  invoice: ({ onRowClick }: { onRowClick: (uuid: string, e: Event) => void }) =>
    txnColumnHelper.accessor("invoices", {
      id: TRANSACTION_COLUMNS.INVOICE,
      size: 50,
      header: "",
      enableSorting: false,
      cell: (info) => {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onRowClick(
                info.row.original.transaction.uuid,
                new Event("click")
              );
            }}
            customType="ghost_icon"
            size="small"
          >
            <div className="t-flex t-items-center t-text-text-30 t-text-caption t-gap-0.5">
              <Paperclip size="16" />
              {info.getValue().length || ""}
            </div>
          </Button>
        );
      },
    }),

  comments: ({
    onRowClick,
  }: {
    onRowClick: (uuid: string, e: Event) => void;
  }) =>
    txnColumnHelper.accessor("comments_count", {
      id: TRANSACTION_COLUMNS.COMMENTS,
      size: 50,
      header: "",
      enableSorting: false,
      cell: (info) => {
        return (
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onRowClick(
                info.row.original.transaction.uuid,
                new Event("click")
              );
            }}
            customType="ghost_icon"
            size="small"
          >
            <div className="t-flex t-items-center t-text-text-30 t-text-caption t-gap-0.5">
              <ChatTeardropDots />
              {info.getValue() || ""}
            </div>
          </Button>
        );
      },
    }),

  options: ({
    onRequestInfo,
    sendToChat,
    onRowClick,
    onDuplicate,
  }: {
    onRequestInfo: ({ transactionIds }: { transactionIds: string }) => void;
    sendToChat: ({ transactionIds }: { transactionIds: string[] }) => void;
    onRowClick: (uuid: string, e: Event) => void;
    onDuplicate: (transaction: Transaction) => void;
  }) =>
    txnColumnHelper.accessor("transaction", {
      id: TRANSACTION_COLUMNS.OPTIONS,
      size: 50,
      header: "",
      enableSorting: false,
      cell: (info) => (
        <TransactionColumn.Actions
          onDuplicate={onDuplicate}
          info={info}
          onRowClick={onRowClick}
          sendToChat={(transactionIds) =>
            sendToChat({ transactionIds: [transactionIds] })
          }
          onRequestInfo={onRequestInfo}
        />
      ),
    }),
};
