export const Revenue = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_541_4923)">
        <path
          d="M3.75 14.8333H16V16H3.75C2.78517 16 2 15.2148 2 14.25V2H3.16667V14.25C3.16667 14.5714 3.42858 14.8333 3.75 14.8333ZM11.9167 4.91667V6.08333H14.0085L10.75 9.34183L8.41667 7.0085L4.50425 10.9209L5.32908 11.7458L8.41667 8.65817L10.75 10.9915L14.8333 6.90817V9H16V4.91667H11.9167Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_541_4923">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
