import React, { useEffect } from "react";

// css
import "../static/styles/components/verifying.css";

import Left from "components/landingContent";
import { useDispatch } from "react-redux";
import { setOnboardingStep } from "store/slices/onboarding";
import VerificationIllustration from "static/images/VerificationIllustration.svg";
import OnboardingHeader from "components/dashboard/OnboardingHeader";
import { useRedirectOnAuth } from "hooks/useRedirectOnAuth";
import { Button } from "components/DesignSystem/Button/Button";
import { ConditionalLink } from "components/conditionalLink";
import { BOOK_A_CALL } from "constants/onBoarding";

const Verifying = () => {
  const dispatch = useDispatch();
  useRedirectOnAuth();

  useEffect(() => {
    dispatch(setOnboardingStep(3));
  }, []);

  return (
    <>
      <OnboardingHeader />
      <div className="t-flex t-h-screen t-mt-[4%] !t-h-4/5 sm:-t-mt-12 sm:!t-justify-end">
        <Left className="sm:t-hidden" />
        <div className="t-flex !t-h-full t-w-2/3 t-items-center t-justify-center sm:t-w-full">
          <div className="displaymessagebody t-w-1/2 sm:t-w-full sm:t-px-8">
            <img src={VerificationIllustration} alt="VerifyingIcon" />
            <span className="verifyingTitle">Thanks - all done!</span>
            <span className="t-text-text-60 t-text-body t-mt-3">
              Please bear with us for a few hours while we verify your account.
              In the meantime, feel free to schedule a demo call with us.
            </span>
            <ConditionalLink to={BOOK_A_CALL} className="t-mt-6">
              <Button customType="primary">Book a call</Button>
            </ConditionalLink>
          </div>
        </div>
      </div>
    </>
  );
};
export default Verifying;
