import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  ComboboxProps,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import ReactCountryFlag from "react-country-flag";
import { useHistory } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import { entitiesSortedByCountry } from "utils/entitiesSortedByCountry";

type EntityComboxProps = Omit<ComboboxProps, "value" | "onChange"> & {
  onChange: (value: string) => void;
  right?: number;
  showOnlyUSEntities: boolean;
  disableNonUsEntities: boolean;
  isEntitySelected: string;
  showGoToEntity: boolean;
  value: string;
  left?: number;
};

const Label = ({
  country,
  country_code,
  name,
}: {
  name: string;
  country_code: string;
  country: string;
}) => (
  <div className="t-flex t-items-center t-gap-2 group-[[data-disabled]]:t-text-neutral-30">
    <ReactCountryFlag countryCode={country_code} svg title={country} />
    <span className="t-truncate t-max-w-36">{name}</span>
  </div>
);

const BareEntityCombobox = ({
  size,
  isDisabled,
  isLoading,
  onChange,
  right = 0,
  value,
  showOnlyUSEntities,
  disableNonUsEntities,
  isEntitySelected,
  showGoToEntity,
  left,
}: EntityComboxProps) => {
  const { entities } = useCurrentGroupContext();
  const { sortedEntities, usEntities } = entitiesSortedByCountry(entities);
  const displayedEntities = showOnlyUSEntities ? usEntities : sortedEntities;
  const history = useHistory();

  const entityOptions = displayedEntities.map(
    ({ name, uuid, country, country_code }) => {
      const disableNonUsEntity = disableNonUsEntities && country_code !== "US";

      return {
        isDisabled: disableNonUsEntity,
        label: (
          <ConditionalToolTip
            condition={disableNonUsEntity && "We only support US entities."}
          >
            <span>
              <Label
                name={name}
                country_code={country_code}
                country={country}
              />
            </span>
          </ConditionalToolTip>
        ),
        value: uuid,
      };
    }
  );
  const selectedEnity = isEntitySelected
    ? {
        label: (
          <Label
            country={
              displayedEntities.find(({ uuid }) => uuid === value)?.country ||
              ""
            }
            name={
              displayedEntities.find(({ uuid }) => uuid === value)?.name || ""
            }
            country_code={
              displayedEntities.find(({ uuid }) => uuid === value)
                ?.country_code || ""
            }
          />
        ),
        value: displayedEntities.find(({ uuid }) => uuid === value)?.uuid || "",
      }
    : null;

  const actionComponent = showGoToEntity ? (
    <Button customType="link" onClick={() => history.push("/tax/entities")}>
      Go to entities
    </Button>
  ) : (
    <></>
  );

  return (
    <span>
      <Combobox
        actions={actionComponent}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        size={size}
        tooltip
        creatable={false}
        isClearable={false}
        options={entityOptions}
        isDisabled={isDisabled}
        // menuIsOpen={true}
        isLoading={isLoading}
        onChange={(
          values: MultiValue<OptionData> | SingleValue<OptionData>
        ) => {
          if (values instanceof Array) {
            return;
          }

          if (!values?.value) {
            return;
          }
          onChange(values.value);
        }}
        styles={{
          menu: (base) => ({
            ...base,
            width: "230px",
            zIndex: 1210,
            ...(left !== undefined && { left }),
            right: right,
          }),
        }}
        value={selectedEnity}
      />
    </span>
  );
};

export default BareEntityCombobox;
