import { Button } from "components/DesignSystem/Button/Button";
import { Switch } from "components/DesignSystem/Switch/Switch";
import { CrossedEye } from "components/icons/CrossedEye";
import { useShow } from "hooks/useShow";
import { createContext, ReactNode, useContext } from "react";

const Context = createContext({
  isShown: true,
  toggle: () => {},
});

export const HideBalancesAndAmountsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { isShown, toggle } = useShow(true);

  return (
    <Context.Provider value={{ isShown, toggle }}>{children}</Context.Provider>
  );
};

export const useShowBalancesAndAmounts = () => {
  if (!useContext(Context)) {
    throw new Error(
      "useShowBalancesAndAmounts must be used within a HideBalancesAndAmountsProvider"
    );
  }
  return useContext(Context);
};

export const HideBalancesAndAmounts = () => {
  const { isShown, toggle } = useShowBalancesAndAmounts();

  return (
    <Button size="small" onClick={toggle}>
      <div className="t-flex t-gap-1.5 t-items-center">
        <CrossedEye /> Hide balances & amounts{" "}
        <Switch size="small" checked={!isShown} />
      </div>
    </Button>
  );
};

export const HideableAmount = ({ children }: { children: ReactNode }) => {
  const { isShown } = useShowBalancesAndAmounts();

  return <>{isShown ? children : "$****"}</>;
};
