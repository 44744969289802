import { CartModal } from "components/CartModal/CartModal";
import { Button } from "components/DesignSystem/Button/Button";
import { CartPaymentIcon } from "components/icons/CartPaymentIcon";
import { SmallWarningInfo } from "components/icons/SmallWarningInfo";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { TaxActionItemType } from "store/apis/actionItems";

export const CartActionItem = ({ item }: { item: TaxActionItemType }) => {
  const { isOpen, open, close } = useModal();
  const { entities } = useCurrentGroupContext();
  const entity = entities.find((entity) => entity.uuid === item.entity_id);

  return (
    <div className="t-flex t-justify-between t-items-center t-py-4 t-px-0 t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 last:t-border-b-0">
      <div className="t-flex t-gap-2 t-items-center">
        <div className="t-relative t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10 t-border t-border-solid t-border-neutral-0 t-rounded-full t-flex t-items-center t-justify-center">
          <CartPaymentIcon />
          <div className="t-absolute -t-right-0.5 -t-bottom-0.5 t-text-orange">
            <SmallWarningInfo />
          </div>
        </div>
        <div className="t-space-y-1">
          <div className="t-text-subtext-sm t-text-text-100">{item.title}</div>
          {entity && (
            <div className="t-flex t-gap-1.5 t-items-center">
              <div className="t-rounded-sm">
                <ReactCountryFlag
                  svg
                  countryCode={entity.country_code}
                  style={{ height: 16, width: 16 }}
                />
              </div>
              <div className="t-text-body-sm t-text-text-30 t-mt-0.5">
                {entity.name}
              </div>
            </div>
          )}
        </div>
      </div>

      <Button size="small" onClick={open} customType="primary-outlined">
        Pay
      </Button>
      <CartModal
        open={isOpen}
        onClose={close}
        entityIdFromProps={item.entity_id}
      />
    </div>
  );
};
