import ReactCountryFlag from "react-country-flag";
import { Button } from "components/DesignSystem/Button/Button";
import { ArrowLeft } from "components/icons/ArrowLeft";
import { FilledGreenTick } from "components/icons/FilledGreenTick";

const FilledGreenWhiteTick = () => (
  <div className="t-flex t-items-center t-gap-1 t-text-green-70 t-w-6 t-h-6">
    <FilledGreenTick />
    <span></span>
  </div>
);

export const TPPaymentBanner = ({
  isOnboardingPending,
  sender,
  receiver,
  steps,
  isOnboardingPendingHandler,
}: {
  isOnboardingPending: boolean;
  sender?: string | null;
  receiver?: string | null;
  steps: string[];
  isOnboardingPendingHandler: () => void;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-10 t-w-full t-bg-[url('static/images/TPPreOnboardingBg.svg')] t-bg-no-repeat t-bg-cover t-py-6 t-ps-8 t-rounded-xl t-border t-border-solid t-border-b-purple-10 t-border-l-0 t-border-r-0 t-border-t-0">
      <div className="t-w-full t-flex t-justify-between t-py-6">
        <div className="t-flex t-flex-col t-gap-3">
          {!isOnboardingPending && (
            <div className="t-flex t-bg-white t-rounded-3xl t-items-center t-w-fit t-p-2 t-gap-4 t-px-3 t-text-body t-text-text-30">
              <div className="t-flex t-w-fit t-items-center t-gap-1">
                <ReactCountryFlag
                  countryCode="US"
                  svg
                  style={{
                    fontSize: "1.4rem",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                  title="US"
                />
                <span>{sender ? sender : "Acme Inc"}</span>
              </div>
              <div className="t-flex t-items-center t-gap-1 t-rotate-180 t-text-neutral-50">
                <ArrowLeft />
              </div>
              <div className="t-flex t-w-fit t-items-center t-gap-1">
                <ReactCountryFlag
                  countryCode="IN"
                  svg
                  style={{
                    fontSize: "1.4rem",
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                  title="IN"
                />
                <span>{receiver ? receiver : "Acme Ind Pvt Ltd"}</span>
              </div>
            </div>
          )}
          <div className="t-text-subtitle-lg">
            {isOnboardingPending
              ? "Get started with inter-company transactions for your entities"
              : "Transfer funds between your entities in just three steps"}
          </div>
          <div>
            {steps?.map((step, index) => (
              <div key={index} className="t-flex t-items-center t-gap-2">
                <div className="t-text-body-sm t-font-bold t-text-text-60">
                  Step {index + 1} :
                </div>
                <div className="t-text-body-sm t-text-text-60">{step}</div>
              </div>
            ))}
          </div>
          {isOnboardingPending && (
            <div>
              <Button
                onClick={isOnboardingPendingHandler}
                customType="primary"
                size="small"
              >
                Get Started
              </Button>
            </div>
          )}
        </div>
        <div className="t-flex t-flex-col t-items-end t-gap-2 t-justify-around t-pe-[1px]">
          <div className="t-text-body-sm t-text-text- t-bg-white t-py-2 t-px-4 t-rounded-s-3xl t-pe-8 t-flex t-items-center t-gap-2">
            <FilledGreenWhiteTick />
            0.25% FX markup over mid-market
          </div>
          <div className="t-text-body-sm t-text-text- t-bg-white t-py-2 t-px-4 t-pe-16 t-rounded-s-3xl t-flex t-items-center t-gap-2">
            <FilledGreenWhiteTick />
            Money moved via top 4 global banks
          </div>
          <div className="t-text-body-sm t-text-text- t-bg-white t-py-2 t-px-4 t-rounded-s-3xl t-pe-8 t-flex t-items-center t-gap-2">
            <FilledGreenWhiteTick />
            Free FIRA available on your dashboard
          </div>
        </div>
      </div>
    </div>
  );
};
