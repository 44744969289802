import { ConditionalLink } from "components/conditionalLink";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useParams, useRouteMatch } from "react-router-dom";
import BankCircular from "static/images/BankCircular.svg";
import MapPinCircular from "static/images/MapPinCircular.svg";
import UserListCircular from "static/images/UserListCircular.svg";
import { useGetAutofillsCountQuery } from "store/apis/autofill";

type RouteParams = {
  groupId?: string;
};

export const AutofillHome = () => {
  usePageTitle("Autofill");
  const { url } = useRouteMatch();
  const params: RouteParams = useParams();
  const { uuid } = useCurrentGroupContext();

  let groupId = "";
  if (!params?.groupId) {
    groupId = uuid;
  } else {
    groupId = params?.groupId;
  }

  const { data: autofillCount } = useGetAutofillsCountQuery(
    {
      groupId,
    },
    { skip: !groupId }
  );

  return (
    <>
      <div className="t-flex t-gap-3 t-w-full t-h-full">
        <ConditionalLink
          to={`${url}/addresses`}
          className="all:unset t-p-5 t-border-solid t-rounded-lg t-shadow-entity-card t-border-neutral-0 t-w-[200px] t-h-[128px] t-flex t-flex-col t-gap-4 t-cursor-pointer hover:t-border-purple-20 hover:t-shadow"
        >
          <div className="-t-ml-1">
            <img src={MapPinCircular} alt="Map" height="32px" width="32px" />
          </div>
          <div className="t-flex t-flex-col t-gap-0.5">
            <div className="t-text-body-sm">Addresses</div>
            <div className="t-text-title-h2-bold ">
              {autofillCount?.address_count}
            </div>
          </div>
        </ConditionalLink>
        <ConditionalLink
          to={`${url}/bank_accounts`}
          className="all:unset t-p-5 t-border-solid t-rounded-lg t-shadow-entity-card t-border-neutral-0 t-w-[200px] t-h-[128px] t-flex t-flex-col t-gap-4 t-cursor-pointer hover:t-border-purple-20 hover:t-shadow"
        >
          <div className="-t-ml-1">
            <img src={BankCircular} alt="bank" height="32px" width="32px" />
          </div>
          <div className="t-flex t-flex-col t-gap-0.5">
            <div className="t-text-body-sm">Banks</div>
            <div className="t-text-title-h2-bold ">
              {autofillCount?.bank_count}
            </div>
          </div>
        </ConditionalLink>
        <ConditionalLink
          to={`${url}/authorized_persons`}
          className="all:unset t-p-5 t-border-solid t-rounded-lg t-shadow-entity-card t-border-neutral-0 t-w-[200px] t-h-[128px] t-flex t-flex-col t-gap-4 t-cursor-pointer hover:t-border-purple-20 hover:t-shadow"
        >
          <div className="-t-ml-1">
            <img src={UserListCircular} alt="bank" height="32px" width="32px" />
          </div>
          <div className="t-flex t-flex-col t-gap-0.5">
            <div className="t-text-body-sm">Personas</div>
            <div className="t-text-title-h2-bold ">
              {autofillCount?.persona_count}
            </div>
          </div>
        </ConditionalLink>
      </div>
    </>
  );
};
