import {
  LeftBarItem,
  LeftBarTag,
  useLeftBar,
} from "components/Sidebar/LeftBar";
import { useMessageCount } from "hooks/useMessageCount";
import { ComponentProps } from "react";
import { ChatIcon } from "components/icons/LeftNav/ChatIcon";
import { useLocation } from "react-router-dom";
import { parse, stringify } from "qs";

export const ChatLeftNav = (
  props: Partial<ComponentProps<typeof LeftBarItem>>
) => {
  const messageCount = useMessageCount();
  const { open } = useLeftBar();

  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const IconWithBadge = () => {
    return (
      <div className="t-relative">
        <ChatIcon />

        {Boolean(messageCount?.messages && !open) &&
          messageCount?.messages &&
          messageCount?.messages > 0 && (
            <LeftBarTag type="closed">
              {messageCount.messages > 99 ? "+99" : messageCount.messages}
            </LeftBarTag>
          )}
      </div>
    );
  };
  return (
    <LeftBarItem
      suffix={
        Boolean(messageCount?.messages) &&
        messageCount?.messages &&
        messageCount?.messages > 0 && (
          <LeftBarTag type="blue">
            {messageCount.messages > 99 ? "+99" : messageCount.messages}
          </LeftBarTag>
        )
      }
      to={`/chat${search}`}
      icon={IconWithBadge}
      {...props}
    >
      Chat
    </LeftBarItem>
  );
};
