import React from "react";

export const SmallArrowUpRight = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <g clip-path="url(#clip0_6225_16787)">
      <path
        d="M11 0H5.00002V1H10.293L0.0215149 11.2715L0.728515 11.9785L11 1.707V7H12V1C12 0.4485 11.5515 0 11 0Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_6225_16787">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
