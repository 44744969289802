import classNames from "classnames";
import { ParentSelector } from "components/ChartOfAccounts/ParentSelector";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { CategoryLabel } from "components/design/CategoryLabel";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { AvatarGroup } from "components/DesignSystem/AvatarGroup/AvatarGroup";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import Dropdown, {
  DropdownItemProps,
} from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowDown } from "components/icons/ArrowDown";
import { BankLogo } from "components/icons/BankLogo";
import { Cross } from "components/icons/Cross";
import { EditModal } from "components/Transaction/EditModal";
import { LinkTransaction } from "components/Transaction/LinkTransaction";
import {
  ChangeCategory,
  Uncategorise,
} from "components/Transaction/UnLinkTransaction";
import { STANDARD } from "constants/bookkeeping";
import { TRANSACTION_SOURCE } from "dictionaries";
import { AnimatePresence, motion } from "framer-motion";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import {
  ClearIndicatorProps,
  components,
  ControlProps,
  SingleValue,
} from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { CellContext } from "react-table-8.10.7";
import ArrowElbowDownRight from "static/images/ArrowElbowDownRight.svg";
import CardAccount from "static/images/CardAccount.svg";
import ThreeDots from "static/images/ThreeDots.svg";
import {
  useAssignExistingMerchantMutation,
  useCreateMerchantMutation,
  useDeleteMerchantFromTxnMutation,
} from "store/apis/merchant";
import {
  useAddTransactionInvoiceMutation,
  useChangeTransactionStatusMutation,
  useUpdateTransactionMutation,
} from "store/apis/transactions";
import {
  openSimilarTxnsModal,
  setUpdateTxnResponse,
} from "store/slices/similarTransactions";
import {
  openDeleteSplitTransactionModal,
  openEditSplitTransactionModal,
  openSplitTransactionModal,
} from "store/slices/splitTransaction";
import { Transaction, Transactions, TxnCategories } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { FileIcon } from "utils/fileTypeIcon";
import { MerchantSelector } from "./MerchantSelector";
import { RemoveSplitActionModal } from "./RemoveSplitActionModal";
import { DeleteTransaction } from "./Slider/DeleteTransaction";
import { SplitCategoryOnPopover } from "./SplitCategoryOnPopover";
import { FileInput, FileType } from "components/FileInput/FileInput";
import { ActionDropdown } from "./ActionDropdown";
import { LinkInkleInvoiceModal } from "./LinkInkleInvoiceModal";
import * as Popover from "@radix-ui/react-popover";
import { AddCommentPop } from "components/AddComment/AddComment";
import { AddCommentPopoverRoot } from "components/AddCommentPopoverRoot/AddCommentPopoverRoot";
import { InternalTransaction } from "./AddTransaction/AddTransactionManuallyModal";
import { CategoryStatus } from "./CategoryStatus";

type CategoryOption = {
  value: string;
  label: JSX.Element;
  data: string;
  isDisabled: boolean;
  categoryType?: string;
  options?: CategoryOption[];
  indentLevel: number;
};

const getTextAndRemaining = ({
  mainDetail,
  splitDetails,
  splitCount,
}: {
  mainDetail?: string;
  splitDetails?: string;
  splitCount?: number;
}) => {
  const splitDetailNames = splitDetails?.split(",");
  const nameToShow = mainDetail || splitDetailNames?.[0];
  const extraNumber = mainDetail ? splitCount : (splitCount as number) - 1;
  return { nameToShow, extraNumber };
};

const getMerchantTextAndRemaining = ({
  mainDetail,
  splitDetails,
  splitCount,
}: {
  mainDetail?: string;
  splitDetails?: Transaction[] | null;
  splitCount?: number;
}) => {
  const splitDetailNames = splitDetails?.map(
    (transaction) => transaction.merchant
  );
  const nameToShow = mainDetail || splitDetailNames?.[0];
  const extraNumber = mainDetail ? splitCount : (splitCount as number) - 1;
  return { nameToShow, extraNumber };
};

export const getCOALabel = ({ account }: { account: TxnCategories }) => {
  return account.category_type === STANDARD
    ? account.identifier.toString().concat(" - ").concat(account.name)
    : account.name;
};

export type SourceTransaction = {
  transactionInfo: Pick<
    Transactions["transaction"],
    | "amount"
    | "from"
    | "logo"
    | "merchant"
    | "date"
    | "uuid"
    | "memo"
    | "category"
    | "linked_transaction"
  >;
  category: {
    categoryType: string;
    categoryName: string;
    categoryId: string;
    data: string;
  };
};

export const TransactionComboboxControl = ({
  children,
  ...props
}: ControlProps<OptionData>) => {
  const {
    selectProps: { value },
  } = props;

  return (
    <components.Control
      {...props}
      className={classNames(
        "t-px-2 t-border t-border-solid hover:t-border-neutral-10 hover:t-bg-surface-grey t-rounded !t-cursor-pointer",
        {
          " t-border-neutral-0 t-bg-surface-lighter-grey": Boolean(!value),
          "t-border-surface-transparent": Boolean(value),
        }
      )}
    >
      {children}
    </components.Control>
  );
};

export const DropdownIndicator = () => {
  return (
    <div className="group-data-state-open:t-rotate-180 group-hover:t-opacity-100 t-opacity-0 group-data-state-open:t-opacity-100 t-flex t-items-center t-w-0 group-hover:t-w-auto">
      <ArrowDown color="currentColor" />
    </div>
  );
};

export const TransactionComboboxClearIndicator = (
  props: ClearIndicatorProps<OptionData>
) => {
  return (
    <components.ClearIndicator {...props}>
      <button
        type="button"
        className="all:unset t-mr-1 group-hover:t-opacity-100 t-opacity-0 t-w-0 group-hover:t-w-auto t-flex t-items-center"
        tabIndex={-1}
      >
        <Cross color="currentColor" />
      </button>
    </components.ClearIndicator>
  );
};

const AttachInvoice = ({ transactionId }: { transactionId: string }) => {
  const { successToast, alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [addInvoice, { isLoading: isUploading }] =
    useAddTransactionInvoiceMutation();

  const onDrop = async (files: FileType[]) => {
    try {
      await addInvoice({
        groupId,
        previewTxnId: transactionId,
        files,
      }).unwrap();
      successToast({ message: "Invoice added" });
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <FileInput
      onDrop={onDrop}
      disabled={isUploading}
      isUploading={isUploading}
      variant="icon"
    />
  );
};

const Source = ({ transaction }: { transaction: Transaction }) => {
  const [hover, setHover] = useState(false);
  const { from, is_credit_card } = transaction;
  const { bank_account } = from || {};
  const { bank_brand } = bank_account || {};
  const { logo_url } = bank_brand || {};

  const mask = from?.bank_account?.mask
    ? `•••• ${from?.bank_account?.mask}`
    : "";

  return (
    <ConditionalToolTip
      condition={
        from &&
        `${from?.bank_account?.nickname} ${mask} (${
          TRANSACTION_SOURCE[from?.source!]
        })`
      }
    >
      <motion.span
        className="t-flex t-justify-center"
        whileHover={
          is_credit_card
            ? {
                transition: {
                  duration: 0.5,
                  ease: "easeInOut",
                },
                rotateY: 180,
              }
            : {}
        }
        onHoverStart={() => setHover(true)}
        onHoverEnd={() => setHover(false)}
      >
        {!hover && is_credit_card ? (
          <Avatar src={CardAccount} alt="Bank" />
        ) : (
          <AnimatePresence>
            <motion.span
              animate={
                hover && is_credit_card
                  ? {
                      transition: {
                        duration: 0.5,
                        ease: "easeInOut",
                      },
                      rotateY: 180,
                    }
                  : {}
              }
            >
              {logo_url ? (
                <Avatar
                  src={logo_url}
                  alt={from?.bank_account?.mask || "Bank"}
                />
              ) : (
                <BankLogo />
              )}
            </motion.span>
          </AnimatePresence>
        )}
      </motion.span>
    </ConditionalToolTip>
  );
};

export const MerchantSelectDelete = ({
  transactions,
  outline,
  onlyLogo,
  splitTransactionsMerchantCount,
  splitTransactionVendors,
  showSplitVendors,
}: {
  transactions: Transactions;
  outline?: boolean;
  onlyLogo?: boolean;
  splitTransactionsMerchantCount?: number;
  splitTransactionVendors?: Transaction[] | null;
  showSplitVendors?: boolean;
}) => {
  const { transaction } = transactions;
  const { successToast, alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const dispatch = useDispatch();
  const removeSplitTxns = useModal();
  const [merchantIdToAssignTransaction, setMerchantIdToAssignTransaction] =
    useState("");

  const [assignExistingMerchantToTransaction, { isLoading: isAssiging }] =
    useAssignExistingMerchantMutation();

  const [createMerchant, { isLoading: isCreatingMerchant }] =
    useCreateMerchantMutation();

  const [deleteMerchant, { isLoading: isDeleting }] =
    useDeleteMerchantFromTxnMutation();

  const { logo, merchant, uuid: transactionId } = transaction;

  const showSplitMerchantCount = Boolean(
    splitTransactionsMerchantCount && splitTransactionsMerchantCount > 0
  );
  const isSplitTransaction =
    transactions?.split_transactions &&
    transactions.split_transactions.length > 0;

  const openRemoveSplitTransaction = ({
    selectedMerchantId,
  }: {
    selectedMerchantId?: string;
  }) => {
    if (!selectedMerchantId) {
      dispatch(openDeleteSplitTransactionModal(transactions));
      return;
    }

    setMerchantIdToAssignTransaction(selectedMerchantId);
    removeSplitTxns.open();
  };

  const handleMerchantChange = async (values: SingleValue<any>) => {
    if (isSplitTransaction) {
      openRemoveSplitTransaction({ selectedMerchantId: values?.merchantId });
      return;
    } else {
      await merchantChange({ merchantId: values?.merchantId });
    }
  };

  const merchantChange = async ({ merchantId }: { merchantId?: string }) => {
    if (merchantId) {
      const payload = { merchant_id: merchantId };
      try {
        await assignExistingMerchantToTransaction({
          groupId,
          transactionId,
          payload,
        }).unwrap();
        successToast({ message: "Vendor assigned" });
      } catch (error) {
        alertToast({
          message: (error as BackendError)?.data?.error?.message,
        });
      }
    } else {
      try {
        await deleteMerchant({ groupId, transactionId }).unwrap();
        successToast({ message: "Vendor unassigned" });
      } catch (error) {
        alertToast({
          message: (error as BackendError)?.data?.error?.message,
        });
      }
    }
  };

  const onCreateMerchant = async (name: string) => {
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("txn_uuid", transactionId);

      await createMerchant({
        groupId,
        entityId,
        payload: formData,
      }).unwrap();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const closeRemoveSplitTxns = () => {
    removeSplitTxns.close();
    setMerchantIdToAssignTransaction("");
  };

  const { nameToShow, extraNumber } = getMerchantTextAndRemaining({
    mainDetail: merchant,
    splitDetails: splitTransactionVendors || [],
    splitCount: splitTransactionsMerchantCount,
  });

  const value =
    merchant || nameToShow
      ? {
          value: merchant || "",
          label: (
            <div className="t-flex t-gap-2 t-items-center">
              <Avatar
                src={logo || ""}
                alt={merchant || (nameToShow as string)}
              />
              <div className="t-text-subtext t-text-text-60 t-max-w-[300px] t-truncate">
                {nameToShow}
              </div>
              {showSplitMerchantCount && (
                <div className="t-text-subtitle-sm t-text-purple">
                  +{extraNumber}
                </div>
              )}
            </div>
          ),
        }
      : null;

  return (
    <div className="t-group t-select-none" onClick={(e) => e.stopPropagation()}>
      <MerchantSelector
        customComponent={!outline}
        size="small"
        onCreateMerchant={onCreateMerchant}
        merchantChange={handleMerchantChange}
        key={transactionId}
        isLoading={isAssiging || isDeleting || isCreatingMerchant}
        defaultValue={value}
        value={value}
        selected={merchant}
        creatable={true}
        showMerchantDetails={showSplitVendors}
        transactions={transactions}
      />
      <RemoveSplitActionModal
        isOpen={removeSplitTxns.isOpen}
        close={closeRemoveSplitTxns}
        transaction={transactions}
        onMerchantConfirm={merchantChange}
        merchantIdToAssignTransaction={merchantIdToAssignTransaction}
      />
    </div>
  );
};

const Merchant = ({
  info,
  showSplitVendors,
}: {
  info: CellContext<Transactions, Transaction["merchant"]>;
  showSplitVendors?: boolean;
}) => {
  const {
    row: {
      original: { split_transactions_merchant_count },
    },
  } = info;
  const splitTransactionVendors = info.row.original.split_transactions?.reduce(
    (acc, transaction) => {
      if (transaction.merchant) {
        if (acc.length > 0) {
          acc += ", ";
        }
        acc += transaction.merchant;
      }
      return acc;
    },
    ""
  );

  return (
    <MerchantSelectDelete
      transactions={info.row.original}
      onlyLogo={true}
      splitTransactionsMerchantCount={split_transactions_merchant_count}
      splitTransactionVendors={info.row.original.split_transactions}
      showSplitVendors={Boolean(showSplitVendors && splitTransactionVendors)}
    />
  );
};

const CategoryChangeWrapper = ({
  children,
  transaction,
}: {
  children: ({
    onChange,
    isCategorising,
  }: {
    onChange: ({ value }: any) => void;
    isCategorising: boolean;
  }) => ReactNode;
  transaction: Transactions;
}) => {
  const [sourceTransaction, setSourceTransaction] =
    useState<SourceTransaction | null>();

  const { successToast, alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const dispatch = useDispatch();

  const [updateTransaction, { isLoading: isUpdating }] =
    useUpdateTransactionMutation();

  const { uuid, memo, category, linked_transaction } = transaction.transaction;

  const assignCategoryToTxn = async ({
    transactionCategoryId,
    previewTxnId,
  }: {
    transactionCategoryId?: string | null;
    previewTxnId: string;
  }) => {
    try {
      const transactionResponse = await updateTransaction({
        groupId,
        entityId,
        previewTxnId,
        payload: {
          linked_transaction_uuid: linked_transaction || null,
          transaction_category_uuid: transactionCategoryId || null,
          memo: memo || null,
        },
      }).unwrap();

      if (transactionResponse?.transaction?.category) {
        successToast({ message: "Category assigned" });
      } else {
        successToast({ message: "Category removed" });
      }

      if (
        transactionResponse?.group_categorization
          ?.similar_merchant_txns_count! > 0
      ) {
        dispatch(setUpdateTxnResponse(transactionResponse));
        dispatch(openSimilarTxnsModal());
      }
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  const {
    isOpen: isLinkTransactionOpen,
    close: closeLinkTransaction,
    open: openLinkTransaction,
  } = useModal();

  const {
    isOpen: isOpenChangeCategoryModal,
    open: openChangeCategoryModal,
    close: closeChangeCategoryModal,
  } = useModal();

  const {
    isOpen: isOpenUncategoriseModal,
    open: openUncategoriseModal,
    close: closeUncategoriseModal,
  } = useModal();

  const onChange = (option?: {
    label: string;
    value: string;
    categoryType: string;
    data: string;
  }) => {
    if (!option) {
      switch (category?.name) {
        case "Bank Transfer":
          openUncategoriseModal();
          break;
        case "Pay Down Credit":
          openUncategoriseModal();
          break;
        default:
          assignCategoryToTxn({
            transactionCategoryId: "",
            previewTxnId: uuid,
          });
      }
    } else {
      const { categoryType, label, value, data } = option;

      const suggestedCategoryCheck: Boolean =
        categoryType === "SUGGESTED" &&
        (data.includes("Bank Transfer") || data.includes("Pay Down Credit"));

      if (
        category?.name === "Bank Transfer" ||
        category?.name === "Pay Down Credit"
      ) {
        openChangeCategoryModal();
      } else if (
        categoryType === "BANK_TRANSFER" ||
        categoryType === "PAY_DOWN_CREDIT" ||
        suggestedCategoryCheck
      ) {
        openLinkTransaction();
      } else {
        assignCategoryToTxn({
          transactionCategoryId:
            option instanceof Array ? option[0].value : option?.value,
          previewTxnId: uuid,
        });
      }

      if (
        category?.name === "Bank Transfer" ||
        category?.name === "Pay Down Credit" ||
        categoryType === "BANK_TRANSFER" ||
        categoryType === "PAY_DOWN_CREDIT" ||
        suggestedCategoryCheck
      ) {
        setSourceTransaction({
          transactionInfo: transaction.transaction,
          category: {
            categoryName: label,
            categoryType: categoryType,
            categoryId: value,
            data: data.replace(/[0-9]/g, ""),
          },
        });
      }
    }
  };

  const onLinkTransaction = async (
    linkedTransactions: Record<string, InternalTransaction>
  ) => {
    if (sourceTransaction) {
      try {
        await updateTransaction({
          groupId,
          entityId,
          previewTxnId: sourceTransaction?.transactionInfo.uuid!,
          payload: {
            transaction_category_uuid:
              sourceTransaction?.category.categoryId || null,
            memo: sourceTransaction?.transactionInfo.memo || "",
            linked_transaction_uuid:
              linkedTransactions[sourceTransaction.transactionInfo.uuid].uuid ||
              "",
          },
        }).unwrap();
        closeLinkTransaction();
        successToast({ title: "Transaction Linked" });
      } catch (error) {
        alertToast({ message: (error as BackendError)?.data?.error?.message });
        throw error;
      }
    }
  };

  const closeLinkTransactionCallback = () => {
    closeLinkTransaction();
    setSourceTransaction(null);
  };

  return (
    <>
      {children({ onChange, isCategorising: isUpdating })}
      {isLinkTransactionOpen && (
        <LinkTransaction
          onSelect={onLinkTransaction}
          isLinkTransactionOpen={isLinkTransactionOpen}
          closeLinkTransaction={closeLinkTransactionCallback}
          sourceTransaction={sourceTransaction!}
          isUpdating={isUpdating}
        />
      )}
      {isOpenChangeCategoryModal && (
        <ChangeCategory
          isOpen={isOpenChangeCategoryModal}
          close={closeChangeCategoryModal}
          sourceTransaction={sourceTransaction!}
          openLinkTransactionModal={openLinkTransaction}
        />
      )}
      {isOpenUncategoriseModal && (
        <Uncategorise
          isOpen={isOpenUncategoriseModal}
          close={closeUncategoriseModal}
          transactionDetail={transaction}
        />
      )}
    </>
  );
};

export const CategoryIndent = ({
  label,
  indentLevel,
}: {
  label: ReactNode;
  indentLevel: number;
}) => {
  const paddingValue = indentLevel * 16;
  return (
    <div
      style={{ paddingLeft: indentLevel === 0 ? "0px" : `${paddingValue}px` }}
      className="t-flex t-gap-1.5 t-w-full"
    >
      {indentLevel !== 0 && (
        <img src={ArrowElbowDownRight} alt="ArrowElbowDownRight" />
      )}
      {label}
    </div>
  );
};

export const coaOptions = (
  chartOfAccounts: TxnCategories[],
  indentLevel: number = 0,
  topLevel: boolean = true,
  hiddenCategories?: string[]
) => {
  let options: CategoryOption[] = [];

  chartOfAccounts.forEach((category) => {
    const label = (
      <CategoryIndent
        label={<CategoryLabel category={category} indentLevel={indentLevel} />}
        indentLevel={indentLevel}
      />
    );

    const disabled = Boolean(
      topLevel &&
        category.category_type !== "BANK_TRANSFER" &&
        category.category_type !== "PAY_DOWN_CREDIT" &&
        category.category_type !== "SUGGESTED"
    );

    const option: CategoryOption = {
      value: category.uuid,
      label,
      data: category.identifier.toString().concat(category.name),
      isDisabled: disabled,
      categoryType: category.category_type,
      indentLevel,
    };

    options = [...options, option];

    if (category.types && category.types.length > 0) {
      const nestedOptions = coaOptions(category.types, indentLevel + 1, false);
      options = [...options, ...nestedOptions];
    }
  });

  const filteredOptions =
    Array.isArray(hiddenCategories) && hiddenCategories?.length > 0
      ? options.filter(({ value }) => !hiddenCategories?.includes(value))
      : options;

  return filteredOptions;
};

export const coaList = (
  chartOfAccounts: TxnCategories[],
  indentLevel: number = 0,
  topLevel: boolean = true
) => {
  let lists: TxnCategories[] = [];

  chartOfAccounts.forEach((category) => {
    lists = [...lists, category];

    if (category.types && category.types.length > 0) {
      const nestedLists = coaList(category.types, indentLevel + 1, false);
      lists = [...lists, ...nestedLists];
    }
  });

  return lists;
};

const searchTermExistsInChildren = (
  category: TxnCategories,
  searchTerm: string
): boolean => {
  if (category.name.toLowerCase().includes(searchTerm)) {
    return true;
  }

  if (category.types && category.types.length > 0) {
    for (const child of category.types) {
      if (searchTermExistsInChildren(child, searchTerm)) {
        return true;
      }
    }
  }

  return false;
};

export const filterOption = (
  option: FilterOptionOption<OptionData>,
  searchedTerm: string,
  chartOfAccounts: TxnCategories[]
) => {
  if (!option.data?.data) {
    return true;
  }

  const optionData = option.data as CategoryOption;

  const searchTerm = searchedTerm.toLowerCase();
  const categoryData = optionData?.data.toLowerCase();
  const indentLevel = optionData.indentLevel;
  let includeOption = false;

  if (categoryData.includes(searchTerm)) {
    includeOption = true;
  }

  if (indentLevel === 0) {
    const categoryId = optionData.value;
    const category = chartOfAccounts.find(({ uuid }) => uuid === categoryId);

    if (category && searchTermExistsInChildren(category, searchTerm)) {
      includeOption = true;
    }
  }

  return includeOption;
};

const SplitActionButtons = ({ transaction }: { transaction: Transactions }) => {
  const dispatch = useDispatch();

  if (transaction.split_transactions) {
    return (
      <Button
        customType="link"
        onClick={() => dispatch(openEditSplitTransactionModal(transaction))}
      >
        Edit split transaction
      </Button>
    );
  }

  return (
    <Button
      customType="link"
      onClick={() => dispatch(openSplitTransactionModal(transaction))}
    >
      Split transaction
    </Button>
  );
};

export const CategorySelector = ({
  transaction,
  outline,
  truncate,
  showSplitActions,
  txnsToShowInLabel,
  tooltip = false,
  tooltipText,
}: {
  transaction: Transactions;
  outline?: boolean;
  truncate?: boolean;
  showSplitActions?: boolean;
  txnsToShowInLabel: string;
  tooltipText?: string;
  tooltip?: boolean;
}) => {
  const { chartOfAccounts = [] } = useChartOfAccounts({
    hiddenCategoryTypes: ["BANK_ACCOUNT"],
  });
  const { isOpen, close, open } = useModal();
  const dispatch = useDispatch();
  const removeSplitTxns = useModal();
  const [selectedCategoryDetail, setSelectedCategoryDetail] = useState();
  const { uuid: groupId } = useCurrentGroupContext();
  const currentEntityId = useCurrentEntityId();
  const [categoryName, setCategoryName] = useState<string>("");

  const {
    transaction: { categorisation_status, uuid, category },
  } = transaction;

  const filteredSuggestedCategories =
    transaction.transaction.suggested_categories.filter(
      ({ uuid }) => category?.uuid !== uuid
    );

  const isSplitTransaction =
    transaction.split_transactions && transaction.split_transactions.length > 0;

  const totalAssignedCategory = transaction.split_transactions
    ? transaction.split_transactions.length
    : 0;

  const hasMultipleCategory = totalAssignedCategory !== 0;
  const notCategorised = categorisation_status === "NOT_CATEGORISED";

  const suggestedCategories: TxnCategories[] = filteredSuggestedCategories.map(
    ({ identifier, name, uuid }) => ({
      identifier,
      name,
      uuid,
      description: "",
      identifier_prefix: 1,
      parent_id: "",
      category_type: "SUGGESTED",
      parent_name: "",
      start_date: null,
      opening_balance: null,
      manually_added: false,
    })
  );

  const suggestedCategoriesWithParentLabel: TxnCategories[] =
    !Boolean(category?.name || txnsToShowInLabel) &&
    transaction.transaction.suggested_categories.length > 0
      ? [
          {
            uuid: "",
            name: "Suggested",
            description: "",
            identifier: 0,
            identifier_prefix: 1,
            parent_id: "",
            category_type: "SUGGESTED_PARENT",
            parent_name: "",
            start_date: null,
            opening_balance: null,
            manually_added: false,
          },
          ...suggestedCategories,
        ]
      : [];

  const openRemoveSplitTxns = (selectedCategory: any) => {
    if (!selectedCategory?.value) {
      dispatch(openDeleteSplitTransactionModal(transaction));
      return;
    }

    setSelectedCategoryDetail(selectedCategory);
    removeSplitTxns.open();
  };

  const { nameToShow, extraNumber } = getTextAndRemaining({
    mainDetail: category?.name,
    splitDetails: txnsToShowInLabel,
    splitCount: totalAssignedCategory,
  });

  const onCreation = async (name: string) => {
    setCategoryName(name);
  };

  const onParentClose = () => {
    setCategoryName("");
    close();
  };

  return (
    <div
      className="t-group t-select-none"
      onClick={(e) => e.stopPropagation()}
      key={uuid}
    >
      <CategoryChangeWrapper transaction={transaction}>
        {({ onChange, isCategorising }) => (
          <>
            <div className="t-flex t-justify-between t-items-center t-gap-0.5 t-w-full">
              <ConditionalToolTip
                condition={tooltip && tooltipText}
                propClass="t-max-w-96 t-word-break"
              >
                <div className="t-w-full">
                  <Combobox
                    fullWidth
                    creatable
                    onCreateOption={onCreation}
                    components={
                      outline
                        ? {}
                        : {
                            Control: TransactionComboboxControl,
                            DropdownIndicator,
                            ClearIndicator: TransactionComboboxClearIndicator,
                          }
                    }
                    isLoading={isCategorising}
                    size="small"
                    placeholder="Select category"
                    menuPlacement="auto"
                    options={coaOptions([
                      ...suggestedCategoriesWithParentLabel,
                      ...chartOfAccounts,
                    ])}
                    // @ts-ignore // Somehow complier is throwing error and vscode is not, check the version and source for both later
                    onChange={
                      isSplitTransaction && showSplitActions
                        ? openRemoveSplitTxns
                        : onChange
                    }
                    filterOption={(option, searchedTerm) =>
                      filterOption(option, searchedTerm, chartOfAccounts)
                    }
                    {...{
                      value:
                        category?.name || txnsToShowInLabel
                          ? {
                              label: (
                                <div className="t-flex t-gap-0.5">
                                  <div
                                    className={classNames({
                                      "t-truncate t-max-w-fit":
                                        truncate && !hasMultipleCategory,
                                      "t-truncate t-max-w-[130px]":
                                        truncate && hasMultipleCategory,
                                    })}
                                  >
                                    {nameToShow || "-"}
                                  </div>
                                  {hasMultipleCategory && (
                                    <div className="t-text-subtitle-sm t-text-purple">
                                      +{extraNumber}
                                    </div>
                                  )}
                                </div>
                              ),
                              value: category?.uuid || "",
                            }
                          : null,
                    }}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({
                        ...base,
                        width: 400,
                      }),
                    }}
                    actions={
                      showSplitActions ? (
                        <SplitActionButtons transaction={transaction} />
                      ) : null
                    }
                  />
                </div>
              </ConditionalToolTip>
              {!notCategorised && (
                <CategoryStatus
                  entityId={currentEntityId}
                  groupId={groupId}
                  transaction={transaction}
                />
              )}
            </div>
            <ParentSelector
              categoryName={categoryName}
              isOpen={isOpen || Boolean(categoryName)}
              close={onParentClose}
              open={open}
              onSuccess={({ name, uuid, category_type }) =>
                onChange({
                  label: name,
                  value: uuid,
                  categoryType: category_type,
                })
              }
            />
            <RemoveSplitActionModal
              isOpen={removeSplitTxns.isOpen}
              close={removeSplitTxns.close}
              transaction={transaction}
              onConfirm={() => onChange(selectedCategoryDetail)}
            />
          </>
        )}
      </CategoryChangeWrapper>
    </div>
  );
};

const Category = ({
  info,
  truncate,
  tooltip,
  showSplitActions,
}: {
  info: CellContext<Transactions, Transaction["category"]>;
  truncate?: boolean;
  tooltip?: boolean;
  showSplitActions?: boolean;
}) => {
  const { split_transactions = [], transaction } = info.row.original;
  let tooltipText = transaction.category?.name || "";

  if (showSplitActions && split_transactions) {
    tooltipText = split_transactions
      .map((t) => t.category?.name)
      .filter(Boolean)
      .join(", ");
  }

  if (showSplitActions && split_transactions) {
    return (
      <SplitCategoryOnPopover
        transaction={info.row.original}
        truncate={truncate}
        showSplitActions={showSplitActions}
        tooltipText={tooltipText}
        key={info.row.original.transaction.uuid}
      />
    );
  }

  return (
    <CategorySelector
      transaction={info.row.original}
      truncate={truncate}
      showSplitActions={showSplitActions}
      txnsToShowInLabel={tooltipText}
      tooltip
      tooltipText={tooltipText}
    />
  );
};

const Invoice = (info: CellContext<Transactions, Transactions["invoices"]>) => {
  const invoices = info.getValue();

  return (
    <>
      {invoices.length !== 0 ? (
        <AvatarGroup max={1} variant="no-fill">
          {invoices.map(({ uuid, file_data }) => (
            <Button key={uuid} customType="ghost_icon" size="small">
              <FileIcon
                fileType={file_data?.file_type}
                width="24px"
                height="24px"
              />
            </Button>
          ))}
        </AvatarGroup>
      ) : (
        <div onClick={(e) => e.stopPropagation()}>
          <AttachInvoice transactionId={info.row.original.transaction.uuid} />
        </div>
      )}
    </>
  );
};

const Amount = ({
  amount,
  isCreditCard,
}: {
  isCreditCard: boolean;
  amount: number;
}) => {
  const isCredit = amount > 0;

  return (
    <div
      className={classNames("t-flex t-justify-end t-whitespace-nowrap", {
        "t-text-dark_green-50": isCredit && !isCreditCard,
      })}
    >
      <AmountSuperScript amount={amount} />
    </div>
  );
};

const Actions = ({
  info,
  onRequestInfo,
  sendToChat,
  onRowClick,
  onDuplicate,
}: {
  info: CellContext<Transactions, Transactions["transaction"]>;
  onRequestInfo?: ({ transactionIds }: { transactionIds: string }) => void;
  sendToChat: (uuid: string) => void;
  onRowClick: (uuid: string, e: Event) => void;
  onDuplicate?: (transaction: Transaction) => void;
}) => {
  const dispatch = useDispatch();

  const {
    row: {
      original: {
        transaction: {
          uuid,
          journal_entry: isManualTransaction,
          status,
          amount,
          is_credit_card,
        },
        split_transactions,
        invoices,
      },
    },
  } = info;

  const isCreditCard = is_credit_card;
  const isCredit = amount > 0;

  const hasLinkedInvoice = invoices.some(
    ({ has_inkle_invoice }) => has_inkle_invoice
  );

  const isSplitTransaction = split_transactions
    ? Boolean(split_transactions?.length > 0)
    : false;

  const isExcluedTransaction = status === "EXCLUDED";

  const isInvoiceLinked = isCredit && !isCreditCard && hasLinkedInvoice;
  const isInvoiceLinkable =
    isCredit && !isCreditCard && !hasLinkedInvoice && !isSplitTransaction;

  const editModal = useModal();
  const deleteModal = useModal();

  const linkInkleInvoiceModal = useModal();
  const linkInkleInvoiceEditModal = useModal();

  const entityId = useCurrentEntityId();
  const { alertToast, successToast } = useToast();

  const [changeTransactionStatus] = useChangeTransactionStatusMutation();

  const changeStatus = async ({
    transactionStatus,
  }: {
    transactionStatus: "EXCLUDED" | "RESTORED";
  }) => {
    try {
      await changeTransactionStatus({
        entityId,
        payload: {
          transaction_ids: [uuid],
          transaction_status: transactionStatus,
        },
      }).unwrap();

      const message =
        transactionStatus === "EXCLUDED"
          ? "Transaction excluded"
          : "Transaction restored";

      successToast({
        message,
      });
    } catch (error) {
      alertToast(
        { message: (error as BackendError)?.data?.error?.message },
        error as {}
      );
    }
  };

  const Action = {
    RequestInfo: (props: DropdownItemProps) => (
      <Dropdown.Item
        {...props}
        onSelect={() => onRequestInfo?.({ transactionIds: uuid })}
      >
        Request info
      </Dropdown.Item>
    ),

    SendToChat: (props: DropdownItemProps) => (
      <Dropdown.Item {...props} onSelect={() => sendToChat(uuid)}>
        Send to chat
      </Dropdown.Item>
    ),

    AddInvoice: (props: DropdownItemProps) => (
      <Dropdown.Item
        {...props}
        onSelect={(e) => {
          onRowClick(uuid, new Event("click"));
        }}
      >
        Add invoice
      </Dropdown.Item>
    ),

    Edit: (props: DropdownItemProps) => (
      <Dropdown.Item {...props} onSelect={editModal.open}>
        Edit transaction
      </Dropdown.Item>
    ),

    Delete: (props: DropdownItemProps) => (
      <Dropdown.Item {...props} type="danger" onSelect={deleteModal.open}>
        Delete transaction
      </Dropdown.Item>
    ),

    SplitTransaction: (props: DropdownItemProps) => (
      <Dropdown.Item
        {...props}
        onSelect={() => dispatch(openSplitTransactionModal(info.row.original))}
      >
        Split transaction
      </Dropdown.Item>
    ),

    EditSplitTransaction: (props: DropdownItemProps) => (
      <Dropdown.Item
        {...props}
        onSelect={() =>
          dispatch(openEditSplitTransactionModal(info.row.original))
        }
      >
        Edit split transaction
      </Dropdown.Item>
    ),

    DeleteSplitTransaction: (props: DropdownItemProps) => (
      <Dropdown.Item
        {...props}
        onSelect={() =>
          dispatch(openDeleteSplitTransactionModal(info.row.original))
        }
      >
        Delete split transaction
      </Dropdown.Item>
    ),

    ExcludeTransaction: (props: DropdownItemProps) => (
      <Dropdown.Item
        {...props}
        onSelect={() => changeStatus({ transactionStatus: "EXCLUDED" })}
      >
        Exclude transaction
      </Dropdown.Item>
    ),

    RestoreTransaction: (props: DropdownItemProps) => (
      <Dropdown.Item
        {...props}
        onSelect={() => changeStatus({ transactionStatus: "RESTORED" })}
      >
        Restore transaction
      </Dropdown.Item>
    ),

    LinkInkleInvoice: (props: DropdownItemProps) => (
      <Dropdown.Item
        {...props}
        onSelect={(e) => {
          e.stopPropagation();
          linkInkleInvoiceModal.open();
        }}
      >
        Link Inkle invoice
      </Dropdown.Item>
    ),

    EditLinkInkleInvoice: (props: DropdownItemProps) => (
      <Dropdown.Item
        {...props}
        onSelect={(e) => {
          e.stopPropagation();
          linkInkleInvoiceEditModal.open();
        }}
      >
        Edit Invoice Linking
      </Dropdown.Item>
    ),
    AddComment: (props: DropdownItemProps) => (
      <Popover.Trigger asChild>
        <Dropdown.Item {...props}>Add comment</Dropdown.Item>
      </Popover.Trigger>
    ),

    ...(onDuplicate
      ? {
          Duplicate: (props: DropdownItemProps) => (
            <Dropdown.Item
              {...props}
              onSelect={() => onDuplicate(info.row.original.transaction)}
            >
              Duplicate
            </Dropdown.Item>
          ),
        }
      : {}),
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <AddCommentPopoverRoot contentId={info.row.original.transaction.uuid}>
        <Dropdown.Root>
          <Dropdown.Trigger asChild>
            <Popover.Anchor asChild>
              <div>
                <Button size="small" customType="ghost_icon">
                  <img src={ThreeDots} alt="Action" className="t-select-none" />
                </Button>
              </div>
            </Popover.Anchor>
          </Dropdown.Trigger>
          <Popover.Portal>
            <AddCommentPop
              commentType="TRANSACTION_COMMENT"
              contentId={info.row.original.transaction.uuid}
            />
          </Popover.Portal>
          <Dropdown.Portal>
            <Dropdown.Content
              sideOffset={8}
              side="bottom"
              className="t-mr-16"
              align="start"
            >
              <ActionDropdown
                Action={Action}
                isManualTransaction={isManualTransaction}
                isSplitTransaction={isSplitTransaction}
                isExcluedTransaction={isExcluedTransaction}
                isInvoiceLinkable={isInvoiceLinkable}
                isInvoiceLinked={isInvoiceLinked}
              />
            </Dropdown.Content>
          </Dropdown.Portal>
        </Dropdown.Root>
      </AddCommentPopoverRoot>
      {editModal.isOpen && (
        <EditModal
          transactionId={uuid}
          isOpen={editModal.isOpen}
          close={editModal.close}
        />
      )}
      {deleteModal.isOpen && (
        <DeleteTransaction
          transactionId={uuid}
          close={deleteModal.close}
          isOpen={deleteModal.isOpen}
        />
      )}
      {linkInkleInvoiceModal.isOpen && (
        <LinkInkleInvoiceModal
          transactionId={uuid}
          transactionAmount={amount}
          isOpen={linkInkleInvoiceModal.isOpen}
          close={linkInkleInvoiceModal.close}
        />
      )}
      {linkInkleInvoiceEditModal.isOpen && (
        <LinkInkleInvoiceModal
          isEditModal={true}
          transactionId={uuid}
          transactionAmount={amount}
          isOpen={linkInkleInvoiceEditModal.isOpen}
          close={linkInkleInvoiceEditModal.close}
        />
      )}
    </div>
  );
};

const TransactionColumn = {
  Category,
  Actions,
  Amount,
  Invoice,
  Merchant,
  Source,
};

export default TransactionColumn;
