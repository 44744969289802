import ConditionalToolTip from "components/design/conditionalToolTip";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { Switch } from "components/DesignSystem/Switch/Switch";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import {
  CLICKED_SAVE_IN_ADD_CUSTOMER_MODAL,
  CLICKED_SAVE_IN_UPDATE_CUSTOMER_MODAL,
} from "constants/analyticsEvents";
import { Form, Formik } from "formik";
import { invoiceCustomerValidation } from "formValidations/invoiceCustomerValidation";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useToast } from "hooks/useToast";
import { useHistory } from "react-router-dom";
import {
  InvoiceCustomer,
  useCreateInvoiceCustomerMutation,
  useGetAllInvoiceCustomersQuery,
  useGetInvoiceSettingsQuery,
  useUpdateInvoiceCustomerMutation,
} from "store/apis/invoices";
import { InvoiceCustomerAddress } from "./InvoiceCustomerAddress";
import { useModal } from "hooks/useModal";
import { ChangeEvent, useState } from "react";

export const initialCustomerValues = {
  company_name: "",
  billing_address: {
    address_string: "",
    street_address: "",
    street_address_line_2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    uuid: "",
  },
  shipping_address: {
    address_string: "",
    street_address: "",
    street_address_line_2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    uuid: "",
  },
  billing_address_id: "",
  shipping_address_id: "",
  phone: "",
  email: "",
  is_reminders_enabled: false,
  shipping_same_as_billing: true,
};

export const InvoiceCustomerModal = ({
  customerId,
  setCustomerId,
}: {
  customerId: string | null;
  setCustomerId: (v: null | string) => void;
}) => {
  const group = useCurrentGroup();
  const entityId = useCurrentEntityId();
  const [createdCustomer, setCreatedCustomer] = useState<InvoiceCustomer>();
  const { data: invoiceSettings } = useGetInvoiceSettingsQuery(
    {
      groupId: group?.uuid!,
      entityId,
    },
    { skip: !group?.uuid || !entityId }
  );
  const { trackEvent } = useAnalytics();
  const history = useHistory();
  const [createInvoiceCustomer, { isLoading: creatingInvoiceCustomer }] =
    useCreateInvoiceCustomerMutation();

  const [updateInvoiceCustomer, { isLoading: updatingInvoiceCustomer }] =
    useUpdateInvoiceCustomerMutation();

  const { alertToast, successToast } = useToast();
  const customerAddressModal = useModal();

  const { data: invoiceCustomers } = useGetAllInvoiceCustomersQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
    },
    { skip: !group?.uuid || !entityId }
  );
  const editingCustomer = invoiceCustomers?.find((r) => r.uuid === customerId);
  const isReminderSetup = Boolean(invoiceSettings?.reminder_settings);

  const onCustomerSubmitUpdate = async (
    values: Omit<InvoiceCustomer, "customer_id">
  ) => {
    trackEvent(CLICKED_SAVE_IN_UPDATE_CUSTOMER_MODAL);
    const payload = {
      company_name: values.company_name,
      email: values.email,
      phone: values.phone,
      is_reminders_enabled: values.is_reminders_enabled,
      billing_address_id: values.billing_address_id,
      shipping_address_id: values.shipping_address_id,
      shipping_same_as_billing: values.shipping_same_as_billing,
    };

    try {
      if (
        group?.uuid &&
        entityId &&
        (customerId || createdCustomer?.customer_id)
      ) {
        await updateInvoiceCustomer({
          groupId: group?.uuid,
          entityId: entityId,
          payload: payload,
          customerId: customerId || createdCustomer?.customer_id!,
        }).unwrap();
        setCustomerId(null);
        customerAddressModal.close();
        successToast({ message: "Customer details has been updated!" });
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const onCustomerSubmitCreate = async (
    values: Omit<InvoiceCustomer, "customer_id">
  ) => {
    trackEvent(CLICKED_SAVE_IN_ADD_CUSTOMER_MODAL);
    let payload = {
      company_name: values.company_name,
      email: values.email,
      phone: values.phone,
    };

    try {
      if (group?.uuid && entityId) {
        const resp = await createInvoiceCustomer({
          groupId: group?.uuid,
          entityId: entityId,
          payload: payload,
        }).unwrap();
        setCustomerId(null);
        setCreatedCustomer(resp);
        customerAddressModal.open();
        successToast({ message: "New Customer has been created!" });
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <>
      {/* Step 1 */}
      <Modal.Root
        open={Boolean(customerId) && !editingCustomer}
        onOpenChange={() => setCustomerId(null)}
      >
        <Formik
          validateOnBlur
          validateOnChange={false}
          onSubmit={onCustomerSubmitCreate}
          validationSchema={invoiceCustomerValidation}
          initialValues={initialCustomerValues}
        >
          {({ submitForm, values, setFieldValue }) => (
            <Form>
              <Modal.Content size="large">
                <Modal.Header>
                  <Modal.Title>Add Customer</Modal.Title>
                  <Modal.Close />
                </Modal.Header>

                <Modal.Body>
                  <div className="t-flex t-flex-col t-gap-4">
                    <p className="t-m-0 t-text-subtitle">Customer Info</p>
                    <TextInput
                      required
                      name="company_name"
                      label="Customer Name"
                    />
                    <div>
                      <MobileInput
                        label="Phone"
                        name="phone"
                        value={values?.phone}
                      />
                    </div>
                    <TextInput required name="email" label="Recipient Email" />
                    <div className="t-flex t-gap-2 t-items-center t-min-h-fit">
                      <ConditionalToolTip
                        side="right"
                        condition={
                          !isReminderSetup && (
                            <div className="t-p-2 t-rounded t-text-left t-w-64">
                              <div>
                                Please configure email reminders inside
                                invoicing settings to send reminders
                              </div>
                              <div
                                className="t-subtext-sm t-underline t-mt-4 t-w-fit"
                                onClick={() =>
                                  history.push(
                                    `/books/invoices-and-customers/settings?entity=${entityId}&openconfigure=true`
                                  )
                                }
                                role="button"
                              >
                                Go to settings
                              </div>
                            </div>
                          )
                        }
                      >
                        <span>
                          <Switch
                            checked={values.is_reminders_enabled}
                            size="small"
                            name="is_reminders_enabled"
                            onCheckedChange={(v) => {
                              setFieldValue("is_reminders_enabled", v);
                            }}
                            disabled={!isReminderSetup}
                          />
                        </span>
                      </ConditionalToolTip>
                      <span className="t-text-body">
                        Send email reminders for unpaid invoices around due date
                      </span>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button>Cancel</Button>
                  </Modal.RawClose>
                  <Button
                    onClick={submitForm}
                    customType="primary"
                    isLoading={creatingInvoiceCustomer}
                    disabled={creatingInvoiceCustomer}
                  >
                    Next
                  </Button>
                </Modal.FooterButtonGroup>
              </Modal.Content>
            </Form>
          )}
        </Formik>
      </Modal.Root>
      {/* Step 2  */}
      <Modal.Root
        open={customerAddressModal.isOpen}
        onOpenChange={customerAddressModal.close}
      >
        <Formik
          onSubmit={onCustomerSubmitUpdate}
          initialValues={{
            ...initialCustomerValues,
            ...createdCustomer,
            created_customer_id: createdCustomer?.uuid,
          }}
          enableReinitialize
        >
          {({ submitForm, values, setFieldValue }) => (
            <Form>
              <Modal.Content size="large">
                <Modal.Header>
                  <Modal.Title>Add Customer Address</Modal.Title>
                  <Modal.Close />
                </Modal.Header>
                <Modal.Body>
                  <div className="t-flex t-flex-col t-gap-4">
                    <InvoiceCustomerAddress />
                    <p className="t-m-0 t-text-subtitle">
                      Default Shipping Address
                    </p>
                    <Checkbox
                      label="Same as billing address"
                      name="shipping_same_as_billing"
                      checked={values.shipping_same_as_billing}
                      defaultChecked={Boolean(values.shipping_address)}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        setFieldValue(
                          "shipping_same_as_billing",
                          e.target.checked
                        );
                      }}
                    />
                    {!values.shipping_same_as_billing && (
                      <InvoiceCustomerAddress isShipping />
                    )}
                  </div>
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button>Cancel</Button>
                  </Modal.RawClose>
                  <Button
                    onClick={() => submitForm}
                    customType="primary"
                    isLoading={updatingInvoiceCustomer}
                    disabled={updatingInvoiceCustomer}
                  >
                    Save
                  </Button>
                </Modal.FooterButtonGroup>
              </Modal.Content>
            </Form>
          )}
        </Formik>
      </Modal.Root>

      {/* Edit Customer Info */}
      {editingCustomer && (
        <Modal.Root
          open={Boolean(customerId)}
          onOpenChange={() => setCustomerId(null)}
        >
          {Boolean(customerId) && editingCustomer && (
            <Formik
              validateOnBlur
              validateOnChange={false}
              onSubmit={onCustomerSubmitUpdate}
              validationSchema={invoiceCustomerValidation}
              initialValues={{
                ...editingCustomer,
                created_customer_id: editingCustomer.uuid,
                billing_address_id: editingCustomer.billing_address?.uuid || "",
                shipping_address_id:
                  editingCustomer.shipping_address?.uuid || "",
                shipping_same_as_billing: !Boolean(
                  editingCustomer.shipping_address
                ),
              }}
            >
              {({ submitForm, values, setFieldValue }) => (
                <Form>
                  <Modal.Content size="large">
                    <Modal.Header>
                      <Modal.Title>Edit Customer</Modal.Title>
                      <Modal.Close />
                    </Modal.Header>

                    <Modal.Body>
                      <div className="t-flex t-flex-col t-gap-4">
                        <p className="t-m-0 t-text-subtitle">Customer Info</p>
                        <TextInput
                          required
                          name="company_name"
                          label="Customer Name"
                        />
                        <div>
                          <MobileInput
                            label="Phone"
                            name="phone"
                            value={values?.phone}
                          />
                        </div>
                        <TextInput
                          required
                          name="email"
                          label="Recipient Email"
                        />
                        <InvoiceCustomerAddress />
                        <p className="t-m-0 t-text-subtitle">
                          Default Shipping Address
                        </p>
                        <Checkbox
                          label="Same as billing address"
                          name="shipping_same_as_billing"
                          checked={values.shipping_same_as_billing}
                          defaultChecked={!Boolean(values.shipping_address)}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFieldValue(
                              "shipping_same_as_billing",
                              e.target.checked
                            );
                          }}
                        />
                        {!values.shipping_same_as_billing && (
                          <InvoiceCustomerAddress isShipping />
                        )}
                        <p className="t-m-0 t-text-subtitle">Email Reminders</p>
                        <div className="t-flex t-gap-2 t-items-center t-min-h-fit">
                          <ConditionalToolTip
                            side="right"
                            condition={
                              !isReminderSetup && (
                                <div className="t-p-2 t-rounded t-text-left t-w-64">
                                  <div>
                                    Please configure email reminders inside
                                    invoicing settings to send reminders
                                  </div>
                                  <div
                                    className="t-subtext-sm t-underline t-mt-4 t-w-fit"
                                    onClick={() =>
                                      history.push(
                                        `/books/invoices-and-customers/settings?entity=${entityId}&openconfigure=true`
                                      )
                                    }
                                    role="button"
                                  >
                                    Go to settings
                                  </div>
                                </div>
                              )
                            }
                          >
                            <span>
                              <Switch
                                checked={values.is_reminders_enabled}
                                size="small"
                                name="is_reminders_enabled"
                                onCheckedChange={(v) => {
                                  setFieldValue("is_reminders_enabled", v);
                                }}
                                disabled={!isReminderSetup}
                              />
                            </span>
                          </ConditionalToolTip>
                          <span className="t-text-body">
                            Send email reminders for unpaid invoices around due
                            date
                          </span>
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <div className="t-flex t-justify-end">
                        <Button
                          onClick={submitForm}
                          customType="primary"
                          isLoading={updatingInvoiceCustomer}
                          disabled={updatingInvoiceCustomer}
                        >
                          Save
                        </Button>
                      </div>
                    </Modal.Footer>
                  </Modal.Content>
                </Form>
              )}
            </Formik>
          )}
        </Modal.Root>
      )}
    </>
  );
};
