import qs from "qs";
import { AddressPayload } from "types/Models/addressAutofill";
import { FileObject } from "types/Models/fileObject";
import { Pagination } from "types/Models/pagination";
import {
  AmountPerSeason,
  MerchantCSVResponse,
  Vendor,
  VendorAllDocuments,
  WFormResponse,
} from "types/Models/vendors";
import { makeFormData } from "utils/makeFormData";
import { fileAndFolder } from "./../../types/Models/documents";
import { emptyApi } from "./emptyApi";

type PayloadData = {
  groupId: string;
  page_num?: number;
  type?: string | null;
  search_term?: string | null;
  season?: string | null;
  entityId: string;
  refresh_merchants?: boolean | null;
  sort_order?: string;
  merchant_source?: string | null;
  sort_key?: "1099_TXN_AMOUNT" | "TOTAL_TXN_AMOUNT";
};

export const vendorsApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getVendors: build.query<Vendor[], PayloadData>({
      query: ({
        groupId,
        entityId,
        search_term,
        type,
        page_num,
        season,
        refresh_merchants,
        sort_order,
        sort_key,
        merchant_source,
      }) => {
        let queryUrl = qs.stringify(
          {
            search_term,
            merchant_type: type,
            page_num,
            season: season || null,
            refresh_merchants,
            sort_order,
            sort_key,
            merchant_source: merchant_source || null,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/merchant/${queryUrl}`,
        };
      },
      providesTags: ["VENDORS"],
    }),

    getVendorDetail: build.query<
      Vendor,
      {
        entityId: string;
        vendorId: string;
        season: string;
      }
    >({
      query: ({ entityId, vendorId, season }) => {
        const query = qs.stringify(
          {
            season,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/merchant/${vendorId}/${query}`,
        };
      },
      providesTags: ["SINGLE_VENDOR", "VENDORS"],
    }),

    getVendorAllDocuments: build.query<
      VendorAllDocuments,
      {
        entityId: string;
        vendorId: string;
      }
    >({
      query: ({ entityId, vendorId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/merchant/${vendorId}/documents/`,
        };
      },
      providesTags: ["SINGLE_VENDOR", "VENDORS"],
    }),

    addVendors: build.mutation<
      Vendor,
      {
        groupId: string;
        payload: {
          name: string;
          email?: string;
          logo?: File;
          address_obj?: AddressPayload;
          amount_paid_in_season?: AmountPerSeason[];
          payment_instructions?: string;
        };
        entityId: string;
      }
    >({
      query: ({ groupId, payload, entityId }) => {
        const formData = new FormData();

        formData.append("name", payload.name);

        if (payload.email) {
          formData.append("email", payload.email);
        }

        if (payload.payment_instructions) {
          formData.append("payment_instructions", payload.payment_instructions);
        }

        if (payload.logo) {
          formData.append("logo", payload.logo);
        }

        if (payload.address_obj) {
          formData.append("address_obj", JSON.stringify(payload.address_obj));
        }

        if (payload.amount_paid_in_season) {
          formData.append(
            "amount_paid_in_season",
            JSON.stringify(payload.amount_paid_in_season)
          );
        }

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/create-merchant/`,
          method: "post",
          body: formData,
        };
      },
      invalidatesTags: ["VENDORS", "TRANSACTION_MERCHANTS"],
    }),

    editVendors: build.mutation<
      Vendor,
      {
        groupId: string;
        uuid: string;
        payload: {
          name?: string;
          email?: string;
          merchant_type?: "CPA_REVIEWED" | "NOT_REQUIRED" | "SYSTEM_REVIEWED";
          season?: string | null;
          amount_paid_in_season?: string;
          form_1099_status?: "NOT_REQUIRED" | "NOT_STARTED";
        };
      }
    >({
      query: ({ groupId, uuid, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/merchant/${uuid}/update/`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["VENDORS", "Merchants", "SINGLE_VENDOR"],
    }),
    deleteVendor: build.mutation<
      Vendor,
      {
        groupId: string;
        merchantId: string;
      }
    >({
      query: ({ groupId, merchantId }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/merchant/${merchantId}/delete/`,
          method: "delete",
        };
      },
      invalidatesTags: ["VENDORS"],
    }),
    uploadForm1099File: build.mutation<
      FileObject,
      {
        file?: File;
        fileId?: string;
        merchantId: string;
        groupId: string;
        entityId: string;
        form1099Document: string;
      }
    >({
      query: ({
        file,
        fileId,
        merchantId,
        groupId,
        entityId,
        form1099Document,
      }) => {
        const formData = new FormData();
        formData.append("form_1099_document_type", form1099Document);

        if (file) {
          formData.append("file", file);
        }
        if (fileId) {
          formData.append("file_id", fileId);
        }

        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/merchant/${merchantId}/file_upload/`,
          method: "post",
          body: formData,
        };
      },
      invalidatesTags: ["VENDORS"],
    }),
    mergeMerchants: build.mutation<
      any,
      {
        parent_merchant_id: string;
        child_merchant_ids: string[];
        group_id: string;
        entity_id: string;
      }
    >({
      query: ({
        child_merchant_ids,
        parent_merchant_id,
        group_id,
        entity_id,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${group_id}/entity/${entity_id}/merge/merchant/`,
          method: "post",
          body: { child_merchant_ids, parent_merchant_id },
        };
      },
      invalidatesTags: ["VENDORS"],
    }),

    uploadMerchantCSV: build.mutation<
      fileAndFolder,
      { entityId: string; csv: File; signal?: AbortSignal }
    >({
      query: ({ entityId, csv, signal }) => {
        const payload = makeFormData({
          csv: csv,
        });
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/upload_merchant_csv/`,
          method: "POST",
          body: payload,
          signal,
        };
      },
    }),

    getParsedCSV: build.query<
      MerchantCSVResponse[],
      { entityId: string; csvId: string }
    >({
      query: ({ entityId, csvId }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/csv/${csvId}/merchant_csv_response/`,
        };
      },
    }),

    bulkMerchantUpload: build.mutation<
      void,
      { entityId: string; merchants: MerchantCSVResponse[] }
    >({
      query: ({ entityId, merchants }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/bulk_merchant_upload/`,
          method: "POST",
          body: { merchant_datas: merchants },
        };
      },
      invalidatesTags: ["VENDORS", "TRANSACTION_MERCHANTS"],
    }),
    MarkMultipleVendorsRequired: build.mutation<
      Vendor,
      {
        entityId: string;
        payload: {
          merchant_ids: string[];
          season?: string;
        };
      }
    >({
      query: ({ entityId, payload }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/w_form_required/`,
          method: "PATCH",
          body: payload,
        };
      },
      invalidatesTags: ["VENDORS"],
    }),

    vendorForms: build.query<
      Pagination & {
        merchants_forms: WFormResponse[];
      },
      { searchTerm: string; page_num: number }
    >({
      query: ({ searchTerm, page_num }) => {
        let queryString = qs.stringify(
          {
            search: searchTerm,
            page_num,
          },
          { skipNulls: true, addQueryPrefix: true }
        );

        return {
          url: `/api/inkle/bookkeeping/merchants/forms/${queryString}`,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetVendorsQuery,
  useGetVendorDetailQuery,
  useGetVendorAllDocumentsQuery,
  useAddVendorsMutation,
  useEditVendorsMutation,
  useDeleteVendorMutation,
  useUploadForm1099FileMutation,
  useMergeMerchantsMutation,
  useLazyGetVendorsQuery,
  useUploadMerchantCSVMutation,
  useGetParsedCSVQuery,
  useLazyGetParsedCSVQuery,
  useBulkMerchantUploadMutation,
  useMarkMultipleVendorsRequiredMutation,
  useVendorFormsQuery,
  useLazyVendorFormsQuery,
} = vendorsApi;
