import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getGroupUsers } from "../../apis/groups";
import { Chat } from "components/chat/Chat";
import { ChannelFilters } from "stream-chat";
import { DefaultStreamChatGenerics } from "stream-chat-react/dist/types/types";
import { useChatContext } from "stream-chat-react";
import { withChatFilterContext } from "contexts/ChatFilterContext";
import { useStreamFilterQuery } from "hooks/useStreamFilterQuery";
import { ChannelHeader } from "components/ChannelHeader/ChannelHeader";
import { Button } from "components/DesignSystem/Button/Button";
import { ArrowLeft } from "components/icons/ArrowLeft";
import { useIsSmallScreen } from "hooks/useIsSmallScreen";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { useDispatch } from "react-redux";

export const GroupChat = ({ path }: { path: string }) => {
  const dispatch = useDispatch();
  const { groupId } = useParams<{ groupId: string }>();
  const { channelId: paramsChannelId } = useParams<{ channelId: string }>();
  const [channelId, setChannelId] = useState<string>(paramsChannelId);
  const { messageId } = useParams<{ messageId: string }>();
  const [groupUserIds, setGroupUserIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const { client, setActiveChannel } = useChatContext();
  const filtersQuery = useStreamFilterQuery();
  const isSmallScreen = useIsSmallScreen();

  useEffect(() => {
    const getAndsetGroupIds = async () => {
      setLoading(true);
      const { data: groupUsers } = await getGroupUsers({ groupId });
      const ids = groupUsers.map((g: any) => g.member_id);
      setGroupUserIds(ids);
      setLoading(false);
    };

    getAndsetGroupIds();
  }, []);

  const filters: ChannelFilters<DefaultStreamChatGenerics> = useMemo(() => {
    delete filtersQuery.group_uuid;
    const filters: ChannelFilters<DefaultStreamChatGenerics> = {
      type: "messaging",
      $and: [
        { members: { $in: [client.userID!] } },
        {
          group_uuid: { $eq: groupId },
        },
      ],
    };

    return filters;
  }, [client.userID, groupUserIds]);

  const onChannelSelect = (channelId: string, messageId?: string) => {
    setChannelId(channelId);
    const channelData = client.channel("messaging", channelId, {});
    setActiveChannel(channelData);
    dispatch(setScrollToMessageId(messageId));
  };

  return (
    <>
      {loading && <strong>Loading...</strong>}
      {!loading && groupUserIds.length === 0 && (
        <strong>No member exists in the group!</strong>
      )}
      {!loading && groupUserIds.length > 0 && (
        <Chat
          filters={{ ...filters, ...filtersQuery }}
          channelId={channelId}
          messageId={messageId}
          hideFilters={["COMPANY"]}
          onChannelSelect={onChannelSelect}
          channelHeaderProps={
            isSmallScreen
              ? {
                  Header: (props) => {
                    return (
                      <ChannelHeader
                        {...props}
                        actionsAvailable={true}
                        backButton={
                          <Button
                            size="small"
                            customType="ghost_icon"
                            onClick={() => setChannelId("")}
                          >
                            <ArrowLeft size="16px" />
                          </Button>
                        }
                      />
                    );
                  },
                }
              : {}
          }
        />
      )}
    </>
  );
};

export const Messages = withChatFilterContext(GroupChat);
