import { DashboardLayout } from "components/DashboardLayout";
import { Button } from "components/DesignSystem/Button/Button";
import React from "react";
import RAndDBG from "static/images/RAndDBG.svg";
import Peyaj from "static/images/Peyaj.svg";
import Ruti from "static/images/Ruti.svg";
import { usePageTitle } from "hooks/usePageTitle";
import { useDispatch } from "react-redux";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { chatApi, useCreateMessageDraftMutation } from "store/apis/chat";
import { useToast } from "hooks/useToast";
import { BackendError } from "types/utils/error";
import { ConditionalLink } from "components/conditionalLink";
import { useGetTaskListForUserQuery } from "store/apis/taskList";
import { US_HOLDCO_INCORPORATION } from "types/Models/services";

export const TaxOthers = () => {
  usePageTitle("Tax Others");
  const dispatch = useDispatch();
  const { secondary_channel_url } = useCurrentGroupContext();
  const [createUpdateDraft] = useCreateMessageDraftMutation();
  const { alertToast } = useToast();

  const { data } = useGetTaskListForUserQuery({
    taskCategoryType: "TAX,RND",
    baseTaskKey: US_HOLDCO_INCORPORATION,
  });

  const hasIncorporationTask = data?.tasks?.length && data?.tasks?.length > 0;

  const openGeneralHelp = async (type: "DISSOLUTION" | "INCORPORATE") => {
    try {
      let messageText = "";
      switch (type) {
        case "DISSOLUTION": {
          messageText =
            "Hello! We have decided to Dissolve our existing US company. How can we proceed?";
          break;
        }
        case "INCORPORATE": {
          messageText =
            "Hello! I am trying to Incorporate a new US company. Please help us with this.";
          break;
        }
        default:
          break;
      }
      if (secondary_channel_url) {
        const messageData = await createUpdateDraft({
          channelUrl: secondary_channel_url,
          message: messageText,
        });

        if (messageData.data) {
          dispatch(
            chatApi.util.upsertQueryData(
              "getDraftMessage",
              { channelUrl: secondary_channel_url },
              messageData.data
            )
          );
        }
        dispatch(setToOpenChatId(secondary_channel_url));
        dispatch(openFloatingChat());
      }
    } catch (e) {
      alertToast({ message: (e as BackendError)?.data?.error?.message });
    }
  };

  return (
    <DashboardLayout header={null}>
      <div className="t-space-y-6">
        <div className="t-flex t-h-[282px] t-w-full t-border t-border-solid t-border-neutral-10 t-rounded-lg">
          <div className="t-p-8 t-flex-1 t-flex t-flex-col t-justify-between">
            <div className="t-space-y-4 t-flex-1">
              <div className="t-text-title-h2-bold t-text-text-100">
                Assisted Incorporation
              </div>
              <div className="t-text-subtitle t-text-text-60">
                <b>$819/</b>per incorporation
              </div>
              <div className="t-text-body t-text-text-30">
                Inkle helps startup founders, both in the US and abroad
                (including specialized support for India), to compliantly
                establish a US Delaware C-Corp through Stripe Atlas or Clerky,
                with options for parent or subsidiary entities.
              </div>
            </div>
            {hasIncorporationTask ? (
              <div className="t-flex t-gap-4 t-h-10">
                <ConditionalLink
                  to={
                    data?.tasks?.[0]?.uuid
                      ? `/filings/${data?.tasks?.[0]?.uuid}`
                      : ""
                  }
                >
                  <Button customType="primary">View incorporation</Button>
                </ConditionalLink>
                <Button onClick={() => openGeneralHelp("INCORPORATE")}>
                  Talk to our team
                </Button>
              </div>
            ) : (
              <div className="t-flex t-gap-4 t-h-10">
                <Button
                  customType="primary"
                  onClick={() => openGeneralHelp("INCORPORATE")}
                >
                  Talk to our team
                </Button>
                <ConditionalLink
                  to="https://www.inkle.io/pricing#Incorporate/Dissolve"
                  target="_blank"
                >
                  <Button>Learn More</Button>
                </ConditionalLink>
              </div>
            )}
          </div>
          <div className="t-relative t-h-full t-w-[380px] t-justify-center t-items-center t-flex -t-mr-[1.5px]">
            <img
              src={RAndDBG}
              className="t-absolute t-h-full t-w-full t-rounded-r-[9px] t-z-[-1]"
              alt=""
            />
            <img src={Peyaj} alt="" />
          </div>
        </div>
        <div className="t-flex t-h-[282px] t-w-full t-border t-border-solid t-border-neutral-10 t-rounded-lg">
          <div className="t-p-8 t-flex-1 t-flex t-flex-col t-justify-between">
            <div className="t-space-y-4 t-flex-1">
              <div className="t-text-title-h2-bold t-text-text-100">
                Assisted Dissolution
              </div>
              <div className="t-text-subtitle t-text-text-60">
                <b>$350/</b>per incorporation
              </div>
              <div className="t-text-body t-text-text-30">
                Inkle facilitates the compliant filing of your Dissolution
                Certificate with the Delaware Secretary of State, prepares
                Stockholder Consent and Board of Directors Resolutions, closes
                your IRS tax account, and provides a comprehensive checklist and
                guidance for winding down all other activities and accounts.
              </div>
            </div>
            <div className="t-flex t-gap-4 t-h-10">
              <Button
                customType="primary"
                onClick={() => openGeneralHelp("DISSOLUTION")}
              >
                Talk to our team
              </Button>
              <ConditionalLink
                to="https://www.inkle.io/pricing#Incorporate/Dissolve"
                target="_blank"
              >
                <Button>Learn More</Button>
              </ConditionalLink>
            </div>
          </div>
          <div className="t-relative t-h-full t-w-[380px] t-justify-center t-items-center t-flex -t-mr-[1.5px]">
            <img
              src={RAndDBG}
              className="t-absolute t-h-full t-w-full t-rounded-r-[9px] t-z-[-1]"
              alt=""
            />
            <img src={Ruti} alt="" />
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};
