import { emailValidationRegex } from "constants/emailValidationRegex";
import { array, object } from "yup";

export const DFTRequestDataSchema = () =>
  object({
    to_emails: array()
      .min(1, "Please add at least one email address.")
      .test("invalidEmails", "", function (value) {
        if (!value) {
          return this.createError({
            path: this.path,
            message: "Please select at least one email address.",
          });
        }

        const invalidEmails = value.filter(
          (email) => !emailValidationRegex.test(email!)
        );

        if (invalidEmails.length > 0) {
          return this.createError({
            path: this.path,
            message: `Invalid emails: ${invalidEmails.join(", ")}`,
          });
        }

        return true;
      }),
  });
