import { Header } from "components/DesignSystem/Header/Header";
import { JournalEntry } from "components/JournalEntry/JournalEntry";
import DashboardContainer from "components/dashboard/DashboardContainer";

export const JournalEntries = () => {
  return (
    <DashboardContainer className="t-h-full">
      <DashboardContainer.Header className="t-px-10 t-z-header">
        <Header
          v2
          title="Journal Entries"
          right={<div className="t-flex t-gap-2"></div>}
        />
      </DashboardContainer.Header>

      <DashboardContainer.Content>
        <div className="t-h-full t-w-full">
          <JournalEntry />
        </div>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
