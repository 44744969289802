export const BANK_ACCOUNT_STATUS = {
  ACTIVE: "ACTIVE",
  CLOSED: "CLOSED",
} as const;

export const BANK_CONNECTION_TYPE = {
  PLAID: "PLAID",
  DIRECT: "DIRECT",
  MANUAL: "MANUAL",
} as const;

export const BANK_CONNECTION_PROVIDER = {
  BREX: "BREX",
  MERCURY: "MERCURY",
  MANUAL: "MANUAL",
  STRIPE: "STRIPE",
  NA: "NA",
} as const;

export const LAST_RECONCILATION_STATUS = {
  COMPLETED: "COMPLETED",
  ONGOING: "ONGOING",
  PENDING: "PENDING",
} as const;
