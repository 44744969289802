import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { ModalProps } from "react-bootstrap";
import CapTableEmpty from "static/images/CapTableEmptyState.svg";
import { useGetAllCapTablesQuery } from "store/apis/capTable";
import { CapTable } from "./CapTable";
import { UploadCapTable } from "./UploadCapTable";
import { DashboardLayout } from "components/DashboardLayout";
import { useParams } from "react-router-dom";

const EmptyCapTable = ({ open }: ModalProps) => {
  return (
    <>
      <div className="t-w-full t-h-full t-flex t-flex-col t-items-center t-justify-center t-gap-3">
        <img src={CapTableEmpty} alt="CapTableEmpty" />
        <div className="t-flex t-flex-col t-gap-1 t-items-center t-text-center">
          <div className="t-text-title-h2-bold">Nothing here yet!</div>
          <div className="t-text-body t-text-text-30">
            Upload your Cap Table to get started.
          </div>
        </div>
        <Button customType="primary" onClick={open}>
          Upload
        </Button>
      </div>
    </>
  );
};

export const CapTableHome = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { entityId } = useParams<{ entityId: string }>();
  const { isOpen, open, close } = useModal();

  const { data, isLoading, isSuccess } = useGetAllCapTablesQuery({
    pageNum: 1,
    groupId,
    entityId,
  });

  const { cap_tables: capTableData = [] } = data || {};

  const isEmpty = capTableData.length === 0;

  return (
    <div className="t-flex t-gap-3 t-flex-col">
      <Header
        v2
        title="Cap Table"
        right={
          <div className="t-flex t-gap-3 t-items-center">
            <Button customType="primary" onClick={open} size="small">
              Upload
            </Button>
          </div>
        }
      />
      <Async.Root {...{ isLoading, isEmpty, isSuccess }}>
        <Async.Empty>
          <EmptyCapTable open={open} />
        </Async.Empty>
        <Async.Success>
          <CapTable />
        </Async.Success>
        <UploadCapTable isOpen={isOpen} close={close} />
      </Async.Root>
    </div>
  );
};
