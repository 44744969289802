import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import { AccountCard } from "components/OnboardingWidget/Tax/ConnectBank";
import { BANK_ACCESS_HELP } from "constants/task";
import { useEffect, useState } from "react";
import CheckCircle from "static/images/CheckCircle.svg";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { openLink } from "utils/openLink";

export const ShareBookkeepingAccess = ({
  entityId,
  groupId,
  setCurrentStep,
  connectedBankCount,
}: {
  entityId: string;
  groupId: string;
  setCurrentStep: (step: number) => void;
  connectedBankCount: number;
}) => {
  const [isChecked, setIsChecked] = useState(true);
  const {
    data: banksAccounts,
    isLoading,
    isSuccess,
  } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );
  const accounts = banksAccounts?.accounts || [];

  useEffect(() => {
    setIsChecked(connectedBankCount == accounts.length);
  }, [accounts.length]);

  return (
    <>
      <Modal.Body className="t-h-[482px]">
        <div className="t-text-subtitle t-text-text-60">
          Share bookkeeping access
        </div>
        <div className="t-text-body t-text-text-30 t-mt-2 t-mb-4">
          Add support@inkle.io as a bookkeeper/accountant to your US Banks via
          net-banking. We need to download bank statements in order to reconcile
          transactions on our platform with your bank accounts.
        </div>
        <div className="t-text-subtext-sm t-text-text-100 t-mb-4 t-flex t-gap-1">
          How to share bank access?{" "}
          <Button
            customType="link"
            size="small"
            onClick={() => openLink(BANK_ACCESS_HELP)}
          >
            See here
          </Button>
        </div>
        <Async.Root
          {...{
            isLoading: isLoading,
            isSuccess: isSuccess,
            isEmpty: isSuccess && accounts.length === 0,
          }}
        >
          <Async.Empty>
            <></>
          </Async.Empty>
          <Async.Success>
            <Accordion.Root
              type="single"
              className="t-mb-4 t-transition-all"
              collapsible
            >
              <Accordion.Item value="accountsConnected">
                <Accordion.Trigger>
                  <div className="t-text-subtext t-text-text-100 t-flex t-items-center t-gap-2">
                    {banksAccounts?.accounts?.length} accounts connected
                    <img src={CheckCircle} alt="CheckCircle" />
                  </div>
                </Accordion.Trigger>
                <Accordion.Content className="t-text-body-sm t-text-text-60 t-mt-3 t-grid t-gap-4">
                  {accounts.length > 0 && (
                    <div className="t-flex t-flex-col">
                      <div className="t-mb-3 t-flex t-flex-col t-gap-3 t-max-h-72 t-overflow-y-auto">
                        {accounts.map((account) => (
                          <AccountCard
                            key={account.account.uuid}
                            mask={account.account.mask}
                            bankName={account.bank_brand.name}
                            accountName={account.account.nickname}
                            logo={account.bank_brand.logo}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </Async.Success>
        </Async.Root>
        <Checkbox
          label={
            <div className="t-text-body t-text-text-60">
              I confirm that I have added support@inkle.io as the bookkeeper for
              all bank accounts linked to my entity.
            </div>
          }
          onChange={(e) => setIsChecked(e.target.checked)}
          checked={isChecked}
          name="shareBookkeepingAccess"
        />
      </Modal.Body>
      <Modal.Footer className="t-flex t-justify-between">
        <Button onClick={() => setCurrentStep(1)}>Back</Button>
        <Button
          customType="primary"
          onClick={() => setCurrentStep(3)}
          disabled={!isChecked}
        >
          Next
        </Button>
      </Modal.Footer>
    </>
  );
};
