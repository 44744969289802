import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useDispatch } from "react-redux";
import { useDeleteTransactionMutation } from "store/apis/transactions";
import { closeSlider } from "store/slices/transactions";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const DeleteTransaction = ({
  close,
  isOpen,
  transactionId,
}: ModalProps & { transactionId: string }) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const entityId = useCurrentEntityId();
  const [deleteTransaction] = useDeleteTransactionMutation();

  const onTransactionDelete = async () => {
    try {
      await deleteTransaction({
        entityId,
        groupId,
        transactionId,
      }).unwrap();
      close();
      dispatch(closeSlider(transactionId));
      successToast({ message: "Transaction deleted!" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Formik initialValues={{}} onSubmit={onTransactionDelete}>
        {({ submitForm, isSubmitting }) => (
          <Form className="t-m-0 t-w-full">
            <Modal.Content size="regular">
              <Modal.Header>
                <Modal.Title>Are you sure?</Modal.Title>
                <Modal.Close type="button" />
              </Modal.Header>
              <Modal.Body>
                Deleting this transaction will cause changes in all associated
                reports. This cannot be undone.
              </Modal.Body>
              <Modal.Footer>
                <div className="t-flex t-gap-4 t-justify-end">
                  <Button onClick={close} type="button" disabled={isSubmitting}>
                    Cancel
                  </Button>
                  <Button
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                    customType="danger"
                    onClick={submitForm}
                    type="submit"
                  >
                    Delete
                  </Button>
                </div>
              </Modal.Footer>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
