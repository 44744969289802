import { COPIED_TEXT, FAILED_COPIED_TEXT } from "constants/documents";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext, useEffect } from "react";
import { useDispatch } from "react-redux";
import Coin from "static/images/Coin.svg";
import ReferCoins from "static/images/ReferCoins.svg";
import ThreeDots from "static/images/ThreeDots.svg";
import { useGetCreditsQuery } from "store/apis/refrral";
import { setCreditAmountToAdd, setPaymentTitle } from "store/slices/credit";
import { AddCredits } from "./billing/Credits/AddCredit";
import { AutoPayCreditModal } from "./billing/Credits/AutoPayCreditModal";
import { Button } from "./DesignSystem/Button/Button";
import Dropdown from "./DesignSystem/Dropdown/Dropdown";
import { PromoCard, PromoCardItem } from "./PromoCard/PromoCard";

export const CreditsCard = ({ refetch }: { refetch?: () => void }) => {
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const { data } = useGetCreditsQuery({ groupId }, { skip: !groupId });
  const { total_credits } = data || {};

  const {
    authtoken: { referral_link },
  } = useContext(authContext);

  const { alertToast, successToast } = useToast();

  const addCredits = useQuery().get("add_credits");
  const { update } = useUpdateQuery();
  const {
    open: openPaymentModal,
    close: closePaymentModal,
    isOpen: showPaymentModal,
  } = useModal();

  useEffect(() => {
    if (addCredits) {
      openPaymentModal();
    }
  }, [addCredits]);

  const autoPayCreditModal = useModal();
  const query = useQuery();
  const creditsToPurchase = query.get("credits_to_purchase");
  const dispatch = useDispatch();
  const entityId = query.get("entity");
  const currentEntity = entities.find(({ uuid }) => entityId === uuid);

  useEffect(() => {
    if (creditsToPurchase !== null) {
      dispatch(setCreditAmountToAdd(+creditsToPurchase));
      dispatch(setPaymentTitle("Inkle Credits"));
      openPaymentModal();
    }
  }, [creditsToPurchase]);

  const closePaymentModalWithReset = () => {
    update({
      query: "add_credits",
      value: null,
    });
    closePaymentModal();
  };

  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(referral_link);
      successToast({ message: COPIED_TEXT });
    } catch (err) {
      alertToast({ message: FAILED_COPIED_TEXT });
    }
    return false;
  };

  return (
    <>
      <div className="t-mb-8 t-flex t-gap-6">
        <PromoCard
          title={
            <div className="t-flex t-justify-between t-items-center">
              <span>You can use these credits for:</span>
              <Dropdown.Root>
                <Dropdown.Trigger asChild>
                  <Button customType="ghost_icon" size="extra-small">
                    <img src={ThreeDots} alt="Show more options" />
                  </Button>
                </Dropdown.Trigger>
                <Dropdown.Portal>
                  <Dropdown.Content
                    sideOffset={4}
                    align="start"
                    side="bottom"
                    className="t-max-h-56 t-overflow-auto t-max-w-md"
                  >
                    <Dropdown.Item onSelect={autoPayCreditModal.open}>
                      Manage Autopay
                    </Dropdown.Item>
                  </Dropdown.Content>
                </Dropdown.Portal>
              </Dropdown.Root>
            </div>
          }
          image={
            <>
              <span className="t-flex t-flex-col t-gap-4 t-items-center t-justify-center">
                <span className="t-text-body-sm t-text-text-30">
                  Inkle Credits
                </span>
                <span className="t-flex  t-items-center t-justify-center">
                  <img src={Coin} alt="credits" />
                  <span className="credit-points t-text-display-h3 t-font-bold">
                    ${total_credits}
                  </span>
                </span>
                <span className="t-bg-neutral-10 t-rounded t-text-body-sm t-px-3 t-py-1 t-text-text-60">
                  Autopay{" "}
                  {currentEntity?.autopay_credits ? "enabled" : "disabled"}
                </span>
              </span>
            </>
          }
          CTA={
            <Button
              customType="primary-outlined"
              block
              onClick={openPaymentModal}
            >
              Add credits
            </Button>
          }
        >
          <PromoCardItem>Tax and compliance filings</PromoCardItem>
          <PromoCardItem>Bookkeeping</PromoCardItem>
        </PromoCard>
        <PromoCard
          title="How to earn credits:"
          image={<img src={ReferCoins} alt="ReferCoinsIllustration" />}
          CTA={
            <Button
              customType="primary-outlined"
              block
              onClick={copyToClipBoard}
            >
              Copy referral link
            </Button>
          }
        >
          <PromoCardItem>Invite your friends </PromoCardItem>
          <PromoCardItem>
            Receive <span className="t-text-purple">$100 Inkle credits.</span>
          </PromoCardItem>
        </PromoCard>

        <AddCredits
          showPaymentModal={showPaymentModal}
          closePaymentModal={closePaymentModalWithReset}
          refetch={refetch}
        />
      </div>
      {autoPayCreditModal && (
        <AutoPayCreditModal
          isOpen={autoPayCreditModal.isOpen}
          close={autoPayCreditModal.close}
        />
      )}
    </>
  );
};
