import React from "react";

export const RegisteredStateInfoItem = ({
  label,
  value,
}: {
  label: string;
  value: string;
}) => {
  return (
    <div className="t-space-y-1.5">
      <div className="t-text-body-sm t-text-text-30">{label}</div>
      <div className="t-text-body t-text-text-100">{value}</div>
    </div>
  );
};
