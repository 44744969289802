export const Accounting = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_305_14708)">
      <path
        d="M2.5835 16H15.4168V3.75C15.4168 3.28587 15.2325 2.84075 14.9043 2.51256C14.5761 2.18437 14.131 2 13.6668 2H4.3335C3.86937 2 3.42425 2.18437 3.09606 2.51256C2.76787 2.84075 2.5835 3.28587 2.5835 3.75V16ZM3.75016 3.75C3.75016 3.59529 3.81162 3.44692 3.92102 3.33752C4.03041 3.22812 4.17879 3.16667 4.3335 3.16667H13.6668C13.8215 3.16667 13.9699 3.22812 14.0793 3.33752C14.1887 3.44692 14.2502 3.59529 14.2502 3.75V14.8333H3.75016V3.75ZM4.91683 7.83333H13.0835V4.33333H4.91683V7.83333ZM6.0835 5.5H11.9168V6.66667H6.0835V5.5ZM4.91683 9.58333H6.0835V10.75H4.91683V9.58333ZM7.25016 9.58333H8.41683V10.75H7.25016V9.58333ZM10.7502 9.58333V10.75H9.5835V9.58333H10.7502ZM4.91683 11.9167H6.0835V13.0833H4.91683V11.9167ZM7.25016 11.9167H8.41683V13.0833H7.25016V11.9167ZM11.9168 9.58333H13.0835V10.75H11.9168V9.58333ZM9.5835 11.9167H13.0835V13.0833H9.5835V11.9167Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_305_14708">
        <rect width="14" height="14" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);
