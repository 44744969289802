import { AmountSuperScript } from "components/design/AmountSuperScript";
import ToolTip from "components/design/toolTip";
import { SmallInfo } from "components/icons/SmallInfo";
import { useFormikContext } from "formik";
import React, { useMemo } from "react";
import { formatDate } from "utils/formatDate";

export const AnnualBooksCalculate = ({
  deadline,
}: {
  deadline: string | null;
}) => {
  const { values } = useFormikContext<{
    total_revenue: string;
    total_expense: string;
    r_and_d_expense: string;
    taxes_paid_in_advance: string;
  }>();
  const {
    total_revenue,
    total_expense,
    r_and_d_expense = 0,
    taxes_paid_in_advance,
  } = values;

  const netProfit = useMemo(() => {
    if (!total_revenue || !total_expense) {
      return null;
    }

    return (
      Number(total_revenue) -
      (Number(total_expense) - Number(r_and_d_expense)) -
      Number(r_and_d_expense) / 15
    );
  }, [total_revenue, total_expense, r_and_d_expense]);
  let estimatedTaxAmount = netProfit ? (netProfit * 21) / 100 : null;
  let isLoss = false;

  if (netProfit && netProfit < 0) {
    estimatedTaxAmount = 0;
    isLoss = true;
  }

  return (
    <div className="t-p-4 t-bg-surface-lighter-grey t-border t-border-solid t-border-neutral-10">
      <div className="t-flex t-justify-between">
        <div className="t-space-y-1">
          <div className="t-text-text-30 t-text-body-sm">
            {netProfit && netProfit < 0 ? "Net Loss" : "Net Profit"}
          </div>
          <div className="t-text-body t-text-text-100 t-mt-0.5">
            {netProfit ? (
              <AmountSuperScript amount={isLoss ? netProfit * -1 : netProfit} />
            ) : (
              "-"
            )}
          </div>
          <div className="t-text-body-sm t-text-text-30 t-mt-1">
            how did we calculate
            <ToolTip
              text={
                <div>
                  Net Profit = Revenue - (Total Expenses - R&D Expenses)-(R&D
                  Expenses / 15). <br />
                  Per Section 174, R&D expenses incurred outside the U.S. must
                  be amortized over 15 years, meaning only 1/15th of those
                  expenses can be deducted in a single year.
                </div>
              }
            >
              <span>
                <SmallInfo />
              </span>
            </ToolTip>
          </div>
        </div>
        <div className="t-space-y-1">
          <div className="t-text-text-30 t-text-body-sm">
            Estimated Tax Amount
          </div>
          <div className="t-text-body t-text-text-100 t-mt-0.5">
            {estimatedTaxAmount || estimatedTaxAmount == 0
              ? `$${estimatedTaxAmount.toFixed(2)}`
              : "-"}
          </div>
          <div className="t-text-body-sm t-text-text-30 t-mt-1">
            how did we calculate
            <ToolTip text="Estimated Tax Amount = 21% of Net Profit">
              <span>
                <SmallInfo />
              </span>
            </ToolTip>
          </div>
        </div>
        <div className="t-space-y-1">
          <div className="t-text-text-30 t-text-body-sm">
            Deadline for tax payment
          </div>
          <div className="t-text-body t-text-text-100 t-mt-0.5">
            {isLoss ? "-" : deadline ? formatDate(deadline) : "-"}
          </div>
        </div>
      </div>
      <div className="t-mt-4">
        {(taxes_paid_in_advance || taxes_paid_in_advance == "") && (
          <div className="t-flex t-items-center t-text-subtext-sm t-gap-1">
            <div className="t-text-text-30">Total tax due: </div>
            <div className="t-text-text-60">
              {estimatedTaxAmount
                ? `$${(
                    estimatedTaxAmount - Number(taxes_paid_in_advance || 0)
                  ).toFixed(2)}`
                : "-"}
            </div>
            <ToolTip text="Total Tax Due = Estimated Tax Amount - Taxes paid in advance">
              <span className="t-text-text-30">
                <SmallInfo />
              </span>
            </ToolTip>
          </div>
        )}
        <div className="t-text-body-sm t-text-text-30">
          {isLoss
            ? "Since your business had a net loss in 2024, you won’t owe federal income tax for this year."
            : "This is an estimate, our team will review your submission and provide the exact amount."}
        </div>
      </div>
    </div>
  );
};
