import { useGetGroupDataQuery } from "store/apis/group";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { useGetCartQuery } from "store/apis/products";
import { Entity } from "types/Models/entity";
import { useAppDispatch } from "hooks/useAppDispatch";
import { setCartInChat } from "store/slices/cartInChat";
import { entitiesSortedByCountry } from "utils/entitiesSortedByCountry";
import Async from "components/DesignSystem/AsyncComponents/Async";
import Loader from "components/design/loader";

export const AttachCart = ({ groupId }: { groupId: string }) => {
  const {
    data: entities,
    isLoading,
    isSuccess,
  } = useGetGroupDataQuery(
    { groupId },
    {
      skip: !groupId,
    }
  );

  const { sortedEntities } = entitiesSortedByCountry(entities?.entities || []);

  return (
    <Async.Root
      isLoading={isLoading}
      isSuccess={isSuccess}
      customLoader={
        <div className="t-flex t-items-center t-justify-center t-h-24 t-w-full">
          <Loader size="regular" />
        </div>
      }
      isEmpty={sortedEntities?.length === 0}
    >
      <Async.Success>
        {sortedEntities?.map((entity) => (
          <EntitiesWithCartItemsCount
            key={entity.uuid}
            entity={entity}
            groupId={groupId}
          />
        ))}
      </Async.Success>
    </Async.Root>
  );
};

const EntitiesWithCartItemsCount = ({
  entity,
  groupId,
}: {
  entity: Entity;
  groupId: string;
}) => {
  const dispatch = useAppDispatch();
  const { data: cart, isLoading: cartIsLoading } = useGetCartQuery(
    { entityId: entity.uuid, groupId },
    { skip: !entity.uuid || !groupId }
  );

  return (
    <>
      <DropDown.Item
        key={entity.uuid}
        disabled={cartIsLoading || cart?.cart_items?.length === 0}
      >
        <section
          className="t-flex t-items-center t-justify-between t-px-1 t-w-full t-cursor-pointer t-gap-1"
          onClick={() =>
            dispatch(
              setCartInChat({
                entityId:
                  cartIsLoading || cart?.cart_items?.length === 0
                    ? ""
                    : entity.uuid,
              })
            )
          }
        >
          <div className="t-flex t-items-center t-gap-1.5">
            <div>{entity.name}</div>
          </div>
          <div>
            ({cart?.cart_items?.length}{" "}
            {cart?.cart_items?.length === 1 ? "item" : "items"})
          </div>
        </section>
      </DropDown.Item>
    </>
  );
};
