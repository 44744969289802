import React, { useEffect } from "react";
import * as RDialog from "@radix-ui/react-dialog";
import Modal from "components/DesignSystem/Modal/Modal";
import { Button } from "components/DesignSystem/Button/Button";
import { Cross } from "components/icons/Cross";
import TaxPage from "./taxPage";
import { useHistory } from "react-router-dom";
import classNames from "classnames";

const CustomOverlay = (
  props: RDialog.DialogOverlayProps & React.RefAttributes<HTMLDivElement>
) => {
  useEffect(() => {
    document.body.classList.add("pointer-events-none");

    return () => {
      document.body.classList.remove("pointer-events-none");
    };
  }, []);

  return (
    <div
      className={classNames(
        "t-fixed t-inset-0 t-z-modal t-bg-text-100 !t-opacity-80 t-bg-image-preview-overlay !t-bg-surface-transparent",
        props.className || ""
      )}
    />
  );
};

export const FilingDetails = (props: {
  isFromServices?: boolean;
  parentRoute?: string;
  addEntityInUrl?: boolean;
}) => {
  const history = useHistory();

  const handleBack = () => {
    if (history.length > 1) {
      history.goBack();
    } else {
      window.close();
    }
  };

  return (
    <RDialog.Root open={true} onOpenChange={handleBack}>
      <RDialog.Portal>
        <CustomOverlay />
        <RDialog.Content className="t-fixed t-z-modal t-top-[50%] t-left-[50%] t-translate-x-[-50%] t-translate-y-[-50%] t-w-full t-pr-6 t-h-full t-bg-surface t-grid t-grid-rows-[3%_97%]">
          <div className="t-flex t-w-full t-justify-end t-mt-2">
            <RDialog.Close asChild>
              <span className="t-text-text-100">
                <Button customType="icon" size="small" onClick={handleBack}>
                  <Cross color="currentColor" />
                </Button>
              </span>
            </RDialog.Close>
          </div>
          <TaxPage {...props} />
        </RDialog.Content>
      </RDialog.Portal>
    </RDialog.Root>
  );
};
