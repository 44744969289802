import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { LAUNCHED_INTRAGROUP_SERVICE } from "constants/analyticsEvents";
import dayjs from "dayjs";
import { Form, Formik, FormikValues } from "formik";
import { addTaskSchema } from "formValidations/addTaskSchema";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useCreateTaskMutation } from "store/apis/getTemplates";
import { closeIntragroupTask } from "store/slices/IntragroupService";
import { RootState } from "store/store";
import { BackendError } from "types/utils/error";
import { IntragroupServiceLaunchBody } from "./IntragroupServiceLaunchBody";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

export const IntragroupServiceActionModal = ({
  isOpen,
  parentRoute,
}: {
  isOpen: boolean;
  parentRoute: string;
}) => {
  const { successToast, alertToast } = useToast();
  const { selectedIntragroupTaskTemplate, callbackUrlOnModalClose } =
    useSelector((state: RootState) => state.intragroupList);
  const [createTask, { isLoading: isTaskCreating }] = useCreateTaskMutation();
  const history = useHistory();
  const entityId = useCurrentEntityId();
  const { isCustomer } = useRoleBasedView();
  const dispatch = useDispatch();
  const {
    title,
    approx_price: amount,
    uuid,
    description,
    seasons,
    default_task_template,
  } = selectedIntragroupTaskTemplate;
  const { entities } = useCurrentGroupContext();
  const { trackEvent } = useAnalytics();

  const handleSubmit = async (values: FormikValues) => {
    try {
      const { entity, season } = values;
      let payload: { base_task_template_id: string; season?: string } = {
        base_task_template_id: uuid as string,
      };

      if (!default_task_template) {
        payload = { ...payload, season };
      }

      const task = await createTask({
        entity,
        metaData: payload,
      }).unwrap();
      successToast({ message: "Service created!" });
      dispatch(closeIntragroupTask());
      const redirectUrl = isCustomer
        ? `/${parentRoute}/services/${task.uuid}?entity=${entity}`
        : `/tax/filings/${task.uuid}`;

      trackEvent(LAUNCHED_INTRAGROUP_SERVICE);
      history.replace(redirectUrl);
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const handleModalClose = () => {
    if (callbackUrlOnModalClose) {
      history.push(callbackUrlOnModalClose);
    }
    dispatch(closeIntragroupTask());
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={handleModalClose} modal={false}>
      <Modal.Content useCustomOverlay>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          onSubmit={handleSubmit}
          initialValues={{
            entity: entityId || entities?.[0]?.uuid || "",
            season: dayjs().subtract(1, "year").get("year").toString(),
          }}
          validationSchema={addTaskSchema}
        >
          <Form className="all:unset">
            <Modal.Body>
              <IntragroupServiceLaunchBody
                amount={amount}
                description={description}
                seasons={seasons}
                defaultTaskTemplate={default_task_template}
              />
            </Modal.Body>
            <Modal.Footer className="t-justify-end t-flex">
              <Button
                customType="primary"
                isLoading={isTaskCreating}
                disabled={isTaskCreating}
                type="submit"
              >
                Start now
              </Button>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
