import React from "react";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import { OptionDropdown } from "components/icons/optionDropdown";
import { useToggleChannelRepliedMutation } from "store/apis/chat";
import { useToast } from "hooks/useToast";

const MarkRepliedUnreplied = ({
  showDropdown,
  setShowDropdown,
  unreplied,
  channelId,
}: {
  showDropdown: boolean;
  setShowDropdown: (v: boolean) => void;
  unreplied?: boolean;
  channelId: string;
}) => {
  const { successToast } = useToast();
  const [toggleChannelRepliedStatus, { isLoading }] =
    useToggleChannelRepliedMutation();

  const toggleChannelReply = async () => {
    try {
      if (channelId) {
        await toggleChannelRepliedStatus({
          channelId,
          channelNeedsReply: unreplied ? false : true,
        });
        successToast({
          title: "Channel update",
          message: `Channel marked as ${unreplied ? "replied" : "unreplied"}.`,
        });
      }
    } catch (e: any) {
      alert(e?.data?.error?.message);
    }
  };

  return (
    <DropDown.Root onOpenChange={setShowDropdown} open={showDropdown}>
      <DropDown.Trigger asChild disabled={isLoading}>
        <div className="t-size-6 t-rounded hover:t-bg-purple-20 data-state-open:t-bg-purple-20 t-items-center t-justify-center t-flex">
          <OptionDropdown color="currentColor" />
        </div>
      </DropDown.Trigger>
      <DropDown.Portal>
        <DropDown.Content className="!t-min-w-24" sideOffset={8} align="start">
          <DropDown.Item onSelect={toggleChannelReply}>
            {unreplied ? "Mark as replied" : "Mark as unreplied"}
          </DropDown.Item>
        </DropDown.Content>
      </DropDown.Portal>
    </DropDown.Root>
  );
};

export default MarkRepliedUnreplied;
