import { DashboardLayout } from "components/DashboardLayout";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Header } from "components/DesignSystem/Header/Header";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { AllTodos } from "components/TodoList/AllTodos";
import { BooksTodos } from "components/TodoList/BooksTodos";
import { TaxTodos } from "components/TodoList/TaxTodos";
import { ALL, INKLE_BOOKS, INKLE_TAX } from "constants/home";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useEffect, useMemo, useState } from "react";
import {
  TaxActionItemType,
  useGetActionItemsQuery,
} from "store/apis/actionItems";
import EmptyActionItem from "static/images/EmptyActionItem.svg";
import { BooksActionItems } from "types/Models/books";
import { usePaginatedMessages } from "hooks/usePaginatedMessages";
import { REQUESTED_INFO_CUSTOM_TYPE } from "constants/chatType";
import { CHAT_REQUESTS } from "constants/bookkeeping";
import { useChatContext } from "stream-chat-react";
import InfoBlue from "static/images/InfoBlue.svg";
import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { LargeInfo } from "components/icons/LargeInfo";
import {
  TODO_TAB_CLICKED_INSIDE_TODO,
  TODOS_PAGE_OPENED,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";

export const TodoList = () => {
  const [selectedTab, setSelectedTab] = useState<
    typeof INKLE_TAX | typeof INKLE_BOOKS | typeof ALL
  >(ALL);
  const { uuid: groupId } = useCurrentGroupContext();
  const { client } = useChatContext();
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    trackEvent(TODOS_PAGE_OPENED);
  }, []);

  const {
    data = { tax_action_items: [] },
    isSuccess: isTaxSuccess,
    isLoading: isTaxLoading,
  } = useGetActionItemsQuery(
    {
      groupId: groupId,
      type: "TAX",
    },
    {
      skip: !groupId,
    }
  );

  const {
    data: booksData = { book_action_items: [] },
    isSuccess: isBooksSuccess,
    isLoading: isBooksLoading,
  } = useGetActionItemsQuery(
    {
      groupId: groupId,
      type: "BOOKS",
    },
    {
      skip: !groupId,
    }
  );

  const { action_items_response, channel_url } =
    (booksData as BooksActionItems) || {};

  const actionItems =
    action_items_response?.filter(
      ({ item_type }) =>
        item_type !== "Reconciliation" && item_type !== "Financial Closing"
    ) || [];

  const { messages: chatActionItems } = usePaginatedMessages(
    client,
    {
      id: channel_url,
    },
    {},
    {},
    {
      custom_type: {
        $in: [REQUESTED_INFO_CUSTOM_TYPE],
      },
      card_status: { $eq: "REQUESTED" },
    },
    !channel_url
  );

  const chatCards = chatActionItems?.map(
    ({
      message: {
        custom_data: { header, transaction_count },
        id,
      },
    }: any) => ({
      header,
      transaction_count,
      id,
      channel_url,
      item_type: CHAT_REQUESTS,
    })
  );

  const allBooksActionItems = actionItems.flatMap(({ items, item_type }) =>
    items.map((item) => ({ ...item, item_type }))
  );

  const totalBooksItemsCount = allBooksActionItems.length + chatCards.length;

  const totalTaxItemsCount = (data as { tax_action_items: TaxActionItemType[] })
    ?.tax_action_items?.length;

  const isEmpty = totalTaxItemsCount === 0 && totalBooksItemsCount === 0;

  const taxActionItems = useMemo(() => {
    if (!("tax_action_items" in data)) return [];
    const taxItems = (data as { tax_action_items: TaxActionItemType[] })
      .tax_action_items;
    return [...taxItems].sort((a, b) => {
      if (a.order == null && b.order == null) return 0;
      if (a.order == null) return 1;
      if (b.order == null) return -1;
      return a.order - b.order;
    });
  }, [data]);

  return (
    <DashboardLayout
      header={
        <div className="t-sticky t-top-0">
          <Header
            v2
            title="Action Centre"
            className="t-border-b-0"
            bottom={
              <div className="t-mt-2">
                <ToggleGroup
                  value={selectedTab}
                  onValueChange={(value: string) => {
                    if (value) {
                      setSelectedTab(
                        value as
                          | typeof INKLE_TAX
                          | typeof INKLE_BOOKS
                          | typeof ALL
                      );
                    }
                  }}
                >
                  <ToggleGroupItem
                    value={ALL}
                    onClick={() => trackEvent(TODO_TAB_CLICKED_INSIDE_TODO)}
                  >
                    <div className="t-w-[124px] t-flex t-items-center t-justify-center t-gap-1">
                      <div>All</div>
                      <div className="t-text-body-sm t-text-text-30">
                        ({totalTaxItemsCount + totalBooksItemsCount})
                      </div>
                    </div>
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    value={INKLE_TAX}
                    onClick={() => trackEvent(TODO_TAB_CLICKED_INSIDE_TODO)}
                  >
                    <div className="t-w-[124px] t-flex t-items-center t-justify-center t-gap-1">
                      <div>Tax</div>
                      {totalTaxItemsCount > 0 && (
                        <div className="t-text-body-sm t-text-text-30">
                          ({totalTaxItemsCount})
                        </div>
                      )}
                    </div>
                  </ToggleGroupItem>
                  <ToggleGroupItem
                    value={INKLE_BOOKS}
                    onClick={() => trackEvent(TODO_TAB_CLICKED_INSIDE_TODO)}
                  >
                    <div className="t-w-[124px] t-flex t-items-center t-justify-center t-gap-1">
                      <div>Books</div>
                      {totalBooksItemsCount > 0 && (
                        <div className="t-text-body-sm t-text-text-30">
                          ({totalBooksItemsCount})
                        </div>
                      )}
                    </div>
                  </ToggleGroupItem>
                </ToggleGroup>
              </div>
            }
          />
        </div>
      }
    >
      <Async.Root
        {...{
          isEmpty,
          isLoading:
            isTaxLoading || isBooksLoading || !isTaxSuccess || !isBooksSuccess,
          isSuccess: isTaxSuccess && isBooksSuccess,
        }}
      >
        <Async.Empty>
          <div className="t-flex t-items-center t-justify-center t-h-full">
            <img src={EmptyActionItem} alt="noActions" />
          </div>
        </Async.Empty>
        <Async.Success>
          <div className="t-w-2/3 t-min-w-[780px]">
            {selectedTab === ALL && (
              <AllTodos taxActionItems={taxActionItems} />
            )}
            {selectedTab === INKLE_TAX && (
              <TaxTodos taxActionItems={taxActionItems} />
            )}
            {selectedTab === INKLE_BOOKS && <BooksTodos />}
            <div className="t-flex t-items-center t-justify-between t-px-3 t-py-2 t-rounded-lg t-bg-blue-0 t-mt-6">
              <div className="t-flex t-items-center t-gap-2">
                <div className="t-text-blue">
                  <LargeInfo />
                </div>
                <div className="t-text-text-60 t-text-body-sm t-mt-0.5">
                  Check the status of your support tickets
                </div>
              </div>
              <ConditionalLink
                to="/tickets"
                target="_blank"
                className="t-text-blue"
              >
                <Button size="small" customType="transparent">
                  View
                </Button>
              </ConditionalLink>
            </div>
          </div>
        </Async.Success>
      </Async.Root>
    </DashboardLayout>
  );
};
