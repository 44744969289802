import { Input } from "components/design/input";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { newTeam } from "formValidations/newMemberSchema";
import { Search } from "components/DesignSystem/Search/Search";
import { debounce } from "utils/debouncing";
import { useEffect, useState } from "react";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { useToast } from "hooks/useToast";
import { TaskName } from "./TaskAccessModal";
import {
  InternalTeamResponse,
  useAddInternalTeamMutation,
  useEditInternalTeamAccessMutation,
  useGetAllInkleCPATeamsQuery,
  useGetInternalTeamTaskAccessListQuery,
} from "store/apis/internalTeamSetting";
import { useRoleBasedView } from "hooks/useRoleBasedView";

type AddInternalTeamProps = {
  show: boolean;
  closeModal: () => void;
  team?: InternalTeamResponse | null;
};

export const AddInternalTeam = ({
  show,
  closeModal,
  team,
}: AddInternalTeamProps) => {
  const [searchTerm, setSearchTerm] = useState<null | string>(null);
  const [selectedTasks, setSelectedTasks] = useState(new Set<string>());
  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const { uuid: groupId } = useCurrentGroupContext();
  const { isAdmin } = useRoleBasedView();
  const { data: teamOptions = [] } = useGetAllInkleCPATeamsQuery(
    {},
    { skip: !isAdmin }
  );
  const { data: internalTeamTask, isLoading } =
    useGetInternalTeamTaskAccessListQuery(
      {
        searchTerm,
        groupId,
        teamId: (selectedTeamId || team?.uuid) as string,
      },
      {
        skip: !groupId || (!selectedTeamId && !team?.uuid),
        refetchOnMountOrArgChange: true,
      }
    );
  const [addInternalTeam, { isLoading: isAdding }] =
    useAddInternalTeamMutation();
  const [updateInternalTeamAccess, { isLoading: isUpdating }] =
    useEditInternalTeamAccessMutation();
  const { successToast, alertToast } = useToast();
  const taskList = internalTeamTask?.task_list || [];
  const noTaskFound = taskList?.length === 0 && !isLoading;
  const accessEditFlow = Boolean(team?.uuid);
  const modalTitle = accessEditFlow ? "Allow Access" : "Add Teams";

  const handleFormSubmit = async (values: {
    team: string;
    accessType: string;
  }) => {
    try {
      let payload: {
        all_access_allowed?: boolean;
        task_id_list?: string[];
      } = {};
      if (values.accessType === "allTaskIncludesFuture") {
        payload = { all_access_allowed: true };
      } else {
        payload = { task_id_list: Array.from(selectedTasks) };
      }
      await addInternalTeam({ groupId, teamId: values.team, payload }).unwrap();
      successToast({
        message: "Team added successfully",
        title: "Group",
      });
      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
      closeModal();
    }
  };

  const updateTaskAccess = async (values: { accessType: string }) => {
    try {
      let payload: {
        all_access_allowed?: boolean;
        task_id_list?: string[];
      } = {};
      if (values.accessType === "allTaskIncludesFuture") {
        payload = { all_access_allowed: true };
      } else {
        payload = { task_id_list: Array.from(selectedTasks) };
      }
      await updateInternalTeamAccess({
        groupId,
        teamId: team?.uuid!,
        payload,
      }).unwrap();
      successToast({
        message: "Team updated successfully",
        title: "Group",
      });
      closeModal();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
      closeModal();
    }
  };

  useEffect(() => {
    setSelectedTasks((s) => {
      const newList = new Set<string>();
      internalTeamTask?.task_list.forEach((task) => {
        if (task.has_task_access) {
          newList.add(task.uuid);
        }
      });

      return newList;
    });
  }, [internalTeamTask]);

  const handleSearch = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchTerm(value || null);
  });

  const onTaskSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = e.target;
    if (name === "allTask") {
      if (checked) {
        return setSelectedTasks((s) => {
          const newList = taskList.reduce(
            (acc, c) => acc.add(c.uuid),
            new Set<string>()
          );
          return newList;
        });
      }
      setSelectedTasks(new Set());
      return;
    }

    if (checked) {
      return setSelectedTasks((s) => {
        const newList = new Set(Array.from(s));
        newList.add(name);
        return newList;
      });
    }

    setSelectedTasks((s) => {
      const newList = new Set(Array.from(s));
      newList.delete(name);
      return newList;
    });
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Formik
        initialValues={{
          team: "",
          accessType: Boolean(team)
            ? team?.is_all_access_allowed
              ? "allTaskIncludesFuture"
              : "selectedTask"
            : "allTaskIncludesFuture",
        }}
        onSubmit={accessEditFlow ? updateTaskAccess : handleFormSubmit}
        validationSchema={!accessEditFlow && newTeam}
        validateOnBlur
        validateOnChange
        enableReinitialize
      >
        {({ values: { accessType }, submitForm, setFieldValue }) => (
          <Form>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>{modalTitle}</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <div className="t-m-0 t-flex t-flex-col t-gap-4">
                  {!accessEditFlow && (
                    <Combobox
                      label="Team"
                      menuPortalTarget={document.body}
                      withForm
                      name="team"
                      options={teamOptions.map((option) => {
                        return {
                          label: option.name,
                          value: option.uuid,
                        };
                      })}
                      onChange={({ value }: any) => {
                        setFieldValue("team", value);
                        setSelectedTeamId(value);
                      }}
                    />
                  )}
                  {(selectedTeamId || team?.uuid) && (
                    <div>
                      <label
                        htmlFor="accessType"
                        className="t-font-sans t-text-body-sm t-text-text-30 t-mb-1"
                      >
                        Access
                      </label>
                      <div
                        role="group"
                        aria-labelledby="form-selector-radio-group"
                        className="t-flex t-flex-col t-gap-2"
                      >
                        <Input
                          label={
                            <div className="t-cursor-pointer t-text-subtitle-sm t-font-medium">
                              All current and future filings
                            </div>
                          }
                          type="radio"
                          value="allTaskIncludesFuture"
                          name="accessType"
                          customInputClass="!t-h-4 !t-w-4 t-cursor-pointer"
                        />
                        <Input
                          label={
                            <div className="t-cursor-pointer t-text-subtitle-sm t-font-medium">
                              Limited filings
                            </div>
                          }
                          value="selectedTask"
                          type="radio"
                          name="accessType"
                          customInputClass="!t-h-4 !t-w-4 t-cursor-pointer"
                        />
                        {accessType === "selectedTask" && (
                          <div className="t-ml-8 t-flex t-flex-col t-gap-3">
                            <Search
                              onChange={handleSearch}
                              customSize="regular"
                              placeholder="Search for Filing"
                              block
                              autoFocus
                            />
                            {noTaskFound ? (
                              <div className="t-flex t-h-56 t-items-center t-justify-center ">
                                No filing found
                              </div>
                            ) : (
                              <div className="t-flex t-h-56 t-flex-col t-gap-4 t-overflow-auto t-p-2">
                                <Checkbox
                                  onChange={onTaskSelect}
                                  checked={taskList.every(({ uuid }) =>
                                    selectedTasks.has(uuid)
                                  )}
                                  label={
                                    <span className="t-text-subtext">
                                      Select all filings
                                    </span>
                                  }
                                  name="allTask"
                                />
                                {taskList.map(({ task_title, uuid }) => {
                                  return (
                                    <Checkbox
                                      key={uuid}
                                      onChange={onTaskSelect}
                                      checked={selectedTasks.has(uuid)}
                                      label={
                                        <TaskName task_title={task_title} />
                                      }
                                      name={uuid}
                                    />
                                  );
                                })}
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <div className="t-flex t-justify-end t-w-full t-gap-4">
                  <Button type="button" onClick={closeModal}>
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    customType="primary"
                    isLoading={isAdding || isUpdating}
                    disabled={isAdding || isUpdating}
                    onClick={submitForm}
                  >
                    Allow
                  </Button>
                </div>
              </Modal.Footer>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
