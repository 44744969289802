import React from "react";

export const TodoListIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.69401 6.33311L2.875 5.5141L3.60565 4.78345L4.42517 5.60246C4.52354 5.69652 4.6544 5.74901 4.7905 5.74901C4.9266 5.74901 5.05746 5.69652 5.15582 5.60246L7.0088 3.75L7.73945 4.48065L5.88647 6.33311C5.74252 6.47708 5.57162 6.59129 5.38353 6.66921C5.19544 6.74713 4.99384 6.78724 4.79024 6.78724C4.58665 6.78724 4.38505 6.74713 4.19695 6.66921C4.00886 6.59129 3.83796 6.47708 3.69401 6.33311ZM8.9243 6.27058H15.125V5.23713H8.9243V6.27058ZM4.7905 10.9211C4.99415 10.9217 5.19588 10.8818 5.38402 10.8039C5.57215 10.7259 5.74294 10.6114 5.88647 10.4669L7.73945 8.61445L7.0088 7.8838L5.15582 9.73626C5.05746 9.83032 4.9266 9.88281 4.7905 9.88281C4.6544 9.88281 4.52354 9.83032 4.42517 9.73626L3.60565 8.91725L2.875 9.6479L3.69401 10.4669C3.83768 10.6113 4.00856 10.7259 4.19677 10.8038C4.38498 10.8818 4.58678 10.9216 4.7905 10.9211ZM8.9243 10.4044H15.125V9.37093H8.9243V10.4044ZM4.7905 15.0549C4.99415 15.0555 5.19588 15.0156 5.38402 14.9377C5.57215 14.8597 5.74294 14.7452 5.88647 14.6007L7.73945 12.7482L7.0088 12.0176L5.15582 13.8701C5.05746 13.9641 4.9266 14.0166 4.7905 14.0166C4.6544 14.0166 4.52354 13.9641 4.42517 13.8701L3.60565 13.051L2.875 13.7817L3.69401 14.6007C3.83768 14.7451 4.00856 14.8596 4.19677 14.9376C4.38498 15.0156 4.58678 15.0554 4.7905 15.0549ZM8.9243 14.5382H15.125V13.5047H8.9243V14.5382Z"
      fill="currentColor"
    />
  </svg>
);
