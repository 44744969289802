// PDF
export const PDF = "PDF";
export const APPLICATION_PDF = "application/pdf";

// IMAGES
export const IMAGE = "IMAGE";
export const SVG = "SVG";
export const JPG = "JPG";
export const PNG = "PNG";
export const JPEG = "JPEG";
export const GIF = "GIF";
export const IMAGE_JPEG = "image/jpeg";
export const IMAGE_PNG = "image/png";
export const IMAGE_GIF = "image/gif";
export const IMAGE_BMP = "image/bmp";
export const IMAGE_SVG_XML = "image/svg+xml";

// CSV
export const CSV = "CSV";
export const TEXT_CSV = "text/csv";

// DOC
export const DOC = "DOC";
export const DOCX = "DOCX";
export const TXT = "TXT";
export const PPT = "PPT";
export const PPTX = "PPTX";
export const APPLICATION_VND_MS_POWERPOINT = "application/vnd.ms-powerpoint";
export const APPLICATION_VND_PRESENTATION =
  "application/vnd.openxmlformats-officedocument.presentationml.presentation";
export const APPLICATION_VND_DOCUMENT =
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const APPLICATION_MSWORD = "application/msword";

// EXCEL
export const APPLICATION_XML = "application/xml";
export const APPLICATION_VND_MS_EXCEL = "application/vnd.ms-excel";
export const EXCEL = "EXCEL";
export const XLSX = "XLSX";
export const XLSM = "XLSM";
export const XLSB = "XLSB";
export const XML = "XML";
export const XLR = "XLR";
export const XLA = "XLA";
export const XLS = "XLS";
export const XLSX1 =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const XLS1 = "application/vnd.ms-excel";

// FOLDER
export const FOLDER = "FOLDER";
export const LOCKED_FOLDER = "LOCKED_FOLDER";

// MULTI_FILES
export const MULTI_FILES = "MULTI_FILES";
