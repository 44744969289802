import React, { useContext, useEffect } from "react";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import {
  ADD_TASK_CLICKED,
  TASK_CREATED,
  TASK_CREATION_FAILED,
} from "constants/analyticsEvents";
import dayjs from "dayjs";
import { Form, Formik, useFormikContext } from "formik";
import {
  addAdhocTaskSchema,
  addTaskSchema,
} from "formValidations/addTaskSchema";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { parse, stringify } from "qs";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ArrowLeft from "static/images/BlackArrowLeft.svg";
import CalendarBlank from "static/images/CalendarBlank.svg";
import CoinVertical from "static/images/CoinVertical.svg";
import InfoGray from "static/images/InfoGray.svg";
import {
  UpdatedDeadlineResponse,
  useCreateTaskMutation,
  useGetSingleTaskTemplatesQuery,
  useLazyGetUpdatedDeadlinesQuery,
} from "store/apis/getTemplates";
import "../../../static/styles/components/addTask.css";
import { BackendError } from "types/utils/error";
import Modal from "components/DesignSystem/Modal/Modal";

type DisclaimerCardProps = {
  children: React.ReactNode;
};

type TaskFormData = {
  default_task_template?: boolean;
  seasons?: number[];
  states?: { name: string; state_id: string }[];
  description: string;
  approx_deadline?: string;
  approx_price?: string;
  government_fee?: string;
  title?: string;
};

type TaskFormProps = {
  data: TaskFormData;
  closeModal: () => void;
  disableLaunch?: boolean;
  templateId: string;
};

type AddTaskEntityModalProps = {
  to: string;
  redirectPathAfterCreate?: string;
  taskTemplateId?: string;
  handleClose?: () => void;
  disableLaunch?: boolean;
};

const DisclaimerCard = ({ children }: DisclaimerCardProps) => {
  return (
    <span className="t-rounded-lg t-bg-yellow-10 t-p-3 t-text-body-sm">
      <span className="t-font-medium">Disclaimer: </span>
      {children}
    </span>
  );
};

const Disclaimer = ({
  updatedDeadlines,
}: {
  updatedDeadlines: UpdatedDeadlineResponse | undefined;
}) => {
  const { is_fiscal, filing7004_filed } = updatedDeadlines || {};

  if (filing7004_filed && is_fiscal) {
    return (
      <DisclaimerCard>
        Since you are a fiscal year company and filed 7004, this deadline has
        been changed accordingly.
      </DisclaimerCard>
    );
  }

  if (is_fiscal) {
    return (
      <DisclaimerCard>
        Since you are a fiscal year company, this deadline has been changed
        accordingly.
      </DisclaimerCard>
    );
  }

  if (filing7004_filed) {
    return (
      <DisclaimerCard>
        Since you have filed 7004, this deadline has been extended by 6 months.
      </DisclaimerCard>
    );
  }

  return null;
};

export const TaskForm = ({
  data,
  closeModal,
  disableLaunch = false,
  templateId,
}: TaskFormProps) => {
  const { entities } = useCurrentGroupContext();
  const { values, isSubmitting } = useFormikContext<any>();
  const [getUpdatedDeadlines, { data: updatedDeadlines }] =
    useLazyGetUpdatedDeadlinesQuery();

  const { updated_deadline } = updatedDeadlines || {};
  const { season, entity } = values;

  useEffect(() => {
    const getDeadlines = async () => {
      try {
        await getUpdatedDeadlines({
          baseTaskTemplateId: templateId,
          season,
          entity,
        }).unwrap();
      } catch (e) {}
    };

    if (season && entity && templateId) {
      getDeadlines();
    }
  }, [season, entity, templateId, getUpdatedDeadlines]);

  return (
    <div>
      <Form className="add-edit-form t-flex t-flex-col t-gap-2">
        <div className="t-flex t-w-[90%]">
          <div className="t-mr-4 t-w-[50%]">
            <SelectDropDown label="Entity" name="entity" dropdownSize="small">
              <option value="">Select entity</option>
              {entities?.map((element) => (
                <option key={element.uuid} value={element.uuid}>
                  {element.name}
                </option>
              ))}
            </SelectDropDown>
          </div>
          {!data?.default_task_template && (
            <div className="t-mr-4 t-w-[10%] t-flex-1">
              <SelectDropDown label="Season" name="season" dropdownSize="small">
                <option value="">Year</option>
                {data.seasons?.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </SelectDropDown>
            </div>
          )}
          {data?.states && (
            <div className="t-w-[40%] t-flex-1">
              <SelectDropDown label="State" name="state_id">
                <option value="">State</option>
                {data.states.map(({ name, state_id }) => (
                  <option key={state_id} value={state_id}>
                    {name}
                  </option>
                ))}
              </SelectDropDown>
            </div>
          )}
        </div>
        <span>
          <h6 className="t-mt-7 t-flex t-items-center t-text-body-sm t-font-thin t-text-neutral">
            <img height="15" className="t-mr-2" src={InfoGray} alt="coin" />
            ABOUT
          </h6>
          <span
            className="t-text-subtitle-sm t-font-thin"
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          ></span>
        </span>
        <div className="t-flex t-gap-4">
          {data?.approx_deadline && (
            <div className="t-flex-1">
              <h6 className="t-mt-1 t-flex t-items-center t-text-body-sm t-font-thin t-text-neutral">
                <img
                  height="15"
                  className="t-mr-2"
                  src={CalendarBlank}
                  alt="coin"
                />
                TYPICAL DEADLINE
              </h6>
              <h6 className="t-mt-1 t-font-bold">
                {updated_deadline ? (
                  <>{dayjs(updated_deadline).format("Do MMMM YYYY")}</>
                ) : (
                  <>{dayjs(data.approx_deadline).format("Do MMMM")} each year</>
                )}
              </h6>
            </div>
          )}
          <div className="t-mt-1 t-flex-1">
            <h6 className="t-flex t-items-center t-text-body-sm t-font-thin t-text-neutral">
              <img
                height="15"
                className="t-mr-2"
                src={CoinVertical}
                alt="coin"
              />
              PRICE
            </h6>
            <h6 className="t-mt-1 t-font-bold">
              {data.approx_price === "0" ? "Free" : "$" + data.approx_price}
            </h6>
          </div>
          {Boolean(data?.government_fee) && (
            <div className="t-mt-1 t-flex-1">
              <h6 className="t-flex t-items-center t-text-body-sm t-font-thin t-text-neutral">
                <img
                  height="15"
                  className="t-mr-2"
                  src={CoinVertical}
                  alt="coin"
                />
                GOVERNMENT FEE
              </h6>
              <h6 className="t-mt-1 t-font-bold">
                {data.government_fee === "0"
                  ? "Free"
                  : "$" + data.government_fee}
              </h6>
            </div>
          )}
        </div>
        <Disclaimer updatedDeadlines={updatedDeadlines} />
        <span className="d-flex gap-3 justify-content-end">
          <Button customType="secondary" type="reset" onClick={closeModal}>
            Cancel
          </Button>
          <Button
            customType="primary"
            type="submit"
            disabled={isSubmitting || disableLaunch}
            isLoading={isSubmitting}
          >
            Add Filing
          </Button>
        </span>
      </Form>
    </div>
  );
};

export default function AddTaskEntityModal({
  to,
  redirectPathAfterCreate = "",
  taskTemplateId = "",
  handleClose,
  disableLaunch = false,
}: AddTaskEntityModalProps) {
  const { trackEvent } = useAnalytics();
  const { alertToast, successToast } = useToast();
  const query = useQuery();
  const { entities } = useCurrentGroupContext();
  const { authtoken } = useContext(authContext);
  const { templateId } = useParams<{ templateId: string }>();
  const history = useHistory();
  const { email } = authtoken;
  const queryEntity = query.get("entity");
  const querySeason = query.get("season");
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const finalTemplateId = templateId || taskTemplateId;

  const {
    data,
    isError: getTaskTemplatesIsError,
    error,
  } = useGetSingleTaskTemplatesQuery(
    { templateId: finalTemplateId },
    {
      skip: !finalTemplateId,
    }
  );

  const [createTask, { isLoading }] = useCreateTaskMutation();

  const closeModal = () => {
    if (handleClose) {
      handleClose();
      return;
    }
    history.replace(to);
  };

  if (getTaskTemplatesIsError && (error as BackendError).status === 402) {
    closeModal();
  }

  const handleSubmit = async (values: any) => {
    trackEvent(ADD_TASK_CLICKED, {
      screen: history.location.pathname,
      email,
      ...values,
    });
    try {
      const { entity, ...rest } = values;
      const task = await createTask({
        entity,
        metaData: rest,
      }).unwrap();

      const { season, creator, title } = task || {};
      trackEvent(TASK_CREATED, {
        screen: history.location.pathname,
        creator,
        task_title: title,
        email,
        season,
        ...values,
      });
      successToast({ message: "Filing created!" });
      if (redirectPathAfterCreate) {
        history.replace(`${redirectPathAfterCreate}/${task.uuid}${search}`);
        return;
      }
      history.replace(`/filings/${task.uuid}`);
    } catch (error) {
      alertToast({ message: (error as any)?.data?.error?.message });
      trackEvent(TASK_CREATION_FAILED, {
        screen: history.location.pathname,
        error,
        ...values,
      });
    }
  };

  return (
    <Modal.Root open onOpenChange={closeModal}>
      <Modal.Content>
        {data ? (
          <>
            <Modal.Header>
              <div className="t-text-title-h2-bold t-flex t-gap-1 t-items-start">
                <Button
                  customType="ghost_icon"
                  onClick={() =>
                    handleClose ? handleClose() : history.goBack()
                  }
                  size="small"
                >
                  <img
                    src={ArrowLeft}
                    alt="arrow left"
                    className="t-h-4 t-w-4"
                  />
                </Button>
                {finalTemplateId ===
                process.env.PUBLIC_AD_HOC_BASE_TASK_TEMPLATE_ID
                  ? "Adhoc Task"
                  : typeof data.title === "string"
                  ? data.title
                  : ""}
              </div>

              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              {finalTemplateId ===
              process.env.PUBLIC_AD_HOC_BASE_TASK_TEMPLATE_ID ? (
                <Formik
                  initialValues={{
                    title: "",
                    adhoc_scope_of_work: "",
                    payment_amount: "",
                    base_task_template_id: finalTemplateId,
                    entity: "",
                  }}
                  onSubmit={handleSubmit}
                  validationSchema={addAdhocTaskSchema}
                >
                  <Form className="add-edit-form t-mt-7 t-flex t-flex-col t-gap-5">
                    <TextInput
                      block
                      name="title"
                      label="Filing name"
                      placeholder="Enter filing name"
                    />
                    <TextInput
                      block
                      name="adhoc_scope_of_work"
                      label="Scope of work"
                      placeholder="Enter scope of work"
                    />
                    <TextInput
                      block
                      name="payment_amount"
                      label="Price"
                      placeholder="Enter price"
                    />
                    <SelectDropDown label="Entity" name="entity">
                      <option value="">Select entity</option>
                      {entities?.map((element) => {
                        return (
                          <option key={element.uuid} value={element.uuid}>
                            {element.name}
                          </option>
                        );
                      })}
                    </SelectDropDown>
                    <span className="d-flex gap-3 justify-content-end">
                      <Button
                        customType="secondary"
                        size="large"
                        type="reset"
                        onClick={closeModal}
                      >
                        Cancel
                      </Button>
                      <Button
                        customType="primary"
                        size="large"
                        type="submit"
                        disabled={isLoading || disableLaunch}
                        isLoading={isLoading}
                      >
                        Add Filing
                      </Button>
                    </span>
                  </Form>
                </Formik>
              ) : (
                <Formik
                  initialValues={{
                    state_id: "",
                    season: querySeason || "",
                    base_task_template_id: finalTemplateId,
                    entity: queryEntity || "",
                    default_task_template: Boolean(data?.default_task_template),
                    has_states: Boolean(data?.states),
                  }}
                  onSubmit={(v) => {
                    const { default_task_template, ...rest } = v;
                    handleSubmit(rest);
                  }}
                  validationSchema={addTaskSchema}
                >
                  <TaskForm
                    templateId={finalTemplateId}
                    data={data as unknown as TaskFormData}
                    closeModal={closeModal}
                    disableLaunch={disableLaunch}
                  />
                </Formik>
              )}
            </Modal.Body>
          </>
        ) : (
          <Loader />
        )}
      </Modal.Content>
    </Modal.Root>
  );
}
