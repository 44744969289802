import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { emailValidationRegex } from "constants/emailValidationRegex";
import { Field, FieldProps } from "formik";

export const FilingEmailInput = ({ name, ...props }: { name: string }) => {
  const validateEmail = (value: string) => {
    let error;
    if (!value) {
      error = "Required";
    } else if (!emailValidationRegex.test(value)) {
      error = "Invalid email address";
    }
    return error;
  };

  return (
    <Field name={name} placeholder="Email" validate={validateEmail} {...props}>
      {({ field, form: { touched, errors } }: FieldProps) => {
        return (
          <TextInput
            {...{ ...field, ...props }}
            type="email"
            placeholder="Email"
            customType={
              touched[field.name] || touched[field.name] === undefined
                ? "success"
                : "error"
            }
          />
        );
      }}
    </Field>
  );
};
