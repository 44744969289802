export const Expenses = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_284_14984)">
        <path
          d="M9 2C5.14008 2 2 5.14008 2 9C2 12.8599 5.14008 16 9 16C12.8599 16 16 12.8599 16 9C16 5.14008 12.8599 2 9 2ZM9 14.8333C5.7835 14.8333 3.16667 12.2165 3.16667 9C3.16667 5.7835 5.7835 3.16667 9 3.16667C12.2165 3.16667 14.8333 5.7835 14.8333 9C14.8333 12.2165 12.2165 14.8333 9 14.8333ZM7.83333 7.83333C7.83333 8.05383 7.99142 8.2405 8.209 8.27667L9.98292 8.57242C10.7657 8.7025 11.3333 9.37333 11.3333 10.1667C11.3333 11.1315 10.5482 11.9167 9.58333 11.9167V13.0833H8.41667V11.9167C7.45183 11.9167 6.66667 11.1315 6.66667 10.1667H7.83333C7.83333 10.4887 8.09467 10.75 8.41667 10.75H9.58333C9.90533 10.75 10.1667 10.4887 10.1667 10.1667C10.1667 9.94617 10.0086 9.7595 9.791 9.72333L8.01708 9.42758C7.23425 9.2975 6.66667 8.62667 6.66667 7.83333C6.66667 6.8685 7.45183 6.08333 8.41667 6.08333V4.91667H9.58333V6.08333C10.5482 6.08333 11.3333 6.8685 11.3333 7.83333H10.1667C10.1667 7.51192 9.90533 7.25 9.58333 7.25H8.41667C8.09467 7.25 7.83333 7.51192 7.83333 7.83333Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_284_14984">
          <rect
            width="14"
            height="14"
            fill="white"
            transform="translate(2 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
