import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Card from "components/DesignSystem/Card/Card";
import Modal from "components/DesignSystem/Modal/Modal";
import Loader from "components/design/loader";
import { DownloadIcon } from "components/icons/Download";
import { LinkIcon } from "components/icons/LinkIcon";
import {
  CHECKLIST_ITEM_SECTION,
  CLOSING_STATUS,
} from "constants/financialClosing";
import { FINANCIAL_CLOSING_CHECKLIST_LABLE } from "dictionaries";
import { Form, Formik } from "formik";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";
import {
  ChecklistItem,
  useGetFinancialClosingQuery,
  useUpdateFinancialClosingCheckListMutation,
  useUpdateFinancialClosingStatusMutation,
} from "store/apis/financialClosing";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { debounce } from "utils/debouncing";
import { ActionItem, CategorisedIcon, getStatus } from "./CheckList";
import { Comments } from "./Comments";
import { Period } from "./FinancialClosing";
import { Info } from "./FinancialClosingDetails";
import { SummaryCard } from "./Summary";

type Props = {
  financialClosingId: string;
} & ModalProps;

const ReviewFinancialsItem = ({
  item,
  onClick,
}: {
  item: ChecklistItem;
  onClick: () => void;
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [downloadFile, { isLoading: isDownloading }] =
    useLazyGetPreviewUrlQuery();

  const onDownloadFile = async (fileId: string) => {
    try {
      const { download_url } = await downloadFile({ fileId, groupId }).unwrap();
      window.open(download_url, "_blank");
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  let status = getStatus({
    resolved_by_system: item.resolved_by_system,
    resolved_by_admin: item.resolved_by_admin,
  }) as keyof typeof CategorisedIcon;

  return (
    <ActionItem
      key={item.uuid}
      status={status}
      discription={item.checklist_item_status}
      label={
        <div className="t-flex t-gap-1 t-items-start t-justify-between t-w-full">
          <span className="t-mt-1">
            {FINANCIAL_CLOSING_CHECKLIST_LABLE[item.type]}
          </span>
          <div className="t-flex t-gap-1 t-items-center">
            {item.file_data && (
              <Button
                type="button"
                customType="ghost_icon"
                size="small"
                isLoading={isDownloading}
                disabled={isDownloading}
                onClick={(e) => {
                  e.stopPropagation();
                  onDownloadFile(item.file_data?.uuid!);
                }}
              >
                <DownloadIcon />
              </Button>
            )}
            {item.file_url && (
              <Button
                type="button"
                customType="ghost_icon"
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(item.file_url, "_blank");
                }}
              >
                <LinkIcon />
              </Button>
            )}
          </div>
        </div>
      }
      onClick={onClick}
    />
  );
};

export const ReviewFinancialsModal = ({
  isOpen,
  close,
  financialClosingId,
}: Props) => {
  const { alertToast, successToast } = useToast();
  const entityId = useCurrentEntityId();
  const confirmModal = useModal();

  const {
    data: financialClosingDetails,
    isLoading,
    isSuccess,
  } = useGetFinancialClosingQuery({
    entityId: entityId,
    financialClosingId: financialClosingId,
  });

  const {
    checklist_items = [],
    closing_card_details,
    summary,
  } = financialClosingDetails || {};

  const [
    updateFinancialClosingCheckList,
    { isLoading: isUpdating, isSuccess: isCheckListUpdated },
  ] = useUpdateFinancialClosingCheckListMutation();

  const finalizeFinancials = checklist_items.filter(
    ({ section }) => section === CHECKLIST_ITEM_SECTION.FINALIZE_FINANCIALS
  );

  const onCheckListChange = debounce(
    async ({
      resolved_by_admin,
      checklistItemId,
    }: {
      checklistItemId: string;
      resolved_by_admin?: boolean;
    }) => {
      try {
        await updateFinancialClosingCheckList({
          entityId: entityId,
          financialClosingId: financialClosingId,
          checklistItemId,
          payload: {
            admin_resolved: resolved_by_admin,
          },
        }).unwrap();
      } catch (error) {
        alertToast(
          {
            message: (error as BackendError).data?.error?.message,
          },
          error as Error
        );
      }
    }
  );

  const [updateStatus] = useUpdateFinancialClosingStatusMutation();

  const onChangeStatus = async () => {
    try {
      await updateStatus({
        entityId: entityId,
        financialClosingId: financialClosingId,
        payload: {
          status: CLOSING_STATUS.COMPLETED,
        },
      }).unwrap();

      successToast({
        message: "Marked complete",
      });
      close();
      confirmModal.close();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const isReviewed = finalizeFinancials.every((item) => item.resolved_by_admin);

  return (
    <>
      <Modal.Root open={isOpen} onOpenChange={close}>
        <Formik initialValues={{}} onSubmit={onChangeStatus}>
          {({ submitForm, isSubmitting }) => (
            <Modal.Content size="xxl">
              <Modal.Header>
                <div>
                  <Modal.Title>Review financials</Modal.Title>
                  <Modal.Subtitle>
                    {closing_card_details && (
                      <Period
                        end_date={closing_card_details?.end_date}
                        start_date={closing_card_details?.start_date}
                      />
                    )}
                  </Modal.Subtitle>
                </div>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body className="t-grid t-grid-cols-[2fr_1fr] t-gap-4">
                <Async.Root
                  isEmpty={!financialClosingDetails}
                  isLoading={isLoading}
                  isSuccess={isSuccess}
                >
                  <Async.Empty>
                    <></>
                  </Async.Empty>
                  <Async.Success>
                    <Form>
                      <Card.Root>
                        <Card.Header>
                          <Card.Title className="t-flex t-justify-between t-w-full t-items-center">
                            <div className="t-flex t-gap-2">
                              Finalize Financials
                              <Info
                                text="Accounts with non-zero difference indicate mismatch between Inkle balance and bank balance, and therefore need to be reconciled. Accounts with no difference are marked as auto-complete by the system."
                                align="start"
                              />
                            </div>

                            <div className="t-text-body-sm t-text-text-30">
                              <Async.Root
                                isLoading={isUpdating}
                                customLoader={<Loader customType="secondary" />}
                                isEmpty={false}
                                isSuccess={isCheckListUpdated}
                              >
                                <Async.Empty>
                                  <></>
                                </Async.Empty>
                                <Async.Success>
                                  <span className="t-flex t-items-center t-gap-2 t-transition-all">
                                    <img src={DoubleGreenTick} alt="Saved" />
                                    Changes saved
                                  </span>
                                </Async.Success>
                              </Async.Root>
                            </div>
                          </Card.Title>
                        </Card.Header>
                        <Card.Body>
                          <Formik
                            initialValues={{
                              checklist_items: finalizeFinancials,
                            }}
                            onSubmit={() => {}}
                          >
                            {({ values, setFieldValue }) => (
                              <Form>
                                {values.checklist_items.map((item, index) => {
                                  return (
                                    <ReviewFinancialsItem
                                      key={item.uuid}
                                      item={item}
                                      onClick={debounce(() => {
                                        onCheckListChange({
                                          resolved_by_admin:
                                            !item.resolved_by_admin,
                                          checklistItemId: item.uuid,
                                        });
                                        setFieldValue(
                                          `checklist_items[${index}].resolved_by_admin`,
                                          !item.resolved_by_admin
                                        );
                                      })}
                                    />
                                  );
                                })}
                              </Form>
                            )}
                          </Formik>
                        </Card.Body>
                      </Card.Root>
                    </Form>
                    {summary && (
                      <SummaryCard
                        summary={summary}
                        financialClosingId={financialClosingId}
                      />
                    )}
                    <Comments financialClosingId={financialClosingId} />
                  </Async.Success>
                </Async.Root>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.Root>
                  <Modal.Trigger asChild>
                    <Button customType="primary" disabled={!isReviewed}>
                      Mark completed
                    </Button>
                  </Modal.Trigger>
                  <Modal.Content>
                    <Modal.Header>
                      <Modal.Title>Mark completed</Modal.Title>
                      <Modal.Close />
                    </Modal.Header>
                    <Modal.Body className="t-text-text-60 t-text-body">
                      Are you sure you want to mark Financial Closing
                      <br />
                      <span className="t-text-subtitle-sm t-mx-0.5">
                        {closing_card_details && (
                          <Period
                            end_date={closing_card_details?.end_date}
                            start_date={closing_card_details?.start_date}
                          />
                        )}
                      </span>
                      completed?
                    </Modal.Body>
                    <Modal.FooterButtonGroup>
                      <Modal.RawClose asChild>
                        <Button disabled={isSubmitting}>Cancel</Button>
                      </Modal.RawClose>
                      <Button
                        disabled={isSubmitting}
                        isLoading={isSubmitting}
                        onClick={submitForm}
                        customType="primary"
                      >
                        Proceed
                      </Button>
                    </Modal.FooterButtonGroup>
                  </Modal.Content>
                </Modal.Root>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          )}
        </Formik>
      </Modal.Root>
    </>
  );
};
