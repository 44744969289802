import { PERCENT } from "constants/stripeCouponTypes";
import { SUBSCRIPTION_COUPON_TYPES } from "types/Models/subscription";

export const calculateDiscount = (
  amount: number,
  discount: {
    type: SUBSCRIPTION_COUPON_TYPES;
    value: number;
  }
) => {
  if (discount.type === PERCENT) {
    return amount - amount * (discount.value / 100);
  }

  return amount - discount.value;
};
