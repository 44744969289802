import React from "react";

export const Pointer = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6 0C4.67436 0.0014558 3.40344 0.528708 2.46607 1.46607C1.52871 2.40344 1.00146 3.67436 1 5C1 7.641 5.2 11.2665 5.677 11.6715L6 11.9445L6.323 11.6715C6.8 11.2665 11 7.641 11 5C10.9985 3.67436 10.4713 2.40344 9.53393 1.46607C8.59656 0.528708 7.32564 0.0014558 6 0ZM6 7.5C5.50555 7.5 5.0222 7.35338 4.61107 7.07867C4.19995 6.80397 3.87952 6.41352 3.6903 5.95671C3.50108 5.49989 3.45157 4.99723 3.54804 4.51227C3.6445 4.02732 3.8826 3.58186 4.23223 3.23223C4.58186 2.8826 5.02732 2.6445 5.51227 2.54804C5.99723 2.45157 6.49989 2.50108 6.95671 2.6903C7.41352 2.87952 7.80397 3.19995 8.07867 3.61107C8.35338 4.0222 8.5 4.50555 8.5 5C8.49921 5.6628 8.23556 6.29822 7.76689 6.76689C7.29822 7.23556 6.6628 7.49921 6 7.5Z"
      fill="#B8B5C2"
    />
    <path
      d="M6 6.5C6.82843 6.5 7.5 5.82843 7.5 5C7.5 4.17157 6.82843 3.5 6 3.5C5.17157 3.5 4.5 4.17157 4.5 5C4.5 5.82843 5.17157 6.5 6 6.5Z"
      fill="#B8B5C2"
    />
  </svg>
);
