import { useEffect, useState } from "react";

export const useLocalStorage = <T>(
  key: string,
  initialValue: T
): { localData: T; setItem: (data: T) => void; removeItem: () => void } => {
  const [localData, setLocalData] = useState<T>(initialValue);

  useEffect(() => {
    if (key && !localStorage.getItem(key) && initialValue) {
      localStorage.setItem(key, JSON.stringify(initialValue));
    }
    const data = localStorage.getItem(key);
    try {
      setLocalData(JSON.parse(data || ""));
    } catch (error) {
      setLocalData(initialValue);
    }
  }, [key, initialValue]);

  const setItem = (data: T) => {
    localStorage.setItem(key, JSON.stringify(data));
    setLocalData(data);
  };

  const removeItem = () => {
    localStorage.removeItem(key);
    setLocalData(initialValue);
  };

  return { localData, setItem, removeItem };
};
