export const BookkeepingServices = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_284_15012)">
      <path
        d="M8.62025 16H2V9.58333C2 8.6185 2.78517 7.83333 3.75 7.83333H8.62025C8.20842 8.16642 7.84733 8.559 7.55217 9H3.75C3.42858 9 3.16667 9.26133 3.16667 9.58333V14.8333H7.55217C7.84733 15.2743 8.20842 15.6669 8.62025 16ZM12.5 2H5.5V3.16667H12.5V2ZM14.25 4.91667H3.75V6.08333H14.25V4.91667ZM16 11.9167C16 14.1677 14.1683 16 11.9167 16C9.665 16 7.83333 14.1677 7.83333 11.9167C7.83333 9.66558 9.665 7.83333 11.9167 7.83333C14.1683 7.83333 16 9.66558 16 11.9167ZM14.8333 11.9167C14.8333 10.3084 13.5249 9 11.9167 9C10.3084 9 9 10.3084 9 11.9167C9 13.5249 10.3084 14.8333 11.9167 14.8333C13.5249 14.8333 14.8333 13.5249 14.8333 11.9167ZM12.5 10.1667H11.3333V11.3333H10.1667V12.5H11.3333V13.6667H12.5V12.5H13.6667V11.3333H12.5V10.1667Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_284_15012">
        <rect width="14" height="14" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);
