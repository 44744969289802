import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { Divider } from "components/design/Divider";
import ChatCard, {
  TicketStatusType,
} from "components/DesignSystem/ChatCard/v2/ChatCard";
import { PAID } from "constants/billing";
import { TICKET_CREATED } from "constants/ticket";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { useLazyGetTicketByIdQuery } from "store/apis/chatTicket";
import { useGetTaskFromChannelIdQuery } from "store/apis/task";
import { useChannelStateContext } from "stream-chat-react";
import { BillingInvoice } from "types/Models/billing";
import {
  CardStatusType,
  CustomData,
} from "types/Models/reviewDocumentAndBalancingPayment";
import { BackendError } from "types/utils/error";
import { TicketCardBtn } from "./TicketCardBtn";
import { getTicketCardStatus, TicketCardIcon } from "./TicketCardStatus";
import { ClampedTextWithTooltip } from "utils/ClampedTextWithTooltip";

type TicketCardProps = {
  customData: CustomData;
  messageId: string;
};

export const TicketCard = ({ customData, messageId }: TicketCardProps) => {
  const { header, description, card_status, cta_data, metadata } = customData;
  const status: TicketStatusType | CardStatusType =
    card_status || TICKET_CREATED;
  const history = useHistory();
  const paymentModal = useModal();
  const { channel } = useChannelStateContext();
  const ticketId =
    cta_data?.[0]?.metadata?.ticket_id || cta_data?.[1]?.metadata?.ticket_id;
  const [ticketInvoices, setTicketInvoices] = useState<BillingInvoice[]>([]);
  const [getTicketDetails, { isFetching: isGettingInvoices }] =
    useLazyGetTicketByIdQuery();
  const { isCustomer } = useRoleBasedView();
  const { alertToast } = useToast();
  const isPaymentRequire = cta_data?.some(
    (cta) => cta.document_operation === "PAY_TICKET"
  );

  const { data: channelTask } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel?.id!,
    },
    {
      skip: !channel?.id,
    }
  );
  const entityId = channelTask?.entity?.uuid;
  const groupId = channelTask?.company_group?.uuid;

  const openPaymentModal = async () => {
    if (groupId && ticketId) {
      try {
        const data = await getTicketDetails({
          groupId: groupId,
          ticketId: ticketId,
        }).unwrap();
        const invoices =
          data?.ticket_payments
            ?.map((paymentDetail) => paymentDetail.ticket_invoice)
            .filter((invoice) => invoice != null && invoice.status !== PAID) ||
          [];
        setTicketInvoices(invoices as BillingInvoice[]);
        if (invoices.length > 0) {
          paymentModal.open();
        } else {
          alertToast({ message: "No due payments." });
        }
      } catch (e) {
        alertToast({
          message: (e as BackendError)?.data?.error?.message,
        });
      }
    }
  };

  const redirectToTicket = (ticketId?: string) => {
    if (isCustomer) {
      history.push(`/tickets?ticketUuid=${ticketId}`);
    } else {
      history.push(`/admin/tickets?ticketUuid=${ticketId}`);
    }
  };

  return (
    <div>
      <ChatCard.Content addMargin={false}>
        <ChatCard.Header icon={<TicketCardIcon status={status} />}>
          <div className="t-text-subtitle-sm t-text-text-30">
            {getTicketCardStatus(status)}
          </div>
        </ChatCard.Header>
        <ChatCard.Body>
          <div>
            <div className="t-min-w-52 t-text-body-sm t-text-text-30 t-mb-1.5">
              {header}
            </div>
            {description && (
              <ClampedTextWithTooltip
                text={description}
                lineClampElementClass="t-line-clamp-2 t-text-subtext t-text-text-60"
                toolTipClass="t-w-64 t-word-break"
              />
            )}
            {metadata?.last_update && (
              <div className="t-border t-mt-4 t-border-solid t-bg-surface-lighter-grey t-p-2 t-rounded t-space-y-2 t-border-neutral-0">
                <div className="t-text-body-sm t-text-text-60">Update</div>
                <ClampedTextWithTooltip
                  text={metadata?.last_update}
                  lineClampElementClass="t-line-clamp-2 t-text-body-sm t-text-text-30"
                  toolTipClass="t-w-64 t-word-break"
                />
              </div>
            )}
            <div className="t-flex t-gap-2.5 t-mt-4 t-w-full t-flex-row-reverse">
              {cta_data?.map((cta) => (
                <TicketCardBtn
                  key={cta.document_operation}
                  cta={cta}
                  openPaymentModal={openPaymentModal}
                  redirectToTicket={redirectToTicket}
                  isPaymentRequire={isPaymentRequire}
                  isGettingInvoices={isGettingInvoices}
                />
              ))}
            </div>
          </div>
        </ChatCard.Body>
      </ChatCard.Content>
      {paymentModal.isOpen && entityId && ticketInvoices?.length > 0 && (
        <CheckoutModal
          entityId={entityId!}
          type="invoices"
          open={paymentModal.isOpen}
          onClose={paymentModal.close}
          onInvoicePaid={paymentModal.close}
          invoices={ticketInvoices}
          messageId={messageId}
        />
      )}
    </div>
  );
};
