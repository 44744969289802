import ConditionalToolTip from "components/design/conditionalToolTip";
import { DropdownItemProps } from "components/DesignSystem/Dropdown/Dropdown";
import { useRoleBasedView } from "hooks/useRoleBasedView";

type ActionDropdownProps = {
  isManualTransaction: boolean;
  isSplitTransaction: boolean;
  isExcluedTransaction: boolean;
  isInvoiceLinked: boolean;
  isInvoiceLinkable: boolean;
  Action: {
    RequestInfo: (props: DropdownItemProps) => JSX.Element;
    SendToChat: (props: DropdownItemProps) => JSX.Element;
    AddComment: (props: DropdownItemProps) => JSX.Element;
    AddInvoice: (props: DropdownItemProps) => JSX.Element;
    Edit: (props: DropdownItemProps) => JSX.Element;
    Delete: (props: DropdownItemProps) => JSX.Element;
    SplitTransaction: (props: DropdownItemProps) => JSX.Element;
    EditSplitTransaction: (props: DropdownItemProps) => JSX.Element;
    DeleteSplitTransaction: (props: DropdownItemProps) => JSX.Element;
    ExcludeTransaction: (props: DropdownItemProps) => JSX.Element;
    RestoreTransaction: (props: DropdownItemProps) => JSX.Element;
    LinkInkleInvoice: (props: DropdownItemProps) => JSX.Element;
    EditLinkInkleInvoice: (props: DropdownItemProps) => JSX.Element;
    Duplicate?: (props: DropdownItemProps) => JSX.Element;
  };
};

export const ActionDropdown = ({
  isManualTransaction,
  isSplitTransaction,
  isExcluedTransaction,
  isInvoiceLinked,
  isInvoiceLinkable,
  Action,
}: ActionDropdownProps) => {
  const { isCpa } = useRoleBasedView();

  const commonActions = (
    <>
      {isCpa && <Action.RequestInfo />}
      <Action.SendToChat />
      <Action.AddComment />
      <Action.AddInvoice />
      {Action.Duplicate && (
        <ConditionalToolTip
          condition={
            isSplitTransaction &&
            "Cannot duplicate transaction that has been split"
          }
        >
          <span>
            <Action.Duplicate disabled={isSplitTransaction} />
          </span>
        </ConditionalToolTip>
      )}
      {isInvoiceLinkable && <Action.LinkInkleInvoice />}
      {isInvoiceLinked && <Action.EditLinkInkleInvoice />}
    </>
  );

  switch (true) {
    case isExcluedTransaction:
      return (
        <>
          <Action.RestoreTransaction />
          {isManualTransaction && <Action.Delete />}
        </>
      );

    case isManualTransaction:
      return (
        <>
          {commonActions}
          {isSplitTransaction && (
            <>
              <Action.EditSplitTransaction disabled={isInvoiceLinked} />
              <Action.DeleteSplitTransaction disabled={isInvoiceLinked} />
            </>
          )}
          {!isSplitTransaction && <Action.SplitTransaction />}
          <ConditionalToolTip
            condition={
              isSplitTransaction &&
              "Cannot edit transaction that has been split"
            }
          >
            <span>
              <Action.Edit disabled={isSplitTransaction} />
            </span>
          </ConditionalToolTip>
          <Action.ExcludeTransaction />
          <ConditionalToolTip
            condition={
              isSplitTransaction &&
              "Cannot delete transaction that has been split"
            }
          >
            <span>
              <Action.Delete disabled={isSplitTransaction} />
            </span>
          </ConditionalToolTip>
        </>
      );

    case !isManualTransaction:
      return (
        <>
          {commonActions}
          {isSplitTransaction && (
            <>
              <Action.EditSplitTransaction disabled={isInvoiceLinked} />
              <Action.DeleteSplitTransaction disabled={isInvoiceLinked} />
            </>
          )}
          {!isSplitTransaction && <Action.SplitTransaction />}
          <Action.ExcludeTransaction />
        </>
      );
  }
};
