import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Slider from "components/DesignSystem/Slider/Slider";
import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import { ReminderSetting } from "store/apis/reminders";
import {
  ChatReminderDetails,
  ToggleReminderEnable,
} from "./ChatReminderSliderContent";
import { useState } from "react";
import Tab from "components/DesignSystem/Tab/Tab";
import { ChatTab } from "components/ChatTab/ChatTab";

type ChatReminderSliderProps = {
  selectedReminder: ReminderSetting;
  setSelectedReminder: (val: null) => void;
  setEditReminder: (val: string) => void;
  toggleReminder: (val: string) => void;
  isUpdating: boolean;
  isOpen: boolean;
  close: () => void;
  open: () => void;
  tabFromParent?: "DETAILS" | "CHAT";
};

export const ChatReminderSlider = ({
  selectedReminder,
  setSelectedReminder,
  setEditReminder,
  toggleReminder,
  isUpdating,
  isOpen,
  close,
  open,
  tabFromParent,
}: ChatReminderSliderProps) => {
  const isReminderEnabled = selectedReminder.is_reminder_enabled;

  const [tab, setTab] = useState(tabFromParent || "DETAILS");
  return (
    <Tab.Root
      value={tab}
      asChild
      onValueChange={(v) => setTab(v as typeof tab)}
    >
      <Slider.Root open onOpenChange={() => setSelectedReminder(null)}>
        <Slider.Content open={true} useCustomOverlay>
          <Async.Root
            {...{ isLoading: false, isSuccess: true, isEmpty: false }}
          >
            <Async.Success>
              <Slider.Header
                bottom={
                  <Tab.List className="!t-pt-0">
                    <Tab.Trigger value="DETAILS">Details</Tab.Trigger>
                    <Tab.Trigger value="CHAT">Chat</Tab.Trigger>
                  </Tab.List>
                }
              >
                <Slider.Title>Reminder Information</Slider.Title>
                <Slider.Close />
              </Slider.Header>
              <Tab.Content value="DETAILS" asChild>
                <Slider.Body>
                  <Accordion.Root
                    type="multiple"
                    defaultValue={["DETAILS"]}
                    className="t-space-y-5"
                  >
                    <Accordion.Item value="DETAILS">
                      <Accordion.Trigger disabled>
                        <div className="t-flex t-justify-between t-w-full t-items-center">
                          Reminder Details
                        </div>
                      </Accordion.Trigger>
                      <Accordion.Content>
                        <ChatReminderDetails
                          selectedReminder={selectedReminder}
                          subscribers={selectedReminder.subscribed_profiles}
                        />
                      </Accordion.Content>
                    </Accordion.Item>
                  </Accordion.Root>
                </Slider.Body>
              </Tab.Content>
              <Tab.Content
                value="CHAT"
                className="t-h-[calc(100%-88px)] t-relative"
              >
                <ChatTab
                  channelId={selectedReminder.content_details?.channel_id}
                  messageId={selectedReminder.content_details?.message_id}
                />
              </Tab.Content>
              {tab === "DETAILS" && (
                <Slider.Footer className="t-flex t-gap-1.5">
                  <Button
                    block
                    onClick={() =>
                      setEditReminder(selectedReminder.reminder_setting_id)
                    }
                  >
                    Edit Reminder
                  </Button>
                  <Button
                    customType={isReminderEnabled ? "danger" : "success"}
                    block
                    onClick={open}
                  >
                    {isReminderEnabled ? "Stop" : "Enable"} Reminder
                  </Button>
                </Slider.Footer>
              )}
              <ToggleReminderEnable
                toggleReminder={() =>
                  toggleReminder(selectedReminder.reminder_setting_id)
                }
                isUpdating={isUpdating}
                isOpen={isOpen}
                isReminderEnabled={isReminderEnabled}
                close={close}
              />
            </Async.Success>
          </Async.Root>
        </Slider.Content>
      </Slider.Root>
    </Tab.Root>
  );
};
