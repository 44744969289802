import { ConditionalLink } from "components/conditionalLink";
import { DashboardLayout } from "components/DashboardLayout";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { Header } from "components/DesignSystem/Header/Header";
import { Search } from "components/DesignSystem/Search/Search";
import Tab from "components/DesignSystem/Tab/Tab";
import { ProductCard } from "components/ProductCard/ProductCard";
import {
  CLICKED_ON_REQUEST_A_INTRAGROUP_SERVICES_CTA,
  CLICKED_ON_START_A_INTRAGROUP_SERVICES_FROM_CARD,
} from "constants/analyticsEvents";
import { ALL, COMPLETED, ONGOING } from "constants/task";
import { useAnalytics } from "hooks/useAnalytics";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { ServicesTable } from "pages/Books/Services/ServicesTable";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useGetBaseTaskTemplatesQuery } from "store/apis/getTemplates";
import { useGetTaskListForUserQuery } from "store/apis/taskList";
import {
  openIntragroupTask,
  setCallbackUrlOnModalClose,
  setIntragroupTaskCount,
  TemplateType,
} from "store/slices/IntragroupService";
import { RootState } from "store/store";

const IntragroupServicesCards = () => {
  const { data, isLoading, isSuccess } = useGetBaseTaskTemplatesQuery({
    task_category_type: "INTRA_GROUP",
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const taskTemplates = data?.["Intragroup"];
  const isEmpty = taskTemplates?.length === 0;
  const { trackEvent } = useAnalytics();

  const openIntragroupTaskLaunch = (taskTemplate: TemplateType) => {
    trackEvent(CLICKED_ON_START_A_INTRAGROUP_SERVICES_FROM_CARD);
    history.push("/intragroup/services/addtask");
    dispatch(setCallbackUrlOnModalClose("/intragroup/services"));
    dispatch(openIntragroupTask(taskTemplate));
  };

  return (
    <Async.Root isEmpty={isEmpty} isLoading={isLoading} isSuccess={isSuccess}>
      <Async.Success>
        <div className="t-grid lg:t-grid-cols-2 t-gap-5">
          {taskTemplates?.map((taskTemplate) => {
            const { title, description, approx_price, uuid } = taskTemplate;
            return (
              <ProductCard
                key={uuid}
                action={
                  <Button
                    size="small"
                    onClick={() =>
                      openIntragroupTaskLaunch(
                        taskTemplate as unknown as TemplateType
                      )
                    }
                  >
                    Start now
                  </Button>
                }
                price={<AmountSuperScript amount={Number(approx_price)} />}
                {...{ title, description }}
              />
            );
          })}
        </div>
      </Async.Success>
      <Async.Empty>
        <></>
      </Async.Empty>
    </Async.Root>
  );
};

export const IntragroupServices = () => {
  usePageTitle("Intra group");
  const { update } = useUpdateQuery();
  const history = useHistory();
  const query = useQuery();
  const serviceType = query.get("intragroup");
  const search = query.get("search");
  const rRef = useRef(null);
  const { ongoing_count, count, completed_count } = useSelector(
    (state: RootState) => state.intragroupList.intragroupTaskCounts
  );
  const dispatch = useDispatch();
  const { trackEvent } = useAnalytics();

  const { data, isLoading, isSuccess } = useGetTaskListForUserQuery({
    taskCategoryType: "INTRA_GROUP",
  });

  useEffect(() => {
    dispatch(
      setIntragroupTaskCount({
        ongoing_count: data?.ongoing_count || 0,
        count: data?.count || 0,
        completed_count: data?.completed_count || 0,
      })
    );
  }, [data?.count]);

  const handleTabChange = (value: string) => {
    update({ query: "intragroup", value: value || null });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    update({ query: "search", value: value || null });
  };

  const tabs = [
    { name: "All", value: "ALL", taskCount: count },
    { name: "Ongoing", value: "ONGOING", taskCount: ongoing_count },
    {
      name: "Completed",
      value: "COMPLETED",
      taskCount: completed_count,
    },
  ];

  const isEmpty = data?.count === 0;

  return (
    <Async.Root isEmpty={isEmpty} isLoading={isLoading} isSuccess={isSuccess}>
      <Async.Success>
        <Tab.Root
          asChild
          defaultValue="ALL"
          onValueChange={handleTabChange}
          value={serviceType || "ALL"}
        >
          <DashboardLayout
            ref={rRef}
            header={
              <Header
                v2
                title="Intragroup Services"
                right={
                  <ConditionalLink
                    to={`/books/intragroup/services/addtask${history?.location?.search}`}
                  >
                    <Button
                      size="small"
                      customType="primary"
                      onClick={() =>
                        trackEvent(CLICKED_ON_REQUEST_A_INTRAGROUP_SERVICES_CTA)
                      }
                    >
                      Request a service
                    </Button>
                  </ConditionalLink>
                }
                bottom={
                  <Tab.List>
                    {tabs.map(({ name, value, taskCount }) => (
                      <Tab.Trigger
                        value={value}
                        key={value}
                        suffix={taskCount?.toString()}
                        customType="primary"
                      >
                        {name}
                      </Tab.Trigger>
                    ))}
                  </Tab.List>
                }
              />
            }
          >
            <div className="t-w-1/2">
              <Search
                placeholder="Search..."
                onChange={handleChange}
                value={search || ""}
                block
              />
            </div>
            <div>
              <Tab.Content value={ALL} className="t-h-full">
                <ServicesTable
                  serviceType="intragroup"
                  taskCategoryType="INTRA_GROUP"
                  parentRoute="/books/intragroup/services"
                />
              </Tab.Content>
              <Tab.Content value={ONGOING} className="t-h-full">
                <ServicesTable
                  serviceType="intragroup"
                  taskCategoryType="INTRA_GROUP"
                  parentRoute="/books/intragroup/services"
                />
              </Tab.Content>
              <Tab.Content value={COMPLETED} className="t-h-full">
                <ServicesTable
                  serviceType="intragroup"
                  taskCategoryType="INTRA_GROUP"
                  parentRoute="/books/intragroup/services"
                />
              </Tab.Content>
            </div>
          </DashboardLayout>
        </Tab.Root>
      </Async.Success>
      <Async.Empty>
        <DashboardLayout ref={rRef} header={<Header v2 title="Services" />}>
          <IntragroupServicesCards />
        </DashboardLayout>
      </Async.Empty>
    </Async.Root>
  );
};
