export const Postpaid = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
  >
    <path
      d="M6 4.5C3.929 4.5 2.25 6.179 2.25 8.25C2.25 10.321 3.929 12 6 12C8.071 12 9.75 10.321 9.75 8.25C9.75 6.179 8.071 4.5 6 4.5ZM5.2605 7.62L6.9035 7.894C7.539 7.9995 8 8.544 8 9.188C8 9.9115 7.4115 10.5 6.688 10.5H6.5V11H5.5V10.5C4.673 10.5 4 9.827 4 9H5C5 9.2755 5.2245 9.5 5.5 9.5H6.688C6.86 9.5 7 9.36 7 9.188C7 9.0345 6.89 8.9055 6.7395 8.88L5.0965 8.606C4.4615 8.5005 4.0005 7.956 4.0005 7.312C4.0005 6.5885 4.589 6 5.3125 6H5.5005V5.5H6.5005V6C7.3275 6 8.0005 6.673 8.0005 7.5H7.0005C7.0005 7.2245 6.7765 7 6.5005 7H5.3125C5.1405 7 5.0005 7.14 5.0005 7.312C5.0005 7.4655 5.11 7.5945 5.261 7.62H5.2605ZM12 2.5V12H8.908C9.275 11.715 9.5985 11.3785 9.868 11H10.9995V5H9.457C9.073 4.5915 8.6185 4.2525 8.1115 4H10.9995V2.5C10.9995 2.2245 10.7755 2 10.4995 2H1.5C1.2245 2 1 2.2245 1 2.5V4H3.888C3.381 4.2525 2.9265 4.5915 2.5425 5H1V11H2.1315C2.401 11.3785 2.725 11.715 3.0915 12H0V2.5C0 1.673 0.673 1 1.5 1H3V0H4V1H8V0H9V1H10.5C11.327 1 12 1.673 12 2.5Z"
      fill="currentColor"
    />
  </svg>
);
