import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface CartInChatState {
  entityId: string;
}
export const initialStateForEntity: CartInChatState = {
  entityId: "",
};

export const cartInChat = createSlice({
  name: "cartInChat",
  initialState: initialStateForEntity,
  reducers: {
    setCartInChat: (
      state,
      action: PayloadAction<typeof initialStateForEntity>
    ) => {
      state.entityId = action.payload.entityId;
    },
  },
});

export const { setCartInChat } = cartInChat.actions;
export default cartInChat.reducer;
