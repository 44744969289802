import Async from "components/DesignSystem/AsyncComponents/Async";
import Slider from "components/DesignSystem/Slider/Slider";
import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import {
  RAMailroomSubscriptionType,
  useGetRAMailroomDetailQuery,
} from "store/apis/RAMailroom";
import { SubscriptionDetails } from "./SubscriptionDetails";
import { RAMailroomPaymentHistory } from "./RAMailroomPaymentHistory";

type RAMailroomSubscriptionSliderProps = {
  subscriptionId: string;
  setSubscriptionId: () => void;
};

export const RAMailroomSubscriptionSlider = ({
  subscriptionId,
  setSubscriptionId,
}: RAMailroomSubscriptionSliderProps) => {
  const {
    data: subscription,
    isLoading,
    isSuccess,
  } = useGetRAMailroomDetailQuery({
    raMailroomId: subscriptionId,
  });

  return (
    <Slider.Root open onOpenChange={() => setSubscriptionId()}>
      <Slider.Content open={true} useCustomOverlay>
        <Async.Root {...{ isLoading, isSuccess, isEmpty: false }}>
          <Async.Success>
            <Slider.Header>
              <Slider.Title>Reminder Information</Slider.Title>
              <Slider.Close />
            </Slider.Header>
            <Slider.Body>
              <Accordion.Root
                type="multiple"
                defaultValue={["SUBSCRIPTION_DETAILS", "PAYMENT_HISTORY"]}
                className="t-space-y-5"
              >
                <Accordion.Item value="SUBSCRIPTION_DETAILS">
                  <Accordion.Trigger disabled>
                    Subscription Details
                  </Accordion.Trigger>
                  <Accordion.Content>
                    {subscription?.subscription_details && (
                      <SubscriptionDetails
                        subscriptionDetails={subscription.subscription_details}
                      />
                    )}
                  </Accordion.Content>
                </Accordion.Item>
                <Accordion.Item value="PAYMENT_HISTORY">
                  <Accordion.Trigger disabled>
                    Payment History
                  </Accordion.Trigger>
                  <Accordion.Content>
                    {subscription?.payment_history && (
                      <RAMailroomPaymentHistory
                        paymentHistory={subscription.payment_history}
                      />
                    )}
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion.Root>
            </Slider.Body>
          </Async.Success>
        </Async.Root>
      </Slider.Content>
    </Slider.Root>
  );
};
