import classNames from "classnames";
import { MapPointer } from "components/icons/MapPointer";
import { MapPin } from "components/icons/Navbar/MapPin";
import {
  ACTIVE,
  COORDINATES,
  EXPIRED,
  US_COORDINATES,
} from "constants/regAgent";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  ZoomableGroup,
} from "react-simple-maps";
import { useGetRegisteredAgentQuery } from "store/apis/registerAgent";
import usMap from "./usmap.json";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

const MapDetails = ({ count }: { count: number }) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data: registeredAgents = [] } = useGetRegisteredAgentQuery(
    { groupId, entityId },
    { skip: !groupId || !entityId }
  );

  return (
    <div className="t-flex t-flex-col t-gap-12 t-text-text-60 sm:t-flex-row sm:t-justify-between">
      <div>
        <div className="t-text-subtitle">Registered Agents In US</div>
        <div className="t-text-display-h2 t-leading-10">{count}</div>
      </div>
      <div className="t-flex t-max-h-48 t-flex-col t-gap-2 t-overflow-auto">
        {registeredAgents.map(({ state, status, agent_uuid }) => {
          return (
            <span
              className="t-flex t-items-center t-gap-2 t-text-subtitle"
              key={agent_uuid}
            >
              <span
                className={classNames({
                  "t-text-red-50": status === EXPIRED,
                  "t-text-purple-20": status === ACTIVE,
                })}
              >
                <MapPin color="currentColor" />
              </span>
              {state}
            </span>
          );
        })}
      </div>
    </div>
  );
};

export const RAMap = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { data: registeredAgents = [] } = useGetRegisteredAgentQuery(
    { groupId, entityId },
    { skip: !groupId || !entityId }
  );

  const registeredAgentsCount = registeredAgents.length;
  const activeRASates = registeredAgents
    .filter(({ status }) => status === ACTIVE)
    .map(({ state }) => state);

  const expiredRASates = registeredAgents
    .filter(({ status }) => status === EXPIRED)
    .map(({ state }) => state);

  if (registeredAgentsCount === 0) {
    return null;
  }

  return (
    <div className="t-flex t-flex-wrap-reverse t-items-center t-justify-between t-gap-2 t-rounded-lg t-border-[0.5px] t-border-solid t-border-neutral-20 t-px-10 t-py-6 t-shadow-map-shadow sm:t-hidden">
      <MapDetails count={registeredAgentsCount} />
      <ComposableMap projection="geoMercator" className="!t-h-96 !t-w-[39rem] ">
        <ZoomableGroup
          center={US_COORDINATES}
          zoom={6.2}
          filterZoomEvent={() => false}
          onMouseDown={(e) => e.preventDefault()}
        >
          <Geographies geography={usMap} width={560}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const {
                  properties: { name },
                } = geo;
                return (
                  <Geography
                    key={geo.rsmKey}
                    geography={geo}
                    stroke={
                      activeRASates.includes(name)
                        ? "#DDD3FF"
                        : expiredRASates.includes(name)
                        ? "#CC4859"
                        : "#DDD3FF"
                    }
                    fill={
                      activeRASates.includes(name)
                        ? "#DDD3FF"
                        : expiredRASates.includes(name)
                        ? "#CC4859"
                        : "#EEE9FF"
                    }
                    strokeWidth="0.2"
                  />
                );
              })
            }
          </Geographies>

          {registeredAgents.map(({ state, agent_uuid }) => {
            return (
              <Marker
                key={agent_uuid}
                //  @ts-ignore
                coordinates={COORDINATES[state]}
              >
                <MapPointer />
              </Marker>
            );
          })}
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};
