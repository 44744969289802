export const Financials = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_285_23535)">
      <path
        d="M10.1667 11.9167H13.6667V13.0833H10.1667V11.9167ZM10.1667 9.58333V10.75H11.3333V9.58333H10.1667ZM13.6667 4.33333H6.66667V6.17608C7.08433 6.242 7.47575 6.34175 7.83333 6.47417V5.5H12.5V6.66667H8.28075C8.89033 6.963 9.37625 7.36025 9.70233 7.83333H13.6667V4.33333ZM14.25 2H6.08333C5.11675 2 4.33333 2.78342 4.33333 3.75V6.17608C4.70317 6.11775 5.09167 6.08333 5.5 6.08333V3.75C5.5 3.428 5.76133 3.16667 6.08333 3.16667H14.25C14.572 3.16667 14.8333 3.428 14.8333 3.75V14.8333H10.0063C9.84408 15.272 9.56292 15.6663 9.17967 16H16V3.75C16 2.78342 15.2166 2 14.25 2ZM13.6667 9.58333H12.5V10.75H13.6667V9.58333ZM9 9.29167V13.9583C9 15.1221 7.49558 16 5.5 16C3.50442 16 2 15.1221 2 13.9583V9.29167C2 8.12792 3.50442 7.25 5.5 7.25C7.49558 7.25 9 8.12792 9 9.29167ZM7.83333 13.9583V13.172C7.22317 13.4829 6.41292 13.6667 5.5 13.6667C4.58708 13.6667 3.77683 13.4829 3.16667 13.172V13.9583C3.16667 14.2669 4.05275 14.8333 5.5 14.8333C6.94725 14.8333 7.83333 14.2669 7.83333 13.9583ZM7.83333 11.625V10.8387C7.22317 11.1496 6.41292 11.3333 5.5 11.3333C4.58708 11.3333 3.77683 11.1496 3.16667 10.8387V11.625C3.16667 11.9336 4.05275 12.5 5.5 12.5C6.94725 12.5 7.83333 11.9336 7.83333 11.625ZM7.83333 9.29167C7.83333 8.98308 6.94725 8.41667 5.5 8.41667C4.05275 8.41667 3.16667 8.98308 3.16667 9.29167C3.16667 9.60025 4.05275 10.1667 5.5 10.1667C6.94725 10.1667 7.83333 9.60025 7.83333 9.29167Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_285_23535">
        <rect width="14" height="14" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);
