import classNames from "classnames";
import { CheckoutModal } from "components/CheckoutModal/CheckoutModal";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import {
  CLIENT_CONFIRMATION,
  CONNECT_BANK,
  SUBSCRIPTION_COMPLETE,
} from "constants/fieldTypes";
import { REVIEW_INFO } from "constants/onbaordingStepKey";
import { Form, Formik } from "formik";
import Lottie from "lottie-react";
import magicWand from "lottie/magicWand.json";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useChangeStepMutation,
  useDeleteDocumentsMutation,
  useGetEntityOnboardingProductsQuery,
  useGetStepFormsQuery,
  useUpdateStepFieldMutation,
  useUploadDocumentsMutation,
} from "store/apis/productOnboarding";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { setPaymentTitle, setScheduledPayment } from "store/slices/credit";
import { OnboardingProduct, Step } from "types/Models/onboarding";
import { debounce } from "utils/debouncing";
import { ComponentGenerator } from "./ComponentGenerator";
import { OnboardingCompleteBanner } from "./OnboardingCompleteBanner";
import { OnboardingCustomFooter } from "./OnboardingCustomFooter";
import { ConnectBank } from "./Tax/ConnectBank";
import { OnboardingEntityDetails } from "./Tax/OnboardingEntityDetails/OnboardingEntityDetails";
import { ENTITY_DETAILS_STEP } from "constants/onBoarding";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { PRODUCT_ONBOARDING_STEPS } from "constants/productOnboardingSteps";
import { SalesTaxEntityDetails } from "components/SalesTaxEntityDetails/SalesTaxEntityDetails";
import { SalesTaxEntityOwnerDetails } from "components/SalesTaxEntityOwnerDetails/SalesTaxEntityOwnerDetails";
import { SalesTaxEntityActivityDetails } from "components/SalesTaxEntityActivityDetails/SalesTaxEntityActivityDetails";
import { useGetSalexTaxBusinessQuery } from "store/apis/salesTax";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { PRODUCT_ONBOARDING_STEP_KEYS } from "constants/productOnboardingStepKeys";

const AI_STEPS = [REVIEW_INFO];

const CustomStep = ({
  isOnboardingComplete,
  step,
  setIsBankConnected,
  isCurrentStepDisabled,
  selectedEntity,
}: {
  isOnboardingComplete: boolean;
  step?: Step;
  setIsBankConnected: (v: boolean) => void;
  isCurrentStepDisabled: boolean;
  selectedEntity: string;
}) => {
  switch (step?.type) {
    case CONNECT_BANK:
      return <ConnectBank />;

    case SUBSCRIPTION_COMPLETE: {
      return <OnboardingCompleteBanner />;
    }

    case ENTITY_DETAILS_STEP: {
      return <OnboardingEntityDetails entityId={selectedEntity} />;
    }

    case PRODUCT_ONBOARDING_STEPS.SALES_TAX_ENTITY_DETAILS:
      return <SalesTaxEntityDetails />;

    case PRODUCT_ONBOARDING_STEPS.SALES_TAX_ENTITY_OWNER_DETAILS:
      return <SalesTaxEntityOwnerDetails />;

    case PRODUCT_ONBOARDING_STEPS.SALES_TAX_ENTITY_ACTIVITY_DETAILS:
      return <SalesTaxEntityActivityDetails />;

    default:
      return null;
  }
};

type OnboardingStepProps = {
  groupId: string;
  currentProduct: OnboardingProduct;
  completeOnboarding: (
    groupId: string,
    productId: string,
    closeModal: boolean
  ) => Promise<void>;
  currentStep: Step | undefined;
  setCurrentStep: (step: Step) => void;
  isCurrentStepDisabled: boolean;
  onClose: () => void;
  selectedEntity: string;
};

export const OnboardingStep = ({
  groupId,
  currentProduct,
  completeOnboarding,
  currentStep,
  setCurrentStep,
  isCurrentStepDisabled,
  onClose,
  selectedEntity,
}: OnboardingStepProps) => {
  const [subscriptionModal, setSubscriptionModal] = useState(false);
  const [showOnboardingComplete, setShowOnboardingComplete] = useState(false);
  const dispatch = useDispatch();
  const [termAndConditionChecked, setTermAndConditionChecked] = useState(false);
  const entityIdOnSelect = useAppSelector(
    (state) => state.onboardingWidget.entityId
  );
  const entityId = useCurrentEntityId() || entityIdOnSelect!;

  const { data: subscriptions = [] } = useGetSubscriptionsQuery(
    {
      groupId,
      refetchArg: currentStep?.uuid,
    },
    { skip: !groupId, refetchOnMountOrArgChange: true }
  );

  const stepSubscription = subscriptions.find(
    (s) => s.uuid === currentStep?.subscription?.uuid
  );

  const { data: stepComponents, isFetching } = useGetStepFormsQuery(
    {
      groupId,
      formStepId: currentStep?.uuid!,
    },
    { skip: !currentStep?.uuid }
  );

  const [changeFormStep] = useChangeStepMutation();
  const clientConfirmation = stepComponents?.find(
    (component: any) => component.type === CLIENT_CONFIRMATION
  );

  useEffect(() => {
    setTermAndConditionChecked(clientConfirmation?.value);
  }, [clientConfirmation?.value]);

  useEffect(() => {
    setCurrentStep(
      currentProduct.steps.find((step) => step.status !== "COMPLETED") ||
        currentProduct.steps[currentProduct.steps.length - 1]
    );
  }, [currentProduct.steps]);

  const changeStep = async (step: "PREV_STEP" | "NEXT_STEP") => {
    if (
      currentStep?.should_show_complete_onboarding_cta &&
      step === "NEXT_STEP"
    ) {
      let closeModal = false;
      if (
        showOnboardingComplete ||
        currentStep?.step_key === "share_bank_access" ||
        currentStep?.step_key === "schedule_call_with_ops" ||
        currentStep?.step_key === "choose_bookkeeping_plan" ||
        currentStep?.step_key === "subscribe_to_inkle_tax_v2" ||
        currentStep?.step_key ===
          PRODUCT_ONBOARDING_STEP_KEYS.SALES_TAX_BUSINESS_ACTIVITY_DETAILS
      ) {
        closeModal = true;
      }
      await completeOnboarding(groupId, currentProduct.uuid, closeModal);

      if (!showOnboardingComplete) {
        setShowOnboardingComplete(true);
      }
      setTermAndConditionChecked(false);
      return;
    }

    const newStepId =
      (step === "PREV_STEP"
        ? currentStep?.prev_step_id
        : currentStep?.next_step_id) || "";

    const newStep = await changeFormStep({
      groupId: groupId || "",
      formStepId: currentStep?.uuid,
      action: step,
      payload: {
        new_form_step_id: newStepId,
      },
    }).unwrap();

    setCurrentStep(newStep);
  };

  const [updateField] = useUpdateStepFieldMutation();
  const [uploadDocuments] = useUploadDocumentsMutation();
  const [deleteDocument] = useDeleteDocumentsMutation();
  const group = useCurrentGroupContext();
  const onboardingEntityId = useAppSelector(
    (state) => state.onboardingWidget.entityId
  );

  const { data: salesTaxBusiness, isLoading } = useGetSalexTaxBusinessQuery(
    {
      entityId: onboardingEntityId!,
    },
    { skip: !onboardingEntityId }
  );

  const handleInputChange = debounce(
    async (e: React.ChangeEvent<HTMLInputElement>, fieldId: string) => {
      const value = e.target.value;
      await updateField({
        groupId,
        fieldId,
        payload: { value },
      });
    }
  );

  const handleCheckBoxChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldId: string
  ) => {
    const value = e.target.checked;
    await updateField({
      groupId,
      fieldId,
      payload: { value },
    });
  };

  const handleFilesDrop = async (
    files: File[],
    fieldId: string,
    tagName?: string
  ) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append("documents", file);
    });

    formData.append("tag_name", tagName || "");

    const payload = formData;

    await uploadDocuments({
      groupId,
      fieldId,
      payload,
    });
  };

  const handleFileDelete = async (fieldDocumentMapId: string) => {
    await deleteDocument({
      groupId,
      fieldDocumentMapId,
    });
  };

  let initialValues =
    stepComponents?.reduce(
      (initialValues, component, i) => ({
        ...initialValues,
        [`${component.field_key}#${i}`]: component.value,
      }),
      {}
    ) || [];

  const isAnySalesTaxStep =
    currentStep &&
    [
      PRODUCT_ONBOARDING_STEP_KEYS.SALES_TAX_BUSINESS_ACTIVITY_DETAILS,
      PRODUCT_ONBOARDING_STEP_KEYS.SALES_TAX_BUSINESS_DETAILS,
      PRODUCT_ONBOARDING_STEP_KEYS.SALES_TAX_BUSINESS_OWNER_DETAILS,
    ].includes(currentStep?.step_key);

  if (isAnySalesTaxStep && salesTaxBusiness) {
    initialValues = {
      ...salesTaxBusiness,
    };
  }

  let isScheduledPayment =
    currentStep?.field_values?.find(
      ({ field_key }) => field_key === "current_year_incorporated"
    )?.value === false ||
    currentStep?.field_values?.find(
      ({ field_key }) => field_key === "current_year_incorporated_v2"
    )?.value;

  const disablePrevBtn =
    currentStep?.order === 0 ||
    currentProduct.status === "COMPLETED" ||
    isCurrentStepDisabled ||
    !Boolean(currentStep?.prev_step_id);

  const onSubscribed = async () => {
    setSubscriptionModal(false);
    await changeStep("NEXT_STEP");
    dispatch(setScheduledPayment(false));
  };

  const onSubscriptionClose = () => {
    setSubscriptionModal(false);
    dispatch(setPaymentTitle(""));
    dispatch(setScheduledPayment(false));
  };

  const handleClientConfirmationChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fileId: string
  ) => {
    handleCheckBoxChange(e, fileId);
    setTermAndConditionChecked(e.target.checked);
  };

  if (isFetching && currentStep && AI_STEPS.includes(currentStep.step_key)) {
    return (
      <div className="t-flex t-items-center t-justify-center t-h-full t-flex-col t-text-body t-text-text-30">
        <Lottie className="t-h-60 t-w-60" animationData={magicWand} />
        <p className="t-m-0">Inkle AI is working it's magic</p>
      </div>
    );
  }

  if (!stepComponents) {
    return null;
  }

  if (isAnySalesTaxStep && isLoading) {
    return null;
  }

  return (
    <div className="t-w-full t-h-full t-flex t-flex-col t-items-center t-gap-6 t-mx-auto">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={() => {}}
      >
        <fieldset
          disabled={isCurrentStepDisabled}
          className={classNames(
            "t-w-full t-h-full t-flex t-flex-col t-items-center t-gap-6 t-mx-auto",
            {
              "t-opacity-50 t-cursor-not-allowed": isCurrentStepDisabled,
            }
          )}
        >
          <Form className="t-m-0 t-h-full t-flex t-flex-col">
            <div className="t-flex-grow t-overflow-y-auto t-h-full">
              <div className="t-mx-auto t-w-full t-h-full t-relative">
                {currentStep?.type === "DEFINED" ? (
                  <div className="t-h-full t-w-full t-flex t-flex-col t-justify-between">
                    <div
                      className={classNames(
                        "t-overflow-auto t-px-[21%] t-py-2",
                        {
                          "t-h-full": !clientConfirmation,
                          "t-flex-1": clientConfirmation,
                        }
                      )}
                    >
                      {stepComponents?.map((component, i) => (
                        <ComponentGenerator
                          component={component}
                          key={component.uuid}
                          handleChange={handleInputChange}
                          handleFilesDrop={handleFilesDrop}
                          handleFileDelete={handleFileDelete}
                          name={`${component.field_key}#${i}`}
                          handleCheckBoxChange={handleCheckBoxChange}
                          isCurrentStepDisabled={isCurrentStepDisabled}
                          currentStep={currentStep}
                        />
                      ))}
                      {clientConfirmation && (
                        <Checkbox
                          name="termsAndCondition"
                          label={
                            <div className="t-text-body-sm t-text-text-100 t-line-clamp-3">
                              {clientConfirmation.content as string}
                            </div>
                          }
                          onChange={(e) =>
                            handleClientConfirmationChange(
                              e,
                              clientConfirmation.uuid
                            )
                          }
                          checked={termAndConditionChecked}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <CustomStep
                    isOnboardingComplete={showOnboardingComplete}
                    step={currentStep}
                    setIsBankConnected={setTermAndConditionChecked}
                    isCurrentStepDisabled={isCurrentStepDisabled}
                    selectedEntity={selectedEntity}
                  />
                )}
              </div>
            </div>
            <Modal.Footer>
              <OnboardingCustomFooter
                currentStep={currentStep}
                stepSubscription={stepSubscription}
                setSubscriptionModal={setSubscriptionModal}
                termAndConditionChecked={termAndConditionChecked}
                setTermAndConditionChecked={setTermAndConditionChecked}
                clientConfirmation={clientConfirmation}
                showOnboardingComplete={showOnboardingComplete}
                setShowOnboardingComplete={setShowOnboardingComplete}
                completeOnboarding={completeOnboarding}
                currentProduct={currentProduct}
                setCurrentStep={setCurrentStep}
                disabled={disablePrevBtn}
                onClose={onClose}
                selectedEntity={selectedEntity}
              />
            </Modal.Footer>
          </Form>
        </fieldset>
      </Formik>

      <CheckoutModal
        entityId={entityId}
        type="subscription"
        isScheduledPayment={isScheduledPayment}
        title={isScheduledPayment ? "Schedule Payment" : "Subscription"}
        onClose={onSubscriptionClose}
        open={subscriptionModal}
        subscription={stepSubscription!}
        onSubscribed={onSubscribed}
      />
    </div>
  );
};
