export const ClockCountdown = ({ size = "16" }: { size?: string }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_509_9671)">
      <path
        d="M14 8.49998C13.7456 11.58 11.1456 14 8 14C6.4087 14 4.88258 13.3678 3.75736 12.2426C2.63214 11.1174 2 9.59128 2 7.99998C2 4.85436 4.42 2.25436 7.5 1.99998"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4.49998V7.99998H11.5"
        stroke="currentColor"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 2.99998C10.4142 2.99998 10.75 2.6642 10.75 2.24998C10.75 1.83577 10.4142 1.49998 10 1.49998C9.58579 1.49998 9.25 1.83577 9.25 2.24998C9.25 2.6642 9.58579 2.99998 10 2.99998Z"
        fill="currentColor"
      />
      <path
        d="M12.25 4.49998C12.6642 4.49998 13 4.1642 13 3.74998C13 3.33577 12.6642 2.99998 12.25 2.99998C11.8358 2.99998 11.5 3.33577 11.5 3.74998C11.5 4.1642 11.8358 4.49998 12.25 4.49998Z"
        fill="currentColor"
      />
      <path
        d="M13.75 6.74998C14.1642 6.74998 14.5 6.4142 14.5 5.99998C14.5 5.58577 14.1642 5.24998 13.75 5.24998C13.3358 5.24998 13 5.58577 13 5.99998C13 6.4142 13.3358 6.74998 13.75 6.74998Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_509_9671">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
