import { APIProvider } from "@vis.gl/react-google-maps";
import { PlaceAutocomplete } from "components/AddressFormFields/AddressFormFields";
import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { US } from "constants/regAgent";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik, FormikValues } from "formik";
import { registerAgentSchema } from "formValidations/registerAgentSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useDispatch, useSelector } from "react-redux";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import {
  useCreateRegisteredAgentMutation,
  useGetRAByIdQuery,
  useUpdateRegisteredAgentMutation,
} from "store/apis/registerAgent";
import { closeAddOrEditModal } from "store/slices/registerAgent";
import { RootState } from "store/store";

const API_KEY = process.env.PUBLIC_GOOGLE_MAP_KEY;

export const AddEditRA = ({
  onSuccess,
  stateName,
}: {
  onSuccess?: () => void;
  stateName?: string;
}) => {
  const { alertToast, successToast } = useToast();
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const [createRegisteredAgent] = useCreateRegisteredAgentMutation();
  const [updateRegisteredAgent] = useUpdateRegisteredAgentMutation();
  const { show, raId } = useSelector(
    (state: RootState) => state.registerAgent.addOrEditRAModal
  );
  const { data: countries } = useGetCountriesDataQuery();

  const entityId = useCurrentEntityId();

  const { data: registerAgent } = useGetRAByIdQuery(
    { groupId, raId, entityId },
    {
      skip: !groupId || !raId || !entityId,
      refetchOnMountOrArgChange: true,
    }
  );
  const {
    start_date,
    expiration_date,
    agent_name,
    street_address,
    street_address_line_2,
    state,
    city,
    zipcode,
    phone_number,
  } = registerAgent || {};

  const onClose = () => {
    dispatch(closeAddOrEditModal());
  };

  const saveRegisterAgent = async ({
    start_date,
    expiration_date,
    agent_name,
    street_address,
    street_address_line_2,
    country,
    state,
    city,
    zipcode,
    phone_number,
  }: FormikValues) => {
    try {
      const payload = {
        agent_name,
        street_address,
        street_address_line_2,
        country,
        state,
        city,
        zipcode,
        phone_number,
        expiration_date: dayjs(expiration_date).format("YYYY-MM-DD"),
        start_date: dayjs(start_date).format("YYYY-MM-DD"),
      };

      if (raId) {
        await updateRegisteredAgent({ groupId, payload, raId }).unwrap();
        successToast({ message: "Registered Agent updated" });
      } else {
        await createRegisteredAgent({ payload, groupId, entityId }).unwrap();
        successToast({ message: "Registered Agent Added" });
      }
      onSuccess?.();
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={onClose}>
      {show && (
        <Formik
          onSubmit={saveRegisterAgent}
          validationSchema={registerAgentSchema}
          initialValues={{
            agent_name: agent_name || "",
            start_date: start_date || "",
            expiration_date: expiration_date || "",
            street_address: street_address || "",
            street_address_line_2: street_address_line_2 || "",
            country: US,
            state: stateName || state || "",
            city: city || "",
            zipcode: zipcode || "",
            phone_number: phone_number || "",
          }}
          validateOnChange
          validateOnBlur={false}
          enableReinitialize
        >
          {({ isSubmitting, values }) => {
            return (
              <Modal.Content asChild useCustomOverlay>
                <Form>
                  <Modal.Header>
                    <Modal.Title>
                      {raId
                        ? "Edit registered agent"
                        : "Add a registered agent"}
                    </Modal.Title>
                    <Modal.Close />
                  </Modal.Header>
                  <Modal.Body className="t-flex t-flex-col t-gap-5">
                    <TextInput
                      name="agent_name"
                      block
                      label="Agent Name"
                      placeholder="Enter Agent Name..."
                    />
                    <div className="t-flex t-gap-4">
                      <Field name="start_date">
                        {({ field }: FieldProps) => (
                          <DateInput
                            {...field}
                            required
                            portalId={field.name}
                            label="Starting date"
                            placeholder="Enter starting date"
                          />
                        )}
                      </Field>
                      <Field name="expiration_date">
                        {({ field }: FieldProps) => (
                          <DateInput
                            required
                            {...field}
                            portalId={field.name}
                            label="Expiration date"
                            placeholder="Enter expiration date"
                          />
                        )}
                      </Field>
                    </div>

                    <APIProvider
                      apiKey={API_KEY || ""}
                      solutionChannel="GMP_devsite_samples_v3_rgmautocomplete"
                    >
                      <div className="autocomplete-control">
                        <PlaceAutocomplete
                          availableCountriesData={countries || []}
                          required
                        />
                      </div>
                    </APIProvider>
                    {!stateName && (
                      <Combobox
                        required
                        isDisabled={!values.country}
                        menuPortalTarget={document.body}
                        name="state"
                        withForm
                        label="State"
                        placeholder="Select State"
                        options={
                          countries
                            ?.find((c) => c.name === values.country)
                            ?.states?.map((state) => ({
                              value: state.name,
                              label: state.name,
                            }))!
                        }
                        value={
                          values.state
                            ? { label: values.state, value: values.state }
                            : null
                        }
                      />
                    )}

                    <TextInput required block label="City" name="city" />

                    <TextInput
                      required
                      block
                      label="Zip, postal or pin code"
                      name="zipcode"
                    />
                    <TextInput
                      name="phone_number"
                      label="Phone No."
                      placeholder="Enter Phone No..."
                    />
                  </Modal.Body>
                  <Modal.FooterButtonGroup>
                    <Button
                      type="reset"
                      disabled={isSubmitting}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      customType="primary"
                      type="submit"
                      isLoading={isSubmitting}
                      disabled={isSubmitting}
                    >
                      Save
                    </Button>
                  </Modal.FooterButtonGroup>
                </Form>
              </Modal.Content>
            );
          }}
        </Formik>
      )}
    </Modal.Root>
  );
};
