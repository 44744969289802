import { Divider } from "components/design/Divider";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { SelectAutofillCombobox } from "components/SelectAutofillCombobox";
import { US } from "constants/countryCodes";
import { useFormikContext } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetCountriesDataQuery } from "store/apis/onboarding";
import { SalesTaxBusinessPayload } from "store/apis/salesTax";

export const SalesTaxEntityDetails = () => {
  const group = useCurrentGroupContext();
  const entityId = group.entities?.[0]?.uuid;

  const { data: countries = [], isLoading } = useGetCountriesDataQuery();

  const usFormationTypes = countries.find(
    (country) => country.code_alpha_2 === US
  );

  const { values, setFieldValue } = useFormikContext<
    SalesTaxBusinessPayload & {
      mailing_same_as_business_address: boolean;
    }
  >();

  const formationTypeOptions = usFormationTypes?.formation_type?.map((f) => ({
    value: f.uuid,
    label: f.name,
  }));

  return (
    <div className="t-flex t-justify-center">
      <div className="t-w-[650px]">
        <div>
          <p className="t-text-title-h2-bold t-mb-1">Business details</p>
          <p className="t-text-body-sm t-text-text-30">
            This business information is required for enabling automated tax
            remittance.
          </p>
        </div>

        <div className="t-flex t-flex-col t-gap-4">
          <div className="t-grid t-grid-cols-2 t-gap-4">
            <TextInput
              name="business_name"
              label="Business Name"
              placeholder="Enter business name"
            />
            <TextInput
              tooltipText="DBA stands for (Doing Business As) "
              name="trade_name"
              label="Trade name or DBA"
              placeholder="Enter trade name"
            />
          </div>

          <div className="t-grid t-grid-cols-2 t-gap-4">
            <TextInput
              name="federal_id"
              label="Federal EIN or SSN"
              placeholder="Enter EIN or SSN"
            />
            <Combobox
              name="formation_type_id"
              label="Entity Type"
              withForm
              placeholder="Enter entity type"
              options={formationTypeOptions || []}
              value={
                formationTypeOptions?.find(
                  (option) => option.value === values.formation_type_id
                ) || null
              }
            />
          </div>

          <div className="t-my-2.5">
            <Divider />
          </div>

          <p className="t-text-subtext t-m-0">Contact details</p>

          <div className="t-grid t-grid-cols-2 t-gap-4">
            <TextInput
              name="email"
              label="Business email"
              placeholder="Enter business email"
            />
            <div className="t-flex t-flex-col">
              <MobileInput
                customSize="regular"
                name="phone"
                label="Business phone no."
                placeholder="Enter number"
              />
            </div>
          </div>

          <SelectAutofillCombobox
            type="addresses"
            name="business_address_id"
            label="Business address"
            entityId={entityId}
            onChange={(uuid) => setFieldValue("business_address_id", uuid)}
            selected={values.business_address_id}
          />

          <Checkbox
            onChange={(e) => {
              setFieldValue(
                "mailing_same_as_business_address",
                e.target.checked
              );
            }}
            label={
              <span className="t-text-body-sm t-text-neutral-40">
                Add business address as mailing address
              </span>
            }
            name="mailing_same_as_business_address"
          />

          {!values.mailing_same_as_business_address && (
            <SelectAutofillCombobox
              type="addresses"
              name="mailing_address_id"
              label="Mailing address"
              entityId={entityId}
              onChange={(uuid) => setFieldValue("mailing_address_id", uuid)}
              selected={values.mailing_address_id}
            />
          )}
        </div>
      </div>
    </div>
  );
};
