import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { BILLING_INFO_CONTACT_EDITED } from "constants/analyticsEvents";
import { Form, Formik } from "formik";
import { billingContactSchema } from "formValidations/billingInfo";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import { useContext } from "react";
import {
  useGetBillingInfoQuery,
  usePatchBillingEmailMutation,
} from "store/apis/billing";
import { useGetTeamMembersQuery } from "store/apis/teamSetting";

const EditBillingContact = ({
  show,
  closeModal,
  entityId,
}: {
  show: boolean;
  closeModal: () => void;
  entityId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const { name: groupName, uuid: groupId } = useCurrentGroupContext();
  const {
    authtoken: { email: emailId },
  } = useContext(authContext);

  const { data: teamMembers } = useGetTeamMembersQuery(
    { groupId },
    { skip: !groupId }
  );

  const { data: billingInfo } = useGetBillingInfoQuery(
    {
      group_id: groupId!,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const [editBillingEmail] = usePatchBillingEmailMutation();
  const { trackEvent } = useAnalytics();

  const handleSubmit = async ({ email }: { email: string }) => {
    try {
      await editBillingEmail({
        group_id: groupId,
        billing_email: email,
        entityId,
      }).unwrap();
      successToast({ message: "Billing email has been updated" });
      trackEvent(BILLING_INFO_CONTACT_EDITED, {
        group_id: groupId,
        email: emailId,
      });
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
      closeModal();
    }
  };

  return (
    <Modal.Root open={show} onOpenChange={closeModal}>
      <Formik
        validateOnMount
        initialValues={{
          email: billingInfo?.billing_email || "",
          companyName: groupName,
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={() => billingContactSchema(groupName)}
      >
        {({ isSubmitting, isValid, values, dirty, submitForm }) => {
          const selectedUser = teamMembers?.group_users.find(
            ({ email }) => email === values.email
          );

          return (
            <Form className="t-m-0">
              <Modal.Content
                className="!t-p-0 md:!t-min-w-[440px]"
                useCustomOverlay
              >
                <Modal.Header>
                  <Modal.Title>Billing Contact</Modal.Title>
                  <Modal.Close type="button" />
                </Modal.Header>
                <Modal.Body>
                  <div className="t-flex t-gap-4 t-flex-col">
                    <Combobox
                      withForm
                      label="Email"
                      name="email"
                      menuPortalTarget={document.body}
                      value={{ label: values.email, value: values.email }}
                      options={teamMembers?.group_users?.map(({ email }) => ({
                        label: email,
                        value: email,
                      }))}
                    >
                      <option value="" key="">
                        Select User
                      </option>
                    </Combobox>

                    <TextInput
                      disabled
                      value={selectedUser?.name}
                      name="name"
                      label="Name"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="t-flex t-justify-end t-gap-3">
                    <Button onClick={closeModal}>Cancel</Button>
                    <Button
                      onClick={submitForm}
                      customType="primary"
                      isLoading={isSubmitting}
                      disabled={!dirty || !isValid || isSubmitting}
                    >
                      Save
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal.Content>
            </Form>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};

export default EditBillingContact;
