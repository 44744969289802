import { FINANCIAL_RECORD_TYPE } from "constants/vendorBills";
import qs from "qs";
import { Pagination } from "types/Models/pagination";
import { VendorBill } from "types/Models/vendorBills";
import { emptyApi } from "./emptyApi";

export type UpdatePayload = VendorBill & {
  mark_as_active?: boolean;
};

export type PublicPayload = VendorBill & {
  reset_changes?: boolean;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllBills: build.query<
      {
        bills: VendorBill[];
        due_amount: number;
        total_amount: number;
        paid_amount: number;
      } & Pagination,
      {
        groupId: string;
        entityId: string;
        bill_type: keyof typeof FINANCIAL_RECORD_TYPE;
        page_num?: number;
        search_term: string | null;
        ids?: string;
        merchant_id?: string;
      }
    >({
      query: ({
        groupId,
        entityId,
        bill_type,
        search_term,
        page_num,
        ids,
        merchant_id,
      }) => {
        const query = qs.stringify(
          {
            type: "BILL",
            bill_type: bill_type,
            page_num: page_num,
            search_term: search_term || null,
            ids,
            merchant_id: merchant_id || null,
          },
          {
            skipNulls: true,
          }
        );
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/vendor_bills/`,
          params: qs.parse(query),
        };
      },
      providesTags: (result) => (result ? ["VENDOR_BILL"] : []),
    }),

    addBillOrRequest: build.mutation<
      VendorBill,
      {
        groupId: string;
        entityId: string;
        bill_type: keyof typeof FINANCIAL_RECORD_TYPE;
      }
    >({
      query: ({ groupId, entityId, bill_type }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/vendor_bill/`,
          method: "POST",
          body: {
            bill_type,
            type: "BILL",
          },
        };
      },
      invalidatesTags: (result) => (result ? ["VENDOR_BILL"] : []),
    }),

    uploadBulkBills: build.mutation<
      { bulk_file_id: string },
      {
        entityId: string;
        file: File;
        signal?: AbortSignal;
      }
    >({
      query: ({ entityId, file, signal }) => {
        const formData = new FormData();
        formData.append("excel", file);
        formData.append("type", "BILL");
        return {
          url: `api/inkle/invoicing/entity/${entityId}/bulk_xlsx/`,
          method: "POST",
          body: formData,
          signal,
        };
      },
    }),

    populateBulkBills: build.mutation<
      { bulk_file_id: string },
      {
        entityId: string;
        bulk_file_id: string;
        signal?: AbortSignal;
      }
    >({
      query: ({ entityId, bulk_file_id, signal }) => {
        return {
          url: `api/inkle/invoicing/entity/${entityId}/populate_bulk_xlsx/`,
          method: "POST",
          body: {
            bulk_file_id,
            type: "BILL",
          },
          signal,
        };
      },
      invalidatesTags: (result) => (result ? ["VENDOR_BILL"] : []),
    }),

    getBillOrRequest: build.query<
      VendorBill,
      {
        groupId: string;
        entityId: string;
        billId: string;
      }
    >({
      query: ({ groupId, entityId, billId }) => {
        const query = qs.stringify(
          {
            type: "BILL",
          },
          {}
        );
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/vendor_bill/${billId}/`,
          params: qs.parse(query),
        };
      },
      providesTags: (result) => (result ? ["VENDOR_BILL"] : []),
    }),

    updateBillOrRequest: build.mutation<
      VendorBill,
      {
        groupId: string;
        entityId: string;
        billId: string;
        payload: Partial<UpdatePayload>;
      }
    >({
      query: ({ groupId, entityId, billId, payload }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/vendor_bill/${billId}/`,
          method: "PATCH",
          body: { ...payload, type: "BILL" },
        };
      },
      invalidatesTags: (result) => (result ? ["VENDOR_BILL"] : []),
    }),

    uploadBill: build.mutation<
      VendorBill,
      {
        groupId: string;
        entityId: string;
        billId: string;
        bill_document: File;
      }
    >({
      query: ({ groupId, entityId, billId, bill_document }) => {
        const formData = new FormData();
        formData.append("type", "BILL");
        formData.append("bill_document", bill_document);

        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/vendor_bill/${billId}/`,
          method: "PATCH",
          body: formData,
        };
      },
      invalidatesTags: (result) => (result ? ["VENDOR_BILL"] : []),
    }),

    downloadBillOrRequest: build.query<
      { doc_id: string },
      {
        groupId: string;
        entityId: string;
        billId: string;
      }
    >({
      query: ({ groupId, entityId, billId }) => {
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/vendor_bill/${billId}/download/`,
        };
      },
      providesTags: (result) => (result ? ["VENDOR_BILL"] : []),
    }),

    sendRequest: build.mutation<
      VendorBill,
      {
        groupId: string;
        entityId: string;
        billId: string;
        payload: {
          subject: string;
          vendor_bill_public_url: string;
          to: string;
        };
      }
    >({
      query: ({ groupId, entityId, billId, payload }) => {
        const formData = new FormData();
        formData.append("type", "BILL");
        Object.entries(payload).forEach(([key, value]) => {
          formData.append(key, value);
        });

        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/vendor_bill/${billId}/send/`,
          method: "POST",
          body: formData,
        };
      },
      invalidatesTags: (result) => (result ? ["VENDOR_BILL"] : []),
    }),

    deleteBillOrRequest: build.mutation<
      VendorBill,
      {
        groupId: string;
        entityId: string;
        billId: string;
      }
    >({
      query: ({ groupId, entityId, billId }) => {
        const query = qs.stringify(
          {
            type: "BILL",
          },
          {}
        );
        return {
          url: `/api/inkle/invoicing/group/${groupId}/entity/${entityId}/vendor_bill/${billId}/`,
          method: "DELETE",
          params: qs.parse(query),
        };
      },
      invalidatesTags: (result) => (result ? ["VENDOR_BILL"] : []),
    }),

    getPubilcBill: build.query<
      VendorBill,
      {
        billId: string;
      }
    >({
      query: ({ billId }) => {
        const query = qs.stringify({
          type: "BILL",
        });
        return {
          url: `/api/inkle/invoicing/vendor_bill/${billId}/`,
          params: qs.parse(query),
        };
      },
      providesTags: (result) => (result ? ["PUBLIC_VENDOR_BILL"] : []),
    }),

    updatePubilcBill: build.mutation<
      VendorBill,
      { payload: Partial<PublicPayload>; billId: string }
    >({
      query: ({ billId, payload }) => {
        return {
          url: `/api/inkle/invoicing/vendor_bill/${billId}/`,
          method: "PATCH",
          body: { ...payload, type: "BILL" },
        };
      },
      invalidatesTags: (result) => (result ? ["PUBLIC_VENDOR_BILL"] : []),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllBillsQuery,
  useAddBillOrRequestMutation,
  useGetBillOrRequestQuery,
  useLazyGetBillOrRequestQuery,
  useUpdateBillOrRequestMutation,
  useUploadBillMutation,
  useLazyDownloadBillOrRequestQuery,
  useSendRequestMutation,
  useDeleteBillOrRequestMutation,
  useGetPubilcBillQuery,
  useUpdatePubilcBillMutation,
  useUploadBulkBillsMutation,
  usePopulateBulkBillsMutation,
} = extendedApi;
