import qs from "qs";
import { emptyApi } from "./emptyApi";
import {
  NexusTracker,
  NexusTrackerTransactionsResponse,
} from "types/Models/nexusTracker";
import { fileAndFolder } from "types/Models/documents";

export type Registration = {
  state_registration_id: string;
  state: string;
  tax_detail_id: string;
};

export type SalesTaxBusinessPayload = {
  business_name?: string;
  federal_id?: string;
  trade_name?: string;
  business_type?: string;
  email?: string;
  phone?: string;
  business_address_id?: string;
  mailing_address_id?: string;
  naics_code?: string;
  collect_tax_from?: string;
  major_business_activity?: string;
  owner_id?: string;
  owner_phone?: string;
  owner_designation?: string;
  owner_position_start_date?: string;
  owner_date_of_birth?: string;
  owner_ssn?: string;
  owner_government_id?: string;
  formation_type_id?: string;
  warehouse_states?: string[];
  office_states?: string[];
  payroll_states?: string[];
};

export const salesNexusTracker = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllNexusTrackers: build.query<
      NexusTracker,
      {
        pageNum: number;
        searchTerm?: string | null;
        entityId: string;
        filter_status?: string;
      }
    >({
      query: ({ entityId, pageNum, searchTerm, filter_status }) => {
        let queryUrl = qs.stringify(
          {
            page_num: pageNum,
            search_term: searchTerm,
            filter_status,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/salestax/entity/${entityId}/nexus_tracker/${queryUrl}`,
        };
      },
      providesTags: (result) => (result ? ["NEXUSTRACKER"] : []),
    }),
    getNexusTrackerTransactions: build.query<
      NexusTrackerTransactionsResponse,
      {
        entityId: string;
        jurisdictionName: string;
        pageNum?: number;
      }
    >({
      query: ({ entityId, jurisdictionName, pageNum = 1 }) => {
        let queryUrl = qs.stringify(
          {
            jurisdiction_name: jurisdictionName,
            page_num: pageNum,
          },
          { skipNulls: true, addQueryPrefix: true }
        );
        return {
          url: `/api/inkle/salestax/entity/${entityId}/nexus_tracker/transactions${queryUrl}`,
        };
      },
      providesTags: ["NEXUSTRACKER"],
    }),
    uploadBulkSalesTaxInvoice: build.mutation<
      {
        bulk_file_id: string;
      },
      {
        entityId: string;
        file: File;
      }
    >({
      query: ({ entityId, file }) => {
        const formData = new FormData();
        formData.append("excel", file);

        return {
          url: `/api/inkle/salestax/entity/${entityId}/bulk_invoices/`,
          method: "POST",
          body: formData,
        };
      },
    }),
    populateBulkSalesTaxInvoice: build.mutation<
      fileAndFolder[],
      {
        entityId: string;
        bulkFileId: string;
      }
    >({
      query: ({ entityId, bulkFileId }) => {
        return {
          url: `/api/inkle/salestax/entity/${entityId}/populate_bulk_invoices/`,
          method: "POST",
          body: { bulk_file_id: bulkFileId },
        };
      },
      invalidatesTags: ["NEXUSTRACKER"],
    }),
    createSalesTaxBusiness: build.mutation<
      SalesTaxBusinessPayload,
      { entityId: string; payload: SalesTaxBusinessPayload }
    >({
      query: ({ entityId, payload }) => ({
        url: `/api/inkle/salestax/entity/${entityId}/business/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["SALES_TAX_BUSINESS" as const],
    }),

    getSalexTaxBusiness: build.query<
      SalesTaxBusinessPayload,
      { entityId: string }
    >({
      query: ({ entityId }) => ({
        url: `/api/inkle/salestax/entity/${entityId}/business/`,
      }),
      providesTags: ["SALES_TAX_BUSINESS" as const],
    }),
    updateSalesTaxBusiness: build.mutation<
      SalesTaxBusinessPayload,
      { entityId: string; payload: SalesTaxBusinessPayload }
    >({
      query: ({ entityId, payload }) => ({
        url: `/api/inkle/salestax/entity/${entityId}/business/`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["SALES_TAX_BUSINESS" as const],
    }),

    getSalesTaxRegistrations: build.query<Registration[], { entityId: string }>(
      {
        query: ({ entityId }) => ({
          url: `/api/inkle/salestax/entity/${entityId}/registrations/`,
        }),
      }
    ),

    updateSalesTaxRegistration: build.mutation<
      SalesTaxBusinessPayload,
      {
        entityId: string;
        registration_id: string;
        payload: { tax_detail_id: string };
      }
    >({
      query: ({ entityId, payload, registration_id }) => ({
        url: `/api/inkle/salestax/entity/${entityId}/registrations/${registration_id}/`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["SALES_TAX_BUSINESS" as const],
    }),
  }),
});

export const {
  useGetAllNexusTrackersQuery,
  useGetNexusTrackerTransactionsQuery,
  useUploadBulkSalesTaxInvoiceMutation,
  usePopulateBulkSalesTaxInvoiceMutation,
  useCreateSalesTaxBusinessMutation,
  useGetSalexTaxBusinessQuery,
  useUpdateSalesTaxBusinessMutation,
  useUpdateSalesTaxRegistrationMutation,
  useGetSalesTaxRegistrationsQuery,
} = salesNexusTracker;
