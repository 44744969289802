import { useToast } from "hooks/useToast";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import randomBytes from "randombytes";
import { useLazyGetConnectionAuthUrlQuery } from "store/apis/booksConnections";
import { BackendError } from "types/utils/error";
import { useState } from "react";
import { TRANSACTION_PULL_TYPE } from "types/contants/transactionPullTypes";

export type ConnectionLocalState = {
  entityId: string;
  groupId: string;
  connectionId: string;
  invokedFrom?: string;
  txn_pull_date: string;
  txn_pull_choice: TRANSACTION_PULL_TYPE;
};

export type onConnectArgs = {
  connectionId: string;
  entityIdInArg?: string;
  invokedFrom?: string;
  type?: "POPUP" | "REDIRECT";
  entityItemId?: string;
  state?: Pick<ConnectionLocalState, "txn_pull_choice" | "txn_pull_date">;
};

export const useBankConnect = ({
  onConnectSuccess,
}: {
  onConnectSuccess?: () => void;
} = {}) => {
  const [connecting, setConnecting] = useState(false);
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const [getAuthUrl, { isLoading, originalArgs }] =
    useLazyGetConnectionAuthUrlQuery();

  const onConnect = async ({
    connectionId,
    entityIdInArg,
    invokedFrom,
    state,
    type = "REDIRECT",
    entityItemId,
  }: onConnectArgs) => {
    if (groupId && (entityIdInArg || entityId)) {
      try {
        const stateKey = randomBytes(16).toString("hex");
        localStorage.setItem("connection_oauth_state_key", stateKey);

        localStorage.setItem(
          stateKey,
          JSON.stringify({
            entityId: entityIdInArg || entityId,
            groupId,
            connectionId,
            invokedFrom,
            ...state,
          } as ConnectionLocalState)
        );

        const { authorization_url } = await getAuthUrl({
          groupId,
          connectionId,
          entityId: (entityIdInArg || entityId)!,
          state: stateKey,
          entityItemId,
        }).unwrap();

        if (type === "POPUP") {
          return authorization_url;
        }

        window.location.href = authorization_url;
      } catch (error) {
        alertToast({ message: (error as BackendError).data?.error?.message });
      }
    }
  };

  const onConnectPopup = async ({
    connectionId,
    entityId,
  }: {
    connectionId: string;
    entityId: string;
  }) => {
    setConnecting(true);
    try {
      const authUrl = await onConnect({
        connectionId: connectionId,
        entityIdInArg: entityId,
        type: "POPUP",
      });

      if (authUrl) {
        const handle = window.open(
          `${window.location.origin}/redirect?url=${encodeURIComponent(
            authUrl
          )}`,
          "BrexConnect"
        );

        if (handle) {
          await new Promise<void>((resolve, reject) => {
            window.addEventListener(
              "message",
              function (ev) {
                if (ev.data === "CONNECTION_AUTHENTICATION_SUCCESS") {
                  resolve();
                }

                if (ev.data === "CONNECTION_AUTHENTICATION_FAILED") {
                  reject("Connection failed");
                }
              },
              false
            );
          });

          onConnectSuccess?.();
          handle.close();
        }
      }
    } catch (error: any) {
      alertToast(
        { message: error?.response?.data?.error?.message || error },
        error
      );
    }
    setConnecting(false);
  };

  return {
    isLoading: isLoading || connecting,
    originalArgs,
    onConnect,
    onConnectPopup,
  };
};
