import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PatternInput } from "components/PatternInput/PatternInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik, useFormikContext } from "formik";
import { parentAccount } from "formValidations/coaCreation";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import React, { FormEvent, useState } from "react";
import {
  useAddTransactionCategoryMutation,
  useGetAutoGenerateNextAvailableIdentiferQuery,
  useLazyGetAutoGenerateNextAvailableIdentiferQuery,
} from "store/apis/chartOfAccounts";
import { TxnCategories } from "types/Models/books";
import { associatedReport } from "utils/associatedReport";
import { DateSelector } from "./AddNewAccount";
import { FormikListener } from "pages/Books/Invoicing/AddInvoice";
import { CategoryTag } from "./EditCategory";

export const AddNewParentAccount = ({
  showAddParentAccount,
  setShowAddParentAccount,
  openPreviousModal,
  onSuccess,
}: {
  showAddParentAccount: boolean;
  setShowAddParentAccount: (newState: React.SetStateAction<boolean>) => void;
  openPreviousModal: () => void;
  onSuccess?: ({
    uuid,
    name,
    category_type,
  }: Pick<TxnCategories, "uuid" | "name" | "category_type">) => void;
}) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [submitCount, setSubmitCount] = useState(0);
  const entityId = useCurrentEntityId();
  const { chartOfAccounts: COAData } = useChartOfAccounts({
    hiddenCategoryTypes: ["BANK_ACCOUNT", "BANK_TRANSFER", "PAY_DOWN_CREDIT"],
  });

  interface NewParentAccountValues {
    typeId: string;
    name: string;
    account_id: string;
    account_id_prefix: string;
    start_date: string;
    opening_balance: string;
  }

  const topCategories = COAData.map(
    ({
      uuid,
      name,
      parent_id,
      parent_name,
      description,
      identifier,
      identifier_prefix,
    }) => ({
      uuid,
      name,
      parent_id,
      parent_name,
      description,
      identifier,
      identifier_prefix,
    })
  );

  const [createTransactionCategory, { isLoading }] =
    useAddTransactionCategoryMutation();

  const createParentAccountCategory = async (values: {
    typeId: string;
    name: string;
    account_id: string;
    account_id_prefix: string;
    start_date: string;
    opening_balance: string;
    category_tag: string;
  }) => {
    const payload = {
      parent_uuid: values.typeId,
      name: values.name,
      identifier: Number(values.account_id),
      start_date: values.start_date,
      opening_balance: Number(values.opening_balance),
      category_tag: values.category_tag,
    };
    try {
      const { uuid, name, category_type } = await createTransactionCategory({
        groupId,
        entityId,
        payload,
      }).unwrap();
      successToast({ title: "Category added" });
      onSuccess?.({ uuid, name, category_type });
      setShowAddParentAccount(false);
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const [
    getAutoGenerateNextAvailableIdentifier,
    { data: { identifier: availableIdentifier } = { identifier: 0 } },
  ] = useLazyGetAutoGenerateNextAvailableIdentiferQuery();

  const fetchAvailableIdentifier = async (values: NewParentAccountValues) => {
    if (values && values.typeId !== "") {
      try {
        const { data } = await getAutoGenerateNextAvailableIdentifier({
          groupId,
          entityId,
          parentId: values.typeId,
        });
        return data;
      } catch (error: any) {}
    }
  };
  return (
    <Modal.Root
      open={showAddParentAccount}
      onOpenChange={setShowAddParentAccount}
    >
      <Formik
        initialValues={{
          name: "",
          account_id: "",
          typeId: "",
          account_id_prefix: "",
          start_date: "",
          opening_balance: "",
          dateSelector: "",
          category_tag: "",
        }}
        onSubmit={createParentAccountCategory}
        validationSchema={parentAccount}
        validateOnBlur={submitCount > 0 ? true : false}
        validateOnChange={submitCount > 0 ? true : false}
      >
        {({ setFieldValue, values, submitForm }) => {
          const selectedType = topCategories.find(
            ({ uuid }) => uuid === values.typeId
          )?.identifier_prefix;

          const { isBalanceSheetAssociated, isIncomeStatementAssociated } =
            associatedReport(selectedType!);

          return (
            <Form className="t-m-0 t-w-full">
              <Modal.Content>
                <Modal.Header>
                  <Modal.Title>Add a new parent category</Modal.Title>
                  <Modal.Close type="button" />
                </Modal.Header>

                <FormikListener
                  values={values}
                  callback={async (newValues) => {
                    let data = await fetchAvailableIdentifier(
                      newValues as NewParentAccountValues
                    );
                    if (data?.identifier)
                      setFieldValue("account_id", data?.identifier);
                  }}
                />
                <Modal.Body className="t-flex t-flex-col t-gap-4">
                  <TextInput label="Name of the parent category" name="name" />
                  <div className="t-flex t-gap-4 t-items-start">
                    <div className="t-w-1/2">
                      <Combobox
                        components={{ ClearIndicator: () => null }}
                        withForm
                        name="typeId"
                        menuPortalTarget={document.body}
                        label="Type"
                        required
                        options={topCategories?.map(
                          ({ name, uuid, identifier_prefix }) => {
                            return {
                              value: uuid,
                              label: name,
                              account_id_prefix: identifier_prefix,
                            };
                          }
                        )}
                        onChange={(option: any) =>
                          setFieldValue(
                            "account_id_prefix",
                            option?.account_id_prefix
                          )
                        }
                      />
                    </div>
                    <div className="t-w-1/2">
                      {availableIdentifier ? (
                        <TextInput
                          label="Category ID"
                          name="account_id"
                          type="number"
                          value={availableIdentifier}
                          disabled
                          note="Identifier has been pre-filled"
                        />
                      ) : (
                        <PatternInput
                          fieldProps={{
                            name: "account_id",
                          }}
                          patternProps={{
                            format: `${values.account_id_prefix}####`,
                            mask: values?.account_id_prefix ? "_" : "",
                            allowEmptyFormatting: true,
                            valueIsNumericString: true,
                          }}
                          label="Category ID"
                        />
                      )}
                    </div>
                  </div>
                  <div className="t-flex t-flex-col t-gap-4">
                    {isBalanceSheetAssociated && (
                      <div className="t-flex t-gap-4">
                        <div className="t-w-1/2">
                          <DateSelector />
                        </div>
                        <div className="t-w-1/2">
                          <PriceInput
                            disabled={!Boolean(values.start_date)}
                            name="opening_balance"
                            label={`Account balance as on ${
                              values.start_date
                                ? dayjs(values.start_date)
                                    .subtract(1, "days")
                                    .format(DD_MMM_YYYY)
                                : "_"
                            }`}
                            type="number"
                            required
                          />
                        </div>
                      </div>
                    )}
                    <CategoryTag parentId={values?.typeId!} />
                    {(isBalanceSheetAssociated ||
                      isIncomeStatementAssociated) && (
                      <div className="t-flex t-flex-col">
                        <Label>Associated Reports</Label>
                        {isBalanceSheetAssociated && (
                          <Tag tagType="yellow">Balance Sheet</Tag>
                        )}
                        {isIncomeStatementAssociated && (
                          <Tag tagType="orange">Income Statement</Tag>
                        )}
                      </div>
                    )}
                  </div>
                </Modal.Body>
                <Modal.Footer className="t-flex t-justify-end t-gap-3">
                  <Button
                    onClick={() => {
                      openPreviousModal();
                      setShowAddParentAccount(false);
                    }}
                    type="button"
                  >
                    Back
                  </Button>
                  <Button
                    customType="primary"
                    isLoading={isLoading}
                    disabled={isLoading}
                    onClick={() => {
                      setSubmitCount((prev) => prev + 1);
                      submitForm();
                    }}
                  >
                    Create
                  </Button>
                </Modal.Footer>
              </Modal.Content>
            </Form>
          );
        }}
      </Formik>
    </Modal.Root>
  );
};
