import { Button } from "components/DesignSystem/Button/Button";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Cross } from "components/icons/Cross";
import { PlusIcon } from "components/icons/PlusIcon";
import { Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Check from "static/images/check.svg";
import { useAddNewFieldMutation } from "store/apis/entity";
import { BackendError } from "types/utils/error";
import { object, string } from "yup";

const AddFieldForm = ({
  stateId,
  onRemove,
  entityId: entityIdFromProps,
}: {
  onRemove: () => void;
  stateId: string;
  entityId?: string;
}) => {
  const { entityId: entityIdFromParams } = useParams<{ entityId: string }>();
  const entityId = entityIdFromParams || entityIdFromProps || "";

  const { successToast, alertToast } = useToast();
  const [addNewField] = useAddNewFieldMutation();

  const onAdd = async ({ fieldName }: { fieldName: string }) => {
    try {
      await addNewField({
        entityId,
        stateId,
        fieldName,
        fieldType: "STATE_FIELD",
      }).unwrap();
      successToast({ message: "Field added successfully" });
      onRemove();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <Formik
      initialValues={{ fieldName: "" }}
      onSubmit={onAdd}
      validateOnChange
      validationSchema={object({
        fieldName: string().min(2).required("Title required"),
      })}
    >
      {({ submitForm, isSubmitting }) => (
        <Form className="all:unset t-flex  t-items-center t-gap-4">
          <TextInput
            label="Field name"
            placeholder="Enter name"
            name="fieldName"
          />
          <span className="t-self-end t-flex">
            <Button
              customType="ghost_icon"
              onClick={submitForm}
              isLoading={isSubmitting}
              disabled={isSubmitting}
            >
              <img src={Check} alt="add" />
            </Button>
            <Button
              type="button"
              customType="ghost_icon"
              onClick={onRemove}
              disabled={isSubmitting}
            >
              <Cross size="20" />
            </Button>
          </span>
        </Form>
      )}
    </Formik>
  );
};

const AddNewField = ({
  stateId,
  entityId,
}: {
  stateId: string;
  entityId?: string;
}) => {
  const [addField, setAddField] = useState(false);

  return (
    <div>
      {addField ? (
        <AddFieldForm
          onRemove={() => setAddField(false)}
          stateId={stateId}
          entityId={entityId}
        />
      ) : (
        <Button type="button" onClick={() => setAddField(true)} size="small">
          <PlusIcon />
          <span className="t-ml-1">Add field</span>
        </Button>
      )}
    </div>
  );
};

export default AddNewField;
