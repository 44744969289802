import { OnboardingProduct, StepComponent } from "types/Models/onboarding";
import { emptyApi } from "./emptyApi";

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getOnboardingProducts: build.query<
      OnboardingProduct[],
      { groupId: string }
    >({
      query: ({ groupId }) => {
        return {
          url: `/api/inkle/platform/group/${groupId}/product_onboarding/`,
        };
      },
      providesTags: ["OnboardingProducts", "Subscriptions"],
    }),
    getStepForms: build.query<
      StepComponent[],
      { groupId: string; formStepId: string }
    >({
      query: ({ groupId, formStepId }) => {
        return {
          url: `/api/inkle/platform/group/${groupId}/form_step/${formStepId}/`,
        };
      },
      providesTags: ["OnboardingSteps", "Subscriptions"],
    }),
    updateStepField: build.mutation({
      query: ({ groupId, fieldId, payload }) => {
        return {
          url: `/api/inkle/platform/group/${groupId}/field/${fieldId}/update/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: (result) =>
        result ? ["OnboardingSteps", "OnboardingProducts"] : [],
    }),
    uploadDocuments: build.mutation({
      query: ({ groupId, fieldId, payload }) => {
        return {
          url: `/api/inkle/platform/group/${groupId}/field/${fieldId}/document/upload/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["OnboardingSteps"],
    }),
    deleteDocuments: build.mutation({
      query: ({ groupId, fieldDocumentMapId }) => {
        return {
          url: `/api/inkle/platform/group/${groupId}/field_document/${fieldDocumentMapId}/delete/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["OnboardingSteps"],
    }),
    changeStep: build.mutation({
      query: ({ groupId, formStepId, action, payload }) => {
        return {
          url: `/api/inkle/platform/group/${groupId}/form_step/${formStepId}/update/?action=${action}`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: ["OnboardingProducts"],
    }),
    updateProductStatus: build.mutation({
      query: ({ groupId, productId, payload }) => {
        return {
          url: `/api/inkle/platform/group/${groupId}/product/${productId}/update/`,
          method: "PUT",
          body: payload,
        };
      },
      invalidatesTags: [
        "OnboardingProducts",
        "Tasks",
        { type: "PlaidBanks" as "PlaidBanks", id: "LIST" },
        { type: "BooksOnboarding" as "BooksOnboarding", id: "STATUS_LIST" },
      ],
    }),
    createProductOnboarding: build.mutation<
      any,
      { groupId: string; productId: string }
    >({
      query: ({ groupId, productId }) => {
        return {
          url: `api/inkle/platform/group/${groupId}/product/${productId}/create/`,
          method: "post",
        };
      },
      invalidatesTags: ["OnboardingProducts", "Tasks"],
    }),

    getEntityOnboardingProducts: build.query<
      OnboardingProduct[],
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) => {
        return {
          url: `/api/inkle/platform/group/${groupId}/entity/${entityId}/product_onboarding/`,
        };
      },
      providesTags: ["OnboardingProducts", "Subscriptions"],
    }),

    createEntityProductOnboarding: build.mutation<
      any,
      { groupId: string; productId: string; entityId: string }
    >({
      query: ({ groupId, productId, entityId }) => {
        return {
          url: `api/inkle/platform/group/${groupId}/entity/${entityId}/product/${productId}/create/`,
          method: "post",
        };
      },
      invalidatesTags: ["OnboardingProducts", "Tasks"],
    }),
  }),
});

export const {
  useGetOnboardingProductsQuery,
  useGetStepFormsQuery,
  useUpdateStepFieldMutation,
  useUploadDocumentsMutation,
  useDeleteDocumentsMutation,
  useChangeStepMutation,
  useUpdateProductStatusMutation,
  useCreateEntityProductOnboardingMutation,
  useGetEntityOnboardingProductsQuery,
  useCreateProductOnboardingMutation,
} = extendedApi;
