import dayjs from "dayjs";
import { MMM_YYYY, YYYY, Q_YYYY, DD_MMM_YYYY } from "./date";

export const lastFiveYears = Array.from({ length: 5 }, (_, i) => i)
  .reverse()
  .map((i) => dayjs().subtract(i, "year").format("YYYY"));

export const REVENUE_BASIS = {
  PRODUCT: "PRODUCT",
  CUSTOMER: "CUSTOMER",
} as const;

export const PERIOD = {
  MONTHLY: "MONTHLY",
  YEARLY: "YEARLY",
  QUARTERLY: "QUARTERLY",
  TOTALS: "TOTALS",
} as const;

export const METRICS = {
  TOTAL: "TOTAL",
  MRR: "MRR",
  ARR: "ARR",
} as const;

export const PERIOD_DATE_FORMAT = {
  [PERIOD.MONTHLY]: MMM_YYYY,
  [PERIOD.QUARTERLY]: Q_YYYY,
  [PERIOD.YEARLY]: YYYY,
  [PERIOD.TOTALS]: DD_MMM_YYYY,
};
