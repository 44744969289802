import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { SwitchCase } from "components/DesignSystem/SwitchCase/SwitchCase";
import { FilePreviewTag } from "components/FilePreviewTag/FilePreviewTag";
import { DownloadIcon } from "components/icons/Download";
import { LinkIcon } from "components/icons/LinkIcon";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { FinancialClosingDetail } from "store/apis/financialClosing";
import { useLazyGetPreviewUrlQuery } from "store/apis/previewUrl";
import { FileObject } from "types/Models/fileObject";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

const File = ({ file }: { file: FileObject }) => {
  const { alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [downloadFile, { isFetching: isDownloading }] =
    useLazyGetPreviewUrlQuery();

  const onDownloadFile = async (fileId: string) => {
    try {
      const { download_url } = await downloadFile({ fileId, groupId }).unwrap();
      window.open(download_url, "_blank");
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  return (
    <FilePreviewTag file={file} size="small" block>
      <Button
        disabled={isDownloading}
        isLoading={isDownloading}
        type="button"
        customType="ghost_icon"
        size="small"
        onClick={(e) => {
          e.stopPropagation();
          onDownloadFile(file.uuid);
        }}
      >
        <DownloadIcon />
      </Button>
    </FilePreviewTag>
  );
};

const FilItem = ({
  file,
  label,
  link,
}: {
  label: string;
  file: FileObject | null;
  link: string | null;
}) => {
  if (!file && !link) return null;

  return (
    <div className="t-py-4 t-px-3 t-flex t-items-center t-gap-4 t-border-b t-border-solid t-border-neutral-0 t-border-0 t-justify-between">
      <span className="t-text-body t-text-text-60">{label}</span>
      <div className="t-w-[335px]">
        <SwitchCase
          value="true"
          cases={{
            [String(Boolean(link))]: (
              <>
                {link && (
                  <div className="t-inline-flex t-items-center t-gap-2 t-rounded-md t-border t-border-solid t-border-neutral-10 t-p-1.5 t-justify-between t-w-full">
                    <div className="t-max-w-full t-line-clamp-1 t-word-break t-text-body">
                      {link}
                    </div>
                    <Button
                      type="button"
                      customType="ghost_icon"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        window.open(link, "_blank");
                      }}
                    >
                      <LinkIcon />
                    </Button>
                  </div>
                )}
              </>
            ),
            [String(Boolean(file))]: <>{file && <File file={file} />}</>,
          }}
        />
      </div>
    </div>
  );
};

export const FinancialsModal = ({
  financialClosing,
  isOpen,
  close,
}: ModalProps & {
  financialClosing: FinancialClosingDetail;
}) => {
  const {
    cash_flow_statement,
    balance_sheet,
    income_statement,
    cash_flow_statement_url,
    balance_sheet_url,
    income_statement_url,
    other_financials,
  } = financialClosing;

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="large">
        <Modal.Header>
          <Modal.Title>Added Financials</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <FilItem
            label="Income Statement"
            file={income_statement}
            link={income_statement_url}
          />
          <FilItem
            label="Balance sheet"
            file={balance_sheet}
            link={balance_sheet_url}
          />
          <FilItem
            label="Cash flow Statement"
            file={cash_flow_statement}
            link={cash_flow_statement_url}
          />
          <div className="t-py-4 t-px-3 t-flex t-gap-4 t-justify-between">
            <span className="t-text-body t-text-text-60">
              Other Attachments
            </span>
            <div className="t-flex t-flex-col t-gap-2 t-justify-end t-w-[335px]">
              {other_financials.map((file) => (
                <>
                  <File file={file} />
                </>
              ))}
            </div>
          </div>
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
