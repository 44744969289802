import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowDown } from "components/icons/ArrowDown";
import { CloudUploadSmall } from "components/icons/CloudUploadSmall";
import { PlusIcon } from "components/icons/PlusIcon";
import { useModal } from "hooks/useModal";
import { AddVendorModal } from "./AddVendorModal";
import { BulkAddVendor } from "./BulkAddVendor";

export const AddVendor = ({ entityId }: { entityId: string }) => {
  const bulkUploadModal = useModal();
  const addManualVendorModal = useModal();

  return (
    <>
      <Dropdown.Root>
        <Dropdown.Trigger asChild className="t-group t-text-text-60">
          <div>
            <Button size="small" customType="primary">
              Add vendors
              <div className="group-data-state-open:-t-rotate-180 t-transform t-transition t-duration-300 t-ease-in-out t-text-surface t-ml-1.5">
                <ArrowDown color="currentColor" strokeWidth="1.5" />
              </div>
            </Button>
          </div>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content
            sideOffset={8}
            side="bottom"
            className="!t-min-w-40"
            align="end"
          >
            <Dropdown.Item onSelect={bulkUploadModal.open}>
              <div className="t-flex t-items-center t-gap-2">
                <CloudUploadSmall size="14" />
                <div>Import .csv file</div>
              </div>
            </Dropdown.Item>
            <Dropdown.Item onSelect={addManualVendorModal.open}>
              <div className="t-flex t-items-center t-gap-2">
                <PlusIcon size="12" />
                <div>Add manually</div>
              </div>
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      <AddVendorModal {...addManualVendorModal} entityId={entityId} />
      <BulkAddVendor {...bulkUploadModal} entityId={entityId} />
    </>
  );
};
