import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import Card from "components/DesignSystem/Card/Card";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { cashFlowPeriod, monthPeriod } from "constants/bookkeeping";
import {
  CATEGORISATION_TYPE,
  EXPENSE_BAR_COLOR,
  PROFIT_LOSS_LINE_COLOR,
  REVENUE_BAR_COLOR,
} from "constants/booksHomePage";
import { YYYY_MM_DD } from "constants/date";
import * as DATE_PERIOD from "constants/dateFilter";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { ReactNode } from "react";
import { MultiValue, SingleValue } from "react-select";
import {
  Metrics,
  useGetMetricsQuery,
  useGetRevenueMetricsQuery,
} from "store/apis/metrics";
import { formatAmount } from "utils/foramtAmount";
import { DateRangeValue, getDateRange } from "utils/getDateRange";
import { Legend, LegendDot } from "../ChartComponents";
import { BarChart } from "./CashFlowChart";
import { HomePageCardHeader, HomePageCardTitle } from "../HomePageCardHeader";
import { HideableAmount } from "../HideBalancesAndAmounts";

type FilterType = {
  PERIOD: DateRangeValue;
};

const MetricsItem = ({
  title,
  value,
}: {
  title: ReactNode;
  value: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-2 t-w-full">
      <div className="t-text-body t-text-text-30">{title}</div>
      <div className="t-text-title-h2-bold t-text-text-60">{value}</div>
    </div>
  );
};

const Divider = () => {
  return (
    <div className="t-h-6 t-border-l t-border-r-0 t-border-solid t-border-neutral-10" />
  );
};

const MetricsReport = ({ data }: { data: Metrics }) => {
  const profitLoss = Number(data.total_revenue) - Number(data.gross_burn);
  const isProfit = profitLoss > 0;

  return (
    <div className="t-flex t-gap-6 t-justify-between t-items-center">
      <div className="t-flex t-gap-6 t-justify-between t-items-center t-w-full">
        <MetricsItem
          title="Revenue"
          value={
            <HideableAmount>
              {formatAmount(Number(data.total_revenue))}
            </HideableAmount>
          }
        />
        <Divider />
      </div>
      <div className="t-flex t-gap-6 t-justify-between t-items-center t-w-full">
        <MetricsItem
          title="Expense"
          value={
            <HideableAmount>
              {formatAmount(Number(data.gross_burn))}
            </HideableAmount>
          }
        />
        <Divider />
      </div>
      <MetricsItem
        title={isProfit ? "Profit" : "Loss"}
        value={
          <HideableAmount>{formatAmount(Number(profitLoss))}</HideableAmount>
        }
      />
    </div>
  );
};

export const CashFlow = ({
  categorisationType,
}: {
  categorisationType: keyof typeof CATEGORISATION_TYPE;
}) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { values, updateFilter } = useFilters<FilterType>({
    initialValue: {
      PERIOD: DATE_PERIOD.LAST_6_MONTHS,
    },
  });

  const { endDate, startDate } = getDateRange(values.PERIOD);
  const value = monthPeriod.find((item) => item.value === values.PERIOD);

  const { data = [], ...revenueMetricsApiStates } = useGetRevenueMetricsQuery(
    {
      groupId,
      entityId,
      start_date: dayjs(startDate).format(YYYY_MM_DD),
      end_date: dayjs(endDate).format(YYYY_MM_DD),
      categorisation_type: categorisationType,
    },
    {
      skip:
        !groupId || !entityId || !categorisationType || !startDate || !endDate,
    }
  );

  const { data: metrics, ...metricsApiStates } = useGetMetricsQuery(
    {
      entityId,
      burn_rate_start_date: dayjs(startDate).format(YYYY_MM_DD),
      burn_rate_end_date: dayjs(endDate).format(YYYY_MM_DD),
      categorisation_type: categorisationType,
    },
    {
      skip: !entityId || !categorisationType || !startDate || !endDate,
    }
  );

  const isFetching =
    revenueMetricsApiStates.isFetching || metricsApiStates.isFetching;
  const isSuccess =
    revenueMetricsApiStates.isSuccess && metricsApiStates.isSuccess;
  const isError = revenueMetricsApiStates.isError || metricsApiStates.isError;

  return (
    <Card.Root borderRounded="lg">
      <HomePageCardHeader>
        <HomePageCardTitle>Cash Flow</HomePageCardTitle>
        <Combobox
          isSearchable={false}
          onChange={(
            value: SingleValue<OptionData> | MultiValue<OptionData>
          ) => {
            if (value instanceof Array) {
              return;
            }

            const range = (value as SingleValue<OptionData>)?.value!;

            if (range) {
              updateFilter("PERIOD", range as DateRangeValue);
            }
          }}
          size="small"
          menuPortalTarget={document.body}
          options={cashFlowPeriod}
          isClearable={false}
          defaultValue={value}
        />
      </HomePageCardHeader>
      <Card.Body className="t-flex t-flex-col t-gap-3">
        <Async.Root
          isLoading={isFetching}
          isSuccess={isSuccess}
          isEmpty={data.length === 0}
          isError={isError}
          customLoader={
            <div className="t-flex t-justify-center t-items-center t-h-40">
              <Loader customType="secondary" />
            </div>
          }
        >
          <Async.Empty>
            <>No data found!</>
          </Async.Empty>
          <Async.ErrorHandler>
            <div className="t-flex t-justify-center t-items-center t-h-40 t-text-red-50">
              Something went wrong!
            </div>
          </Async.ErrorHandler>
          <Async.Success>
            {metrics && <MetricsReport data={metrics} />}
            <div className="t-w-full t-border-t-[1px] t-border-b-0 t-border-solid t-border-neutral-10" />
            <div className="t-flex t-gap-4 t-items-center t-mb-4">
              <Legend
                dot={<LegendDot color={REVENUE_BAR_COLOR} />}
                lable="Revenue"
              />
              <Legend
                dot={<LegendDot color={EXPENSE_BAR_COLOR} />}
                lable="Expense"
              />
              <Legend
                dot={<LegendDot color={PROFIT_LOSS_LINE_COLOR} />}
                lable="Profit/Loss"
              />
            </div>
            <div className="t-h-[176px] t-w-full">
              <BarChart data={data} />
            </div>
          </Async.Success>
        </Async.Root>
      </Card.Body>
    </Card.Root>
  );
};
