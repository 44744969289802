import { KeyDownWrapper } from "components/DesignSystem/KeydownWrapper/KeydownWrapper";
import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { AccountCard } from "./ConnectBank";
import GreenTickWithCircle from "static/images/GreenTickWithCircle.svg";
import { pluralize } from "utils/pluralize";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";

export const BooksBankAccounts = () => {
  const { uuid: groupId } = useCurrentGroupContext();
  const entityIdOnSelect = useAppSelector(
    (state) => state.onboardingWidget.entityId
  );
  const entityId = useCurrentEntityId() || entityIdOnSelect!;

  const { data: ledger } = useGetEntityBanksQuery(
    {
      groupId: groupId!,
      entityId: entityId!,
    },
    { skip: !groupId || !entityId }
  );

  const { accounts = [] } = ledger || {};

  if (accounts.length === 0) {
    return null;
  }

  return (
    <Accordion.Root
      type="single"
      collapsible
      defaultValue="BANK_ACCOUNTS"
      className="t-flex t-gap-4 t-flex-col t-my-3"
    >
      <Accordion.Item value="BANK_ACCOUNTS">
        <Accordion.Trigger>
          <div className="t-flex t-items-center t-gap-2">
            {pluralize(accounts.length, "account", "accounts")} connected
            <img
              src={GreenTickWithCircle}
              alt="GreenTickWithCircle"
              className="-t-mt-1"
            />
          </div>
        </Accordion.Trigger>
        <Accordion.Content>
          <span>
            <KeyDownWrapper>
              <div className="t-mt-4 t-mb-3 t-flex t-flex-col t-gap-3">
                {accounts.map(({ bank_brand, account }) => (
                  <AccountCard
                    key={account.uuid}
                    mask={account.mask}
                    bankName={bank_brand.name}
                    accountName={account.nickname}
                    logo={bank_brand.logo}
                  />
                ))}
              </div>
            </KeyDownWrapper>
          </span>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion.Root>
  );
};
