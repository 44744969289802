import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { Form, Formik } from "formik";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useParams } from "react-router-dom";
import { MultiValue, SingleValue } from "react-select";
import GrayPencil from "static/images/GrayPencil.svg";
import { useGetAutofillQuery } from "store/apis/autofill";
import {
  useGetTaskQuery,
  useUpdateTaskFormDataMutation,
} from "store/apis/task";
import classNames from "classnames";
import { DirectorAutofill } from "types/Models/directorAutofill";
import { AddFilingDirectorModal } from "./AddFilingDirector";
import RedInfo from "static/images/RedFilledInfo.svg";
import { Cross } from "components/icons/Cross";
import { useFormikContext } from "formik";
import { SectionField } from "types/Models/Filing";
import { BackendError } from "types/utils/error";
import { DeleteFilingAutofill } from "./DeleteFilingAutofill";

type FilingDirectorsProps = {
  section: any;
  groupId: string;
  entityId: string;
  formData: any;
  keysToFill: string[];
  fields: SectionField[];
  field: SectionField;
  required?: boolean;
};

export const FilingAuthorizedSignatory = ({
  section,
  groupId,
  entityId,
  formData,
  keysToFill,
  fields,
  field,
  required,
}: FilingDirectorsProps) => {
  const { values: formValues } = useFormikContext();
  const directorAutoFillKey = "OFFICER";
  const { data: directorAutofills = [], isLoading } = useGetAutofillQuery(
    {
      groupId,
      autofillKey: directorAutoFillKey,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const {
    isOpen: showAddDirectorModal,
    close: closeAddDirectorModal,
    open: openAddDirectorModal,
  } = useModal();
  const {
    isOpen: showDeleteModal,
    close: closeDeleteModal,
    open: openDeleteModal,
  } = useModal();

  const [updateTaskForm, { isLoading: isUpdatingTask }] =
    useUpdateTaskFormDataMutation();
  const { taskId } = useParams<{ taskId: string }>();
  const { data: task, isFetching: isTaskLoading } = useGetTaskQuery({ taskId });
  const { alertToast, successToast } = useToast();
  const [directorIdForEdit, setDirectorIdForEdit] = useState("");
  let shouldShowDetailsCard =
    section.is_address_selected ||
    fields.find((field) => field.field_key === "a_s_legal_name")?.value;
  let isAnyDetailsMissing =
    section.is_address_data_missing ||
    fields.find((field) => field.field_key === "a_s_legal_name")?.value === "";

  const getUpdatedField = (
    fieldKey: string,
    field: SectionField,
    values: any,
    isDelete: boolean
  ) => {
    try {
      let keyToFill = keysToFill.find((key) => fieldKey?.includes(key));

      if (isDelete) {
        return { ...field, value: "" };
      }

      if (keyToFill === "legal_name") {
        return {
          ...field,
          value: values["first_name"] + " " + values["last_name"],
        };
      }

      if (keyToFill && keyToFill.includes("select_autofill")) {
        return { ...field, value: values.uuid };
      }

      if (keyToFill) {
        return { ...field, value: values[keyToFill] };
      }

      //@ts-ignore
      return { ...field, value: formValues?.[fieldKey] || field.value };
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  const updateAutofillSection = (updatedFieldsWithValue: {
    section_fields: {}[];
  }) => {
    return formData?.data?.data?.section_group?.[0].sections.map(
      (currentSection: { [x: string]: any | {}[]; section_key: string }) => {
        if (currentSection.section_key === section.section_key) {
          return updatedFieldsWithValue;
        }
        return {
          ...currentSection,
          section_fields: currentSection?.section_fields?.map(
            (sectionField: SectionField) => ({
              ...sectionField,
              value:
                //@ts-ignore
                formValues?.[sectionField.field_key] || sectionField?.value,
            })
          ),
        };
      }
    );
  };

  const getPayload = (updatedSections: any) => {
    const payload = {
      form_data: {
        ...formData,
        data: {
          ...formData.data.data,
          section_group: [{ sections: updatedSections }],
        },
      },
    };
    return payload;
  };

  const updateTaskSection = async (
    autofillValue: SingleValue<OptionData> | MultiValue<OptionData> = []
  ) => {
    try {
      const selectedAutofill = (autofillValue as SingleValue<OptionData>)
        ?.value;
      const valuesToSet = directorAutofills.find(
        ({ uuid }) => uuid === selectedAutofill
      );

      await updateTaskDetails({
        directorIdForEdit: valuesToSet?.uuid || "",
        editedDirectorDetails: {
          persona_autofill: valuesToSet?.persona_autofill || {},
          address: valuesToSet?.address || {},
          designation: valuesToSet?.designation || null,
        },
      });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const updateTaskDetails = async ({
    directorIdForEdit,
    editedDirectorDetails,
    isDelete = false,
  }: {
    directorIdForEdit: string;
    editedDirectorDetails: {
      persona_autofill: any;
      address: any;
      designation: string | null;
    };
    isDelete?: boolean;
  }) => {
    try {
      let updatedFieldWithValue = {
        ...section,
        selected_autofill_details: {
          address_details: editedDirectorDetails.address,
          persona_details: editedDirectorDetails.persona_autofill,
          uuid: directorIdForEdit,
          designation: editedDirectorDetails.designation,
        },
        section_fields: section.section_fields?.map((field: SectionField) => {
          return getUpdatedField(
            field.field_key,
            field,
            {
              ...(editedDirectorDetails.address || {}),
              ...(editedDirectorDetails.persona_autofill || {}),
            },
            isDelete
          );
        }),
      };

      const updatedSections = updateAutofillSection(updatedFieldWithValue);
      const payload = getPayload(updatedSections);

      await updateTaskForm({
        formId: formData.uuid,
        payload,
        taskId,
      }).unwrap();
      successToast({ message: "Task updated successfully!" });
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const openEditDirectorModal = (directorId: string) => {
    setDirectorIdForEdit(directorId);
    openAddDirectorModal();
  };

  const onAddDirectorClose = () => {
    closeAddDirectorModal();
    setDirectorIdForEdit("");
  };

  const handleDelete = async () => {
    try {
      await updateTaskDetails({
        directorIdForEdit: "",
        editedDirectorDetails: {
          persona_autofill: {},
          address: {},
          designation: null,
        },
        isDelete: true,
      });
      closeDeleteModal();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
      closeDeleteModal();
    }
  };

  const defaultSelectedDirector = {
    label: (
      <div className="t-text-body t-w-full t-truncate">
        {section.selected_autofill_details?.persona_details?.first_name || ""}{" "}
        {section.selected_autofill_details?.persona_details?.last_name || ""}
        {section?.selected_autofill_details?.designation && (
          <span> ({section?.selected_autofill_details?.designation})</span>
        )}
      </div>
    ),
    value: section.selected_autofill_details?.uuid,
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <div>
        <Combobox
          actions={
            <Button
              type="button"
              customType="link"
              onClick={openAddDirectorModal}
            >
              Add new officer
            </Button>
          }
          components={{ ClearIndicator: () => null }}
          menuPortalTarget={document.body}
          onChange={updateTaskSection}
          value={defaultSelectedDirector}
          withForm
          name={field.field_key || "merchants"}
          label="Select Authorized Signatory"
          options={directorAutofills.map((autofill) => {
            return {
              label: (
                <div className="t-text-body t-w-full t-truncate">
                  {autofill.persona_autofill?.first_name || ""}{" "}
                  {autofill.persona_autofill?.last_name || ""}
                </div>
              ),
              value: autofill.uuid,
            };
          })}
          isLoading={isUpdatingTask}
          tooltipText={field.tooltip}
          required={required}
        />

        {shouldShowDetailsCard && !isUpdatingTask && !isTaskLoading && (
          <div className="t-mb-3 t-mt-6">
            <div
              className={classNames(
                "t-border t-border-solid t-border-neutral-0 t-p-4 t-rounded-lg",
                {
                  "t-border-red-30": isAnyDetailsMissing,
                }
              )}
            >
              <div className="t-flex t-justify-between">
                <div className="t-text-body">
                  {(section?.selected_autofill_details?.persona_details
                    ?.first_name || "") +
                    " " +
                    (section?.selected_autofill_details?.persona_details
                      ?.last_name || "")}
                  {section?.selected_autofill_details?.designation && (
                    <span>
                      {" "}
                      ({section?.selected_autofill_details?.designation})
                    </span>
                  )}
                </div>
                <div className="t-flex t-gap-2">
                  <Button
                    onClick={() =>
                      openEditDirectorModal(
                        section.selected_autofill_details.uuid
                      )
                    }
                    customType="transparent"
                    size="small"
                    type="button"
                  >
                    <img
                      src={GrayPencil}
                      className="t-h-4 t-m-1"
                      alt="GrayPencil"
                    />
                  </Button>
                  {section.is_removable && (
                    <Button
                      onClick={openDeleteModal}
                      customType="transparent"
                      size="small"
                      type="button"
                    >
                      <span className="t-text-text-30">
                        <Cross />
                      </span>
                    </Button>
                  )}
                </div>
              </div>
              <div className="t-text-body-sm t-text-text-30 t-w-4/5">
                {
                  section?.selected_autofill_details?.address_details
                    ?.autofill_string
                }
              </div>
            </div>

            {isAnyDetailsMissing && !isUpdatingTask && !isTaskLoading && (
              <div className="t-text-red t-text-body-sm t-mt-1 t-flex t-items-center t-gap-1">
                <img src={RedInfo} alt="RedInfo" />
                <div>
                  Please update missing information. Edit to complete the
                  required fields
                </div>
              </div>
            )}
          </div>
        )}
      </div>
      <DeleteFilingAutofill
        isOpen={showDeleteModal}
        close={closeDeleteModal}
        handleDeleteBank={handleDelete}
        isLoading={isUpdatingTask}
        title="Delete Authorized Signatory"
      />
      {showAddDirectorModal && (
        <AddFilingDirectorModal
          open={showAddDirectorModal}
          onClose={onAddDirectorClose}
          groupId={groupId}
          entityId={entityId}
          directorUuid={directorIdForEdit}
          updateTaskDirector={(editedDirectorDetails: {
            persona_autofill: any;
            address: any;
            designation: string | null;
          }) => {
            updateTaskDetails({ directorIdForEdit, editedDirectorDetails });
            onAddDirectorClose();
          }}
          isLoading={isUpdatingTask}
          onDirectorCreate={(director: DirectorAutofill) => {
            updateTaskDetails({
              directorIdForEdit: director.uuid,
              editedDirectorDetails: director,
            });
          }}
          title={
            directorIdForEdit
              ? "Edit Authorized Signatory"
              : "Add New Authorized Signatory"
          }
          autofillKey={directorAutoFillKey}
        />
      )}
    </>
  );
};
