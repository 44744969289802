import Toast, { ToastSizeType } from "components/DesignSystem/Toast/Toast";
import { ReactNode } from "react";
import { FileIcon } from "utils/fileTypeIcon";
import { FakeProgress } from "./UploadFileSection";

export const LoadingToast = ({
  children,
  title,
  size = "regular",
  loading,
}: {
  loading: boolean;
  title?: string;
  children: ReactNode;
  size?: ToastSizeType;
}) => {
  return (
    <div className="t-fixed t-right-0 t-bottom-0 t-z-toast">
      <Toast.Provider duration={20000}>
        <Toast.Root open={Boolean(loading)} customType="loading" size={size}>
          <Toast.Title>{title}</Toast.Title>
          <Toast.Description>{children}</Toast.Description>
        </Toast.Root>
      </Toast.Provider>
    </div>
  );
};

export const FileUploadingToast = ({
  title,
  size = "regular",
  isSuccess,
  file,
  onCancel,
  close,
  open,
}: {
  isSuccess: boolean;
  title?: string;
  size?: ToastSizeType;
  open: boolean;
  close: () => void;
  file: {
    name: string;
    type: string;
  };
  onCancel?: () => void;
}) => {
  return (
    <div className="t-fixed t-right-0 t-bottom-0 t-z-toast">
      <Toast.Provider duration={20000}>
        <Toast.Root
          className="!t-min-w-[392px]"
          icon={<FileIcon fileType={file.type} width="24" height="24" />}
          open={open}
          onOpenChange={close}
          customType="default"
          size={size}
        >
          <Toast.Title>
            <div>
              {title}
              <div className="t-text-body-sm t-text-text-60">{file.name}</div>
            </div>
          </Toast.Title>
          <Toast.Description>
            <FakeProgress isLoading={open} isSuccess={isSuccess} />
          </Toast.Description>
          {onCancel && !isSuccess && (
            <Toast.Action onAction={onCancel} altText="Cancel">
              Cancel
            </Toast.Action>
          )}
          <Toast.Close onClose={close} />
        </Toast.Root>
      </Toast.Provider>
    </div>
  );
};
