import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { BANK_ACCOUNT_STATUS } from "constants/dataSource";
import { Field, FieldProps, Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import { useEditBankAccountMutation } from "store/apis/bankConnections";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import * as Yup from "yup";

type FormValues = {
  account_closing_date: string;
};

type MarkAccountCloseModalProps = {
  entityId: string;
  groupId: string;
  bankAccountId: string;
  bankId: string;
} & ModalProps;

export const MarkAccountCloseModal = ({
  close,
  entityId,
  groupId,
  bankAccountId,
  bankId,
}: MarkAccountCloseModalProps) => {
  const { alertToast, successToast } = useToast();
  const [editBankAccount] = useEditBankAccountMutation();

  const onSubmit = async (values: FormValues) => {
    try {
      await editBankAccount({
        bankAccountId,
        bankId,
        groupId,
        entityId,
        payload: {
          closed_on: values.account_closing_date,
          account_status: BANK_ACCOUNT_STATUS.CLOSED,
        },
      }).unwrap();
      successToast({
        message: "Account has been marked as closed",
      });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError).data?.error?.message,
      });
    }
  };

  return (
    <Formik<FormValues>
      initialValues={{
        account_closing_date: "",
      }}
      validateOnChange
      validationSchema={Yup.object().shape({
        account_closing_date: Yup.string().required(
          "Account closing date is required"
        ),
      })}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, submitForm }) => (
        <>
          <Modal.Header>
            <Modal.Title>Mark account as closed</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Field name="account_closing_date">
                {({ field }: FieldProps) => {
                  return (
                    <DateInput
                      portalId="mark-account-close-modal"
                      {...field}
                      required
                      label="Account closed on"
                      placeholder="Select date"
                      block
                    />
                  );
                }}
              </Field>
            </Form>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button disabled={isSubmitting}>Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="danger"
              isLoading={isSubmitting}
              disabled={isSubmitting}
              onClick={submitForm}
            >
              Confirm
            </Button>
          </Modal.FooterButtonGroup>
        </>
      )}
    </Formik>
  );
};
