import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik, FormikValues } from "formik";
import { csvColumnMappingSchema } from "formValidations/csvColumnMappingSchema";
import { reconciliationCSVColumnsSchema } from "formValidations/reconciliationCSVColumnsSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useHistory, useLocation } from "react-router-dom";
import { useStoreCSVtransactionsMutation } from "store/apis/reconciliation";
import { CSVParsedResponse } from "types/Models/reconciliation";

export const CsvColumnsMap = ({
  isOpen,
  onClose,
  uploadedCsvData,
}: {
  isOpen: boolean;
  onClose: () => void;
  uploadedCsvData?: CSVParsedResponse;
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();

  const {
    labels_in_csv,
    csv,
    reconcilation_state_id = "",
    entity_transaction_labels,
  } = uploadedCsvData || {};

  const isSVBBank = entity_transaction_labels?.some(
    (value) =>
      value.toLowerCase().includes("credit") ||
      value.toLowerCase().includes("debit")
  )!;

  const { uuid: bank_csv_id = "" } = csv || {};

  const options = labels_in_csv?.map((col) => ({
    value: col,
    label: col,
  }));

  const [storeCSVtransactions] = useStoreCSVtransactionsMutation();
  const { alertToast, successToast } = useToast();

  const onSubmit = async (values: FormikValues) => {
    const {
      date_field,
      merchant_field,
      description_field,
      amount_field,
      credit_field,
      debit_field,
    } = values;

    try {
      const data = await storeCSVtransactions({
        groupId,
        entityId,
        date_field,
        description_field,
        merchant_field,
        amount_field,
        credit_field,
        debit_field,
        bank_csv_id,
        reconcilation_state_id,
      }).unwrap();
      successToast({ message: data.message });
      history.push(`/books/reconciliation/${reconcilation_state_id}/${search}`);
      onClose();
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={onClose}>
      <Formik
        initialValues={{
          date_field: "",
          merchant_field: "",
          description_field: "",
          amount_field: "",
          credit_field: "",
          debit_field: "",
          isSVBBank,
        }}
        onSubmit={onSubmit}
        enableReinitialize
        validationSchema={csvColumnMappingSchema}
        validateOnChange={true}
        validateOnMount={true}
      >
        {({ isSubmitting, submitForm }) => (
          <Form className="t-m-0 t-w-full">
            <Modal.Content useCustomOverlay>
              <Modal.Header>
                <div className="t-w-full">
                  <Modal.Title>Start Reconciliation</Modal.Title>
                  <Modal.Subtitle>Map columns from the CSV file</Modal.Subtitle>
                </div>
                <Modal.Close type="button" />
              </Modal.Header>
              <Modal.Body className="t-flex t-gap-3 t-flex-col t-w-full">
                <Combobox
                  label="Date"
                  required
                  name="date_field"
                  withForm
                  placeholder="Select property"
                  menuPortalTarget={document.getElementById("root")}
                  options={options}
                  block
                />
                <Combobox
                  label="Merchant"
                  menuPortalTarget={document.getElementById("root")}
                  name="merchant_field"
                  withForm
                  placeholder="Select property"
                  options={options}
                  block
                />
                <Combobox
                  required
                  label="Description"
                  menuPortalTarget={document.getElementById("root")}
                  name="description_field"
                  withForm
                  placeholder="Select property"
                  options={options}
                  block
                />
                {!isSVBBank ? (
                  <Combobox
                    required
                    label="Amount"
                    menuPortalTarget={document.getElementById("root")}
                    name="amount_field"
                    withForm
                    placeholder="Select property"
                    options={options}
                    block
                  />
                ) : (
                  <>
                    <Combobox
                      required
                      label="Credit"
                      menuPortalTarget={document.getElementById("root")}
                      name="credit_field"
                      withForm
                      placeholder="Select property"
                      options={options}
                      block
                    />
                    <Combobox
                      required
                      label="Debit"
                      menuPortalTarget={document.getElementById("root")}
                      name="debit_field"
                      withForm
                      placeholder="Select property"
                      options={options}
                      block
                    />
                  </>
                )}
              </Modal.Body>
              <Modal.Footer className="t-flex t-items-center t-justify-end t-gap-3">
                <Button type="reset" onClick={onClose} disabled={isSubmitting}>
                  Cancel
                </Button>
                <Button
                  customType="primary"
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                  onClick={submitForm}
                >
                  Next
                </Button>
              </Modal.Footer>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
