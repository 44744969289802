import { AddressComponents } from "components/AddressComponent/AddressComponent";
import { AddressFormFields } from "components/AddressFormFields/AddressFormFields";
import { BlurFade } from "components/design/BlurFade";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Divider } from "components/design/Divider";
import { TableUI } from "components/design/TableUI";
import { AvatarUpload } from "components/DesignSystem/AvatarGroup/AvatarUpload";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import { Info } from "components/icons/Info";
import { PlusIcon } from "components/icons/PlusIcon";
import { PriceInput } from "components/PriceInput/PriceInput";
import { YYYY } from "constants/date";
import { USER_PROFILE } from "constants/userTypes";
import dayjs from "dayjs";
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  useFormikContext,
} from "formik";
import { vendorAddSchema } from "formValidations/vendorSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import randomBytes from "randombytes";
import { ReactNode } from "react";
import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import { useAddVendorsMutation } from "store/apis/vendors";
import { AmountPerSeason } from "types/Models/vendors";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

type SeasonItem = {
  id: string;
} & AmountPerSeason;

type VendorSchema = {
  name: string;
  email?: string;
  logo?: File;
  is_add_billing_address: boolean;
  address_obj: {
    uuid: string;
    city: string;
    country: string;
    state: string;
    street_address: string;
    street_address_line_2: string;
    zipcode: string;
  };
  is_add_payment_instructions: boolean;
  payment_instructions: string;
  amounts_per_season: SeasonItem[];
  save_and_add_new?: boolean;
};

const BLUR = "0px";

export const InfoToolTip = ({ info }: { info: ReactNode }) => {
  return (
    <ConditionalToolTip condition={info}>
      <span className="t-text-text-30">
        <Info stroke="1" />
      </span>
    </ConditionalToolTip>
  );
};
const createColumn = createColumnHelper<SeasonItem>();

const DeleteSeasonItem = ({ id }: { id: string }) => {
  const { values, setFieldValue } = useFormikContext<VendorSchema>();
  const { amounts_per_season } = values;
  const isOnlyOne = amounts_per_season.length === 1;

  const onDelete = () => {
    const newSeason = amounts_per_season.filter((item) => item.id !== id);
    setFieldValue("amounts_per_season", newSeason);
  };

  return (
    <Button
      disabled={isOnlyOne}
      type="button"
      customType="ghost_icon"
      onClick={onDelete}
    >
      <span className="t-text-text-30">
        <DeleteIcon />
      </span>
    </Button>
  );
};

const columns = [
  createColumn.accessor("season", {
    header: () => <Label>Season</Label>,
    size: 45,
    cell: ({ row: { index } }) => (
      <Field name={`amounts_per_season[${index}].season`}>
        {({ field }: FieldProps) => (
          <DateInput
            {...field}
            name={field.name}
            showYearPicker
            showYearDropdown
            dateFormat="yyyy"
            hideError
            placeholder="YYYY"
          />
        )}
      </Field>
    ),
  }),

  createColumn.accessor("total_amount", {
    header: () => (
      <div className="t-justify-end t-w-full t-flex">
        <Label>1099 AMOUNT</Label>
      </div>
    ),
    size: 45,
    cell: ({ row: { index } }) => (
      <PriceInput
        rightAlign
        name={`amounts_per_season[${index}].total_amount`}
        placeholder="Enter amount"
      />
    ),
  }),

  createColumn.accessor("id", {
    header: "",
    size: 10,
    cell: ({
      row: {
        original: { id },
      },
    }) => <DeleteSeasonItem id={id} />,
  }),
];

const BillingAddress = () => {
  const { values, setFieldValue } = useFormikContext<VendorSchema>();
  const { is_add_billing_address } = values;
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  if (is_add_billing_address) {
    return (
      <BlurFade
        blur={BLUR}
        duration={0.2}
        key="billing-address"
        className="t-flex t-flex-col t-gap-4"
      >
        <div className="t-flex t-justify-between t-items-center t-w-full">
          <span className="t-text-text-100 t-text-subtext">
            Billing Address{" "}
            <InfoToolTip
              info={
                <>
                  Optional: Add vendor's billing address to <br />
                  seamlessly add it to bills, and track tax <br />
                  compliances.
                </>
              }
            />
          </span>

          <Button
            type="button"
            customType="ghost_icon"
            size="small"
            onClick={() => setFieldValue("is_add_billing_address", false)}
          >
            <span className="t-text-text-30">
              <DeleteIcon />
            </span>
          </Button>
        </div>
        <div className="t-grid t-gap-4">
          <AddressComponents
            formPrefix=""
            entityId={entityId}
            groupId={groupId}
            selectedAddressId={values.address_obj.uuid}
            label="Address"
            onAddressSelect={(address) => setFieldValue("address_obj", address)}
            hideClear
          />
        </div>
      </BlurFade>
    );
  }

  return (
    <BlurFade blur={BLUR} duration={0.2} key="add-billing-address">
      <Button
        type="button"
        size="small"
        customType="ghost"
        onClick={() => setFieldValue("is_add_billing_address", true)}
      >
        <div className="t-flex t-gap-1.5 t-items-center">
          <span className="t-text-text-30">
            <PlusIcon />
          </span>
          Add Billing Address
          <InfoToolTip
            info={
              <>
                Optional: Add vendor's billing address to <br />
                seamlessly add it to bills, and track tax <br />
                compliances.
              </>
            }
          />
        </div>
      </Button>
    </BlurFade>
  );
};

const PaymentInstructions = () => {
  const { values, setFieldValue } = useFormikContext<VendorSchema>();
  const { is_add_payment_instructions } = values;

  if (is_add_payment_instructions) {
    return (
      <BlurFade
        blur={BLUR}
        duration={0.2}
        key="payment-instructions"
        className="t-flex t-flex-col t-gap-3"
      >
        <div className="t-flex t-justify-between t-items-center t-w-full t-gap-4">
          <span className="t-text-text-100 t-text-subtext">
            Payment Instructions{" "}
            <InfoToolTip
              info={
                <>
                  Optional: Add payment guidelines or details <br />
                  provided by the vendor.
                </>
              }
            />
          </span>

          <Button
            type="button"
            customType="ghost_icon"
            size="small"
            onClick={() => setFieldValue("is_add_payment_instructions", false)}
          >
            <span className="t-text-text-30">
              <DeleteIcon />
            </span>
          </Button>
        </div>
        <TextArea
          name="payment_instructions"
          placeholder="Enter Payment Details"
        />
      </BlurFade>
    );
  }

  return (
    <BlurFade blur={BLUR} duration={0.2} key="add-payment-instructions">
      <Button
        type="button"
        size="small"
        customType="ghost"
        onClick={() => setFieldValue("is_add_payment_instructions", true)}
      >
        <div className="t-flex t-gap-1.5 t-items-center">
          <span className="t-text-text-30">
            <PlusIcon />
          </span>
          Add Payment Instructions
          <InfoToolTip
            info={
              <>
                Optional: Add payment guidelines or details <br />
                provided by the vendor.
              </>
            }
          />
        </div>
      </Button>
    </BlurFade>
  );
};

const AmountsPerSeason = () => {
  const { values, setFieldValue } = useFormikContext<VendorSchema>();
  const { amounts_per_season } = values;

  const table = useReactTable({
    data: amounts_per_season,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const onAddMore = () => {
    setFieldValue("amounts_per_season", [
      ...amounts_per_season,
      { season: "", total_amount: "", id: randomBytes(10).toString("hex") },
    ]);
  };

  return (
    <div className="t-flex t-gap-1.5 t-flex-col ">
      <span className="t-text-text-100 t-text-subtext">
        1099 Amounts per Season{" "}
        <InfoToolTip
          info={
            <>
              Sum of the payments made during the season
              <br />
              that qualify to be reported on IRS Form 1099.
            </>
          }
        />
      </span>
      <TableUI table={table} />

      <div className="t-mr-auto">
        <Button
          type="button"
          size="small"
          customType="ghost"
          onClick={onAddMore}
        >
          <span className="t-text-text-30 t-mr-1.5">
            <PlusIcon />
          </span>
          Add Another Payment
        </Button>
      </div>
    </div>
  );
};

const AddVendorForm = () => {
  const { values, setFieldValue } = useFormikContext<VendorSchema>();
  const { logo, name } = values;

  return (
    <Form className="t-flex t-flex-col t-gap-5">
      <div className="t-flex t-items-center t-gap-4">
        <AvatarUpload
          onDrop={(file) => setFieldValue("logo", file)}
          variant="primary"
          alt={name}
          size="extra-large"
          file={logo}
          src={logo ? undefined : USER_PROFILE}
        />
        <TextInput
          required
          label="Name"
          name="name"
          placeholder="Enter vendor name"
        />
        <TextInput
          label="Email"
          name="email"
          placeholder="Enter vendor email"
        />
      </div>
      <Divider />
      <div className="t-flex t-flex-col t-gap-4">
        <BillingAddress />
        <Divider />
        <PaymentInstructions />
        <Divider />
        <AmountsPerSeason />
      </div>
    </Form>
  );
};

export const AddVendorModal = ({ isOpen, close, entityId }: ModalProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { alertToast, successToast } = useToast();
  const [addVendor] = useAddVendorsMutation();

  const onSubmit = async (
    values: VendorSchema,
    formikHelpers: FormikHelpers<VendorSchema>
  ) => {
    try {
      const amountPaidInSeason = values.amounts_per_season
        .filter(({ season, total_amount }) => season && total_amount)
        .map(({ season, total_amount }) => ({
          season: season ? dayjs(season).format(YYYY) : "",
          total_amount,
        }));

      const amount_paid_in_season =
        amountPaidInSeason.length > 0 ? amountPaidInSeason : undefined;

      await addVendor({
        entityId: entityId!,
        groupId,
        payload: {
          name: values.name,
          address_obj: values.is_add_billing_address
            ? values.address_obj
            : undefined,
          amount_paid_in_season,
          email: values.email,
          logo: values.logo,
          payment_instructions: values.payment_instructions,
        },
      }).unwrap();

      successToast({ message: "Vendor added successfully" });

      if (values.save_and_add_new) {
        formikHelpers.resetForm();
        return;
      }

      close();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Modal.Content size="large" useCustomOverlay>
        <Formik<VendorSchema>
          initialValues={{
            name: "",
            email: "",
            logo: undefined,
            is_add_billing_address: false,
            address_obj: {
              uuid: "",
              city: "",
              country: "",
              state: "",
              street_address: "",
              street_address_line_2: "",
              zipcode: "",
            },
            is_add_payment_instructions: false,
            payment_instructions: "",
            amounts_per_season: [
              {
                season: "",
                total_amount: "",
                id: randomBytes(10).toString("hex"),
              },
            ],
            save_and_add_new: false,
          }}
          validationSchema={vendorAddSchema}
          validateOnChange
          onSubmit={onSubmit}
        >
          {({
            submitForm,
            isSubmitting,
            setFieldValue,
            isValid,
            values: { save_and_add_new },
          }) => (
            <>
              <Modal.Header>
                <Modal.Title>Add Vendor Manually</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <AddVendorForm />
              </Modal.Body>
              <Modal.Footer className="t-flex t-justify-between">
                <Modal.RawClose asChild>
                  <Button disabled={isSubmitting} customType="ghost">
                    Cancel
                  </Button>
                </Modal.RawClose>
                <div className="t-flex t-gap-2">
                  <Button
                    disabled={(isSubmitting && save_and_add_new) || !isValid}
                    isLoading={isSubmitting && save_and_add_new}
                    onClick={() => {
                      setFieldValue("save_and_add_new", true);
                      submitForm();
                    }}
                  >
                    Save & Add new
                  </Button>
                  <Button
                    disabled={(isSubmitting && !save_and_add_new) || !isValid}
                    isLoading={isSubmitting && !save_and_add_new}
                    onClick={() => {
                      setFieldValue("save_and_add_new", false);
                      submitForm();
                    }}
                    customType="primary"
                  >
                    Save
                  </Button>
                </div>
              </Modal.Footer>
            </>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
