import Modal from "components/DesignSystem/Modal/Modal";
import { Form, Formik } from "formik";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { Button } from "components/DesignSystem/Button/Button";
import {
  useGetChatRoleListQuery,
  useUpdateChatRoleMutation,
} from "store/apis/teamSettingCPA";
import { TeamMember } from "store/apis/teamSettingCPA";

type EditRoleModalProps = {
  data: TeamMember;
  open: boolean;
  setShowEditRoleModal: (newState: React.SetStateAction<boolean>) => void;
};

export const EditRoleModal = ({
  data,
  open,
  setShowEditRoleModal,
}: EditRoleModalProps) => {
  const { data: roles = [] } = useGetChatRoleListQuery();
  const [updateChatRole, { isLoading }] = useUpdateChatRoleMutation();

  const onSubmit = async (values: { role: string }) => {
    await updateChatRole({
      memberId: data.uuid,
      payload: {
        chat_role: values.role,
      },
    }).unwrap();
    setShowEditRoleModal(false);
  };

  return (
    <Modal.Root open={open} onOpenChange={setShowEditRoleModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Edit Role</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          onSubmit={(values) => onSubmit(values)}
          initialValues={{ role: data.chat_role }}
        >
          {({ values }) => (
            <Form className="all:unset">
              <Modal.Body className="t-flex t-flex-col t-gap-4">
                <Combobox
                  label="Role"
                  name="role"
                  value={{
                    value: values?.role,
                    label: values?.role,
                  }}
                  options={roles.map((role) => ({
                    label: role.role_name,
                    value: role.role_name,
                  }))}
                  withForm
                  placeholder="Select role"
                  menuPortalTarget={document.body}
                  isClearable={values?.role?.length > 0}
                />
              </Modal.Body>
              <Modal.Footer className="t-flex t-gap-3 t-justify-end">
                <Button
                  type="button"
                  onClick={() => setShowEditRoleModal(false)}
                >
                  Cancel
                </Button>
                <Button
                  customType="primary"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Save Changes
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
