/* eslint-disable no-useless-escape */
import { Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

//style
import "react-phone-input-2/lib/style.css";
import "../../../static/styles/components/landingForm.css";

//utils
import { signUp } from "formValidations/signupSchema";

// components
import GoogleBtn from "../../../signInSignUp/googleBtn";

//hook
import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { LoadingIcon } from "components/icons/LoadingIcon";
import {
  SIGNUP_WITH_EMAIL,
  SIGNUP_WITH_GOOGLE,
} from "constants/analyticsEvents";
import { PHONE } from "constants/help";
import { PUBLIC_USER_EMAIL, TASK_SESSION_ID } from "constants/session";
import { useAnalytics } from "hooks/useAnalytics";
import { useLocalStorage } from "hooks/useLocalStorage";
import BlackArrowLeft from "static/images/BlackArrowLeft.svg";
import WhatsappIcon from "static/images/whatsappIcon.png";
import { useValidateReferralQuery } from "store/apis/refrral";
import { useQuery, useUpdateQuery } from "../../../hooks/useQuery";
import { InvitationContext } from "contexts/InvitationContext";
import { useGetCrmUserQuery } from "store/apis/onboarding";
import Loader from "components/design/loader";
import { useToast } from "hooks/useToast";

// constants
const clientId = process.env.PUBLIC_CURRENT_GOOGLE_CLIENT_ID;
const path = process.env.PUBLIC_REDIRECT_URL;

const SignUp = ({ updatePage, authLoading }) => {
  let query = useQuery();
  const { update } = useUpdateQuery();
  const { trackEvent } = useAnalytics();
  const [data, setData] = useState({
    first_name: "",
    last_name: "",
    email: "",
  });
  const history = useHistory();
  const couponCode = query.get("couponCode");
  const refCode = query.get("ref");
  const taskSessionId = query.get("session_id");
  const { localData: refData, setItem: setReferralCodeData } = useLocalStorage(
    "referralCode",
    null
  );
  const isInvited = useContext(InvitationContext);
  const { setItem } = useLocalStorage("referralCode", null);
  const referralCode = refCode || refData;

  const { hash } = useLocation();
  const crmAccessToken = hash?.split("#payload=")[1];

  const {
    data: crmInvitedUser,
    isLoading,
    isError,
    error: inviteError,
  } = useGetCrmUserQuery(
    { crmAccessToken },
    { skip: crmAccessToken === undefined || !isInvited }
  );

  const { alertToast } = useToast();

  useEffect(() => {
    if (isError) {
      alertToast({ message: inviteError?.data?.error?.message });
    }
  }, [isError]);

  const { data: referrerDetails, isSuccess } = useValidateReferralQuery(
    {
      referralCode,
    },
    { skip: !referralCode }
  );
  const [error, setError] = useState({
    first_name: false,
    last_name: false,
    email: false,
  });

  const userType = localStorage.getItem("user_type");

  useEffect(() => {
    if (taskSessionId) {
      localStorage.setItem(TASK_SESSION_ID, taskSessionId);
    }
  }, [taskSessionId]);

  useEffect(() => {
    document.title = "Sign Up | Inkle";
    if (couponCode) localStorage.setItem("couponCode", couponCode);
    if (referralCode && isSuccess) {
      setReferralCodeData(referralCode);
    }
  }, [couponCode, referralCode, isSuccess]);

  const send = (values) => {
    trackEvent(SIGNUP_WITH_EMAIL, {});
    const body = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      user_type: userType,
      uuid: crmInvitedUser?.uuid,
    };

    updatePage(body);
  };

  const getAccessToken = () => {
    trackEvent(SIGNUP_WITH_GOOGLE, {});
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?redirect_uri=${path}&prompt=consent&response_type=token&client_id=${clientId}&scope=openid%20email%20profile&flowName=GeneralOAuthFlow`;
  };

  const publicUserEmail = localStorage.getItem(PUBLIC_USER_EMAIL);

  useEffect(() => {
    setData((prev) => ({
      ...prev,
      email: crmInvitedUser?.email || publicUserEmail,
    }));
  }, [publicUserEmail, crmInvitedUser?.email]);

  const goBack = () => {
    history.push("/signup");
    update({ query: "signupform", value: null });
  };

  if (isInvited && (isLoading || !data.email)) {
    return (
      <div className="t-flex t-w-full t-h-full t-items-center t-flex-1 t-justify-center">
        <Loader />
      </div>
    );
  }

  return (
    <div className="t-flex t-w-3/5 t-flex-col sm:t-w-full ">
      <ConditionalLink to={`https://wa.me/${PHONE}`}>
        <button className="t-bg-dark_green-60 t-absolute t-bottom-8 t-right-8 t-z-50 t-flex t-h-12 t-w-12 t-items-center t-justify-center t-rounded-full t-border-0 t-bg-[transparent] t-shadow-hover-card-shadow t-drop-shadow-bubble">
          <img className="t-h-4/5" src={WhatsappIcon} alt="WhatsappIcon" />
        </button>
      </ConditionalLink>
      {/* {Boolean(referrerDetails) && (
        <ReferralBanner referrerDetails={referrerDetails} />
      )} */}

      <div className="rightBox landingForm signupContainer t-ml-16  !t-h-full !t-items-start t-justify-start  t-border t-border-solid t-border-surface-transparent t-border-l-neutral-10 t-pr-[20%] t-pt-0 sm:t-mt-16 sm:t-px-[10%]">
        <div className="authHeader t-relative t-mr-auto">
          <div className="accountHead t-text-title-h2-bold sm:t-hidden">
            {!isInvited && (
              <div
                className="backBtn t-absolute -t-ml-12  -t-mt-[7px] sm:-t-ml-8"
                onClick={goBack}
                aria-hidden="true"
              >
                <img src={BlackArrowLeft} alt="BlackArrowLeft" />
              </div>
            )}
            {isInvited ? "Enter name" : "Sign up"}
          </div>
          <div className="accountHead !t-hidden t-items-center t-justify-between sm:t-block sm:!t-flex">
            <div className="backBtn t-mr-2" onClick={goBack} aria-hidden="true">
              <img src={BlackArrowLeft} alt="BlackArrowLeft" />
            </div>
            <div className="t-mx-auto">
              {isInvited ? "Enter name" : "Sign up"}
            </div>
          </div>
        </div>

        <Formik
          initialValues={data}
          validationSchema={signUp}
          onSubmit={send}
          validateOnBlur
          validateOnChange={false}
        >
          <Form className="t-m-0 t-mb-4 sm:!t-w-full">
            <div className="t-flex t-w-full t-gap-4">
              <TextInput
                placeholder="John"
                label="First name"
                name="first_name"
                customSize="large"
              />
              <TextInput
                placeholder="Smith"
                label="Last name"
                name="last_name"
                customSize="large"
              />
            </div>
            <div className="t-mt-7 t-mb-5 t-w-full">
              <TextInput
                placeholder="johnsmith@company.com"
                label="Work email id"
                name="email"
                customSize="large"
                block
                disabled={crmInvitedUser?.email}
              />
            </div>
            <div className="t-mt-8 t-w-full">
              {isInvited ? (
                <Button
                  customType="primary"
                  block
                  size="large"
                  type="submit"
                  isLoading={authLoading}
                  disabled={authLoading}
                >
                  Continue
                </Button>
              ) : (
                <Button
                  customType="primary"
                  block
                  size="large"
                  type="submit"
                  isLoading={authLoading}
                  disabled={authLoading}
                >
                  Sign up with email
                </Button>
              )}
            </div>
          </Form>
        </Formik>
        {!isInvited && (
          <>
            <div className="t-mt-3 t-mb-0 t-flex t-w-full t-items-center t-justify-between t-text-neutral">
              <div className="t-m-2.5 t-h-px t-w-2/5 t-bg-[color:var(--shade18Gray)]" />
              <span>or</span>
              <div className="t-m-2.5 t-h-px t-w-2/5 t-bg-[color:var(--shade18Gray)]" />
            </div>
            <GoogleBtn className="!t-ml-0" onClick={getAccessToken} />
          </>
        )}
      </div>
    </div>
  );
};

export default SignUp;
