import {
  DnDArea,
  File,
  UploadLabel,
} from "components/design/UploadFileSection";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { Stepper } from "components/DesignSystem/Stepper/Stepper";
import { ArrowRight } from "components/icons/ArrowRight";
import { DownloadIcon } from "components/icons/Download";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { bulkVendorAddSchema } from "formValidations/vendorSchema";
import { useAbortController } from "hooks/useAbortController";
import { useToast } from "hooks/useToast";
import randomBytes from "randombytes";
import { useState } from "react";
import {
  useBulkMerchantUploadMutation,
  useLazyGetParsedCSVQuery,
  useUploadMerchantCSVMutation,
} from "store/apis/vendors";
import { MerchantCSVResponse } from "types/Models/vendors";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { AddMerchantsTable } from "../AddMerchantsTable";

const ImportVendors = ({
  entityId,
  onSuccess,
}: {
  entityId: string;
  onSuccess: (data: MerchantCSVResponse[]) => void;
}) => {
  const { alertToast } = useToast();
  const [file, setFile] = useState<File | null>(null);
  const uploadController = useAbortController({
    onAbort: () => {
      alertToast({ message: "Upload cancelled" });
    },
  });

  const [uploadMerchantCSV, uploadState] = useUploadMerchantCSVMutation();
  const [parseCSV, parseCSVState] = useLazyGetParsedCSVQuery();

  const onDrop = async (files: File[]) => {
    if (!files.length) return;

    try {
      setFile(files[0]);
      await uploadMerchantCSV({
        csv: files[0],
        signal: uploadController.signal,
        entityId,
      }).unwrap();
    } catch (error) {
      setFile(null);
      if (
        (
          error as {
            status: string;
          }
        ).status === "FETCH_ERROR"
      ) {
        return;
      }

      alertToast(
        { message: (error as BackendError)?.data?.error?.message },
        error as Error
      );
    }
  };

  const onNext = async () => {
    if (!uploadState.data?.uuid) return null;

    try {
      const res = await parseCSV({
        entityId,
        csvId: uploadState.data?.uuid,
      }).unwrap();
      onSuccess(res);
    } catch (error) {
      alertToast(
        { message: (error as BackendError)?.data?.error?.message },
        error as Error
      );
    }
  };

  return (
    <>
      <Modal.Body className="t-flex t-flex-col t-gap-3">
        <UploadLabel>
          Download the pre-formatted template to ensure your data is properly
          structured. Add the bill details to the template and upload it.
        </UploadLabel>
        <span>
          <Button
            size="small"
            onClick={() =>
              window.open(
                "https://inkle-django-files.s3.ap-south-1.amazonaws.com/sample_vendor_upload_csv.csv"
              )
            }
          >
            <DownloadIcon />
            <span className="t-ml-1.5">Download Excel Template</span>
          </Button>
        </span>
        {file ? (
          <File
            file={file}
            onCancel={uploadController.abort}
            onDelete={() => setFile(null)}
            isUploading={uploadState.isLoading}
            isUploaded={uploadState.isSuccess}
          />
        ) : (
          <DnDArea
            onDrop={onDrop}
            accept={{ "text/csv": [".csv"] }}
            subText="File Format: .csv"
          />
        )}
      </Modal.Body>
      <Modal.FooterButtonGroup>
        <Modal.RawClose asChild>
          <Button disabled={parseCSVState.isFetching}>Cancel</Button>
        </Modal.RawClose>
        <Button
          disabled={parseCSVState.isFetching}
          isLoading={parseCSVState.isFetching}
          customType="primary"
          onClick={onNext}
        >
          Next
        </Button>
      </Modal.FooterButtonGroup>
    </>
  );
};

const ReviewVendors = ({
  parsedVendor,
  onSuccess,
  entityId,
}: {
  parsedVendor: MerchantCSVResponse[];
  onSuccess: () => void;
  entityId: string;
}) => {
  const { alertToast, successToast } = useToast();
  const parsedInitialValue: MerchantCSVResponse[] = parsedVendor.map((data) => {
    return {
      ...data,
      season: data.season ? dayjs(data.season).format(DD_MMM_YYYY) : "",
      id: randomBytes(10).toString("hex"),
    };
  });

  const [bulkUploadMerchant] = useBulkMerchantUploadMutation();

  const onSubmit = async (values: { merchants: MerchantCSVResponse[] }) => {
    const merchants = values.merchants.map(
      ({ email, name, total_amount, season }) => {
        return {
          season: season ? dayjs(season).format(DD_MMM_YYYY) : "",
          email,
          name,
          total_amount,
        };
      }
    );

    try {
      await bulkUploadMerchant({
        entityId,
        merchants: merchants,
      }).unwrap();

      successToast({ message: "New vendor has been added" });
      onSuccess();
    } catch (error) {
      alertToast({ message: (error as BackendError)?.data?.error?.message });
    }
  };

  return (
    <Formik
      initialValues={{ merchants: parsedInitialValue }}
      onSubmit={onSubmit}
      validateOnChange
      validationSchema={bulkVendorAddSchema}
    >
      {({ submitForm, isSubmitting }) => (
        <>
          <Modal.Body>
            <Form>
              <AddMerchantsTable />
            </Form>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button disabled={isSubmitting}>Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="primary"
              disabled={isSubmitting}
              isLoading={isSubmitting}
              onClick={submitForm}
            >
              Add vendors
            </Button>
          </Modal.FooterButtonGroup>
        </>
      )}
    </Formik>
  );
};

export const BulkAddVendor = ({ close, isOpen, entityId }: ModalProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [parsedVendor, setParsedVendor] = useState<MerchantCSVResponse[]>([]);

  const breadcrumbs = [
    { name: "Import vendors", step: 1 },
    { name: "Review vendors", step: 2 },
  ];

  const onClose = () => {
    close();
    setCurrentStep(1);
    setParsedVendor([]);
  };

  if (!entityId) {
    return null;
  }

  return (
    <Modal.Root open={isOpen} modal={false} onOpenChange={onClose}>
      <Modal.Content size={currentStep === 1 ? "large" : "xl"} useCustomOverlay>
        <Modal.Header>
          <div>
            <Modal.Title>Import Vendors</Modal.Title>
            <Modal.Subtitle>
              <Stepper size="xs" direction="horizontal">
                {breadcrumbs?.map(({ name, step }) => (
                  <Stepper.Step
                    step={step}
                    key={step}
                    isActive={currentStep === step}
                    clickable={step === 1}
                    onClick={() => setCurrentStep(step)}
                  >
                    <div className="t-flex t-items-center">
                      {name}
                      {step === 1 && <ArrowRight color="currentColor" />}
                    </div>
                  </Stepper.Step>
                ))}
              </Stepper>
            </Modal.Subtitle>
          </div>
          <Modal.Close />
        </Modal.Header>
        {currentStep === 1 && (
          <ImportVendors
            entityId={entityId}
            onSuccess={(data) => {
              setParsedVendor(data);
              setCurrentStep(2);
            }}
          />
        )}
        {currentStep === 2 && (
          <ReviewVendors
            entityId={entityId}
            onSuccess={onClose}
            parsedVendor={parsedVendor}
          />
        )}
      </Modal.Content>
    </Modal.Root>
  );
};
