import { Chip } from "components/DesignSystem/Chips/Chips";
import { Filter } from "components/DesignSystem/Filter/Filter";
import Radio from "components/DesignSystem/RadioGroup/RadioGroup";
import {
  SOURCE,
  VENDOR_SEASON_OPTION,
  VENDOR_SOURCE_OPTION,
  VENDOR_TYPE,
  VENDOR_WFORM_OPTION,
} from "constants/vendor";
import { FilterProps } from "pages/Books/BillsAndVendors/Vendors";

const SeasonFilter = ({ values, updateFilter }: FilterProps) => {
  return (
    <div className="t-flex t-flex-col t-gap-3">
      <div className="t-text-body-sm t-text-neutral-80 t-w-full">Season</div>
      <Radio.Root
        onValueChange={(value) => {
          updateFilter("SEASON", value);
        }}
        value={values.SEASON || ""}
      >
        <Radio.Content>
          {VENDOR_SEASON_OPTION.map((season: string) => (
            <Radio.Item asChild value={season} key={season}>
              {season}
            </Radio.Item>
          ))}
        </Radio.Content>
      </Radio.Root>
    </div>
  );
};

export const WFormFilters = ({ values, updateFilter }: FilterProps) => {
  return (
    <div className="t-flex t-flex-col t-gap-3">
      <div className="t-text-body-sm t-text-neutral-80 t-w-full">W-Form</div>
      <Radio.Root
        onValueChange={(v) => {
          updateFilter("VENDOR_TYPE", v as keyof typeof VENDOR_TYPE);
        }}
        value={values.VENDOR_TYPE || ""}
      >
        <Radio.Content>
          {VENDOR_WFORM_OPTION.map(({ label, value }) => (
            <Radio.Item asChild value={value} key={value}>
              {label}
            </Radio.Item>
          ))}
        </Radio.Content>
      </Radio.Root>
      <button
        className="all:unset hover:t-underline t-text-purple t-text-subtext"
        onClick={() => updateFilter("VENDOR_TYPE", null)}
      >
        Reset
      </button>
    </div>
  );
};

const Source = ({ values, updateFilter }: FilterProps) => {
  return (
    <div className="t-flex t-flex-col t-gap-3">
      <div className="t-text-body-sm t-text-neutral-80 t-w-full">Source</div>
      <Radio.Root
        onValueChange={(v) => {
          updateFilter("SOURCE", v as keyof typeof SOURCE);
        }}
        value={values.SOURCE || ""}
      >
        <Radio.Content>
          {VENDOR_SOURCE_OPTION.map(({ label, value }) => (
            <Radio.Item asChild value={value} key={value}>
              {label}
            </Radio.Item>
          ))}
        </Radio.Content>
      </Radio.Root>
      <button
        className="all:unset hover:t-underline t-text-purple t-text-subtext"
        onClick={() => updateFilter("SOURCE", null)}
      >
        Reset
      </button>
    </div>
  );
};

const AppliedFilters = ({ values, updateFilter }: FilterProps) => {
  const isShowEstimated1099 = values.SHOW_ESTIMATED_1099;

  return (
    <>
      {values.SEASON && (
        <Chip
          isActive
          filterType="SEASON"
          isRemovable={!values.SHOW_ESTIMATED_1099}
          onClose={() => updateFilter("SEASON", null)}
        >
          Season {values.SEASON}
        </Chip>
      )}
      {values.SOURCE && (
        <Chip
          filterType="SOURCE"
          isActive
          onClose={() => updateFilter("SOURCE", null)}
        >
          <div className="first-letter:t-uppercase t-lowercase">
            {values.SOURCE}
          </div>
        </Chip>
      )}
      {isShowEstimated1099 && (
        <Chip
          isFixedFilter
          filterType="W-FORM"
          isActive={Boolean(values.VENDOR_TYPE)}
          onClose={() => updateFilter("VENDOR_TYPE", null)}
          onFixedFilterClick={() =>
            updateFilter("VENDOR_TYPE", VENDOR_TYPE.VENDORS)
          }
        >
          W-Form{" "}
          <div className="t-lowercase">
            {VENDOR_WFORM_OPTION.find(
              ({ value }) => value === values.VENDOR_TYPE
            )?.label || "required"}
          </div>
        </Chip>
      )}
    </>
  );
};

export const VendorsFilter = ({ values, updateFilter }: FilterProps) => {
  const isShowEstimated1099 = values.SHOW_ESTIMATED_1099;

  return (
    <Filter.Root
      defaultValue="SEASON"
      capsule={<AppliedFilters updateFilter={updateFilter} values={values} />}
    >
      <Filter.Portal size="small">
        <Filter.List>
          <Filter.ListItem value="SEASON">Season</Filter.ListItem>
          {isShowEstimated1099 && (
            <Filter.ListItem value="W-FORM">W-Form</Filter.ListItem>
          )}
          <Filter.ListItem value="SOURCE">Source</Filter.ListItem>
        </Filter.List>
        <Filter.Body value="SEASON">
          <SeasonFilter updateFilter={updateFilter} values={values} />
        </Filter.Body>
        <Filter.Body value="W-FORM">
          <WFormFilters updateFilter={updateFilter} values={values} />
        </Filter.Body>
        <Filter.Body value="SOURCE">
          <Source updateFilter={updateFilter} values={values} />
        </Filter.Body>
      </Filter.Portal>
    </Filter.Root>
  );
};
