import { useState } from "react";
import { useParams } from "react-router-dom";
import "static/styles/components/taskContractors.css";
import { useGetTaskMerchantsQuery } from "store/apis/taskMerchants";
import { AnalyseBankStatement } from "./AnalyseBankStatement";
import Loader from "./design/loader";
import { NoContractor } from "./NoContractor";
import { IncompleteBooksOnboarding } from "./Task1099/IncompleteBooksOnboarding";
import { AddVendor } from "./Vendors/AddVendor/AddVendor";
import { MerchantTable } from "./Vendors/merchantTable";

export const WSeriesForm = ({
  withFormProgress,
  groupId,
  noContractorsFound,
  taskSeason,
  isSuccessPage = false,
  shouldLoadBooksOnboarding,
  entityId,
}: {
  withFormProgress?: boolean;
  groupId: string;
  noContractorsFound: boolean;
  taskSeason: string;
  isSuccessPage?: boolean;
  shouldLoadBooksOnboarding: boolean;
  entityId: string;
}): JSX.Element | null => {
  const { taskId } = useParams<{ taskId: string }>();

  const [sorting, setSorting] = useState([
    {
      id: "AMOUNT",
      desc: false,
    },
  ]);

  const { data: merchants = [], isLoading: isContractorLoading } =
    useGetTaskMerchantsQuery({
      groupId,
      taskId,
      season: taskSeason,
      entityId,
    });

  if (isContractorLoading) {
    return <Loader />;
  }

  if (shouldLoadBooksOnboarding) {
    return <IncompleteBooksOnboarding entityId={entityId} groupId={groupId} />;
  }

  if (noContractorsFound && merchants?.length === 0) {
    return <NoContractor />;
  }

  if (merchants?.length === 0) {
    return <AnalyseBankStatement season={taskSeason} />;
  }

  const tableContent = (
    //@ts-ignore
    <MerchantTable
      merchants={merchants}
      groupId={groupId}
      showHeader={false}
      sorting={sorting}
      setSorting={setSorting}
      entityId={entityId}
      selectedSeason={taskSeason}
    />
  );

  if (isSuccessPage) {
    return <div className="t-w-full">{tableContent}</div>;
  }

  return (
    <div className="task-contractors pb-3 overflow-auto t-mx-4">
      <div className="t-my-4">
        <div className="t-flex t-justify-between t-items-center t-mb-3">
          <div className="t-text-subtitle-sm t-text-text-100">
            W Series and Form 1099
          </div>
          <div className="t-mr-2">
            <AddVendor entityId={entityId} />
          </div>
        </div>
        <div className="t-text-body t-text-text-60">
          Below is the list of contractors from your bank statements. Please add
          their contact details and collect W-series forms. If a contractor is
          missing, inform us in chat.
        </div>
      </div>
      {tableContent}
    </div>
  );
};
