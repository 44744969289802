import classNames from "classnames";
import React from "react";
import { ChannelHeader } from "components/ChannelHeader/ChannelHeader";
import { MessageBubble } from "components/MessageBubble/MessageBubble";
import { MessageInput } from "components/MessageInput/MessageInput";
import { SMALL } from "constants/chatBubbleWidths";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import uploadCloud from "static/images/uploadCloud.svg";
import { TaskChannel, useGetTaskFromChannelIdQuery } from "store/apis/task";
import { setDropedFile } from "store/slices/chat";
import {
  Channel as StreamChannel,
  getGroupStyles,
  MessageInput as StreamInput,
  MessageList,
  useChatContext,
  Window,
  VirtualizedMessageList,
} from "stream-chat-react";
import { JumpToMessage } from "./JumpToMessage";
import { useGetUnresolvedTicketMessageIdsQuery } from "store/apis/chat";
import { useQuery } from "hooks/useQuery";
import { useSelector } from "react-redux";
import { RootState } from "store/store";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { ANNOUNCEMENTS_CHANNEL } from "constants/chatType";
import DeleteMessageModal from "components/MessageBubble/DeleteMessage";
import { ArrayElement } from "types/utils/ArrayElement";
import { useDeviceInfo } from "hooks/useDeviceInfo";
import { FIREFOX } from "constants/deviceInfo";
import { NoChannelSelected } from "components/TPPayments/Illustration/NoChannelSelected";
import { DocuSignModal } from "components/RequestSign/DocuSignModal";
import { ChatTicketModal } from "components/MessageBubble/ChatTicketModal";
import { ClarifyModal } from "components/OpenItem/ClarifyModal";
import { AddOpenItem } from "components/OpenItem/AddOpenItem";

dayjs.extend(isYesterday);
dayjs.extend(isToday);
dayjs.extend(localizedFormat);

type CustomChannelProps = {
  channelId?: string;
  Header?: React.ComponentType<{
    isPrivateChannel: boolean;
    setPrivateChannel: any;
  }>;
  messageMaxWidth?: string;
  isTicket?: boolean;
  children?: React.ReactNode;
  messageId?: string;
};

export const getPrivateOrPublicChannelForCurrentChannel = (
  task?: ArrayElement<TaskChannel["tasks"]>,
  companyGroup?: TaskChannel["company_group"],
  currentChannel?: string,
  isPrivate?: boolean
) => {
  const returnPublic = !isPrivate;
  const returnPrivate = isPrivate;

  const isTaskChannel =
    task?.channel.channel_url === currentChannel ||
    task?.private_channel.channel_url === currentChannel;

  const isBookkeepingChannel = currentChannel
    ? Object.values(companyGroup?.books_channels || {})
        .map((c) => c.channel_url)
        .includes(currentChannel)
    : false;

  const isAnnouncementChannel =
    companyGroup?.announcements_channel.channel_url === currentChannel;
  const isMailRoomChannel =
    companyGroup?.mailbox_channel.channel_url === currentChannel;

  const isGroupLevelChannel =
    isBookkeepingChannel ||
    isAnnouncementChannel ||
    isMailRoomChannel ||
    !isTaskChannel;

  const isCurrentChannelPrivate =
    companyGroup?.private_channel.channel_url === currentChannel;

  const privateChannel = isTaskChannel
    ? task?.private_channel.channel_url
    : companyGroup?.private_channel.channel_url;

  let publicChannel = isGroupLevelChannel
    ? currentChannel
    : task?.channel.channel_url;

  if (returnPrivate && privateChannel) {
    return privateChannel;
  }

  if (returnPublic && isCurrentChannelPrivate) {
    return companyGroup?.secondary_channel.channel_url;
  }

  if (returnPublic && isGroupLevelChannel) {
    return currentChannel;
  }

  if (returnPublic && publicChannel) {
    return publicChannel;
  }

  return currentChannel;
};

export const Channel = ({
  channelId: propsChannelId,
  Header = ChannelHeader,
  isTicket,
  messageMaxWidth = SMALL,
  children,
  messageId,
}: CustomChannelProps) => {
  const dispatch = useDispatch();
  const { client, channel: currentChannel } = useChatContext();
  const [isPrivate, setIsPrivate] = useState(false);
  const [channelToShow, setChannelToShow] = useState<string>();
  const { browser } = useDeviceInfo();
  const { isAdmin, isCustomer } = useRoleBasedView();
  const [isLoading, setIsLoading] = useState(true);

  const messageToScrollTo =
    useSelector((state: RootState) => state.messageToScrollTo.messageId) ||
    messageId;

  const { data: channelTask } = useGetTaskFromChannelIdQuery(
    {
      channelId: (propsChannelId || currentChannel?.id)!,
    },
    {
      skip: !(propsChannelId || currentChannel?.id),
    }
  );

  const { tasks, company_group: companyGroup } = channelTask || {};
  const task = tasks?.[0];

  const formatDate = (date: Date) => {
    const formattedDate = dayjs(date);

    if (formattedDate.isToday()) {
      return `Today at ${formattedDate.format("LT")}`;
    }

    if (dayjs(date).isYesterday()) {
      return `Yesterday at ${formattedDate.format("LT")}`;
    }

    return formattedDate.format("D-MMM-YYYY");
  };

  const onDrop = async (files: File[]) => {
    dispatch(setDropedFile(files));
  };

  const { getRootProps, isDragAccept } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop,
  });

  const { data: unresolvedTickets } = useGetUnresolvedTicketMessageIdsQuery(
    {
      channelUrl: currentChannel?.id!,
    },
    {
      skip: (!isAdmin && !isCustomer) || !currentChannel?.id,
    }
  );
  const query = useQuery();
  const currentTicket = query.get("ticketUuid");

  const ticketMessageId = useMemo(() => {
    return (
      unresolvedTickets?.ticket_messages.find(
        (e) => e.ticket_uuid === currentTicket
      )?.message_id ?? null
    );
  }, [currentTicket, unresolvedTickets]);

  let channelId = propsChannelId || currentChannel?.id;

  const setPrivateChannel = () => {
    setIsPrivate((v) => {
      setIsLoading(true);
      const newChannelId = getPrivateOrPublicChannelForCurrentChannel(
        task,
        companyGroup,
        channelId,
        !v
      );

      setChannelToShow(newChannelId);
      setIsLoading(false);

      return !v;
    });
  };

  useEffect(() => {
    const isCurrentPrivateChannel =
      task?.private_channel.channel_url === channelId ||
      companyGroup?.private_channel.channel_url === channelId;

    setIsPrivate(isCurrentPrivateChannel);
  }, [
    channelId,
    companyGroup?.private_channel.channel_url,
    task?.private_channel.channel_url,
  ]);

  useEffect(() => {
    setChannelToShow(channelId);
    setIsLoading(false);
  }, [channelId]);

  useEffect(() => {
    if (ticketMessageId) {
      dispatch(setScrollToMessageId(ticketMessageId));
    }
  }, [dispatch, ticketMessageId]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    timer = setTimeout(() => {
      dispatch(setScrollToMessageId());
    }, 2000);

    return () => clearTimeout(timer);
  }, [messageToScrollTo]);

  const channel = useMemo(() => {
    if (channelToShow) {
      return client.channel("messaging", channelToShow);
    }
  }, [channelToShow]);

  if (!client || isLoading) {
    return (
      <div className="t-flex t-items-center t-justify-center t-h-full t-w-full t-bg-surface-grey t-gap-5 t-flex-col">
        <div className="t-flex t-flex-col t-gap-1 t-text-center">
          <p className="t-m-0 t-text-subtext t-animate-pulse-fast">
            Loading...
          </p>
        </div>
      </div>
    );
  }

  if (!channelToShow) {
    return (
      <div className="t-flex t-items-center t-justify-center t-h-full t-w-full t-bg-surface-grey t-gap-5 t-flex-col">
        <NoChannelSelected />
        <div className="t-flex t-flex-col t-gap-1 t-text-center">
          <p className="t-m-0 t-text-subtext">No channel selected yet</p>
          <p className="t-m-0 t-text-body-sm">
            Select a channel to get started
          </p>
        </div>
      </div>
    );
  }

  return (
    <div
      // @ts-ignore
      style={{ "--message-max-width": messageMaxWidth }}
      {...getRootProps()}
      className={classNames({
        "t-h-full": !isTicket,
        "t-h-[calc(100%-98px)]": isTicket,
      })}
    >
      {isDragAccept && (
        <div
          className={classNames(
            "t-absolute t-z-10 t-flex  t-flex-col t-items-center t-justify-center t-gap-3 t-border t-border-dashed t-border-neutral-70 t-bg-[#f3f3f580]",
            {
              "t-h-[-webkit-fill-available] t-w-[-webkit-fill-available]":
                browser !== FIREFOX,
              "t-h-full t-w-[-moz-available]": browser === FIREFOX,
            }
          )}
        >
          <img
            src={uploadCloud}
            alt="upload"
            className="t-animate-drag-and-drop"
          />
          <div className="custom-chat-upload-drop-card">
            Drop files to upload them
          </div>
        </div>
      )}
      <StreamChannel
        Message={MessageBubble}
        Input={MessageInput}
        channel={channel}
      >
        <DocuSignModal />
        <ClarifyModal />
        <AddOpenItem />
        {messageToScrollTo && <JumpToMessage messageId={messageToScrollTo} />}
        <Window hideOnThread>
          {!isTicket && (
            <Header
              isPrivateChannel={isPrivate}
              setPrivateChannel={setPrivateChannel}
            />
          )}

          <MessageList
            formatDate={formatDate}
            // @ts-ignore // They only expect a certain type but we need to pass our class name also
            groupStyles={(...args) =>
              `${getGroupStyles(...args)}  t-group/${getGroupStyles(...args)}`
            }
          />
          {currentChannel?.data?.custom_type !== ANNOUNCEMENTS_CHANNEL && (
            <StreamInput />
          )}
        </Window>

        {children}
        <ChatTicketModal />
      </StreamChannel>
      {isAdmin && <DeleteMessageModal />}
    </div>
  );
};
