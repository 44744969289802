import { ActionRequiredPopOver } from "components/ActionRequiredPopover/ActionRequiredPopover";
import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import { useActionItems } from "hooks/useActionItems";
import { DataSourcesList } from "./DataSourcesList";

export const DataSources = () => {
  const { isReconciliationRequired, isBankReconnectRequired } =
    useActionItems();

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title={
            <div className="t-flex t-gap-2 t-items-center">
              Data Sources{" "}
              {(isBankReconnectRequired || isReconciliationRequired) && (
                <ActionRequiredPopOver type="RECONCILLATION_OR_ACCOUNT" />
              )}
            </div>
          }
        />
      }
    >
      <DataSourcesList />
    </DashboardLayout>
  );
};
