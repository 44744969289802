export const TASK_ARCHIVED = "Filing Archived";
export const TASK_UNARCHIVED = "Filing Unarchived";

export const CANT_EDIT_BECAUSE_TASK_ARCHIVED =
  "You can't edit because This filing is archived.";

export const TASK_SUCCESSFULLY_FILED = "Successfully Filed";

// Task filters
export const ONGOING = "ONGOING";
export const COMPLETED = "COMPLETED";
export const ARCHIVED = "ARCHIVED";
export const ALL = "ALL";

export const SUBMITTED = "SUBMITTED";

export const ADDRESS_AUTOFILL_VERSION_2_0 = "2.0";

export const BANK_ACCOUNTS = "bank_accounts";
export const ADDRESSES = "addresses";
export const AUTHORIZED_PERSONS = "authorized_persons";

// sort options
export const MY_FILING_SORT_OPTIONS = [
  { id: 1, title: "Default", value: "Default" },
  {
    id: 2,
    title: "Oldest Filing in progress",
    value: "Oldest FIP",
    sortKey: "OLD_BY_FILING_IN_PROGRESS",
  },
  {
    id: 3,
    title: "Latest Filing in progress",
    value: "Latest FIP",
    sortKey: "NEW_BY_FILING_IN_PROGRESS",
  },
];

//etd filter keys
export const UN_SET = "UN_SET";
export const LESS_THAN_30_DAYS = "LESS_THAN_30_DAYS";
export const MORE_THAN_30_DAYS = "MORE_THAN_30_DAYS";
export const OVER_DUE = "OVER_DUE";

// etd filter options
export const ETD_FILTER_OPTIONS = [
  {
    value: UN_SET,
    label: "Unset ETD",
  },
  {
    value: LESS_THAN_30_DAYS,
    label: "Less Than 30 Days remaining",
  },
  {
    value: MORE_THAN_30_DAYS,
    label: "More Than 30 Days remaining",
  },
  {
    label: "Overdue",
    value: OVER_DUE,
  },
];

export const BANK_ACCESS_HELP =
  "https://www.inkle.io/help/giving-bank-access-to-team-inkle-as-bookkeeper";

export const MISSING_INFORMATION_FORM_7004_FLAG =
  "missing_information_form_7004_flag";
export const MISSING_BUSINESS_FINANCIALS_FORM_7004_FLAG =
  "missing_business_financials_form_7004_flag";
export const PROFITABLE = "profitable";
export const NON_PROFITABLE = "non_profitable";
export const BOOKS_COMPLETED = "books_completed";
export const BOOKS_IN_PROGRESS = "books_in_progress";
export const BOOKS_NOT_STARTED = "books_not_started";
