import ConditionalToolTip from "components/design/conditionalToolTip";
import { LoadingToast } from "components/design/LoadingToast";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Form, Formik } from "formik";
import { manuallyTransactionsSchema } from "formValidations/manuallyTransactionsSchema";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { useSelector } from "react-redux";
import { useAddManualTransactionsMutation } from "store/apis/transactions";
import { RootState } from "store/store";
import { FileObject } from "types/Models/fileObject";
import { CSVResponse } from "types/Models/reconciliation";
import { BackendError } from "types/utils/error";
import { TransactionTable } from "./TransactionTable";
import { useMarkInvoicePaidMutation } from "store/apis/invoices";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { flattenTypes } from "utils/flattenCOA";
import randomBytes from "randombytes";

export type InternalTransaction = {
  id: number;
  date: string;
  merchant: string;
  category: string;
  description: string;
  amount: number;
  invoice: (FileObject & { file_data: FileObject | null }) | null;
  linked_transaction?: string;
  uuid?: string;
};

type TransactionForTable = Omit<InternalTransaction, "id"> & { id: string };

export const AddTransactionManuallyModal = ({
  close,
  selectedAccountId,
  csvTransactions,
  defaultTransactions,
}: {
  close: () => void;
  selectedAccountId: string;
  csvTransactions?: CSVResponse[];
  defaultTransactions?: (Omit<TransactionForTable, "invoice"> & {
    invoice: null;
  })[];
}) => {
  const { manualTxnInvoiceId, manualInvoiceDueAmount } = useSelector(
    (state: RootState) => state.linkInkleInvoice
  );

  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const isCSVTransactions = Boolean(csvTransactions);

  const { chartOfAccounts = [] } = useChartOfAccounts({
    hiddenCategoryTypes: ["BANK_ACCOUNT"],
  });

  const [addManualTransactions, { isLoading }] =
    useAddManualTransactionsMutation();

  const [markInvoicePaid, { isLoading: isMarkingInovicePaid }] =
    useMarkInvoicePaidMutation();

  const manualTxnInvoiceCategory = flattenTypes({
    accounts: chartOfAccounts,
  }).find(({ identifier }) => identifier === 11000);

  const onSubmit = async ({
    transactions,
    reverse,
  }: {
    transactions: TransactionForTable[];
    reverse: boolean | null;
  }) => {
    try {
      const transactionList = transactions
        .filter(
          ({ date, description }) => Boolean(date) && Boolean(description)
        )
        .map(({ amount, category, date, description, invoice, merchant }) => ({
          date: dayjs(date).format(YYYY_MM_DD),
          amount: reverse ? -1 * amount! : amount,
          description,
          invoice_id: invoice?.uuid! || null,
          merchant_data_id: merchant || "",
          transaction_category_id: category || "",
        }));

      if (manualTxnInvoiceId) {
        await markInvoicePaid({
          accountId: selectedAccountId,
          entityId,
          invoiceId: manualTxnInvoiceId,
          manualTransactions: transactionList?.[0],
        }).unwrap();
        successToast({ message: "Marked Paid Successsfully" });
        close();
      } else {
        await addManualTransactions({
          accountId: selectedAccountId,
          entityId,
          groupId,
          transactions: transactionList,
        }).unwrap();

        successToast({ message: "Transactions have been populated!" });
        close();
      }
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  let initialTransactions = csvTransactions
    ? csvTransactions.map((transaction, i) => ({
        id: randomBytes(16).toString("hex"),
        date: transaction.date,
        merchant: transaction.merchant || "",
        category: transaction.category || "",
        description: transaction.description || "",
        amount: transaction.amount ?? 0,
        invoice: null,
      }))
    : Array.from({ length: manualTxnInvoiceId ? 1 : 3 }).map((arr, i) => ({
        id: randomBytes(16).toString("hex"),
        date: "",
        merchant: "",
        category: manualTxnInvoiceId ? manualTxnInvoiceCategory?.uuid! : "",
        description: "",
        amount: manualTxnInvoiceId ? manualInvoiceDueAmount : 0,
        invoice: null,
      }));

  if (defaultTransactions && defaultTransactions.length > 0) {
    initialTransactions = defaultTransactions;
  }

  const isTransactionFilled = (transaction: TransactionForTable) =>
    Boolean(
      transaction.date || transaction.amount !== 0 || transaction.description
    );

  const areMandatoryFieldsFilled = (transaction: TransactionForTable) =>
    Boolean(transaction.date && transaction.description);

  const areTransactionsValid = (transactions: TransactionForTable[]) => {
    const anyTransactionFilled = transactions.some(isTransactionFilled);

    const allFilledTransactionsValid = transactions.every(
      (transaction) =>
        !isTransactionFilled(transaction) ||
        areMandatoryFieldsFilled(transaction)
    );

    return anyTransactionFilled && allFilledTransactionsValid;
  };

  return (
    <>
      <Formik
        initialValues={{
          transactions: initialTransactions,
          reverse: false,
        }}
        onSubmit={onSubmit}
        validateOnChange
        validationSchema={manuallyTransactionsSchema}
      >
        {({
          submitForm,
          values: { transactions },
          isSubmitting,
          isValid,
          setFieldValue,
        }) => (
          <Form className="t-m-0 t-w-full t-h-full">
            <Modal.Body className="t-pb-0 t-flex t-flex-col t-gap-4">
              {isCSVTransactions && (
                <div className="t-flex t-flex-col t-gap-2">
                  <div className="t-text-subtitle-sm t-text-text-60">
                    Are the uploaded transactions represented like:{" "}
                    <span className="t-text-text-100">
                      expenses= negative numbers & income= positive numbers
                    </span>
                  </div>
                  <RadioGroup.Root
                    defaultValue="YES"
                    onValueChange={(value) => {
                      setFieldValue("reverse", value === "NO" ? true : false);
                    }}
                    className="t-flex t-gap-4"
                  >
                    <RadioGroup.Item value="YES" className="t-w-max">
                      Yes
                    </RadioGroup.Item>
                    <RadioGroup.Item value="NO" className="t-w-max">
                      No, reverse signs
                    </RadioGroup.Item>
                  </RadioGroup.Root>
                </div>
              )}
              <TransactionTable isCSVTransactions={isCSVTransactions} />
            </Modal.Body>
            <Modal.FooterButtonGroup>
              <Button onClick={close} type="reset">
                Cancel
              </Button>
              <ConditionalToolTip
                condition={isValid ? "" : "Please add all mandatory fields"}
              >
                <span>
                  <Button
                    customType="primary"
                    type="submit"
                    onClick={submitForm}
                    disabled={
                      transactions.length === 0 ||
                      isSubmitting ||
                      !isValid ||
                      !areTransactionsValid(transactions)
                    }
                    isLoading={isSubmitting || isMarkingInovicePaid}
                  >
                    {manualTxnInvoiceId ? "Link & Add" : "Add Transactions"}
                  </Button>
                </span>
              </ConditionalToolTip>
            </Modal.FooterButtonGroup>
          </Form>
        )}
      </Formik>
      <LoadingToast loading={isLoading} title="Please wait">
        Populating transactions...
      </LoadingToast>
    </>
  );
};
