import Modal from "components/DesignSystem/Modal/Modal";
import { useState } from "react";
import { ModalProps } from "types/utils/modal";
import { AddAnnualCatchupStepper } from "./AddAnnualCatchupStepper";
import { AnnualCatchupConnectBank } from "./AnnualCatchupConnectBank";
import { ShareBookkeepingAccess } from "./ShareBookkeepingAccess";
import SmallInkleBooksLogo from "static/images/SmallInkleBooks.svg";
import { AddProductToCart } from "components/AddProductToCart/AddProductToCart";

type AddAnnualCatchupProps = ModalProps & {
  entityId: string;
  groupId: string;
  productId: string;
  currentSeason: number;
  onAdd: () => void;
};

export const AddAnnualCatchup = ({
  isOpen,
  close,
  entityId,
  groupId,
  productId,
  currentSeason,
  onAdd,
}: AddAnnualCatchupProps) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [connectedBankCount, setConnectedBankCount] = useState(-1);

  const handleItemAdd = () => {
    close();
    onAdd();
  };

  let stepComponent = null;

  if (currentStep == 1) {
    stepComponent = (
      <AnnualCatchupConnectBank
        entityId={entityId}
        groupId={groupId}
        setCurrentStep={setCurrentStep}
        connectedBankCount={connectedBankCount}
        setConnectedBankCount={setConnectedBankCount}
      />
    );
  }
  if (currentStep == 2) {
    stepComponent = (
      <ShareBookkeepingAccess
        entityId={entityId}
        groupId={groupId}
        setCurrentStep={setCurrentStep}
        connectedBankCount={connectedBankCount}
      />
    );
  }

  if (currentStep == 3) {
    stepComponent = (
      <AddProductToCart
        onClose={() => {}}
        productIdFromProps={productId}
        currentSeason={currentSeason}
        entityIdFromProps={entityId}
        onAdd={handleItemAdd}
        useOwnModal
        setCurrentStep={setCurrentStep}
      />
    );
  }

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content size="large">
        <Modal.Header>
          <div>
            <Modal.Title>
              <div className="t-flex t-items-center t-gap-2">
                Bookkeeping Catchup {currentSeason}{" "}
                <span className="t-text-body t-text-text-30">by</span>
                <img src={SmallInkleBooksLogo} alt="" className="t-h-4" />
              </div>
            </Modal.Title>
            <Modal.Subtitle className="t-mt-3">
              <AddAnnualCatchupStepper
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
            </Modal.Subtitle>
          </div>
          <Modal.Close />
        </Modal.Header>
        {stepComponent}
      </Modal.Content>
    </Modal.Root>
  );
};
