export const GROUP_BOOKS_CONSOLIDATION_ONE_TIME =
  "GROUP_BOOKS_CONSOLIDATION_ONE_TIME";
export const US_BOOKKEEPING_ONE_TIME = "US_BOOKKEEPING_ONE_TIME";
export const US_BOOKKEEPING_SUBSCRIPTION_PARENT =
  "US_BOOKKEEPING_SUBSCRIPTION_PARENT";
export const US_BOOKKEEPING_SUBSCRIPTION_SUB_TASK =
  "US_BOOKKEEPING_SUBSCRIPTION_SUB_TASK";
export const GAAP_FINANCIALS = "GAAP_FINANCIALS";
export const AUDIT = "AUDIT";
export const FINANCIAL_MODELLING = "FINANCIAL_MODELLING";
export const HISTORICAL_CLEANUP = "HISTORICAL_CLEANUP";
export const GROUP_BOOKS_CONSOLIDATION_RECURRING = "GROUP_BOOKS_CONSOLIDATION";
export const PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING =
  "PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING";
export const PRO_MONTHLY_BOOKKEEPING = "PRO_MONTHLY_BOOKKEEPING";
export const FORM_1099_AND_W_SERIES = "FORM_1099_AND_W_SERIES";
export const PAN_CARD_APPLICATION = "PAN_CARD_APPLICATION";
export const TP_PAYMENTS_ONBOARDING = "TP_PAYMENTS_ONBOARDING";
export const STATE_FRANCHISE_TAX_DELAWARE = "STATE_FRANCHISE_TAX_DELAWARE";
export const AD_HOC = "AD_HOC";
export const US_HOLDCO_INCORPORATION = "US_HOLDCO_INCORPORATION";

export type ServiceType =
  | typeof GROUP_BOOKS_CONSOLIDATION_ONE_TIME
  | typeof GAAP_FINANCIALS
  | typeof AUDIT
  | typeof FINANCIAL_MODELLING
  | typeof GROUP_BOOKS_CONSOLIDATION_RECURRING
  | typeof PAY_AS_YOU_GO_ANNUAL_BOOKKEEPING
  | typeof PRO_MONTHLY_BOOKKEEPING
  | typeof PAN_CARD_APPLICATION
  | typeof TP_PAYMENTS_ONBOARDING
  | typeof US_BOOKKEEPING_ONE_TIME
  | typeof US_BOOKKEEPING_SUBSCRIPTION_PARENT
  | typeof US_BOOKKEEPING_SUBSCRIPTION_SUB_TASK
  | typeof US_HOLDCO_INCORPORATION;
