import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { useToast } from "hooks/useToast";
import { useEditVendorsMutation } from "store/apis/vendors";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const MarkAsRequired = ({
  isOpen,
  close,
  merchantId,
  groupId,
  season,
}: ModalProps & { merchantId: string; groupId: string; season: string }) => {
  const [editVendor, { isLoading: isEditing }] = useEditVendorsMutation();
  const { alertToast, successToast } = useToast();

  const markVendorFormNotRequired = async () => {
    try {
      await editVendor({
        groupId,
        uuid: merchantId,
        payload: {
          form_1099_status: "NOT_STARTED",
          amount_paid_in_season: season,
        },
      }).unwrap();
      close();
      successToast({ message: "Vendor has been updated" });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Mark the vendor as required</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-text-body-lg t-text-text-100">
            Are you sure you want to mark this vendor as required?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-flex t-justify-end t-gap-3">
            <Modal.RawClose asChild>
              <Button>Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="primary"
              isLoading={isEditing}
              disabled={isEditing}
              onClick={markVendorFormNotRequired}
            >
              Confirm
            </Button>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
