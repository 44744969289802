import { useCallback, useState } from "react";

export const useShow = (defaultValue: boolean = false) => {
  const [isShown, setIsShown] = useState(defaultValue);

  const show = useCallback(() => setIsShown(true), []);

  return {
    isShown,
    show,
    hide: () => {
      setIsShown(false);
    },
    toggle: () => {
      setIsShown((o) => !o);
    },
  };
};
