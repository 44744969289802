import { object, string, number } from "yup";

export const shareholderSchema = object({
  shareholderType: string().required("Shareholder type is required"),
  persona: string().when("shareholderType", {
    is: "PERSONA",
    then: string().required("Persona is required"),
    otherwise: string().nullable(),
  }),
  entity: string().when("shareholderType", {
    is: "COMPANY_ENTITY",
    then: string().required("Entity is required"),
    otherwise: string().nullable(),
  }),
  ownership: number()
    .required("Ownership is required")
    .typeError("Ownership must be a number")
    .max(100, "Ownership must be less than or equal to 100"),
  country_id: string().required("Country is required"),
});
