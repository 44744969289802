import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import FilledCaretRight from "static/images/FilledRightCaret.svg";

// components
import { Button } from "components/design/button";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";

const CompanyInformation = ({
  goBack,
  setPerkData,
  handleChange,
  setPreviewImage,
  setCurrentState,
  perkData,
}) => {
  const [file, setFile] = useState("");

  useEffect(() => {
    if (perkData.file) setFile(perkData.file);
  }, []);

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);

    setPreviewImage(
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      })
    );
  };

  const { open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/png": [".png", ".jpg", ".jpeg"],
    },
  });

  const storeData = (values) => {
    setPerkData((perkData) => ({ ...perkData, ...values, file }));
    setCurrentState(1);
  };

  return (
    <>
      <span className="perkRightContainerHeader">Company Information</span>
      <Formik
        onSubmit={storeData}
        initialValues={{
          website: perkData.website,
          linkedin: perkData.linkedin,
          shortDescription: perkData.shortDescription,
        }}
      >
        <Form className="w-100 newPerkForm" onChange={handleChange}>
          <div className="t-flex t-flex-col t-gap-3">
            <TextInput
              placeholder="The name of your company"
              name="name"
              label="Company"
              required
            />

            <div>
              <label className="t-font-sans t-text-body-sm t-text-neutral-80 after:t-font-bold after:t-text-red after:t-content-['_*']">
                Logo
              </label>
              <div className="logoInput t-bg-surface-lighter-grey">
                <span className="newPerkInputs">
                  {file?.path || "Choose a file"}
                  <button
                    type="button"
                    className="chooseBtn"
                    onClick={() => {
                      open();
                    }}
                  >
                    Choose
                  </button>
                </span>
              </div>
            </div>

            <TextInput
              type="url"
              placeholder="Your company website"
              name="website"
              label="Website"
              required
            />

            <TextInput
              type="url"
              placeholder="Your LinkedIn Link"
              name="linkedin"
              label="LinkedIn"
              required
            />

            <TextInput
              maxLength="60"
              placeholder="One sentence description of company/product"
              name="shortDescription"
              label="Short Description"
              required
            />
          </div>
          <div className="d-flex justify-content-between w-100 perkBtns">
            <Button size="medium" color="cancel" onClick={goBack} type="button">
              Cancel
            </Button>
            <Button size="medium" color="transparent" type="submit">
              <img src={FilledCaretRight} alt="CaretRight" />
            </Button>
          </div>
        </Form>
      </Formik>
    </>
  );
};

export default CompanyInformation;
