import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import ReviveSubscriptionPaymentFailedImg from "static/images/ReviveSubscriptionPaymentFailed.svg";

const ReviveSubscriptionPaymentFailed = ({
  show,
  setShow,
  openPaymentMethodModal,
}: {
  show: boolean;
  setShow: (v: boolean) => void;
  openPaymentMethodModal: () => void;
}) => {
  return (
    <Modal.Root open={show} onOpenChange={() => setShow(false)}>
      <Modal.Content>
        <Modal.Header>
          <div></div>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-mb-4 t-mt-12  t-flex t-flex-col t-items-center">
            <img
              src={ReviveSubscriptionPaymentFailedImg}
              alt="ReviveSubscriptionPaymentFailedImg"
            />
            <div className="t-mt-4 t-text-title-h2-bold t-text-text-100">
              Payment failed
            </div>
            <div className="t-mb-6 t-mt-1 t-text-center t-text-body-lg t-text-neutral">
              Oops! Your payment was not successful. Please try again.
            </div>
          </div>
        </Modal.Body>
        <Modal.FooterButtonGroup>
          <Modal.RawClose asChild>
            <Button onClick={() => setShow(false)}>Cancel</Button>
          </Modal.RawClose>
          <Button onClick={openPaymentMethodModal}>Update card details</Button>
        </Modal.FooterButtonGroup>
      </Modal.Content>
    </Modal.Root>
  );
};

export default ReviveSubscriptionPaymentFailed;
