import { Padlock } from "components/icons/Padlock";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import {
  ComponentProps,
  ComponentType,
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useEffect,
  useId,
  useState,
} from "react";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";
import { Anchor, useLeftBar } from "./LeftBar";
import classNames from "classnames";
import { AnimatePresence, LayoutGroup, motion } from "framer-motion";
import { ActiveIndicator } from "components/icons/LeftNav/Books/ActiveIndicator";
import HoverCard from "components/DesignSystem/HoverCard/HoverCard";
import { Expand as ExpandIcon } from "components/icons/LeftNav/Books/Expand";

type SubMenuContextProps = {
  isOpen: boolean;
  toggleMenu: () => void;
  isHovering: boolean;
  onHoverClose: () => void;
  onHoverOpen: () => void;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  defaultRoute?: string;
  isActiveIndicatorVisible: boolean;
  isSubMenuActive: boolean;
};

const SubMenuContext = createContext<SubMenuContextProps>({
  isOpen: false,
  toggleMenu: () => {},
  isHovering: false,
  onHoverClose: () => {},
  onHoverOpen: () => {},
  setIsOpen: () => {},
  isActiveIndicatorVisible: false,
  isSubMenuActive: false,
});

export const SubMenuRoot = ({
  children,
  defaultRoute,
  childPaths,
}: {
  children: ReactNode;
  defaultRoute?: string;
  childPaths?: string[];
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const { open } = useLeftBar();
  const { location } = useHistory();

  const isSubMenuActive = Boolean(
    childPaths?.some((path) => location?.pathname?.includes(path))
  );

  const isActiveIndicatorVisible = isSubMenuActive && Boolean(!open || !isOpen);

  const toggleMenu = () => {
    if (!open) return;
    setIsOpen((prev) => !prev);
  };

  const onHoverClose = () => {
    setIsHovering(false);
  };

  const onHoverOpen = () => {
    setIsHovering(true);
  };

  return (
    <SubMenuContext.Provider
      value={{
        isOpen,
        toggleMenu,
        isHovering,
        onHoverClose,
        onHoverOpen,
        setIsOpen,
        defaultRoute,
        isSubMenuActive,
        isActiveIndicatorVisible,
      }}
    >
      <HoverCard.Root open={isHovering} openDelay={300}>
        {children}
      </HoverCard.Root>
    </SubMenuContext.Provider>
  );
};

const useSubMenu = () => {
  const context = useContext(SubMenuContext);
  if (!context)
    throw new Error("useSubMenu must be used within SubMenuProvider");
  return context;
};

const StaticTrigger = (props: ComponentProps<"div">) => {
  return <div {...props} />;
};

const HoverTrigger = ({ children, ...props }: ComponentProps<"div">) => {
  const { onHoverClose, onHoverOpen } = useSubMenu();

  return (
    <HoverCard.Trigger onMouseEnter={onHoverOpen} onMouseLeave={onHoverClose}>
      <div {...props}>{children}</div>
    </HoverCard.Trigger>
  );
};

const SubMenuTrigger = ({
  children,
  icon: Icon,
}: {
  children: ReactNode;
  icon: ComponentType;
}) => {
  const { isOpen, toggleMenu, isSubMenuActive, isActiveIndicatorVisible } =
    useSubMenu();
  const { open } = useLeftBar();
  const id = useId();
  const { location } = useHistory();

  const SubMenuTriggerContainer = open ? StaticTrigger : HoverTrigger;

  useEffect(() => {
    if (!isSubMenuActive && isOpen) {
      toggleMenu();
    }
  }, [location?.pathname]);

  return (
    <SubMenuTriggerContainer
      onClick={() => {
        toggleMenu();
      }}
      className="t-cursor-pointer"
    >
      <div
        className={classNames(
          "t-relative t-py-2 t-mb-1 t-mx-2 t-pr-2 t-flex t-gap-2 t-items-center t-rounded-md t-border-solid t-border-0 before:t-content-[''] before:t-w-[3px] before:t-h-full before:t-absolute before:t-left-0 t-transition-all t-no-underline",
          {
            "t-bg-surface-lighter-grey hover:t-bg-neutral-0 t-group/non-active-link":
              !false,
            "!t-bg-neutral-0 t-group/active-link":
              (isActiveIndicatorVisible && !isOpen) ||
              (!open && isActiveIndicatorVisible),
            "t-justify-center !t-pr-0": !open,
            // "py-2": open,
          }
        )}
      >
        <LayoutGroup id={id}>
          <AnimatePresence>
            {isActiveIndicatorVisible ? (
              <motion.div
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                transition={{ delay: 0, duration: 0.2, ease: "easeIn" }}
                className={classNames("-t-mt-1 ", {
                  "t-absolute t-left-0": !open,
                })}
              >
                <ActiveIndicator />
              </motion.div>
            ) : (
              <div
                className={classNames("t-w-[2px]", {
                  "t-absolute t-left-0": !open,
                })}
              />
            )}
          </AnimatePresence>
          <AnimatePresence>
            {!open && (
              <motion.span
                animate={{ opacity: 1, position: "static" }}
                exit={{ opacity: 0, position: "absolute" }}
                initial={{ opacity: 0, position: "absolute" }}
                transition={{ delay: 0, duration: 0.1 }}
                className="t-flex group-[&]/active-link:t-text-text-100 t-text-text-30"
              >
                <Icon />
              </motion.span>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {open && (
              <motion.div
                animate={{ opacity: 1, position: "static" }}
                exit={{ opacity: 0, position: "absolute" }}
                initial={{ opacity: 0, position: "absolute" }}
                transition={{ delay: 0, duration: 0.1 }}
                className="t-flex t-gap-2 t-items-center t-justify-between t-w-full"
              >
                <div className="t-flex t-gap-2 t-items-center">
                  <span
                    className={classNames(
                      "t-flex group-[&]/active-link:t-text-text-100",
                      {
                        "t-text-text-30": true,
                      }
                    )}
                  >
                    <Icon />
                  </span>
                  <span
                    className={classNames(
                      "t-text-body group-[&]/active-link:t-text-text-100 group-[&]/active-link:t-font-medium  t-whitespace-nowrap t-overflow-hidden",
                      {
                        "!t-text-text-60 group-hover/non-active-link:t-text-text-100":
                          true,
                      }
                    )}
                  >
                    {children}
                  </span>
                </div>
                <div className="t-cursor-pointer">
                  <motion.div
                    animate={{ rotate: isOpen ? 180 : 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    <ExpandIcon />
                  </motion.div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </LayoutGroup>
      </div>
    </SubMenuTriggerContainer>
  );
};

const SubMenuContainer = ({
  children,
  hoverTitle,
}: {
  children: ReactNode;
  hoverTitle: string;
}) => {
  const { isOpen } = useSubMenu();
  const { open } = useLeftBar();

  if (!open) {
    return (
      <HoverCard.Portal>
        <SubMenuContent hoverTitle={hoverTitle}>{children}</SubMenuContent>
      </HoverCard.Portal>
    );
  }

  return (
    <motion.div
      layout
      initial={{ opacity: 0, height: 0 }}
      animate={{
        opacity: isOpen ? 1 : 0,
        height: isOpen ? "auto" : 0,
      }}
      transition={{ duration: 0.2, ease: "easeInOut" }}
      className={classNames(
        "t-border-neutral-10 t-border-l t-border-0 t-overflow-hidden t-border-solid t-ml-5 t-relative"
      )}
    >
      <AnimatePresence>{isOpen && <div>{children}</div>}</AnimatePresence>
    </motion.div>
  );
};

const SubMenuContent = ({
  children,
  hoverTitle,
}: {
  children: ReactNode;
  hoverTitle: string;
}) => {
  const { onHoverClose, onHoverOpen } = useSubMenu();

  return (
    <HoverCard.Content
      className="t-min-w-60"
      align="start"
      side="right"
      sideOffset={2}
      onMouseEnter={onHoverOpen}
      onMouseLeave={onHoverClose}
    >
      <motion.div layout transition={{ duration: 0.3, ease: "backIn" }}>
        <p className="t-ml-4 t-pt-2 t-mb-[-3px] t-text-text-30 t-text-subtext-sm">
          {hoverTitle}
        </p>
        <div
          className={classNames(
            "t-border-neutral-10 t-border-l t-my-2 t-border-0 t-border-solid t-ml-5 t-relative t-overflow-hidden"
          )}
        >
          {children}
        </div>
      </motion.div>
    </HoverCard.Content>
  );
};

const SubMenuItem = ({
  useParentPath,
  to,
  disabled,
  suffix,
  children,
}: {
  useParentPath: boolean;
  to: string;
  disabled?: boolean;
  suffix?: ReactNode;
  children: ReactNode;
}) => {
  const isExternal =
    to?.startsWith("https://") ||
    to?.startsWith("http://") ||
    to?.startsWith("mailto") ||
    to?.startsWith("tel");

  let LinkComponent = isExternal ? Anchor : NavLink;
  const { open } = useLeftBar();
  const { path } = useRouteMatch();
  const { location, push } = useHistory();
  const { isOpen, isHovering, defaultRoute, isSubMenuActive } = useSubMenu();
  const isSelected = location?.pathname?.includes(to?.split("?")?.[0]);

  useEffect(() => {
    if (
      defaultRoute &&
      isOpen &&
      !disabled &&
      !isHovering &&
      defaultRoute === to &&
      !isSelected &&
      !isSubMenuActive
    ) {
      push(`${path}${defaultRoute}`);
    }
  }, [isOpen]);

  return (
    <LinkComponent
      to={useParentPath ? `${path}${to}` : to}
      target={to?.includes("http") ? "_blank" : undefined}
      key={to}
      // @ts-ignore
      className={() =>
        classNames(
          "t-relative t-pr-4 t-flex t-gap-0 t-items-center t-border-solid t-border-0 before:t-content-[''] before:t-w-[3px] before:t-h-full before:t-absolute before:t-left-0 t-transition-all t-no-underline",
          {
            "t-text-text-30 t-pointer-events-none": disabled,
          }
        )
      }
    >
      <LayoutGroup id={"submenu_item"}>
        <AnimatePresence>
          {(open || isHovering) && (
            <div className="t-flex t-flex-row t-items-center t-justify-between t-w-full">
              {isSelected ? (
                <motion.div
                  layoutId="underline"
                  id="underline"
                  className={"t-mb"}
                >
                  <ActiveIndicator />
                </motion.div>
              ) : (
                <div className="t-w-[2px] t-h-2 " />
              )}
              <div
                className={classNames(
                  "t-text-body group-[&]/active-link:t-text-text-100 group-[&]/active-link:t-font-medium  t-whitespace-nowrap t-overflow-hidden t-w-full t-pl-2 t-my-1 t-pr-4  t-py-2 t-rounded-lg t-ml-3 t-text-text-60 group-hover/non-active-link:t-text-text-100 t-flex t-gap-2 t-items-center t-justify-between",
                  {
                    "!t-text-text-30 t-pointer-events-none": disabled,
                    "t-bg-surface-lighter-grey hover:t-bg-neutral-0 hover:before:t-bg-purple-30 t-group/non-active-link":
                      !isSelected,
                    "t-bg-neutral-0 t-text-text-100 t-transition-all t-font-medium hover:before:t-border-l-[1.5px] before:t-bg-purple-50 t-group/active-link":
                      isSelected,
                    "t-justify-center": !open,
                    // "py-2": open,
                    "t-bg-white": Boolean(isHovering && !isSelected),
                  }
                )}
              >
                <span> {children}</span>
                {suffix}
              </div>
            </div>
          )}
        </AnimatePresence>
      </LayoutGroup>
    </LinkComponent>
  );
};

const SubMenuBaseItem = (props: ComponentProps<typeof SubMenuItem>) => {
  const { path } = useRouteMatch();
  const currentPath =
    props.useParentPath === false ? props.to : `${path}${props.to}`;
  return (
    <PermissionBasedUI
      key={props.to}
      side="right"
      route={currentPath.split("?")[0]}
      blockedUI={
        <SubMenuItem disabled={true} suffix={<Padlock />} {...props} />
      }
    >
      <SubMenuItem {...props} />
    </PermissionBasedUI>
  );
};

export const SubMenu = {
  Trigger: SubMenuTrigger,
  Container: SubMenuContainer,
  Item: SubMenuBaseItem,
  Root: SubMenuRoot,
};
