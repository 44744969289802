import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import { ReactNode } from "react";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { parse, stringify } from "qs";
import { Customers as CustomersPage } from "pages/Books/Customers/Customers";
import { InvoiceCustomerInfo } from "pages/Books/Customers/InvoiceCustomerInfo";

const Layout = ({ children }: { children: ReactNode; url: string }) => {
  return (
    <DashboardLayout header={<Header v2 title="Customers" />}>
      {children}
    </DashboardLayout>
  );
};

export const Customers = () => {
  const { path, url } = useRouteMatch();
  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <Switch>
      <Route path={`${path}/:customerId`}>
        <InvoiceCustomerInfo
          breadcrumbs={[
            {
              name: "Customers",
              link: `${url}${search}`,
            },
          ]}
        />
      </Route>

      <Route path={path}>
        <Layout url={url}>
          <CustomersPage />
        </Layout>
      </Route>
    </Switch>
  );
};
