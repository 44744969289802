import classNames from "classnames";
import { BankLogos } from "dictionaries";
import React from "react";

export const PaymentMethodCard = ({
  isDefaultCard,
  brand,
  mask,
  option,
  expiryMonth,
  expiryYear,
}: {
  isDefaultCard: boolean;
  brand: string;
  mask: string;
  option?: React.ReactNode;
  expiryMonth: string;
  expiryYear: string;
}) => {
  const cardBrand = BankLogos.hasOwnProperty(brand)
    ? BankLogos[brand as keyof typeof BankLogos]
    : BankLogos["default"];

  return (
    <div
      className={classNames(
        "cardBackgroundSize t-flex t-h-[147px] t-w-[231px] t-flex-col t-justify-between t-bg-no-repeat t-px-4 t-py-3 t-bg-cover",
        {
          "t-bg-[url('static/images/DefaultCardBG.png')]": isDefaultCard,
          "t-bg-[url('static/images/NormalCardBG.png')]": !isDefaultCard,
        }
      )}
    >
      <div
        className={classNames("t-flex t-items-center", {
          "t-justify-between": isDefaultCard,
          "t-justify-end": !isDefaultCard,
        })}
      >
        {isDefaultCard && (
          <div className="t-h-[18px] t-rounded t-bg-purple-10 t-px-2 t-py-0.5 t-text-caption t-text-purple">
            DEFAULT
          </div>
        )}
        {option}
      </div>
      <div>
        <div
          className={classNames("t-text-subtitle", {
            "t-text-surface": isDefaultCard,
            "t-text-text-100": !isDefaultCard,
          })}
        >
          **** **** **** {mask}
        </div>
        <div
          className={classNames("t-flex t-items-end t-justify-between", {
            "t-text-text-100": !isDefaultCard,
            "t-text-surface": isDefaultCard,
          })}
        >
          <div>
            <div className="t-text-[6px] t-font-medium">Expiry Date</div>
            <div className="t-text-caption">
              {expiryMonth}/{expiryYear}
            </div>
          </div>
          <div>
            <img className="t-h-[22px]" src={cardBrand} alt="cardBrand" />
          </div>
        </div>
      </div>
    </div>
  );
};
