import DashboardContainer from "components/dashboard/DashboardContainer";
import OnboardingHeader from "components/dashboard/OnboardingHeader";
import { usePageTitle } from "hooks/usePageTitle";
import ArchivedUserImg from "static/images/ArchivedUser.svg";

const ArchivedUser = () => {
  usePageTitle("Archived user");

  return (
    <>
      <DashboardContainer className="t-h-screen">
        <DashboardContainer.Header>
          <OnboardingHeader />
        </DashboardContainer.Header>
        <DashboardContainer.Content>
          <div className="t-flex t-h-full t-w-screen t-flex-col t-items-center t-justify-center">
            <div className="t-flex t-max-w-xs t-flex-col t-items-center md:t-max-w-md">
              <img src={ArchivedUserImg} alt="ArchivedUserImg" />
              <div className="t-mt-3 t-text-title-h2-bold">Access Denied</div>
              <div className="t-mb-4 t-text-center t-text-subtext t-text-neutral">
                Request your primary owner to get access again
              </div>
            </div>
          </div>
        </DashboardContainer.Content>
      </DashboardContainer>
    </>
  );
};

export default ArchivedUser;
