export const JACARTA = "#41395C";
export const SHADE_2_MAGNOLIA = "#EEE9FF";
export const ERRIGAL_WHITE = "#f3f3f5";

export const FILE_TAXES = "FILE_TAXES";
export const VIEW_FORUM = "VIEW_FORUM";
export const PARTNER_INKLE = "PARTNER_INKLE";
export const CA_CPA = "CA_CPA";

export const OPTION_CONTENT =
  "Apps that provides you with set of tools to help you with your tax, bookkeeping and compliance related needs.";

export const ACCOUNTANT = "ACCOUNTANT";
export const FOUNDER = "FOUNDER";
export const PARTNER = "PARTNER";
export const OTHERS = "OTHERS";
export const NOT_SPECIFIED = "NOT_SPECIFIED";
export const PREINCORPORATION = "PREINCORPORATION";
export const FOREIGN_CA = "FOREIGN_CA";
export const INVESTOR = "INVESTOR";

export const STATE_ID = "state_id";
export const COUNTRY = "country";

export const BASIC_INFORMATION_SECTION = "Basic Information";
export const UPLOAD_DOCUMENT_SECTION = "Upload Documents";
export const ENTITY_DETAILS_STEP = "ENTITY_DETAILS";
export const US_ENTITY_DETAILS_V2 = "us_entity_details_tax_v2";

export const CREATE_COMPANY_DETAILS = [
  "For Founders and Finance Leads",
  "Delaware C-Corps only",
  "Subscribe to Inkle for $300/year",
];

export const PERK_PROVIDER_DETAILS = [
  "Advertise a product or service for free",
  "Reach hundreds of US startups",
  "Edit perk and track referrals",
];

export const CPA_DETAILS = [
  "Add US tax & accounting to your services",
  "Get new customers for your core services",
  "Add & manage clients through a dashboard",
];

export const TERMS_AND_CONDITIONS = "https://www.inkle.io/terms-conditions";
export const PRIVACY_POLICY = "https://www.inkle.io/privacy-policy";
export const BOOK_A_CALL = "https://calendly.com/d/cmzm-kdn-6mn";
