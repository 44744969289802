import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { FOREIGN_ENTITY_ACCOUNTANT } from "constants/groupRequest";
import { Form, Formik, FormikValues } from "formik";
import { DFTRequestDataSchema } from "formValidations/DFTRequestDataSchema";
import { useToast } from "hooks/useToast";
import React from "react";
import { useConfirmDFTDataMutation } from "store/apis/task";
import { useGetTeamMembersQuery } from "store/apis/teamSetting";
import { Task } from "types/Models/task";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

export const DFTRequestDataModal = ({
  isOpen,
  close,
  task,
}: ModalProps & { task: Task }) => {
  const { alertToast, successToast } = useToast();
  const [confirmDFTData, { isLoading }] = useConfirmDFTDataMutation();
  const groupId = task.group_uuid;

  const {
    data: teamMembers,
    isLoading: teamMemberLoading,
    isSuccess,
  } = useGetTeamMembersQuery({ groupId }, { skip: !groupId });

  const handleSubmit = async (values: FormikValues) => {
    try {
      await confirmDFTData({
        taskId: task.uuid,
        entityId: task.entity.uuid,
        cc_emails: values.cc_emails.join(","),
        to_emails: values.to_emails,
      }).unwrap();
      successToast({ message: "Request data sent successfully" });
      close();
    } catch (error: any) {
      alertToast(
        { message: (error as BackendError)?.data?.error?.message },
        error as {}
      );
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content useCustomOverlay>
        <Modal.Header>
          <Modal.Title>Request Data Confirmation</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          validateOnChange
          enableReinitialize
          validateOnMount={false}
          initialValues={{
            to_emails: [],
            cc_emails: [],
          }}
          onSubmit={handleSubmit}
          validationSchema={DFTRequestDataSchema}
        >
          {({ isSubmitting, submitForm, setFieldValue, values }) => {
            return (
              <Form className="t-m-0">
                <Modal.Body className="t-overflow-auto t-flex t-flex-col t-gap-4">
                  <Combobox
                    placeholder="Select emails"
                    isMulti
                    label="To"
                    required
                    withForm
                    isLoading={teamMemberLoading}
                    name="to_emails"
                    components={{
                      DropdownIndicator: () => null,
                      NoOptionsMessage: () => null,
                    }}
                    options={teamMembers?.group_users
                      ?.filter(
                        ({ role_name }) =>
                          role_name !== FOREIGN_ENTITY_ACCOUNTANT
                      )
                      ?.map(({ email }) => ({
                        label: email,
                        value: email,
                      }))}
                    menuPortalTarget={document.body}
                    minMenuHeight={300}
                    menuPlacement="auto"
                  />
                  <Combobox
                    placeholder="Add emails"
                    formatCreateLabel={(inputText) => `Add ${inputText}`}
                    isMulti
                    label="CC"
                    withForm
                    name="cc_emails"
                    creatable
                    components={{
                      DropdownIndicator: () => null,
                      NoOptionsMessage: () => null,
                    }}
                    onCreateOption={(value) =>
                      setFieldValue("cc_emails", [
                        ...(values.cc_emails || []),
                        value,
                      ])
                    }
                    value={values.cc_emails?.map((i) => ({
                      label: i,
                      value: i,
                    }))}
                    menuPortalTarget={document.body}
                  />
                </Modal.Body>
                <Modal.FooterButtonGroup>
                  <Modal.RawClose asChild>
                    <Button type="button">Cancel</Button>
                  </Modal.RawClose>
                  <Button
                    onClick={submitForm}
                    customType="primary"
                    isLoading={isSubmitting || isLoading}
                    disabled={isSubmitting || isLoading}
                  >
                    Save
                  </Button>
                </Modal.FooterButtonGroup>
              </Form>
            );
          }}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
