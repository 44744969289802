import { ChatLeftNav } from "components/ChatLeftNav/ChatLeftNav";
import { Documents as DocumentsIcon } from "components/icons/LeftNav/Documents";
import { LeftBarItem } from "components/Sidebar/LeftBar";
import { TodoLeftNav } from "components/TodoLeftNav/TodoLeftNav";
import {
  ACTION_ITEMS_CLICKED_FROM_SIDEBAR,
  CHAT_CLICKED_FROM_SIDEBAR,
  DOCUMENTS_CLICKED_FROM_SIDEBAR,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { parse, stringify } from "qs";
import { useLocation } from "react-router-dom";

export const NavAcrossApps = () => {
  const { trackEvent } = useAnalytics();

  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  return (
    <>
      <button
        className="all:unset t-w-full"
        onClick={() => trackEvent(CHAT_CLICKED_FROM_SIDEBAR)}
      >
        <ChatLeftNav />
      </button>
      <button
        className="all:unset t-w-full"
        onClick={() => trackEvent(ACTION_ITEMS_CLICKED_FROM_SIDEBAR)}
      >
        <TodoLeftNav />
      </button>
      <button
        className="all:unset t-w-full"
        onClick={() => trackEvent(DOCUMENTS_CLICKED_FROM_SIDEBAR)}
      >
        <LeftBarItem to={`/documents/${search}`} icon={DocumentsIcon}>
          Documents
        </LeftBarItem>
      </button>
    </>
  );
};
