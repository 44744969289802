import classNames from "classnames";
import { LoadingToast } from "components/design/LoadingToast";
import { Header } from "components/DesignSystem/Header/Header";
import { Search } from "components/DesignSystem/Search/Search";
import { DocumentPreviewModal } from "components/PreviewModal";
import {
  FOLDER_CREATION_FAILED,
  NEW_FOLDER_CREATED,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import {
  documentApi,
  useLazyGetFolderContentQuery,
  useLazyGetSearchContentQuery,
  useRecentDocumentsQuery,
} from "store/apis/documents";
import { useDebounce } from "utils/debounce";
import { debounce } from "utils/debouncing";
import { createNewFolder, moveFileLocation } from "../../apis/documents";
import { profileDataUpdate } from "../../apis/profileData";
import {
  CRM_FOLDER_ROUTE,
  DOCUMENT_VIEW_UPDATE,
  FILE_UPLOADING_MESSAGE,
  NEW_FOLDER_MESSAGE,
} from "../../constants/documents";
import authContext from "../../jwt_context&axios/authContext";
import "../../static/styles/components/documents.css";
import Loader from "../design/loader";
import { DocHeaderActions } from "./docHeaderActions";
import { DocumentContext } from "./documentContext";
import { AddNewFolder } from "./documentsModal/AddNewFolder";
import { DragNDropArea } from "./DragNDropArea";
import FolderAndFileSection from "./folderAndFileSection";
import { NoDocumentItem } from "./NoDocumentItem";
import { DashboardLayout } from "components/DashboardLayout";
import { BreadcrumbDND } from "./FileDnDWrapper";
import { useDispatch } from "react-redux";
import RightClickMenu from "./RightClickMenu";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";

const Documents = ({ appUrl = "" }) => {
  usePageTitle("Documents");
  const { alertToast, successToast } = useToast();
  const { trackEvent } = useAnalytics();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { update } = useUpdateQuery();
  const query = useQuery();
  const searchTerm = query.get("search");
  const { authtoken, updateUser } = useContext(authContext);

  const group = useCurrentGroupContext();
  const groupId = group?.uuid;
  const groupDocId = group?.documents_group_id;

  const { docId } = useParams();
  const docContainers = useRef();
  const [listView, setListView] = useState(authtoken?.is_listview_preferred);
  const [breadcrumbData, setBreadcrumbData] = useState([]);
  const [pageLoading, setpageLoading] = useState(true);
  const [loadingFileUploadstart, setLoadingFileUploadStart] = useState(false);
  const [loadingMultipleFileDelete, setLoadingMultipleFileDelete] =
    useState(false);
  const [movedFolderName, setMovedFolderName] = useState("");
  const [addFolderModalShow, setAddFolderModalShow] = useState(false);
  const [ParentId, setParentId] = useState(docId);
  const [load, setLoad] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [entityFileAndFolder, setEntityFileAndFolder] = useState([]);
  const [uploadPayload, setUploadPayload] = useState([]);
  const [uploadLoad, setUploadLoad] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedRecentDocs, setSelectedRecentDocs] = useState([]);
  const [currentPageNo, setCurrentPageNo] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [recentDocuments, setRecentDocuments] = useState([]);
  const { data: recentDocsData, isLoading: isRecentDocsLoading } =
    useRecentDocumentsQuery({ groupId }, { skip: !groupId });
  const [getSearchContent, { isFetching: isSearchLoading }] =
    useLazyGetSearchContentQuery();
  const searchValue = useDebounce(searchTerm, 500);
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [getListAnyFolderContent] = useLazyGetFolderContentQuery();

  const getSearchQuery = async ({ pageNum }) => {
    try {
      const {
        files = [],
        folders = [],
        current_page,
        total_pages,
      } = await getSearchContent({
        searchTerm: searchValue,
        groupId,
        pageNum,
      }).unwrap();
      setTotalPage(total_pages);

      let updatedMapData = [...folders, ...files].map((ele) => {
        return {
          ...ele,
          isChecked: false,
        };
      });
      if (current_page === 1) {
        setEntityFileAndFolder(updatedMapData);
      } else {
        setEntityFileAndFolder((prevContent) => [
          ...prevContent,
          ...updatedMapData,
        ]);
      }
      setpageLoading(false);
    } catch (error) {}
  };

  useEffect(() => {
    searchValue && groupId && getSearchQuery({ pageNum: currentPageNo });
  }, [searchValue, groupId]);

  const onDrop = (acceptedFiles) => {
    setUploadLoad((prev) => !prev);
    setUploadPayload([...acceptedFiles]);
  };

  const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
  });

  const closeOptions = () => {
    let updatedMapData = entityFileAndFolder.map((ele) => {
      return { ...ele, isChecked: false };
    });
    setEntityFileAndFolder(updatedMapData);
    let updatedRecentDocs = recentDocuments.map((ele) => {
      return { ...ele, isChecked: false };
    });
    setRecentDocuments(updatedRecentDocs);
    setSelectedFiles([]);
    setSelectedRecentDocs([]);
  };

  useEffect(() => {
    setParentId(docId);
  }, [docId]);

  useEffect(() => {
    const updatedRecentData =
      recentDocsData?.recent_documents?.map((ele) => {
        return {
          ...ele,
          isChecked: ele.isChecked || false,
        };
      }) || [];
    setRecentDocuments(updatedRecentData);
  }, [recentDocsData]);

  useEffect(() => {
    if (ParentId !== null && groupId && !searchTerm) {
      getEntityFolders();
    }
  }, [ParentId, load, groupId, searchTerm]);

  const createFolder = async ({ folder_name }) => {
    try {
      await createNewFolder({
        groupId,
        payload: { folder_name, parent_uuid: ParentId },
      });
      trackEvent(NEW_FOLDER_CREATED, {
        parent_uuid: ParentId,
        folder_name: folder_name,
      });
      successToast({ message: NEW_FOLDER_MESSAGE });
      closeAddFolderModal();
      setLoad((prev) => !prev);
    } catch (error) {
      trackEvent(FOLDER_CREATION_FAILED, {
        parent_uuid: ParentId,
        folder_name: folder_name,
        error: error?.response?.data?.error?.message,
      });
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const getEntityFolders = async () => {
    try {
      setpageLoading(true);
      const { data: response } = await getListAnyFolderContent({
        groupId,
        folderId: ParentId,
      });

      let updatedMapData = [...response.subfolders, ...response.files].map(
        (ele) => {
          return {
            ...ele,
            isChecked: false,
          };
        }
      );
      setEntityFileAndFolder(updatedMapData);
      setBreadcrumbData([...response.parents_list]);
      setpageLoading(false);
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const openAddFolderModal = () => {
    setAddFolderModalShow(true);
  };

  const closeAddFolderModal = () => {
    setAddFolderModalShow(false);
  };

  const changeViewType = async () => {
    if (listView) {
      setListView(false);
      updateViewType({
        is_listview_preferred: false,
        operation: DOCUMENT_VIEW_UPDATE,
      });
    } else {
      setListView(true);
      updateViewType({
        is_listview_preferred: true,
        operation: DOCUMENT_VIEW_UPDATE,
      });
    }
  };

  const updateViewType = async (payload) => {
    try {
      const response = await profileDataUpdate(payload);
      updateUser(response);
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    update({ query: "search", value: value || null });
    if (value) {
      setBreadcrumbData([]);
    } else {
      getEntityFolders();
    }
    setCurrentPageNo(1);
    setSelectedFiles([]);
    setSelectedRecentDocs([]);
  };

  const fetchMoreData = debounce(() => {
    if (currentPageNo < totalPage && searchTerm) {
      setCurrentPageNo((page) => page + 1);
      getSearchQuery({ pageNum: currentPageNo + 1 });
    }
  });

  const handleFolderClick = (id) => {
    if (path === CRM_FOLDER_ROUTE) {
      history.push(`/crm/${groupId}/documents/${id}${search}`);
    } else {
      history.push(`${appUrl}/documents/${id}${search}`);
    }
    setParentId(id);
    if (searchTerm) {
      update({ query: "search", value: null });
    }
  };

  const title =
    breadcrumbData.length > 1
      ? breadcrumbData[breadcrumbData.length - 1].name
      : "Documents";

  const moveFileOnDrop = async ({ folderId, fileId }) => {
    try {
      const { data = {} } = await moveFileLocation({
        groupId,
        payload: { destination_folder_id: folderId },
        fileId,
      });
      const { name, parent_name } = data.data;
      successToast({ message: `Moved to ${parent_name}` });

      setNewFolderName(name);
      setMovedFolderName(parent_name);
      if (searchTerm) {
        const {
          files = [],
          folders = [],
          current_page,
        } = await getSearchContent({
          searchTerm,
          groupId,
          pageNum: 1,
        }).unwrap();
        setCurrentPageNo(current_page);

        let updatedMapData = [...folders, ...files].map((ele) => {
          return {
            ...ele,
            isChecked: false,
          };
        });
        setEntityFileAndFolder(updatedMapData);
      } else {
        setLoad((prev) => !prev);
      }
      dispatch(documentApi.util.invalidateTags(["RECENT_DOCUMENT"]));
    } catch (error) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  return (
    <DocumentContext.Provider
      value={{
        fetchMoreData: fetchMoreData,
        selectedFiles: selectedFiles,
        setSelectedFiles: setSelectedFiles,
        setCurrentPageNo: setCurrentPageNo,
        setEntityFileAndFolder: setEntityFileAndFolder,
        isSearchLoading: isSearchLoading,
        handleFolderClick: handleFolderClick,
        closeOptions: closeOptions,
        setRecentDocuments: setRecentDocuments,
        selectedRecentDocs: selectedRecentDocs,
        setSelectedRecentDocs: setSelectedRecentDocs,
        entityFileAndFolder: entityFileAndFolder,
      }}
    >
      <DashboardLayout
        header={
          <div className="t-flex t-flex-col gap-3 t-pt-1">
            <Header
              v2
              title={searchTerm ? "Search Results" : title}
              breadcrumbs={breadcrumbData.map((ele) => ({
                name: (
                  <BreadcrumbDND
                    folderId={ele.uuid}
                    name={ele.name}
                    onDrop={moveFileOnDrop}
                  />
                ),
                link: `${appUrl}/documents/${ele.uuid}`,
              }))}
              bottom={
                <div className="t-flex t-items-center t-gap-4 t-w-full t-py-3">
                  <div className="t-w-1/2">
                    <Search
                      placeholder="Search Documents"
                      onChange={handleSearch}
                      value={searchTerm || ""}
                      block
                    />
                  </div>
                  <div className="t-flex t-gap-2 t-ml-auto">
                    <DocHeaderActions
                      userGroupId={group?.uuid}
                      entityFileAndFolder={entityFileAndFolder}
                      setLoadingFileUploadStart={setLoadingFileUploadStart}
                      groupId={groupId}
                      setLoad={setLoad}
                      openAddFolderModal={openAddFolderModal}
                      ParentId={ParentId}
                      uploadPayload={uploadPayload}
                      setUploadPayload={setUploadPayload}
                      uploadLoad={uploadLoad}
                      setUploadLoad={setUploadLoad}
                      open={open}
                      changeViewType={changeViewType}
                      listView={listView}
                      setNewFolderName={setNewFolderName}
                      setMovedFolderName={setMovedFolderName}
                      groupDocID={groupDocId}
                      setLoadingMultipleFileDelete={
                        setLoadingMultipleFileDelete
                      }
                      recentDocuments={recentDocuments}
                    />
                  </div>
                </div>
              }
            />
          </div>
        }
      >
        <RightClickMenu refetch={() => setLoad((prev) => !prev)}>
          <div
            data-testid="documents-container"
            className="w-100 documentsContainer"
            {...getRootProps()}
            onClick={closeOptions}
          >
            <div ref={docContainers} className="docContainer t-relative">
              {isDragAccept && (
                <DragNDropArea>
                  {breadcrumbData[breadcrumbData.length - 1]?.name}
                </DragNDropArea>
              )}
              <div>
                <input {...getInputProps()} />
                {pageLoading || isRecentDocsLoading ? (
                  <Loader />
                ) : (
                  <>
                    {entityFileAndFolder.length === 0 ? (
                      <NoDocumentItem />
                    ) : (
                      <div
                        className={classNames(
                          "folderAndFileComponent t-h-full",
                          {
                            fileFolderContainer: !listView,
                          }
                        )}
                      >
                        <FolderAndFileSection
                          listView={listView}
                          userGroupId={group?.uuid}
                          entityFileAndFolder={entityFileAndFolder}
                          groupId={groupId}
                          setLoad={setLoad}
                          ParentId={ParentId}
                          setParentId={setParentId}
                          setNewFolderName={setNewFolderName}
                          setMovedFolderName={setMovedFolderName}
                          groupDocID={groupDocId}
                          showRecentDocument={
                            breadcrumbData.length <= 1 &&
                            recentDocuments.length > 0 &&
                            !searchTerm
                          }
                          recentDocuments={recentDocuments}
                        />
                      </div>
                    )}
                  </>
                )}
                {addFolderModalShow && (
                  <AddNewFolder
                    isOpen={addFolderModalShow}
                    close={closeAddFolderModal}
                    createFolder={createFolder}
                  />
                )}
                {loadingFileUploadstart && (
                  <LoadingToast loading={loadingFileUploadstart}>
                    {FILE_UPLOADING_MESSAGE}
                  </LoadingToast>
                )}
                {loadingMultipleFileDelete && (
                  <LoadingToast loading={loadingMultipleFileDelete}>
                    Files have been deleted
                  </LoadingToast>
                )}
              </div>
            </div>
          </div>
        </RightClickMenu>

        <DocumentPreviewModal />
      </DashboardLayout>
    </DocumentContext.Provider>
  );
};

export default Documents;
