import classNames from "classnames";
import { ConditionalLink } from "components/conditionalLink";
import { Divider } from "components/design/Divider";
import ToolTip from "components/design/toolTip";
import Card from "components/DesignSystem/Card/Card";
import { BareInput } from "components/DesignSystem/TextInput/TextInput";
import { FileInput } from "components/FileInput/FileInput";
import { DottedCircle } from "components/icons/DottedCircle";
import { DottedRoundCheck } from "components/icons/DottedRoundCheck";
import { Redirect } from "components/icons/Redirect";
import { SolidRoundCheck } from "components/icons/SolidRoundCheck";
import { InfoSolid } from "components/InfoSolid";
import { LocalFilePreview, Preview } from "components/PreviewModal";
import {
  CHECKLIST_ITEM_SECTION,
  CHECKLIST_ITEM_TYPE,
} from "constants/financialClosing";
import {
  FINANCIAL_CLOSING_CHECKLIST_LABLE,
  FINANCIAL_CLOSING_CHECKLIST_TOOLTIP_TEXT,
  REVIEW_CHANGES_LINK,
} from "dictionaries";
import { useFormikContext } from "formik";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import React, { ButtonHTMLAttributes, ComponentProps } from "react";
import { useLocation } from "react-router-dom";
import {
  ChecklistItem,
  FinancialClosingDetail,
  useUpdateFinancialClosingCheckListMutation,
} from "store/apis/financialClosing";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import { CheckListAccordion } from "./CheckListAccordion";

export const CategorisedIcon = {
  SYSTEM_SUGGESTED: DottedRoundCheck,
  MANUAL_ACCEPTED: SolidRoundCheck,
  NOT_ACCEPTED: DottedCircle,
};

type CheckListUpdatePayload = {
  checklistItemId: string;
  resolved_by_admin?: boolean;
  file_url?: string;
  file_data?: File;
};

export const getStatus = ({
  resolved_by_system,
  resolved_by_admin,
}: {
  resolved_by_system: boolean;
  resolved_by_admin: boolean;
}) => {
  if (resolved_by_admin) {
    return "MANUAL_ACCEPTED";
  } else if (resolved_by_system) {
    return "SYSTEM_SUGGESTED";
  } else {
    return "NOT_ACCEPTED";
  }
};

export const Info = (
  props: Omit<ComponentProps<typeof ToolTip>, "children">
) => {
  return (
    <ToolTip {...props}>
      <span className="t-text-text-30">
        <InfoSolid size="16" color="currentColor" />
      </span>
    </ToolTip>
  );
};

export const ActionItem = ({
  label,
  discription,
  status,
  ...rest
}: {
  label: React.ReactNode;
  discription: string;
  status: keyof typeof CategorisedIcon;
} & ButtonHTMLAttributes<HTMLButtonElement>) => {
  const CategorisationStatusIcon = CategorisedIcon[status];

  const toolTipText = {
    SYSTEM_SUGGESTED: "Auto-completed by system; click to confirm",
    MANUAL_ACCEPTED: "Confirmed",
    NOT_ACCEPTED: "Click to confirm",
  };

  return (
    <button
      {...rest}
      type="button"
      className={classNames(
        "all:unset t-flex t-gap-3 t-p-4 hover:t-bg-surface-lighter-grey t-w-full t-border-b t-border-0 t-border-solid t-border-neutral-0 disabled:t-cursor-not-allowed"
      )}
    >
      <ToolTip text={toolTipText[status]} side="right" align="start">
        <span>
          <CategorisationStatusIcon size="20" />
        </span>
      </ToolTip>

      <div className="t-w-full">
        <div className="t-text-text-60 t-text-subtext-sm">{label}</div>
        <div className="t-text-text-30 t-text-body-sm">{discription}</div>
      </div>
    </button>
  );
};

const FinancialClosingCheckList = ({
  item,
  index,
  onCheckListChange,
  getReportLinks,
}: {
  item: ChecklistItem;
  index: number;
  onCheckListChange: (arg: CheckListUpdatePayload) => Promise<void>;
  getReportLinks: (type: ChecklistItem["type"]) => string;
}) => {
  const { setFieldValue } = useFormikContext<{
    checklist_items: ChecklistItem[];
  }>();

  const previewModal = useModal();

  return (
    <Card.Root>
      <div className="t-flex t-flex-col t-gap-4 t-py-4 t-w-full">
        <div className="t-text-text-60 t-text-subtext-sm t-flex t-justify-between t-w-full">
          <span>
            {FINANCIAL_CLOSING_CHECKLIST_LABLE[item.type]}
            <span className="after:t-font-bold after:t-text-red after:t-content-['_*']" />
          </span>
          <ConditionalLink
            onClick={(e) => {
              e.stopPropagation();
            }}
            to={getReportLinks(item.type)}
            target="_blank"
            className="t-text-text-30 hover:t-text-purple"
          >
            <Redirect size={16} />
          </ConditionalLink>
        </div>
        <FileInput
          label="Add"
          name={`checklist_items[${index}].file_data`}
          file={
            item.file_data
              ? {
                  name: item.file_data.name,
                  file_type: item.file_data.file_type || item.file_data.type,
                  is_previewable: item.file_data.is_previewable,
                  uuid: item.file_data.uuid,
                }
              : undefined
          }
          onDelete={() => {
            onCheckListChange({
              checklistItemId: item.uuid,
              file_data: undefined,
            });
            setFieldValue(`checklist_items[${index}].file_data`, undefined);
          }}
          onFileClick={previewModal.open}
          onDrop={debounce((file) => {
            onCheckListChange({
              checklistItemId: item.uuid,
              file_data: file[0] as File,
            });
            setFieldValue(
              `checklist_items[${index}].file_data`,
              file[0] as File
            );
          })}
        />
        <Divider color="dark-grey">OR</Divider>
        <BareInput
          defaultValue={item.file_url}
          onChange={debounce((e) => {
            const value = e.target.value;
            onCheckListChange({
              checklistItemId: item.uuid,
              file_url: value,
            });
            setFieldValue(
              `checklist_items[${index}].file_url`,
              !item.resolved_by_admin
            );
          })}
          label="Provide a URL to an external document"
          placeholder="Add link"
        />
      </div>
      {item.file_data?.uuid && (
        <Preview
          showModal={previewModal.isOpen}
          closeModal={previewModal.close}
          previewId={item.file_data?.uuid}
        />
      )}

      {!item.file_data?.uuid && previewModal.isOpen && (
        <LocalFilePreview
          isOpen={previewModal.isOpen}
          close={previewModal.close}
          // @ts-ignore
          file={item?.file_data}
        />
      )}
    </Card.Root>
  );
};

export const Checklist = ({
  closingDetails,
}: {
  closingDetails: FinancialClosingDetail;
}) => {
  const { alertToast } = useToast();
  const entityId = useCurrentEntityId();
  const { search } = useLocation();
  const { link } = useConstructInternalLink();
  const { values, setFieldValue } = useFormikContext<{
    checklist_items: ChecklistItem[];
  }>();
  const { checklist_items } = values;
  const { start_date, end_date, uuid: financialClosingId } = closingDetails;

  const reconcilationAccounts = checklist_items.filter(
    ({ section }) => section === CHECKLIST_ITEM_SECTION.RECONCILE_ACCOUNTS
  );

  const verifyCategorisation = checklist_items.filter(
    ({ section }) => section === CHECKLIST_ITEM_SECTION.VERIFY_CATEGORIZATION
  );

  const reviewChanges = checklist_items.filter(
    ({ section }) => section === CHECKLIST_ITEM_SECTION.REVIEW_CHANGES
  );

  const finalizeFinancials = checklist_items.filter(
    ({ section }) => section === CHECKLIST_ITEM_SECTION.FINALIZE_FINANCIALS
  );

  const getIndex = (uuid: string) => {
    return checklist_items.findIndex((item) => item.uuid === uuid);
  };

  const getReportLinks = (type: string) => {
    const REPORT_LINK = {
      [CHECKLIST_ITEM_TYPE.REVIEW_BALANCE_SHEET]: "/books/live-financials",
      [CHECKLIST_ITEM_TYPE.REVIEW_INCOME_STATEMENT]:
        "/books/live-financials/income-statement",
      [CHECKLIST_ITEM_TYPE.REVIEW_CASHFLOW_STATEMENT]:
        "/books/live-financials/cash-flow-statement",
    };

    return link(REPORT_LINK[type as keyof typeof REPORT_LINK], {
      moreQuery: {
        startDate: start_date,
        endDate: end_date,
      },
    });
  };

  const getVerifyCategorisationLink = (type: string) => {
    const VERIFY_CATEGORISATION_LINK = {
      [CHECKLIST_ITEM_TYPE.CATEGORIZATION]: {
        uncategorised: true,
      },
      [CHECKLIST_ITEM_TYPE.REVIEW_PENDING_BANK_TRANSFERS]: {},
      [CHECKLIST_ITEM_TYPE.REVIEW_PENDING_CARD_PAYMENTS]: {},
    };

    return link(`/books/transactions`, {
      moreQuery: {
        startDate: start_date,
        endDate: end_date,
        ...VERIFY_CATEGORISATION_LINK[
          type as keyof typeof VERIFY_CATEGORISATION_LINK
        ],
      },
    });
  };

  const [updateFinancialClosingCheckList] =
    useUpdateFinancialClosingCheckListMutation();

  const onCheckListChange = debounce(
    async ({
      resolved_by_admin,
      checklistItemId,
      file_url,
      file_data,
    }: CheckListUpdatePayload) => {
      try {
        await updateFinancialClosingCheckList({
          entityId: entityId,
          financialClosingId: financialClosingId,
          checklistItemId: checklistItemId,
          payload: {
            admin_resolved: resolved_by_admin,
            file_data: file_data,
            file_url: file_url,
          },
        }).unwrap();
      } catch (error) {
        alertToast(
          {
            message: (error as BackendError).data?.error?.message,
          },
          error as Error
        );
      }
    }
  );

  return (
    <Card.Root>
      <Card.Header>
        <Card.Title>Closing Checklist</Card.Title>
      </Card.Header>
      <Card.Body>
        <CheckListAccordion.Root
          type="multiple"
          defaultValue={[
            CHECKLIST_ITEM_SECTION.RECONCILE_ACCOUNTS,
            CHECKLIST_ITEM_SECTION.VERIFY_CATEGORIZATION,
            CHECKLIST_ITEM_SECTION.REVIEW_CHANGES,
            CHECKLIST_ITEM_SECTION.FINALIZE_FINANCIALS,
          ]}
          className="t-flex t-flex-col t-gap-4"
        >
          <CheckListAccordion.Item
            value={CHECKLIST_ITEM_SECTION.RECONCILE_ACCOUNTS}
          >
            <CheckListAccordion.Trigger>
              Reconcile accounts
              <Info
                text={FINANCIAL_CLOSING_CHECKLIST_TOOLTIP_TEXT.ACCOUNT}
                align="start"
              />
            </CheckListAccordion.Trigger>
            <CheckListAccordion.Content>
              {reconcilationAccounts.map((item) => {
                const index = getIndex(item.uuid);
                let status = getStatus({
                  resolved_by_system: item.resolved_by_system,
                  resolved_by_admin: item.resolved_by_admin,
                }) as keyof typeof CategorisedIcon;

                return (
                  <ActionItem
                    key={item.uuid}
                    status={status}
                    discription={item.checklist_item_status}
                    label={
                      <div className="t-flex t-justify-between">
                        <span className="t-flex t-gap-1">
                          {item.entity_bank_account.name}
                          <span className="t-text-body t-text-start">••••</span>
                          {item.entity_bank_account.mask}
                        </span>

                        <ConditionalLink
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          to={`/books/data-sources/reconciliation/start/${item.entity_bank_account.uuid}${search}`}
                          target="_blank"
                          className="t-text-text-30 hover:t-text-purple"
                        >
                          <Redirect size={16} />
                        </ConditionalLink>
                      </div>
                    }
                    onClick={debounce(() => {
                      onCheckListChange({
                        resolved_by_admin: !item.resolved_by_admin,
                        checklistItemId: item.uuid,
                      });
                      setFieldValue(
                        `checklist_items[${index}].resolved_by_admin`,
                        !item.resolved_by_admin
                      );
                    })}
                  />
                );
              })}
            </CheckListAccordion.Content>
          </CheckListAccordion.Item>

          <CheckListAccordion.Item
            value={CHECKLIST_ITEM_SECTION.VERIFY_CATEGORIZATION}
          >
            <CheckListAccordion.Trigger>
              Verify Categorisation
            </CheckListAccordion.Trigger>
            <CheckListAccordion.Content>
              {verifyCategorisation.map((item) => {
                const index = getIndex(item.uuid);
                let status = getStatus({
                  resolved_by_system: item.resolved_by_system,
                  resolved_by_admin: item.resolved_by_admin,
                }) as keyof typeof CategorisedIcon;

                return (
                  <ActionItem
                    key={item.uuid}
                    status={status}
                    discription={item.checklist_item_status}
                    label={
                      <div className="t-flex t-justify-between">
                        <div className="t-flex t-gap-1">
                          {FINANCIAL_CLOSING_CHECKLIST_LABLE[item.type]}
                          <Info
                            text={
                              FINANCIAL_CLOSING_CHECKLIST_TOOLTIP_TEXT[
                                item.type
                              ]
                            }
                            side="right"
                          />
                        </div>
                        <ConditionalLink
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          to={getVerifyCategorisationLink(item.type)}
                          target="_blank"
                          className="t-text-text-30 hover:t-text-purple"
                        >
                          <Redirect size={16} />
                        </ConditionalLink>
                      </div>
                    }
                    onClick={debounce(() => {
                      onCheckListChange({
                        resolved_by_admin: !item.resolved_by_admin,
                        checklistItemId: item.uuid,
                      });
                      setFieldValue(
                        `checklist_items[${index}].resolved_by_admin`,
                        !item.resolved_by_admin
                      );
                    })}
                  />
                );
              })}
            </CheckListAccordion.Content>
          </CheckListAccordion.Item>

          <CheckListAccordion.Item
            value={CHECKLIST_ITEM_SECTION.REVIEW_CHANGES}
          >
            <CheckListAccordion.Trigger>
              Review Changes
            </CheckListAccordion.Trigger>
            <CheckListAccordion.Content>
              {reviewChanges.map((item) => {
                const index = getIndex(item.uuid);
                let status = getStatus({
                  resolved_by_system: item.resolved_by_system,
                  resolved_by_admin: item.resolved_by_admin,
                }) as keyof typeof CategorisedIcon;

                return (
                  <ActionItem
                    key={item.uuid}
                    status={status}
                    discription={item.checklist_item_status}
                    label={
                      <div className="t-flex t-justify-between">
                        <div className="t-flex t-gap-1">
                          {FINANCIAL_CLOSING_CHECKLIST_LABLE[item.type]}
                          <Info
                            text={
                              FINANCIAL_CLOSING_CHECKLIST_TOOLTIP_TEXT[
                                item.type
                              ]
                            }
                            side="right"
                          />
                        </div>
                        <ConditionalLink
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          to={`${
                            REVIEW_CHANGES_LINK[
                              item.type as keyof typeof REVIEW_CHANGES_LINK
                            ]
                          }${search}`}
                          target="_blank"
                          className="t-text-text-30 hover:t-text-purple"
                        >
                          <Redirect size={16} />
                        </ConditionalLink>
                      </div>
                    }
                    onClick={debounce(() => {
                      onCheckListChange({
                        resolved_by_admin: !item.resolved_by_admin,
                        checklistItemId: item.uuid,
                      });
                      setFieldValue(
                        `checklist_items[${index}].resolved_by_admin`,
                        !item.resolved_by_admin
                      );
                    })}
                  />
                );
              })}
            </CheckListAccordion.Content>
          </CheckListAccordion.Item>

          <CheckListAccordion.Item
            value={CHECKLIST_ITEM_SECTION.FINALIZE_FINANCIALS}
          >
            <CheckListAccordion.Trigger>
              Finalize Financials
              <Info text="Review and add finalized financials" side="right" />
            </CheckListAccordion.Trigger>
            <CheckListAccordion.Content className="t-flex t-flex-col t-gap-4 t-pb-4">
              {finalizeFinancials.map((item) => {
                const index = getIndex(item.uuid);
                return (
                  <FinancialClosingCheckList
                    key={item.uuid}
                    index={index}
                    item={item}
                    getReportLinks={getReportLinks}
                    onCheckListChange={onCheckListChange}
                  />
                );
              })}
            </CheckListAccordion.Content>
          </CheckListAccordion.Item>
        </CheckListAccordion.Root>
      </Card.Body>
    </Card.Root>
  );
};
