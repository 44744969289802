import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useDispatch, useSelector } from "react-redux";
import { useGetRegisteredAgentQuery } from "store/apis/registerAgent";
import { openAddOrEditModal } from "store/slices/registerAgent";
import { RootState } from "store/store";
import { AddEditRA } from "./AddEditRA";
import { RAMap } from "./RAMap";
import { RATable } from "./RATable";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { EntitySelector } from "components/EntitySelector/EntitySelector";
import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";

export const RegisterAgent = () => {
  usePageTitle("Register Agent");
  const dispatch = useDispatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { isLoading, isSuccess } = useGetRegisteredAgentQuery(
    { groupId, entityId },
    { skip: !groupId || !entityId, refetchOnMountOrArgChange: true }
  );
  const { show } = useSelector(
    (state: RootState) => state.registerAgent.addOrEditRAModal
  );

  const onRAAdd = () => {
    dispatch(openAddOrEditModal());
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <DashboardLayout
      header={
        <Header
          v2
          title="Registered Agent"
          right={
            <>
              <EntitySelector />
            </>
          }
        />
      }
    >
      <div className="t-flex t-flex-col t-gap-4">
        <div className="t-flex t-items-center t-justify-between">
          <span className="t-text-title-h2-bold">Overview</span>
          <Button size="small" customType="primary" onClick={onRAAdd}>
            <i className="fas fa-plus t-mr-3" /> Add RA
          </Button>
        </div>
        {isSuccess && (
          <div className="t-flex t-flex-col t-gap-6">
            <RAMap />
            <RATable />
          </div>
        )}
        {show && <AddEditRA />}
      </div>
    </DashboardLayout>
  );
};
