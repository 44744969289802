import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextArea } from "components/DesignSystem/TextArea/TextArea";
import { FileInput } from "components/FileInput/FileInput";
import { DownloadIcon } from "components/icons/Download";
import { CAP_TABLE_TEMPLATE_LINK } from "constants/capTable";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik, FormikHelpers } from "formik";
import { capTableSchema } from "formValidations/capTable";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import ReactCountryFlag from "react-country-flag";
import { useParams } from "react-router-dom";
import { useCreateCapTableMutation } from "store/apis/capTable";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import { openLink } from "utils/openLink";

export type FormValuesType = {
  group_entity_id: string;
  valid_as_on: string;
  note: string;
  document: File | null;
};

const CapTableUpload = () => {
  return (
    <FileInput
      name="document"
      withForm
      label="Upload File"
      required
      accept={{
        "image/*": [".png", ".jpeg", ".jpg", ".webp", ".avif"],
        "application/pdf": [".pdf"],
        "application/vnd.ms-excel": [".xls"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
        "text/csv": [".csv"],
      }}
    />
  );
};

export const UploadCapTable = ({ isOpen, close }: ModalProps) => {
  const group = useCurrentGroup();
  const { entityId } = useParams<{ entityId: string }>();
  const { successToast, alertToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const [createCapTable, { isLoading }] = useCreateCapTableMutation();
  const templateLink = CAP_TABLE_TEMPLATE_LINK;
  const currentEntity = group.entities?.find((e) => e.uuid === entityId);

  const initialValues: FormValuesType = {
    group_entity_id: currentEntity?.uuid!,
    valid_as_on: "",
    note: "",
    document: null,
  };

  const onSubmit = async (
    values: FormValuesType,
    { resetForm }: FormikHelpers<FormValuesType>
  ) => {
    try {
      const formData = new FormData();
      formData.append(
        "valid_as_on",
        dayjs(values.valid_as_on).format(YYYY_MM_DD)
      );
      formData.append("document", values.document!);
      formData.append("note", values.note);
      await createCapTable({ groupId, entityId, payload: formData }).unwrap();
      successToast({ message: "Cap Table created" });
      close();
      resetForm();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close} modal={false}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={capTableSchema}
      >
        {({ submitForm, isSubmitting, setFieldValue }) => (
          <Form className="t-m-0 t-w-full">
            <Modal.Content useCustomOverlay>
              <Modal.Header>
                <Modal.Title>Upload Cap Table</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <div className="t-flex t-flex-col t-cursor-pointer t-gap-4">
                  <CapTableUpload />
                  <Combobox
                    menuPortalTarget={document.body}
                    label="Entity"
                    withForm
                    name="group_entity_id"
                    value={
                      currentEntity
                        ? {
                            label: (
                              <div className="t-flex t-gap-2 t-items-center">
                                <ReactCountryFlag
                                  countryCode={currentEntity.country_code}
                                  svg
                                  className="t-opacity-50"
                                />
                                {currentEntity.name}
                              </div>
                            ),
                            value: currentEntity.uuid,
                          }
                        : null
                    }
                    isDisabled
                  />
                  <Field name="valid_as_on">
                    {({ field }: FieldProps) => {
                      return (
                        <DateInput
                          {...field}
                          required
                          maxDate={new Date()}
                          label="As On"
                          placeholder="DD-MMM-YYYY"
                          portalId="validOnDate"
                          onDateChange={(date) =>
                            setFieldValue("valid_as_on", date)
                          }
                        />
                      );
                    }}
                  </Field>
                  <TextArea
                    label="Note"
                    name="note"
                    placeholder="Add your note here..."
                  />
                </div>
              </Modal.Body>
              <Modal.Footer className="t-flex t-justify-between">
                <Button
                  type="button"
                  customType="ghost"
                  onClick={() => openLink(templateLink)}
                >
                  <div className="t-flex t-gap-1.5 t-items-center">
                    <DownloadIcon color="currentColor" />
                    <span>Inkle Template</span>
                  </div>
                </Button>
                <div className="t-flex t-gap-3">
                  <Button
                    onClick={close}
                    type="button"
                    disabled={isLoading || isSubmitting}
                  >
                    Cancel
                  </Button>
                  <Button
                    customType="primary"
                    onClick={submitForm}
                    isLoading={isLoading || isSubmitting}
                    disabled={isLoading || isSubmitting}
                  >
                    Save
                  </Button>
                </div>
              </Modal.Footer>
            </Modal.Content>
          </Form>
        )}
      </Formik>
    </Modal.Root>
  );
};
