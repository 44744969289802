import DashboardContainer from "components/dashboard/DashboardContainer";
import { Header } from "components/DesignSystem/Header/Header";
import Tab from "components/DesignSystem/Tab/Tab";
import { YourReports } from "components/Reports/YourReports";
import { YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";

import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { usePageTitle } from "hooks/usePageTitle";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useReportsPrefetch } from "store/apis/reports";

export const SavedReport = () => {
  usePageTitle("Saved Reports");
  const entityId = useCurrentEntityId();
  const groupId = useCurrentGroupContext()?.uuid;
  const rRef = useRef(null);
  const { pathname } = useLocation();

  const prefetchLiveReports = useReportsPrefetch("newInteractiveReport");

  useEffect(() => {
    if (entityId) {
      prefetchLiveReports({
        groupId,
        entityId,
        reportType: "INCOME_STATEMENT",
        accountingMethod: "CASH",
        startDate: dayjs().startOf("year").format(YYYY_MM_DD),
        endDate: dayjs().format(YYYY_MM_DD),
        // @ts-ignore
        showNonZeroAccounts: true,
        comparison_report_type: "TOTALS",
      });

      prefetchLiveReports({
        groupId,
        entityId,
        reportType: "CASH_FLOW",
        accountingMethod: "ACCRUAL",
        startDate: dayjs().startOf("year").format(YYYY_MM_DD),
        endDate: dayjs().format(YYYY_MM_DD),
        // @ts-ignore
        showNonZeroAccounts: true,
        comparison_report_type: "TOTALS",
      });
    }
  }, [entityId]);

  return (
    <>
      <Tab.Root asChild defaultValue={pathname} value="MATCH">
        <DashboardContainer ref={rRef} className="t-h-full">
          <DashboardContainer.Header className="t-px-10 t-z-header">
            <Header
              v2
              title={
                <div className="t-flex t-items-center t-gap-2 t-h-full">
                  Saved Reports
                </div>
              }
            />
          </DashboardContainer.Header>
          <DashboardContainer.Content>
            <div className="t-h-full t-w-full">
              <YourReports />
            </div>
          </DashboardContainer.Content>
        </DashboardContainer>
      </Tab.Root>
    </>
  );
};
