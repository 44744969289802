import {
  useGetBankBalancesQuery,
  useGetEntityBanksQuery,
} from "store/apis/bankConnections";
import { pluralize } from "utils/pluralize";
import { BankConnectModal } from "./ChartOfAccounts/BankConnectModal";
import Async from "./DesignSystem/AsyncComponents/Async";
import { Accordion } from "./DesignSystem/Accordion/Accordion";
import { useModal } from "hooks/useModal";
import { useBankConnect } from "hooks/useBankConnect";
import { Button } from "./DesignSystem/Button/Button";
import { PermissionBasedUI } from "./PermissionBasedUI/PermissionBasedUI";
import { BlockedButton } from "./BlockedButton/BlockedButton";
import { CONNECT_BANK_ACCOUNT } from "constants/subscriptionPermissionFeatures";
import { useEffect, useMemo } from "react";
import { useFormikContext } from "formik";
import classNames from "classnames";
import { formatDate } from "utils/formatDate";
import { BANK_ACCOUNT_STATUS } from "constants/dataSource";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { Tag } from "./DesignSystem/Tag/Tag";

export const TaskConnectBank = ({
  groupId,
  entityId,
  openAccordionByDefault = true,
  date,
}: {
  groupId: string;
  entityId: string;
  openAccordionByDefault?: boolean;
  date: string;
}) => {
  const {
    data: banksAccounts,
    isLoading,
    isSuccess,
  } = useGetEntityBanksQuery(
    { entityId, groupId },
    { skip: !entityId || !groupId }
  );

  const {
    data: bankBalances,
    isLoading: isGettingBankBalances,
    isSuccess: isBankBalancesFetched,
  } = useGetBankBalancesQuery({ entityId, date }, { skip: !entityId || !date });
  const { setFieldValue } = useFormikContext();

  const bankAccountBalances = useMemo(() => {
    const balances: { [key: string]: number } = {};
    bankBalances?.forEach((account) => {
      balances[account.account_id] = account.current_balance || 0;
    });
    return balances;
  }, [bankBalances]);

  const bankAllAccountBalances = useMemo(() => {
    const balances: { [key: string]: number } = {};
    bankBalances?.forEach((account) => {
      if (account.bank_brand_name) {
        balances[account.bank_brand_name] =
          (balances[account.bank_brand_name] || 0) +
          (account.current_balance || 0);
      }
    });
    return balances;
  }, [bankBalances]);

  const totalBalance = useMemo(() => {
    return Object.values(bankAccountBalances).reduce(
      (sum, balance) => sum + balance,
      0
    );
  }, [bankAccountBalances]);

  useEffect(() => {
    setFieldValue("admin_calculated_total_cash_balance", `$ ${totalBalance}`);
  }, [totalBalance, setFieldValue]);

  const accounts = banksAccounts?.accounts || [];

  const banksAccountsGroupByBank = accounts.reduce(
    (acc: { [key: string]: typeof accounts }, curr) => {
      const bankName = curr.bank_brand.name;
      if (!acc[bankName]) {
        acc[bankName] = [];
      }
      acc[bankName].push(curr);
      return acc;
    },
    {}
  );

  const connectBank = useModal();

  const { onConnect } = useBankConnect();

  return (
    <Async.Root
      {...{
        isLoading: isLoading || isGettingBankBalances,
        isSuccess: isSuccess && isBankBalancesFetched,
        isEmpty: banksAccounts?.accounts?.length === 0,
      }}
    >
      <Async.Empty>
        <PermissionBasedUI
          align="start"
          feature={CONNECT_BANK_ACCOUNT}
          blockedUI={
            <BlockedButton size="small">Connect account</BlockedButton>
          }
        >
          <Button
            type="button"
            onClick={connectBank.open}
            customType="primary-outlined"
            size="small"
          >
            Connect account
          </Button>
        </PermissionBasedUI>
      </Async.Empty>
      <Async.Success>
        <Accordion.Root
          defaultValue={
            openAccordionByDefault ? Object.keys(banksAccountsGroupByBank) : []
          }
          type="multiple"
          className="t-flex t-flex-col t-gap-4 t-w-full"
        >
          {Object.entries(banksAccountsGroupByBank).map(([name, accounts]) => {
            const totalBankBalance = bankAllAccountBalances[name] || 0;

            const totalBankDisconnected = accounts.filter(
              (account) => !account.is_bank_connected
            ).length;
            const totalBankConnected = accounts.filter(
              (account) => account.is_bank_connected
            ).length;

            return (
              <Accordion.Item value={name} key={name}>
                <Accordion.Trigger>
                  <div className="t-flex t-justify-between t-items-center t-w-full">
                    <div className="t-flex t-flex-col t-gap-1">
                      <div className="t-text-text-60 t-text-subtext-sm">
                        {name}
                      </div>
                      {totalBankConnected > 0 && (
                        <div className="t-text-dark_green t-text-body-sm t-lowercase">
                          {pluralize(
                            totalBankConnected,
                            "account connected",
                            "accounts connected"
                          )}
                        </div>
                      )}
                      {totalBankDisconnected > 0 && (
                        <div className="t-text-red-70 t-text-body-sm t-lowercase">
                          {pluralize(
                            accounts.length,
                            "account disconnected",
                            "accounts disconnected"
                          )}
                        </div>
                      )}
                    </div>
                    <div className="t-flex t-gap-4 t-mr-3 t-items-center t-normal-case">
                      <div className="t-flex t-flex-col t-items-end t-gap-1.5">
                        <div className="t-text-text-60 t-text-subtext-sm">
                          {totalBankBalance
                            ? `$${totalBankBalance}`
                            : "Data not found"}
                        </div>
                        <div className="t-text-text-30 t-text-body-sm">
                          as of {formatDate(date)}
                        </div>
                      </div>
                      <div className="t-text-neutral-10">|</div>
                    </div>
                  </div>
                </Accordion.Trigger>
                <Accordion.Content>
                  <div className="t-flex t-flex-col t-gap-3">
                    {accounts.map(({ account, is_bank_connected }) => {
                      const accountBalance =
                        bankAccountBalances[account.uuid] || 0;
                      return (
                        <div
                          className="t-w-full t-flex t-justify-between t-items-center"
                          key={account.uuid}
                        >
                          <div className="t-space-y-1">
                            <div
                              className={classNames(
                                "t-text-body-sm t-flex t-gap-1",
                                {
                                  "t-text-red-70": !is_bank_connected,
                                  "t-text-text-60": is_bank_connected,
                                }
                              )}
                            >
                              {account.nickname}{" "}
                              {account.status === BANK_ACCOUNT_STATUS.CLOSED &&
                                account.closed_on && (
                                  <Tag tagType="gray" rounded icon={false}>
                                    Closed as of{" "}
                                    {dayjs(account.closed_on).format(
                                      DD_MMM_YYYY
                                    )}
                                  </Tag>
                                )}
                            </div>
                            <div className="t-text-text-30 t-text-body-sm">
                              •••• {account.mask}
                            </div>
                          </div>
                          {Boolean(accountBalance) && (
                            <div className="t-text-text-60 t-text-subtext-sm">
                              ${accountBalance}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </Accordion.Content>
              </Accordion.Item>
            );
          })}
          <div>
            <PermissionBasedUI
              align="start"
              feature={CONNECT_BANK_ACCOUNT}
              blockedUI={
                <BlockedButton size="small" type="button">
                  Connect more
                </BlockedButton>
              }
            >
              <Button
                onClick={connectBank.open}
                customType="primary-outlined"
                size="small"
                type="button"
              >
                Connect more
              </Button>
            </PermissionBasedUI>
          </div>
        </Accordion.Root>
      </Async.Success>
      <BankConnectModal
        isOpen={connectBank.isOpen}
        close={connectBank.close}
        onConnect={onConnect}
        entityIdFromParent={entityId}
      />
    </Async.Root>
  );
};
