import { DeleteModal } from "components/design/deleteModal";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import Modal from "components/DesignSystem/Modal/Modal";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import ThreeDots from "static/images/ThreeDots.svg";
import {
  useDeleteVendorMutation,
  useEditVendorsMutation,
} from "store/apis/vendors";
import { MarkAsNotRequired } from "./MarkAsNotRequired";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { MarkAsRequired } from "./MarkAsRequired";
import { EditVendor } from "./EditVendor";
import { Vendor } from "types/Models/vendors";

export const MerchantTableDropdown = ({
  openMarkAsVendor,
  merchantId,
  groupId,
  isManualMerchant,
  isVendor,
  showTransactions,
  expandedRow,
  vendorName,
  season,
  form1099Status,
  vendor,
}: {
  openMarkAsVendor: () => void;
  merchantId: string;
  groupId: string;
  isManualMerchant: boolean;
  isVendor: boolean;
  showTransactions: (vendorName: string | null) => void;
  expandedRow: string;
  vendorName: string;
  season: string;
  form1099Status: "NOT_STARTED" | "NOT_REQUIRED" | "PENDING" | "COMPLETED";
  vendor: Vendor;
}) => {
  const { isCustomer, isCpa } = useRoleBasedView();
  const [deleteMerchant, { isLoading: isDeleting }] = useDeleteVendorMutation();
  const { alertToast, successToast } = useToast();
  const markAsNotRequiredModal = useModal();
  const markAsRequiredModal = useModal();
  const deleteModal = useModal();
  const notRequiredModal = useModal();
  const editVendorModal = useModal();

  const [editVendor, { isLoading: isEditing }] = useEditVendorsMutation();

  const markVendorFormNotRequired = async () => {
    try {
      await editVendor({
        groupId,
        uuid: merchantId,
        payload: { merchant_type: "NOT_REQUIRED", season },
      }).unwrap();
      notRequiredModal.close();
      successToast({ message: "Vendor has been updated" });
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const deleteVendor = async () => {
    try {
      await deleteMerchant({ merchantId, groupId }).unwrap();
      successToast({ message: "Vendor deleted successfully" });
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const isForm1099Completed = form1099Status === "COMPLETED";
  const allowRequireTaxDocument = !isVendor && isCpa && !isForm1099Completed;
  const allowMark1099NotRequired =
    isCpa && form1099Status === "NOT_STARTED" && !isForm1099Completed;
  const allowSkipTaxDocument = isVendor && isCpa && !isForm1099Completed;
  const allowDeleteVendor =
    !isCustomer && isManualMerchant && !isForm1099Completed;
  const allowMark1099Required = isCpa && form1099Status === "NOT_REQUIRED";

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <Button type="button" customType="ghost_icon">
            <img src={ThreeDots} alt="Three dots" />
          </Button>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content align="end">
            <Dropdown.Item onClick={editVendorModal.open}>
              Edit vendor
            </Dropdown.Item>
            {allowRequireTaxDocument && (
              <ConditionalToolTip
                condition={
                  !season && "Filter by a season to enable this action."
                }
                side="left"
              >
                <Dropdown.Item onClick={openMarkAsVendor} disabled={!season}>
                  Mark W-Form required
                </Dropdown.Item>
              </ConditionalToolTip>
            )}

            {allowMark1099NotRequired && (
              <ConditionalToolTip
                condition={
                  !season && "Filter by a season to enable this action."
                }
                side="left"
              >
                <Dropdown.Item
                  onClick={markAsNotRequiredModal.open}
                  disabled={!season}
                >
                  Mark 1099 not required
                </Dropdown.Item>
              </ConditionalToolTip>
            )}

            {allowMark1099Required && (
              <ConditionalToolTip
                condition={
                  !season && "Filter by a season to enable this action."
                }
                side="left"
              >
                <Dropdown.Item
                  onClick={markAsRequiredModal.open}
                  disabled={!season}
                >
                  Mark 1099 required
                </Dropdown.Item>
              </ConditionalToolTip>
            )}

            {allowSkipTaxDocument && (
              <ConditionalToolTip
                condition={
                  !season && "Filter by a season to enable this action."
                }
                side="left"
              >
                <Dropdown.Item
                  onClick={notRequiredModal.open}
                  disabled={!season}
                >
                  Mark W-Form not required
                </Dropdown.Item>
              </ConditionalToolTip>
            )}

            {allowDeleteVendor && (
              <Dropdown.Item onClick={deleteModal.open}>
                Delete vendor
              </Dropdown.Item>
            )}
            {vendorName !== expandedRow ? (
              <Dropdown.Item onClick={() => showTransactions(vendorName)}>
                Show transactions
              </Dropdown.Item>
            ) : (
              <Dropdown.Item onClick={() => showTransactions(null)}>
                Hide transactions
              </Dropdown.Item>
            )}
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>
      <DeleteModal
        show={deleteModal.isOpen}
        closeModal={deleteModal.close}
        onClick={deleteVendor}
        text="Delete vendor"
        body={
          "Are you sure you want to delete this vendor? This action can not be undone."
        }
        isLoading={isDeleting}
      />

      <Modal.Root
        open={notRequiredModal.isOpen}
        onOpenChange={notRequiredModal.close}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title> Mark W-Form not required</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <div className="t-text-body-lg t-text-text-100">
              Are you sure you want to Mark W-Form not required collection for
              this vendor?
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="t-flex t-justify-end t-gap-3">
              <Modal.RawClose asChild>
                <Button>Cancel</Button>
              </Modal.RawClose>
              <Button
                customType="primary"
                isLoading={isEditing}
                disabled={isEditing}
                onClick={markVendorFormNotRequired}
              >
                Confirm
              </Button>
            </div>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>

      <MarkAsNotRequired
        {...markAsNotRequiredModal}
        merchantId={merchantId}
        groupId={groupId}
        season={season}
      />
      <MarkAsRequired
        {...markAsRequiredModal}
        merchantId={merchantId}
        groupId={groupId}
        season={season}
      />
      <EditVendor groupId={groupId} vendor={vendor} {...editVendorModal} />
    </div>
  );
};
