export const ActiveIndicator = () => (
  <svg
    width="2"
    height="16"
    viewBox="0 0 2 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0C1.10457 0 2 0.895431 2 2V14C2 15.1046 1.10457 16 0 16V0Z"
      fill="#784EFF"
    />
  </svg>
);
