import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { useAuth } from "hooks/useAuth";

export const ExportOptions = ({
  onLocalDownload,
  onMailExport,
}: {
  onLocalDownload: () => void;
  onMailExport: () => void;
}) => {
  const { email } = useAuth();

  return (
    <>
      <Dropdown.Item onSelect={onMailExport}>
        <div>
          Export to mail
          <div className="t-text-body-sm t-text-text-30">
            Export will be mailed to <br /> {email} once done
          </div>
        </div>
      </Dropdown.Item>
      <Dropdown.Item onSelect={onLocalDownload}>
        <div>
          Download locally
          <div className="t-text-body-sm t-text-text-30">
            This may take upto a minute
          </div>
        </div>
      </Dropdown.Item>
    </>
  );
};
