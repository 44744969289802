import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Button } from "components/DesignSystem/Button/Button";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { ReactNode } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";
import BlackArrowLeft from "static/images/BlackArrowLeft.svg";
import { useGetParticularAutofillQuery } from "store/apis/autofill";
import { useCreateTransferMutation } from "store/apis/transferPricing";
import { setTPPaymentId } from "store/slices/transferpricing";
import { RootState } from "store/store";
import { formatDate } from "utils/formatDate";

const AddressItem = ({
  title,
  body,
}: {
  title: string;
  body: string | ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-1.5">
      <div className="t-text-body-sm t-text-text-30">{title}</div>
      <div className="t-w-[260px] t-text-body">{body}</div>
    </div>
  );
};

const Details = ({ children }: { children: ReactNode }) => {
  return (
    <div className="t-rounded-lg t-border t-border-solid t-border-neutral-0 t-p-4 t-flex t-flex-col t-gap-4 t-h-[240px]">
      {children}
    </div>
  );
};

export const ReviewStep = ({
  setCurrentStep,
}: {
  setCurrentStep: (v: number) => void;
}) => {
  const { alertToast } = useToast();
  const { uuid: groupId, entities } = useCurrentGroupContext();
  const entityId = entities[0].uuid;
  const { amount, currency, purpose_code, accountInfo } = useSelector(
    (state: RootState) => state.tpPaymentsSlice
  );

  const dispatch = useDispatch();
  const [createTPTransfer, { isLoading: isCreateTransferLoading }] =
    useCreateTransferMutation();

  const date = formatDate(new Date());

  const { data: senderAddress } = useGetParticularAutofillQuery({
    groupId,
    entityId,
    uuid: accountInfo?.sender_address_uuid || "",
    autofillKey: "addresses",
  });

  const { data: receiverAddress } = useGetParticularAutofillQuery({
    groupId,
    entityId,
    uuid: accountInfo?.receiver_address_uuid || "",
    autofillKey: "addresses",
  });

  const createTransfer = async () => {
    try {
      const resp = await createTPTransfer({
        groupId,
        payLoad: { amount, currency, purpose_code_uuid: purpose_code.uuid },
      }).unwrap();
      dispatch(setTPPaymentId(resp.uuid));
      setCurrentStep(4);
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  return (
    <div>
      <div className="t-pb-8 t-text-title-h2-bold">
        Review the details of your transfer
      </div>
      <div className="t-flex t-gap-5 t-pb-6 t-items-center">
        <Details>
          <div>
            <div className="t-flex t-items-center t-gap-1">
              <ReactCountryFlag
                countryCode="US"
                svg
                style={{
                  fontSize: "1rem",
                  height: "100%",
                  borderRadius: "50%",
                }}
                title="US"
              />
              <div className="t-text-body t-text-text-30">Sender</div>
            </div>
            <div className="t-text-subtitle-sm">
              {accountInfo?.sender?.name}
            </div>
          </div>
          <AddressItem
            title="Legal address"
            //@ts-ignore
            body={senderAddress?.autofill_string || ""}
          />
        </Details>
        <div className="t-flex t-rotate-180 t-justify-center t-w-6 t-h-6">
          <img src={BlackArrowLeft} alt="to" />
        </div>
        <Details>
          <div>
            <div className="t-flex t-items-center t-gap-1">
              <ReactCountryFlag
                countryCode="IN"
                svg
                style={{
                  fontSize: "1rem",
                  height: "100%",
                  borderRadius: "50%",
                }}
                title="IN"
              />
              <div className="t-text-body t-text-text-30">Receiver</div>
            </div>
            <div className="t-text-subtitle-sm">
              {accountInfo?.receiver?.name}
            </div>
          </div>
          <AddressItem
            title="Legal address"
            //@ts-ignore
            body={receiverAddress?.autofill_string || ""}
          />
          <AddressItem
            title="Ultimate Recipient Bank"
            body={`${accountInfo?.receiver_bank_account?.domestic_credit?.slice(
              0,
              4
            )} - 
            ${accountInfo?.receiver_bank_account?.account_number.slice(-4)}`}
          />
        </Details>
      </div>
      <Details>
        <div className="t-flex t-flex-col t-gap-2">
          <div className="t-text-body-sm t-text-text-30">Date</div>
          <div className="t-text-subtext">{date}</div>
        </div>
        <div className="t-flex t-flex-col t-gap-2">
          <div className="t-text-body-sm t-text-text-30">Purpose code</div>
          <div className="t-text-subtext">
            {purpose_code?.code} {purpose_code?.description}
          </div>
        </div>
        <div className="t-flex t-flex-col t-gap-2">
          <div className="t-text-body-sm t-text-text-30">Amount</div>
          <div className="t-text-title-h2-bold">
            <AmountSuperScript amount={amount} currencyCode="USD" />
          </div>
        </div>
      </Details>
      <div className="t-flex t-justify-end t-gap-3 t-pt-4">
        <Button onClick={() => setCurrentStep(2)}>Back</Button>
        <Button
          isLoading={isCreateTransferLoading}
          disabled={isCreateTransferLoading}
          customType="primary"
          onClick={createTransfer}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};
