import Async from "components/DesignSystem/AsyncComponents/Async";
import { KeyDownWrapper } from "components/DesignSystem/KeydownWrapper/KeydownWrapper";
import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { useEffect, useRef, useState } from "react";
import {
  useGetAllCompliancesQuery,
  useGetComplianceOverviewQuery,
} from "store/apis/calendar";
import { CurrentDeadlines } from "./Deadlines/CurrentDeadlines";
import { PastDeadlines } from "./Deadlines/PastDeadlines";

export const NewCompliance = () => {
  const [silderAccordionValue, setSilderAccordionValue] =
    useState<string>("CURRENT");
  const currentDeadlineRef = useRef<HTMLSpanElement>(null);
  const pastDeadlineRef = useRef<HTMLSpanElement>(null);
  const entityId = useCurrentEntityId();

  const {
    data,
    isLoading: isGetting,
    isSuccess: isOverviewSuccess,
  } = useGetComplianceOverviewQuery(
    {
      entityId,
    },
    { skip: !entityId, refetchOnMountOrArgChange: true }
  );

  const {
    data: deadlines,
    isLoading,
    isSuccess,
  } = useGetAllCompliancesQuery(
    {
      entityId,
    },
    { skip: !entityId, refetchOnMountOrArgChange: true }
  );

  const pastDeadlineCount = deadlines?.historical_alarms.length;
  const currentDeadlineCount = deadlines?.current_alarms.length;

  useEffect(() => {
    if (
      (!pastDeadlineCount || pastDeadlineCount === 0) &&
      (!currentDeadlineCount || currentDeadlineCount === 0) &&
      isSuccess
    ) {
      setSilderAccordionValue("");
    }
  }, [pastDeadlineCount, currentDeadlineCount, isSuccess]);

  useEffect(() => {
    if (silderAccordionValue === "CURRENT") {
      currentDeadlineRef?.current?.scrollIntoView({ behavior: "smooth" });
    }

    if (silderAccordionValue === "PAST") {
      pastDeadlineRef?.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [silderAccordionValue]);

  return (
    <Async.Root
      {...{
        isEmpty: Boolean(
          deadlines &&
            deadlines.current_alarms.length === 0 &&
            deadlines.historical_alarms.length === 0 &&
            !data
        ),
        isLoading: isLoading || isGetting,
        isSuccess: isSuccess || isOverviewSuccess,
      }}
    >
      <Async.Empty>
        <EmptyScreen text="No data available" />
      </Async.Empty>
      <Async.Success>
        <div className="t-flex t-gap-4 t-flex-col t-pb-24">
          <Accordion.Root
            type="single"
            collapsible
            value={silderAccordionValue}
            onValueChange={setSilderAccordionValue}
            className="t-flex t-gap-4 t-flex-col"
          >
            <Accordion.Item value="CURRENT">
              <Accordion.Trigger
                disabled={Boolean(
                  !currentDeadlineCount || currentDeadlineCount === 0
                )}
              >
                <div>
                  Current Compliances{" "}
                  {Boolean(
                    currentDeadlineCount && currentDeadlineCount > 0
                  ) && (
                    <span className="t-text-text-30 t-body-sm">
                      ({currentDeadlineCount} filings)
                    </span>
                  )}
                </div>
              </Accordion.Trigger>
              <Accordion.Content>
                <span ref={currentDeadlineRef}>
                  {deadlines?.current_alarms && (
                    <CurrentDeadlines deadlines={deadlines.current_alarms} />
                  )}
                </span>
              </Accordion.Content>
            </Accordion.Item>
            <Accordion.Item value="PAST">
              <Accordion.Trigger
                disabled={Boolean(
                  !pastDeadlineCount || pastDeadlineCount === 0
                )}
              >
                <div>
                  Historical Compliances{" "}
                  {Boolean(pastDeadlineCount && pastDeadlineCount > 0) && (
                    <span className="t-text-text-30 t-body-sm">
                      ({pastDeadlineCount} filings)
                    </span>
                  )}
                </div>
              </Accordion.Trigger>
              <Accordion.Content>
                <span ref={pastDeadlineRef}>
                  <KeyDownWrapper>
                    {deadlines?.historical_alarms && (
                      <PastDeadlines deadlines={deadlines?.historical_alarms} />
                    )}
                  </KeyDownWrapper>
                </span>
              </Accordion.Content>
            </Accordion.Item>
          </Accordion.Root>
        </div>
      </Async.Success>
    </Async.Root>
  );
};
