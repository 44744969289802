import classNames from "classnames";
import { Stepper } from "components/DesignSystem/Stepper/Stepper";
import { ArrowRight } from "components/icons/ArrowRight";

export const AddAnnualCatchupStepper = ({
  currentStep,
  setCurrentStep,
}: {
  currentStep: number;
  setCurrentStep: (step: number) => void;
}) => {
  const breadcrumbs = [
    { name: "Connect banks", step: 1, index: 1 },
    { name: "Share bookkeeping access", step: 2, index: 2 },
    { name: "Checkout", step: 3, index: 3 },
  ];

  return (
    <Stepper size="xs" direction="horizontal">
      {breadcrumbs?.map(({ name, step }) => (
        <Stepper.Step
          step={step}
          key={step}
          isActive={currentStep == step}
          clickable={currentStep == step}
          onClick={() => setCurrentStep(step)}
        >
          <div
            className={classNames("t-flex t-items-center t-text-body", {
              "t-text-text-30": currentStep !== step,
              "t-text-purple": currentStep === step,
            })}
          >
            {name}
            {step !== breadcrumbs.length && <ArrowRight color="currentColor" />}
          </div>
        </Stepper.Step>
      ))}
    </Stepper>
  );
};
