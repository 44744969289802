import { ConditionalLink } from "components/conditionalLink";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { Button } from "components/DesignSystem/Button/Button";
import { Search } from "components/DesignSystem/Search/Search";
import { Switch } from "components/DesignSystem/Switch/Switch";
import { Icon1099 } from "components/icons/Icon1099";
import { Wform } from "components/icons/Wform";
import { AddVendor } from "components/Vendors/AddVendor/AddVendor";
import { MerchantsPanel } from "components/Vendors/merchantsPanel";
import { VendorsFilter } from "components/Vendors/VendorsFilter";
import { CURRENT_SEASON, SOURCE, VENDOR_TYPE } from "constants/vendor";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { usePageTitle } from "hooks/usePageTitle";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { useDispatch } from "react-redux";
import ArrowsClockwise from "static/images/ArrowsClockwise.svg";
import { useLazyGetVendorsQuery, vendorsApi } from "store/apis/vendors";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";

export type VendorFilterType = {
  SEARCH_TERM: string | null;
  SEASON: string | null;
  SOURCE: keyof typeof SOURCE | null;
  VENDOR_TYPE: keyof typeof VENDOR_TYPE | null;
  SHOW_ESTIMATED_1099: boolean;
};

export type FilterProps = {
  values: VendorFilterType;
  updateFilter: <S extends keyof VendorFilterType>(
    name: S,
    newValue: VendorFilterType[S]
  ) => void;
};

const InfoBanner = () => {
  return (
    <div className="t-rounded-lg t-p-4 t-w-full t-shadow-[0px_1px_8px_-1px_rgba(31,12,92,0.04)] t-border-solid t-border-neutral-0 t-border">
      <div className="t-flex t-gap-6">
        <div className="t-flex t-gap-4 t-items-center t-flex-1">
          <div className="t-shrink-0">
            <Wform />
          </div>
          <p className="t-m-0 t-text-body-sm t-text-text-30">
            A tax document used in the U.S. for reporting income earned by U.S.
            residents or foreign entities.{" "}
            <ConditionalLink
              to="https://www.inkle.io/blog/irs-form-1099#step-by-step-instructions-for-completing-form-w-9"
              className="t-text-text-30 t-font-bold !t-underline"
            >
              Know more
            </ConditionalLink>
          </p>
        </div>

        <div className="t-border-0 t-border-solid t-border-l t-border-neutral-0" />

        <div className="t-flex t-gap-4 t-items-center t-flex-1">
          <div className="t-shrink-0">
            <Icon1099 />
          </div>
          <p className="t-m-0 t-text-body-sm t-text-text-30">
            Form 1099 is an essential tax document that reports non-employment
            income to the Internal Revenue Service (IRS).{" "}
            <ConditionalLink
              to="https://www.inkle.io/blog/irs-form-1099"
              className="t-text-text-30 t-font-bold !t-underline"
            >
              Know more
            </ConditionalLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export const Vendors = () => {
  usePageTitle("Vendors");
  const { uuid: groupId } = useCurrentGroupContext();
  const dispatch = useDispatch();
  const query = useQuery();
  const page = Number(query.get("page")) || 1;
  const { update } = useUpdateQuery();
  const { alertToast, successToast } = useToast();
  let entityId = useCurrentEntityId();
  const [getVendors, { isFetching }] = useLazyGetVendorsQuery();

  const handleChange = debounce(() => {
    update({ query: "page", value: 1 });
  });

  const { values, updateFilter } = useFilters<VendorFilterType>({
    initialValue: {
      SEARCH_TERM: null,
      SEASON: null,
      SOURCE: null,
      VENDOR_TYPE: null,
      SHOW_ESTIMATED_1099: false,
    },
    useQueryParams: true,
  });

  const refreshMerchants = async () => {
    try {
      const queries = {
        groupId: groupId,
        page_num: page,
        type: values.VENDOR_TYPE,
        search_term: values.SEARCH_TERM,
        season: values.SEASON,
        merchant_source: values.SOURCE,
        entityId,
        refresh_merchants: true,
      };
      const data = await getVendors(queries).unwrap();
      successToast({
        message: "Vendors refreshed successfully",
      });

      dispatch(
        // @ts-ignore
        vendorsApi.util.updateQueryData(
          "getVendors",
          { ...queries, refresh_merchants: false },
          (draft) => {
            return data;
          }
        )
      );
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as Error
      );
    }
  };

  const onShowEstimated1099 = () => {
    updateFilter("SHOW_ESTIMATED_1099", !values.SHOW_ESTIMATED_1099);
    if (!values.SEASON) {
      updateFilter("SEASON", CURRENT_SEASON);
    }
    updateFilter("VENDOR_TYPE", null);
  };

  return (
    <DashboardContainer>
      <DashboardContainer.Header>
        <div className="t-flex t-gap-4 t-flex-col">
          <InfoBanner />
          <div className="t-flex t-gap-4 t-w-full t-items-center">
            <div className="t-w-2/6">
              <Search
                placeholder="Search..."
                block
                onChange={(e) => {
                  updateFilter("SEARCH_TERM", e.target.value || null);
                  handleChange();
                }}
              />
            </div>
            <div className="t-flex t-gap-2 t-ml-auto">
              <Button size="small" onClick={onShowEstimated1099}>
                <div className="t-flex t-gap-1.5 t-items-center">
                  Show estimated 1099 totals & filings
                  <Switch size="small" checked={values.SHOW_ESTIMATED_1099} />
                </div>
              </Button>
              <Button
                size="small"
                customType="icon"
                isLoading={isFetching}
                disabled={isFetching}
                onClick={refreshMerchants}
              >
                <img src={ArrowsClockwise} alt="ArrowsClockwise" />
              </Button>
              <AddVendor entityId={entityId} />
            </div>
          </div>
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content className="t-relative t-flex t-flex-col t-gap-5 t-mt-5">
        <MerchantsPanel
          vendorFilter={
            <VendorsFilter updateFilter={updateFilter} values={values} />
          }
          filters={values}
        />
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
