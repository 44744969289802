import DashboardContainer from "components/dashboard/DashboardContainer";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { EmptyInvoiceList } from "components/Illustrations/EmptyInvoiceList";
import { InvoiceConsole } from "components/InvoiceList/InvoiceList";
import { AddRequestAndBill } from "components/VendorBills/AddRequestAndBill/AddRequestAndBill";
import { BillList } from "components/VendorBills/BillList";
import { FINANCIAL_RECORD_TYPE } from "constants/vendorBills";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { usePagination } from "hooks/usePagination";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import React from "react";
import { useGetAllBillsQuery } from "store/apis/vendorBills";
import { useDebounce } from "utils/debounce";

type VendorBillsProps = {
  entityId: string;
  canRequestAndAddBill?: boolean;
  isSearchable?: boolean;
  merchantId?: string;
};

export const VendorBills = ({
  entityId,
  canRequestAndAddBill = true,
  isSearchable = true,
  merchantId,
}: VendorBillsProps) => {
  const { uuid: groupId } = useCurrentGroupContext();
  const { values, updateFilter } = useFilters<{
    SEARCH: string | null;
    BILL_TYPE: keyof typeof FINANCIAL_RECORD_TYPE;
    PAGE_NUM: number;
  }>({
    initialValue: {
      SEARCH: null,
      BILL_TYPE: FINANCIAL_RECORD_TYPE.BILL,
      PAGE_NUM: 1,
    },
    useQueryParams: true,
  });

  const pageNum = useDebounce(values.PAGE_NUM);
  const search = useDebounce(values.SEARCH);

  const { data, isSuccess, isFetching } = useGetAllBillsQuery(
    {
      bill_type: values.BILL_TYPE,
      entityId,
      groupId,
      page_num: pageNum,
      search_term: search,
      merchant_id: merchantId,
    },
    {
      skip: !groupId || !entityId,
    }
  );

  const {
    bills = [],
    due_amount,
    total_amount,
    paid_amount,
    per_page = 1,
    total_count = 1,
    total_pages = 1,
  } = data || {};

  const pagination = usePagination({
    onPageNumChange: (page) => {
      updateFilter("PAGE_NUM", page);
    },
    pageNumber: values.PAGE_NUM,
    totalPage: total_pages,
  });

  const handlesearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateFilter("SEARCH", e.target.value || null);
  };

  return (
    <DashboardContainer className="t-relative">
      <DashboardContainer.Content className="t-flex t-flex-col t-gap-5">
        {(canRequestAndAddBill || isSearchable) && (
          <div className="t-flex t-justify-between">
            {isSearchable && (
              <Search
                placeholder="Search bills"
                className="t-w-1/2"
                onChange={handlesearch}
                defaultValue={search || ""}
              />
            )}
            {canRequestAndAddBill && <AddRequestAndBill />}
          </div>
        )}
        <InvoiceConsole
          total={{
            label: "Total billed amount",
            amount: total_amount || 0,
          }}
          paid={{
            label: "Paid",
            amount: paid_amount || 0,
          }}
          due={{
            label: "Due",
            amount: due_amount || 0,
          }}
        />
        <div className="t-flex t-justify-between">
          <ToggleGroup
            value={values.BILL_TYPE}
            onValueChange={(value: keyof typeof FINANCIAL_RECORD_TYPE) => {
              if (!value) return;
              updateFilter("BILL_TYPE", value);
            }}
          >
            <ToggleGroupItem value={FINANCIAL_RECORD_TYPE.BILL}>
              Bills
            </ToggleGroupItem>
            <ToggleGroupItem value={FINANCIAL_RECORD_TYPE.REQUEST}>
              Requests
            </ToggleGroupItem>
          </ToggleGroup>
          <Pagination
            goToFirstPage={pagination.goToFirstPage}
            goToLastPage={pagination.goToLastPage}
            goToNextPage={pagination.goToNextPage}
            goToPrevPage={pagination.goToPrevPage}
            currentPage={values.PAGE_NUM}
            itemsPerPage={per_page}
            totalItemCount={total_count}
            totalPage={total_pages}
          />
        </div>
        <Async.Root
          isLoading={isFetching}
          isSuccess={isSuccess}
          isEmpty={bills.length === 0}
        >
          <Async.Empty>
            <>
              {values.BILL_TYPE === FINANCIAL_RECORD_TYPE.BILL ? (
                <EmptyScreen text="No bills found">
                  <EmptyInvoiceList />
                </EmptyScreen>
              ) : (
                <EmptyScreen text="No bills requested">
                  <EmptyInvoiceList />
                </EmptyScreen>
              )}
            </>
          </Async.Empty>
          <Async.Success>
            <BillList bills={bills} />
          </Async.Success>
        </Async.Root>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
