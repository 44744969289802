import { AmountSuperScript } from "components/design/AmountSuperScript";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import Table from "components/DesignSystem/Table/V2/Table";
import { BankLogo } from "components/icons/BankLogo";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  Table as TableProps,
  useReactTable,
} from "react-table-8.10.7";
import { BankBrand } from "types/Models/banks";
import { pluralize } from "utils/pluralize";
import CardAccount from "static/images/CardAccount.svg";
import { HideableAmount } from "../HideBalancesAndAmounts";

export type AccountType = {
  cash_balance: number;
  credit_card_balance: number;
  bank_brand: BankBrand;
  credit_card_accounts_count: number;
  cash_accounts_count: number;
};
type AccountsProps = {
  accounts: AccountType[];
};

const createColumn = createColumnHelper<AccountType>();

export const bankColumns = {
  bank: ({ size }: { size: number }) =>
    createColumn.accessor("bank_brand.name", {
      size,
      id: "BANKS",
      header: "Banks",
      cell: ({ row }) => {
        const { bank_brand } = row.original;
        return (
          <div className="t-flex t-items-center t-gap-2">
            <Avatar src={bank_brand.logo} alt={bank_brand.name} />
            <div className="t-text-subtext t-text-text-100 t-truncate t-max-w-36">
              {bank_brand.name}
            </div>
          </div>
        );
      },
    }),

  spends: ({ size }: { size: number }) =>
    createColumn.accessor("credit_card_balance", {
      size,
      id: "SPENDS",
      header: () => <div className="t-text-end">Spends</div>,
      cell: ({ row }) => {
        const { credit_card_balance, credit_card_accounts_count } =
          row.original;
        console.log("credit_card_accounts_count:", credit_card_accounts_count);

        if (credit_card_accounts_count) {
          return (
            <div className="t-flex t-flex-col t-gap-1 t-items-end">
              <div className="t-text-text-60 t-text-subtext-sm t-text-end">
                <HideableAmount>
                  <AmountSuperScript amount={credit_card_balance} />
                </HideableAmount>
              </div>
              <div className="t-text-body-sm t-text-text-30 t-text-end t-flex t-gap-1 t-items-center">
                <img src={CardAccount} alt="Bank" className="t-size-3" />
                {pluralize(
                  credit_card_accounts_count,
                  "credit card",
                  "credit cards"
                )}
              </div>
            </div>
          );
        }

        return (
          <div className="t-text-text-60 t-text-subtext-sm t-text-end">-</div>
        );
      },
    }),

  divider: ({ size }: { size: number }) =>
    createColumn.accessor("bank_brand.uuid", {
      size,
      id: "DIVIDER",
      header: () => (
        <div className="t-text-end t-border-r t-border-solid t-border-text-10 t-border-x-0 t-border-y-0 t-pr-4 t-border-neutral-10 t-w-1 t-h-6" />
      ),
      cell: ({ row }) => {
        return (
          <div className="t-text-text-60 t-text-end t-border-r t-border-solid t-border-text-10 t-border-x-0 t-border-y-0 t-pr-4 t-border-neutral-10  t-w-1 t-h-6" />
        );
      },
    }),

  assets: ({ size }: { size: number }) =>
    createColumn.accessor("cash_balance", {
      size,
      id: "ASSETS",
      header: () => <div className="t-text-end">Assets</div>,
      cell: ({ row }) => {
        const { cash_balance, cash_accounts_count } = row.original;

        if (cash_accounts_count) {
          return (
            <div className="t-flex t-flex-col t-gap-1 t-items-end">
              <div className="t-text-text-60 t-text-subtext-sm t-text-end">
                <HideableAmount>
                  <AmountSuperScript amount={cash_balance} />
                </HideableAmount>
              </div>
              <div className="t-text-body-sm t-text-text-30 t-text-end t-flex t-gap-1 t-items-center">
                <div>
                  <BankLogo size={12} color="currentColor" strokeWidth="1" />
                </div>
                {pluralize(cash_accounts_count, "account", "accounts")}
              </div>
            </div>
          );
        }

        return (
          <div className="t-text-text-60 t-text-subtext-sm t-text-end">-</div>
        );
      },
    }),
};

const columns = [
  bankColumns.bank({
    size: 30,
  }),
  bankColumns.spends({
    size: 30,
  }),
  bankColumns.divider({
    size: 10,
  }),
  bankColumns.assets({
    size: 30,
  }),
];

export const AccountsTable = <T,>({ table }: { table: TableProps<T> }) => {
  return (
    <Table.Container>
      <Table.Content>
        <Table.Head className="!t-border-none">
          {table.getHeaderGroups().map((headerGroup) => (
            <Table.HeadRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <Table.HeadCell
                  className="!t-text-body-sm"
                  key={header.id}
                  style={{ width: `${header.getSize()}%` }}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </Table.HeadCell>
              ))}
            </Table.HeadRow>
          ))}
        </Table.Head>
        <Table.Body>
          {table.getRowModel().rows.map((row) => {
            return (
              <Table.Row className="!t-border-none" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Table.Cell
                    className="!t-py-3"
                    key={cell.id}
                    style={{ width: `${cell.column.getSize()}%` }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table.Content>
    </Table.Container>
  );
};

export const Accounts = ({ accounts }: AccountsProps) => {
  const table = useReactTable({
    data: accounts,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 10,
    },
  });

  return <AccountsTable table={table} />;
};
