import React from "react";
type SwitchCaseProps<T extends string | number | symbol> = {
  value: T;
  cases: { [key in T]: React.ReactNode } | { [key: string]: React.ReactNode };
  default?: React.ReactNode;
};

/**
 * A React component that renders a specific case based on the provided value.
 *
 * @component
 * @param {string} props.value - The value to match against the cases.
 * @param {Object.<string, React.ReactNode>} props.cases - An object where keys are case values and values are React nodes to render.
 * @param {React.ReactNode} [props.default] - The default React node to render if no case matches the value.
 *
 * @example
 * ```tsx
 * <SwitchCase
 *   value="case1"
 *   cases={{
 *     case1: <div>Case 1</div>,
 *     case2: <div>Case 2</div>,
 *   }}
 *   default={<div>Default Case</div>}
 * />
 * ```
 */
export const SwitchCase = <T extends string | number>({
  value,
  cases,
  default: defaultCase,
}: SwitchCaseProps<T>) => {
  return <>{cases[value] || defaultCase || null}</>;
};
