import React from "react";
import { RuleCategorised } from "components/icons/RuleCategorised";
import { AICategorised } from "components/icons/AICategorised";
import { AICategorisedAccepted } from "components/icons/AICategorisedAccepted";
import { SolidRoundCheck } from "components/icons/SolidRoundCheck";
import Modal from "components/DesignSystem/Modal/Modal";
import { ModalProps } from "types/utils/modal";

const Item = ({
  icon,
  title,
  description,
}: {
  icon: React.ReactNode;
  title: string;
  description: string;
}) => {
  return (
    <div className="t-flex t-items-center t-gap-3 t-p-3 t-border-solid t-border t-border-neutral-0 t-rounded">
      {icon}
      <div>
        <div className="t-text-subtitle-sm t-text-text-60">{title}</div>
        <div className="t-text-body-sm t-text-text-30">{description}</div>
      </div>
    </div>
  );
};

export const CategorisationStatusInfo = ({ isOpen, close }: ModalProps) => {
  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Categorisation status</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body className="t-flex t-flex-col t-gap-3">
          <Item
            icon={<AICategorised />}
            title="AI Categorised and not manually confirmed"
            description="Requires manual confirmation to be verified"
          />

          <Item
            icon={<AICategorisedAccepted />}
            title="AI Categorised and manually confirmed"
            description="Verified and doesn't require manual confirmation"
          />

          <Item
            icon={<RuleCategorised />}
            title="Categorised by Rules"
            description="Verified and doesn't require manual confirmation"
          />

          <Item
            icon={<SolidRoundCheck />}
            title="Categorised by Bookkeeper"
            description="Verified and doesn't require manual confirmation"
          />
        </Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
