import { object, string } from "yup";

export const personaAutofill = object({
  first_name: string().min(1, "Min. 1 character").required("Required"),
  last_name: string().required("Required"),
  email: string().email("Should be a valid email").nullable(),
});

export const addressAutofill = object({
  street_address: string().required("Address Line 1 Required"),
  city: string().required("City Required"),
  country: string().required("Country Required"),
  state: string().required("State Required"),
  zipcode: string().required("Required"),
});

export const bankAutofill = object({
  account_number: string().required("Account Number Required"),
  entity_id: string().required("Please select entity"),
});
