import ChatCard from "components/DesignSystem/ChatCard/ChatCard";
import { CartPaymentIcon } from "components/icons/CartPaymentIcon";
import { Cross } from "components/icons/Cross";
import { useGetCartQuery } from "store/apis/products";
import { initialStateForEntity, setCartInChat } from "store/slices/cartInChat";
import { useAppDispatch } from "hooks/useAppDispatch";
import { Entity } from "types/Models/entity";
import { useGetGroupDataQuery } from "store/apis/group";
import { Button } from "components/DesignSystem/Button/Button";
import { ChatCartPaid } from "components/icons/Chat/ChatCartPaid";
import { ConditionalLink } from "components/conditionalLink";
import { MessageComponentProps } from "components/MessageBubble/MessageBubble";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { ReactNode } from "react";
import classNames from "classnames";
import { useState } from "react";
import { BillingSlider } from "components/BillingSlider/BillingSlider";
import Slider from "components/DesignSystem/Slider/Slider";
import { BillingInvoice } from "types/Models/billing";

type CartAttachmentViewInChatProps = {
  entityId: string;
  groupId: string;
  custom_data?: MessageComponentProps["message"]["custom_data"];
  card_status?: MessageComponentProps["message"]["card_status"];
};

export const CartAttachmentPreviews = ({
  entityId,
  groupId,
}: CartAttachmentViewInChatProps) => {
  const { data: entities } = useGetGroupDataQuery(
    { groupId },
    {
      skip: !groupId,
    }
  );

  const entity = entities?.entities?.find(
    (entity: Entity) => entity.uuid === entityId
  );

  const dispatch = useAppDispatch();

  const clearCartAttachmentPreview = () => {
    dispatch(setCartInChat(initialStateForEntity));
  };

  const { data: cart } = useGetCartQuery(
    { entityId: entityId!, groupId },
    { skip: !entityId || !groupId }
  );

  const products_name =
    cart?.cart_items
      ?.map((item) => item.product_details?.product_name)
      ?.join(", ") || "";

  return (
    <>
      <CartAttachmentPreview
        entity={entity!}
        cartStatus={"Cart Payment Pending"}
        bodyStatus={"items pending in your cart"}
        headerIconClear={
          <button
            onClick={clearCartAttachmentPreview}
            className="all:unset t-rounded-full t-border t-border-neutral-20 t-border-solid t-p-1 t-h-6 t-w-6 t-flex t-items-center t-justify-center t-bg-neutral-10 t-mr-4 t-cursor-pointer"
          >
            <Cross />
          </button>
        }
        Icon={<CartPaymentIcon />}
        color="yellow"
        groupId={groupId}
        cart_item_count={cart?.cart_items?.length}
        cart_item_name={products_name}
      />
    </>
  );
};

export const CartChatCard = ({
  entityId,
  card_status,
  custom_data,
  groupId,
}: CartAttachmentViewInChatProps) => {
  const isPaid = card_status !== "CART_UNPAID";
  const { isAdmin, isCustomer } = useRoleBasedView();
  const { data: cart, isLoading } = useGetCartQuery(
    { entityId: entityId!, groupId },
    {
      skip:
        !entityId || !groupId || custom_data?.cart_item_count! > 0 || isPaid,
    }
  );
  const [open, setOpen] = useState<boolean>(false);

  const unpaidLink = `${
    isAdmin
      ? `/admin/crm/${groupId!}/billing/cart`
      : isCustomer && `/settings/billing/cart`
  }?entity=${entityId}`;

  const products_name =
    custom_data?.cart_item_name?.map((item) => item)?.join(", ") ||
    cart?.cart_items
      ?.map((item) => item.product_details?.product_name)
      ?.join(", ") ||
    "";

  return (
    <>
      <CartAttachmentPreview
        entity={{ uuid: entityId, name: custom_data?.entity_name || "" }}
        cartStatus={isPaid ? "Cart Paid" : "Cart Payment Pending"}
        Icon={isPaid ? <ChatCartPaid /> : <CartPaymentIcon />}
        color={isPaid ? "green" : "yellow"}
        footer={
          isPaid ? (
            <Button
              onClick={() => setOpen(true)}
              customType="secondary"
              size="small"
              block
            >
              View
            </Button>
          ) : (
            <ConditionalLink to={unpaidLink}>
              <Button customType="primary" size="small" block>
                Review & Pay
              </Button>
            </ConditionalLink>
          )
        }
        headerIconClear={null}
        bodyStatus={
          isPaid
            ? custom_data?.cart_item_count! > 1
              ? "Items paid"
              : "Item Paid"
            : "items pending in your cart"
        }
        groupId={groupId}
        cart_item_count={
          custom_data?.cart_item_count || cart?.cart_items?.length
        }
        cart_item_name={products_name!}
        isLoading={isLoading}
      />
      <Slider.Root open={open} onOpenChange={setOpen}>
        <Slider.Content open>
          <BillingSlider
            openCheckoutModal={(invoice: BillingInvoice) => {}}
            entityIdFromParent={entityId}
            invoiceIdFromParent={custom_data?.invoice_id}
            groupId={groupId}
          />
        </Slider.Content>
      </Slider.Root>
    </>
  );
};

const CartAttachmentPreview = ({
  entity,
  cartStatus,
  Icon,
  color,
  footer,
  headerIconClear,
  bodyStatus,
  groupId,
  cart_item_count,
  cart_item_name,
  isLoading,
}: {
  entity: Pick<Entity, "uuid" | "name">;
  cartStatus?: string;
  Icon?: ReactNode | null;
  color?: string;
  footer?: ReactNode | null;
  headerIconClear?: ReactNode | null;
  bodyStatus?: string;
  groupId: string;
  cart_item_count?: number;
  cart_item_name?: string;
  isLoading?: boolean;
}) => {
  const { data: cart } = useGetCartQuery(
    { entityId: entity?.uuid!, groupId },
    { skip: !entity?.uuid || cart_item_name?.length! > 0 }
  );

  return (
    <section className="t-pb-2">
      <ChatCard.Root>
        <ChatCard.Header>
          <CartToChat.PreviewHeader
            Icon={Icon}
            cartStatus={cartStatus!}
            headerIconClear={headerIconClear!}
          />
        </ChatCard.Header>
        <ChatCard.Body>
          {isLoading ? (
            <section className="t-flex t-flex-col t-gap-1.5">
              {Array(3)
                ?.fill(null)
                ?.map((_, index) => (
                  <div
                    key={index}
                    className="t-w-full t-h-3 t-bg-neutral-10 t-rounded-md t-overflow-hidden"
                  >
                    <div className="t-animate-pulse t-bg-neutral-0 t-w-full t-h-full" />
                  </div>
                ))}
            </section>
          ) : (
            <>
              <CartToChat.Body
                color={color!}
                name={entity?.name || "Entity Name"}
                cart={cart_item_count || cart?.cart_items?.length}
                products_name={cart_item_name!}
                items_status={bodyStatus!}
              />
              {footer}
            </>
          )}
        </ChatCard.Body>
      </ChatCard.Root>
    </section>
  );
};

const Footer = ({
  text,
  link,
  type,
}: {
  text: string;
  link: string;
  type: "secondary" | "primary";
}) => {
  return (
    <>
      <ConditionalLink to={link}>
        <Button customType={type} size="small" block>
          {text}
        </Button>
      </ConditionalLink>
    </>
  );
};

const PreviewHeader = ({
  Icon,
  cartStatus,
  headerIconClear,
}: {
  Icon: ReactNode;
  cartStatus: string;
  headerIconClear?: ReactNode | null;
}) => {
  return (
    <div className="t-flex t-items-center t-gap-1.5 t-w-[288px] t-px-1 t-justify-between">
      <div className="t-flex t-items-center t-gap-1.5">
        {Icon}
        <div>{cartStatus}</div>
      </div>
      {headerIconClear}
    </div>
  );
};

<div className="t-w-28 t-h-28 t-bg-gray-200 t-rounded-lg t-overflow-hidden">
  <div className="t-animate-pulse t-bg-i-text-light t-w-full t-h-full" />
</div>;

const Body = ({
  color,
  name,
  cart,
  products_name,
  items_status,
}: {
  color: string;
  name: string;
  cart?: number;
  products_name: string;
  items_status: string;
}) => {
  return (
    <section className="t-flex t-gap-1.5 t-flex-col">
      <div className="t-flex t-gap-2 t-w-full t-items-center">
        <div
          className={classNames("t-h-2 t-w-2 t-rounded-full", {
            "t-bg-green-70": color === "green",
            "t-bg-yellow-70": color === "yellow",
          })}
        />
        <div className="t-text-body t-font-medium t-flex">
          {cart}
          &nbsp;
          {items_status}
        </div>
      </div>
      <div className="t-text-text-30 t-text-body-sm t-px-4 t-font-medium">
        {name || "Entity Name"}
      </div>
      <div className="t-text-text-30 t-text-body-sm t-px-4 t-font-normal">
        {products_name?.slice(0, 30)}...
      </div>
    </section>
  );
};

export const CartToChat = {
  Footer,
  PreviewHeader,
  Body,
};
