import dayjs from "dayjs";

export const VENDOR_TYPE = {
  VENDORS: "VENDORS",
  NOT_REQUIRED: "NOT_REQUIRED",
} as const;

export const FORM_1099_STATUS = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  NOT_REQUIRED: "NOT_REQUIRED",
  COMPLETED: "COMPLETED",
} as const;

export const SOURCE = {
  MANUAL: "MANUAL",
  TRANSACTION: "TRANSACTION",
} as const;

const START_FROM_YEAR = 2021;
const currentYear = dayjs().year();
export const VENDOR_SEASON_OPTION = Array.from(
  { length: currentYear - START_FROM_YEAR },
  (_, i) => (currentYear - i).toString()
);

export const CURRENT_SEASON = VENDOR_SEASON_OPTION[0];

export const VENDOR_WFORM_OPTION = [
  { label: "Required", value: VENDOR_TYPE.VENDORS },
  { label: "Not required", value: VENDOR_TYPE.NOT_REQUIRED },
];

export const VENDOR_SOURCE_OPTION = [
  { label: "Manual", value: SOURCE.MANUAL },
  { label: "Transaction", value: SOURCE.TRANSACTION },
];
