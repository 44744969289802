import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import { DD_MMM_YYYY_LT } from "constants/date";
import dayjs from "dayjs";
import { CompanyProfileType } from "types/Models/group";
import CopyIcon from "static/images/CopyPurple.svg";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { Divider } from "components/design/Divider";
import { ConditionalLink } from "components/conditionalLink";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useDocPreview } from "hooks/useDocPreview";
import { DocumentPreviewModal } from "components/PreviewModal";
import { Button } from "components/DesignSystem/Button/Button";
import { FileIcon } from "utils/fileTypeIcon";
import { AmountSuperScript } from "components/design/AmountSuperScript";

const CompanyProfile = ({
  companyData,
  entityCount,
  selectedEntity,
}: {
  companyData: CompanyProfileType;
  entityCount?: number;
  selectedEntity: string;
}) => {
  const { copyToClipboard } = useCopyToClipboard();
  const openPreview = useDocPreview();
  const {
    brand_name,
    formation,
    country,
    ein,
    entity_name,
    foreign_qualification,
    formation_documents,
    incorporation_date,
    place_of_business,
    mrr,
    home_state,
    mailing_address,
    customer_since,
    directors_count,
    officers,
    shareholders,
    active_subscriptions_count,
    outstanding_dues,
    products,
  } = companyData;

  return (
    <div className="t-rounded-lg t-border-neutral-10 t-border t-border-solid">
      <div className="!t-border-b t-border-0 t-border-neutral-10 t-p-4 t-border-solid t-text-subtitle">
        Company Profile
      </div>
      <div className="t-p-4 t-flex t-gap-6 t-flex-col">
        <div className="t-grid t-grid-cols-2 t-gap-6">
          <InfoItem label="Brand Name">{brand_name}</InfoItem>
          <InfoItem label="Formation">{formation}</InfoItem>
          <InfoItem label="Country">{country}</InfoItem>
          <InfoItem label="EIN">{ein || "-"}</InfoItem>
          <InfoItem label="Incorporation">
            {incorporation_date
              ? dayjs(incorporation_date).format(DD_MMM_YYYY_LT).toString()
              : "-"}
          </InfoItem>
          <InfoItem label="Entity name">{entity_name}</InfoItem>
        </div>
        <InfoItem label="Place of Business">
          <div className="t-flex t-gap-1.5 t-items-center">
            <span className="t-text-text-100 t-text-body">
              {place_of_business || "-"}
            </span>
            {place_of_business && (
              <img
                src={CopyIcon}
                alt="copyIcon"
                role="button"
                onClick={() => copyToClipboard(place_of_business!)}
              />
            )}
          </div>
        </InfoItem>
        <InfoItem label="Home Address">
          <div className="t-flex t-gap-1.5 t-items-center">
            <span className="t-text-text-100 t-text-body">
              {home_state || "-"}
            </span>
            {home_state && (
              <img
                src={CopyIcon}
                alt="copyIcon"
                role="button"
                onClick={() => copyToClipboard(home_state!)}
              />
            )}
          </div>
        </InfoItem>
        <InfoItem label="Mailing Address">
          <div className="t-flex t-gap-1.5 t-items-center">
            <span className="t-text-text-100 t-text-body">
              {mailing_address || "-"}
            </span>
            {mailing_address && (
              <img
                src={CopyIcon}
                alt="copyIcon"
                role="button"
                onClick={() => copyToClipboard(mailing_address!)}
              />
            )}
          </div>
        </InfoItem>
        <div className="t-grid t-grid-cols-2 t-gap-6">
          <InfoItem label="Foreign Qualification">
            {foreign_qualification ? "Yes" : "No"}
          </InfoItem>
          {entityCount && (
            <InfoItem label="No. Of Entities">{entityCount}</InfoItem>
          )}
        </div>
        <InfoItem label="Customer Since">
          {dayjs(customer_since).format(DD_MMM_YYYY_LT).toString()}
        </InfoItem>
        <Divider />
        <div className="t-flex t-gap-6 t-flex-wrap t-justify-between">
          <div>
            <ConditionalLink
              to={`entity/${selectedEntity}/directors-officers`}
              target="_blank"
            >
              <div className="t-font-sans t-text-body-sm t-text-purple">
                Directors
              </div>
            </ConditionalLink>
            <span className="t-text-subtext">{directors_count}</span>
          </div>
          <div>
            <ConditionalLink
              to={`entity/${selectedEntity}/directors-officers`}
              target="_blank"
            >
              <div className="t-font-sans t-text-body-sm t-text-purple">
                Officers
              </div>
            </ConditionalLink>
            <span className="t-text-subtext">{officers}</span>
          </div>
          <div>
            <ConditionalLink
              to={`entity/${selectedEntity}/shareholders`}
              target="_blank"
            >
              <div className="t-font-sans t-text-body-sm t-text-purple">
                Shareholders
              </div>
            </ConditionalLink>
            <span className="t-text-subtext">{shareholders}</span>
          </div>
        </div>
        <Divider />
        <div className="t-grid t-grid-cols-2 t-gap-6">
          <InfoItem label="EIN or SS-4">
            {formation_documents?.ein_or_ss_4?.file_id ? (
              <Button
                customType="ghost_icon"
                size="small"
                onClick={(e) =>
                  openPreview(formation_documents.ein_or_ss_4.file_id as string)
                }
              >
                <FileIcon
                  fileType={formation_documents.ein_or_ss_4?.file_type}
                  width="24px"
                  height="24px"
                />
              </Button>
            ) : (
              "-"
            )}
          </InfoItem>

          <InfoItem label="COI">
            {formation_documents?.certificate_of_incorporation?.file_id ? (
              <Button
                customType="ghost_icon"
                size="small"
                onClick={(e) =>
                  openPreview(
                    formation_documents.certificate_of_incorporation
                      .file_id as string
                  )
                }
              >
                <FileIcon
                  fileType={
                    formation_documents.certificate_of_incorporation?.file_type
                  }
                  width="24px"
                  height="24px"
                />
              </Button>
            ) : (
              "-"
            )}
          </InfoItem>

          <InfoItem label="Cap Table">
            {formation_documents?.cap_table?.file_id ? (
              <Button
                customType="ghost_icon"
                size="small"
                onClick={() =>
                  openPreview(formation_documents.cap_table.file_id as string)
                }
              >
                <FileIcon
                  fileType={formation_documents.cap_table?.file_type}
                  width="24px"
                  height="24px"
                />
              </Button>
            ) : (
              "-"
            )}
          </InfoItem>

          <InfoItem label="Active Subscriptions">
            {active_subscriptions_count}
          </InfoItem>
          <InfoItem label="Outstanding Dues">
            <div className="t-text-red">
              <AmountSuperScript amount={outstanding_dues} />
            </div>
          </InfoItem>
          <InfoItem label="MRR">${mrr}</InfoItem>
        </div>
        <Divider />
        <div className="t-grid t-grid-cols-2 t-gap-6">
          <InfoItem label="Inkle Tax">
            {products.inkle_tax ? "Yes" : "No"}
          </InfoItem>
          <InfoItem label="Inkle Books">
            {products.inkle_books ? "Yes" : "No"}
          </InfoItem>
          <InfoItem label="Registered Agent">
            {products.registered_agent ? "Yes" : "No"}
          </InfoItem>
          <InfoItem label="Virtual Mailroom">
            {products.virtual_mailroom ? "Yes" : "No"}
          </InfoItem>
        </div>
      </div>
      <DocumentPreviewModal />
    </div>
  );
};

export default CompanyProfile;
