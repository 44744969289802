import { Button } from "components/DesignSystem/Button/Button";
import React from "react";
import { MailroomSmallCard } from "./MailroomSmallCard";
import ActionItemsIcon from "static/images/ActionItemsIcon.svg";
import { ConditionalLink } from "components/conditionalLink";
import { useRouteMatch } from "react-router-dom";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useGetMailroomAddressQuery } from "store/apis/mailroom";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";

export const MailroomActionItems = () => {
  const { path } = useRouteMatch();
  const { uuid: groupId } = useCurrentGroupContext();
  const { data: mailroomAddress } = useGetMailroomAddressQuery(
    { groupId },
    { skip: !groupId, refetchOnMountOrArgChange: true }
  );
  const isMailroomAddressExist = Boolean(mailroomAddress?.uuid);

  const { link } = useConstructInternalLink();

  if (isMailroomAddressExist) {
    return null;
  }

  return (
    <MailroomSmallCard header="Action Centre">
      <div
        role="button"
        className="t-border t-border-solid t-rounded t-p-4 t-border-neutral-0 t-w-full t-bg-surface-lighter-grey t-flex t-justify-between t-gap-10 t-items-center"
      >
        <div className="t-flex t-gap-2 t-items-center">
          <div className="t-h-10 t-w-10 t-flex t-items-center t-justify-center t-rounded-full t-bg-surface">
            <img src={ActionItemsIcon} alt="ActionItemsIcon" />
          </div>{" "}
          Default forwarding address required
        </div>
        <ConditionalLink
          to={link(`${path}/inbox`, {
            moreQuery: {
              open_address: true,
            },
          })}
        >
          <Button customType="primary-outlined" size="small">
            Add
          </Button>
        </ConditionalLink>
      </div>
    </MailroomSmallCard>
  );
};
