import classNames from "classnames";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Table from "components/DesignSystem/Table/V2/Table";
import { ACCOUNTING_METHODS } from "constants/bookkeeping";
import { YYYY_MM_DD } from "constants/date";
import * as DATE_PERIOD from "constants/dateFilter";
import { PERIOD } from "constants/revenueMetrics";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useModal } from "hooks/useModal";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import { Fragment, useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import EmptyCalender from "static/images/EmptyCalendar.svg";
import {
  useGetVendorSummaryQuery,
  useLazyExportVendorSummaryQuery,
  VendorSummary as VendorSummaryType,
} from "store/apis/reports";
import { useLazyGetAllTransactionsDataQuery } from "store/apis/transactions";
import { BackendError } from "types/utils/error";
import { getCommonPinningStyles } from "utils/getCommonPinningStyles";
import { getDateRange } from "utils/getDateRange";
import { AddToClosing as AddToClosingModal } from "../AddToClosing";
import { Transactions } from "./Transaction";
import { FilterValues, VendorFilter } from "./VendorSummaryFilter";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import { ArrowDown } from "components/icons/ArrowDown";
import { useAuth } from "hooks/useAuth";
import { ExportOptions } from "components/ExportOptions/ExportOptions";

dayjs.extend(relativeTime);

const FIXED_COLUMN_SIZE = 200;

const ExportVendorSummary = ({
  filters,
}: {
  filters: {
    startDate: string;
    endDate: string;
    reportType: "MONTHLY" | "YEARLY" | "QUARTERLY" | "TOTALS";
    vendorNames: string[];
    accountingMethod: "ACCRUAL" | "CASH";
  };
}) => {
  const { alertToast, successToast } = useToast();
  const entityId = useCurrentEntityId();
  const { email } = useAuth();

  const [exportVendorSummary, { isFetching: isExporting }] =
    useLazyExportVendorSummaryQuery();

  const exportSummary = async (exportToMail: boolean = false) => {
    try {
      const result = await exportVendorSummary({
        entityId,
        ...filters,
        export_to_mail: exportToMail,
      }).unwrap();

      if (!exportToMail && result.download_url) {
        window.open(result.download_url, "_blank");
      }

      successToast({
        message: exportToMail ? "Report sent to email" : "Report exported",
      });
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>
        <div>
          <Button size="small" customType="primary" disabled={isExporting}>
            Export
            <div className="group-data-state-open:-t-rotate-180 t-transform t-transition t-duration-300 t-ease-in-out">
              <ArrowDown color="currentColor" />
            </div>
          </Button>
        </div>
      </Dropdown.Trigger>
      <Dropdown.Portal>
        <Dropdown.Content align="end" sideOffset={4}>
          <ExportOptions
            onLocalDownload={() => exportSummary(false)}
            onMailExport={() => exportSummary(true)}
          />
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};

const AddToClosing = ({
  filters,
}: {
  filters: {
    startDate: string;
    endDate: string;
    reportType: "MONTHLY" | "YEARLY" | "QUARTERLY" | "TOTALS";
    vendorNames: string[];
    accountingMethod: "ACCRUAL" | "CASH";
  };
}) => {
  const { isCpa, isForeignCA } = useRoleBasedView();
  const { alertToast } = useToast();
  const entityId = useCurrentEntityId();
  const addToClosingModal = useModal();

  const [exportVendorSummary, { isFetching: isExporting, data }] =
    useLazyExportVendorSummaryQuery();

  const addToClosing = async () => {
    try {
      await exportVendorSummary({
        entityId,
        ...filters,
      }).unwrap();

      addToClosingModal.open();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  if (isCpa || isForeignCA) {
    return (
      <>
        <Button
          size="small"
          onClick={addToClosing}
          disabled={isExporting}
          isLoading={isExporting}
        >
          Add to closing
        </Button>
        {data?.document_id && (
          <AddToClosingModal
            {...addToClosingModal}
            startDate={filters.startDate}
            endDate={filters.endDate}
            key={
              filters.startDate && filters.endDate
                ? filters.startDate.concat(filters.endDate)
                : ""
            }
            reportFileId={data?.document_id}
            reportType="VENDOR_SUMMARY"
          />
        )}
      </>
    );
  }

  return null;
};

export const VendorSummary = () => {
  const { alertToast } = useToast();
  const [expendedVendor, setExpendedVendor] =
    useState<VendorSummaryType | null>();
  const [expendedCellId, setExpendedCellId] = useState<string | null>();
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();
  const { startDate, endDate } = getDateRange(DATE_PERIOD.CURRENT_YEAR);

  const [
    getTransactions,
    {
      data: transactionData,
      isSuccess: isTxnSuccess,
      isFetching: isTxnFetching,
    },
  ] = useLazyGetAllTransactionsDataQuery();

  const { values: filterValues, updateFilter } = useFilters<FilterValues>({
    initialValue: {
      START_DATE: dayjs(startDate).format(YYYY_MM_DD),
      END_DATE: dayjs(endDate).format(YYYY_MM_DD),
      SELECT_PERIOD: DATE_PERIOD.CURRENT_YEAR,
      ACCOUNTING_METHOD: ACCOUNTING_METHODS.CASH,
      VENDORS: [],
      VIEW_BY: PERIOD.TOTALS,
    },
    useQueryParams: true,
  });

  const isTotalView = filterValues.VIEW_BY === PERIOD.TOTALS;
  const columnHelper = createColumnHelper<VendorSummaryType>();

  const filters = useMemo(() => {
    return {
      startDate: dayjs(filterValues.START_DATE).format(YYYY_MM_DD),
      endDate: dayjs(filterValues.END_DATE).format(YYYY_MM_DD),
      reportType: filterValues.VIEW_BY,
      vendorNames: filterValues.VENDORS,
      accountingMethod: filterValues.ACCOUNTING_METHOD,
    };
  }, [JSON.stringify(filterValues)]);

  const {
    data: summary = [],
    isFetching,
    isSuccess,
  } = useGetVendorSummaryQuery(
    {
      entityId,
      ...filters,
    },
    {
      skip: !entityId,
    }
  );

  const getVendorTxn = async ({
    vendorName,
    categoryId,
    startDate,
    endDate,
  }: {
    vendorName: string;
    categoryId: string;
    startDate: string;
    endDate: string;
  }) => {
    try {
      await getTransactions({
        groupId,
        entityId,
        startDate: { value: dayjs(startDate).format(YYYY_MM_DD) },
        endDate: { value: dayjs(endDate).format(YYYY_MM_DD) },
        vendors: { value: [vendorName] },
        categoryIds: { value: [categoryId] },
        sortCol: "DATE",
        sortOrder: "DSC",
      }).unwrap();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const onAmountClick = ({
    summary,
    startDate,
    endDate,
    cellId,
  }: {
    summary: VendorSummaryType;
    startDate: string;
    endDate: string;
    cellId: string;
  }) => {
    if (expendedCellId === cellId) {
      setExpendedCellId(null);
      setExpendedVendor(null);
      return;
    }

    setExpendedVendor(summary);
    setExpendedCellId(cellId);
    getVendorTxn({
      vendorName: summary.name,
      categoryId: summary.category.category_id,
      startDate,
      endDate,
    });
  };

  const amountColumn = Object.keys(summary?.[0]?.category?.period || {}).map(
    (key) =>
      columnHelper.accessor(`category.period.${key}`, {
        header: () => <div className="t-flex t-justify-end">{key}</div>,
        size: isTotalView ? 80 : 100,
        cell: ({ getValue, row, cell }) => {
          const period = getValue();

          if (period) {
            return (
              <div
                className={classNames("t-flex t-justify-end t-w-full")}
                aria-label={`${key} ${row.original.category.name} ${period.amount}`}
              >
                <Button
                  disabled={expendedCellId === cell.id && isTxnFetching}
                  customType="link"
                  onClick={() =>
                    onAmountClick({
                      summary: row.original,
                      startDate: period.start_date,
                      endDate: period.end_date,
                      cellId: cell.id,
                    })
                  }
                >
                  <span
                    className={classNames(
                      "hover:t-text-purple t-text-subtext",
                      {
                        "t-text-purple": expendedCellId === cell.id,
                        "t-text-text-30": expendedCellId !== cell.id,
                      }
                    )}
                  >
                    <AmountSuperScript amount={period.amount} />
                  </span>
                </Button>
              </div>
            );
          }
          return (
            <div className="t-text-text-30 hover:t-text-purple t-text-subtext-sm t-flex t-justify-end">
              -
            </div>
          );
        },
      })
  );

  const totalColumn = !isTotalView
    ? [
        columnHelper.accessor(`category.period`, {
          header: () => <div className="t-flex t-justify-end">Total</div>,
          size: 100,
          cell: ({ getValue, row }) => {
            const period = getValue();

            const total = Object.keys(period).reduce((acc, key) => {
              return acc + Number(period[key]?.amount || 0);
            }, 0);

            return (
              <div
                className="t-text-text-30 t-text-subtext t-flex t-justify-end"
                aria-label={`${row.original.name} ${row.original.category.name} Total ${total}`}
              >
                {total ? <AmountSuperScript amount={total} /> : "-"}
              </div>
            );
          },
        }),
      ]
    : [];

  const columns = [
    columnHelper.accessor("name", {
      header: "Vendor Name",
      size: isTotalView ? 10 : FIXED_COLUMN_SIZE,
      cell: ({ row, getValue, table }) => {
        const name = getValue();

        const lastrow =
          Number(row.id) - 1 >= 0
            ? table.getRow((Number(row.id) - 1).toString())
            : null;

        const lastrowname = lastrow?.original.name;
        if (lastrowname === name) {
          return null;
        }

        return <div className="t-text-text-60 t-text-body">{name}</div>;
      },
    }),

    columnHelper.accessor("category", {
      header: "Category",
      size: isTotalView ? 10 : FIXED_COLUMN_SIZE,
      cell: ({ getValue }) => {
        const category = getValue();
        return (
          <div className="t-text-text-30 t-text-body">{category.name}</div>
        );
      },
    }),

    ...amountColumn,
    ...totalColumn,
  ];

  const table = useReactTable({
    data: summary,
    columns: columns,
    state: {
      columnPinning: {
        left: ["name", "category"],
      },
    },
    getCoreRowModel: getCoreRowModel(),
  });

  const totalAmount = summary.reduce((acc, row) => {
    const period = row.category.period;
    return (
      acc +
      Object.keys(period).reduce((acc, key) => {
        return acc + Number(period[key]?.amount || 0);
      }, 0)
    );
  }, 0);

  const { transactions = [] } = transactionData || {};

  const transactionsTableHeight =
    transactions?.length > 0 ? 50 * (transactions?.length + 1) + 30 : 50;

  return (
    <DashboardContainer className="t-h-full t-w-full t-flex t-px-10 t-pt-5 t-gap-6">
      <DashboardContainer.Header className="t-sticky t-top-0 t-bg-white t-flex t-justify-between">
        <VendorFilter filterValues={filterValues} updateFilter={updateFilter} />
        <div className="t-justify-end t-flex t-gap-2">
          <AddToClosing filters={filters} />
          <ExportVendorSummary filters={filters} />
        </div>
      </DashboardContainer.Header>
      <DashboardContainer.Content className="t-overflow-hidden t-mb-10 scrollbar-visible">
        <Async.Root
          isLoading={isFetching}
          isSuccess={isSuccess}
          isEmpty={summary.length === 0}
        >
          <Async.Empty>
            <EmptyScreen
              className="t-h-[calc(100%-64px)]"
              text={
                <span className="t-text-subtext-sm t-text-text-100">
                  No data found
                </span>
              }
            >
              <img src={EmptyCalender} alt="EmptyCalender" />
            </EmptyScreen>
          </Async.Empty>
          <Async.Success>
            <div className="t-relative">
              <Table.Container
                className="scrollbar-visible t-h-full t-w-full t-overflow-x-auto"
                size="regular"
              >
                <Table.Content>
                  <Table.Head>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <Table.HeadRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          const styles = getCommonPinningStyles(header.column);

                          const style = isTotalView
                            ? { width: `${header.getSize()}%` }
                            : {
                                minWidth: header.column.getSize(),
                              };

                          return (
                            <Table.HeadCell
                              key={header.id}
                              style={{
                                ...styles,
                                ...style,
                              }}
                            >
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </Table.HeadCell>
                          );
                        })}
                      </Table.HeadRow>
                    ))}
                  </Table.Head>
                  <Table.Body>
                    {table.getRowModel().rows.map((row) => {
                      return (
                        <Fragment key={row.id}>
                          <Table.Row
                            aria-label={`${row.original.name} ${row.original.category.name}`}
                          >
                            {row.getVisibleCells().map((cell) => {
                              const styles = getCommonPinningStyles(
                                cell.column
                              );

                              const style = isTotalView
                                ? { width: `${cell.column.getSize()}%` }
                                : {
                                    minWidth: cell.column.getSize(),
                                  };

                              return (
                                <Table.Cell
                                  key={cell.id}
                                  style={{
                                    ...styles,
                                    ...style,
                                  }}
                                >
                                  {flexRender(
                                    cell.column.columnDef.cell,
                                    cell.getContext()
                                  )}
                                </Table.Cell>
                              );
                            })}
                          </Table.Row>
                          {expendedVendor === row.original && (
                            <tr
                              className="t-w-full t-z-filter-head t-overscroll-x-none"
                              style={{
                                height: `${transactionsTableHeight}px `,
                              }}
                            >
                              <Async.Root
                                isLoading={isTxnFetching}
                                isSuccess={isTxnSuccess}
                                isEmpty={
                                  transactionData?.transactions.length === 0
                                }
                                customLoader={
                                  <td className="t-w-full" colSpan={6}>
                                    <div className="t-w-full t-flex t-justify-center">
                                      <Loader size="small" />
                                    </div>
                                  </td>
                                }
                              >
                                <Async.Empty>
                                  <td className="t-w-full" colSpan={6}>
                                    <div className="t-text-text-60 t-text-body-sm t-text-center t-w-full t-p-1.5 t-flex t-justify-center">
                                      No transactions found
                                    </div>
                                  </td>
                                </Async.Empty>
                                <Async.Success>
                                  <td className="t-absolute t-pt-1">
                                    <div className="t-shadow-light-30">
                                      <div className="t-flex t-justify-end t-border t-border-solid t-border-t-0 t-border-r-0 t-border-l-0 t-border-neutral-0 t-pb-3 t-pt-1 t-pr-1">
                                        <div className="t-rounded t-bg-surface-lighter-grey t-border t-border-solid t-border-neutral-10 t-px-2 t-py-0.5 t-text-text-60 t-text-body-sm">
                                          {transactionData?.transactions.length}{" "}
                                          transactions
                                        </div>
                                      </div>
                                      <Transactions
                                        transactionData={transactionData!}
                                      />
                                    </div>
                                  </td>
                                </Async.Success>
                              </Async.Root>
                            </tr>
                          )}
                        </Fragment>
                      );
                    })}
                    <Table.Row>
                      <Table.Cell
                        style={{
                          minWidth: FIXED_COLUMN_SIZE,
                          left: `0px`,
                        }}
                        className="t-bg-surface-lighter-grey  t-text-text-60 t-text-subtext t-sticky t-z-10"
                      >
                        Total
                      </Table.Cell>
                      <Table.Cell
                        className="t-bg-surface-lighter-grey t-text-text-60 t-text-subtext t-sticky t-z-10"
                        style={{
                          minWidth: FIXED_COLUMN_SIZE,
                          left: `${FIXED_COLUMN_SIZE}px`,
                        }}
                      ></Table.Cell>
                      {Object.keys(summary?.[0]?.category?.period || {}).map(
                        (key) => {
                          const total = summary.reduce((acc, row) => {
                            const period = row.category.period;
                            return acc + Number(period[key]?.amount || 0);
                          }, 0);

                          return (
                            <Table.Cell
                              key={key}
                              aria-label={`Total ${totalAmount}`}
                              className="t-bg-white t-text-text-60 t-text-subtext"
                            >
                              <div className="t-flex t-justify-end">
                                {total ? (
                                  <AmountSuperScript amount={total} />
                                ) : (
                                  "-"
                                )}
                              </div>
                            </Table.Cell>
                          );
                        }
                      )}

                      {isTotalView ? (
                        <></>
                      ) : (
                        <Table.Cell className="t-bg-white t-text-text-60 t-text-subtext">
                          <div className="t-flex t-justify-end">
                            <AmountSuperScript amount={totalAmount} />
                          </div>
                        </Table.Cell>
                      )}
                    </Table.Row>
                  </Table.Body>
                </Table.Content>
              </Table.Container>
            </div>
          </Async.Success>
        </Async.Root>
      </DashboardContainer.Content>
    </DashboardContainer>
  );
};
