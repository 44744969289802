import ConditionalToolTip from "components/design/conditionalToolTip";
import Loader from "components/design/loader";
import Async from "components/DesignSystem/AsyncComponents/Async";
import Card from "components/DesignSystem/Card/Card";
import { AirplaneTakeoff } from "components/icons/AirplaneTakeoff";
import { Fire } from "components/icons/Fire";
import { Info } from "components/icons/Info";
import { CATEGORISATION_TYPE } from "constants/booksHomePage";
import { YYYY_MM_DD } from "constants/date";
import * as DATE_PERIOD from "constants/dateFilter";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { ReactNode } from "react";
import { useGetMetricsQuery } from "store/apis/metrics";
import { formatAmount } from "utils/foramtAmount";
import { getDateRange } from "utils/getDateRange";
import { HomePageCardHeader, HomePageCardTitle } from "../HomePageCardHeader";
import { HideableAmount } from "../HideBalancesAndAmounts";

const MetricsItem = ({
  title,
  value,
  description,
  icon,
}: {
  title: ReactNode;
  value: ReactNode;
  icon: ReactNode;
  description: ReactNode;
}) => {
  return (
    <div className="t-flex t-flex-col t-gap-2 t-w-full">
      <div className="t-flex t-justify-between t-items-center">
        <div className="t-text-body t-text-text-30">{title}</div>
        <div className="t-text-text-30">{icon}</div>
      </div>
      <div className="t-text-[24px] t-font-medium t-text-text-60">{value}</div>
      <div className="t-text-body t-italic t-text-text-30">{description}</div>
    </div>
  );
};

export const FinancialHealth = ({
  categorisationType,
}: {
  categorisationType: keyof typeof CATEGORISATION_TYPE;
}) => {
  const entityId = useCurrentEntityId();

  const { endDate, startDate } = getDateRange(DATE_PERIOD.LAST_3_MONTHS);

  const {
    data: metrics,
    isFetching,
    isError,
    isSuccess,
  } = useGetMetricsQuery(
    {
      entityId,
      burn_rate_start_date: dayjs(startDate).format(YYYY_MM_DD),
      burn_rate_end_date: dayjs(endDate).format(YYYY_MM_DD),
      categorisation_type: categorisationType,
    },
    {
      skip: !entityId || !categorisationType || !startDate || !endDate,
    }
  );

  const runwayEnd = dayjs().add(metrics?.gross_runway || 0, "month");

  return (
    <Card.Root borderRounded="lg">
      <HomePageCardHeader>
        <HomePageCardTitle>Financial Health</HomePageCardTitle>
      </HomePageCardHeader>
      <Card.Body className="t-flex t-flex-col t-gap-3">
        <Async.Root
          isLoading={isFetching}
          isSuccess={isSuccess}
          isEmpty={!metrics}
          isError={isError}
          customLoader={
            <div className="t-flex t-justify-center t-items-center t-h-40">
              <Loader customType="secondary" />
            </div>
          }
        >
          <Async.Empty>
            <>No data found!</>
          </Async.Empty>
          <Async.ErrorHandler>
            <div className="t-flex t-justify-center t-items-center t-h-40 t-text-red-50">
              Something went wrong!
            </div>
          </Async.ErrorHandler>
          <Async.Success>
            {metrics && (
              <div className="t-flex t-flex-col t-gap-4">
                <MetricsItem
                  icon={<Fire />}
                  description="Average of 3 month spends"
                  title={
                    <div className="t-flex t-items-center t-gap-2">
                      Net Burn Rate
                      <ConditionalToolTip
                        condition={
                          <>
                            The amount of cash your business spends
                            <br />
                            each month after accounting for all <br />
                            income.
                          </>
                        }
                      >
                        <span className="t-text-text-30">
                          <Info stroke="1.12" />
                        </span>
                      </ConditionalToolTip>
                    </div>
                  }
                  value={
                    <HideableAmount>
                      {formatAmount(Number(metrics.net_burn_rate))}
                    </HideableAmount>
                  }
                />
                <div className="t-w-full t-border-t-[1px] t-border-b-0 t-border-solid t-border-neutral-10" />
                <MetricsItem
                  icon={<AirplaneTakeoff />}
                  description={`${metrics?.gross_runway || 0} months`}
                  title="Runway"
                  value={
                    <>
                      {metrics?.gross_burn_rate === 0 &&
                      metrics?.gross_runway === 0 ? (
                        "N/A"
                      ) : (
                        <>
                          <span>{runwayEnd.format("MMM YYYY")}</span>
                          <span className="t-inline-block t-ml-2 t-text-subtitle"></span>
                        </>
                      )}
                    </>
                  }
                />
              </div>
            )}
          </Async.Success>
        </Async.Root>
      </Card.Body>
    </Card.Root>
  );
};
