import { Dayjs } from "dayjs";
import { Merchant } from "./merchant";
import { FileObject } from "./fileObject";

export const CPA_REVIEWED = "CPA_REVIEWED";
export const NOT_REQUIRED = "NOT_REQUIRED";
export const SYSTEM_REVIEWED = "SYSTEM_REVIEWED";

export type Vendor = Merchant & {
  transaction_amount: number;
  linked_transaction_count: number;
};

export type MerchantCSVResponse = {
  season: string | Dayjs;
  email: string;
  name: string;
  total_amount: number;
  id?: string;
};

export type WFormResponse = {
  merchant: { name: string; uuid: string };
  uuid: string;
  company_group_id: string;
  w_form: {
    document: FileObject;
    season: string;
    type: string;
  };
};

export type AmountPerSeason = {
  season: string;
  total_amount: string;
};

type WFormDocument = {
  season: string;
  w_form_document: FileObject;
};

type FormDocument = {
  task_document_map_id: string;
  task_document_merchant_id: string;
  form_1099_document_type: "RECIPIENTS_DOCUMENT" | "PAYERS_DOCUMENT";
} & FileObject;

type Form1099Document = {
  season: string;
  recipients_document: FormDocument;
  payers_document: FormDocument;
};

export type VendorAllDocuments = {
  w_forms: WFormDocument[];
  "1099_forms": Form1099Document[];
};
