import classNames from "classnames";
import DashboardContainer from "components/dashboard/DashboardContainer";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { Slider } from "components/DesignSystem/Slider/Slider";
import Tab from "components/DesignSystem/Tab/Tab";
import { ArrowLeft } from "components/icons/ArrowLeft";
import { Cross } from "components/icons/Cross";
import {
  MessageBody,
  MessageDate,
  MessageLayout,
} from "components/MessageBubble/MessageBubble";
import { MessageDateFormat } from "components/MessageDateFormat/MessageDateFormat";
import dayjs from "dayjs";
import { useGetMessage } from "hooks/useGetMessage";
import { useDispatch, useSelector } from "react-redux";
import SmallWhatsappIcon from "static/images/SmallWhatsappIcon.svg";
import { useGetMessageDetailsQuery } from "store/apis/chat";
import { useGetTaskFromChannelIdQuery } from "store/apis/task";
import { closeMessageInfoSlider } from "store/slices/channelInfoSlider";
import { RootState } from "store/store";
import {
  MessageUIComponentProps,
  useChannelStateContext,
  useChatContext,
} from "stream-chat-react";

export const MessageInfoSlider = ({ block = false }: { block?: boolean }) => {
  const dispatch = useDispatch();
  const { channel } = useChannelStateContext();
  const { messageIdForInfo } = useSelector(
    (store: RootState) => store.channelInfoSlider
  );
  const message = useGetMessage(messageIdForInfo!);
  const { client } = useChatContext();
  const sentByTheUser = message?.user?.id === client.userID;

  const { data: currentChannelData } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
    }
  );
  const groupId = currentChannelData?.company_group?.uuid;

  const { data: messageDetails } = useGetMessageDetailsQuery(
    {
      groupId: groupId!,
      channelId: channel.id!,
      messageId: messageIdForInfo,
    },
    {
      skip: !channel.id || !groupId || !messageIdForInfo,
    }
  );
  const isMessageForwardedOnWhatsapp = Boolean(
    messageDetails?.forwarded_on_whatsapp &&
      messageDetails?.forwarded_on_whatsapp?.length > 0
  );
  const isMessageForwardedOnEmail = Boolean(
    messageDetails?.forwarded_on_email &&
      messageDetails?.forwarded_on_email?.length > 0
  );
  const isMessageForwardedOnSlack = Boolean(
    messageDetails?.forwarded_on_slack &&
      messageDetails?.forwarded_on_slack?.length > 0
  );

  const sliderOpen = useSelector(
    (store: RootState) => store.channelInfoSlider.showMessageInfo
  );

  const closeSlider = () => {
    if (sliderOpen) {
      dispatch(closeMessageInfoSlider());
    }
  };

  const props = {
    message: message as MessageUIComponentProps["message"] & {
      forwarded_message?: MessageUIComponentProps["message"];
    },
    otherData: { sentByTheUser },
    hideOptions: true,
  };

  const forwardedOnWAGroupedByUser =
    messageDetails?.forwarded_on_whatsapp.reduce((acc, item) => {
      const email = item.forwarded_to.email;
      if (!acc[email]) {
        acc[email] = [];
      }
      acc[email].push(item);

      return acc;
    }, {} as Record<string, typeof messageDetails.forwarded_on_whatsapp>);

  return (
    <Slider
      open={sliderOpen}
      className={classNames(
        "t-absolute t-z-10 !t-p-0 t-shadow-slider !t-h-full",
        {
          "t-w-full": block,
          "t-w-[360px]": !block,
        }
      )}
      position="right"
      width={360}
    >
      <Tab.Root defaultValue="MEDIA" className="t-h-full">
        <DashboardContainer className="t-h-full">
          <DashboardContainer.Header>
            <div className="t-py-3 t-pr-4 t-pl-5 t-flex t-justify-between t-items-center t-border-b t-border-0 t-border-solid t-border-i-neutral-10">
              <div className="t-flex t-gap-2 t-items-center">
                <Button
                  customType="ghost_icon"
                  size="small"
                  onClick={closeSlider}
                >
                  <span className="t-text-text-30">
                    <ArrowLeft size="16" />
                  </span>
                </Button>
                <span className="t-text-subtitle">Message info</span>
              </div>
              <Button
                customType="ghost_icon"
                size="small"
                onClick={closeSlider}
              >
                <Cross />
              </Button>
            </div>
          </DashboardContainer.Header>
          <DashboardContainer.Content
            className="t-p-5 t-pt-4"
            id="media-scroll"
          >
            <div className="t-p-5 t-bg-surface-lighter-grey">
              {message && (
                <div className="t-flex t-gap-1 t-group">
                  <Avatar
                    src={message?.user?.image}
                    alt={message?.user?.name || ""}
                    size="regular"
                  />
                  <MessageLayout {...props}>
                    <MessageBody
                      {...props}
                      Date={
                        <MessageDate
                          sentByTheUser={sentByTheUser}
                          createdAt={message?.created_at}
                        />
                      }
                    />
                  </MessageLayout>
                </div>
              )}
            </div>
            {isMessageForwardedOnWhatsapp && (
              <div className="t-mt-4 t-border t-border-solid t-border-neutral-0 t-rounded-lg t-flex t-flex-col t-gap-3 t-p-4">
                <div className="t-text-subtext t-text-text-100">
                  WhatsApp sent to:
                </div>

                {forwardedOnWAGroupedByUser &&
                  Object.entries(forwardedOnWAGroupedByUser).map(
                    ([email, messageDetails]) => (
                      <div className="t-flex t-w-full t-gap-2" key={email}>
                        <Avatar
                          src={messageDetails[0].forwarded_to?.profile_url}
                          alt={messageDetails[0].forwarded_to?.name || ""}
                          size="regular"
                        />
                        <div className="t-flex t-flex-col t-w-full t-gap-1">
                          <div className="t-flex t-justify-between t-items-center">
                            <div className="t-text-subtext-sm t-text-text-60">
                              {messageDetails[0].forwarded_to.name}
                            </div>
                            <div className="t-text-caption t-text-text-30">
                              <MessageDateFormat
                                createdAt={dayjs(
                                  messageDetails[0].time_stamp
                                ).toDate()}
                              />
                            </div>
                          </div>
                          <div className="t-flex t-flex-col t-gap-1">
                            {messageDetails.map(
                              ({ forwarded_to, delivery_status }) => (
                                <div
                                  className="t-flex t-gap-1 t-justify-between"
                                  key={forwarded_to.to_mobile}
                                >
                                  <div className="t-text-body-sm t-text-text-30 t-flex t-items-center t-gap-1">
                                    <img
                                      src={SmallWhatsappIcon}
                                      alt="SmallWhatsappIcon"
                                    />
                                    <div>{forwarded_to.to_mobile}</div>
                                  </div>
                                  <div className="t-capitalize t-tracking-[0.25px] t-text-caption t-text-text-30 t-italic">
                                    {delivery_status}
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    )
                  )}
              </div>
            )}
            {isMessageForwardedOnEmail && (
              <div className="t-mt-4 t-border t-border-solid t-border-neutral-0 t-rounded-lg t-flex t-flex-col t-gap-3 t-p-4">
                <div className="t-text-subtext t-text-text-100">
                  Email sent to:
                </div>
                {messageDetails?.forwarded_on_email?.map((emailDetail) => (
                  <div
                    className="t-flex t-w-full t-gap-2"
                    key={emailDetail.forwarded_to.email}
                  >
                    <Avatar
                      src={emailDetail?.forwarded_to?.profile_url}
                      alt={emailDetail.forwarded_to?.name || ""}
                      size="regular"
                    />
                    <div className="t-flex t-flex-col t-w-full">
                      <div className="t-flex t-justify-between t-items-center">
                        <div className="t-text-subtext-sm t-text-text-60">
                          {emailDetail.forwarded_to.name}
                        </div>
                        <div className="t-text-caption t-text-text-30">
                          <MessageDateFormat
                            createdAt={dayjs(emailDetail.time_stamp).toDate()}
                          />
                        </div>
                      </div>
                      <div className="t-flex t-justify-between t-items-center">
                        <div className="t-text-body-sm t-text-text-30 t-flex t-items-center t-gap-1">
                          {emailDetail.forwarded_to.email}
                        </div>
                        <div className="t-capitalize t-tracking-[0.25px] t-text-caption t-text-text-30 t-italic">
                          {emailDetail.delivery_status}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {isMessageForwardedOnSlack && (
              <div className="t-mt-4 t-border t-border-solid t-border-neutral-0 t-rounded-lg t-flex t-flex-col t-gap-3 t-p-4">
                <div className="t-text-subtext t-text-text-100">
                  Slack sent to:
                </div>
                {messageDetails?.forwarded_on_slack?.map((slackDetail) => (
                  <div
                    className="t-flex t-w-full t-gap-2"
                    key={slackDetail.forwarded_to.email}
                  >
                    <Avatar
                      src={slackDetail?.forwarded_to?.profile_url}
                      alt={slackDetail.forwarded_to?.name || ""}
                      size="regular"
                    />
                    <div className="t-flex t-flex-col t-w-full">
                      <div className="t-flex t-justify-between t-items-center">
                        <div className="t-text-subtext-sm t-text-text-60">
                          {slackDetail.forwarded_to.name}
                        </div>
                        <div className="t-text-caption t-text-text-30">
                          <MessageDateFormat
                            createdAt={dayjs(slackDetail.time_stamp).toDate()}
                          />
                        </div>
                      </div>
                      <div className="t-flex t-justify-between t-items-center">
                        <div className="t-text-body-sm t-text-text-30 t-flex t-items-center t-gap-1">
                          {slackDetail.forwarded_to.email}
                        </div>
                        <div className="t-capitalize t-tracking-[0.25px] t-text-caption t-text-text-30 t-italic">
                          {slackDetail.delivery_status}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </DashboardContainer.Content>
        </DashboardContainer>
      </Tab.Root>
    </Slider>
  );
};
