import { DashboardLayout } from "components/DashboardLayout";
import { Button } from "components/DesignSystem/Button/Button";
import { usePageTitle } from "hooks/usePageTitle";
import React from "react";
import RAndDBG from "static/images/RAndDBG.svg";
import RAndDTaxSavingIcon from "static/images/RAndDTaxSavingIcon.svg";
import MainStreet from "static/images/MainStreet.svg";
import { ConditionalLink } from "components/conditionalLink";
import { ArrowUpRight } from "components/icons/ArrowUpRight";

export const RAndD = () => {
  usePageTitle("R&D");
  return (
    <DashboardLayout header={null}>
      <div className="t-flex t-h-[282px] t-w-full t-border t-border-solid t-border-neutral-10 t-rounded-lg">
        <div className="t-p-8 t-flex-1 t-flex t-flex-col t-justify-between">
          <div className="t-space-y-4">
            <div className="t-text-title-h2-bold t-text-text-100">
              Unlock Potential R&D Tax Credits
            </div>
            <div className="t-text-body t-text-text-30">
              R&D Tax Credits are government incentives that help businesses
              offset costs related to research and development by reducing their
              corporate tax liability.
            </div>
          </div>
          <div className="t-space-y-4">
            <div className="t-flex t-gap-4">
              <ConditionalLink
                to="https://dashboard.mainstreet.com/welcome/inkle"
                target="_blank"
              >
                <Button customType="primary">
                  Claim Your Credits{" "}
                  <div className="t-ml-1.5">
                    <ArrowUpRight />
                  </div>
                </Button>
              </ConditionalLink>
              <ConditionalLink
                to="https://www.inkle.io/pricing#Inkle%20R&D"
                target="_blank"
              >
                <Button>Learn More</Button>
              </ConditionalLink>
            </div>
            <div className="t-flex t-gap-2 t-items-center">
              <div className="t-text-subtext-sm t-text-text-30">Powered by</div>
              <img src={MainStreet} alt="" />
            </div>
          </div>
        </div>
        <div className="t-relative t-h-full t-w-[380px] t-justify-center t-items-end t-flex -t-mr-[1.5px]">
          <img
            src={RAndDBG}
            className="t-absolute t-h-full t-w-full t-rounded-r-[9px] t-z-[-1]"
            alt=""
          />
          <img src={RAndDTaxSavingIcon} alt="" className="t-h-[236px]" />
        </div>
      </div>
    </DashboardLayout>
  );
};
