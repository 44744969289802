import { TaxCalculateResponse, TaxCode } from "types/Models/taxCalculate";
import { emptyApi } from "./emptyApi";

export type Address = {
  country?: string;
  zipCode: string;
  state: string;
  city: string;
  address1: string;
  uuid?: string;
};
export type CalculateTaxPayload = {
  seller_address: Address;
  buyer_address: Address;
  product_detail: {
    uuid: string;
    tax_code: string;
    label?: string;
  };
};

export const taxCalculator = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getAllTaxCode: build.query<
      TaxCode[],
      {
        entityId: string;
      }
    >({
      query: ({ entityId }) => {
        return {
          url: `/api/inkle/salestax/entity/${entityId}/tax_codes/`,
        };
      },
      providesTags: (result) => (result ? ["TAX_CALCULATOR_TAX_CODE"] : []),
    }),
    calculateTax: build.mutation<
      TaxCalculateResponse,
      { entityId: string; payload: CalculateTaxPayload }
    >({
      query: ({ entityId, payload }) => ({
        url: `/api/inkle/salestax/entity/${entityId}/tax/calculate/`,
        method: "POST",
        body: payload,
      }),
    }),
  }),
});

export const { useGetAllTaxCodeQuery, useCalculateTaxMutation } = taxCalculator;
