import { Address } from "components/Address/Address";
import { Accordion } from "components/DesignSystem/Accordion/Accordion";
import { Button } from "components/DesignSystem/Button/Button";
import { Form1099Handler, WForm } from "components/Vendors/VendorColumn";
import { CURRENT_SEASON } from "constants/vendor";
import { Vendor } from "types/Models/vendors";
import { VendorDocumentsSlider } from "./VendorDocumentsSlider";
import { useModal } from "hooks/useModal";
import Pencil from "components/icons/pencil";

export const VendorDeatilAccordion = ({
  vendor,
  groupId,
  entityId,
}: {
  vendor: Vendor;
  entityId: string;
  groupId: string;
}) => {
  const vendorDocumentSlider = useModal();

  return (
    <>
      <Accordion.Root collapsible type="single" defaultValue="DETAILS">
        <Accordion.Item value="DETAILS">
          <Accordion.Trigger>
            <div className="t-flex t-items-center t-w-full t-gap-2 t-justify-between t-mr-4">
              Details
              {/* TODO: add new edit flow */}
              {/* <div className="t-ml-2 t-text-text-30">
                <Pencil color="currentColor" size="16" />
              </div> */}
            </div>
          </Accordion.Trigger>
          <Accordion.Content className="t-gap-5 t-flex t-flex-col">
            <div className="t-grid t-grid-flow-row t-gap-5 t-grid-cols-3">
              <Accordion.InfoItem label="Name">
                {vendor.name}
              </Accordion.InfoItem>
              <Accordion.InfoItem label="Email">
                {vendor.email || "-"}
              </Accordion.InfoItem>
              <Accordion.InfoItem label="Address">
                {vendor.address ? <Address address={vendor.address} /> : "-"}
              </Accordion.InfoItem>
              <Accordion.InfoItem label="Payment Instructions">
                {vendor.payment_instructions || "-"}
              </Accordion.InfoItem>
              <Accordion.InfoItem label="Source">
                <div className="first-letter:t-uppercase t-lowercase">
                  {vendor.source}
                </div>
              </Accordion.InfoItem>
              {vendor.is_merchant_document_present && (
                <Accordion.InfoItem label="Documents">
                  <Button
                    size="small"
                    customType="link"
                    onClick={vendorDocumentSlider.open}
                  >
                    View all
                  </Button>
                </Accordion.InfoItem>
              )}
            </div>

            <div className="t-flex t-items-center t-gap-4">
              <span className="t-text-subtext-sm t-whitespace-nowrap t-text-text-60">
                Season {CURRENT_SEASON}
              </span>
              <span className="t-h-px t-bg-neutral-10 t-w-full" />
            </div>
            <div className="t-grid t-grid-flow-row t-gap-6 t-grid-cols-3">
              <Accordion.InfoItem label="W-Form">
                <WForm
                  groupId={groupId}
                  season={CURRENT_SEASON}
                  vendor={vendor}
                />
              </Accordion.InfoItem>
              <Accordion.InfoItem label="1099">
                <div className="t-flex">
                  <Form1099Handler
                    entityId={entityId}
                    season={CURRENT_SEASON}
                    vendor={vendor}
                  />
                </div>
              </Accordion.InfoItem>
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
      <VendorDocumentsSlider
        entityId={entityId}
        vendorId={vendor.uuid}
        {...vendorDocumentSlider}
      />
    </>
  );
};
