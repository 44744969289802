import { default as classNames, default as cx } from "classnames";
import MarkRepliedUnreplied from "components/chat/MarkRepliedUnreplied";
import { SelectedChannelsContext } from "components/chat/SelectedChannelContext";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import { DynamicStar } from "components/DesignSystem/Star/DynamicStar";
import { Camera } from "components/icons/Chat/Camera";
import { ChatCard } from "components/icons/Chat/ChatCard";
import { CheckSquare } from "components/icons/Chat/CheckSquare";
import { Help } from "components/icons/Chat/Help";
import { NotAllowed } from "components/icons/Chat/NotAllowed";
import { Pin } from "components/icons/Chat/Pin";
import { ColorCheckSquare } from "components/icons/ColorCheckSquare";
import { Document } from "components/icons/Document";
import { PrivateChat } from "components/icons/PrivateChat";
import TaskDetailArchive from "components/icons/TaskDetailArchive";
import { Mention } from "components/MessageBubble/MessageBubble";
import { MessageDateFormat } from "components/MessageDateFormat/MessageDateFormat";
import { PRIVATE_CHANNEL_CUSTOM_TYPE } from "constants/channelType";
import { DELETED_MESSAGE_TEXT, MESSAGE_TYPE_DELETED } from "constants/chatType";
import dayjs from "dayjs";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useContext, useMemo, useRef, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useDispatch, useSelector } from "react-redux";
import { useGetTaskFromChannelIdQuery } from "store/apis/task";
import {
  closeMessageInfoSlider,
  closeShareViaWhatsappSlider,
} from "store/slices/channelInfoSlider";
import { setScrollToMessageId } from "store/slices/messageToScrollTo";
import { resetReviewBalancePaymentState } from "store/slices/reviewAndBalancePayment";
import { RootState } from "store/store";
import { DefaultGenerics } from "stream-chat";
import {
  ChannelPreviewUIComponentProps,
  defaultAllowedTagNames,
  renderText,
  StreamMessage,
} from "stream-chat-react";
import { formatDate } from "utils/formatDate";
dayjs.extend(isYesterday);
dayjs.extend(isToday);

const MessagePreview = ({
  message,
  lastMessageDeleted,
}: {
  message?: StreamMessage<DefaultGenerics>;
  lastMessageDeleted?: boolean;
}) => {
  const renderer = useMemo(
    () =>
      renderText(message?.text, message?.mentioned_users, {
        customMarkDownRenderers: {
          mention: Mention,
        },
        allowedTagNames: [],
      }),
    [message?.text, JSON.stringify(message?.mentioned_users || [])]
  );

  if (lastMessageDeleted) {
    return (
      <div className="t-mt-1 t-break-all t-text-body-sm t-text-text-30 t-line-clamp-1 t-italic">
        <span className="t-mr-1">
          <NotAllowed />
        </span>

        {DELETED_MESSAGE_TEXT}
      </div>
    );
  }
  if (message?.text) {
    return (
      <div className="t-mt-1 t-break-all t-text-body-sm t-text-text-30 t-line-clamp-1">
        {renderer}
      </div>
    );
  }

  if (!message?.text && message?.attachments?.at(-1)?.type === "image") {
    return (
      <div className="t-flex t-gap-1 t-mt-1 t-items-center t-text-neutral-40">
        <Camera />
        <p className="t-break-all t-text-body-sm t-text-text-30 t-line-clamp-1 t-m-0">
          {message.attachments.at(-1)?.fallback}
        </p>
      </div>
    );
  }

  if (
    !message?.text &&
    message?.attachments &&
    message?.attachments?.length > 0
  ) {
    return (
      <div className="t-flex t-gap-1 t-mt-1 t-items-center t-text-neutral-40">
        <Document />
        <p className="t-break-all t-text-body-sm t-text-text-30 t-line-clamp-1 t-m-0">
          {message.attachments.at(-1)?.title}
        </p>
      </div>
    );
  }

  if (message?.custom_data) {
    return (
      <div className="t-flex t-gap-1 t-mt-1 t-items-center t-text-neutral-40">
        <ChatCard />
        <p className="t-break-all t-text-body-sm t-text-text-30 t-line-clamp-1 t-m-0">
          {/* @ts-ignore */}
          {message.custom_data?.header}
        </p>
      </div>
    );
  }

  if (message?.forwarded_message) {
    return (
      <MessagePreview
        message={message.forwarded_message as StreamMessage<DefaultGenerics>}
      />
    );
  }

  return (
    <span className="t-italic t-mt-1 t-break-all t-text-body-sm t-text-text-30 t-line-clamp-1">
      No messages yet
    </span>
  );
};

const PreviewIcon = ({
  isPinned,
  needsReply,
  isPrivate,
  isFrozen,
}: {
  isPinned?: boolean;
  needsReply?: any;
  isPrivate?: boolean;
  isFrozen?: boolean;
}) => {
  if (isFrozen) {
    return (
      <span className="t-text-neutral-70">
        <TaskDetailArchive color="currentColor" />
      </span>
    );
  }

  if (isPrivate) {
    return <PrivateChat />;
  }

  if (needsReply) {
    return <ColorCheckSquare color="currentColor" />;
  }

  if (isPinned) {
    return <Help />;
  }

  return <CheckSquare />;
};

export const ChannelPreview = (
  props: ChannelPreviewUIComponentProps<DefaultGenerics> & {
    selected?: boolean;
    unreplied?: boolean;
    searchValue?: string;
  }
) => {
  const {
    active,
    // Avatar,
    channel,
    // className: customClassName = "",
    // displayImage,
    displayTitle,
    // latestMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    unread,
    watchers,
    lastMessage: propsLastMessage,
    selected,
    searchValue,
  } = props;

  const unreplied = (channel?.data?.needs_reply as boolean) || props.unreplied;

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);
  const { secondary_channel_url } = useCurrentGroup();
  const { isCustomer, isAdmin } = useRoleBasedView();
  const [showDropdown, setShowDropdown] = useState(false);
  const dispatch = useDispatch();
  const showUnreadMentionedIcon = channel.countUnreadMentions() > 0;
  const selectionContext = useContext(SelectedChannelsContext);

  const { selectedChannels, setSelectedChannels } = selectionContext || {};

  const { data: channelTask } = useGetTaskFromChannelIdQuery(
    {
      channelId: channel.id!,
    },
    {
      skip: !channel.id,
    }
  );

  const lastMessage =
    propsLastMessage || channel.state.messageSets[0].messages.at(-1);

  const timestampKey = isCustomer
    ? "last_message_at"
    : "last_non_broadcast_message_at";

  const messageCreatedAt = channel?.data?.[timestampKey] as string | undefined;

  const lastMessageDeleted = lastMessage?.type === MESSAGE_TYPE_DELETED;

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(setScrollToMessageId());
    dispatch(resetReviewBalancePaymentState());
    dispatch(closeShareViaWhatsappSlider());
    dispatch(closeMessageInfoSlider());
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  const isChannelOpen = active || selected;
  const isPinned = secondary_channel_url === channel.id;
  const isPrivate = channel?.data?.custom_type === PRIVATE_CHANNEL_CUSTOM_TYPE;

  const { entity } = channelTask || {};
  const groupName = channel?.data?.group_name as string;
  const season = channel?.data?.season as string;
  const showSeperator = !isCustomer && groupName;

  let unrepliedDateDiffColor = {
    componentClassName: classNames({
      "!t-bg-surface-transparent hover:!t-bg-surface-lighter-grey":
        !isChannelOpen,
    }),
    /* @tw */
    logoClassName: isPrivate
      ? "t-text-neutral-70 t-bg-surface-grey"
      : "t-bg-purple-10 t-text-purple",
  };
  const currentDate = dayjs();
  // @ts-ignore
  const lastReplied = dayjs(props.channel?.data?.last_message_at);
  const daysPassed = currentDate.diff(lastReplied, "days");
  if (daysPassed >= 3 && isAdmin) {
    unrepliedDateDiffColor = {
      componentClassName: classNames({
        "!t-bg-red-20 hover:!t-bg-red-30": !isChannelOpen,
      }),
      logoClassName: classNames("t-bg-red-30 t-text-red", {
        "group-hover:t-bg-red-40": !isChannelOpen,
      }),
    };
  }
  if (daysPassed >= 1 && daysPassed < 3 && isAdmin) {
    unrepliedDateDiffColor = {
      componentClassName: classNames({
        "!t-bg-orange-20 hover:!t-bg-orange-30": !isChannelOpen,
      }),
      logoClassName: classNames("t-bg-orange-30 t-text-orange", {
        "group-hover:t-bg-orange-40": !isChannelOpen,
      }),
    };
  }

  const getBgClassName = () => {
    if (channel.data?.frozen) {
      return "t-bg-neutral-10";
    }

    if (channel?.data?.needs_reply) {
      return [unrepliedDateDiffColor.logoClassName];
    }

    if (isPrivate) {
      return "t-text-neutral-70 t-bg-neutral-0 t-border-neutral-10 t-border t-border-solid";
    }

    return "t-bg-purple-10";
  };

  const isSelected = selectedChannels.includes(channel.id!);

  const onChannelCheck = () =>
    setSelectedChannels((chnls) => {
      if (channel.id) {
        if (chnls.includes(channel.id)) {
          return chnls.filter((ch) => ch !== channel.id);
        } else {
          return [...chnls, channel.id];
        }
      }
      return chnls;
    });

  return (
    <button
      aria-label={`Select Channel: ${displayTitle || ""}`}
      aria-selected={isChannelOpen}
      className={cx(
        `all:unset t-relative t-box-border t-flex t-h-[92px] t-w-full t-gap-2 t-pl-2 t-pr-4 t-py-5 t-overflow-anchor-none t-items-center t-group`,
        {
          "t-bg-surface-purple": isChannelOpen,
          "t-bg-white hover:t-bg-surface-lighter-grey": !isChannelOpen,
          "t-border-solid t-border-surface-transparent t-border-b-surface":
            unreplied,
          [unrepliedDateDiffColor.componentClassName]:
            channel?.data?.needs_reply,
          "t-group/disabled": channel.data?.frozen,
          "t-group": !channel.data?.frozen,
          // @ts-ignore
        }
      )}
      onClick={onSelectChannel}
      ref={channelPreviewButton}
      role="option"
    >
      {isChannelOpen && (
        <div className="t-absolute t-left-0 t-top-0 t-h-full t-border-0 t-border-l-4 t-border-solid t-border-purple-40" />
      )}

      {Boolean(channel.data?.frozen) && (
        <div className="t-absolute t-inset-0 t-bg-white t-opacity-40" />
      )}

      <div
        className={classNames("group-hover:t-opacity-100", {
          "t-opacity-0": !isSelected,
          "t-opacity-100": isSelected,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <Checkbox
          onChange={onChannelCheck}
          checked={Boolean(channel.id && selectedChannels.includes(channel.id))}
        />
      </div>

      <div
        className={classNames(
          "t-flex t-h-10 t-w-10 t-shrink-0 t-items-center t-justify-center t-rounded-full",
          getBgClassName()
        )}
      >
        <PreviewIcon
          isPinned={isPinned}
          isPrivate={isPrivate}
          isFrozen={channel.data?.frozen as boolean}
          needsReply={channel?.data?.needs_reply}
        />
      </div>
      <div className="t-flex t-flex-col t-leading-[normal] t-w-full t-min-w-[50%]">
        <div className="t-flex t-justify-between">
          <div className="t-text-subtext t-line-clamp-1">
            {(displayTitle || channel.data?.name) && (
              <HighlightSearchTerm
                text={displayTitle || channel.data?.name!}
                searchTerm={searchValue || ""}
              />
            )}
          </div>
        </div>
        <MessagePreview
          message={lastMessage}
          lastMessageDeleted={lastMessageDeleted}
        />
        <div className={classNames("t-flex t-items-end")}>
          <>
            {isAdmin && channel?.data?.is_vip && (
              <div className="t-mr-1">
                <DynamicStar isActive />
              </div>
            )}
            {!isCustomer && groupName && (
              <span className="t-text-subtext-sm t-text-blue t-overflow-x-hidden t-text-ellipsis t-whitespace-nowrap">
                <HighlightSearchTerm
                  searchTerm={searchValue || ""}
                  text={groupName}
                />
              </span>
            )}
            {showSeperator && (
              <span className="t-mx-2 t-text-neutral-10">|</span>
            )}
            <>
              {entity && (
                <>
                  <ReactCountryFlag
                    style={{
                      width: "14px",
                      height: "10px",
                      marginBottom: "3px",
                    }}
                    countryCode={entity?.code_alpha_2}
                    svg
                  />
                  <span className="t-inline-block t-ml-1 t-text-subtext-sm t-text-blue t-overflow-x-hidden t-text-ellipsis t-whitespace-nowrap">
                    {entity.name}
                  </span>
                  {season && (
                    <span className="t-mx-2 t-text-neutral-10">|</span>
                  )}
                </>
              )}
              <span className="t-text-body-sm t-text-blue">{season}</span>
            </>
          </>
        </div>
        {/* <Badge color="light-purple" size="small">
            {"Private"}
          </Badge> */}
        {/* <div className="t-mt-1.5 t-text-caption t-text-neutral-40">{date}</div> */}
      </div>

      <div className="t-ml-auto t-flex t-flex-col t-flex-shrink-0 t-items-center t-gap-1.5">
        <div className="t-right-6 t-text-caption t-text-neutral-40 t-self-end">
          <MessageDateFormat createdAt={messageCreatedAt} />
        </div>
        <div className="t-flex t-gap-1.5 t-w-full t-justify-end">
          {Boolean(showUnreadMentionedIcon) && (
            <div className="t-text-purple t-text-subtext">@</div>
          )}
          {!!unread && (
            <div
              data-testid="unread-badge"
              className="t-flex t-h-5 t-min-w-5 t-items-center t-justify-center t-self-center t-rounded-full t-bg-purple-50 t-px-1 t-text-body-sm t-text-white"
            >
              {unread}
            </div>
          )}
          {isPinned && (
            <div className="t-text-purple-30">
              <Pin />
            </div>
          )}
          {isAdmin && (
            <div
              className={classNames("t-group group-hover:t-block", {
                "t-block": showDropdown,
                "t-hidden": !showDropdown,
              })}
            >
              <MarkRepliedUnreplied
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                unreplied={unreplied}
                channelId={channel.id!}
              />
            </div>
          )}
        </div>
      </div>
    </button>
  );
};
