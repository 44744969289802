import Async from "components/DesignSystem/AsyncComponents/Async";
import { Avatar } from "components/DesignSystem/AvatarGroup/Avatar";
import { Button } from "components/DesignSystem/Button/Button";
import { Chip } from "components/DesignSystem/Chips/Chips";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import {
  Filter,
  MultiSelectFilter,
} from "components/DesignSystem/Filter/Filter";
import { HighlightSearchTerm } from "components/DesignSystem/HighlightText";
import Modal from "components/DesignSystem/Modal/Modal";
import { Pagination } from "components/DesignSystem/Pagination/Pagination";
import { Search } from "components/DesignSystem/Search/Search";
import Table from "components/DesignSystem/Table/V2/Table";
import { EmptyInvoiceList } from "components/Illustrations/EmptyInvoiceList";
import { InvoiceCustomerModal } from "components/InvoiceCustomerModal/InvoiceCustomerModal";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import { TableUI } from "components/design/TableUI";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { Contacts as ContactsIcon } from "components/icons/LeftNav/Books/Contacts";
import { OptionDropdown } from "components/icons/optionDropdown";
import {
  CLICKED_ADD_CUSTOMER_IN_INVOICE,
  CLICKED_CUSTOMER_LINE_ITEM_IN_TABLE,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useFilters } from "hooks/useFilter";
import { useModal } from "hooks/useModal";
import { usePagination } from "hooks/usePagination";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import { parse, stringify } from "qs";
import { useMemo, useState } from "react";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  GroupInvoiceCustomer,
  useDeleteCustomerMutation,
  useGetAllInvoiceCustomersListQuery,
} from "store/apis/invoices";
import { BackendError } from "types/utils/error";
import { useDebounce } from "utils/debounce";

const CustomerAction = ({ customer }: { customer: GroupInvoiceCustomer }) => {
  const deleteModal = useModal();
  const editModal = useModal();
  const { alertToast, successToast } = useToast();
  const { uuid: customerId, source } = customer;
  const entityId = useCurrentEntityId();
  const { uuid: groupId } = useCurrentGroupContext();

  const [deleteCustomer, { isLoading }] = useDeleteCustomerMutation();

  const onDelete = async () => {
    try {
      await deleteCustomer({
        groupId,
        customerId,
        entityId,
      }).unwrap();
      deleteModal.close();
      successToast({ message: "Customer deleted!" });
    } catch (error) {
      alertToast(
        { message: (error as BackendError).data?.error?.message },
        error as {}
      );
    }
  };

  const isEditable = source === "MANUAL";
  const isDeletable = source === "MANUAL";

  return (
    <span
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Dropdown.Root>
        <Dropdown.Trigger asChild>
          <span>
            <Button size="small" customType="ghost_icon">
              <OptionDropdown color="currentColor" />
            </Button>
          </span>
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <Dropdown.Content align="end" className="!t-min-w-40">
            <Dropdown.Item
              type="ordinary"
              onSelect={() => {
                editModal.open();
              }}
              disabled={!isEditable}
            >
              <ConditionalToolTip
                condition={!isEditable && "Cannot edit Stripe customer"}
              >
                <span>Edit</span>
              </ConditionalToolTip>
            </Dropdown.Item>
            <Dropdown.Item
              type="danger"
              onSelect={() => {
                deleteModal.open();
              }}
              disabled={!isDeletable}
            >
              <ConditionalToolTip
                condition={!isDeletable && "Cannot delete Stripe customer"}
              >
                <span>Delete</span>
              </ConditionalToolTip>
            </Dropdown.Item>
          </Dropdown.Content>
        </Dropdown.Portal>
      </Dropdown.Root>

      <Modal.Root open={deleteModal.isOpen} onOpenChange={deleteModal.close}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Are you sure?</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            The selected customer will be permanently deleted. This action
            cannot be undone.
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild disabled={isLoading}>
              <Button disabled={isLoading}>Cancel</Button>
            </Modal.RawClose>
            <Button
              customType="danger"
              onClick={onDelete}
              isLoading={isLoading}
              disabled={isLoading}
            >
              Confirm
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
      <InvoiceCustomerModal
        customerId={editModal.isOpen ? customerId : null}
        setCustomerId={() => editModal.close()}
      />
    </span>
  );
};

export const InvoiceCustomers = () => {
  const group = useCurrentGroupContext();
  const { url } = useRouteMatch();
  const history = useHistory();
  const [customerId, setCustomerId] = useState<string | null>(null);

  const location = useLocation();
  const parsedSearch = parse(location.search, { ignoreQueryPrefix: true });
  const entityId = useCurrentEntityId();

  const search = stringify(
    {
      entity: parsedSearch.entity,
      company: parsedSearch.company,
    },
    { skipNulls: true, addQueryPrefix: true }
  );

  const { values: filterValues, updateFilter } = useFilters({
    initialValue: {
      SOURCES: [] as string[],
    },
  });

  const query = useQuery();
  const { update } = useUpdateQuery();
  const page = query.get("page");
  const { trackEvent } = useAnalytics();

  const searchValue = query.get("search");
  const searchTerm = useDebounce(searchValue);

  const {
    data: invoiceCustomers,
    isLoading: loadingCustomers,
    isSuccess: loadedCustomers,
  } = useGetAllInvoiceCustomersListQuery(
    {
      groupId: group?.uuid!,
      entityId: entityId!,
      pageNum: page || "1",
      searchTerm,
      sources: filterValues.SOURCES.join(","),
    },
    { skip: !group?.uuid || !entityId }
  );

  const paginationData = {
    totalPage: invoiceCustomers?.total_pages!,
    currentPage: invoiceCustomers?.current_page!,
    itemsPerPage: invoiceCustomers?.per_page!,
    totalItemCount: invoiceCustomers?.total_count!,
  };

  const { goToFirstPage, goToPrevPage, goToLastPage, goToNextPage } =
    usePagination({
      pageNumber: Number(page || 1),
      onPageNumChange: (page) => update({ query: "page", value: page }),
      totalPage: invoiceCustomers?.total_pages,
    });

  const createColumn = createColumnHelper<GroupInvoiceCustomer>();

  const columns = useMemo(
    () => [
      createColumn.accessor("uuid", {
        size: 1,
        header: "NO.",
        cell: (info) => {
          return <div className="t-text-center">{info.row.index + 1}</div>;
        },
      }),

      createColumn.accessor("email", {
        size: 29,
        header: "NAME",
        cell: (info) => (
          <div>
            <div className="t-text-body t-text-text-60">
              <HighlightSearchTerm
                text={info.row.original.name}
                searchTerm={searchValue || ""}
              />
            </div>
            <div className="t-text-body-sm t-text-text-30">
              <HighlightSearchTerm
                text={info.getValue()}
                searchTerm={searchValue || ""}
              />
            </div>
          </div>
        ),
      }),

      createColumn.accessor("paid_amount", {
        size: 15,
        header: () => <p className="t-m-0 t-text-right">AMOUNT PAID</p>,
        cell: (info) => (
          <div className="t-text-right">
            <AmountSuperScript amount={Number(info.getValue() || "0")} />
          </div>
        ),
      }),

      createColumn.accessor("due_amount", {
        size: 15,
        header: () => <div className="t-text-right">AMOUNT DUE</div>,
        cell: (info) => (
          <div className="t-text-right">
            <AmountSuperScript amount={info.getValue()} />
          </div>
        ),
      }),

      createColumn.accessor("total_amount", {
        id: "Total inovice",
        size: 25,
        header: () => (
          <p className="t-m-0 t-text-right">TOTAL INVOICED AMOUNT</p>
        ),
        cell: (info) => (
          <div className="t-text-right">
            <AmountSuperScript amount={Number(info.getValue() || "0")} />
          </div>
        ),
      }),

      createColumn.accessor("source", {
        size: 10,
        header: () => <div className="t-flex t-justify-center">SOURCE</div>,
        cell: (info) => {
          const { logo_url } = info.row.original;

          return (
            <div className="t-flex t-justify-center">
              <ConditionalToolTip
                condition={
                  <div className="t-lowercase first-letter:t-capitalize">
                    {info.getValue()}
                  </div>
                }
              >
                {logo_url ? (
                  <Avatar size="small" src={logo_url} alt={info.getValue()} />
                ) : (
                  <span
                    className="t-rounded-full t-bg-white t-overflow-hidden t-min-h-6 t-min-w-6 t-max-h-6 t-max-w-6 t-text-neutral-40 t-border-neutral-0 t-border t-border-solid t-text-center"
                    role="img"
                  >
                    <ContactsIcon />
                  </span>
                )}
              </ConditionalToolTip>
            </div>
          );
        },
      }),

      createColumn.accessor("uuid", {
        size: 5,
        header: "",
        cell: (info) => {
          return <CustomerAction customer={info.row.original} />;
        },
      }),
    ],
    [searchValue]
  );

  const customers = useMemo(
    () => invoiceCustomers?.client_customers,
    [invoiceCustomers]
  );

  const table = useReactTable({
    data: customers || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
    },
  });

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    update({ query: "search", value: e.target.value });
  };

  const handleAddCustomer = () => {
    setCustomerId("NEW");
    trackEvent(CLICKED_ADD_CUSTOMER_IN_INVOICE);
  };

  const handleCustomerRowClick = (rowUuid: string) => {
    history.push(`${url}/${rowUuid}${search}`);
    trackEvent(CLICKED_CUSTOMER_LINE_ITEM_IN_TABLE);
  };

  const isFillterApplied = filterValues.SOURCES.length > 0;

  return (
    <div className="t-text-text-60 t-flex t-gap-3 t-flex-col t-relative">
      <div className="t-flex t-gap-5 t-flex-col t-sticky t-top-0 t-z-header t-bg-white">
        <div className="t-flex t-justify-between t-gap-5 t-mb-3">
          <div className="t-w-1/2">
            <Search
              block
              onChange={onSearch}
              value={searchValue || ""}
              placeholder="Search"
            />
          </div>

          <Button onClick={handleAddCustomer} size="small" customType="primary">
            Add customer
          </Button>
        </div>

        <div className="t-flex t-justify-between">
          <Filter.Root
            defaultValue="SOURCES"
            capsule={
              <>
                {filterValues.SOURCES.map((value) => {
                  return (
                    <Chip
                      key={value}
                      onClose={() => {
                        updateFilter(
                          "SOURCES",
                          filterValues.SOURCES.filter((v) => v !== value)
                        );
                      }}
                      isActive
                      filterType="SOURCES"
                    >
                      <span className="first-letter:t-uppercase t-lowercase">
                        {value}
                      </span>
                    </Chip>
                  );
                })}
              </>
            }
          >
            <Filter.Portal>
              <Filter.List>
                <Filter.ListItem value="SOURCES">Sources</Filter.ListItem>
              </Filter.List>

              <Filter.Body value="SOURCES" block>
                <MultiSelectFilter
                  isSearchable={false}
                  onChange={(newValues) => updateFilter("SOURCES", newValues)}
                  options={[
                    {
                      value: "STRIPE",
                      label: "Stripe",
                    },
                    {
                      value: "MANUAL",
                      label: "Manually added",
                    },
                  ]}
                  selected={filterValues.SOURCES}
                />
              </Filter.Body>
            </Filter.Portal>
          </Filter.Root>
          <Pagination
            {...paginationData}
            goToFirstPage={goToFirstPage}
            goToPrevPage={goToPrevPage}
            goToNextPage={goToNextPage}
            goToLastPage={goToLastPage}
          />
        </div>
      </div>
      <Async.Root
        isLoading={loadingCustomers}
        isSuccess={loadedCustomers}
        isEmpty={customers?.length === 0}
      >
        <Async.Success>
          <TableUI table={table} size="small">
            {({ row }) => (
              <Table.Row
                key={row.id}
                onRowClick={() => handleCustomerRowClick(row.original.uuid)}
                className="t-cursor-pointer"
                tabIndex={0}
                role="button"
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <Table.Cell
                      key={cell.id}
                      style={{ width: `${cell.column.getSize()}%` }}
                      aria-label={cell.column.id}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Table.Cell>
                  );
                })}
              </Table.Row>
            )}
          </TableUI>
        </Async.Success>
        <Async.Empty>
          <div className="t-flex t-flex-col t-items-center t-justify-center t-h-72 t-text-text-60 t-gap-3">
            <EmptyInvoiceList />
            {searchValue && (
              <>
                <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center">
                  <p className="t-m-0 t-text-subtitle-sm">
                    No results found for your search
                  </p>
                  <p className="t-m-0 t-text-body-sm">
                    Try clearing the search.
                  </p>
                </div>
                <Button
                  onClick={() => update({ query: "search", value: "" })}
                  size="small"
                  customType="primary"
                >
                  Clear search
                </Button>
              </>
            )}

            {isFillterApplied && (
              <>
                <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center">
                  <p className="t-m-0 t-text-subtitle-sm">No results found</p>
                  <p className="t-m-0 t-text-body-sm">
                    Try clearing the filter.
                  </p>
                </div>
                <Button
                  onClick={() => updateFilter("SOURCES", [])}
                  size="small"
                  customType="primary"
                >
                  Clear filter
                </Button>
              </>
            )}
            {!isFillterApplied && !searchValue && (
              <>
                <div className="t-flex t-flex-col t-gap-2 t-justify-center t-items-center">
                  <p className="t-m-0 t-text-subtitle-sm">Add first customer</p>
                  <p className="t-m-0 t-text-body-sm">
                    Add & manage receivables for customers
                  </p>
                </div>
                <Button
                  onClick={() => setCustomerId("NEW")}
                  size="small"
                  customType="primary"
                >
                  Add customer
                </Button>
              </>
            )}
          </div>
        </Async.Empty>
      </Async.Root>
      <InvoiceCustomerModal
        customerId={customerId}
        setCustomerId={setCustomerId}
      />
    </div>
  );
};
