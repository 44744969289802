import { FormDataConfiguration } from "types/Models/task";
import { array, object, string } from "yup";

export const getValidationSchema = (formData: FormDataConfiguration) => {
  let shape: Record<string, any> = {};

  formData?.data?.data?.section_group?.forEach((group) => {
    group.sections?.forEach((section) => {
      section.section_fields?.forEach((field) => {
        if (field.required) {
          if (field.type === "email") {
            shape[field.field_key] = string().when({
              is: () => !!document.querySelector(`[name="${field.field_key}"]`),
              then: string()
                .trim()
                .required(`${field.field_label || "Email"} is required`),
              otherwise: string().nullable().notRequired(),
            });
          } else if (["file_upload", "multiselect"].includes(field.type)) {
            shape[field.field_key] = array().when({
              is: () => !!document.querySelector(`[name="${field.field_key}"]`),
              then: array().min(1, `${field.field_label} is required`),
              otherwise: array().notRequired(),
            });
          } else {
            shape[field.field_key] = string().when({
              is: () => !!document.querySelector(`[name="${field.field_key}"]`),
              then: string()
                .trim()
                .required(`${field.field_label || "This field"} is required`),
              otherwise: string().nullable().notRequired(),
            });
          }
        }
      });
    });
  });

  return object().shape(shape);
};
