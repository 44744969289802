import { QueryStatus } from "@reduxjs/toolkit/query";
import Loader from "components/design/loader";
import ToolTip from "components/design/toolTip";
import Async from "components/DesignSystem/AsyncComponents/Async";
import Breadcrumb from "components/DesignSystem/Breadcrumb/Breadcrumb";
import { Button } from "components/DesignSystem/Button/Button";
import Card from "components/DesignSystem/Card/Card";
import {
  Combobox,
  OptionData,
} from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import Modal from "components/DesignSystem/Modal/Modal";
import { FloatingChat } from "components/floatingChat/FloatingChat";
import { InfoSolid } from "components/InfoSolid";
import { FINANCIAL_CLOSING_CARD_TYPE } from "constants/chatType";
import { MONTH_FORMAT, YYYY_MM_DD } from "constants/date";
import {
  CHECKLIST_ITEM_SECTION,
  CLOSING_STATUS,
} from "constants/financialClosing";
import dayjs from "dayjs";
import { FINANCIAL_CLOSING_ACTION_NAME } from "dictionaries";
import { Field, FieldProps, Form, Formik, useFormikContext } from "formik";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useModal } from "hooks/useModal";
import { usePageTitle } from "hooks/usePageTitle";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useToast } from "hooks/useToast";
import { ComponentProps } from "react";
import { useParams } from "react-router-dom";
import { SingleValue } from "react-select";
import DoubleGreenTick from "static/images/DoubleGreenTick.svg";
import {
  ChecklistItem,
  FinancialClosing,
  FinancialClosingDetail,
  useGetFinancialClosingQuery,
  useUpdateFinancialClosingMutation,
  useUpdateFinancialClosingStatusMutation,
} from "store/apis/financialClosing";
import { openFloatingChat, setToOpenChatId } from "store/slices/chat";
import { setChatCardAttachment } from "store/slices/financialClosing";
import { BackendError } from "types/utils/error";
import { debounce } from "utils/debouncing";
import { Checklist } from "./CheckList";
import { Comments } from "./Comments";
import { ClosingStatus, Period } from "./FinancialClosing";
import { ReviewFinancialsModal } from "./ReviewFinancialsModal";
import { SummaryCard } from "./Summary";
import { OtherAttachments } from "./OtherAttachments";

const useChangeStatusAllowed = ({
  status,
  is_service_team_user,
}: {
  status: FinancialClosingDetail["status"];
  is_service_team_user: FinancialClosingDetail["creator"]["is_service_team_user"];
}) => {
  const { isCpa, isCustomer } = useRoleBasedView();

  if (status === CLOSING_STATUS.COMPLETED) {
    return false;
  }

  if (!is_service_team_user) {
    return true;
  }

  const ACTION_DISABLED = {
    [CLOSING_STATUS.IN_PROGRESS]: isCpa,
    [CLOSING_STATUS.REVIEW_PENDING]: isCustomer,
  };

  return ACTION_DISABLED[status];
};

const useEditAllowed = ({
  status,
  is_service_team_user,
}: {
  status: FinancialClosingDetail["status"];
  is_service_team_user: FinancialClosingDetail["creator"]["is_service_team_user"];
}) => {
  const { isCpa, isCustomer } = useRoleBasedView();

  if (status === CLOSING_STATUS.COMPLETED) {
    return false;
  }

  if (!is_service_team_user || isCpa) {
    return true;
  }

  const ACTION_DISABLED = {
    [CLOSING_STATUS.IN_PROGRESS]: isCpa,
    [CLOSING_STATUS.REVIEW_PENDING]: !isCustomer,
  };

  return ACTION_DISABLED[status];
};

const useGetApiStatus = (endpointName: string) => {
  const state = useAppSelector((state) => state.api.mutations);

  const updateFinancialClosing = Object.values(state).find(
    (e) => e?.endpointName === endpointName
  );

  const isLoading = updateFinancialClosing?.status === QueryStatus.pending;

  const isSuccess = updateFinancialClosing?.status === QueryStatus.fulfilled;

  return { isLoading, isSuccess };
};

export const Info = (
  props: Omit<ComponentProps<typeof ToolTip>, "children">
) => {
  return (
    <ToolTip {...props}>
      <span className="t-text-text-30">
        <InfoSolid size="16" color="currentColor" />
      </span>
    </ToolTip>
  );
};

const Details = ({ isLoading }: { isLoading: boolean }) => {
  const { values, setFieldValue } = useFormikContext<FinancialClosingDetail>();
  const { financialClosingId } = useParams<{ financialClosingId: string }>();
  const entityId = useCurrentEntityId();
  const { alertToast } = useToast();

  const accountingMethods = [
    { label: "Cash", value: "CASH" },
    {
      label: "Accrual",
      value: "ACCRUAL",
    },
  ];

  const defaultValue = accountingMethods.find(
    ({ value }) => value === values.accounting_method
  );

  const [updateFinancialClosing] = useUpdateFinancialClosingMutation();

  const onDetailsChange = async (values: {
    accounting_method?: FinancialClosingDetail["accounting_method"];
    start_date?: string;
    end_date?: string;
  }) => {
    try {
      await updateFinancialClosing({
        entityId: entityId,
        financialClosingId: financialClosingId,
        payload: {
          accounting_method: values.accounting_method,
          start_date: values.start_date,
          end_date: values.end_date,
        },
      }).unwrap();
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  return (
    <Card.Root className="t-pb-5">
      <Card.Header>
        <Card.Title>Details</Card.Title>
      </Card.Header>
      <Card.Body className="t-flex t-flex-col t-gap-4">
        <div className="t-flex t-gap-4">
          <Field name="start_date">
            {({ field }: FieldProps) => {
              return (
                <DateInput
                  disabled={isLoading}
                  customSize="small"
                  {...field}
                  label="Start from"
                  placeholder="Select date"
                  block
                  showMonthYearPicker
                  dateFormat={MONTH_FORMAT}
                  required
                  onDateChange={debounce((date) => {
                    const start_date = dayjs(date).format(YYYY_MM_DD);
                    if (start_date === values.start_date) {
                      return;
                    }
                    onDetailsChange({
                      start_date,
                    });
                  })}
                />
              );
            }}
          </Field>
          <Field name="end_date">
            {({ field }: FieldProps) => {
              return (
                <DateInput
                  disabled={isLoading}
                  customSize="small"
                  {...field}
                  label="To"
                  placeholder="Select date"
                  block
                  showMonthYearPicker
                  dateFormat={MONTH_FORMAT}
                  required
                  onDateChange={debounce((date) => {
                    const end_date = dayjs(date).format(YYYY_MM_DD);
                    if (end_date === values.end_date) {
                      return;
                    }
                    onDetailsChange({
                      end_date,
                    });
                  })}
                />
              );
            }}
          </Field>
        </div>
        <Combobox
          isDisabled={isLoading}
          menuPortalTarget={document.body}
          size="small"
          label="Accounting method"
          name="accounting_method"
          defaultValue={defaultValue}
          options={accountingMethods}
          isClearable={false}
          required
          onChange={debounce((option) => {
            const value = (option as SingleValue<OptionData>)?.value;

            if (value === values.accounting_method) {
              return;
            }

            onDetailsChange({
              accounting_method:
                value as FinancialClosingDetail["accounting_method"],
            });
            setFieldValue("accounting_method", value);
          })}
        />

        <InfoItem
          label="Status"
          dir="row"
          className="t-gap-8 t-flex t-items-center"
        >
          <ClosingStatus status={values.status} />
        </InfoItem>
        {values?.preparer && (
          <InfoItem label="Preparer" dir="row" className="t-gap-8">
            <span className="t-text-subtext-sm t-text-text-100">
              {values.preparer}
            </span>
          </InfoItem>
        )}
      </Card.Body>
    </Card.Root>
  );
};

export const FinancialClosingDetails = ({
  breadcrumbs,
  close,
}: {
  breadcrumbs: {
    name: string;
    link: string;
  }[];
  close: () => void;
}) => {
  usePageTitle("Financial closing details");
  const { financialClosingId } = useParams<{ financialClosingId: string }>();
  const entityId = useCurrentEntityId();
  const { alertToast, successToast } = useToast();
  const reviewModal = useModal();
  const { entities } = useCurrentGroupContext();
  const dispatch = useAppDispatch();

  const channelId = entities.find(
    ({ uuid }) => uuid === entityId
  )?.bookkeeping_channel;

  const {
    data: financialClosingDetails,
    isLoading,
    isSuccess,
  } = useGetFinancialClosingQuery({
    entityId: entityId,
    financialClosingId: financialClosingId,
  });

  const {
    checklist_items = [],
    closing_card_details,
    summary,
    other_financials = [],
  } = financialClosingDetails || {};

  const updateFinancialClosingApiState = useGetApiStatus(
    "updateFinancialClosing"
  );
  const addOtherFinancialsApiState = useGetApiStatus("addOtherFinancials");
  const deleteOtherFinancialsApiState = useGetApiStatus(
    "deleteOtherFinancials"
  );
  const updateFinancialClosingCheckListApiState = useGetApiStatus(
    "updateFinancialClosingCheckList"
  );

  const isUpdating =
    updateFinancialClosingApiState.isLoading ||
    addOtherFinancialsApiState.isLoading ||
    deleteOtherFinancialsApiState.isLoading ||
    updateFinancialClosingCheckListApiState.isLoading;

  const success =
    updateFinancialClosingApiState.isSuccess &&
    addOtherFinancialsApiState.isSuccess &&
    deleteOtherFinancialsApiState.isSuccess &&
    updateFinancialClosingCheckListApiState.isSuccess;

  const [updateStatus, { isLoading: isStatusUpdating }] =
    useUpdateFinancialClosingStatusMutation();

  const checkIsReviewed = () => {
    const finalizeFinancials = checklist_items.filter(
      ({ section }) => section === CHECKLIST_ITEM_SECTION.FINALIZE_FINANCIALS
    );

    const isReviewed = finalizeFinancials.every(
      (item) => item.resolved_by_admin
    );
    if (isReviewed) {
      return true;
    } else {
      reviewModal.open();
      return false;
    }
  };

  const onChangeStatus = async (status: FinancialClosingDetail["status"]) => {
    try {
      const isReviewed =
        status === CLOSING_STATUS.COMPLETED ? checkIsReviewed() : true;

      if (isReviewed) {
        if (!channelId) {
          return alertToast({
            message: "No chat channel found for this entity",
          });
        }

        // If the status is REVIEW_PENDING, send the card to the chat
        if (status === CLOSING_STATUS.REVIEW_PENDING && closing_card_details) {
          dispatch(openFloatingChat());
          dispatch(setToOpenChatId(channelId));
          dispatch(
            setChatCardAttachment({
              entityId: entityId,
              financialClosingId: financialClosingId,
              card_type: FINANCIAL_CLOSING_CARD_TYPE.REVIEW_FINANCIAL,
              start_date: closing_card_details?.start_date,
              end_date: closing_card_details?.end_date,
              status,
            })
          );
          return;
        }

        await updateStatus({
          entityId: entityId,
          financialClosingId: financialClosingId,
          payload: {
            status,
          },
        }).unwrap();

        successToast({
          message:
            status === CLOSING_STATUS.COMPLETED
              ? "Marked as reviewed"
              : "Sent for review",
        });
      }
    } catch (error) {
      alertToast(
        {
          message: (error as BackendError).data?.error?.message,
        },
        error as Error
      );
    }
  };

  const isChangeStatusAllowed = useChangeStatusAllowed({
    status: closing_card_details?.status || CLOSING_STATUS.COMPLETED,
    is_service_team_user:
      closing_card_details?.creator.is_service_team_user || false,
  });

  const isEditAllowed = useEditAllowed({
    status: closing_card_details?.status || CLOSING_STATUS.COMPLETED,
    is_service_team_user:
      closing_card_details?.creator.is_service_team_user || false,
  });

  if (isLoading || !isSuccess) {
    return (
      <Modal.Root open={true}>
        <Modal.Content size="fullscreen">
          <Loader />
        </Modal.Content>
      </Modal.Root>
    );
  }

  if (!closing_card_details) {
    return;
  }

  return (
    <>
      <Modal.Root open={true} modal={false} onOpenChange={close}>
        <Modal.Content size="fullscreen">
          <div className="t-px-10 t-h-full t-w-full t-overflow-auto">
            <Modal.Header className="t-sticky t-top-0 t-bg-white t-z-header">
              <div>
                <Modal.Subtitle>
                  <Breadcrumb.Root>
                    {breadcrumbs.map((e) => (
                      <Breadcrumb.Item link={e.link} key={e.link}>
                        {e.name}
                      </Breadcrumb.Item>
                    ))}
                    <Breadcrumb.Item link="">
                      <Period
                        end_date={closing_card_details.end_date}
                        start_date={closing_card_details.start_date}
                      />
                    </Breadcrumb.Item>
                  </Breadcrumb.Root>
                </Modal.Subtitle>
                <Modal.Title>
                  <div className="t-flex t-items-center t-gap-4">
                    Add financials
                    <span className="t-text-body-sm t-text-text-30">
                      <Async.Root
                        isLoading={
                          isUpdating ||
                          updateFinancialClosingCheckListApiState.isLoading
                        }
                        customLoader={<Loader customType="secondary" />}
                        isEmpty={false}
                        isSuccess={
                          success ||
                          updateFinancialClosingCheckListApiState.isSuccess
                        }
                      >
                        <Async.Empty>
                          <></>
                        </Async.Empty>
                        <Async.Success>
                          <span className="t-flex t-items-center t-gap-2 t-transition-all">
                            <img src={DoubleGreenTick} alt="Saved" />
                            Changes saved
                          </span>
                        </Async.Success>
                      </Async.Root>
                    </span>
                  </div>
                </Modal.Title>
              </div>
              <Modal.Close />
            </Modal.Header>
            <Modal.Body className="t-grid t-grid-cols-[2fr_1fr] t-gap-4">
              <div className="t-flex t-flex-col t-gap-4">
                <fieldset
                  disabled={!isEditAllowed}
                  className="disabled:t-pointer-events-none disabled:t-cursor-not-allowed disabled:t-opacity-90"
                >
                  <Formik<FinancialClosingDetail>
                    initialValues={
                      financialClosingDetails?.closing_card_details
                    }
                    onSubmit={() => {}}
                  >
                    <Form>
                      <Details
                        isLoading={
                          isUpdating ||
                          updateFinancialClosingCheckListApiState.isLoading
                        }
                      />
                    </Form>
                  </Formik>
                </fieldset>

                <fieldset
                  disabled={!isEditAllowed}
                  className="disabled:t-pointer-events-none disabled:t-cursor-not-allowed disabled:t-opacity-90"
                >
                  <Formik<{ checklist_items: ChecklistItem[] }>
                    initialValues={{
                      checklist_items: checklist_items,
                    }}
                    onSubmit={() => {}}
                  >
                    <Form>
                      <Checklist closingDetails={closing_card_details} />
                    </Form>
                  </Formik>
                </fieldset>

                <fieldset
                  disabled={!isEditAllowed}
                  className="disabled:t-pointer-events-none disabled:t-cursor-not-allowed disabled:t-opacity-90"
                >
                  <Formik<{
                    other_financials: FinancialClosing["other_financials"];
                  }>
                    initialValues={{
                      other_financials: other_financials,
                    }}
                    onSubmit={() => {}}
                  >
                    <Form>
                      <OtherAttachments other_financials={other_financials} />
                    </Form>
                  </Formik>
                </fieldset>
              </div>
              <div className="t-flex t-flex-col t-gap-4">
                {summary && (
                  <SummaryCard
                    editable={isEditAllowed}
                    summary={summary}
                    financialClosingId={financialClosingId}
                  />
                )}
                <Comments financialClosingId={financialClosingId} />
              </div>
            </Modal.Body>
          </div>

          {closing_card_details.status !== "COMPLETED" && (
            <Modal.Footer className="t-flex t-justify-between">
              <Button onClick={close}>Back</Button>
              <Button
                customType="primary"
                disabled={!isChangeStatusAllowed || isStatusUpdating}
                onClick={() =>
                  onChangeStatus(
                    closing_card_details.status ===
                      CLOSING_STATUS.REVIEW_PENDING
                      ? CLOSING_STATUS.COMPLETED
                      : CLOSING_STATUS.REVIEW_PENDING
                  )
                }
                isLoading={isStatusUpdating}
              >
                {FINANCIAL_CLOSING_ACTION_NAME[closing_card_details.status]}
              </Button>
            </Modal.Footer>
          )}
          <ReviewFinancialsModal
            isOpen={reviewModal.isOpen}
            close={reviewModal.close}
            financialClosingId={
              financialClosingDetails.closing_card_details.uuid
            }
          />
          <FloatingChat position={{ bottom: 90 }} />
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
