import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ONBOARDING_PRODUCTS } from "types/contants/onboardingProducts";

export interface OnboardingWidgetState {
  isOpen: boolean;
  product: ONBOARDING_PRODUCTS;
  entityId: string | null;
}

const initialState: OnboardingWidgetState = {
  isOpen: false,
  product: "Tax V2",
  entityId: null,
};

export const onboardingWidgetSlice = createSlice({
  name: "onboardingWidget",
  initialState,
  reducers: {
    openOnboardingWidget: (
      state,
      action: PayloadAction<{
        product?: ONBOARDING_PRODUCTS;
        entityId?: string;
      }>
    ) => {
      state.isOpen = true;
      state.product = action.payload.product || state.product;
      state.entityId = action.payload.entityId || null;
    },
    closeOnboardingWidget: (state) => {
      state.isOpen = false;
    },
  },
});

export const { openOnboardingWidget, closeOnboardingWidget } =
  onboardingWidgetSlice.actions;

export default onboardingWidgetSlice.reducer;
