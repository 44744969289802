import { Button } from "components/DesignSystem/Button/Button";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { teamManagementInvite } from "formValidations/teamManagement";
import { Form, Formik } from "formik";
import { useToast } from "hooks/useToast";
import authContext from "jwt_context&axios/authContext";
import React, { useContext } from "react";
import {
  useGetChatRoleListQuery,
  useInviteManageTeamMembersMutation,
} from "store/apis/teamSettingCPA";

export const InviteTeammate = ({
  showInviteModal,
  setShowInviteModal,
  groupDomain,
}: {
  showInviteModal: boolean;
  setShowInviteModal: (newState: React.SetStateAction<boolean>) => void;
  groupDomain?: string;
}) => {
  const { successToast, alertToast } = useToast();
  const [inviteTeamMember, { isLoading }] =
    useInviteManageTeamMembersMutation();
  const { authtoken } = useContext(authContext);
  const domain = groupDomain || authtoken.email.split("@")?.[1];
  const { data: roles = [] } = useGetChatRoleListQuery();

  const handleInvite = async (values: {
    first_name: string;
    last_name: string;
    email: string;
    chat_role: string;
  }) => {
    try {
      await inviteTeamMember({ payload: values }).unwrap();
      successToast({ message: "Invite sent successfully." });
      setShowInviteModal(false);
    } catch (err: any) {
      alertToast({ message: "Failed to send an invite." });
      setShowInviteModal(false);
    }
  };
  return (
    <Modal.Root open={showInviteModal} onOpenChange={setShowInviteModal}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Invite member</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          onSubmit={handleInvite}
          initialValues={{
            first_name: "",
            last_name: "",
            email: "",
            chat_role: "",
          }}
          validationSchema={teamManagementInvite({ domain })}
        >
          {({ isSubmitting, values }) => (
            <Form className="all:unset">
              <Modal.Body className="t-flex t-flex-col t-gap-4">
                <div className="t-flex t-gap-2.5">
                  <TextInput
                    label="First Name"
                    name="first_name"
                    placeholder="John"
                  />
                  <TextInput
                    label="Last Name"
                    name="last_name"
                    placeholder="Doe"
                  />
                </div>
                <TextInput
                  label="Email"
                  name="email"
                  placeholder={`example@${domain}`}
                />
                <Combobox
                  label="Role"
                  name="chat_role"
                  options={
                    roles?.map((role) => ({
                      label: role.role_name,
                      value: role.role_name,
                    })) || []
                  }
                  placeholder="Select role"
                  withForm
                  menuPortalTarget={document.body}
                />
              </Modal.Body>
              <Modal.Footer className="t-flex t-gap-3 t-justify-end">
                <Button type="button" onClick={() => setShowInviteModal(false)}>
                  Cancel
                </Button>
                <Button
                  customType="primary"
                  isLoading={isLoading}
                  disabled={isLoading}
                >
                  Send Invite
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
