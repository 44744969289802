import * as bootstrap from "bootstrap";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { Field, useFormikContext } from "formik";
import { useRoleBasedView } from "hooks/useRoleBasedView";
import { useEffect, useMemo, useRef } from "react";
import { Form } from "react-bootstrap";
import { Autofill } from "../AutoFill/autofill";
import { Message } from "./helper";
import { FormUploadDocuments } from "components/FormUploadDocuments";
import SuggestedDocument from "components/documents/suggestedDocument/suggestedDocument";
import { MobileInput } from "components/DesignSystem/MobileInput/MobileInput";
import { FilingEmailInput } from "./FilingEmailInput";
import { DoBooksWithInkle } from "./DoBooksWithInkle";
import { FilingDirectors } from "./FilingDirectors";
import { ADDRESS_AUTOFILL_VERSION_2_0 } from "constants/task";
import { SelectAutofill } from "./SelectAutofill";
import { AddBankAccount } from "./AddBankAccount";
import { AddFilingManualBank } from "./AddFilingManualBank";
import { AddEditAddress } from "./AddEditAddress";
import ToolTip from "components/design/toolTip";
import { SuggestedDocumentDND } from "./SuggestedDocumentDND";
import { AddEditPersona } from "./AddEditPersona";
import { AddShareholder } from "components/Entity/Shareholders/AddShareholder";
import ShareholderAutofill from "./ShareholderAutofill";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import { FilingAuthorizedSignatory } from "./FilingAuthorizedSignatory";
import { FilingPrincipalOfficer } from "./FilingPrincipalOfficer";
import { AddFinancialClosing } from "./FinancialClosing";
import { TaskConnectBank } from "components/TaskConnectBank";
import Radio from "./FilingRadioGroup";
import FilingInfoIcon from "static/images/FilingInfo.svg";
import { YYYY_MM_DD } from "constants/date";
import { AnnualCatchup } from "./AnnualCatchup";
import { AnnualBooksCalculate } from "./AnnualBooksCalculate";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import {
  useGetTaskListForServiceTeamQuery,
  useGetTaskListForUserQuery,
} from "store/apis/taskList";
import { getIn } from "formik";
import { PriceInput } from "components/PriceInput/PriceInput";

dayjs.extend(customParseFormat);

const CombinedComponents = (props) => {
  return (
    <>
      <AddBankAccount {...props} />
      <AddFilingManualBank {...props} />
    </>
  );
};

export const FormTooltip = ({ tooltipText }) => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current) {
      new bootstrap.Tooltip(ref.current);
    }
  }, []);
  return (
    <ToolTip text={tooltipText} side="right">
      <img src={FilingInfoIcon} alt="info" className="t-ml-1" />
    </ToolTip>
  );
};

const Label = ({ label, tooltip, mandatory, name }) => {
  return (
    <Form.Label htmlFor={name} className="m-0">
      <span
        className="dangerLabel t-font-sans t-text-body-sm t-text-text-30 t-pb-1.5"
        dangerouslySetInnerHTML={{ __html: label }}
      ></span>
      {tooltip && <FormTooltip tooltipText={tooltip} />}
      {mandatory && <span className="t-text-red t-ml-1">*</span>}
    </Form.Label>
  );
};

const CustomField = ({ name, onChange, component: Component, ...props }) => {
  return (
    <Field name={name}>
      {({
        field, // { name, value, onChange, onBlur }
        form: { touched, errors },
      }) => {
        const hasError = getIn(errors, field.name);
        return (
          <>
            <Component
              {...props}
              {...field}
              onChange={(e) => {
                onChange(e);
                field.onChange(e);
              }}
            />
            {hasError && (
              <span className="t-text-body-sm t-text-red t-mt-[6px]">
                Please enter a value
              </span>
            )}
          </>
        );
      }}
    </Field>
  );
};

export const FormControl = ({
  authtoken,
  type,
  label,
  options = [],
  tooltip,
  descript,
  defaultValue,
  mandatory,
  current,
  data,
  uuid,
  groupId,
  autofillKey,
  autofillPrefix,
  autofillSuffix,
  updatedTask,
  formId,
  document,
  sectionKey,
  tag,
  field,
  isArchived,
  entityId,
  section,
  formData,
  fields,
  season,
  deadline,
  ...props
}) => {
  const { isCustomer, isAdmin, isAnyServiceUser } = useRoleBasedView();
  const { values } = useFormikContext();
  const { total_revenue, total_expense, r_and_d_expense = 0 } = values;

  const netProfit = useMemo(() => {
    if (!total_revenue || !total_expense) {
      return null;
    }

    return (
      Number(total_revenue) -
      (Number(total_expense) - Number(r_and_d_expense)) -
      Number(r_and_d_expense) / 15
    );
  }, [total_revenue, total_expense, r_and_d_expense]);

  const {
    data: userTaskData,
    isLoading: isUserTaskLoading,
    isFetching: isUserTaskFetching,
  } = useGetTaskListForUserQuery(
    {
      baseTaskKey: field.control_basetask?.join(","),
      seasons: [season],
      showArchive: false,
    },
    {
      skip:
        isAnyServiceUser ||
        !field.control_basetask ||
        !season ||
        field.control_basetask?.length === 0,
    }
  );

  const {
    data: cpaTaskList,
    isLoading: isCPATaskLoading,
    isFetching: isAdminTaskFetching,
  } = useGetTaskListForServiceTeamQuery(
    {
      baseTaskKey: field.control_basetask?.join(","),
      groupId,
      seasons: [season],
      showArchive: false,
    },
    {
      skip:
        isCustomer ||
        !groupId ||
        !season ||
        !field.control_basetask ||
        field.control_basetask?.length === 0,
    }
  );

  const count =
    (userTaskData || cpaTaskList)?.tasks?.filter(
      (task) => task.entity?.uuid === entityId
    )?.length || 0;

  if (isUserTaskLoading || isCPATaskLoading) {
    return (
      <div className="t-bg-neutral-10 t-w-full t-my-2 t-h-5 t-animate-pulse"></div>
    );
  }

  if (
    (field.control_basetask_for === "CUSTOMER" && isCustomer) ||
    (field.control_basetask_for === "ADMIN" && isAdmin)
  ) {
    if (field.control_basetask && field.control_basetask.length > 0) {
      if (field.control_basetask_show && count == 0) {
        return <></>;
      }
      if (!field.control_basetask_show && count > 0) {
        return <></>;
      }
    }
  }

  if (field.should_show_on_profit && (!netProfit || netProfit < 0)) {
    return <></>;
  }

  const isDocumentUploaded = (uploadedDocs, suggestedDoc) => {
    return uploadedDocs?.some(
      (uploadedDoc) => uploadedDoc?.uuid === suggestedDoc?.uuid
    );
  };

  if (field.is_hidden_for_customer && isCustomer) {
    return null;
  }

  const year = season ? season : dayjs().year() - 1;

  if (type === "action") {
    switch (field.cta.cta_action) {
      case "open_bookkeeping_subscriptions": {
        return (
          <DoBooksWithInkle
            field={field}
            groupId={groupId}
            entityId={entityId}
          />
        );
      }
      case "add_bank_account": {
        return (
          <TaskConnectBank
            entityId={entityId}
            groupId={groupId}
            openAccordionByDefault={false}
            date={dayjs(`${year}-12-31`).format(YYYY_MM_DD)}
          />
        );
      }

      case "add_view_financials": {
        return <AddFinancialClosing />;
      }

      default: {
        return <></>;
      }
    }
  }

  let autofillKeys = [];

  if (autofillKey && !autofillSuffix)
    autofillKeys = section.section_fields?.reduce((ac, cv) => {
      if (cv.autofill_section === field.autofill_section && cv.field_key) {
        const fieldKeyWithoutPrefix = cv.field_key?.replace(
          `${field.autofill_prefix}_`,
          ""
        );
        if (fieldKeyWithoutPrefix) {
          return [...ac, fieldKeyWithoutPrefix];
        }
        return ac;
      }
      return ac;
    }, []);

  if (autofillKey && autofillSuffix) {
    autofillKeys = section.repeatable_fields
      ?.find(({ id }) => id == autofillSuffix)
      ?.fields?.reduce((ac, cv) => {
        if (cv.autofill_section === field.autofill_section && cv.field_key) {
          const fieldKeyWithoutPrefix = cv.field_key?.replace(
            `${field.autofill_prefix}_`,
            ""
          );
          if (fieldKeyWithoutPrefix) {
            return [...ac, fieldKeyWithoutPrefix];
          }
          return ac;
        }
        return ac;
      }, []);
  }

  const repeatableFieldData =
    section?.repeatable_fields?.find(({ id }) => id == autofillSuffix) || {};

  if (type === "annual_catchup_subscription") {
    return (
      <AnnualCatchup
        season={season}
        entityIdFromProps={entityId}
        taskCount={(userTaskData || cpaTaskList)?.count || 0}
        controlBasetask={field.control_basetask?.join(",")}
        isFetching={isUserTaskFetching || isAdminTaskFetching}
      />
    );
  }

  if (type === "annual_books_calculation") {
    return <AnnualBooksCalculate deadline={deadline} />;
  }

  if (
    type === "select_shareholder_autofill" &&
    formData.data.data.address_autofill_version === ADDRESS_AUTOFILL_VERSION_2_0
  ) {
    return (
      <ShareholderAutofill
        section={section}
        groupId={groupId}
        entityId={entityId}
        formData={formData}
        autofillKey={autofillKey}
        field={field}
        keysToFill={autofillKeys}
        deleteModalTitle="shareholder"
        addNewAutofillText="Add new shareholder"
        comboboxName="shareholder"
        currentAutofillDetailKey="shareholder_details"
        shouldShowDetailsCard={
          autofillSuffix
            ? repeatableFieldData[
                `is_shareholder_selected_for_repeatable_fields_${autofillSuffix}`
              ]
            : section.is_shareholder_selected
        }
        isAnyDetailsMissing={
          autofillSuffix
            ? repeatableFieldData[
                `is_shareholder_data_missing_for_repeatable_fields_${autofillSuffix}`
              ]
            : section.is_shareholder_data_missing
        }
        autofillSuffix={autofillSuffix}
        AutofillCreateEdit={AddShareholder}
        repeatableFieldData={repeatableFieldData}
        fields={fields}
      />
    );
  }

  if (
    type === "select_bank_autofill" &&
    formData.data.data.address_autofill_version === ADDRESS_AUTOFILL_VERSION_2_0
  ) {
    return (
      <SelectAutofill
        section={section}
        groupId={groupId}
        entityId={entityId}
        formData={formData}
        autofillKey={autofillKey}
        field={field}
        keysToFill={autofillKeys}
        deleteModalTitle="bank"
        addNewAutofillText="Add new bank"
        comboboxName="bank"
        currentAutofillDetailKey="bank_details"
        shouldShowDetailsCard={
          autofillSuffix
            ? repeatableFieldData[
                `is_bank_account_selected_for_repeatable_fields_${autofillSuffix}`
              ]
            : section.is_bank_account_selected
        }
        isAnyDetailsMissing={
          autofillSuffix
            ? repeatableFieldData[
                `is_bank_account_data_missing_for_repeatable_fields_${autofillSuffix}`
              ]
            : section.is_bank_account_data_missing
        }
        isBankAutofill
        AutofillCreateEdit={CombinedComponents}
        repeatableFieldData={repeatableFieldData}
        fields={fields}
        autofillSuffix={autofillSuffix}
      />
    );
  }

  if (
    type === "select_autofill" &&
    formData.data.data.address_autofill_version === ADDRESS_AUTOFILL_VERSION_2_0
  ) {
    return (
      <SelectAutofill
        section={section}
        groupId={groupId}
        entityId={entityId}
        formData={formData}
        autofillKey={autofillKey}
        field={field}
        keysToFill={autofillKeys}
        deleteModalTitle="address"
        addNewAutofillText="Add new address"
        comboboxName="address"
        currentAutofillDetailKey="address_details"
        shouldShowDetailsCard={
          autofillSuffix
            ? repeatableFieldData[
                `is_address_selected_for_repeatable_fields_${autofillSuffix}`
              ]
            : section.is_address_selected
        }
        isAnyDetailsMissing={
          autofillSuffix
            ? repeatableFieldData[
                `is_address_data_missing_for_repeatable_fields_${autofillSuffix}`
              ]
            : section.is_address_data_missing
        }
        isAddressAutofill
        AutofillCreateEdit={AddEditAddress}
        autofillSuffix={autofillSuffix}
        repeatableFieldData={repeatableFieldData}
        fields={fields}
      />
    );
  }

  if (
    type === "select_persona_autofill" &&
    formData.data.data.address_autofill_version === ADDRESS_AUTOFILL_VERSION_2_0
  ) {
    return (
      <SelectAutofill
        section={section}
        groupId={groupId}
        entityId={entityId}
        formData={formData}
        autofillKey={autofillKey}
        field={field}
        keysToFill={autofillKeys}
        deleteModalTitle="persona"
        addNewAutofillText="Add new persona"
        comboboxName="persona"
        currentAutofillDetailKey="persona_details"
        shouldShowDetailsCard={
          autofillSuffix
            ? repeatableFieldData[
                `is_persona_selected_for_repeatable_fields_${autofillSuffix}`
              ]
            : section.is_persona_selected
        }
        isAnyDetailsMissing={
          autofillSuffix
            ? repeatableFieldData[
                `is_persona_data_missing_for_repeatable_fields_${autofillSuffix}`
              ]
            : section.is_persona_data_missing
        }
        isPersonaAutofill
        autofillSuffix={autofillSuffix}
        AutofillCreateEdit={AddEditPersona}
        repeatableFieldData={repeatableFieldData}
        fields={fields}
      />
    );
  }

  if (
    type === "select_directors_autofill" &&
    formData.data.data.address_autofill_version === ADDRESS_AUTOFILL_VERSION_2_0
  ) {
    return (
      <FilingDirectors
        section={section}
        groupId={groupId}
        entityId={entityId}
        formData={formData}
        autofillSuffix={autofillSuffix}
        field={field}
      />
    );
  }

  if (
    type === "select_authorized_signatory_autofill" &&
    formData.data.data.address_autofill_version === ADDRESS_AUTOFILL_VERSION_2_0
  ) {
    return (
      <FilingAuthorizedSignatory
        section={section}
        groupId={groupId}
        entityId={entityId}
        formData={formData}
        keysToFill={autofillKeys}
        fields={fields}
        field={field}
        required={field.required}
      />
    );
  }
  if (
    type === "select_principal_officer_autofill" &&
    formData.data.data.address_autofill_version === ADDRESS_AUTOFILL_VERSION_2_0
  ) {
    return (
      <FilingPrincipalOfficer
        section={section}
        groupId={groupId}
        entityId={entityId}
        formData={formData}
        keysToFill={autofillKeys}
        fields={fields}
        field={field}
      />
    );
  }

  if (type === "select_autofill" && authtoken) {
    return (
      <Autofill
        entityId={entityId}
        label={label}
        groupId={groupId}
        autofillKey={autofillKey}
        autofillPrefix={autofillPrefix}
        autofillSuffix={autofillSuffix}
      />
    );
  }

  if (type === "select") {
    return (
      <>
        {label && <Label label={label} tooltip={tooltip} name={props.name} />}
        <span message={Message({ current, data, uuid, isArchived })}>
          <span className="msgId">
            {Message({ current, data, uuid, isArchived })}
          </span>
          <CustomField
            name={props.name}
            component={Form.Select}
            defaultValue={defaultValue}
            type={type}
            title={tooltip}
            {...props}
          >
            {options.map((option) => (
              <option key={option}>{option}</option>
            ))}
          </CustomField>
        </span>
      </>
    );
  }

  if (type === "multiselect") {
    const selectedOptions =
      defaultValue?.length > 0
        ? options.filter((option) => defaultValue.includes(option.value))
        : null;

    return (
      <div>
        {label && (
          <Label
            label={label}
            tooltip={tooltip}
            name={props.name}
            mandatory={field.required}
          />
        )}
        <span message={Message({ current, data, uuid, isArchived })}>
          <span className="msgId">
            {Message({ current, data, uuid, isArchived })}
          </span>
          <Combobox
            label=""
            components={{
              ClearIndicator: () => null,
            }}
            isMulti
            withForm
            placeholder={props.placeholder}
            name={props.name}
            options={options}
            defaultValue={selectedOptions}
            isDisabled={props.disabled}
            {...props}
          />
          {field.note && values[props.name].length > 0 && (
            <span className="t-text-caption t-text-yellow-80">
              {field.note}
            </span>
          )}
        </span>
      </div>
    );
  }

  if (type === "checkbox") {
    return (
      <div className="d-flex align-items-center gap-3">
        <span message={Message({ current, data, uuid, isArchived })}>
          <span className="msgId">
            {Message({ current, data, uuid, isArchived })}
          </span>
          <div className="d-flex gap-2">
            <CustomField
              name={props.name}
              component={Form.Check}
              type={type}
              defaultChecked={defaultValue}
              title={tooltip}
              {...props}
              label={null}
            />
            <Label
              mandatory={mandatory}
              label={label}
              tooltip={tooltip}
              name={props.name}
            />
          </div>
        </span>
        <span
          className="descT last"
          dangerouslySetInnerHTML={{ __html: descript }}
        />
      </div>
    );
  }

  if (type === "radio") {
    return (
      <>
        <span
          className="descT last"
          dangerouslySetInnerHTML={{ __html: descript }}
        />

        {Boolean(descript) && (
          <>
            <br />
            <br />
          </>
        )}

        {label && (
          <Label
            mandatory={mandatory}
            label={label}
            tooltip={tooltip}
            name={props.name}
          />
        )}

        <span message={Message({ current, data, uuid, isArchived })}>
          <span className="msgId">
            {Message({ current, data, uuid, isArchived })}
          </span>
          <Radio.Root
            defaultValue={field.value}
            onValueChange={(value) => props.onRadioChange(value)}
          >
            <Radio.Content className="t-mt-2 t-space-y-1">
              {options?.map((ele) => (
                <Radio.Item asChild value={ele?.value} key={ele?.value}>
                  <div className="t-text-body t-text-text-100 t-w-full">
                    <div>{ele?.label}</div>
                  </div>
                </Radio.Item>
              ))}
            </Radio.Content>
          </Radio.Root>
        </span>
      </>
    );
  }

  if (type === "text") {
    return (
      <>
        <span
          className="descT last"
          dangerouslySetInnerHTML={{ __html: descript }}
        />

        {Boolean(descript) && (
          <>
            <br />
            <br />
          </>
        )}

        {label && (
          <Label
            mandatory={mandatory}
            label={label}
            tooltip={tooltip}
            name={props.name}
          />
        )}

        <span message={Message({ current, data, uuid, isArchived })}>
          <span className="msgId">
            {Message({ current, data, uuid, isArchived })}
          </span>
          <TextInput
            name={props.name}
            component={Form.Control}
            defaultValue={defaultValue}
            title={tooltip}
            type={type}
            {...props}
          />
        </span>
      </>
    );
  }

  if (type === "amount") {
    return (
      <>
        <span
          className="descT last"
          dangerouslySetInnerHTML={{ __html: descript }}
        />

        {Boolean(descript) && (
          <>
            <br />
            <br />
          </>
        )}

        {label && (
          <Label
            mandatory={mandatory}
            label={label}
            tooltip={tooltip}
            name={props.name}
          />
        )}

        <span message={Message({ current, data, uuid, isArchived })}>
          <span className="msgId">
            {Message({ current, data, uuid, isArchived })}
          </span>
          <PriceInput
            name={props.name}
            label=""
            allowNegative
            placeholder={props.placeholder}
            unit="USD"
          />
        </span>
      </>
    );
  }

  if (type === "phone") {
    return (
      <>
        <span
          className="descT last"
          dangerouslySetInnerHTML={{ __html: descript }}
        />

        {Boolean(descript) && (
          <>
            <br />
            <br />
          </>
        )}

        {label && (
          <Label
            mandatory={mandatory}
            label={label}
            tooltip={tooltip}
            name={props.name}
          />
        )}

        <span message={Message({ current, data, uuid, isArchived })}>
          <span className="msgId">
            {Message({ current, data, uuid, isArchived })}
          </span>
          <div>
            <MobileInput name={props.name} title={tooltip} {...props} />
          </div>
        </span>
      </>
    );
  }

  if (type === "email") {
    return (
      <>
        <span
          className="descT last"
          dangerouslySetInnerHTML={{ __html: descript }}
        />

        {Boolean(descript) && (
          <>
            <br />
            <br />
          </>
        )}

        {label && (
          <Label
            mandatory={mandatory}
            label={label}
            tooltip={tooltip}
            name={props.name}
          />
        )}

        <span message={Message({ current, data, uuid, isArchived })}>
          <span className="msgId">
            {Message({ current, data, uuid, isArchived })}
          </span>
          <FilingEmailInput
            name={props.name}
            defaultValue={defaultValue}
            title={tooltip}
            type={type}
            {...props}
          />
        </span>
      </>
    );
  }

  if (type === "info") {
    return (
      <span className="t-mb-4" dangerouslySetInnerHTML={{ __html: descript }} />
    );
  }

  if (type === "date") {
    return (
      <>
        {label && (
          <Label mandatory={mandatory} label={label} tooltip={tooltip} />
        )}
        <div message={Message({ current, data, uuid, isArchived })}>
          <span className="msgId">
            {Message({ current, data, uuid, isArchived })}
          </span>
          <Field name={props.name}>
            {({ field }) => {
              const formatDate = (dateString) => {
                if (!dateString) return "";

                const formats = ["DD-MM-YYYY", "DD-MMM-YYYY", "YYYY-MM-DD"];
                for (const format of formats) {
                  const parsedDate = dayjs(dateString, format, true);
                  if (parsedDate.isValid()) {
                    return parsedDate.format("YYYY-MM-DD");
                  }
                }

                return dateString;
              };

              const formattedDate = formatDate(field?.value);

              return (
                <DateInput
                  {...field}
                  {...props}
                  value={formattedDate}
                  disabled={props.disabled}
                  name={props.name}
                  required={mandatory}
                  portalId="portal"
                  placeholder="DD-MMM-YYYY"
                  min="1900-01-01"
                  max="2050-12-31"
                />
              );
            }}
          </Field>
        </div>
      </>
    );
  }

  if (type === "file_upload") {
    return (
      <div className="t-mb-2">
        <FormUploadDocuments
          field={field}
          formInstanceId={formId}
          groupId={groupId}
          suggestedDocUpload={() => {}}
        />
        {field?.suggested_documents?.length ? (
          <p className="t-my-2 t-text-caption t-text-neutral">
            Suggested Document below. Drag and Drop to attach your files.
          </p>
        ) : null}
        <div className="t-flex t-max-w-[600px] t-flex-wrap t-items-center t-gap-2">
          {field?.suggested_documents?.map((doc) => {
            const isUploaded = isDocumentUploaded(field?.documents, doc);

            return (
              <SuggestedDocumentDND
                isUploaded={isUploaded}
                key={doc?.uuid}
                data={JSON.stringify(doc)}
              >
                <SuggestedDocument
                  document={doc}
                  groupId={groupId}
                  isUploaded={isUploaded}
                />
              </SuggestedDocumentDND>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};
