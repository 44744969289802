import { ACCOUNT_TYPE, CATEGORISATION_TYPE } from "constants/booksHomePage";
import { stringify } from "qs";
import { Transactions } from "types/Models/books";
import { emptyApi } from "./emptyApi";

export type Insight = {
  merchants: {
    merchant_name: string;
    logo: string | null;
    amount: number;
  }[];
  categories: {
    category_name: string;
    category_uuid: string;
    parent: string;
    amount: number;
  }[];
  transactions: Transactions[];
  channel_url: string;
};

export type Metrics = {
  entity_name: string;
  entity_id: string;
  gross_burn: string;
  net_burn: string;
  gross_burn_rate: number;
  net_burn_rate: number;
  gross_runway: number;
  net_runway: number;
  total_revenue: number;
};

export type RevenueMetrics = {
  period: string;
  revenue: number;
  expense: number;
  profit: number;
};

export type CashBalance = {
  bank_account_balance: number;
  credit_account_balance: number;
  total_balances: number;
  all_individual_accounts_response: Record<string, number>;
  trends: {
    name: string;
    data: {
      date: string;
      amount: number;
    }[];
  }[];
};

export const metricsApis = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getMetrics: build.query<
      Metrics,
      {
        entityId: string;
        burn_rate_start_date: string;
        burn_rate_end_date: string;
        categorisation_type: keyof typeof CATEGORISATION_TYPE;
      }
    >({
      query: ({
        entityId,
        burn_rate_end_date,
        burn_rate_start_date,
        categorisation_type,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/metrics/${stringify(
            {
              burn_rate_start_date,
              burn_rate_end_date,
              categorisation_type,
            },
            { skipNulls: true, addQueryPrefix: true }
          )}`,
        };
      },
    }),

    getInsights: build.query<
      Insight,
      {
        groupId: string;
        entityId: string;
        start_date: string | null;
        end_date: string | null;
        limit: number;
        categorisation_type: keyof typeof CATEGORISATION_TYPE;
        insight_type: string;
      }
    >({
      query: ({
        groupId,
        entityId,
        end_date,
        start_date,
        limit,
        categorisation_type,
        insight_type,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/insights/${stringify(
            {
              start_date,
              end_date,
              limit,
              categorisation_type,
              insight_type,
            },
            { skipNulls: true, addQueryPrefix: true }
          )}`,
        };
      },
    }),

    getCashBalance: build.query<
      CashBalance,
      {
        entityId: string;
        start_date: string;
        end_date: string;
        account_type: keyof typeof ACCOUNT_TYPE;
        categorisation_type: keyof typeof CATEGORISATION_TYPE;
      }
    >({
      query: ({
        entityId,
        start_date,
        end_date,
        account_type,
        categorisation_type,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/entity/${entityId}/cash-balance/${stringify(
            {
              start_date,
              end_date,
              account_type,
              categorisation_type,
            },
            { skipNulls: true, addQueryPrefix: true }
          )}`,
        };
      },
    }),

    getRevenueMetrics: build.query<
      RevenueMetrics[],
      {
        groupId: string;
        entityId: string;
        start_date: string;
        end_date: string;
        categorisation_type: keyof typeof CATEGORISATION_TYPE;
      }
    >({
      query: ({
        groupId,
        entityId,
        start_date,
        end_date,
        categorisation_type,
      }) => {
        return {
          url: `/api/inkle/bookkeeping/group/${groupId}/entity/${entityId}/revenue-metrics/`,
          params: {
            start_date,
            end_date,
            categorisation_type,
          },
        };
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetMetricsQuery,
  useGetInsightsQuery,
  useGetCashBalanceQuery,
  useGetRevenueMetricsQuery,
} = metricsApis;
