import cx from "classnames";
import { DD_MMM_YYYY } from "constants/date";
import dayjs from "dayjs";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroup } from "hooks/useCurrentGroup";
import { ReactNode } from "react";
import {
  Invoice,
  InvoiceItem,
  useGetInvoiceSettingsQuery,
} from "store/apis/invoices";
import { currency } from "utils/Currency";

export const InvoicePreivew = ({
  invoice,
  size = "large",
  scale = "normal",
}: {
  invoice?: Omit<Invoice, "status" | "uuid">;
  size?: "small" | "large";
  scale?: "normal" | "scaled";
}) => {
  if (!invoice) {
    return null;
  }

  return (
    <div
      className={cx(
        "t-flex t-flex-col t-bg-white t-h-full t-mt-5 t-flex-1",
        {
          "t-text-[7px]": size === "small",
          "t-text-[11px]": size === "large",
        },
        {
          "t-p-8": scale === "normal",
          "t-p-12": scale === "scaled",
          "t-text-[14px]": scale === "scaled" && size === "large",
          "t-text-[9px]": scale === "scaled" && size === "small",
        }
      )}
    >
      <div
        className={cx("t-flex t-justify-between", {
          "t-mb-2": scale === "normal",
          "t-mb-4": scale === "scaled",
        })}
      >
        <div>
          <div>
            <img
              className={cx("t-max-w-[168px] t-mb-2", {
                "t-max-h-[54px]": scale === "normal",
                "t-max-h-[80px]": scale === "scaled",
              })}
              src={invoice?.entity?.logo_url}
              alt={invoice?.entity?.entity_name}
            />
          </div>
          <p
            className={cx("t-font-bold t-m-0", {
              "t-text-[9px]": size === "small",
              "t-text-[14px]": size === "large",
              "t-text-[18px]": scale === "scaled" && size === "large",
            })}
          >
            {invoice?.entity?.entity_name}
          </p>
          {/* {invoiceSettings?.address && ( */}
          <div
          // className={cx("t-flex t-flex-col", {
          //   "t-gap-0.5": scale === "normal",
          //   "t-gap-1": scale === "scaled",
          // })}
          >
            <span>
              {" "}
              {invoice?.entity?.address?.street_address &&
                `${invoice?.entity?.address?.street_address},`}
            </span>
            <br />
            {invoice?.entity?.address?.street_address_line_2 && (
              <>
                <span>{invoice?.entity?.address?.street_address_line_2},</span>
                <br />
              </>
            )}
            <span>
              {" "}
              {invoice?.entity?.address?.city &&
                `${invoice?.entity?.address?.city},`}
            </span>
            <span> {invoice?.entity?.address?.state}</span>
            <br />
            <span>
              {" "}
              {invoice?.entity?.address?.country &&
                `${invoice?.entity?.address?.country},`}
            </span>
            <span>
              {" "}
              {invoice?.entity?.address?.zipcode &&
                invoice?.entity?.address?.zipcode}
            </span>
          </div>
        </div>

        <div
          className={cx("t-flex t-flex-col t-text-right", {
            "t-gap-1": scale === "normal",
            "t-gap-2": scale === "scaled",
          })}
        >
          <span
            className={cx("t-font-bold", {
              "t-text-body-sm": size === "small" && scale === "normal",
              "t-text-[22px]": size === "large" && scale === "normal",
              "t-text-[16px]": size === "small" && scale === "scaled",
              "t-text-[32px]": size === "large" && scale === "scaled",
            })}
          >
            {invoice.title}
          </span>
          <span className="t-text-text-30">
            #{invoice?.entity?.invoice_prefix}-
            {invoice.invoice_number_without_prefix}
          </span>
        </div>
      </div>

      <table
        className={cx("t-table-fixed t-w-full", {
          "[&_td]:t-py-0.5 [&_td]:t-w-1/4": scale === "normal",
          "[&_td]:t-py-1 [&_td]:t-w-1/4": scale === "scaled",
        })}
      >
        <tbody>
          <tr>
            <td>Bill to:</td>
            <td>
              {invoice.customer &&
                invoice.customer.shipping_address &&
                "Ship to:"}
            </td>
            <td className="t-text-right">Date:</td>
            <td className="t-text-right t-pr-1">
              {invoice.invoice_date &&
                dayjs(invoice.invoice_date).format(DD_MMM_YYYY)}
            </td>
          </tr>

          <tr>
            <td rowSpan={4} className="t-align-top">
              {invoice.customer && (
                <>
                  <span className="t-font-bold">
                    {invoice.customer?.company_name &&
                      `${invoice.customer?.company_name},`}
                  </span>
                  <span>
                    {" "}
                    {invoice.customer?.billing_address?.street_address &&
                      `${invoice.customer?.billing_address?.street_address},`}
                  </span>
                  {invoice.customer?.billing_address?.street_address_line_2 && (
                    <span>
                      {" "}
                      {invoice.customer?.billing_address?.street_address_line_2}
                      ,
                    </span>
                  )}
                  <span>
                    {" "}
                    {invoice.customer?.billing_address?.city &&
                      `${invoice.customer?.billing_address?.city},`}
                  </span>
                  <span> {invoice.customer?.billing_address?.state}</span>
                  <br />
                  <span>
                    {" "}
                    {invoice.customer?.billing_address?.country &&
                      `${invoice.customer?.billing_address?.country},`}
                  </span>
                  <span>
                    {" "}
                    {invoice.customer?.billing_address?.zipcode &&
                      invoice.customer?.billing_address?.zipcode}
                  </span>
                </>
              )}
            </td>

            <td rowSpan={4} className="t-align-top">
              {invoice.customer && invoice.customer.shipping_address && (
                <>
                  <span className="t-font-bold">
                    {invoice.customer?.company_name &&
                      `${invoice.customer?.company_name},`}
                  </span>
                  <span>
                    {" "}
                    {invoice.customer?.shipping_address?.street_address &&
                      `${invoice.customer?.shipping_address?.street_address},`}
                  </span>
                  {invoice.customer?.shipping_address
                    ?.street_address_line_2 && (
                    <span>
                      {" "}
                      {
                        invoice.customer?.shipping_address
                          ?.street_address_line_2
                      }
                      ,
                    </span>
                  )}
                  <span>
                    {" "}
                    {invoice.customer?.shipping_address?.city &&
                      `${invoice.customer?.shipping_address?.city},`}
                  </span>
                  <span> {invoice.customer?.shipping_address?.state}</span>
                  <br />
                  <span>
                    {" "}
                    {invoice.customer?.shipping_address?.country &&
                      `${invoice.customer?.shipping_address?.country},`}
                  </span>
                  <span>
                    {" "}
                    {invoice.customer?.shipping_address?.zipcode &&
                      invoice.customer?.shipping_address?.zipcode}
                  </span>
                </>
              )}
            </td>
            {invoice.due_date && (
              <>
                <td className="t-text-right">Due Date:</td>
                <td className="t-text-right t-pr-1">
                  {dayjs(invoice.due_date).format(DD_MMM_YYYY)}
                </td>
              </>
            )}
          </tr>
          {/* {invoice.shipping_date && (
            <tr>
              <td className="t-text-right">Shipping date:</td>
              <td className="t-text-right t-pr-1">
                {" "}
                {dayjs(invoice.shipping_date).format(DD_MMM_YYYY)}
              </td>
            </tr>
          )} */}
          {invoice.po_number && (
            <tr>
              <td className="t-text-right">PO number:</td>
              <td className="t-text-right t-pr-1">{invoice.po_number}</td>
            </tr>
          )}

          <tr className="t-font-bold">
            <td className="t-text-right t-bg-neutral-0 t-rounded-l t-pr-1">
              Balance Due:
            </td>
            <td className="t-text-right t-bg-neutral-0 t-rounded-r t-pr-1">
              {currency({ amount: invoice.due_balance || "0" })}
            </td>
          </tr>

          <tr>
            <td colSpan={4} height={scale === "scaled" ? 24 : 20}></td>
          </tr>
        </tbody>
      </table>

      <table
        className={cx("t-table-fixed t-w-full t-my-5", {
          "[&_td]:t-py-0.5": scale === "normal",
          "[&_td]:t-py-1": scale === "scaled",
        })}
      >
        <tbody>
          <tr>
            <td className="t-bg-i-primary-100 t-rounded-l t-text-surface t-w-6/12 t-pl-1">
              Item
            </td>
            <td className="t-bg-i-primary-100 t-text-surface t-w-3/12">
              Quantity
            </td>
            <td className="t-bg-i-primary-100 t-text-surface t-w-1/12 t-text-right">
              Rate
            </td>
            <td className="t-bg-i-primary-100 t-text-surface t-rounded-r t-pr-1 t-w-2/12 t-text-right">
              Amount
            </td>
          </tr>

          <tr>
            <td colSpan={4} height={8} />
          </tr>

          {invoice?.invoice_items?.map((item) => (
            <tr key={item.uuid}>
              <td className="t-pl-1">{item.description}</td>
              <td>{item.quantity}</td>
              <td className="t-text-right">
                {currency({ amount: item.rate || "0" })}
              </td>
              <td className="t-text-right t-pr-1">
                {currency({ amount: item.amount || "0" })}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <table className="t-table-fixed [&_td]:t-py-0.5 [&_td]:t-w-1/4 t-w-full">
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td className="t-text-right">Subtotal:</td>
            <td className="t-text-right">
              {currency({ amount: invoice.sub_total || "0" })}
            </td>
          </tr>

          {Boolean(invoice.discount) && (
            <tr>
              <td></td>
              <td></td>
              <td className="t-text-right">
                Discount{" "}
                {invoice.discount_type === "PERCENT" &&
                  `(${invoice.discount}%)`}{" "}
                :
              </td>
              <td className="t-text-right">
                {currency({ amount: invoice.discount_amount || "0" })}
              </td>
            </tr>
          )}

          {Boolean(invoice.tax) && (
            <tr>
              <td></td>
              <td></td>
              <td className="t-text-right">
                Tax
                {invoice.tax_type === "PERCENT" && `(${invoice.tax}%)`}:
              </td>
              <td className="t-text-right">
                {currency({ amount: invoice.tax_amount || "0" })}
              </td>
            </tr>
          )}

          <tr>
            <td></td>
            <td></td>
            <td className="t-text-right">Total</td>
            <td className="t-text-right">
              {currency({ amount: invoice.total || "0" })}
            </td>
          </tr>

          {Boolean(invoice.paid_amount) && (
            <tr>
              <td></td>
              <td></td>
              <td className="t-text-right">Amount Paid:</td>
              <td className="t-text-right">
                {currency({ amount: invoice.paid_amount || "0" })}
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {(invoice.notes || invoice.payment_link) && (
        <div
          className={cx("t-mt-auto", {
            "t-gap-1": scale === "normal",
            "t-gap-2 t-pt-6": scale === "scaled",
          })}
        >
          <p className="t-m-0 t-mt-auto">Notes:</p>
          <p className="t-whitespace-pre-wrap t-m-0 t-mb-3">{invoice.notes}</p>
          {invoice.payment_link && (
            <p className="t-whitespace-pre-wrap t-m-0">
              Payment link: {invoice.payment_link}
            </p>
          )}
        </div>
      )}

      {invoice.terms && (
        <div
          className={cx({
            "t-gap-1": scale === "normal",
            "t-gap-2": scale === "scaled",
          })}
        >
          <p className="t-m-0">Terms:</p>
          <p className="t-m-0 t-whitespace-pre-wrap">{invoice.terms}</p>
        </div>
      )}
    </div>
  );
};
