export const ChatIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 11.3337V14.2504C16 14.7145 15.8156 15.1596 15.4874 15.4878C15.1592 15.816 14.7141 16.0004 14.25 16.0004H11.3333C10.515 15.9995 9.71122 15.7835 9.00271 15.374C8.2942 14.9644 7.70585 14.3758 7.29667 13.667C7.73642 13.6639 8.17469 13.6154 8.6045 13.5224C8.93184 13.9317 9.34708 14.262 9.81946 14.489C10.2918 14.716 10.8092 14.8338 11.3333 14.8337H14.25C14.4047 14.8337 14.5531 14.7722 14.6625 14.6628C14.7719 14.5534 14.8333 14.4051 14.8333 14.2504V11.3337C14.8332 10.8094 14.715 10.2919 14.4876 9.8195C14.2602 9.34711 13.9294 8.93197 13.5197 8.60486C13.6135 8.17513 13.6628 7.73687 13.6667 7.29703C14.3754 7.70621 14.964 8.29457 15.3736 9.00308C15.7831 9.71159 15.9992 10.5153 16 11.3337ZM12.4866 7.63011C12.5411 6.87857 12.4333 6.12409 12.1703 5.41794C11.9074 4.71178 11.4955 4.07051 10.9627 3.53769C10.4299 3.00487 9.78858 2.59299 9.08243 2.33004C8.37628 2.06709 7.62179 1.95922 6.87025 2.01378C5.53845 2.16587 4.30821 2.79963 3.41119 3.79571C2.51417 4.79179 2.01227 6.08146 2 7.42186V10.3619C2 11.8389 2.87908 12.5004 3.75 12.5004H7.075C8.41597 12.4888 9.70642 11.9873 10.7032 11.0902C11.7 10.1931 12.3343 8.96246 12.4866 7.63011ZM10.1375 4.36345C10.5516 4.77849 10.8718 5.27765 11.0763 5.82714C11.2808 6.37663 11.3649 6.96364 11.3228 7.54845C11.1983 8.58892 10.6978 9.54801 9.91544 10.2452C9.13309 10.9423 8.12289 11.3295 7.075 11.3337H3.75C3.20867 11.3337 3.16667 10.5899 3.16667 10.3619V7.42186C3.17153 6.37441 3.55904 5.36486 4.25628 4.58317C4.95352 3.80149 5.91241 3.30159 6.9525 3.17753C7.04933 3.17053 7.14617 3.16703 7.243 3.16703C7.78041 3.16653 8.31265 3.27193 8.8093 3.47722C9.30595 3.68251 9.75728 3.98365 10.1375 4.36345Z"
        fill="currentColor"
      />
    </svg>
  );
};
