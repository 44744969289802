import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import Modal from "components/DesignSystem/Modal/Modal";
import Table from "components/DesignSystem/Table/V2/Table";
import { Label, TextInput } from "components/DesignSystem/TextInput/TextInput";
import { DeleteIcon } from "components/icons/delete";
import { MagnifyingGlass } from "components/icons/MagnifyingGlass";
import { DD_MMM_YYYY, YYYY_MM_DD } from "constants/date";
import dayjs from "dayjs";
import { Field, FieldProps, Form, Formik, useFormikContext } from "formik";
import { journalEntrySchema } from "formValidations/JournalEntrySchema";
import { AnimatePresence, motion } from "framer-motion";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useToast } from "hooks/useToast";
import { EmptyScreen } from "pages/Books/EmptyScreen";
import randomBytes from "randombytes";
import { useMemo, useState } from "react";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "react-table-8.10.7";
import {
  useGetRelatedLedgerEntryQuery,
  useUpdatedLedgerEntryMutation,
} from "store/apis/generalLedger";
import { FileData } from "types/Models/books";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";
import {
  JournalEntryFormWarrper,
  RecurringJournalEntrySettings,
} from "./AddJournalEntryModal";
import { Amount, Invoice, Vendor } from "./AddJournalEntryTable";
import { AccordionAnimation } from "components/AccordionAnimation";
import { SwitchField } from "components/DesignSystem/Switch/SwitchField";
import Loader from "components/design/loader";
import { useModal } from "hooks/useModal";
import RadioGroup from "components/DesignSystem/RadioGroup/RadioGroup";
import { RECURRING_JOUNRAL_ENTRY_UPDATE_TYPES_T } from "types/contants/recurringJournalEntryUpdateTypes";
import * as RECURRING_JOUNAL_ENTRY_UPDATE_TYPES from "constants/recurringJorunalEntryUpdateTypes";
import { STOP_RECURRING_JOUNAL_ENTRY_TYPES_T } from "types/contants/stopRecurringJounalEntryTypes";
import * as STOP_RECURRING_JOUNAL_ENTRY_TYPES from "constants/stopRecurringJounalEntryTypes";
import { onEnterKey } from "utils/onEnterKey";
import { EnterIcon } from "components/icons/EnterIcon";
import { EnterButtonCard } from "components/EnterButtonCard";
import { table } from "console";
import { PermissionBasedUI } from "components/PermissionBasedUI/PermissionBasedUI";
import { Padlock } from "components/icons/Padlock";
import { RECURRING_JOURNAL_ENTRY } from "constants/subscriptionPermissionFeatures";
import { BankSelector } from "components/BankSelector/BankSelector";
import { useGetEntityBanksQuery } from "store/apis/bankConnections";
import { CategorySelector } from "components/CategorySelector/CategorySelector";

type LocalTransactions = {
  description: string | null;
  category: string | undefined;
  merchant: string | null;
  uuid: string;
  memo: string | null;
  logo: string | null;
  credit: number;
  debit: number;
  invoice: Omit<FileData, "invoice_status" | "invoice_uuid"> | null;
};

type JournalEntryValues = {
  transaction_date: string;
  ledger_entry_name: string | undefined;
  transactions: LocalTransactions[];
};

const initialTransaction: Omit<LocalTransactions, "uuid"> = {
  merchant: "",
  category: "",
  description: "",
  credit: 0,
  debit: 0,
  logo: "",
  memo: "",
  invoice: null,
};

const Delete = ({ uuid }: { uuid: string }) => {
  const { values, setFieldValue } = useFormikContext<{
    transactions: LocalTransactions[];
  }>();

  const onDelete = () => {
    const newValues = values.transactions.filter((transaction) => {
      return transaction.uuid !== uuid;
    });

    setFieldValue("transactions", newValues);
  };

  return (
    <Button customType="ghost_icon" onClick={onDelete}>
      <DeleteIcon />
    </Button>
  );
};

const JournalEntryTable = () => {
  const createColumn = createColumnHelper<LocalTransactions>();
  const { setFieldValue, values } = useFormikContext<JournalEntryValues>();
  const { transactions } = values;
  const columns = useMemo(
    () => [
      createColumn.accessor("description", {
        size: 29,
        header: () => <Label required>DESCRIPTION</Label>,
        cell: (info) => {
          const id = info.row.id;

          return (
            <TextInput
              name={`transactions[${id}].description`}
              placeholder="Enter description"
              hideError
              block
              showErrorOnceTouched
            />
          );
        },
      }),

      createColumn.accessor("merchant", {
        size: 20,
        header: () => <Label>VENDOR</Label>,
        cell: (info) => {
          const id = info.row.id;
          const { merchant = "" } = info.row.original || {};

          return <Vendor id={id} merchant={merchant || ""} creatable />;
        },
      }),

      createColumn.accessor("category", {
        size: 20,
        header: () => <Label required>Category</Label>,
        cell: (info) => {
          const id = info.row.id;
          const { category = "" } = info.row.original || {};

          return (
            <CategorySelector
              hiddenCategoryTypes={[]}
              name={`transactions[${id}].category`}
              category={category}
            />
          );
        },
      }),

      createColumn.accessor("debit", {
        size: 10,
        header: () => (
          <div className="t-flex">
            <Label required>debit</Label>
          </div>
        ),
        cell: (info) => {
          const id = info.row.id;

          return <Amount id={id} type="debit" />;
        },
      }),

      createColumn.accessor("credit", {
        size: 10,
        header: () => (
          <div className="t-flex ">
            <Label required>credit</Label>
          </div>
        ),
        cell: (info) => {
          const id = info.row.id;

          return <Amount id={id} type="credit" />;
        },
      }),

      createColumn.accessor("invoice", {
        size: 5,
        header: "",
        cell: (info) => {
          const id = info.row.id;
          const invoice = info.getValue();

          return (
            <Invoice
              id={id}
              invoice={
                invoice
                  ? {
                      file_type: invoice?.file_type,
                      name: invoice?.name,
                      uuid: invoice?.uuid,
                      is_previewable: invoice?.is_previewable,
                    }
                  : null
              }
            />
          );
        },
      }),

      createColumn.accessor("uuid", {
        size: 5,
        header: "",
        cell: (info) => {
          const { uuid } = info.row.original;

          return <Delete uuid={uuid} />;
        },
      }),
    ],
    []
  );

  const table = useReactTable({
    data: transactions,
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: {
      minSize: 1,
      maxSize: 100,
    },
  });
  const totalDebit = transactions.reduce(
    (acc, { debit }) => acc + Number(debit),
    0
  );

  const totalCredit = transactions.reduce(
    (acc, { credit }) => acc + Number(credit),
    0
  );

  const noTransaction = transactions?.length === 0;
  const amountDiff = Math.abs(totalDebit - totalCredit);

  const addAnotherTransaction = () => {
    const newValues = [
      ...values.transactions,
      {
        uuid: randomBytes(10).toString("hex") + "NEW_ENTRY",
        ...initialTransaction,
      },
    ];
    setFieldValue("transactions", newValues);
  };

  return (
    <>
      {noTransaction ? (
        <EmptyScreen text="No journal entry found" className="!t-h-56">
          <span className="t-text-i-neutral-10">
            <MagnifyingGlass size="149" />
          </span>
        </EmptyScreen>
      ) : (
        <Table.Container>
          <Table.Content>
            <Table.Head className="-t-top-1">
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeadCell
                      key={header.id}
                      style={{ width: `${header.getSize()}%` }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Table.HeadCell>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>
            <Table.Body>
              <AnimatePresence>
                {table.getRowModel().rows.map((row) => (
                  <motion.tr
                    layout
                    key={row.original.uuid}
                    initial={{ y: -100 }}
                    animate={{ x: 0, y: 0 }}
                    exit={{ y: -20, opacity: 0 }}
                    transition={{ ease: "easeOut", duration: 0.1 }}
                    className="t-px-3 t-border-solid t-border-neutral-0 t-border-b t-border-0 t-text-body"
                  >
                    {row.getVisibleCells().map((cell) => {
                      return (
                        <Table.Cell
                          key={cell.id}
                          style={{ width: `${cell.column.getSize()}%` }}
                          onKeyDown={onEnterKey(addAnotherTransaction)}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Table.Cell>
                      );
                    })}
                  </motion.tr>
                ))}
              </AnimatePresence>

              <Table.Row
                className={classNames("!t-border-none t-bg-white", {
                  "t-bottom-[37px]": Boolean(amountDiff),
                  "t-bottom-0": !Boolean(amountDiff),
                })}
              >
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell className="t-text-end t-p-2 t-text-subtitle-sm t-text-text-30">
                  Total
                </Table.Cell>
                <Table.Cell className="t-text-end t-p-2 t-text-subtitle-sm">
                  <AmountSuperScript amount={totalDebit} />
                </Table.Cell>
                <Table.Cell className="t-text-end t-p-2 t-text-subtitle-sm">
                  <AmountSuperScript amount={totalCredit} />
                </Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
              </Table.Row>
              {Boolean(amountDiff) && (
                <Table.Row className="!t-border-none t-sticky t-bottom-0 t-bg-white">
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell className="t-text-end t-p-2 t-text-subtitle-sm t-text-red">
                    Difference
                  </Table.Cell>
                  <Table.Cell className="t-p-2 t-text-subtitle-sm t-px-5 t-text-red">
                    <AmountSuperScript amount={amountDiff} />
                  </Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell></Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table.Content>
        </Table.Container>
      )}
      <div className="t-bg-white t-flex t-items-center t-gap-2">
        <Button type="button" onClick={addAnotherTransaction}>
          Add more
        </Button>
        <span className=" t-text-button t-text-neutral-30">or</span>
        <EnterButtonCard />
      </div>
    </>
  );
};

export const EditJournalEntry = ({
  close,
  showConfirmation,
  isOpen,
  transactionId,
  dismissConfirmation,
}: ModalProps & { transactionId: string }) => {
  const { alertToast, successToast } = useToast();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const [updatedLedgerEntry, { isLoading: updating }] =
    useUpdatedLedgerEntryMutation();
  const settingOnlyEditModal = useModal();
  const settingOrAndDataEditModal = useModal();
  const stopRecurringModal = useModal();

  const {
    data: relatedLedgerEntry,
    isLoading,
    isSuccess,
  } = useGetRelatedLedgerEntryQuery(
    {
      entityId,
      groupId,
      transactionId,
    },
    {
      skip: !entityId || !groupId || !transactionId || !isOpen,
      refetchOnMountOrArgChange: true,
    }
  );

  const { ledger_entry, transactions = [] } = relatedLedgerEntry || {};
  const { entry_name = "", uuid: ledgerEntryId = "" } = ledger_entry || {};

  const transaction_date = dayjs(ledger_entry?.date).format(DD_MMM_YYYY);

  const localTransactions: LocalTransactions[] = transactions.map(
    ({
      transaction: {
        description,
        amount,
        category,
        uuid,
        memo,
        logo,
        merchant_uuid,
      },
      invoices,
    }) => ({
      description: description || null,
      category: category?.uuid,
      merchant: merchant_uuid || null,
      uuid,
      memo: memo || null,
      logo: logo || null,
      debit: amount < 0 ? Math.abs(amount) : 0,
      credit: amount > 0 ? Math.abs(amount) : 0,
      invoice: invoices?.[0]
        ? {
            uuid: invoices?.[0]?.file_data?.uuid || "",
            file_type: invoices?.[0]?.file_data?.file_type || "IMAGE",
            is_previewable: invoices?.[0]?.file_data?.is_previewable || false,
            name: invoices?.[0]?.file_data?.name || "",
          }
        : null,
    })
  );

  const isEmpty = transactions?.length === 0;

  let endsOnValue = null;

  if (ledger_entry?.settings?.end_date) {
    endsOnValue ??= "END_DATE";
  }

  if (
    ledger_entry?.settings?.max_recurring_count &&
    ledger_entry?.settings?.max_recurring_count >= 0
  ) {
    endsOnValue ??= "NO_OF_ENTRIES";
  }

  if (
    !ledger_entry?.settings?.end_date &&
    !ledger_entry?.settings?.max_recurring_count
  ) {
    endsOnValue ??= "NEVER_ENDS";
  }

  const initialValues = {
    transaction_date,
    ledgerEntryId,
    ledger_entry_name: entry_name,
    transactions: localTransactions,
    is_recurring_enabled: ledger_entry?.settings?.is_recurring_enabled || false,
    frequency: ledger_entry?.settings?.frequency,
    end_date: ledger_entry?.settings?.end_date,
    max_recurring_count: ledger_entry?.settings?.max_recurring_count,
    recurring_count: ledger_entry?.settings?.recurring_count,
    ends_on: endsOnValue,
  };

  const [tempFormData, setTempFormData] = useState<
    typeof initialValues | null
  >();

  const confirmSubmit = async ({
    values,
    editAaction,
    stopAction,
  }: {
    values: typeof initialValues;
    editAaction?: RECURRING_JOUNRAL_ENTRY_UPDATE_TYPES_T;
    stopAction?: STOP_RECURRING_JOUNAL_ENTRY_TYPES_T;
  }) => {
    const { ledgerEntryId, ledger_entry_name, transaction_date, transactions } =
      values;

    const transactionList = transactions.map(
      ({ category, merchant, uuid, credit, debit, invoice, ...rest }) => {
        const transaction_id = uuid.includes("NEW_ENTRY")
          ? {}
          : { transaction_id: uuid };

        return {
          amount: Math.abs(Number(credit)) || Math.abs(Number(debit)) * -1,
          merchant_data_id: merchant,
          transaction_category_id: category!,
          invoice_id: invoice?.uuid || null,
          ...transaction_id,
          ...rest,
        };
      }
    );

    try {
      await updatedLedgerEntry({
        entityId,
        groupId,
        ledgerEntryId,
        transaction_date: dayjs(transaction_date).format(YYYY_MM_DD),
        ledger_entry_name,
        transactions: transactionList,
        event_update_type:
          editAaction || RECURRING_JOUNAL_ENTRY_UPDATE_TYPES.UPDATE_CURRENT,
        stop_recurrence_type: stopAction,
        is_recurring_enabled: values.is_recurring_enabled,
        max_recurring_count:
          values.end_date || !values.max_recurring_count
            ? undefined
            : Number(values.max_recurring_count),
        frequency: values.frequency,
        end_date:
          values.end_date && !values.max_recurring_count
            ? dayjs(values.end_date).format(YYYY_MM_DD)
            : undefined,
      }).unwrap();
      successToast({ message: "Transactions edited" });
      close();
    } catch (error) {
      alertToast({
        message: (error as BackendError)?.data?.error?.message,
      });
    }
  };

  const onSubmit = async (values: typeof initialValues) => {
    let settingsChanged =
      values.frequency !== initialValues.frequency ||
      values.end_date !== initialValues.end_date ||
      values.max_recurring_count !== initialValues.max_recurring_count;

    let dataChanged =
      values.ledger_entry_name !== initialValues.ledger_entry_name ||
      JSON.stringify(values.transactions) !==
        JSON.stringify(initialValues.transactions) ||
      values.transaction_date !== initialValues.transaction_date;

    const newRecurring =
      values.is_recurring_enabled && !initialValues.is_recurring_enabled;
    const recurring = values.is_recurring_enabled;

    const stopsRecurring =
      initialValues.is_recurring_enabled && !values.is_recurring_enabled;

    if (stopsRecurring) {
      setTempFormData(values);
      return stopRecurringModal.open();
    }

    if (recurring && !newRecurring) {
      if (settingsChanged && !dataChanged) {
        setTempFormData(values);
        return settingOnlyEditModal.open();
      }

      if (dataChanged) {
        setTempFormData(values);
        return settingOrAndDataEditModal.open();
      }
    }

    return confirmSubmit({
      values,
      editAaction: RECURRING_JOUNAL_ENTRY_UPDATE_TYPES.UPDATE_CURRENT,
    });
  };

  if (isLoading) {
    return (
      <Modal.Root open>
        <Modal.Content size="xxxl">
          <Modal.Header>
            <Modal.Title>Edit Journal Entry</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            <Loader />
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    );
  }

  const onSettingOnlyEditModalSubmit = () => {
    if (tempFormData) {
      confirmSubmit({
        values: tempFormData,
        editAaction:
          RECURRING_JOUNAL_ENTRY_UPDATE_TYPES.UPDATE_CURRENT_AND_FOLLOWING,
      });
      settingOnlyEditModal.close();
      setTempFormData(null);
    }
  };

  const onSettingOrAndDataEditModalSubmit = ({
    event,
  }: {
    event: RECURRING_JOUNRAL_ENTRY_UPDATE_TYPES_T;
  }) => {
    if (tempFormData) {
      confirmSubmit({
        values: tempFormData,
        editAaction: event,
      });
      settingOrAndDataEditModal.close();
      setTempFormData(null);
    }
  };

  const onStopRecurringModalSubmit = ({
    event,
  }: {
    event: STOP_RECURRING_JOUNAL_ENTRY_TYPES_T;
  }) => {
    if (tempFormData) {
      confirmSubmit({
        values: tempFormData,
        stopAction: event,
      });
      stopRecurringModal.close();
      setTempFormData(null);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnChange
        validationSchema={journalEntrySchema}
      >
        {({ submitForm, values, dirty }) => (
          <>
            <Modal.Root
              open={isOpen}
              onOpenChange={() => {
                close({
                  takeConfirmation: dirty,
                });
              }}
            >
              <Form>
                <JournalEntryFormWarrper>
                  {({ isValidTransactions, isAmountMatch }) => (
                    <Modal.Content useCustomOverlay size="xxxl">
                      <Modal.Header>
                        <div>
                          <Modal.Title>Edit Journal Entry</Modal.Title>
                          <Modal.Subtitle>
                            Please make sure the total debit amount matches the
                            total credit amount
                          </Modal.Subtitle>
                        </div>
                        <Modal.Close />
                      </Modal.Header>
                      <Async.Root {...{ isEmpty, isLoading, isSuccess }}>
                        <Async.Empty>
                          <></>
                        </Async.Empty>
                        <Async.Success>
                          <Modal.Body className="t-flex t-flex-col t-flex-grow t-gap-4">
                            <div className="t-flex t-gap-6 t-items-center t-justify-center">
                              <div className="t-flex t-gap-6">
                                <Field name="transaction_date">
                                  {({ field }: FieldProps) => {
                                    return (
                                      <DateInput
                                        label="Date"
                                        {...field}
                                        portalId="transaction_date"
                                        required
                                        block
                                        maxDate={new Date()}
                                        placeholder="DD-MMM-YYYY"
                                      />
                                    );
                                  }}
                                </Field>
                                <TextInput
                                  name="ledger_entry_name"
                                  label="Journal entry title"
                                  required
                                />
                              </div>

                              <div className="t-ml-auto">
                                <PermissionBasedUI
                                  blockedUI={
                                    <SwitchField
                                      disabled={!values.is_recurring_enabled}
                                      name="is_recurring_enabled"
                                      label={
                                        <label className="t-flex t-gap-1 t-justify-center">
                                          <span>Recurring entry</span>
                                          <span>
                                            <Padlock />
                                          </span>
                                        </label>
                                      }
                                    />
                                  }
                                  feature={RECURRING_JOURNAL_ENTRY}
                                >
                                  <SwitchField
                                    name="is_recurring_enabled"
                                    label="Recurring entry"
                                  />
                                </PermissionBasedUI>
                              </div>
                            </div>

                            <AnimatePresence>
                              {values.is_recurring_enabled && (
                                <AccordionAnimation>
                                  <div className="t-flex t-gap-6 t-pt-4">
                                    <RecurringJournalEntrySettings />
                                  </div>
                                </AccordionAnimation>
                              )}
                            </AnimatePresence>
                            <span className=" t-flex t-justify-end t-text-subtext t-text-neutral-50">
                              {values.transactions.length} entries added
                            </span>
                            <JournalEntryTable />
                          </Modal.Body>
                          <Modal.FooterButtonGroup>
                            <Button
                              type="reset"
                              onClick={() => close()}
                              disabled={updating}
                            >
                              Cancel
                            </Button>
                            <ConditionalToolTip
                              condition={
                                !isAmountMatch && (
                                  <span>
                                    Total debits and credits
                                    <br /> should match
                                  </span>
                                )
                              }
                            >
                              <span>
                                <Button
                                  customType="primary"
                                  type="submit"
                                  onClick={submitForm}
                                  disabled={!isValidTransactions || updating}
                                  isLoading={updating}
                                >
                                  Save changes
                                </Button>
                              </span>
                            </ConditionalToolTip>
                          </Modal.FooterButtonGroup>
                        </Async.Success>
                      </Async.Root>
                    </Modal.Content>
                  )}
                </JournalEntryFormWarrper>
              </Form>
            </Modal.Root>
          </>
        )}
      </Formik>

      <Modal.Root open={showConfirmation}>
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Your progress will be lost</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to exit? Your progress will not be saved.
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Button onClick={dismissConfirmation}>Cancel</Button>
            <Button onClick={() => close()} customType="danger">
              Exit
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>

      <Modal.Root
        open={settingOrAndDataEditModal.isOpen}
        onOpenChange={settingOrAndDataEditModal.close}
      >
        <Formik
          onSubmit={onSettingOrAndDataEditModalSubmit}
          initialValues={{
            event: RECURRING_JOUNAL_ENTRY_UPDATE_TYPES.UPDATE_CURRENT,
          }}
        >
          {({ submitForm, setFieldValue, values }) => (
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>Edit Journal Entry</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <p className="t-text-body t-text-text-100 t-mb-4">
                    This is a recurring journal entry, this action will update:
                  </p>
                  <RadioGroup.Root
                    onValueChange={(v) => setFieldValue("event", v)}
                    value={values.event}
                    className="t-flex t-flex-col t-gap-4"
                  >
                    <RadioGroup.Item
                      value={RECURRING_JOUNAL_ENTRY_UPDATE_TYPES.UPDATE_CURRENT}
                    >
                      <span className="t-text-body t-flex">This entry</span>
                    </RadioGroup.Item>
                    <RadioGroup.Item
                      value={
                        RECURRING_JOUNAL_ENTRY_UPDATE_TYPES.UPDATE_CURRENT_AND_FOLLOWING
                      }
                    >
                      <span className="t-text-body t-flex">
                        This and following entries
                      </span>
                    </RadioGroup.Item>
                  </RadioGroup.Root>
                </Form>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button>Cancel</Button>
                </Modal.RawClose>
                <Button customType="primary" onClick={submitForm}>
                  Save
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          )}
        </Formik>
      </Modal.Root>

      <Modal.Root
        open={stopRecurringModal.isOpen}
        onOpenChange={stopRecurringModal.close}
      >
        <Formik
          onSubmit={onStopRecurringModalSubmit}
          initialValues={{
            event: STOP_RECURRING_JOUNAL_ENTRY_TYPES.STOP,
          }}
        >
          {({ submitForm, setFieldValue, values }) => (
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>Edit Recurring Journal Entry</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <p className="t-text-body t-text-text-100 t-mb-4">
                    This is a recurring journal entry, this action will update:
                  </p>
                  <RadioGroup.Root
                    onValueChange={(v) => setFieldValue("event", v)}
                    value={values.event}
                    className="t-flex t-flex-col t-gap-4"
                  >
                    <RadioGroup.Item
                      value={STOP_RECURRING_JOUNAL_ENTRY_TYPES.STOP}
                    >
                      <span className="t-text-body t-flex">
                        Stop recurrence
                      </span>
                    </RadioGroup.Item>
                    <RadioGroup.Item
                      value={STOP_RECURRING_JOUNAL_ENTRY_TYPES.STOP_AND_DELETE}
                    >
                      <span className="t-text-body t-flex">
                        Delete related entries and stop recurrence
                      </span>
                    </RadioGroup.Item>
                  </RadioGroup.Root>
                </Form>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button>Cancel</Button>
                </Modal.RawClose>
                <Button customType="primary" onClick={submitForm}>
                  Save
                </Button>
              </Modal.FooterButtonGroup>
            </Modal.Content>
          )}
        </Formik>
      </Modal.Root>

      <Modal.Root
        open={settingOnlyEditModal.isOpen}
        onOpenChange={settingOnlyEditModal.close}
      >
        <Modal.Content>
          <Modal.Header>
            <Modal.Title>Edit Jounral Entry</Modal.Title>
            <Modal.Close />
          </Modal.Header>

          <Modal.Body>
            <p className="t-m-0 t-text-body">
              This is a recurring journal entry, this action will update this
              and following entries. Are you sure?
            </p>
          </Modal.Body>
          <Modal.FooterButtonGroup>
            <Modal.RawClose asChild>
              <Button>Cancel</Button>
            </Modal.RawClose>

            <Button customType="primary" onClick={onSettingOnlyEditModalSubmit}>
              Confirm
            </Button>
          </Modal.FooterButtonGroup>
        </Modal.Content>
      </Modal.Root>
    </>
  );
};
