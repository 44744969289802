import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { SelectDropDown } from "components/DesignSystem/SelectDropDown/SelectDropDown";
import { Form, Formik } from "formik";

type AddStateModalProps = {
  open: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (values: any) => void;
  states: {
    name: string;
    uuid: string;
  }[];
};

export const AddStateModal = ({
  open,
  onOpenChange,
  onSubmit,
  states,
}: AddStateModalProps) => {
  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Add state</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik initialValues={{ state: "" }} onSubmit={onSubmit}>
          <Form className="t-w-auto t-m-0">
            <Modal.Body>
              <SelectDropDown
                label="Select state for foreign qualification:"
                name="state"
              >
                <option className="t-text-text-30" value="">
                  Select a state
                </option>
                {states.map((state) => (
                  <option value={state?.uuid} key={state?.uuid}>
                    {state?.name}
                  </option>
                ))}
              </SelectDropDown>
            </Modal.Body>
            <Modal.Footer>
              <div className="t-flex t-justify-end t-gap-3">
                <Button
                  type="button"
                  onClick={() => onOpenChange(false)}
                  customType="secondary"
                >
                  Close
                </Button>
                <Button type="submit" customType="primary">
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};

export const EditStateModal = ({
  open,
  onOpenChange,
  onSubmit,
  complianceStatuses,
  raStatuses,
  selectedFiling,
}: {
  open: boolean;
  onOpenChange: React.Dispatch<React.SetStateAction<boolean>>;
  onSubmit: (values: any) => void;
  complianceStatuses: string[];
  raStatuses: string[];
  selectedFiling: string;
}) => {
  const formatText = (text: string) => {
    const words = text.split("_");
    return words
      .map((word) => `${word[0]}${word.slice(1).toLowerCase()}`)
      .join(" ");
  };

  return (
    <Modal.Root open={open} onOpenChange={onOpenChange}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>Edit state</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          initialValues={{
            state_filing_id: selectedFiling,
            foreign_qualification: "",
            franchise_tax: "",
            statement_of_information: "",
            income_tax_filing: "",
            registered_agent: "",
          }}
          onSubmit={onSubmit}
        >
          <Form className="t-w-auto t-m-0">
            <Modal.Body className="t-flex t-flex-col t-gap-4">
              <SelectDropDown
                label="Foreign Qualification"
                name="foreign_qualification"
              >
                <option className="t-text-text-30" value="">
                  Select status
                </option>
                {complianceStatuses.map((state) => (
                  <option value={state} key={state}>
                    {formatText(state)}
                  </option>
                ))}
              </SelectDropDown>
              <SelectDropDown label="Franchise Tax" name="franchise_tax">
                <option className="t-text-text-30" value="">
                  Select status
                </option>
                {complianceStatuses.map((state) => (
                  <option value={state} key={state}>
                    {formatText(state)}
                  </option>
                ))}
              </SelectDropDown>
              <SelectDropDown
                label="Statement of Information"
                name="statement_of_information"
              >
                <option className="t-text-text-30" value="">
                  Select status
                </option>
                {complianceStatuses.map((state) => (
                  <option value={state} key={state}>
                    {formatText(state)}
                  </option>
                ))}
              </SelectDropDown>
              <SelectDropDown
                label="Income tax filings"
                name="income_tax_filing"
              >
                <option className="t-text-text-30" value="">
                  Select status
                </option>
                {complianceStatuses.map((state) => (
                  <option value={state} key={state}>
                    {formatText(state)}
                  </option>
                ))}
              </SelectDropDown>
              <SelectDropDown label="Registered Agent" name="registered_agent">
                <option className="t-text-text-30" value="">
                  Select status
                </option>
                {raStatuses.map((state) => (
                  <option value={state} key={state}>
                    {formatText(state)}
                  </option>
                ))}
              </SelectDropDown>
            </Modal.Body>
            <Modal.Footer>
              <div className="t-flex t-justify-end t-gap-3">
                <Button
                  type="button"
                  onClick={() => onOpenChange(false)}
                  customType="secondary"
                >
                  Close
                </Button>
                <Button type="submit" customType="primary">
                  Add
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
