import React from "react";
import reportWebVitals from "./reportWebVitals";
import Routes from "./routes";
import * as Sentry from "@sentry/react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "./static/styles/common.css";
import "stream-chat-react/dist/css/v2/index.css";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import { store } from "store/store";
import { ErrorFallback } from "components/ErrorFallback";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { PostHogProvider } from "posthog-js/react";

const samplingRate =
  process.env.PUBLIC_SENTRY_ENV === "production" ||
  process.env.PUBLIC_SENTRY_ENV === "staging"
    ? 1.0
    : 0.1;

Sentry.init({
  dsn: process.env.PUBLIC_SENTRY_DNS,
  integrations: [new Sentry.BrowserTracing()],
  environment: process.env.PUBLIC_SENTRY_ENV || "staging",
  replaysSessionSampleRate: samplingRate,
  replaysOnErrorSampleRate: samplingRate,
  tracesSampleRate: samplingRate,
});

const postHogKey = process.env.PUBLIC_POSTHOG_KEY!;
const postHogHost =
  process.env.NODE_ENV === "production" ? process.env.PUBLIC_POSTHOG_HOST! : "";

const postHogOptions = {
  api_host: postHogHost,
};

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    {/* @ts-ignore */}
    <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
      <PostHogProvider apiKey={postHogKey} options={postHogOptions}>
        <Provider store={store}>
          <DndProvider backend={HTML5Backend}>
            <Routes />
          </DndProvider>
        </Provider>
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
);
reportWebVitals();
