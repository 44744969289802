import { Divider } from "components/design/Divider";
import { Combobox } from "components/DesignSystem/Combobox/Combobox";
import { DateInput } from "components/DesignSystem/DateInput/DateInput";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { US_UUID } from "constants/regAgent";
import { Field, FieldProps, useFormikContext } from "formik";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { SalesTaxBusinessPayload } from "store/apis/salesTax";
import { useGetCountryStatesQuery } from "store/apis/teamSetting";

export const SalesTaxEntityActivityDetails = () => {
  const group = useCurrentGroupContext();

  const { values } = useFormikContext<
    SalesTaxBusinessPayload & {
      mailing_same_as_business_address: boolean;
    }
  >();

  const entityId = group.entities?.[0]?.uuid;

  const { data: states, isLoading } = useGetCountryStatesQuery({
    countryId: US_UUID,
  });

  const statesOptions = states?.map((state) => ({
    value: state.state_id,
    label: state.name,
  }));

  return (
    <div className="t-flex t-justify-center">
      <div className="t-w-[650px]">
        <div>
          <p className="t-text-title-h2-bold t-mb-1">
            Business activity details
          </p>
          <p className="t-text-body-sm t-text-text-30">
            This business information is required for enabling automated tax
            remittance.
          </p>
        </div>

        <div className="t-flex t-flex-col t-gap-4">
          <div className="t-grid t-grid-cols-2 t-gap-4">
            <TextInput
              tooltipText="NAICS is a six-digit code system used to classify businesses based on their primary economic activity."
              name="naics_code"
              label="NAICS Code"
              placeholder="Enter code"
            />
            <Field name="collect_tax_from">
              {({ field }: FieldProps) => (
                <DateInput
                  {...field}
                  showMonthYearPicker
                  label="Tax collection start date"
                  required
                  portalId={field.name}
                />
              )}
            </Field>
          </div>

          <TextInput
            tooltipText="What you sell ?"
            name="major_business_activity"
            label="Major business activity"
            placeholder="Enter business activity"
          />

          <div className="t-my-2.5">
            <Divider />
          </div>

          <p className="t-text-subtext t-m-0">State details</p>

          <Combobox
            withForm
            isMulti
            name="warehouse_states"
            label="Warehouses"
            options={statesOptions}
            value={
              statesOptions?.filter((state) =>
                values.warehouse_states?.includes(state.value)
              ) || []
            }
          />
          <Combobox
            withForm
            isMulti
            name="office_states"
            label="Offices"
            options={statesOptions}
            value={
              statesOptions?.filter((state) =>
                values.office_states?.includes(state.value)
              ) || []
            }
          />
          <Combobox
            withForm
            isMulti
            name="payroll_states"
            label="Employees"
            options={statesOptions}
            value={
              statesOptions?.filter((state) =>
                values.payroll_states?.includes(state.value)
              ) || []
            }
          />
        </div>
      </div>
    </div>
  );
};
