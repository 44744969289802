import { createContext, useState } from "react";

export const SelectedChannelsContext = createContext<{
  selectedChannels: string[];
  setSelectedChannels: React.Dispatch<React.SetStateAction<string[]>>;
}>({
  selectedChannels: [],
  setSelectedChannels: () => {},
});

export const SelectedChannels = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedChannels, setSelectedChannels] = useState<string[]>([]);

  return (
    <SelectedChannelsContext.Provider
      value={{ selectedChannels, setSelectedChannels }}
    >
      {children}
    </SelectedChannelsContext.Provider>
  );
};
