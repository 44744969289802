import React from "react";

export const Crown = ({ size = "16" }: { size?: string }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M14.4315 4.59994C14.236 4.50409 14.0143 4.47535 13.8008 4.51817C13.5872 4.56099 13.3938 4.67298 13.2503 4.83682L11.1459 7.10494L8.9084 2.08682C8.9083 2.08474 8.9083 2.08265 8.9084 2.08057C8.82839 1.90704 8.70034 1.76006 8.5394 1.65704C8.37846 1.55402 8.19137 1.49927 8.00028 1.49927C7.80919 1.49927 7.6221 1.55402 7.46116 1.65704C7.30022 1.76006 7.17216 1.90704 7.09215 2.08057C7.09226 2.08265 7.09226 2.08474 7.09215 2.08682L4.85465 7.10494L2.75028 4.83682C2.60588 4.67295 2.41181 4.56086 2.19771 4.51768C1.98361 4.4745 1.76126 4.5026 1.56464 4.59769C1.36801 4.69278 1.20792 4.84962 1.10884 5.04426C1.00975 5.2389 0.977108 5.46063 1.0159 5.67557C1.0159 5.68244 1.0159 5.68869 1.02028 5.69557L2.43778 12.1874C2.48141 12.416 2.60341 12.6223 2.78276 12.7706C2.9621 12.9189 3.18755 13 3.42028 12.9999H12.5809C12.8135 12.9999 13.0388 12.9187 13.218 12.7704C13.3973 12.6221 13.5192 12.4159 13.5628 12.1874L14.9803 5.69557C14.9803 5.68869 14.9803 5.68244 14.9847 5.67557C15.0242 5.46036 14.9914 5.23813 14.8913 5.04354C14.7912 4.84896 14.6296 4.69297 14.4315 4.59994ZM12.5847 11.9799L12.5809 11.9999H3.41965L3.4159 11.9799L2.00028 5.49994L2.00903 5.50994L4.63403 8.33744C4.69024 8.39818 4.76064 8.44402 4.83893 8.47084C4.91722 8.49767 5.00094 8.50464 5.08259 8.49114C5.16424 8.47763 5.24126 8.44407 5.30674 8.39347C5.37222 8.34286 5.42412 8.2768 5.45778 8.20119L8.00028 2.49994L10.5434 8.20307C10.5771 8.27867 10.629 8.34474 10.6944 8.39534C10.7599 8.44595 10.8369 8.47951 10.9186 8.49301C11.0002 8.50652 11.084 8.49954 11.1622 8.47272C11.2405 8.44589 11.3109 8.40006 11.3672 8.33932L13.9922 5.51182L14.0003 5.49994L12.5847 11.9799Z"
        fill="currentColor"
      />
    </svg>
  );
};
