import classNames from "classnames";
import { Divider } from "components/design/Divider";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import DropDown from "components/DesignSystem/Dropdown/Dropdown";
import Modal from "components/DesignSystem/Modal/Modal";
import { ArrowRight } from "components/icons/ArrowRight";
import { Price } from "components/Price";
import { REGISTERED_AGENT } from "constants/addons";
import { US_UUID } from "constants/regAgent";
import { Form, Formik } from "formik";
import { useAppSelector } from "hooks/useAppSelector";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useQuery, useUpdateQuery } from "hooks/useQuery";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAddProductToCartMutation } from "store/apis/products";
import { useGetSubscriptionsQuery } from "store/apis/subscriptions";
import { useGetCountryStatesQuery } from "store/apis/teamSetting";
import {
  setAddonPayload,
  setAddonType,
  setOpenCheckoutModal,
} from "store/slices/addons";
import { setPaymentTitle, setUsableCredit } from "store/slices/credit";
import { RootState } from "store/store";
import { GroupSubscriptionState } from "types/Models/subscription";

export const RegisteredAgent = () => {
  const { addonData } = useSelector((state: RootState) => state.addonsSlice);
  const [showAddonModal, setShowAddonModal] = useState<boolean>(
    addonData?.subscription_type === REGISTERED_AGENT
  );
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const dispatch = useDispatch();
  const { data: states, isLoading } = useGetCountryStatesQuery({
    countryId: US_UUID,
  });
  const {
    subscription_name,
    billing_cycle,
    group_subscription,
    selectedState,
    disableStateChange,
  } = addonData || {};
  const { update } = useUpdateQuery();
  const query = useQuery();
  const isSubscriptionOpen = query.get("subscription_to_open");

  const isCartSubscriptionActive = useAppSelector(
    (state) => state.cartSubscription.isCartSubscriptionActive
  );

  const [addToCart, { isLoading: addingToCart }] =
    useAddProductToCartMutation();

  const { data: subscriptions } = useGetSubscriptionsQuery(
    {
      groupId,
      entityId,
    },
    { skip: !groupId || !entityId }
  );

  const handleAddonNext = async (values: {
    selectedStateUuid: string;
    selectedStateAmount: string;
    selectedState: string;
  }) => {
    const subscription = subscriptions?.find(
      (s) => s.uuid === values.selectedStateUuid
    );

    if (isCartSubscriptionActive && groupId && subscription) {
      await addToCart({
        groupId: groupId,
        entityId,
        payload: {
          product_id: values.selectedStateUuid,
          ra_state_name: values?.selectedState,
          product_content_type_id: subscription.product_content_type_id,
        },
      });

      dispatch(setAddonType(""));
      setShowAddonModal(false);
      return;
    }

    update({
      query: "subscription_to_open",
      value: null,
    });
    dispatch(
      setAddonPayload({
        title: addonData?.subscription_name,
        amount: values?.selectedStateAmount,
        selectedState: values?.selectedState,
        uuid: values?.selectedStateUuid,
        type: addonData?.subscription_type,
      })
    );
    dispatch(setUsableCredit(false));
    dispatch(
      setPaymentTitle(
        `${addonData?.subscription_name!} - ${values?.selectedState} `
      )
    );
    dispatch(setAddonType(""));
    dispatch(setOpenCheckoutModal());
    setShowAddonModal(false);
  };

  const selectedStates = group_subscription?.map(
    (state: GroupSubscriptionState) => state.ra_state_name
  );

  const filteredStates: {
    stateName: string;
    amount: string;
    uuid: string;
  }[] =
    states
      ?.filter((state: any) => !selectedStates?.includes(state.name))
      ?.map((ele: any) => {
        const stateMap = addonData?.ra_state_amount_maps?.find(
          (state) => state.ra_state === ele.name
        );

        const amount = stateMap?.amount || addonData?.amount;
        const registeredAgentUuid = stateMap?.uuid || addonData?.uuid;

        return {
          stateName: ele.name,
          amount: amount?.toString() || "",
          uuid: registeredAgentUuid || "",
        };
      }) || [];

  const onClose = () => {
    setShowAddonModal(false);
    dispatch(setAddonType(""));
    if (isSubscriptionOpen) {
      update({
        query: "subscription_to_open",
        value: null,
      });
    }
  };

  const selectedStateDetail = filteredStates.find(
    (state) => state.stateName === selectedState
  );

  return (
    <Modal.Root open={showAddonModal} onOpenChange={onClose}>
      <Modal.Content>
        <Modal.Header>
          <Modal.Title>{subscription_name}</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Formik
          initialValues={{
            selectedState: selectedStateDetail?.stateName || "",
            selectedStateAmount: selectedStateDetail?.amount || "",
            selectedStateUuid: selectedStateDetail?.uuid || "",
          }}
          onSubmit={handleAddonNext}
          enableReinitialize
        >
          {({ values, isSubmitting, setFieldValue }) => (
            <Form className="all:unset">
              <Modal.Body>
                <div className="t-flex t-flex-col t-gap-4">
                  <div className="t-text-text-60 t-text-body">
                    Inkle Virtual Registered Agent (RA) is available in all US
                    states as a monthly subscription. Select a state to get
                    started.
                  </div>
                  <div className="t-w-full t-flex t-gap-10 t-items-end">
                    <div className="t-w-[280px]">
                      <DropDown.Root>
                        <DropDown.Trigger asChild disabled={disableStateChange}>
                          <div className="t-flex t-flex-col t-gap-[6px]">
                            <div className="t-font-sans t-text-body-sm t-text-neutral-80">
                              State
                            </div>
                            <div
                              className={classNames(
                                "secondary-border t-flex t-items-center t-justify-between t-px-2 t-py-2.5 t-text-body t-font-medium t-drop-shadow-i-dropdown",
                                {
                                  "t-cursor-not-allowed": disableStateChange,
                                }
                              )}
                            >
                              <>
                                <div className="t-flex t-items-center t-justify-between t-w-full">
                                  <div className="t-text-body">
                                    {values?.selectedState || "Select state"}
                                  </div>
                                  <div className="t-rotate-90 group-data-state-open:-t-rotate-90">
                                    <ArrowRight
                                      stroke="2.5"
                                      color="currentColor"
                                    />
                                  </div>
                                </div>
                              </>
                            </div>
                          </div>
                        </DropDown.Trigger>
                        <DropDown.Portal>
                          <DropDown.Content
                            sideOffset={8}
                            side="bottom"
                            className="t-w-[280px]"
                          >
                            <div className="t-h-56 t-overflow-auto">
                              {isLoading ? (
                                <Loader />
                              ) : (
                                <>
                                  {filteredStates?.map(
                                    ({
                                      stateName,
                                      amount,
                                      uuid,
                                    }: {
                                      stateName: string;
                                      amount: string;
                                      uuid: string;
                                    }) => (
                                      <DropDown.Item
                                        onSelect={() => {
                                          setFieldValue(
                                            "selectedState",
                                            stateName
                                          );
                                          setFieldValue(
                                            "selectedStateAmount",
                                            amount
                                          );
                                          setFieldValue(
                                            "selectedStateUuid",
                                            uuid
                                          );
                                        }}
                                        key={stateName}
                                      >
                                        {stateName}
                                      </DropDown.Item>
                                    )
                                  )}
                                </>
                              )}
                            </div>
                          </DropDown.Content>
                        </DropDown.Portal>
                      </DropDown.Root>
                    </div>
                    <div>
                      <div className="t-text-text-30 t-text-body-sm">
                        Monthly subscription
                      </div>
                      <div className="t-text-body">
                        {values?.selectedStateAmount
                          ? `$${values?.selectedStateAmount}`
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <Divider />
                  <div className="t-flex t-justify-between">
                    <div className="t-text-body-sm t-text-text-30">Total</div>
                    <div className="t-text-body">
                      {values?.selectedStateAmount ? (
                        <Price
                          size="medium"
                          cycle={billing_cycle!}
                          amount={values?.selectedStateAmount}
                        />
                      ) : (
                        "-"
                      )}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="t-flex t-justify-end t-gap-3">
                <Button type="button" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  customType="primary"
                  isLoading={isSubmitting}
                  disabled={!values?.selectedState || isSubmitting}
                >
                  Next
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
