import { RegisterAgentResult } from "types/Models/registerAgent";
import { emptyApi } from "./emptyApi";

export type CreateRegisteredAgentProps = {
  payload: {
    agent_name: string;
    start_date: string;
    expiration_date: string;
    street_address: string;
    street_address_line_2: string | null;
    country: string;
    state: string;
    city: string;
    zipcode: string;
    phone_number: string;
  };
  groupId: string;
  raId?: string;
};

const extendedApi = emptyApi.injectEndpoints({
  endpoints: (build) => ({
    getRegisteredAgent: build.query<
      RegisterAgentResult[],
      { groupId: string; entityId: string }
    >({
      query: ({ groupId, entityId }) =>
        `/api/inkle/agent/group/${groupId}/entity/${entityId}`,
      providesTags: () => {
        return [{ type: "REGISTER_AGENT", id: "LIST" }];
      },
    }),

    createRegisteredAgent: build.mutation<
      RegisterAgentResult[],
      CreateRegisteredAgentProps & { entityId: string }
    >({
      query: ({ groupId, payload, entityId }) => ({
        url: `/api/inkle/agent/group/${groupId}/entity/${entityId}/`,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: () => {
        return [{ type: "REGISTER_AGENT" }];
      },
    }),

    updateRegisteredAgent: build.mutation<
      RegisterAgentResult,
      CreateRegisteredAgentProps
    >({
      query: ({ groupId, payload, raId }) => ({
        url: `/api/inkle/agent/group/${groupId}/update/${raId}/`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: (result) => {
        return [
          { type: "REGISTER_AGENT", id: result?.agent_group_mapping_uuid },
          { type: "REGISTER_AGENT", id: "LIST" },
        ];
      },
    }),

    getRAById: build.query<
      RegisterAgentResult,
      { groupId: string; raId: string; entityId: string }
    >({
      query: ({ groupId, raId, entityId }) =>
        `/api/inkle/agent/group/${groupId}/entity/${entityId}/agent/${raId}/`,
      providesTags: (result) => {
        return [
          { type: "REGISTER_AGENT", id: result?.agent_group_mapping_uuid },
          { type: "REGISTER_AGENT", id: "LIST" },
        ];
      },
    }),
  }),

  overrideExisting: false,
});

export const {
  useGetRegisteredAgentQuery,
  useCreateRegisteredAgentMutation,
  useGetRAByIdQuery,
  useUpdateRegisteredAgentMutation,
} = extendedApi;
