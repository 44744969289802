import classNames from "classnames";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import ConditionalToolTip from "components/design/conditionalToolTip";
import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { DividerLine } from "components/icons/DividerLine";
import { InfoFilledSmall } from "components/icons/InfoFilledSmall";
import { SmallInfo } from "components/icons/SmallInfo";
import { InfoSolid } from "components/InfoSolid";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import { BILLING_CYCLE } from "dictionaries";
import { createColumnHelper } from "react-table-8.10.7";
import { Cart as CartT } from "store/apis/products";
import { US_BOOKKEEPING_ONE_TIME } from "types/Models/services";
import { ArrayElement } from "types/utils/ArrayElement";
import { formatDate } from "utils/formatDate";
import { PERIOD_MAP } from "./CartModal";
import FilingInfoIcon from "static/images/FilingInfo.svg";
dayjs.extend(isSameOrBefore);

export const createCartColumn =
  createColumnHelper<ArrayElement<CartT["cart_items"]>>();

export const PAYMENT_ACCOUNTING_METHODS_MAP = {
  CASH: (
    <div className="t-flex t-items-center t-gap-1">
      Cash method
      <ToolTip
        text={
          <div className="t-p-3 t-text-left t-w-80">
            <div className="t-text-subtext">Cash</div>
            <div className="t-text-body-sm t-mt-1">
              The cash method provides immediate recognition of revenue and
              expenses when money is received or spent.
            </div>
            <div className="t-mt-4 t-text-body-sm">
              Example: If you complete a service worth $100 on March 15 and the
              customer pays you on April 10, you record the $100 as income on
              April 10 when the cash is received.
            </div>
          </div>
        }
      >
        <img src={FilingInfoIcon} alt="info" />
      </ToolTip>
    </div>
  ),
  ACCRUAL: (
    <div className="t-flex t-items-center t-gap-1">
      Accrual method
      <ToolTip
        text={
          <div className="t-p-3 t-text-left t-w-80">
            <div className="t-text-subtext">Accrual</div>
            <div className="t-text-body-sm t-mt-1">
              The accrual method focuses on anticipated revenue and expenses,
              recording them when they are earned or incurred, regardless of
              cash movement.
            </div>
            <div className="t-mt-4 t-text-body-sm">
              Example: If you complete a service worth $100 on March 15 but get
              paid on April 10, you still record $100 as income on March 15.
            </div>
          </div>
        }
      >
        <img src={FilingInfoIcon} alt="info" />
      </ToolTip>
    </div>
  ),
};

const ItemColumn = ({
  cartItem,
}: {
  cartItem: ArrayElement<CartT["cart_items"]>;
}) => {
  const {
    ra_state_name,
    tier_information,
    accounting_method,
    product_details: {
      season,
      has_balancing_payment,
      state,
      product_name,
      slabs,
    },
    subscription,
  } = cartItem;

  return (
    <div className="t-flex t-flex-col t-gap-0.5">
      <p className="t-m-0">{product_name}</p>
      <p className="t-m-0 t-flex t-gap-1.5 t-items-center t-text-body-sm t-text-text-30">
        {accounting_method && (
          <span>{PAYMENT_ACCOUNTING_METHODS_MAP[accounting_method]}</span>
        )}
        {ra_state_name && (
          <>
            <span className="t-text-neutral-20">
              <DividerLine />
            </span>
            <span>{ra_state_name}</span>
          </>
        )}
        {tier_information && (
          <>
            <span className="t-text-neutral-20">
              <DividerLine />
            </span>
            <span>{tier_information.range}</span>
          </>
        )}
        {subscription && (
          <>
            <span className="t-text-neutral-20">
              <DividerLine />
            </span>
            <span>Subscription</span>
          </>
        )}
        {season && state && (
          <span className="t-text-neutral-20">
            <DividerLine />
          </span>
        )}{" "}
        <span>{state?.name}</span>
      </p>
      {has_balancing_payment && (
        <p className="t-m-0 t-flex t-gap-1.5 t-items-center t-mt-1 t-text-body-sm">
          <span className="t-text-orange t-flex">
            <InfoFilledSmall color="currentColor" />
          </span>
          <span>May have extra charges.</span>

          <Modal.Root>
            <Modal.Trigger asChild>
              {slabs && (
                <button className="all:unset t-text-purple t-underline">
                  Learn more
                </button>
              )}
            </Modal.Trigger>
            <Modal.Content>
              <Modal.Header>
                <Modal.Title>{product_name}</Modal.Title>
                <Modal.Close />
              </Modal.Header>

              <Modal.Body>
                {slabs && (
                  <div
                    className="t-text-body"
                    dangerouslySetInnerHTML={{
                      __html: slabs,
                    }}
                  />
                )}
              </Modal.Body>

              <Modal.Footer>
                <Modal.RawClose asChild>
                  <Button block>Go back to cart</Button>
                </Modal.RawClose>
              </Modal.Footer>
            </Modal.Content>
          </Modal.Root>
        </p>
      )}
    </div>
  );
};

export const cartColumn = {
  name: () =>
    createCartColumn.accessor("product_details.product_name", {
      header: "Item",
      cell: (info) => {
        return <ItemColumn cartItem={info.row.original} />;
      },
      size: 50,
    }),

  quantity: () =>
    createCartColumn.accessor("quantity", {
      header: "QTY",
      size: 3,
    }),

  season: () =>
    createCartColumn.accessor("product_details.season", {
      header: "Period",
      cell: (info) => {
        const season = info.getValue();
        const billingCycle = info.row.original.subscription?.billing_cycle;

        if (info.row.original.product_details.from_date) {
          const isPast = dayjs(info.row.original.product_details.from_date)
            .date(1)
            .isSameOrBefore(dayjs().date(1));

          const startDate = isPast
            ? dayjs().add(1, "month").date(1)
            : dayjs(info.row.original.product_details.from_date);

          return (
            <ConditionalToolTip
              condition={
                info.row.original.subscription &&
                `The first deduction of this payment will happen on ${formatDate(
                  startDate
                )}`
              }
              align="start"
            >
              <div className="t-text-text-30 t-text-body-sm t-flex t-gap-1">
                <span>
                  {dayjs(info.row.original.product_details.from_date).format(
                    "MMM YYYY"
                  )}{" "}
                  {info.row.original.product_details.to_date && (
                    <>
                      -{" "}
                      {dayjs(info.row.original.product_details.to_date).format(
                        "MMM YYYY"
                      )}
                    </>
                  )}
                </span>
                {info.row.original.subscription && <InfoSolid size="15" />}
              </div>
            </ConditionalToolTip>
          );
        }

        if (!info.row.original.subscription) {
          return (
            <div className="t-text-text-30 t-text-body-sm">
              {season ? `Season ${season}` : "-"}
            </div>
          );
        }

        if (!billingCycle) {
          return "-";
        }

        const validTill = formatDate(
          dayjs().add(1, PERIOD_MAP[billingCycle] as dayjs.ManipulateType)
        );

        return (
          <div className="t-text-text-30 t-text-body-sm">
            {formatDate(dayjs())} - <br />
            {validTill}
          </div>
        );
      },
      size: 24,
    }),

  total: () =>
    createCartColumn.accessor("total", {
      header: "price",
      cell: (info) => {
        const { discount, subtotal, accounting_method } = info.row.original;
        const isDiscounted = discount && discount.discount_value > 0;

        if (
          info.row.original.product_details.base_task_key ===
          US_BOOKKEEPING_ONE_TIME
        ) {
          const startPricing = accounting_method === "CASH" ? 199 : 399;

          if (!isNaN(Number(subtotal)) && Number(subtotal) !== 0) {
            return <AmountSuperScript amount={subtotal} />;
          }

          return (
            <div>
              <div className="t-text-subtext t-text-text-60">Post paid</div>
              <div className="t-text-text-30 t-text-body-sm">
                Starts at ${startPricing}
              </div>
            </div>
          );
        }

        return (
          <div className="">
            <p
              className={classNames("t-m-0 t-flex", {
                "t-line-through t-text-body-sm t-text-text-30": isDiscounted,
              })}
            >
              <AmountSuperScript amount={subtotal} />
              {info.row.original.subscription && (
                <span className="t-text-text-30 t-flex t-gap-1 t-text-nowrap t-items-center">
                  /
                  {BILLING_CYCLE[info.row.original.subscription?.billing_cycle]}
                  {!isDiscounted &&
                    !info.row.original.product_details.from_date && (
                      <ToolTip
                        text={`Renews on ${formatDate(
                          dayjs().add(
                            1,
                            PERIOD_MAP[
                              info.row.original.subscription?.billing_cycle
                            ] as dayjs.ManipulateType
                          )
                        )}`}
                        side="bottom"
                      >
                        <span className="t-text-text-30">
                          <SmallInfo />
                        </span>
                      </ToolTip>
                    )}
                </span>
              )}
            </p>
            {Boolean(discount) && discount && discount.discount_value > 0 && (
              <p className="t-m-0 t-flex t-flex-nowrap t-items-center">
                <AmountSuperScript amount={info.getValue()} />
                {info.row.original.subscription && (
                  <span className="t-text-text-30 t-flex t-gap-1 t-flex-nowrap t-items-center t-text-nowrap">
                    /
                    {
                      BILLING_CYCLE[
                        info.row.original.subscription?.billing_cycle
                      ]
                    }
                    {isDiscounted && !info.row.original.from_date && (
                      <ToolTip
                        text={`Renews on ${formatDate(
                          dayjs().add(
                            1,
                            PERIOD_MAP[
                              info.row.original.subscription?.billing_cycle
                            ] as dayjs.ManipulateType
                          )
                        )}`}
                        side="bottom"
                      >
                        <span className="t-text-text-30">
                          <SmallInfo />
                        </span>
                      </ToolTip>
                    )}
                  </span>
                )}
              </p>
            )}
          </div>
        );
      },
      size: 20,
    }),
};
