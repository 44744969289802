import ToolTip from "components/design/toolTip";
import { Button } from "components/DesignSystem/Button/Button";
import Card from "components/DesignSystem/Card/Card";
import Table from "components/DesignSystem/Table/V2/Table";
import { ClockCountdown } from "components/icons/ClockCountdown";
import { PlusIcon } from "components/icons/PlusIcon";
import { CART_ITEM_STATUS } from "constants/cart";
import { useToast } from "hooks/useToast";
import { flexRender, getCoreRowModel, useReactTable } from "react-table-8.10.7";
import {
  Cart as CartT,
  useGetCartItemsQuery,
  useUpdateCartItemMutation,
} from "store/apis/products";
import { BackendError } from "types/utils/error";
import { cartColumn, createCartColumn } from "./CartTableColumn";
import { useAnalytics } from "hooks/useAnalytics";
import { MOVE_TO_CART } from "constants/analyticsEvents";
import { ArrayElement } from "types/utils/ArrayElement";

type SaveForLaterCardProps = {
  entityId: string;
};

const CartItemActions = ({
  cartItem,
  entityId,
}: {
  cartItem: ArrayElement<CartT["cart_items"]>;
  entityId: string;
}) => {
  const { successToast, alertToast } = useToast();
  const { trackEvent } = useAnalytics();

  const [saveForLater, { isLoading: isSaving }] = useUpdateCartItemMutation();

  const onSaveForLater = async () => {
    try {
      await saveForLater({
        entityId,
        payload: {
          cartItemId: cartItem.uuid,
          status: CART_ITEM_STATUS.IN_CART,
        },
      }).unwrap();
      trackEvent(MOVE_TO_CART, {
        cart_item_id: cartItem.uuid,
      });
      successToast({ message: "Item has been moved to cart" });
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <div className="t-flex t-justify-end">
      <ToolTip text="Move to cart">
        <Button
          isLoading={isSaving}
          disabled={isSaving}
          customType="ghost_icon"
          size="small"
          onClick={onSaveForLater}
        >
          <span className="t-text-text-30">
            <PlusIcon size="16" />
          </span>
        </Button>
      </ToolTip>
    </div>
  );
};

export const SaveForLaterCard = ({ entityId }: SaveForLaterCardProps) => {
  const { data } = useGetCartItemsQuery(
    { entityId, status: CART_ITEM_STATUS.SAVED_FOR_LATER },
    {
      skip: !entityId,
    }
  );

  const columns = [
    cartColumn.name(),
    cartColumn.quantity(),
    cartColumn.season(),
    cartColumn.total(),
    createCartColumn.display({
      header: "",
      id: "actions",
      cell: (info) => {
        return (
          <CartItemActions
            cartItem={info.row.original}
            // @ts-ignore
            entityId={info.table.options.meta.entityId}
          />
        );
      },
      size: 15,
    }),
  ];

  const table = useReactTable({
    data: data?.cart_items || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    defaultColumn: { minSize: 10 },
    meta: {
      entityId: entityId!,
    },
  });

  return (
    <Card.Root
      borderRounded="lg"
      className="t-bg-surface-lighter-grey"
      variant="secondary"
    >
      <Card.Header className="t-bg-surface-lighter-grey !t-px-4">
        <Card.Title className="t-flex t-items-center t-gap-2 t-text-subtitle-sm t-text-text-60">
          <span className="t-text-text-30">
            <ClockCountdown size="20" />
          </span>
          Save for Later ({data?.cart_items.length || 0})
        </Card.Title>
      </Card.Header>
      <Card.Body className="!t-p-0">
        <Table.Container className="t-h-full t-w-full">
          <Table.Content className="t-w-full">
            <Table.Head className="t-bg-surface-lighter-grey">
              {table.getHeaderGroups().map((headerGroup) => (
                <Table.Row key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <Table.HeadCell
                      key={header.id}
                      className="t-text-subtext-sm t-uppercase !t-p-3"
                      style={{ width: `${header.getSize()}%` }}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </Table.HeadCell>
                  ))}
                </Table.Row>
              ))}
            </Table.Head>
            <Table.Body>
              {table.getRowModel().rows.map((row) => (
                <Table.Row key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td
                      key={cell.id}
                      style={{
                        width: `${cell.column.getSize()}%`,
                      }}
                      className="t-p-3"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </Table.Row>
              ))}
            </Table.Body>
          </Table.Content>
        </Table.Container>
      </Card.Body>
    </Card.Root>
  );
};
