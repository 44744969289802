import { Address } from "components/Address/Address";
import { AmountSuperScript } from "components/design/AmountSuperScript";
import Async from "components/DesignSystem/AsyncComponents/Async";
import { Button } from "components/DesignSystem/Button/Button";
import Card from "components/DesignSystem/Card/Card";
import { InfoItem } from "components/DesignSystem/InfoItem/InfoItem";
import Slider from "components/DesignSystem/Slider/Slider";
import { Tag } from "components/DesignSystem/Tag/Tag";
import { CopyIcon } from "components/icons/CopyIcon";
import { DD_MMM_YYYY } from "constants/date";
import {
  BILL_STATUS,
  BILL_STATUS_LABEL,
  FINANCIAL_RECORD_TYPE,
  TAG_TYPE,
} from "constants/vendorBills";
import dayjs from "dayjs";
import { useConstructInternalLink } from "hooks/useConstructInternalLink";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { useCurrentEntityId } from "hooks/useCurrentEntityId";
import { useCurrentGroupContext } from "hooks/useCurrentGroupContext";
import { useHistory } from "react-router-dom";
import { useGetBillOrRequestQuery } from "store/apis/vendorBills";
import { VendorBill } from "types/Models/vendorBills";
import { ModalProps } from "types/utils/modal";

export const GridLayout = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return <div {...props} className="t-grid t-grid-cols-2 t-gap-4" />;
};

export const BillSlider = ({
  close,
  isOpen,
  billId,
}: ModalProps & { billId: string }) => {
  const history = useHistory();
  const { link } = useConstructInternalLink();
  const { uuid: groupId } = useCurrentGroupContext();
  const entityId = useCurrentEntityId();
  const { copyToClipboard } = useCopyToClipboard();

  const { data, isLoading, isSuccess, isError } = useGetBillOrRequestQuery(
    { groupId, entityId, billId },
    {
      skip: !billId || !entityId || !groupId,
    }
  );

  const isBill = data?.bill_document_type === FINANCIAL_RECORD_TYPE.BILL;
  const status = isBill ? data?.bill_payment_status : data?.bill_request_status;
  const isDraft = status === BILL_STATUS.DRAFT;

  const onClick = ({ bill }: { bill: VendorBill }) => {
    if (bill.bill_document_type === FINANCIAL_RECORD_TYPE.REQUEST) {
      history.push(
        link(`/books/bills-and-vendors/request/${bill.uuid}`, {
          moreQuery: { BILL_TYPE: FINANCIAL_RECORD_TYPE.REQUEST },
        })
      );
      return;
    }
    if (bill.bill_document_type === FINANCIAL_RECORD_TYPE.BILL) {
      history.push(
        link(`/books/bills-and-vendors/bill/${bill.uuid}`, {
          moreQuery: { BILL_TYPE: FINANCIAL_RECORD_TYPE.BILL },
        })
      );
      return;
    }
  };
  return (
    <Slider.Root open={isOpen} onOpenChange={close}>
      <Slider.Content>
        <Async.Root
          isLoading={isLoading}
          isSuccess={isSuccess}
          isError={isError}
          isEmpty={!data}
        >
          <Async.Empty>
            <></>
          </Async.Empty>
          <Async.Success>
            {data && (
              <>
                <Slider.Header>
                  <Slider.Title className="t-flex t-gap-1 t-items-center">
                    {data.bill_number}
                    {status && (
                      <Tag tagType={TAG_TYPE[status]} rounded icon={false}>
                        {BILL_STATUS_LABEL[status]}
                      </Tag>
                    )}
                  </Slider.Title>
                  <Slider.Close />
                </Slider.Header>
                <Slider.Body className="t-flex t-flex-col t-gap-4">
                  <Card.Root>
                    <Card.Header>
                      <Card.Title>Bill Details</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <GridLayout>
                        <InfoItem label="Title">{data.title || "-"}</InfoItem>
                        <InfoItem label="Due Date">
                          {data.due_date
                            ? dayjs(data.due_date).format(DD_MMM_YYYY)
                            : "-"}
                        </InfoItem>

                        <InfoItem label="Amount Paid">
                          {data.paid_amount ? (
                            <AmountSuperScript
                              amount={Number(data.paid_amount)}
                            />
                          ) : (
                            "-"
                          )}
                        </InfoItem>

                        <InfoItem label="Payment Link">
                          {data.payment_link ? (
                            <div className="t-flex t-gap-1">
                              <div className="t-truncate">
                                {data.payment_link}
                              </div>
                              <Button
                                customType="link"
                                size="small"
                                onClick={() =>
                                  copyToClipboard(data.payment_link)
                                }
                              >
                                <CopyIcon color="currentColor" />
                              </Button>
                            </div>
                          ) : (
                            "-"
                          )}
                        </InfoItem>
                      </GridLayout>
                    </Card.Body>
                  </Card.Root>

                  <Card.Root>
                    <Card.Header>
                      <Card.Title>Vendor Details</Card.Title>
                    </Card.Header>
                    <Card.Body className="t-gap-4 t-flex t-flex-col">
                      <GridLayout>
                        <InfoItem label="Name">
                          {data.entity_merchant_data?.name || "-"}
                        </InfoItem>
                        <InfoItem label="Email">
                          {data.entity_merchant_data?.email ? (
                            <div className="t-flex t-gap-1">
                              <div className="t-truncate">
                                {data.entity_merchant_data.email}
                              </div>
                              <Button
                                customType="link"
                                size="small"
                                onClick={() =>
                                  copyToClipboard(
                                    data.entity_merchant_data?.email!
                                  )
                                }
                              >
                                <CopyIcon color="currentColor" />
                              </Button>
                            </div>
                          ) : (
                            "-"
                          )}
                        </InfoItem>

                        <InfoItem label="Address">
                          {data.merchant_address ? (
                            <Address address={data.merchant_address} />
                          ) : (
                            "-"
                          )}
                        </InfoItem>
                      </GridLayout>

                      <InfoItem label="Payment Instructions">
                        {data.payment_instructions ? (
                          <div className="t-break-words">
                            {data.payment_instructions}
                          </div>
                        ) : (
                          "-"
                        )}
                      </InfoItem>
                    </Card.Body>
                  </Card.Root>
                </Slider.Body>
                {isDraft && (
                  <Slider.Footer>
                    <Button
                      customType="primary"
                      block
                      onClick={() => onClick({ bill: data })}
                    >
                      Edit
                    </Button>
                  </Slider.Footer>
                )}
              </>
            )}
          </Async.Success>
        </Async.Root>
      </Slider.Content>
    </Slider.Root>
  );
};
