import classNames from "classnames";
import ConditionalToolTip from "components/design/conditionalToolTip";
import Loader from "components/design/loader";
import { Button } from "components/DesignSystem/Button/Button";
import { Checkbox } from "components/DesignSystem/Checkbox/Checkbox";
import Modal from "components/DesignSystem/Modal/Modal";
import { useToast } from "hooks/useToast";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useGetTaskQuery,
  useMarkTaskAsReviewedMutation,
} from "store/apis/task";
import { closeTaskReview, resetTaskReview } from "store/slices/TaskReview";
import { RootState } from "store/store";
import { ReviewForm } from "./fillFormStepper/ReviewModal";

export const FilingReviewModal = ({
  isArchived,
  handleSubmit,
}: {
  isArchived: boolean;
  handleSubmit: () => void;
}) => {
  const [isFilingReviewed, setIsFilingReviewed] = useState(false);
  const { taskId } = useParams<{ taskId: string }>();
  const { data, isLoading } = useGetTaskQuery({ taskId }, { skip: !taskId });
  const { showTaskReview, taskUpdatedSection } = useSelector(
    (state: RootState) => state.taskReview
  );
  const sections =
    taskUpdatedSection ||
    data?.form_data?.data?.data?.section_group?.[0].sections;
  const [markTaskAsReview, { isLoading: isMarkTaskAsReviewLoading }] =
    useMarkTaskAsReviewedMutation();

  const dispatch = useDispatch();
  const { alertToast } = useToast();

  const markTaskAsReviewed = async () => {
    try {
      await markTaskAsReview({ taskId }).unwrap();
      handleSubmit();
      closeModal();
    } catch (e: any) {
      alertToast({ message: e?.data?.error?.message });
    }
  };

  const closeModal = () => {
    dispatch(resetTaskReview());
    dispatch(closeTaskReview());
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Modal.Root open={showTaskReview} onOpenChange={closeModal}>
      <Modal.Content size="large">
        <Modal.Header>
          <Modal.Title>Review Information</Modal.Title>
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <div className="t-h-[500px] t-relative">
            <div className={classNames("t-mx-auto t-pb-5")}>
              <ReviewForm sections={sections}>
                <div className="t-flex t-items-center t-gap-1">
                  <div className="t-text-text-100 t-text-subtext">
                    To review the documents, please
                  </div>
                  <Button customType="link" onClick={closeModal}>
                    go back.
                  </Button>
                </div>
              </ReviewForm>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="t-pb-3 t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 t-mb-3 t-ml-2">
            <Checkbox
              name="filingReviewed"
              checked={isFilingReviewed}
              alignTop
              label={
                <div className="t-text-body-sm t-text-text-100 t-flex t-flex-col">
                  <div className="t-mt-1">
                    I have reviewed this document for its content and accuracy.
                  </div>
                  <div
                    className={classNames("t-text-red t-font-medium", {
                      "t-hidden": !isFilingReviewed,
                      "t-block": isFilingReviewed,
                    })}
                  >
                    This action cannot be undone.
                  </div>
                </div>
              }
              onChange={(e) => setIsFilingReviewed?.(e.target.checked)}
            />
          </div>
          <div className="t-flex t-justify-end t-gap-3">
            <Button onClick={closeModal}>Make changes</Button>
            <ConditionalToolTip
              condition={
                !isFilingReviewed && "Please tick the checkbox to proceed"
              }
            >
              <div>
                <Button
                  customType="primary"
                  isLoading={isMarkTaskAsReviewLoading}
                  disabled={!isFilingReviewed || isMarkTaskAsReviewLoading}
                  onClick={markTaskAsReviewed}
                >
                  Submit
                </Button>
              </div>
            </ConditionalToolTip>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal.Root>
  );
};
