import { useCallback, useRef } from "react";

type AbortControllerProps = {
  onAbort?: (s: AbortSignal) => void;
};

export const useAbortController = ({ onAbort }: AbortControllerProps = {}) => {
  const abortControllerRef = useRef<AbortController | null>(null);

  const signal = useCallback(() => {
    if (!abortControllerRef.current) {
      abortControllerRef.current = new AbortController();
    }
    return abortControllerRef.current.signal;
  }, []);

  const abort = useCallback(
    (reason?: string) => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort(reason);
        onAbort?.(abortControllerRef.current.signal);
        abortControllerRef.current = null;
      }
    },
    [onAbort]
  );

  return {
    signal: signal(),
    abort,
  };
};
