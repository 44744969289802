import React, { useContext, useEffect, useRef, useState } from "react";

// context
import authContext from "../../jwt_context&axios/authContext";

// components
import { updateTaskState } from "./adhocTaskFunctions";
import TaskLogModal from "./taskLogModal";

// images
import TaskLog from "../../static/images/TaskLog.svg";
import ActiveTaskLog from "../../static/images/ActiveTaskLog.svg";
import ToolTip from "components/design/toolTip";
import { UpdateTaskPrice } from "./updateTaskPrice";
import TaskInProgressSocialShare from "../TaskInprogressSocialShare";
import { Button } from "components/DesignSystem/Button/Button";
import ConditionalDynamicToolTip from "components/design/conditionalDynamicToolTip";
import TaskButtonBar from "./TaskButtonBar";
import { PERCENT } from "constants/stripeCouponTypes";
import { Badge } from "components/design/badge";

export const AdhocTaskInProgress = ({
  task,
  next,
  title,
  hasCustomerApproved,
  activeScopeOfWork,
  isCompleted,
  groupId,
  parentRef,
}) => {
  const { authtoken } = useContext(authContext);
  const [isShowLog, setShowLog] = useState(false);
  const [isHovered, setHovered] = useState(false);
  const [showUpdatePriceModal, setShowUpdatePriceModal] = useState(false);
  const [showSocialShare, setShowSocialShare] = useState(true);
  const [isVariablePricing, setIsVariablePricing] = useState(false);
  const shareable = task?.share_data?.is_sharable;
  const isCustomer =
    !authtoken?.is_service_user && !authtoken?.is_any_service_user;
  const { price } = activeScopeOfWork;

  useEffect(() => {
    if (shareable) {
      const storedSocialShareTasks =
        JSON.parse(localStorage.getItem("socialShareTaskIds")) || [];
      const taskIncluded = storedSocialShareTasks.includes(task.uuid);
      const showedSocialShare = taskIncluded ? false : true;
      setShowSocialShare(showedSocialShare);
      setTimeout(() => {
        if (!taskIncluded) {
          localStorage.setItem(
            "socialShareTaskIds",
            JSON.stringify([...storedSocialShareTasks, task.uuid])
          );
        }
      }, 500);
    }
  }, []);

  const updateToNextState = () => {
    if (!isCustomer && task.is_next_state_final) {
      setShowUpdatePriceModal(true);
      if (task.is_pricing_fixed) {
        setIsVariablePricing(false);
      } else {
        setIsVariablePricing(true);
      }
    } else {
      const nextState = task?.state?.is_step_replaceable
        ? task?.state?.new_state_id
        : next;
      updateTaskState(nextState);
    }
  };

  const coupon = task?.coupon;

  const discountOff =
    coupon?.discount_type === PERCENT
      ? `${coupon?.discount_rate}%`
      : `$${coupon?.discount_rate}`;

  let finalPrice = coupon?.discounted_price || price;

  const openLogModal = () => setShowLog(true);
  const closeLogModal = () => setShowLog(false);

  return (
    <div className="taxing scopeOfWorkContainer taskInProgress">
      <div className="d-flex justify-content-between w-100">
        <h6 className="pricingSubtitle">Scope of Work and Inkle Fee</h6>
        <div className="actionContainer">
          <img
            onClick={openLogModal}
            onMouseEnter={() => {
              setHovered(true);
            }}
            onMouseLeave={() => {
              setHovered(false);
            }}
            className="icon"
            src={isHovered ? ActiveTaskLog : TaskLog}
            alt="TaskLog"
          />
        </div>
      </div>
      <div className="d-flex justify-content-between w-100">
        <span className="scopeOfWork content">{title}</span>
        <span className="scopeOfWork">$ {price}</span>
      </div>
      <div className="d-flex justify-content-end w-100 inkleServiceFee pricingSubtitle">
        Inkle service fee
      </div>
      <div className="pricingAmount">
        <span>
          {coupon && (
            <div className="t-mt-2 t-flex t-items-center">
              <span className="t-mr-2 t-text-body-sm t-text-text-30">
                {coupon.coupon_code}
              </span>
              <Badge color="light-green">
                <span className="t-text-body-sm t-font-medium">
                  {discountOff} off
                </span>
              </Badge>
            </div>
          )}
          <span className="t-text-right t-block">$ {finalPrice}</span>
        </span>
      </div>

      {!isCompleted && (
        <TaskButtonBar justifyContent="end" parentRef={parentRef}>
          <ConditionalDynamicToolTip
            condition={
              isCustomer &&
              "This filing is still in progress, please reply in chat for updates."
            }
          >
            <span>
              <Button
                disabled={isCustomer}
                customType="primary"
                onClick={updateToNextState}
              >
                Next
              </Button>
            </span>
          </ConditionalDynamicToolTip>
        </TaskButtonBar>
      )}
      {isShowLog && (
        <TaskLogModal
          closeModal={closeLogModal}
          taskLogs={task.adhoc_data_updates}
        />
      )}

      <UpdateTaskPrice
        show={showUpdatePriceModal}
        setShow={setShowUpdatePriceModal}
        closeModal={() => setShowUpdatePriceModal(false)}
        groupId={groupId}
        newStateId={
          task?.state?.is_step_replaceable ? task?.state?.new_state_id : next
        }
        taskId={task.uuid}
        task={task}
        isVariablePricing={isVariablePricing}
        entityId={task.entity_id}
      />

      {isCustomer && shareable && (
        <TaskInProgressSocialShare
          show={showSocialShare}
          closeModal={() => setShowSocialShare(false)}
          task={task}
        />
      )}
    </div>
  );
};
