import { DashboardLayout } from "components/DashboardLayout";
import { Header } from "components/DesignSystem/Header/Header";
import { Reconciliation as ReconciliationPage } from "components/Reconciliation/Reconciliation";

export const Reconciliation = () => {
  return (
    <DashboardLayout
      header={
        <Header
          v2
          title={
            <div className="t-flex t-gap-2 t-items-center">Reconciliation</div>
          }
        />
      }
    >
      <ReconciliationPage />
    </DashboardLayout>
  );
};
