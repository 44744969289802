import { CHAT_REQUESTS } from "constants/bookkeeping";
import { REQUESTED_INFO_CUSTOM_TYPE } from "constants/chatType";
import { useGetActionItemsQuery } from "store/apis/actionItems";
import { BooksActionItems } from "types/Models/books";
import { usePaginatedMessages } from "./usePaginatedMessages";
import { useRoleBasedView } from "./useRoleBasedView";
import { useChatContext } from "stream-chat-react";

type UseBookActionItemsArgs = {
  groupId: string;
};

export const useBookActionItems = ({ groupId }: UseBookActionItemsArgs) => {
  const { client } = useChatContext();
  const { isCustomer } = useRoleBasedView();

  const {
    data: groupBooksActionItems,
    isLoading: isGroupBooksLoading,
    isSuccess: isGroupBooksSuccess,
  } = useGetActionItemsQuery(
    {
      groupId: groupId,
      type: "BOOKS",
    },
    { skip: !groupId, refetchOnMountOrArgChange: true }
  );

  const { action_items_response, channel_url } =
    (groupBooksActionItems as BooksActionItems) || {};

  const actionItems =
    action_items_response?.filter(({ item_type }) =>
      isCustomer
        ? item_type !== "Reconciliation" && item_type !== "Financial Closing"
        : item_type !== "Financial Closing"
    ) || [];

  const { messages: chatActionItems, loading } = usePaginatedMessages(
    client,
    {
      id: channel_url,
    },
    {},
    {},
    {
      custom_type: {
        $in: [REQUESTED_INFO_CUSTOM_TYPE],
      },
      card_status: { $eq: "REQUESTED" },
    },
    !channel_url
  );

  const chatCards = chatActionItems?.map(
    ({
      message: {
        custom_data: { header, transaction_count },
        id,
      },
    }: any) => ({
      header,
      transaction_count,
      id,
      channel_url,
      item_type: CHAT_REQUESTS,
    })
  );

  const allBooksActionItems = actionItems.flatMap(({ items, item_type }) =>
    items.map((item) => ({ ...item, item_type }))
  );

  const allItems = [...allBooksActionItems, ...chatCards];
  const totalActionItemCount = allItems.length;
  const isEmpty = totalActionItemCount === 0;

  return {
    allItems,
    totalActionItemCount,
    isEmpty,
    isLoading: loading || isGroupBooksLoading,
    isSuccess: isGroupBooksSuccess,
  };
};
