export const Paperclip = ({ size = "20" }: { size?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#clip0_2142_11052)">
      <path
        d="M9.99981 4.99998L4.79294 10.2931C4.60997 10.4816 4.50851 10.7345 4.51049 10.9972C4.51246 11.2599 4.61772 11.5112 4.80351 11.6969C4.9893 11.8826 5.24069 11.9878 5.50337 11.9896C5.76605 11.9915 6.01891 11.8899 6.20731 11.7069L12.4142 5.41436C12.7893 5.03924 13 4.53048 13 3.99998C13 3.46949 12.7893 2.96072 12.4142 2.58561C12.0391 2.21049 11.5303 1.99976 10.9998 1.99976C10.4693 1.99976 9.96055 2.21049 9.58544 2.58561L3.37856 8.87873C2.82352 9.44289 2.51389 10.2035 2.51711 10.9949C2.52034 11.7863 2.83615 12.5444 3.39577 13.104C3.95539 13.6636 4.71346 13.9795 5.50487 13.9827C6.29628 13.9859 7.05691 13.6763 7.62106 13.1212L12.7498 7.99998"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2142_11052">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
