import { ConditionalLink } from "components/conditionalLink";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { DocumentRequestedIcon } from "components/icons/DocumentRequestedIcon";
import { PaymentRequestedIcon } from "components/icons/PaymentRequestedIcon";
import { PendingDocumentsIcon } from "components/icons/PendingDocumentsIcon";
import { ReviewAndPayIcon } from "components/icons/ReviewAndPayIcon";
import { SensitiveDataIcon } from "components/icons/SensitiveDataIcon";
import { SignatureRequestedIcon } from "components/icons/SignatureRequestedIcon";
import { useModal } from "hooks/useModal";
import { useToast } from "hooks/useToast";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import NoPendingActionItems from "static/images/NoPendingActionItems.svg";
import { TaxActionItemType } from "store/apis/actionItems";
import { MessageResponse } from "stream-chat";
import { DefaultStreamChatGenerics, useChatContext } from "stream-chat-react";
import { ActionItemCard } from "./ActionItemCard";

const actionIcons: { [key: string]: JSX.Element } = {
  REQUESTED_DOCUMENTS_CUSTOM_TYPE: <DocumentRequestedIcon />,
  PAYMENT_REQUESTED: <PaymentRequestedIcon />,
  DOCUMENT_REVIEW: <PendingDocumentsIcon />,
  REVIEW_AND_PAY: <ReviewAndPayIcon />,
  SENSITIVE_DATA_CUSTOM_TYPE: <SensitiveDataIcon />,
  ESIGN_REQUEST_CUSTOM_TYPE: <SignatureRequestedIcon />,
};

export const ActionItemRow = ({
  item,
}: {
  item: TaxActionItemType & { custom_type: string };
}) => {
  const { ids: messageIds, title } = item;
  const actionModal = useModal();
  const { client } = useChatContext();
  const [messages, setMessages] = useState<
    MessageResponse<DefaultStreamChatGenerics>[]
  >([]);
  const { search } = useLocation();
  const { alertToast } = useToast();

  const getMessages = async (messageIds: string[]) => {
    try {
      const searchResults = await client.search(
        {
          members: { $in: [client.user?.id!] },
        },
        { id: { $in: messageIds } },
        {}
      );
      setMessages(
        searchResults.results
          .filter(
            (result) =>
              result.message.custom_type == "TICKET_CARD" ||
              !result.message.channel?.frozen
          )
          .map((result) => result.message)
      );
    } catch (e: any) {
      alertToast({ message: e.message });
    }
  };

  useEffect(() => {
    if (messageIds.length > 0) {
      getMessages(messageIds);
    }
  }, [JSON.stringify(messageIds)]);

  return (
    <div className="t-flex t-justify-between t-items-center t-py-4 t-px-0 t-border t-border-solid t-border-neutral-0 t-border-t-0 t-border-l-0 t-border-r-0 last:t-border-b-0">
      <div className="t-flex t-gap-2 t-items-center">
        <div className="t-relative t-min-h-10 t-min-w-10 t-max-h-10 t-max-w-10 t-border t-border-solid t-border-neutral-0 t-rounded-full t-flex t-items-center t-justify-center">
          {actionIcons[item.custom_type] || <DocumentRequestedIcon />}
        </div>
        <div className="t-text-subtext-sm t-text-text-100">
          {item.count} {item.title}
        </div>
      </div>
      <Modal.Root onOpenChange={actionModal.toggle} open={actionModal.isOpen}>
        <Modal.Trigger asChild>
          <Button size="small">View</Button>
        </Modal.Trigger>
        <Modal.Content useCustomOverlay>
          <Modal.Header>
            <Modal.Title>{title}</Modal.Title>
            <Modal.Close />
          </Modal.Header>
          <Modal.Body className="t-space-y-5">
            {messages.length === 0 ? (
              <div className="t-flex t-flex-col t-items-center t-justify-center t-h-80 t-w-full t-gap-8">
                <img src={NoPendingActionItems} alt="NoPendingActionItems" />
                <div className="t-text-subtitle t-text-text-30">
                  Awesome! No pending items
                </div>
              </div>
            ) : (
              messages.map((message) => {
                const {
                  channel,
                  id: messageId,
                  custom_data,
                  custom_type,
                } = message;
                const showSeparator =
                  channel?.name && channel?.created_by?.name;

                return (
                  <div
                    key={messageId}
                    className="t-p-3 t-rounded-lg t-bg-surface-lighter-grey t-space-y-3"
                  >
                    <div className="t-flex t-justify-between t-items-center">
                      <div className="t-flex t-gap-1.5 t-items-center">
                        {channel?.created_by?.name && (
                          <div className="t-text-subtext-sm t-text-text-100">
                            {channel?.created_by?.name}
                          </div>
                        )}
                        {showSeparator && (
                          <div className="t-text-neutral-20 t-text-caption">
                            |
                          </div>
                        )}
                        {channel?.name && (
                          <div className="t-text-body-sm t-text-text-30">
                            {channel.name}
                          </div>
                        )}
                      </div>
                      {channel && (
                        <ConditionalLink
                          to={`chat/${channel.id}/${messageId}${search}`}
                          target="_blank"
                        >
                          <Button customType="link" size="small">
                            Go to chat
                          </Button>
                        </ConditionalLink>
                      )}
                    </div>
                    <ActionItemCard
                      type={custom_type as string}
                      customData={custom_data}
                      messageId={messageId}
                      channelId={channel?.id || ""}
                    />
                  </div>
                );
              })
            )}
          </Modal.Body>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
};
