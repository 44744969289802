import { Divider } from "components/design/Divider";
import { AvatarUpload } from "components/DesignSystem/AvatarGroup/AvatarUpload";
import { Button } from "components/DesignSystem/Button/Button";
import Modal from "components/DesignSystem/Modal/Modal";
import { TextInput } from "components/DesignSystem/TextInput/TextInput";
import { PriceInput } from "components/PriceInput/PriceInput";
import { Form, Formik } from "formik";
import { vendorEditSchema } from "formValidations/vendorSchema";
import { useToast } from "hooks/useToast";
import { useEditVendorsMutation } from "store/apis/vendors";
import { Vendor } from "types/Models/vendors";
import { BackendError } from "types/utils/error";
import { ModalProps } from "types/utils/modal";

type EditVendorProps = {
  groupId: string;
  vendor: Vendor;
} & ModalProps;

export const EditVendor = ({
  close,
  isOpen,
  groupId,
  vendor,
}: EditVendorProps) => {
  const { alertToast, successToast } = useToast();
  const [editVendor] = useEditVendorsMutation();

  const { uuid, name, email, w_form_season, source } = vendor || {};
  const isAmountEditAllowed = source === "MANUAL" && w_form_season;

  const onSubmit = async (payload: {
    name: string;
    email: string;
    amount_paid_in_season: string;
    total_amount: number;
  }) => {
    try {
      await editVendor({
        groupId,
        uuid,
        payload: { ...payload, season: w_form_season },
      }).unwrap();
      successToast({ message: "Vendor has been updated" });
      close();
    } catch (error) {
      alertToast({ message: (error as BackendError).data?.error?.message });
    }
  };

  return (
    <Modal.Root open={isOpen} onOpenChange={close}>
      <Modal.Content>
        <Formik
          initialValues={{
            name: name || "",
            email: email || "",
            amount_paid_in_season: w_form_season || "",
            total_amount: vendor["1099_transaction_amount"] || 0,
          }}
          onSubmit={onSubmit}
          validationSchema={vendorEditSchema}
          validateOnChange
        >
          {({ values: { name }, isSubmitting, submitForm }) => (
            <>
              <Modal.Header>
                <Modal.Title>Edit Vendor</Modal.Title>
                <Modal.Close />
              </Modal.Header>
              <Modal.Body>
                <Form className="t-flex t-flex-col t-gap-5">
                  <div className="t-flex t-items-center t-gap-4">
                    <AvatarUpload
                      variant="primary"
                      alt={name}
                      size="extra-large"
                    />
                    <TextInput
                      required
                      label="Name"
                      name="name"
                      placeholder="Enter vendor name"
                    />
                    <TextInput
                      label="Email"
                      name="email"
                      placeholder="Enter vendor email"
                    />
                  </div>
                  <Divider />
                  <PriceInput
                    disabled={!isAmountEditAllowed}
                    name="total_amount"
                    label="Amount Paid"
                    required
                  />
                </Form>
              </Modal.Body>
              <Modal.FooterButtonGroup>
                <Modal.RawClose asChild>
                  <Button disabled={isSubmitting}>Cancel</Button>
                </Modal.RawClose>
                <Button
                  onClick={submitForm}
                  customType="primary"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </Modal.FooterButtonGroup>
            </>
          )}
        </Formik>
      </Modal.Content>
    </Modal.Root>
  );
};
