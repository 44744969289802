import { multipleFileDelete } from "apis/documents";
import ConditionalToolTip from "components/design/conditionalToolTip";
import { DeleteModal } from "components/design/deleteModal";
import { LoadingToast } from "components/design/LoadingToast";
import { Button } from "components/DesignSystem/Button/Button";
import Dropdown from "components/DesignSystem/Dropdown/Dropdown";
import {
  ToggleGroup,
  ToggleGroupItem,
} from "components/DesignSystem/ToggleGroup/ToggleGroup";
import { useAuth } from "hooks/useAuth";
import {
  FILE_FOLDER_DELETED,
  FILE_FOLDER_DELETE_FAILED,
  FILE_FOLDER_DOWNLOADED,
  FILE_FOLDER_DOWNLOAD_FAILED,
} from "constants/analyticsEvents";
import { useAnalytics } from "hooks/useAnalytics";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { useQuery } from "hooks/useQuery";
import { useToast } from "hooks/useToast";
import React, { useContext, useMemo, useState } from "react";
import Gridview from "static/images/GridView.svg";
import Listview from "static/images/ListView.svg";
import {
  useExportFolderMutation,
  useLazyGetSearchContentQuery,
  useRenameFileFolderMutation,
} from "store/apis/documents";
import { openLink } from "utils/openLink";
import Actions from "./Actions";
import AddActionDropdown from "./addActionDropdown";
import { DocumentContext } from "./documentContext";
import { RenameFileFolderModal } from "./documentsModal/renameFileFolderModal";
import { SelectAll } from "./SelectAll";
import { MoveDocModal } from "./documentsModal/MoveDocModal";
import { useModal } from "hooks/useModal";
import { ExportOptions } from "components/ExportOptions/ExportOptions";
import { CaretDown } from "components/icons/CaretDown";

interface DocHeaderActionsProps {
  userGroupId: string;
  groupId: string;
  openAddFolderModal: () => void;
  setLoad: React.Dispatch<React.SetStateAction<boolean>>;
  ParentId: string | null;
  setLoadingFileUploadStart: (value: boolean) => void;
  uploadPayload: any;
  open: boolean;
  listView: boolean;
  changeViewType: () => void;
  setUploadPayload: (payload: any) => void;
  uploadLoad: boolean;
  setUploadLoad: (value: boolean) => void;
  setLoadingMultipleFileDelete: React.Dispatch<React.SetStateAction<boolean>>;
  groupDocID: string;
  setMovedFolderName: (name: string) => void;
  setNewFolderName: (name: string) => void;
  entityFileAndFolder: Array<{
    uuid: string;
    name: string;
    type: string;
    parent_uuid: string;
    is_locked: boolean;
    isChecked?: boolean;
  }>;
}

export const DocHeaderActions: React.FC<DocHeaderActionsProps> = ({
  userGroupId,
  groupId,
  openAddFolderModal,
  setLoad,
  ParentId,
  setLoadingFileUploadStart,
  uploadPayload,
  open,
  listView,
  changeViewType,
  setUploadPayload,
  uploadLoad,
  setUploadLoad,
  setLoadingMultipleFileDelete,
  groupDocID,
  setMovedFolderName,
  setNewFolderName,
  entityFileAndFolder,
}) => {
  const { trackEvent } = useAnalytics();
  const {
    selectedFiles,
    setSelectedFiles,
    setCurrentPageNo,
    setEntityFileAndFolder,
    handleFolderClick,
  } = useContext(DocumentContext);
  const query = useQuery();
  const { alertToast, successToast } = useToast();
  const searchTerm = query.get("search");
  const selectedFileIds = useMemo(
    () => selectedFiles.map(({ uuid }: { uuid: string }) => uuid),
    [selectedFiles]
  );
  const [deleteModalShow, setDeleteModalShow] = useState(false);
  const moveFileModal = useModal();
  const [renameModalShow, setRenameModalShow] = useState(false);
  const [getSearchContent] = useLazyGetSearchContentQuery();
  const [exportFolder, { isLoading: isExporting }] = useExportFolderMutation();
  const { copyToClipboard } = useCopyToClipboard();
  const selectedFileData = useMemo(() => [...selectedFiles], [selectedFiles]);
  const [renameFileAndFolder] = useRenameFileFolderMutation();
  const isAnyFileOrFolderSelected = selectedFileData.length > 0;
  const selectedItemCount = selectedFileData.length;
  const linkText = `${process.env.PUBLIC_URL}docs/${groupId}/${selectedFileData?.[0]?.parent_uuid}`;
  const { email } = useAuth();

  const onSelectAllChange = (value: boolean) => {
    const updatedMapData = entityFileAndFolder.map((ele) => ({
      ...ele,
      isChecked: value,
    }));

    setEntityFileAndFolder(updatedMapData);

    if (value) {
      const selected = updatedMapData.map(
        ({ name, uuid, type, parent_uuid, is_locked }) => ({
          uuid,
          name,
          type,
          parent_uuid,
          is_locked,
        })
      );
      setSelectedFiles(selected);
    } else {
      setSelectedFiles([]);
    }
  };

  const exportAllDocuments = async (exportToMail: boolean) => {
    try {
      const response = await exportFolder({
        groupId,
        export_to_mail: exportToMail,
      }).unwrap();

      if (!exportToMail) {
        const { download_url } = response;
        successToast({
          title: "Export Completed",
        });
        openLink(download_url);
      } else {
        successToast({
          title: "Export in progress",
          message: "Documents will be sent to your email once done",
        });
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
    }
  };

  const multipleDownloads = async (
    e?: React.MouseEvent<HTMLButtonElement>,
    exportToMail?: boolean
  ) => {
    e?.stopPropagation();
    try {
      const response = await exportFolder({
        groupId,
        fileId: selectedFileIds,
        export_to_mail: exportToMail,
      }).unwrap();

      if (!exportToMail) {
        const { download_url } = response;
        successToast({
          title: "Export Completed",
        });
        openLink(download_url);
        trackEvent(FILE_FOLDER_DOWNLOADED, {
          file_or_folder_ids: selectedFileIds,
        });
      } else {
        successToast({
          title: "Export in progress",
          message: "Documents will be sent to your email once done",
        });
      }
    } catch (error: any) {
      alertToast({ message: error?.data?.error?.message });
      trackEvent(FILE_FOLDER_DOWNLOAD_FAILED, {
        file_or_folder_ids: selectedFileIds,
        error: error?.data?.error?.message,
      });
    }
  };

  const multipleDelete = async () => {
    try {
      const response = await multipleFileDelete({
        groupId,
        fileIds: selectedFileIds,
      });
      trackEvent(FILE_FOLDER_DELETED, { file_or_folder_ids: selectedFileIds });
      setLoadingMultipleFileDelete(true);
      if (searchTerm) {
        const {
          files = [],
          folders = [],
          current_page,
        } = await getSearchContent({
          searchTerm,
          groupId,
          pageNum: 1,
        }).unwrap();
        setCurrentPageNo(current_page);

        const updatedMapData = [...folders, ...files].map((ele) => ({
          ...ele,
          isChecked: false,
        }));
        setEntityFileAndFolder(updatedMapData);
      } else {
        const updatedMapData = [
          ...response.data.data.subfolders,
          ...response.data.data.files,
        ].map((ele) => ({
          ...ele,
          isChecked: false,
        }));
        setEntityFileAndFolder(updatedMapData);
      }
      setSelectedFiles([]);
      setDeleteModalShow(false);
      setTimeout(() => {
        setLoadingMultipleFileDelete(false);
      }, 1500);
    } catch (error: any) {
      trackEvent(FILE_FOLDER_DELETE_FAILED, {
        file_or_folder_ids: selectedFileIds,
        error: error?.data?.error?.message,
      });
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  const renameFolder = async ({ file_name }: { file_name: string }) => {
    try {
      const data = await renameFileAndFolder({
        groupId,
        payload: { file_name },
        fileId: selectedFileData[0]?.uuid,
      }).unwrap();
      successToast({ message: `Renamed to ${data.name}` });
      const updatedDocuments = entityFileAndFolder.map((doc) =>
        doc.uuid === data.uuid ? data : doc
      );
      setEntityFileAndFolder(updatedDocuments);
      setNewFolderName(data.name);
      setSelectedFiles([]);
      setRenameModalShow(false);
    } catch (error: any) {
      alertToast({ message: error?.response?.data?.error?.message });
    }
  };

  return (
    <span
      className="viewChangeButtonContainer t-flex gap-2"
      onClick={(e) => e.stopPropagation()}
    >
      {isAnyFileOrFolderSelected ? (
        <span className="t-flex t-gap-2 t-items-center">
          <Actions
            onRename={() => setRenameModalShow(true)}
            onCopy={() => copyToClipboard(linkText)}
            onMove={() => moveFileModal.open()}
            onDownload={multipleDownloads}
            onDelete={() => setDeleteModalShow(true)}
            goToFolder={() => {
              handleFolderClick(selectedFileData[0]?.parent_uuid);
              setSelectedFiles([]);
            }}
          />
          {!searchTerm && (
            <SelectAll
              selectedItemCount={selectedItemCount}
              onSelectAllChange={onSelectAllChange}
            />
          )}
        </span>
      ) : (
        <>
          <ToggleGroup
            value={listView ? "list" : "grid"}
            onValueChange={changeViewType}
            size="small"
          >
            <ConditionalToolTip condition="Grid view">
              <ToggleGroupItem value="grid">
                <img src={Gridview} alt="Grid view" />
              </ToggleGroupItem>
            </ConditionalToolTip>
            <ConditionalToolTip condition="List view">
              <ToggleGroupItem value="list">
                <img src={Listview} alt="List view" />
              </ToggleGroupItem>
            </ConditionalToolTip>
          </ToggleGroup>

          <ConditionalToolTip condition="Export documents">
            <span>
              <Dropdown.Root>
                <Dropdown.Trigger asChild className="t-group">
                  <div>
                    <Button size="small" title="Download selected">
                      <div className="t-flex t-items-center t-gap-1.5">
                        Download
                        <div className="group-data-state-open:t-rotate-180">
                          <CaretDown />
                        </div>
                      </div>
                    </Button>
                  </div>
                </Dropdown.Trigger>
                <Dropdown.Portal>
                  <Dropdown.Content sideOffset={4} align="end" side="bottom">
                    <ExportOptions
                      onLocalDownload={() => exportAllDocuments(false)}
                      onMailExport={() => exportAllDocuments(true)}
                    />
                  </Dropdown.Content>
                </Dropdown.Portal>
              </Dropdown.Root>
            </span>
          </ConditionalToolTip>
          <AddActionDropdown
            setLoadingFileUploadStart={setLoadingFileUploadStart}
            groupId={groupId}
            setLoad={setLoad}
            openAddFolderModal={openAddFolderModal}
            ParentId={ParentId}
            uploadPayload={uploadPayload}
            setUploadPayload={setUploadPayload}
            open={open}
          />
        </>
      )}
      {deleteModalShow && (
        <DeleteModal
          text={`Delete ${selectedItemCount} ${selectedFileData[0]?.type?.toLowerCase()}`}
          show={deleteModalShow}
          closeModal={() => setDeleteModalShow(false)}
          onClick={multipleDelete}
        />
      )}

      {renameModalShow && (
        <RenameFileFolderModal
          renameModalShow={renameModalShow}
          closeRenameModal={() => setRenameModalShow(false)}
          itemType={selectedFileData[0]?.type}
          folderName={selectedFileData[0]?.name}
          renameFolder={renameFolder}
        />
      )}

      <LoadingToast loading={isExporting} title="Preparing Export">
        This may take upto one minute
      </LoadingToast>
      <MoveDocModal
        isOpen={moveFileModal.isOpen}
        close={moveFileModal.close}
        itemIds={selectedFileIds}
        onMoveSuccess={() => {
          setLoad((prev) => !prev);
          setSelectedFiles([]);
        }}
      />
    </span>
  );
};
