import { useQuery } from "hooks/useQuery";
import { EMPTY_FOLDER_MESSAGE } from "../../constants/documents";
import EmptyFolder from "../../static/images/EmptyFolder.svg";

export const NoDocumentItem = () => {
  const query = useQuery();
  const searchTerm = query.get("search");

  return (
    <div className="t-flex t-justify-center t-items-center t-flex-col t-gap-12 t-h-[70dvh]">
      <img src={EmptyFolder} alt="empty folder" />
      <div className="t-flex t-justify-center t-items-center t-flex-col">
        <h4 className="t-text-title-h2-bold t-text-text-black">
          {EMPTY_FOLDER_MESSAGE}
        </h4>
        {!searchTerm && (
          <p className="t-text-subtitle t-text-text-30">
            Drop files here or use 'Upload' button
          </p>
        )}
      </div>
    </div>
  );
};
