import classNames from "classnames";
import React from "react";

const Tag = ({ color_hex, bg_color_hex, title, size, justifyEnd = false }) => (
  <span
    className={classNames("t-mb-1 t-rounded-lg t-px-2 t-py-1 t-font-medium", {
      "!t-text-body-sm": size === "small",
      "!t-px-3 !t-py-[8px]": size === "big",
      "t-mr-3": !justifyEnd,
      "t-ml-3": justifyEnd,
    })}
    style={{
      color: color_hex,
      backgroundColor: bg_color_hex,
    }}
  >
    #{title}
  </span>
);

export default Tag;
